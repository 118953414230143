import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgInApp(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.65 8.2c0-.966.784-1.75 1.75-1.75h11.2c.966 0 1.75.784 1.75 1.75v1.586c0 .464-.184.909-.513 1.237l-.092.093a1.25 1.25 0 000 1.768l.092.093c.329.328.513.773.513 1.237V15.8a1.75 1.75 0 01-1.75 1.75H6.4a1.75 1.75 0 01-1.75-1.75v-1.586c0-.464.184-.909.513-1.237l.092-.093a1.25 1.25 0 000-1.768l-.092-.093a1.75 1.75 0 01-.513-1.237V8.2zm1.75-.25a.25.25 0 00-.25.25v1.586c0 .066.026.13.073.177l.093.093a2.75 2.75 0 010 3.889l-.093.092a.25.25 0 00-.073.177V15.8c0 .138.112.25.25.25h11.2a.25.25 0 00.25-.25v-1.586a.25.25 0 00-.073-.177l-.093-.092a2.75 2.75 0 010-3.89l.093-.092a.25.25 0 00.073-.177V8.2a.25.25 0 00-.25-.25H6.4zM5.85 19.2a.75.75 0 01.75-.75h10.8a.75.75 0 010 1.5H6.6a.75.75 0 01-.75-.75zm6.618-9.441a.5.5 0 00-.944 0l-.272.78a.5.5 0 01-.462.336l-.826.017a.5.5 0 00-.292.898l.659.5a.5.5 0 01.176.542l-.24.791a.5.5 0 00.765.555l.678-.472a.5.5 0 01.571 0l.679.472a.5.5 0 00.764-.555l-.24-.79a.5.5 0 01.177-.544l.658-.499a.5.5 0 00-.292-.898l-.825-.017a.5.5 0 01-.463-.336l-.27-.78z"
        fill="#B255E0"
      />
    </svg>
  );
}

export default SvgInApp;
