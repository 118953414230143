import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgBoxSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.043 1.674a1.917 1.917 0 011.914 0h.001l4.084 2.334c.232.134.433.316.59.531a.753.753 0 01.173.287c.128.26.195.548.195.84v4.667a1.92 1.92 0 01-.958 1.659l-.003.002-4.08 2.331-.002.001a1.916 1.916 0 01-.626.227.746.746 0 01-.662 0 1.916 1.916 0 01-.626-.227h-.001l-4.08-2.332-.004-.002A1.917 1.917 0 012 10.334V5.667c0-.292.067-.58.195-.84a.753.753 0 01.173-.288c.157-.216.358-.397.59-.531l.003-.002 4.082-2.332zM8.75 12.717l3.542-2.024a.417.417 0 00.208-.36V6.268L8.75 8.44v4.278zm-1.5-4.279v4.279l-3.542-2.024a.417.417 0 01-.208-.36V6.269l3.75 2.17zm.958-5.464l3.518 2.01L8 7.139 4.274 4.984l3.515-2.009.003-.001a.417.417 0 01.416 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgBoxSmallLined;
