// Store
import { useStoreActions } from '@src/store/store';
import { PeriodSettings } from '@src/common/types/statistics';

// Types
type TActionLoad = (payload: { id: string; periodSettings: PeriodSettings }) => void;
type TResult = TActionLoad;

export function useLoadStatistics(): TResult {
  const load: TActionLoad = useStoreActions((actions): TActionLoad => actions.statistics.load);

  return load;
}
