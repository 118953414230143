export enum RepeatType {
  EVERY_DAY = 'every-day',
  EVERY_WEEK = 'every-week',
  EVERY_MONTH = 'every-month',
  CUSTOM = 'custom',
  DATES_SPECIFIED = 'dates-specified'
}

export enum RepeatFrequency {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  DATES = 'dates'
}
