import _reduce from 'lodash/reduce';

export const formatWeekDaysValues = (values: boolean[]): string[] =>
  _reduce(
    values,
    (acc, item, index) => {
      if (item) {
        acc = [index.toString(), ...acc];
      }

      return acc;
    },
    []
  );
