import styled from 'styled-components';
import { Color, FontSize, FontWeight, Spacing } from '@pushwoosh/kit-constants';
import { Badge } from '@src/ui/kit/Badge';

export const EnabledChannels = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${Spacing.S12};
  gap: ${Spacing.S4};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: ${FontSize.REGULAR};
  font-weight: ${FontWeight.MEDIUM};
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: ${Spacing.S3};
`;

export const SmallBadge = styled(Badge)`
  font-size: ${FontSize.SMALL};
`;

export const SpecifiedDaysWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${Color.LOCKED};
  gap: ${Spacing.S2};
`;

export const SpecifiedDay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.SOFT_LIGHT};
  height: 24px;
  width: 32px;
  border-radius: 4px;
  margin-right: ${Spacing.S3};
`;

export const Hint = styled.span`
  font-size: ${FontSize.SMALL};
  font-weight: ${FontWeight.MEDIUM};
  color: ${Color.PHANTOM};
`;
