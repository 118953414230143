import * as React from 'react';

// Components
import { DocElements } from '@src/ui/features/Documentation/components/DocElements';

export function DocumentationPointDelay(): JSX.Element {
  return (
    <DocElements>
      <DocElements.Paragraph>
        {
          'The Time Delay element makes users wait for a specified amount of time before moving forward to the next journey step. The delay can be set as a time period, exact time, or can be automatically taken from a tag value or event attributes. '
        }
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        <strong>Time period </strong>
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        {
          'When set to a specific time period, the Time Delay element lets users continue their journey only when the specified amount of time passes. '
        }
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        <i>
          {
            'For example, if a delay is set to 8 hours, a user who reaches this journey point will wait for 8 hours before moving to the next step. '
          }
        </i>
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentations-delay-1.png"
        label="Setting up a delay"
        alt="Setting up a delay"
      />

      <DocElements.Paragraph>
        <strong>Exact time</strong>
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        {
          'You can set the exact time for users to move forward through their journey. That means the users who have reached the delay point go to the next journey step once the specified time within that same day occurs. '
        }
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        <i>
          {
            "For example, if a user gets to a delay point early in the morning and you have set the delay to wait until 5:30 PM, those users will proceed to the next journey point at 5:30 PM according to their device's timezone. "
          }
        </i>
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-delay-2.png"
        label="Setting up exact time"
        alt="Setting up exact time"
      />

      <DocElements.Paragraph>
        <strong>Delay based on user or event data </strong>
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        {
          'For some cases, you might need to set a delay dynamically, based on what you already know about journey travelers or what actions they perform within their journey. '
        }
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        {'To set a delay based on user tags or events they trigger: '}
      </DocElements.Paragraph>
      <DocElements.List>
        <DocElements.ListItem>
          {'choose the '}
          <strong>Based on user/event data option;</strong>
        </DocElements.ListItem>
        <DocElements.ListItem>
          {'select a '}
          <strong>tag or event</strong>
          {' to get data from. '}
        </DocElements.ListItem>
      </DocElements.List>

      <DocElements.Paragraph>
        {'The next journey step can be scheduled to happen '}
        <strong>right on the date and time</strong>
        {' specified in a tag value or event attributes or '}
        <strong>several days after/several days before</strong>
        {' that date. '}
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentations-delay-3.png"
        label="Setting up exact time"
        alt="Setting up exact time"
      />

      <DocElements.Paragraph>
        <i>
          {
            'For example, you set the "before 2 days" delay to remind users of their appointment by getting the date and time of the visit from the Appointment event attributes. If a user makes an appointment for tomorrow, they won\'t fall under the "before 2 days" delay condition and will exit the journey right after they reach the Time Delay point in their journey. '
          }
        </i>
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        {'However, to manage these cases, you can '}
        <strong>split the further journey</strong>
        {
          ' into two branches following the Time Delay element and let users continue their journey even if they fall out on the delay step. '
        }
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        {'Check the '}
        <strong>{"Split to branches if the date's in the past or date is empty"}</strong>
        {
          ' checkbox, and the further flow will be split up into two branches – "In the future" and "In the past", where "in the past" will gather users whose tag values or event attributes don\'t fall under the delay conditions and can be built of any other elements (for example, another Time Delay, Segment Splitter, Wait for Event, or immediate communication).'
        }
      </DocElements.Paragraph>
    </DocElements>
  );
}
