import React from 'react';
import Konva from 'konva';
import { Group, Path, Text } from 'react-konva';
import {
  useActiveCommentId,
  useSetActiveComment,
  useUpdateCommentPosition,
  useCheckOverlapComments
} from '@src/store/hooks';
import { useStoreActions } from '@src/store/store';

// Constamts
import { Color } from '@pushwoosh/kit-constants';

// Types
import { IDeletedCommentProps } from './DeletedComment.types';

const SMALL_TEXT_OFFSET = 4.5;
const LARGE_TEXT_OFFSET = 9;

export function DeletedComment(props: IDeletedCommentProps): JSX.Element {
  const { text, position, commentId } = props;

  const activeCommentId = useActiveCommentId();
  const setActiveComment = useSetActiveComment();
  const handleCommentRestore = useStoreActions((state) => state.comments.restore);
  const actionUpdateCommentPosition = useUpdateCommentPosition();
  const handleCommentsCheckOverlap = useCheckOverlapComments();

  const textPosition = text.length < 2 ? LARGE_TEXT_OFFSET : SMALL_TEXT_OFFSET;
  const isActive = activeCommentId === commentId;

  const [isVisibleRestore, setIsVisibleRestore] = React.useState(isActive);

  const onMouseEnter = React.useCallback(() => {
    setActiveComment(commentId);
    setIsVisibleRestore(true);
  }, [setActiveComment, commentId]);

  const onMouseLeave = React.useCallback(() => {
    setActiveComment('');
    setIsVisibleRestore(false);
  }, [setActiveComment]);

  const onClick = React.useCallback(() => {
    handleCommentRestore(commentId);
  }, [commentId, handleCommentRestore]);

  const handleCommentDragEnd = React.useCallback(
    (event: Konva.KonvaEventObject<DragEvent>) => {
      const { commentId, x, y } = event.currentTarget.getAttrs();

      actionUpdateCommentPosition(commentId, { x, y });
      handleCommentsCheckOverlap();
    },
    [actionUpdateCommentPosition, handleCommentsCheckOverlap]
  );

  return (
    <>
      <Group
        onDragEnd={handleCommentDragEnd}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        name="comment"
        commentId={commentId}
        x={position.x}
        y={position.y}
        draggable
      >
        <Path
          fill={isActive ? Color.PRIMARY : Color.PHANTOM}
          perfectDrawEnabled={false}
          shadowForStrokeEnabled={false}
          data="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 18.4991 25.9538 21.6195 23.2707 24.143C21.942 25.3927 20.4447 26.5087 18.9617 27.5758C18.6697 27.7859 18.3788 27.9938 18.0896 28.2004C16.8933 29.0552 15.7256 29.8896 14.6292 30.7772C14.2623 31.0742 13.7377 31.0742 13.3708 30.7772C12.2744 29.8897 11.1067 29.0553 9.91047 28.2005C9.62121 27.9938 9.33029 27.7859 9.03831 27.5758C7.5553 26.5087 6.05799 25.3927 4.72923 24.1429C2.04623 21.6194 0 18.4991 0 14Z"
        />
        {isVisibleRestore ? (
          <Path
            fill={Color.CLEAR}
            perfectDrawEnabled={false}
            shadowForStrokeEnabled={false}
            x={6}
            y={7}
            data="M0.421875 4.92871C0.421875 5.34292 0.757662 5.67871 1.17188 5.67871L5.17188 5.67871C5.58609 5.67871 5.92188 5.34292 5.92188 4.92871C5.92188 4.5145 5.58609 4.17871 5.17188 4.17871L3.22396 4.17871C3.13487 4.17871 3.09025 4.071 3.15325 4.008L3.58087 3.58038C3.58353 3.57772 3.58616 3.57505 3.58877 3.57236C6.03019 1.13951 9.98153 1.14216 12.4197 3.58029C14.8604 6.02106 14.8604 9.97835 12.4197 12.4191C9.97889 14.8599 6.0216 14.8599 3.58083 12.4191C3.01472 11.853 2.57992 11.2053 2.27641 10.5139C2.09452 10.0995 1.61503 9.87356 1.20306 10.0608C0.863127 10.2153 0.688273 10.6008 0.830059 10.9463C1.20834 11.8679 1.7717 12.7313 2.52017 13.4798C5.54673 16.5063 10.4538 16.5063 13.4803 13.4798C16.5069 10.4532 16.5069 5.54619 13.4803 2.51963C10.4538 -0.506936 5.54673 -0.506936 2.52017 2.51963C2.51751 2.52228 2.51489 2.52495 2.51229 2.52764L2.09259 2.94734C2.02959 3.01034 1.92188 2.96572 1.92188 2.87663L1.92188 0.928711C1.92188 0.514497 1.58609 0.178711 1.17188 0.178711C0.757662 0.178711 0.421875 0.514497 0.421875 0.928711V4.92871Z"
          />
        ) : (
          <Text
            fill="white"
            perfectDrawEnabled={false}
            shadowForStrokeEnabled={false}
            x={textPosition}
            y={9}
            fontFamily="Roboto"
            fontSize={16}
            fontStyle="700"
            text={text}
          />
        )}
      </Group>
    </>
  );
}
