export const platforms = {
  0: 'UNKNOWN',
  1: 'IOS',
  2: 'Android',
  3: 'OSX',
  4: 'Windows',
  5: 'Amazon',
  6: 'Safari',
  7: 'Chrome',
  8: 'Firefox',
  9: 'Email',
  10: 'HuaweiAndroid',
  11: 'SMS',
  12: 'Web'
} as Record<number, string>;
