import styled from 'styled-components';

// Components
import { H2 } from '@pushwoosh/kit-typography';
import { FormInput } from '@src/ui/form';
import { FormTextarea } from '@src/ui/form/FormTextarea';
import { Button } from '@src/ui/kit/Button';
import { Link as DefaultLink } from '@src/ui/kit';

// Constants
import { Color, FontSize, FontWeight, ShapeRadius, Spacing } from '@pushwoosh/kit-constants';

export const Gray = styled.span`
  font-size: ${FontSize.REGULAR};
  font-weight: ${FontWeight.REGULAR};
  color: ${Color.LOCKED};
`;

export const StyledInput = styled(FormInput)`
  padding-bottom: 0;
  margin-top: ${Spacing.S5};
  width: 100%;
`;

export const Example = styled.div`
  position: relative;
`;

export const StyledTextarea = styled(FormTextarea)`
  padding-bottom: 0;
  margin-top: ${Spacing.S5};
  width: 100%;

  textarea {
    height: 200px;
  }
`;

export const CopyButton = styled(Button)`
  position: absolute;
  top: ${Spacing.S6};
  right: ${Spacing.S6};
`;

export const SubHeader = styled(H2)`
  margin-bottom: ${Spacing.S3};
`;

export const Placeholder = styled.span`
  background-color: ${Color.SOFT_LIGHT};
  border-radius: ${ShapeRadius.CONTROL};
  font-weight: ${FontWeight.REGULAR};
  padding: ${Spacing.S1} ${Spacing.S2};
  margin: 0 ${Spacing.S1};
`;

export const Link = styled(DefaultLink)`
  display: inline-flex;
  font-size: 1em;
`;
