import * as React from 'react';
import { Router, Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { createApi } from '@src/store/api/configured/configured';
import { createRPC } from '@src/store/api/configured/rpc';
import { HttpClientProvider } from '@src/common/contexts/http-client';
import { RpcProvider } from '@src/common/contexts/rpc';
import { HistoryProvider } from '@src/common/contexts/history';
import { BusProvider } from '@src/common/contexts/bus';
import { EventBus } from '@src/common/libs/EventBus';

import '@pushwoosh/ui/lib/styles.css';
import { GlobalPWKitFontFamilyStyle, usePWKitFontFamily } from '@pushwoosh/pwkit-typography';

// Container Components
import { PageJourneyEditor } from '@src/containers/components/PageJourneyEditor';
import { PageJourneyList } from '@src/ui/pages/PageJourneyList';
import { ModalManager } from '@src/ui/features/Modals/ModalManager';
import { Tooltips } from '@src/containers/components/Tooltips';
import { App } from '@src/containers/components/App';

// Theme
import { ThemeType } from '@pushwoosh/ui/lib/themes';

// Store
import { createStoreWithInjections, Provider as ProviderStore } from '@src/store';

// Utils
import { history } from '@src/history';
import { initLogger } from '@src/common/logger';
import { initMixpanel } from '@src/common/analytics';

// Types
import { SessionStore } from '@pushwoosh/session-store';
import { IUniqueId } from './common/types/entities';
import { JourneyStatus } from './common/constants';

type TPropsRouteJourney = RouteComponentProps<{ id: IUniqueId['id']; status: JourneyStatus }>;

const valueContext: ThemeType = { theme: 'default' };
const { Provider: ThemeProvider } = React.createContext<ThemeType>(valueContext);

initLogger();
initMixpanel();

function PageJourneyWithRoute(props: TPropsRouteJourney): JSX.Element {
  return (
    <>
      <PageJourneyEditor journeyId={props.match.params.id} />
      <ModalManager />
    </>
  );
}

const api = createApi(history);
const rpc = createRPC(api);
const store = createStoreWithInjections({ api, rpc });
const bus = new EventBus();
const sessionStore = new SessionStore(api);

function Main(): JSX.Element {
  usePWKitFontFamily();

  return (
    <HttpClientProvider value={api}>
      <BusProvider value={bus}>
        <RpcProvider value={rpc}>
          <HistoryProvider value={history}>
            <ProviderStore store={store}>
              <ThemeProvider value={valueContext}>
                <Router history={history}>
                  <App sessionStore={sessionStore}>
                    <Switch>
                      <Route path="/journeys/list" component={PageJourneyList} exact />
                      <Route path="/journeys/:status/:id" render={PageJourneyWithRoute} exact />
                      <Redirect to="/journeys/list" />
                    </Switch>
                    <Tooltips />
                    <GlobalPWKitFontFamilyStyle />
                  </App>
                </Router>
              </ThemeProvider>
            </ProviderStore>
          </HistoryProvider>
        </RpcProvider>
      </BusProvider>
    </HttpClientProvider>
  );
}

const app = document.getElementById('app');
if (app !== null) {
  ReactDOM.render(<Main />, app);
}
