import React from 'react';
import { format, sub } from 'date-fns';
import { InfoRoundIcon } from '@pushwoosh/kit-icons';

// Constants
import { RepeatFrequency, RepeatType } from '@src/common/constants/repeatSegment';

// Components
import { Button, InputDatepicker, Message, Switch } from '@src/ui/kit';
import { Icon, IconEnum } from '@src/ui/kit/Icon';
import { FormChoiceGroup, FormDaypicker, FormNumberStepper, FormSelect } from '@src/ui/form';

// Helpers
import { makeTextRepeatFrequency, makeTextRepeatType } from '@src/ui/helpers';
import { TimeField } from '@src/ui/features/StartSegment/components/TimeField';
import { useCloseAllModals, useOpenNewModal, useRestrictions } from '@src/store/hooks';
import { Color } from '@pushwoosh/kit-constants';
import { FormBlock } from '@src/ui/form/styled';
import { Checkbox } from '@pushwoosh/kit-checkbox';
import { Paragraph } from '@pushwoosh/kit-typography';
import { JourneyStatus, ModalType } from '@src/common/constants';
import { sendMixpanelEvent } from '@src/common/analytics';
import { PremiumFeatures } from '@src/ui/features/Modals/PremiumModal/types';
import { getOptionsByType } from './helpers/getOptionsByType';
import { getRepeatDescription } from './helpers/getRepeatDescription';

// Constants
import { WeeklyValues } from './constants';

// Styled
import {
  DatepickerWrap,
  DatesSpecifiedWrap,
  Description,
  IconWrap,
  RepeatDaypickerWrap,
  RepeatDescription,
  RepeatEveryWrap,
  RepeatFrequencyWrap,
  RepeatSettings,
  RepeatTypeWrap,
  StyledMessageIconWrapper,
  StyledPremiumIconWrapper
} from './styled';

// Types
import { IProps } from './ScheduledLaunch.types';

const repeatTypesList = Object.values(RepeatType).map((item) => ({
  value: item,
  label: makeTextRepeatType(item)
}));

const frequencyList = Object.values(RepeatFrequency)
  .filter((item) => item !== RepeatFrequency.DATES)
  .map((item) => ({
    value: item,
    label: makeTextRepeatFrequency(item)
  }));

export function ScheduledLaunch(props: IProps): JSX.Element {
  const {
    onSubmit,
    names,
    values,
    setFieldValue,
    setValues,
    setFieldTouched,
    isDisabled,
    journeyStatus
  } = props;

  const { allowCjScheduledSegment } = useRestrictions();

  const openNewModal = useOpenNewModal();
  const closeAllModals = useCloseAllModals();

  const isFeatureDisabled = isDisabled || !allowCjScheduledSegment;

  const toggleRepeat = React.useCallback(
    (value: boolean) => {
      setFieldValue(names.repeatEnabled, value);
    },
    [names.repeatEnabled, setFieldValue]
  );

  const updateRepeatType = React.useCallback(
    (_, repeatType: RepeatType) => {
      const repeatOptions = getOptionsByType(repeatType);

      setValues({
        ...values,
        [names.repeatEvery]: repeatOptions.repeatEvery,
        [names.repeatFrequency]: repeatOptions.repeatFrequency,
        [names.repeatDates]: repeatOptions.repeatDates,
        [names.repeatType]: repeatOptions.repeatType
      });
    },
    [setValues, values, names]
  );

  const updateEvery = React.useCallback(
    (value: number) => {
      setFieldValue(names.repeatEvery, value);
    },
    [setFieldValue, names.repeatEvery]
  );

  const updateRepeatFrequency = React.useCallback(
    (_, value: RepeatFrequency) => {
      setFieldValue(names.repeatFrequency, value);
      setFieldValue(names.repeatDates, []);
    },
    [setFieldValue, names.repeatFrequency, names.repeatDates]
  );

  const updateDatesSpecified = React.useCallback(
    (value: Date, index: number) => {
      const newDates = [...values.repeatDates];

      newDates[index] = format(value, 'yyyy-MM-dd');

      setFieldValue(names.repeatDates, newDates);
    },
    [setFieldValue, names.repeatDates, values.repeatDates]
  );

  const addDate = React.useCallback(() => {
    const newDates = [...values.repeatDates, ''];

    setFieldValue(names.repeatDates, newDates);
  }, [setFieldValue, names.repeatDates, values.repeatDates]);

  const removeDate = React.useCallback(
    (index: number) => {
      const newDates = values.repeatDates.filter((_, indexItem) => index !== indexItem);

      setFieldValue(names.repeatDates, newDates);
    },
    [setFieldValue, names.repeatDates, values.repeatDates]
  );

  const handleContactSalesClick = () => {
    onSubmit();
    closeAllModals();
    openNewModal({
      type: ModalType.PREMIUM,
      data: {
        premiumType: PremiumFeatures.SCHEDULED
      }
    });
    sendMixpanelEvent({
      eventName: 'JourneyCanvasRestrictionButtonClicked',
      eventData: {
        point: 'Scheduled Launch'
      }
    });
  };

  return (
    <>
      <Switch
        defaultEnabled={values.repeatEnabled}
        onChange={toggleRepeat}
        isDisabled={isFeatureDisabled}
      >
        Scheduled Launch
        {!allowCjScheduledSegment && (
          <Button
            color="primary"
            type="button"
            onClick={handleContactSalesClick}
            view="ghost"
            size="compact"
            iconType={IconEnum.OPEN_SMALL_LINED}
          >
            {/* <Icon type={IconEnum.OPEN_SMALL_LINED} /> */}
            CONTACT SALES
          </Button>
        )}
      </Switch>
      <Description>
        Save your time and efforts with automated journeys based on your segments. Adjust the
        <br />
        frequency of getting users from a segment to a journey.
      </Description>
      {!allowCjScheduledSegment && (
        <Message
          icon={
            <StyledPremiumIconWrapper>
              <Icon type={IconEnum.PREMIUM_MONO} width={16} height={16} />
            </StyledPremiumIconWrapper>
          }
          title="Upgrade your plan to use this feature with a journey "
          color={Color.BRIGHT_LIGHT}
          fullWidth
        />
      )}
      {values.repeatEnabled && (
        <>
          <TimeField
            values={values}
            names={names}
            setFieldValue={setFieldValue}
            isDisabled={isFeatureDisabled}
          />
          <RepeatSettings>
            <RepeatTypeWrap>
              <FormSelect
                label="Launch period"
                name={names.repeatType}
                options={repeatTypesList}
                value={values.repeatType}
                onBlur={setFieldTouched}
                onChange={updateRepeatType}
                isDisabled={isFeatureDisabled}
              />
            </RepeatTypeWrap>
            {values.repeatType === RepeatType.CUSTOM && (
              <>
                <RepeatEveryWrap>
                  <FormNumberStepper
                    label="Every"
                    name={names.repeatEvery}
                    value={values.repeatEvery}
                    min={1}
                    step={1}
                    onChange={updateEvery}
                    isDisabled={isFeatureDisabled}
                  />
                </RepeatEveryWrap>
                <RepeatFrequencyWrap>
                  <FormSelect
                    name={names.repeatFrequency}
                    value={values.repeatFrequency as RepeatFrequency}
                    options={frequencyList}
                    onBlur={setFieldTouched}
                    onChange={updateRepeatFrequency}
                    isDisabled={isFeatureDisabled}
                  />
                </RepeatFrequencyWrap>
              </>
            )}
          </RepeatSettings>
          {values.repeatType === RepeatType.CUSTOM &&
            values.repeatFrequency === RepeatFrequency.WEEKLY && (
              <FormChoiceGroup
                name={names.repeatDates}
                label="On these days:"
                activeValues={values.repeatDates as string[]}
                defaultValues={WeeklyValues}
                setFieldValue={setFieldValue}
                isDisabled={isFeatureDisabled}
              />
            )}
          {values.repeatType === RepeatType.CUSTOM &&
            values.repeatFrequency === RepeatFrequency.MONTHLY && (
              <RepeatDaypickerWrap>
                <FormDaypicker
                  label="At these dates:"
                  values={values.repeatDates as string[]}
                  name={names.repeatDates}
                  setFieldValue={setFieldValue}
                  isDisabled={isFeatureDisabled}
                />
              </RepeatDaypickerWrap>
            )}
          {values.repeatType === RepeatType.DATES_SPECIFIED && (
            <>
              <DatesSpecifiedWrap>
                {values.repeatDates.map((date: string, index: number) => {
                  const value = date && new Date(date);
                  const minDate = sub(new Date(), { days: 1 });

                  return (
                    <DatepickerWrap key={index}>
                      <InputDatepicker
                        onChange={(value) => updateDatesSpecified(value, index)}
                        value={value}
                        minDate={minDate}
                        isDisabled={isFeatureDisabled}
                      />
                      {values.repeatDates.length > 1 && (
                        <IconWrap onClick={() => removeDate(index)}>
                          <Icon type={IconEnum.CLOSE_SMALL} />
                        </IconWrap>
                      )}
                    </DatepickerWrap>
                  );
                })}
              </DatesSpecifiedWrap>
              <Button
                iconType={IconEnum.PLUS_MEDIUM}
                color="secondary"
                view="ghost"
                iconPosition="left"
                onClick={addDate}
                disabled={isFeatureDisabled}
              >
                Add Date
              </Button>
            </>
          )}
          {values.repeatType !== RepeatType.DATES_SPECIFIED && (
            <RepeatDescription>
              {getRepeatDescription(values.repeatType, values.repeatFrequency)}
            </RepeatDescription>
          )}
        </>
      )}
      {journeyStatus === JourneyStatus.PAUSED && values.repeatEnabled !== true && (
        <>
          <Message
            title={`The campaign ${
              values.isDeactivated ? "won't" : 'will'
            } be launched as a one-time`}
            message="The launch will occur for the entire segment, since you disabled the previous schedule. You can start the campaign again for the entire segment, or end the campaign with current users and not let in new users"
            color={Color.BRIGHT_LIGHT}
            icon={
              <StyledMessageIconWrapper>
                <InfoRoundIcon size="small" view="filled" color={Color.BRIGHT} />
              </StyledMessageIconWrapper>
            }
          />
          <FormBlock>
            <Checkbox
              name={names.isDeactivated}
              value={!values.isDeactivated}
              onChange={(bag) => setFieldValue(names.isDeactivated, bag.value)}
              isChecked={!values.isDeactivated}
            >
              <Paragraph>Launch one-time аfter resuming campaign</Paragraph>
            </Checkbox>
          </FormBlock>
        </>
      )}
    </>
  );
}
