import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgFilterSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm-6 2h3.354a2.751 2.751 0 005.292 0H13a.75.75 0 000-1.5h-1.354a2.751 2.751 0 00-5.292 0H3a.75.75 0 000 1.5zm.354 4.5H3a.75.75 0 000 1.5h.354a2.751 2.751 0 005.292 0H13a.75.75 0 000-1.5H8.646a2.751 2.751 0 00-5.292 0zM7.25 11a1.25 1.25 0 10-2.5 0 1.25 1.25 0 002.5 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgFilterSmallLined;
