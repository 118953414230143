import { action } from 'easy-peasy';

// Types
import { IAssistant } from './assistant.types';

export const model: IAssistant = {
  isLaunchReady: false,
  setLaunchReady: action((state, payload): void => {
    state.isLaunchReady = payload;
  })
};
