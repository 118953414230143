import styled from 'styled-components';
import { Paragraph } from '@pushwoosh/kit-typography';
import { Spacing, Color, ShapeRadius } from '@pushwoosh/kit-constants';

export const IconWrap = styled.button`
  cursor: pointer;
  color: ${Color.PHANTOM};
  margin-left: auto;
  background-color: transparent;
  border: none;
  outline: transparent;

  &:hover {
    color: ${Color.DANGER};
  }
`;

type Props = {
  isPressed?: boolean;
  isDeleteHovered: boolean;
};

export const Number = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isDeleteHovered }) => (isDeleteHovered ? Color.DANGER : Color.BRIGHT)};
  color: ${Color.CLEAR};
  border-radius: ${ShapeRadius.CONTROL};
  width: 24px;
  height: 24px;
  font-size: 12px;
  font-weight: 500;
  margin-right: ${Spacing.S4};
`;

export const Container = styled.div<Props>`
  padding: ${Spacing.S5} ${Spacing.S6};
  border-left: 4px solid ${Color.BRIGHT};
  background-color: ${Color.BRIGHT_LIGHT};
  cursor: pointer;

  opacity: ${({ isPressed }) => (isPressed ? '0.5' : '1')};

  &:hover {
    border-left: 4px solid
      ${({ isDeleteHovered }) => (isDeleteHovered ? Color.DANGER : Color.BRIGHT)};
    background-color: ${({ isDeleteHovered }) =>
      isDeleteHovered ? Color.DANGER_LIGHT : Color.BRIGHT_LIGHT};
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: ${Spacing.S3};
`;

export const Date = styled(Paragraph)`
  color: ${Color.LOCKED};
  margin-right: ${Spacing.S4};
`;

export const Message = styled.textarea`
  margin-left: ${Spacing.S10};
  background: transparent;
  border: none;
  outline: transparent;
  color: ${Color.MAIN};
  caret-color: ${Color.BRIGHT};
`;

export const ButtonWrap = styled.div`
  margin-left: ${Spacing.S7};
`;
