// Enums
import { JourneyStatus } from '@src/common/constants/journey';

// Types
import { TJourney } from '@src/common/types/journey';

export function makeTextStatusJourney(status: TJourney['status']): string {
  switch (status) {
    case JourneyStatus.RUNNING: {
      return 'Active';
    }

    case JourneyStatus.FINISHED: {
      return 'Inactive';
    }

    case JourneyStatus.ARCHIVED: {
      return 'Archived';
    }

    case JourneyStatus.PAUSED: {
      return 'Paused';
    }

    default: {
      return 'Draft';
    }
  }
}
