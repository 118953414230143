import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgTrashSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 4.5A.498.498 0 013 4v-.333a.5.5 0 01.5-.5h2.658a.5.5 0 00.312-.11l.56-.447a.5.5 0 01.312-.11h1.316a.5.5 0 01.312.11l.56.447a.5.5 0 00.312.11H12.5a.5.5 0 01.5.5V4a.5.5 0 01-.5.5h-9zM12 6a.5.5 0 00-.5-.5h-7A.5.5 0 004 6v5.5a2 2 0 002 2h4a2 2 0 002-2V6zM5.5 7v4.5a.5.5 0 00.5.5h4a.5.5 0 00.5-.5V7h-5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgTrashSmallLined;
