import React from 'react';
import { Icon, IconEnum, Modal } from '@src/ui/kit';
import { getModalDescription, getModalTitle } from '@src/ui/features/Modals/PremiumModal/helpers';
import { PremiumModalType } from '@src/ui/features/Modals/PremiumModal/types';
import { useOpenNewModal, useUser } from '@src/store/hooks';
import { Color } from '@pushwoosh/kit-constants';
import { ModalType } from '@src/common/constants';
import { useCloseModalByType } from '@src/store/hooks/modals/useCloseModalByType';
import { sendMixpanelEvent } from '@src/common/analytics';
import {
  CloseButton,
  CurrentPlan,
  Description,
  Feature,
  GrayCheck,
  GreenCheck,
  ModalTitle,
  Plan,
  PlanBody,
  PlanHeader,
  PlansWrapper,
  StyledButton,
  StyledLink
} from './styled';

interface IPremiumModalProps {
  premiumType: PremiumModalType;
}

export function PremiumModal(props: IPremiumModalProps) {
  const { premiumType } = props;
  const { subscriptionPlan } = useUser();
  const openNewModal = useOpenNewModal();
  const closeModal = useCloseModalByType();

  React.useEffect(() => {
    sendMixpanelEvent({
      eventName: 'CJPaidFeatureModalOpened',
      eventData: {
        feature: premiumType
      }
    });
  }, []);

  const handleClose = () => {
    closeModal(ModalType.PREMIUM);
  };

  const handleClick = () => {
    handleClose();
    openNewModal({
      type: ModalType.CONTACT_SALES
    });
    sendMixpanelEvent({
      eventName: 'CJPaidFeatureContactSalesClicked',
      eventData: {
        feature: premiumType
      }
    });
  };

  return (
    <Modal
      title={
        <ModalTitle>
          {getModalTitle(premiumType)}
          <CloseButton onClick={handleClose}>
            <Icon type={IconEnum.CLOSE_MEDIUM} fill={Color.MAIN} />
          </CloseButton>
        </ModalTitle>
      }
      widthType="wide"
      isOpen
    >
      <Description>{getModalDescription(premiumType)}</Description>
      <PlansWrapper>
        <Plan>
          <PlanHeader>
            {subscriptionPlan}
            <CurrentPlan>Current plan</CurrentPlan>
          </PlanHeader>
          <PlanBody>
            <Feature>
              <GrayCheck type={IconEnum.CHECK_SMALL} fill={Color.LOCKED} />
              One-time launch by segment
            </Feature>
            <Feature>
              <GrayCheck type={IconEnum.CHECK_SMALL} fill={Color.LOCKED} />
              Launch by event trigger
            </Feature>
            <Feature>
              <GrayCheck type={IconEnum.CHECK_SMALL} fill={Color.LOCKED} />
              User-based personalization
            </Feature>
            <Feature>
              <GrayCheck type={IconEnum.CHECK_SMALL} fill={Color.LOCKED} />
              Push and email messages
            </Feature>
            <Feature>
              <GrayCheck type={IconEnum.CHECK_SMALL} fill={Color.LOCKED} />
              Fixed time delay
            </Feature>
            <Feature>
              <GrayCheck type={IconEnum.CHECK_SMALL} fill={Color.LOCKED} />
              Basic journey steps and settings
            </Feature>
          </PlanBody>
        </Plan>

        <Plan>
          <PlanHeader>
            Custom plan <Icon type={IconEnum.PREMIUM_ENABLED} />
          </PlanHeader>
          <PlanBody>
            <Feature>
              <GreenCheck type={IconEnum.CHECK_SMALL} fill={Color.CLEAR} />
              Scheduled launches by segment
            </Feature>
            <Feature>
              <GreenCheck type={IconEnum.CHECK_SMALL} fill={Color.CLEAR} />
              Launch by external trigger via webhook (with segments specified)
            </Feature>
            <Feature>
              <GreenCheck type={IconEnum.CHECK_SMALL} fill={Color.CLEAR} />
              <span>Modify message texts via event attributes or API requests</span>
            </Feature>
            <Feature>
              <GreenCheck type={IconEnum.CHECK_SMALL} fill={Color.CLEAR} />
              In-app messages and SMS
            </Feature>
            <Feature>
              <GreenCheck type={IconEnum.CHECK_SMALL} fill={Color.CLEAR} />
              Dynamic time delay
            </Feature>
            <Feature>
              <GreenCheck type={IconEnum.CHECK_SMALL} fill={Color.CLEAR} />
              Outbound webhooks
            </Feature>

            <StyledLink
              isExternal
              to="https://www.pushwoosh.com/pushwoosh_detailed_pricing.pdf"
              target="_blank"
            >
              See all features
              <Icon type={IconEnum.ARROW_RIGHT_MEDIUM} fill={Color.PRIMARY} />
            </StyledLink>

            <StyledButton onClick={handleClick}>Contact sales</StyledButton>
          </PlanBody>
        </Plan>
      </PlansWrapper>
    </Modal>
  );
}
