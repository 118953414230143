import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgLimitMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.75 3.78V6a.75.75 0 01-1.5 0V3.78a9.21 9.21 0 00-5.239 2.17l1.52 1.52A.75.75 0 016.47 8.53L4.95 7.011a9.21 9.21 0 00-2.17 5.239H5a.75.75 0 010 1.5H2.78a9.22 9.22 0 002.745 5.856.75.75 0 11-1.05 1.071A10.72 10.72 0 011.25 13C1.25 7.063 6.063 2.25 12 2.25S22.75 7.063 22.75 13c0 2.93-1.173 5.587-3.073 7.525a.75.75 0 01-1.071-1.05 9.217 9.217 0 002.614-5.725H19a.75.75 0 010-1.5h2.22a9.21 9.21 0 00-2.17-5.239l-1.52 1.52a.75.75 0 11-1.06-1.061l1.519-1.52a9.21 9.21 0 00-5.239-2.17z"
        fill={props.fill}
      />
      <path
        d="M12 15c.277 0 .541-.056.781-.158l2.689 2.688a.75.75 0 101.06-1.06l-2.688-2.689A2 2 0 1012 15z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgLimitMediumLined;
