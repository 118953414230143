import Konva from 'konva';

// Types
import { IVector2 } from '@src/common/types/types';

export function getCorrectPointPosition(
  stage: Konva.Stage,
  pointPosition: IVector2,
  scale: IVector2 | void
): IVector2 {
  const scaleX = !scale ? stage.scaleX() : scale.x;
  const scaleY = !scale ? stage.scaleY() : scale.y;

  const x = pointPosition.x / scaleX - stage.x() / scaleX;
  const y = pointPosition.y / scaleY - stage.y() / scaleY;

  return { x, y };
}
