import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPlatformLogoChromeMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 7.957a4.05 4.05 0 00-3.95 3.179L5.167 6.142A8.941 8.941 0 0112 3c2.404 0 4.664.936 6.364 2.636a9.02 9.02 0 011.682 2.321H12z"
        fill={props.fill}
      />
      <path d="M14.988 12a2.988 2.988 0 11-5.976 0 2.988 2.988 0 015.976 0z" fill={props.fill} />
      <path
        d="M13.226 15.852a4.046 4.046 0 01-4.753-1.875L4.477 7.055A8.937 8.937 0 003 12c0 2.404.936 4.664 2.636 6.364a8.935 8.935 0 004.706 2.484l2.884-4.995z"
        fill={props.fill}
      />
      <path
        d="M16.043 12c0-1.183-.51-2.248-1.323-2.988h5.774A9.01 9.01 0 0121 12a8.941 8.941 0 01-2.636 6.364 8.941 8.941 0 01-6.883 2.62l3.997-6.923.007-.013.004-.007c.352-.6.554-1.297.554-2.041z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPlatformLogoChromeMediumFilled;
