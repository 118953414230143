import React, { useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';

// Analytics
import { sendMixpanelEvent } from '@src/common/analytics';
import { SidebarType } from '@src/common/constants/sidebars';

// Store Hooks
import {
  useCurrentJourneyId,
  useEditJourneyById,
  useGetJourneyById,
  useGetPointById,
  useJourneyAppCode,
  useOpenNewModal,
  useRestrictions,
  useUpdatePoint
} from '@src/store/hooks';
import { useStoreActions, useStoreState } from '@src/store/store';

// Containers
// UI Components
import { Button, IconEnum, Section } from '@src/ui/kit';
import { Paragraph } from '@pushwoosh/kit-typography';
import { Modal } from '@src/ui/kit/Modal';
import { DefaultButtons } from '@src/ui/kit/ModalButtons';
import { CollapseIcon, ExpandIcon } from '@pushwoosh/kit-icons';

// Container Hooks
// UI Hooks
import { useForm } from '@src/ui/hooks';

// Types
import { TWaitEventPoint } from '@src/common/types/points';
import { IUniqueId } from '@src/common/types/entities';
import { ICommonEvent } from '@src/common/types/event';
import { JourneyStatus, ModalType } from '@src/common/constants';
import { createLeaf, generateLeafKey, LeafBase } from '@src/common/structureObjects/leaf';

// Helpers
import { countErrors } from '@src/common/helpers/formikErrors';
import { validate } from '../validation';

// Styled
import { WaitingPeriodType, WaitPeriodTime } from '../components/WaitPeriodTime';
import { EventBranches } from '../components/EventBranches';
import { ButtonsWrap, ErrorsCounter, RightFooter } from './styled';

interface IProps {
  pointId: IUniqueId['id'];
  closeModal: () => void;
}

const MIN_DELAY = 86400;

export function WaitEvent(props: IProps): JSX.Element {
  const { pointId, closeModal } = props;
  const [saveDisabled, setSaveDisabled] = React.useState(false);

  const journeyId = useCurrentJourneyId();
  const applicationCode = useJourneyAppCode(journeyId);
  const showDocumentationMenu = useStoreActions((actions) => actions.documentationMenu.show);
  const editPoint = useStoreActions((actions) => actions.points.update);
  const handleEditJourneyById = useEditJourneyById();
  const openNewModal = useOpenNewModal();
  const sidebars = useStoreState((state) => state.sidebars);
  const openNewSidebar = useStoreActions((actions) => actions.sidebars.openNewSidebar);
  const closeAllSidebars = useStoreActions((actions) => actions.sidebars.closeAllSidebars);

  const getPointById = useGetPointById();
  const updatePoint = useUpdatePoint();
  const { allowCjAddBranchesInWaitForTrigger } = useRestrictions();

  const getJourneyById = useGetJourneyById();

  const { status } = getJourneyById(journeyId);

  const point = getPointById(pointId) as TWaitEventPoint;

  const isJourneyActive = status === JourneyStatus.RUNNING;

  const initBranches = useMemo(
    () =>
      LeafBase.getBranchesFromJsom(point.data.waitEventsConditionsScript, point.data.waitEvents),
    [point.data.waitEventsConditionsScript, point.data.waitEvents]
  );

  const initialValues = useMemo(
    () => ({
      title: point.title,
      branches: initBranches,
      isFixedDelay:
        point.data.waitEvents && point.data.waitEvents[0] && point.data.waitEvents[0].isFixedDelay
          ? point.data.waitEvents[0].isFixedDelay
          : false,
      delay:
        point.data.waitEvents && point.data.waitEvents[0] && point.data.waitEvents[0].delay
          ? point.data.waitEvents[0].delay
          : MIN_DELAY
    }),
    [point, initBranches]
  );

  const initialErrors = useMemo(() => validate(initialValues), [initialValues]);

  const { values, errors, setFieldValue, setFieldTouched, handleChange, handleBlur } =
    useForm<WaitingPeriodType>({
      validate,
      initialValues,
      initialErrors,
      onSubmit: null
    });

  const handleSubmit = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();

      const eventsList: ICommonEvent[] = [];

      values.branches.forEach((branch) => {
        branch.events.forEach((event) => {
          // we calculate new event index in array of all events
          // and set it to operation.toIndex;
          // this field gets updated in branches too
          // because we do not copy it, we just pass reference
          const eventIndex = eventsList.length;
          event.operation = {
            ...event.operation,
            toIndex: eventIndex
          };
          eventsList.push(event);
        });
      });

      const leaf = createLeaf(values.branches);
      const waitEventsConditionsScript = leaf.toJson();

      const newPoint = {
        ...point,
        title: values.title,
        data: {
          waitEvents: eventsList.map((item) => ({
            delay: values.delay,
            isFixedDelay: values.isFixedDelay,
            applicationCode,
            name: item.name,
            eventConditions: item.eventConditions
          })),
          waitEventsConditionsScript
        }
      };
      const isChanged = !isEqual(newPoint.data, point.data);

      if (!isJourneyActive) {
        updatePoint(newPoint);

        if (eventsList[0].name) {
          sendMixpanelEvent({
            eventName: 'WaitForEventConfigured',
            eventData: {
              EventConditionAdded: eventsList[0].eventConditions.length > 0
            }
          });
        }

        closeModal();
      } else {
        editPoint(newPoint);

        handleEditJourneyById(journeyId);

        sendMixpanelEvent({
          eventName: 'JourneyEditModeChangesApplied',
          eventData: {
            PointType: 'WaitEvent'
          }
        });

        closeModal();

        openNewModal({
          type: ModalType.DETAIL_POINT,
          data: {
            pointId,
            isChanged
          }
        });
      }
    },
    [
      values,
      applicationCode,
      closeModal,
      editPoint,
      handleEditJourneyById,
      isJourneyActive,
      journeyId,
      openNewModal,
      point,
      pointId,
      updatePoint
    ]
  );

  const handleAddBranch = React.useCallback(() => {
    if (values.branches.length >= 3) return;

    const newBranches = [
      ...values.branches.map((b) => ({ ...b, open: false })),
      {
        title: `Trigger group ${values.branches.length + 1}`,
        open: true,
        events: [],
        key: generateLeafKey()
      }
    ];

    setFieldValue('branches', newBranches);

    sendMixpanelEvent({
      eventName: 'CustomerJourneyWaitFotTriggerBranchAdded'
    });
  }, [setFieldValue, values.branches]);

  const handleCollapseAll = React.useCallback(() => {
    const newBranches = values.branches.map((b) => ({ ...b, open: false }));
    setFieldValue('branches', newBranches);
  }, [setFieldValue, values.branches]);

  const handleExpandAll = React.useCallback(() => {
    const newBranches = values.branches.map((b) => ({ ...b, open: true }));
    setFieldValue('branches', newBranches);
  }, [setFieldValue, values.branches]);

  const handleCancel = React.useCallback(() => {
    const { waitEvents = [] } = point.data;
    const listEvents = [] as ICommonEvent[];

    values.branches.forEach((branch) => {
      branch.events.forEach((event) => listEvents.push(event));
    });

    const titleChanged = values.title !== point.title;
    const delayChanged = values.delay !== waitEvents[0]?.delay;
    let eventsChanged = waitEvents.length !== listEvents.length;

    if (!eventsChanged) {
      eventsChanged = !isEqual(waitEvents, listEvents);
    }

    const eventData = {
      PointType: 'WaitEvent',
      PointChanged: titleChanged || delayChanged || eventsChanged
    };

    if (status === JourneyStatus.RUNNING) {
      sendMixpanelEvent({
        eventName: 'JourneyEditModeChangesCancelled',
        eventData: {
          PointType: 'ConversionGoals'
        }
      });
    } else {
      sendMixpanelEvent({
        eventName: 'ConfigurePointCancelled',
        eventData
      });
    }

    closeModal();
  }, [closeModal, values, point.data, point.title, status]);

  const onShowDocumentation = React.useCallback((): void => {
    if (sidebars.isOpened) {
      closeAllSidebars();
    } else {
      openNewSidebar({
        type: SidebarType.DOCUMENTATION
      });
      showDocumentationMenu();
      sendMixpanelEvent({ eventName: 'ShowDocumentation' });
    }
  }, [showDocumentationMenu, sidebars, closeAllSidebars, openNewSidebar]);

  const allCollapsed = values.branches.every((branch) => !branch.open);

  const errorsNumber = useMemo(() => countErrors(errors), [errors]);
  const showErrors = errorsNumber > 0 && status !== JourneyStatus.RUNNING;
  const showErrorsSuffix = errorsNumber > 1 ? 'errors' : 'error';

  return (
    <Modal
      title="Wait for Trigger"
      footerRight={
        <RightFooter>
          {showErrors && (
            <ErrorsCounter>
              {errorsNumber} {showErrorsSuffix}
            </ErrorsCounter>
          )}
          <DefaultButtons
            isDisabledActionButton={saveDisabled}
            onClickActionButton={handleSubmit}
            onClickCancelButton={handleCancel}
            actionButtonName={status === JourneyStatus.RUNNING ? 'Save' : 'Apply'}
          />
        </RightFooter>
      }
      footerLeft={
        <ButtonsWrap>
          <Button
            color="primary"
            size="field"
            view="ghost"
            iconPosition="left"
            iconType={
              allowCjAddBranchesInWaitForTrigger ? IconEnum.PLUS_MEDIUM : IconEnum.PREMIUM_MONO
            }
            onClick={handleAddBranch}
            disabled={!allowCjAddBranchesInWaitForTrigger || values.branches.length >= 3}
          >
            Add branch
          </Button>

          {allCollapsed ? (
            <Button color="primary" size="field" view="ghost" onClick={handleExpandAll}>
              <ExpandIcon size="small" view="lined" />
              &nbsp;&nbsp;Expand all
            </Button>
          ) : (
            <Button color="primary" size="field" view="ghost" onClick={handleCollapseAll}>
              <CollapseIcon size="small" view="lined" />
              &nbsp;&nbsp;Collapse all
            </Button>
          )}

          <Button
            color="primary"
            size="field"
            view="ghost"
            iconPosition="left"
            iconType={IconEnum.DOCS_MEDIUM_LINED}
            onClick={onShowDocumentation}
          >
            How to Use
          </Button>
        </ButtonsWrap>
      }
      isOpen
    >
      <Section direction="column">
        <Paragraph>
          Set a specific event (or events) that must happen before moving forward with the journey,
          and a&nbsp;period of time after which the user will continue the journey (if no events
          were triggered).
        </Paragraph>
      </Section>
      <WaitPeriodTime
        names={{
          delay: 'delay',
          title: 'title',
          isFixedDelay: 'isFixedDelay',
          branches: 'branches'
        }}
        values={values}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        onBlurField={handleBlur}
        onChangeField={handleChange}
      />
      <EventBranches
        names={{
          delay: 'delay',
          title: 'title',
          isFixedDelay: 'isFixedDelay',
          branches: 'branches'
        }}
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        setSaveDisabled={setSaveDisabled}
        status={status}
      />
    </Modal>
  );
}
