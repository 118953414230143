import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgCommentMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.75c-3.998 0-7.25 3.27-7.25 7.319 0 2.299 1.007 3.9 2.483 5.3.747.709 1.606 1.357 2.514 2.016.17.124.344.248.518.374.577.415 1.166.84 1.735 1.282.569-.442 1.158-.867 1.735-1.282l.518-.374c.908-.66 1.767-1.307 2.514-2.017 1.476-1.4 2.483-3 2.483-5.299 0-4.048-3.252-7.319-7.25-7.319zm-8.75 7.319C3.25 6.204 7.161 2.25 12 2.25c4.838 0 8.75 3.954 8.75 8.819 0 2.837-1.282 4.805-2.95 6.388-.825.782-1.752 1.479-2.666 2.142l-.538.389c-.736.53-1.45 1.045-2.122 1.593a.75.75 0 01-.948 0c-.672-.548-1.386-1.063-2.122-1.593-.178-.129-.358-.258-.538-.39-.914-.662-1.841-1.36-2.666-2.141-1.668-1.583-2.95-3.551-2.95-6.388z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 13a.75.75 0 01.75-.75h4a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM8.25 10A.75.75 0 019 9.25h6a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgCommentMediumLined;
