import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgWaitForTriggerPointFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={24} height={24} rx={4} fill="#00A2FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.616 10.258c-.041-.197-.313-.217-.383-.03l-.748 1.993A1.2 1.2 0 018.361 13H5.2a.2.2 0 01-.2-.2v-1.6c0-.11.09-.2.2-.2h2.468a.2.2 0 00.188-.13L9.57 6.295c.422-1.126 2.053-1 2.299.177l1.514 7.27c.041.197.313.217.383.03l.748-1.993A1.2 1.2 0 0115.639 11H18.8c.11 0 .2.09.2.2v1.6a.2.2 0 01-.2.2h-2.468a.2.2 0 00-.188.13l-1.715 4.575c-.422 1.126-2.053 1-2.299-.177l-1.514-7.27z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgWaitForTriggerPointFilled;
