import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';
import { Paragraph } from '@pushwoosh/kit-typography';

export const Container = styled.div`
  width: 100%;
`;

export const Labels = styled.div`
  display: flex;
  margin-bottom: ${Spacing.S1};
`;

export const Tag = styled.div`
  display: flex;
  margin-bottom: ${Spacing.S5};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Name = styled.div`
  min-width: 308px;
  margin-right: ${Spacing.S3};

  .customSelect__control {
    min-width: 120px;
  }
`;

export const Value = styled.div`
  width: 100%;
`;

export const Remove = styled.div`
  align-self: center;
  margin-left: ${Spacing.S4};
`;

export const Controls = styled.div`
  margin-top: ${Spacing.S5};
  margin-left: -${Spacing.S4};
`;

export const Description = styled(Paragraph)`
  margin-top: ${Spacing.S3};
  margin-bottom: ${Spacing.S5};
`;
