import { TPoints } from '@src/common/types/points';
import { TComment } from '@src/common/types/comments';

// Constants
import {
  GroupSizeContent,
  PointType,
  DEFAULT_POINT_BLOCK_OFFSET
} from '@src/common/constants/point';

// Helper
import { getSizePoint } from '@src/common/helpers';

import { isCollide } from './isCollide';

const getWidthPoint = (point: TPoints): number => {
  const { widthPoint } = getSizePoint(point.type);
  return widthPoint;
};

const getHeightPoint = (point: TPoints): number => {
  const { heightPoint } = getSizePoint(point.type);
  if (point.type === PointType.BOOLEAN_SPLITTER || point.type === PointType.AB_SPLITTER) {
    return GroupSizeContent.HEIGHT * 2 + DEFAULT_POINT_BLOCK_OFFSET * 3;
  }

  return heightPoint;
};

export const isOverlapPoints = (pointFirst: TPoints, pointSecond: TPoints): boolean =>
  isCollide(
    // first point
    pointFirst.position.x,
    pointFirst.position.y,
    getWidthPoint(pointFirst),
    getHeightPoint(pointFirst),

    // second point
    pointSecond.position.x,
    pointSecond.position.y,
    getWidthPoint(pointSecond),
    getHeightPoint(pointSecond)
  );

export const isOverlapComments = (commentFirst: TComment, commentSecond: TComment): boolean =>
  isCollide(
    // first point
    commentFirst.position.x,
    commentFirst.position.y,
    40,
    40,

    // second point
    commentSecond.position.x,
    commentSecond.position.y,
    40,
    40
  );

export const isOverlapPointsWithComments = (point: TPoints, comment: TComment): boolean =>
  isCollide(
    // first point
    point.position.x,
    point.position.y,
    getWidthPoint(point),
    getHeightPoint(point),

    // second point
    comment.position.x,
    comment.position.y,
    40,
    40
  );
