// Store
import { useStoreActions, useStoreState } from '@src/store/store';

// Types
import { TRequest } from '@src/store/models/requests/requests.types';
import { TRichMedia } from '@src/common/types/richMedia';

type TActionGet = (payload: {
  appCode: string;
  richMediaCode: string;
}) => Promise<{ code: string; richMedia?: TRichMedia }>;

type TResult = [TActionGet, boolean];

export function useLoadRichMediaWithContent(): TResult {
  const load: TActionGet = useStoreActions(
    (actions): TActionGet => actions.richMedias.loadWithContent
  );

  const request: TRequest = useStoreState(
    (state): TRequest => state.requests.entities['richMedias.loadByUid']
  );

  return [load, Boolean(request)];
}
