// Store
import { useStoreState } from '@src/store/store';
import { IUniqueId } from '@src/common/types/entities';

type TResult = IUniqueId['id'];

export function useCurrentJourneyId(): TResult {
  const currentJourneyId: IUniqueId['id'] = useStoreState(
    (state): IUniqueId['id'] => state.journeys.currentJourneyId
  );

  return currentJourneyId;
}
