import { action, computed } from 'easy-peasy';
import { makeUniqueId } from '@src/common/helpers/makeUniqueId';
import { IDictionary } from '@src/common/types/types';
import { TArrow } from '@src/common/types/arrow';
import { IUniqueId } from '@src/common/types/entities';
import { IArrowModel, DrawingArrowStatus } from './arrow.types';

const INITIAL_DRAWING_ARROW_STATE = {
  status: DrawingArrowStatus.IDLE,
  fromX: 0,
  fromY: 0,
  toX: 0,
  toY: 0,
  fromPointId: '',
  toPointId: '',
  outputKey: '',
  outputOrder: 0,
  outputStats: 0,
  pageX: 0,
  pageY: 0
};

export const model: IArrowModel = {
  drawingData: INITIAL_DRAWING_ARROW_STATE,
  entities: {},
  arrows: computed((state) => Object.values(state.entities)),
  isDrawingArrow: computed((state) => state.drawingData.status !== DrawingArrowStatus.IDLE),
  setArrowState: action((state, payload): void => {
    state.drawingData = { ...state.drawingData, ...payload };
  }),
  resetArrowState: action((state) => {
    state.drawingData = INITIAL_DRAWING_ARROW_STATE;
  }),
  addArrow: action((state, payload) => {
    state.entities = { ...state.entities, payload };
  }),
  removeArrow: action((state, payload) => {
    delete state.entities[payload];
  }),
  updateArrow: action((state, payload) => {
    if (Array.isArray(payload)) {
      payload.forEach((item) => {
        const { id, ...arrow } = item;

        state.entities[id] = { ...state.entities[id], ...arrow };
      });
    } else {
      state.entities[payload.id] = { ...state.entities[payload.id], ...payload };
    }
  }),
  setArrows: action((state, payload) => {
    state.entities = payload.reduce((entities, entity) => {
      const id = makeUniqueId() as string;

      return {
        ...entities,
        [id]: Object.assign({ id }, entity)
      };
    }, {} as IDictionary<TArrow & IUniqueId>);
  })
};
