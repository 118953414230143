import styled from 'styled-components';
import { FontSize } from '@pushwoosh/kit-constants';

import { IProps } from './ManageLink.types';

export const Wrap = styled.div<Pick<IProps, 'alignRight'>>`
  width: 100%;
  text-align: ${({ alignRight }) => (alignRight ? 'right' : 'left')};
  display: flex;
  align-items: center;
  font-size: ${FontSize.SMALL};
`;
