import React from 'react';

// Components
import { IconEnum, Icon } from '@src/ui/kit/Icon';
import { Button } from '@src/ui/kit';
import { Paragraph } from '@pushwoosh/kit-typography';

// Types
import { FormControlProps } from './FormControls.types';

// Styled
import { Header, ErrorMessage, ExternalLink, FormSelectWrap, ActionWrap } from './styled';

export function FormControl(props: FormControlProps): JSX.Element {
  const { label, url, error, actionText, children, onActionClick } = props;

  return (
    <FormSelectWrap>
      {(label || url) && (
        <Header>
          {label && (
            <Paragraph>
              <b>{label}</b>
            </Paragraph>
          )}
          {url && (
            <ExternalLink href={url} target="_blanc">
              <Icon type={IconEnum.OPEN_SMALL_LINED} />
            </ExternalLink>
          )}
          {actionText && onActionClick && (
            <ActionWrap>
              <Button color="secondary" size="compact" view="ghost" onClick={onActionClick}>
                {actionText}
              </Button>
            </ActionWrap>
          )}
        </Header>
      )}
      {children}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </FormSelectWrap>
  );
}
