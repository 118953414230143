import * as React from 'react';

// Store Hooks
import {
  useDragAndDropStore,
  useAutoCleanDragAndDropAtDragEnd,
  useAvailablePoints,
  useModeMap
} from '@src/store/hooks';

// UI Components
import { Sidebar as SidebarBase } from '../components/Sidebar';

export function JourneySidebar(): JSX.Element {
  const dragAndDropStore = useDragAndDropStore();
  const pointList = useAvailablePoints();
  const modeMap = useModeMap();

  useAutoCleanDragAndDropAtDragEnd(dragAndDropStore.isDragging, dragAndDropStore.dragEnd);

  return (
    <SidebarBase
      modes={modeMap}
      pointList={pointList}
      draggablePoint={dragAndDropStore.draggablePoint}
      isDragging={dragAndDropStore.isDragging}
      onDragStart={dragAndDropStore.dragStart}
    />
  );
}
