import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgAttachmentMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.625 5.529a3.472 3.472 0 00-4.91 0l-.55.55 1.06 1.06.55-.55a1.972 1.972 0 012.79 2.79l-6.05 6.05a.806.806 0 11-1.14-1.14l4.97-4.97a.75.75 0 10-1.06-1.06l-4.97 4.97a2.306 2.306 0 003.26 3.26l6.05-6.05a3.472 3.472 0 000-4.91z"
        fill={props.fill}
      />
      <path
        d="M13.225 7.14L6.91 13.455a3.15 3.15 0 004.454 4.455l5.787-5.786a.75.75 0 011.06 1.06l-5.786 5.787a4.65 4.65 0 11-6.576-6.576l6.317-6.317 1.06 1.06z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgAttachmentMediumLined;
