import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgFunnel(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.109 9.546A1 1 0 0110 9h20a1 1 0 01.809 1.588l-7.713 10.606a.5.5 0 00-.096.294V29a1 1 0 01-.4.8l-4 3A1 1 0 0117 32V21.488a.5.5 0 00-.096-.294L9.191 10.588a1 1 0 01-.082-1.042zM12.946 11a.5.5 0 00-.405.794l6.268 8.618A1 1 0 0119 21v8a.5.5 0 00.8.4l1-.75a.5.5 0 00.2-.4V21a1 1 0 01.191-.588l6.268-8.618a.5.5 0 00-.405-.794H12.946z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgFunnel;
