export enum TimePeriod {
  ALL_TIME = 'ALL_TIME',
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  THIS_WEEK = 'THIS_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  LAST_30_DAYS = 'LAST_30_DAYS',
  CUSTOM_RANGE = 'CUSTOM_RANGE'
}

export enum DropOffsType {
  RequestError = 'request_error',
  DeviceNotFound = 'device_not_found',
  UserMismatch = 'user_mismatch',
  FilterNotFound = 'filter_not_found',
  AccountSuspended = 'account_suspended',
  RichMediaNotFound = 'RichMediaNotFound',
  DynamicTimePassed = 'dynamic_time_passed',
  UserNotFound = 'user_not_found',
  EmailLimitReached = 'email_limit_reached',
  PresetNotFound = 'preset_not_found',
  TagNotFound = 'tag_not_found',
  Error = 'error'
}

export const periodOptions = [
  { label: 'All time', value: TimePeriod.ALL_TIME },
  { label: 'Today', value: TimePeriod.TODAY },
  { label: 'Yesterday', value: TimePeriod.YESTERDAY },
  { label: 'This week', value: TimePeriod.THIS_WEEK },
  { label: 'This month', value: TimePeriod.THIS_MONTH },
  { label: 'Last 30 days', value: TimePeriod.LAST_30_DAYS },
  { label: 'Custom range', value: TimePeriod.CUSTOM_RANGE }
];
