// Helpers
import { makeUniqueId } from '@src/common/helpers/makeUniqueId';

// Analytics
import { sendEventToGA } from '@src/common/analytics';

// Enums
import { DEFAULT_JSON_VALUE, PointType, REACHABILITY_CHECK_TYPES } from '@src/common/constants';
import { RepeatType } from '@src/common/constants/repeatSegment';

// Types
import { TClonedPoints, TPoints, TWaitEvent, TWaitEventPoint } from '@src/common/types/points';
import { IUniqueId } from '@src/common/types/entities';

export function makePoint(point: {
  type: TPoints['type'];
  position?: TPoints['position'];
  data?: TPoints['data'];
  title?: TPoints['title'];
  outputs?: TPoints['outputs'];
  id?: TPoints['id'];
}) {
  const { type, title, position = { x: 0, y: 0 }, data = {}, outputs = [], id } = point;

  const defaultField: {
    id: IUniqueId['id'];
    title: TPoints['title'];
    position: TPoints['position'];
    outputs: TPoints['outputs'];
    data: TPoints['data'];
    originalId?: IUniqueId['id'];
    originalOutputs?: TPoints['outputs'];
  } = {
    id: makeUniqueId(),
    title,
    position,
    outputs: [],
    data
  };

  if (id && outputs) {
    defaultField.originalOutputs = outputs;
    defaultField.originalId = id;
  }

  switch (type) {
    case PointType.EVENT: {
      sendEventToGA({
        eventAction: 'Add',
        eventLabel: 'EntryEvent'
      });
      return {
        ...defaultField,
        title: 'Trigger-based Entry 1',
        type: PointType.EVENT,
        data: {
          name: '',
          applicationCode: '',
          eventConditions: [],
          ...defaultField.data
        }
      };
    }

    case PointType.SEGMENT: {
      return {
        ...defaultField,
        type: PointType.SEGMENT,
        data: {
          applicationCode: '',
          applicationTitle: '',
          filterCode: '',
          filterTitle: '',
          entryLimitsEnabled: true,
          entryLimitsPerSecond: 5000,
          repeat: {
            enabled: false,
            type: RepeatType.EVERY_DAY
          },
          IsDeactivated: false,
          ...defaultField.data
        }
      };
    }

    case PointType.API: {
      return {
        ...defaultField,
        type: PointType.API,
        data: {
          applicationCode: '',
          entryLimitsEnabled: true,
          entryLimitsPerSecond: 5000,
          IsDeactivated: false,
          attributes: []
        }
      };
    }

    case PointType.AB_SPLITTER: {
      return {
        ...defaultField,
        type: PointType.AB_SPLITTER,
        data: {
          split: [50, 50],
          ...defaultField.data
        }
      };
    }

    case PointType.EXIT: {
      return {
        ...defaultField,
        type: PointType.EXIT,
        data: {}
      };
    }

    case PointType.WAIT: {
      return {
        ...defaultField,
        type: PointType.WAIT,
        data:
          Object.keys(data).length > 0
            ? data
            : {
                delay: 86400,
                ...defaultField.data
              }
      };
    }

    case PointType.SEND_PUSH: {
      return {
        ...defaultField,
        type: PointType.SEND_PUSH,
        data: {
          title: '',
          content: '',
          presetCode: '',
          applicationCode: '',
          campaignCode: '',
          isPresetAvailable: true,
          behaviorSplitter: {
            enabled: false,
            interval: 0
          },
          ...defaultField.data
        }
      };
    }

    case PointType.SEND_SMS: {
      return {
        ...defaultField,
        type: PointType.SEND_SMS,
        data: {
          content: '',
          presetCode: '',
          applicationCode: '',
          campaignCode: '',
          isPresetAvailable: true,
          behaviorSplitter: {
            enabled: false,
            interval: 0
          },
          ...defaultField.data
        }
      };
    }

    case PointType.GOAL_EVENT: {
      // При редактировании голов нужно сохранять id поинта
      if (id) {
        defaultField.id = id;
      }

      return {
        ...defaultField,
        type: PointType.GOAL_EVENT,
        data: {
          applicationCode: '',
          name: '',
          eventConditions: [],
          isThrowOut: false,
          ...defaultField.data
        }
      };
    }

    case PointType.SEND_EMAIL: {
      return {
        ...defaultField,
        type: PointType.SEND_EMAIL,
        data: {
          applicationCode: '',
          presetCode: '',
          campaignCode: '',
          ...defaultField.data
        }
      };
    }

    case PointType.SEND_IN_APP: {
      return {
        ...defaultField,
        type: PointType.SEND_IN_APP,
        data: {
          applicationCode: '',
          richMediaCode: '',
          expireInDays: 7,
          ...defaultField.data
        }
      };
    }

    case PointType.BOOLEAN_SPLITTER: {
      return {
        ...defaultField,
        type: PointType.BOOLEAN_SPLITTER,
        data: {
          filterCode: '',
          name: '',
          ...defaultField.data
        }
      };
    }

    case PointType.WAIT_EVENT: {
      const eventsData = data as TWaitEventPoint['data'];
      const waitEvents = eventsData?.waitEvents || [];

      return {
        ...defaultField,
        type: PointType.WAIT_EVENT,
        data: {
          waitEvents
        }
      };
    }

    case PointType.SET_TAGS: {
      return {
        ...defaultField,
        type: PointType.SET_TAGS,
        data: {
          applicationCode: '',
          tags: []
        }
      };
    }

    case PointType.WEBHOOK: {
      return {
        ...defaultField,
        type: PointType.WEBHOOK,
        data: {
          url: '',
          headers: {
            'Content-Type': 'application/json'
          },
          request_template: DEFAULT_JSON_VALUE,
          method: 'POST',
          ...defaultField.data
        }
      };
    }

    case PointType.REACHABILITY_CHECK: {
      return {
        ...defaultField,
        type: PointType.REACHABILITY_CHECK,
        title: '',
        data: {
          filterType: REACHABILITY_CHECK_TYPES.PUSH
        }
      };
    }

    default: {
      throw new Error(`No Point of ${type} type exists.`);
    }
  }
}
