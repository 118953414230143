// Store
import { useStoreState } from '@src/store/store';

// Types
import { TFilter } from '@src/common/types/filter';

type TResult = TFilter[];

export function useFilter(): TResult {
  const filters: TFilter[] = useStoreState((state): TFilter[] => state.filter.items);

  return filters;
}
