import { action, actionOn } from 'easy-peasy';

// Types
import { IErrorsPoint } from './errorsPoint.types';

export const model: IErrorsPoint = {
  items: [],
  isVisibleErrorsPaginate: false,
  currentError: 0,
  setCurrentError: action((state, payload): void => {
    state.currentError = payload;
  }),
  setVisibleErrorsPaginate: action((state, payload): void => {
    state.isVisibleErrorsPaginate = payload;
  }),
  add: action((state, payload): void => {
    state.items = [...payload];
  }),
  close: action((state): void => {
    state.items = [];
    state.isVisibleErrorsPaginate = false;
    state.currentError = 0;
  }),
  remove: actionOn(
    (_, storeActions): string[] => [
      storeActions.points.update.type,
      storeActions.points.delete.type,
      storeActions.points.addOutputToPoint.type,
      storeActions.points.removeOutputFromPoint.type
    ],
    (state, target): void => {
      if (!state.isVisibleErrorsPaginate) {
        return;
      }
      const { payload } = target;
      const [updateType, deleteType, addEdgeType, deleteEdgeType] = target.resolvedTargets;

      const prevLengthItems = state.items.length;

      if (target.type === updateType && !payload.position) {
        state.items = state.items.filter((item): boolean => item.id !== payload.id);
      }
      if (target.type === addEdgeType || target.type === deleteEdgeType) {
        state.items = state.items.filter(
          (item): boolean => item.id !== payload.toPointId && item.id !== payload.fromPointId
        );
      }
      if (target.type === deleteType) {
        state.items = state.items.filter((item): boolean => item.id !== payload);
      }

      // reset current error
      if (prevLengthItems !== state.items.length) {
        state.currentError = 0;
      }

      if (!state.items.length) {
        state.isVisibleErrorsPaginate = false;
      }
    }
  )
};
