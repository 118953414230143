import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSortNotSortedSmall(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 3a.75.75 0 01.75.75v6.19l.72-.72a.75.75 0 011.06 1.06l-2 2a.75.75 0 01-1.06 0l-2-2a.75.75 0 111.06-1.06l.72.72V3.75A.75.75 0 014.75 3zM11 11.75a.75.75 0 001.5 0V5.56l.72.72a.75.75 0 001.06-1.06l-2-2a.75.75 0 00-1.06 0l-2 2a.75.75 0 101.06 1.06l.72-.72v6.19z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgSortNotSortedSmall;
