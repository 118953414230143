export const mapWeekDay = (number: string): string => {
  switch (number) {
    case '1': {
      return 'Mondays';
    }
    case '2': {
      return 'Tuesday';
    }
    case '3': {
      return 'Wednesday';
    }
    case '4': {
      return 'Thursday';
    }
    case '5': {
      return 'Friday';
    }
    case '6': {
      return 'Saturday';
    }
    case '7': {
      return 'Sunday';
    }
    default: {
      return 'unknown';
    }
  }
};
