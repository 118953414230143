import { Subject, merge, tap } from 'rxjs';
import { KeyCode } from '@src/common/constants/keykodes';
import { shortcut } from './helpers/shortcut';

type State = {
  isAltPressed: boolean;
  isShiftPressed: boolean;
};

const subject = new Subject();

const initialState: State = {
  isAltPressed: false,
  isShiftPressed: false
};

let state: State = initialState;

const altKey$ = merge(shortcut([KeyCode.AltLeft]), shortcut([KeyCode.AltRight]));
const shiftKey$ = merge(shortcut([KeyCode.ShiftLeft, KeyCode.ShiftRight]));

altKey$
  .pipe(
    tap((e) => {
      if (e[0].type === 'keydown') {
        state = {
          ...state,
          isAltPressed: true
        };
      } else {
        state = {
          ...state,
          isAltPressed: false
        };
      }

      subject.next(state);
    })
  )
  .subscribe();

shiftKey$
  .pipe(
    tap((e) => {
      if (e[0].type === 'keydown') {
        state = {
          ...state,
          isShiftPressed: true
        };
      } else {
        state = {
          ...state,
          isShiftPressed: false
        };
      }

      subject.next(state);
    })
  )
  .subscribe();

export const hotkeys$ = {
  init: () => subject.next(state),
  subscribe: <T>(setState: (value: T) => void) => subject.subscribe(setState),
  initialState
};
