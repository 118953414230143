import * as React from 'react';
import classnames from 'classnames';

// Types
import { ITextProps } from './Text.types';

// Styles
import styles from './styles/styles.scss';

function Text(props: ITextProps): JSX.Element {
  const {
    className,
    children,
    tag = 'span',
    size = 'sm',
    weight = 'regular',
    transform,
    view = 'base',
    title,
    onDoubleClick,
    onClick
  } = props;

  const textClasses = React.useMemo(
    (): string =>
      classnames(
        className,
        styles.text,
        styles[`size-${size}`],
        styles[`view-${view}`],
        styles[`weight-${weight}`],
        {
          [styles[`transform-${transform}`]]: Boolean(transform)
        }
      ),
    [className, size, view, weight, transform]
  );

  return React.createElement(
    tag,
    {
      className: textClasses,
      title,
      onClick,
      onDoubleClick
    },
    children
  );
}

export default React.memo(Text);
