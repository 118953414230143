import { action } from 'easy-peasy';

// Types
import { IPopupModel } from './popupMenu.types';

const POSITION_DEFAULT = {
  x: 0,
  y: 0
};

export const model: IPopupModel = {
  isVisible: false,
  menuPosition: POSITION_DEFAULT,
  canvasMousePosition: POSITION_DEFAULT,
  fromPointId: '',
  pointOutputKey: '',
  pointOutputOrder: 0,

  showMenu: action((state, payload): void => {
    state.isVisible = true;

    state.menuPosition = {
      x: payload.pageMouseX,
      y: payload.pageMouseY
    };

    state.canvasMousePosition = {
      x: payload.canvasMouseX,
      y: payload.canvasMouseY
    };

    state.fromPointId = payload.fromPointId ? payload.fromPointId : '';
    state.pointOutputKey = payload.pointOutputKey ? payload.pointOutputKey : '';
    state.pointOutputOrder = payload.pointOutputOrder ? payload.pointOutputOrder : 0;
  }),

  setMenuPosition: action((state, payload): void => {
    state.menuPosition = payload;
  }),

  closeMenu: action((state): void => {
    state.menuPosition = POSITION_DEFAULT;
    state.canvasMousePosition = POSITION_DEFAULT;
    state.fromPointId = '';
    state.pointOutputKey = '';
    state.pointOutputOrder = 0;
    state.isVisible = false;
  })
};
