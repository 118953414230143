import * as React from 'react';

// Hooks
import { useStoreActions, useStoreState } from '@src/store/store';

// UI Components
import { RightDrawerSidebar } from '@src/ui/kit';
import { DocumentationButtonCards } from '@src/ui/features/Documentation/components/DocumentationButtonCards';

// Articles
import { DocumentationCanvas } from '@src/ui/features/Documentation/articles/DocumentationCanvas';
import { DocumentationGoals } from '@src/ui/features/Documentation/articles/DocumentationGoals';
import { DocumentationPointSendPush } from '@src/ui/features/Documentation/articles/DocumentationPointSendPush';
import { DocumentationPointSendEmail } from '@src/ui/features/Documentation/articles/DocumentationPointSendEmail';
import { DocumentationPointSendInApp } from '@src/ui/features/Documentation/articles/DocumentationPointSendInApp';
import { DocumentationPointSendBooleanSplitter } from '@src/ui/features/Documentation/articles/DocumentationPointSendBooleanSplitter';
import { DocumentationPointWaitForEvent } from '@src/ui/features/Documentation/articles/DocumentationPointWaitForEvent';
import { DocumentationPointDelay } from '@src/ui/features/Documentation/articles/DocumentationPointDelay';
import { DocumentationPointExit } from '@src/ui/features/Documentation/articles/DocumentationPointExit';
import { DocumentationPointStartEvent } from '@src/ui/features/Documentation/articles/DocumentationPointStartEvent';
import { DocumentationPointSegment } from '@src/ui/features/Documentation/articles/DocumentationPointSegment';
import { DocumentationPointABSplit } from '@src/ui/features/Documentation/articles/DocumentationPointABSplit';
import { DocumentationPointSetTags } from '@src/ui/features/Documentation/articles/DocumentationPointSetTags';
import { DocumentationReachabilityCheck } from '@src/ui/features/Documentation/articles/DocumentationReachabilityCheck';

// Helpers
import { sendYandexMetrika } from '@src/common/analytics/analytics';

// Types
import { TDocumentationMenuRoutePath } from '@src/store/models/documentationMenu/documentationMenu.types';
import { DocumentationWebhook } from '@src/ui/features/Documentation/articles/DocumentationWebhook';
import { TDocumentationMenuRoute } from './DocumentationMenu.types';

// Constants
import { documentationMenuLinks } from './DocumentationMenu.constants';

export function DocumentationMenu(): JSX.Element {
  const routePath = useStoreState((state) => state.documentationMenu.routePath);

  const closeMenu = useStoreActions((actions) => actions.sidebars.closeAllSidebars);
  const setRoutePath = useStoreActions((actions) => actions.documentationMenu.setRoutePath);

  const handleBack = React.useCallback((): void => {
    setRoutePath('/');
  }, [setRoutePath]);

  const handleClose = React.useCallback((): void => {
    closeMenu();
  }, [closeMenu]);

  const handleMenuButtonClick = React.useCallback(
    (path: TDocumentationMenuRoutePath): void => {
      setRoutePath(path);
      sendYandexMetrika('cj-widjet-helper-item');
    },
    [setRoutePath]
  );

  const routes = React.useMemo(
    (): TDocumentationMenuRoute<TDocumentationMenuRoutePath>[] => [
      {
        title: 'Learn & Inspire',
        path: '/',
        component: (
          <DocumentationButtonCards<TDocumentationMenuRoutePath>
            links={documentationMenuLinks}
            onClick={handleMenuButtonClick}
          />
        )
      },
      {
        title: 'Canvas & Flow',
        path: '/canvas',
        component: <DocumentationCanvas />
      },
      {
        title: 'Goals',
        path: '/goals',
        component: <DocumentationGoals />
      },
      {
        title: 'Trigger-based Entry',
        path: '/point-event',
        component: <DocumentationPointStartEvent />
      },
      {
        title: 'Audience-based Entry',
        path: '/point-start-by-segment',
        component: <DocumentationPointSegment />
      },
      {
        title: 'Push',
        path: '/point-send-push',
        component: <DocumentationPointSendPush />
      },
      {
        title: 'Email',
        path: '/point-send-email',
        component: <DocumentationPointSendEmail />
      },
      {
        title: 'In-App',
        path: '/point-inapp',
        component: <DocumentationPointSendInApp />
      },
      {
        title: 'Webhook',
        path: '/point-webhook',
        component: <DocumentationWebhook />
      },
      {
        title: 'Update User Profile',
        path: '/point-set-tags',
        component: <DocumentationPointSetTags />
      },
      {
        title: 'Exit from Journey',
        path: '/point-exit',
        component: <DocumentationPointExit />
      },
      {
        title: 'Time Delay',
        path: '/point-wait',
        component: <DocumentationPointDelay />
      },
      {
        title: 'Segment Split',
        path: '/point-boolean-splitter',
        component: <DocumentationPointSendBooleanSplitter />
      },
      {
        title: 'Reachability Check',
        path: '/point-filter',
        component: <DocumentationReachabilityCheck />
      },
      {
        title: 'Wait For Trigger',
        path: '/point-wait-event',
        component: <DocumentationPointWaitForEvent />
      },
      {
        title: 'A/B/n Split',
        path: '/point-ab-splitter',
        component: <DocumentationPointABSplit />
      }
    ],
    [handleMenuButtonClick]
  );

  const currentRoute = React.useMemo(
    () => routes.find((route) => route.path === routePath),
    [routes, routePath]
  );

  if (!currentRoute) {
    return null;
  }

  return (
    <RightDrawerSidebar
      title={currentRoute.title}
      isBack={routePath !== '/'}
      isOpen
      onBack={handleBack}
      onClose={handleClose}
    >
      {currentRoute.component}
    </RightDrawerSidebar>
  );
}
