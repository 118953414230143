import { PointType, GroupSizeFirst, GroupSizeSecond, GroupSizeThird } from '@src/common/constants';

type TResult = {
  widthPoint: number;
  heightPoint: number;
};

export const getSizePoint = (type: PointType): TResult => {
  switch (type) {
    case PointType.EVENT:
      return {
        widthPoint: GroupSizeFirst.WIDTH,
        heightPoint: GroupSizeFirst.HEIGHT
      };
    case PointType.SEGMENT:
      return {
        widthPoint: GroupSizeFirst.WIDTH,
        heightPoint: GroupSizeFirst.HEIGHT
      };
    case PointType.API:
      return {
        widthPoint: GroupSizeFirst.WIDTH,
        heightPoint: GroupSizeFirst.HEIGHT
      };
    case PointType.SEND_PUSH:
      return {
        widthPoint: GroupSizeFirst.WIDTH,
        heightPoint: GroupSizeFirst.HEIGHT
      };
    case PointType.SEND_EMAIL:
      return {
        widthPoint: GroupSizeFirst.WIDTH,
        heightPoint: GroupSizeFirst.HEIGHT
      };
    case PointType.SEND_IN_APP:
      return {
        widthPoint: GroupSizeFirst.WIDTH,
        heightPoint: GroupSizeFirst.HEIGHT
      };
    case PointType.SEND_SMS:
      return {
        widthPoint: GroupSizeFirst.WIDTH,
        heightPoint: GroupSizeFirst.HEIGHT
      };
    case PointType.WAIT_EVENT:
      return {
        widthPoint: GroupSizeFirst.WIDTH,
        heightPoint: GroupSizeFirst.HEIGHT
      };
    case PointType.SET_TAGS:
      return {
        widthPoint: GroupSizeFirst.WIDTH,
        heightPoint: GroupSizeFirst.HEIGHT
      };
    case PointType.WAIT:
      return {
        widthPoint: GroupSizeFirst.WIDTH,
        heightPoint: GroupSizeFirst.HEIGHT
      };
    case PointType.EXIT:
      return {
        widthPoint: GroupSizeSecond.WIDTH,
        heightPoint: GroupSizeSecond.HEIGHT
      };
    case PointType.AB_SPLITTER:
      return {
        widthPoint: GroupSizeThird.WIDTH,
        heightPoint: GroupSizeThird.HEIGHT
      };
    case PointType.BOOLEAN_SPLITTER:
      return {
        widthPoint: GroupSizeThird.WIDTH,
        heightPoint: GroupSizeThird.HEIGHT
      };
    case PointType.WEBHOOK:
      return {
        widthPoint: GroupSizeFirst.WIDTH,
        heightPoint: GroupSizeFirst.HEIGHT
      };
    case PointType.REACHABILITY_CHECK:
      return {
        widthPoint: GroupSizeFirst.WIDTH,
        heightPoint: GroupSizeFirst.HEIGHT
      };
    default:
      return {
        widthPoint: GroupSizeFirst.WIDTH,
        heightPoint: GroupSizeFirst.HEIGHT
      };
  }
};
