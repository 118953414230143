import React from 'react';
import { StyledButton, ButtonField } from './styled';

type Props = {
  icon: JSX.Element;
  children: string | JSX.Element;
  onClick?: (event: React.MouseEvent) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isCompact?: boolean;
};

export function SettingsButton(props: Props): JSX.Element {
  const { icon: Icon, children, onClick, isCompact, onMouseLeave, onMouseEnter } = props;

  return (
    <StyledButton
      onClick={onClick}
      isCompact={isCompact}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {Icon}
      {!isCompact && <ButtonField>{children}</ButtonField>}
    </StyledButton>
  );
}
