import React, { useMemo } from 'react';

// UI Components
import { ExtraOneButton, Modal, Section, FormField } from '@src/ui/kit';
import { PointTitle, ItemDetails } from '@src/ui/kit/DetailsPoint/styled';
import { RichMediaPreviewMemo } from '@src/ui/features/SendInApp/components/RichMediaPreview';
import { DropOffStat } from '@src/ui/features/DropOffStat';

// Types
import { TRichMedia } from '@src/common/types/richMedia';
import { ModalType } from '@src/common/constants/modals';
import { TModal } from '@src/common/types/modal';
import { useCloseModalByType } from '@src/store/hooks/modals/useCloseModalByType';
import { ExpirationLabel } from '@src/ui/features/SendInApp/styled';
import { RichMediaStatstic } from '../components/RichMediaStatistic';

interface IProps {
  richMedia: TRichMedia;
  pointTitle: string;
  pointId: string;
  expireInDays?: number;
  applicationCode: string;
  campaignCode: string;
}

export function DetailSendInApp(props: IProps): JSX.Element {
  const { richMedia, pointTitle, pointId, applicationCode, expireInDays, campaignCode } = props;
  const [isOpenStats, setOpenStats] = React.useState(false);

  const closeModalByType = useCloseModalByType();

  const modalSettings = {
    type: ModalType.EDITING_FORM_POINT,
    data: {
      pointId
    }
  } as TModal;

  const expirationLabelText = useMemo(() => {
    if (typeof expireInDays !== 'number') return undefined;
    if (expireInDays <= 0) return undefined;

    if (expireInDays === 1) {
      return '1 day';
    }

    return `${expireInDays} days`;
  }, [expireInDays]);

  return (
    <Modal
      title={
        <>
          Send in App:&nbsp;
          <PointTitle>{pointTitle}</PointTitle>
        </>
      }
      footerRight={
        <ExtraOneButton
          actionButtonName="Close"
          onClickActionButton={() => closeModalByType(ModalType.DETAIL_POINT)}
        />
      }
      isOpen
    >
      <Section direction="column">
        <ItemDetails>
          <DropOffStat pointId={pointId} modal={modalSettings} />
        </ItemDetails>
      </Section>
      <Section direction="column">
        {expirationLabelText && (
          <ItemDetails>
            <b>In-app Expiration after:</b>
            <ExpirationLabel>{expirationLabelText}</ExpirationLabel>
          </ItemDetails>
        )}
        <ItemDetails>
          <FormField
            label="Rich Media:"
            value={richMedia?.name}
            onClick={() => setOpenStats(true)}
            url={`https://app.pushwoosh.com/applications/${applicationCode}/richMedia/edit/${richMedia?.uid}/${richMedia?.code}`}
          />
        </ItemDetails>
        <ItemDetails>
          <RichMediaPreviewMemo
            richMediaCode={richMedia ? richMedia.code : ''}
            zipContent={richMedia ? richMedia.zipcontent : ''}
            params={richMedia ? richMedia.params : ''}
            language={richMedia ? richMedia.defaultLanguage : ''}
          />
        </ItemDetails>
      </Section>
      <RichMediaStatstic
        isOpen={isOpenStats}
        applicationCode={richMedia?.applicationCode}
        richMediaCode={richMedia?.code}
        campaignCode={campaignCode}
        messageCode={richMedia?.name}
        onClose={() => setOpenStats(false)}
      />
    </Modal>
  );
}
