import * as React from 'react';

// Analytics
import { sendPostEvent } from '@src/common/analytics';

// Store
import { useStoreActions, useStoreState } from '@src/store/store';

// Hooks
import { useAddNotifications, useUsername } from '@src/store/hooks';

// Enums
import { NotificationType, NOTIFICATION_TITLE_SUCCESS } from '@src/common/constants/notification';

// Types
import { TJourney } from '@src/common/types/journey';
import { TRequest } from '@src/store/models/requests/requests.types';
import { IUniqueId } from '@src/common/types/entities';
import { PeriodSettings } from '@src/common/types/statistics';

type TActionUpdateById = (data: { id: IUniqueId['id']; title?: string }) => Promise<TJourney>;
type TActionActivateById = (id: IUniqueId['id']) => Promise<TJourney>;
type TResult = [TActionActivateById, boolean];

export function useActivateJourneyById(): TResult {
  const addNotifications = useAddNotifications();
  const username = useUsername();
  const periodSettings = useStoreState((state) => state.statistics.periodSettings);

  const updateById = useStoreActions((actions): TActionUpdateById => actions.journeys.updateById);

  const activateJourneyById: TActionActivateById = useStoreActions(
    (actions): TActionActivateById => actions.journeys.activateById
  );

  const loadedStatistics: (payload?: { id: string; periodSettings: PeriodSettings }) => void =
    useStoreActions(
      (actions): ((payload?: { id: string; periodSettings: PeriodSettings }) => void) =>
        actions.statistics.load
    );

  const request: TRequest = useStoreState(
    (state): TRequest => state.requests.entities['journeys.activateById']
  );

  const activateById = React.useCallback<TActionActivateById>(
    async (id): Promise<TJourney> => {
      await updateById({ id });
      const response = await activateJourneyById(id);

      sendPostEvent('CustomerJourneyActivate', {
        userEmail: username,
        isFirst: response.isFirstActivated
      });

      loadedStatistics({
        id,
        periodSettings
      });

      addNotifications([
        {
          title: NOTIFICATION_TITLE_SUCCESS.activateJourney,
          type: NotificationType.SUCCESS
        }
      ]);

      return response;
    },
    [updateById, activateJourneyById, loadedStatistics, addNotifications, username, periodSettings]
  );

  return [activateById, Boolean(request)];
}
