// Enums
import { PointType } from '@src/common/constants/point';

export function getPointNameByType(type: PointType): string {
  switch (type) {
    case PointType.EVENT: {
      return 'Trigger-based Entry';
    }

    case PointType.SEGMENT: {
      return 'Audience-based Entry';
    }

    case PointType.API: {
      return 'Webhook-based entry';
    }

    case PointType.EXIT: {
      return 'Exit from Journey';
    }

    case PointType.WAIT: {
      return 'Time Delay';
    }

    case PointType.SEND_PUSH: {
      return 'Push';
    }

    case PointType.SEND_SMS: {
      return 'SMS';
    }

    case PointType.SEND_EMAIL: {
      return 'Email';
    }

    case PointType.SEND_IN_APP: {
      return 'In-App';
    }

    case PointType.BOOLEAN_SPLITTER: {
      return 'Segment Split';
    }

    case PointType.AB_SPLITTER: {
      return 'A/B/n Split';
    }

    case PointType.SET_TAGS: {
      return 'Update User Profile';
    }

    case PointType.WAIT_EVENT: {
      return 'Wait for Trigger';
    }

    case PointType.WEBHOOK: {
      return 'Webhook';
    }

    case PointType.REACHABILITY_CHECK: {
      return 'Reachability check';
    }

    default: {
      return '';
    }
  }
}
