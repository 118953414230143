import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPushSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.25 6A.75.75 0 015 5.25h6a.75.75 0 010 1.5H5A.75.75 0 014.25 6zM5 8.25a.75.75 0 000 1.5h3.5a.75.75 0 100-1.5H5z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.95 2.25h6.1c.664 0 1.237 0 1.696.062.492.066.963.215 1.345.597s.531.854.597 1.345c.062.459.062 1.032.062 1.697v3.098c0 .665 0 1.238-.062 1.697-.066.492-.215.963-.597 1.345s-.853.531-1.345.597c-.459.062-1.032.062-1.697.062H8.227l-2.655 1.77a.85.85 0 01-1.322-.707v-1.066a8.608 8.608 0 01-.996-.059c-.491-.066-.963-.215-1.345-.597s-.531-.853-.597-1.345c-.062-.459-.062-1.032-.062-1.697V5.951c0-.665 0-1.238.062-1.697.066-.491.215-.963.597-1.345s.854-.531 1.345-.597c.459-.062 1.032-.062 1.697-.062zM3.455 3.798c-.325.044-.427.115-.484.172-.057.057-.128.159-.172.484-.046.347-.048.818-.048 1.546v3c0 .728.002 1.2.048 1.546.044.325.115.427.172.484.057.057.159.128.484.172.347.046.818.048 1.546.048h.75v1.349l2.023-1.349H11c.728 0 1.2-.002 1.546-.048.325-.044.427-.115.484-.172.057-.057.128-.159.172-.484.046-.347.048-.818.048-1.546V6c0-.728-.002-1.2-.048-1.546-.044-.325-.115-.427-.172-.484-.057-.057-.159-.128-.484-.172-.347-.046-.818-.048-1.546-.048H5c-.728 0-1.2.002-1.546.048z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPushSmallLined;
