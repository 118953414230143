import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPlatformLogoHuaweiMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.545 8.032c-.519-1.77.375-3.425 2.006-3.848 0 0 .645-.146.941-.183.03-.004.059.01.075.034.225.342 1.72 2.958.9 11.15 0 0 .004.06-.057.075 0 0-.033.018-.073-.023-.575-.83-3.247-4.79-3.79-7.201l-.002-.004zM5 6.714a.079.079 0 01.117.005c.433.538 3.34 4.22 5.51 8.96.02.046-.017.075-.017.075-.023.03-.062.02-.078.016l-.005-.001c-4.48-2.231-5.894-3.613-6.061-3.776a.547.547 0 00-.017-.016c-.05-.04-.946-.78-1.011-2.002-.111-1.474 1.25-2.946 1.561-3.261zM9.96 16.97s.064 0 .08.053c0 0 .015.033-.031.077h.002c-.604.433-2.433 1.71-3.24 1.91-1.13.275-2.22-.58-2.893-1.713a.08.08 0 01.065-.12l6.017-.21v.004zM10.11 16.381c-2.5-1.687-7.167-4.207-7.85-4.574a.078.078 0 00-.113.046c-.42 1.43.194 2.57.194 2.57.666 1.409 1.939 1.836 1.939 1.836a3.77 3.77 0 001.178.257c.091.017 3.655.002 4.61-.002.042-.002.063-.041.063-.041.03-.049-.021-.092-.021-.092zM16.465 6.085l-.002-.004c-.015-.053-.432-1.505-2.032-1.894 0 0-.44-.111-.919-.184-.198-.03-1.824 2.816-.984 11.19.008.054.047.067.047.067.06.024.089-.034.089-.034.591-.853 3.249-4.793 3.79-7.192.002-.01.291-1.165.01-1.95zM14.02 16.975l.01-.002.001-.001 6.022.208a.08.08 0 01.064.12c-1.363 2.24-2.9 1.715-2.9 1.715-.86-.241-2.644-1.489-3.233-1.911a.066.066 0 01-.016-.022c-.02-.043.006-.095.052-.107zM21.853 11.843a.08.08 0 00-.114-.048c-.68.368-5.343 2.896-7.843 4.58v.002c-.068.045-.026.136.03.136.968.002 4.631.006 4.723-.01 0 0 .472-.02 1.057-.244 0 0 1.303-.414 1.98-1.894 0 0 .577-1.153.167-2.522zM19 6.712c.309.306 1.657 1.73 1.56 3.255-.05 1.207-.963 1.972-1.01 2.01l-.086.076c-.395.353-1.848 1.652-5.98 3.709-.058.033-.135-.033-.1-.107h-.001c2.169-4.732 5.07-8.404 5.5-8.936A.079.079 0 0119 6.712z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPlatformLogoHuaweiMediumFilled;
