import * as React from 'react';

// Components
import { DocElements } from '@src/ui/features/Documentation/components/DocElements';

export function DocumentationPointSendInApp(): JSX.Element {
  return (
    <DocElements>
      <DocElements.Paragraph>
        To show the In-App to users within the journey, add the
        <strong> In-App </strong>
        to the canvas next to any element. Choose an app and a Rich Media page to be shown.
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-point-send-in-app_gif-1.gif"
        alt="documentation-point-send-in-app_gif-1"
      />

      <DocElements.List>
        <DocElements.ListItem>
          {
            'If your app is opened at the moment the In-App element is triggered, the In-App will be displayed immediately.'
          }
        </DocElements.ListItem>

        <DocElements.ListItem>
          {
            'If the app is closed, the In-App will be displayed the next time the user opens the app.'
          }
        </DocElements.ListItem>
      </DocElements.List>
    </DocElements>
  );
}
