import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSortDescSmall(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 3.75a.75.75 0 00-1.5 0v6.19l-.72-.72a.75.75 0 00-1.06 1.06l2 2a.75.75 0 001.06 0l2-2a.75.75 0 10-1.06-1.06l-.72.72V3.75z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgSortDescSmall;
