export function getStatusMessage(
  isBaseline: boolean,
  conversion: number,
  isSignificant: boolean,
  isWinner: boolean
) {
  if (isWinner && isBaseline) {
    return 'Baseline is better';
  }

  if (isWinner && !isBaseline) {
    return 'Significant';
  }

  if (isBaseline) {
    return 'Baseline';
  }

  if (conversion === 0 && !isSignificant) {
    return 'Not enough data';
  }

  if (!isSignificant) {
    return 'Not significant';
  }

  return 'Not significant';
}
