import * as React from 'react';
import { Rect, Group } from 'react-konva';

// Helpers
import { getSizeIconPoint } from '@src/common/helpers';

// Types
import { IProps } from './PointIcon.types';

export function PointIcon(props: IProps): JSX.Element {
  const { type, children, fill } = props;

  const { widthIcon, heightIcon } = getSizeIconPoint(type);

  return (
    <Group width={widthIcon} height={heightIcon} perfectDrawEnabled={false}>
      <Rect
        width={widthIcon}
        height={heightIcon}
        fill={fill}
        cornerRadius={2}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
      />
      {children}
    </Group>
  );
}
