import React from 'react';
import { useParams } from 'react-router-dom';

// Store Hooks
import {
  useComputedGetPointStatByPointId,
  useGetEmailByAppCodeAndEmailCode,
  useGetPointById
} from '@src/store/hooks';

// UI Components
import {
  Button,
  ExtraOneButton,
  Icon,
  IconEnum,
  InitialTag,
  Link,
  Modal,
  Section,
  Tag
} from '@src/ui/kit';
import { ItemDetails, PointTitle } from '@src/ui/kit/DetailsPoint/styled';
import { ModalButtonsWrap } from '@src/ui/form/styled';
import { DropOffStat } from '@src/ui/features/DropOffStat';

// Types
import { IUniqueId } from '@src/common/types/entities';
import { TPointSendEmailStatistics } from '@src/common/types/statistics';
import { ModalType } from '@src/common/constants/modals';
import { TModal } from '@src/common/types/modal';
import { TSendEmailPoint } from '@src/common/types/points';
import { decodeDelay } from '@src/common/helpers';

// Components
import { useCloseModalByType } from '@src/store/hooks/modals/useCloseModalByType';
import { Color, FontSize } from '@pushwoosh/kit-constants';
import { getEmailTemplateLink } from '@src/ui/features/SendEmail/helpers';
import { GrayText, OptionsRow, OptionsText } from '@src/ui/features/SendPush/styled';
import { MainStat } from '../components/MainStat';
import { DetailBlock, DetailTitle, Row, TagWrap } from '../styled';

interface IProps {
  pointId: IUniqueId['id'];
  applicationCode: string;
  campaignCode: string;
  pointTitle: string;
  behaviorSplitterEnabled: TSendEmailPoint['data']['emailBehaviorSplitter']['enabled'];
  behaviorSplitterInterval: TSendEmailPoint['data']['emailBehaviorSplitter']['interval'];
  enabledPersonalise: boolean;
  sendOnlyToSubscribed: boolean;
  placeholders: TSendEmailPoint['data']['personalise']['placeholders'];
}

export function DetailSendEmail(props: IProps): JSX.Element {
  const {
    pointId,
    applicationCode,
    campaignCode,
    pointTitle,
    enabledPersonalise,
    sendOnlyToSubscribed,
    placeholders,
    behaviorSplitterEnabled,
    behaviorSplitterInterval
  } = props;
  const getPointStatByPointId = useComputedGetPointStatByPointId();
  const getPointData = useGetPointById();
  const getEmailByAppCodeAndEmailCode = useGetEmailByAppCodeAndEmailCode();
  const point = getPointStatByPointId(pointId) as TPointSendEmailStatistics;
  const { sent, opened, unsubscribed, clicked } = point?.pointStat ?? {};

  const isEnabledBehaviorSplitter =
    behaviorSplitterEnabled &&
    typeof behaviorSplitterInterval === 'number' &&
    behaviorSplitterInterval > 0;

  const time = decodeDelay(behaviorSplitterInterval);

  const closeModalByType = useCloseModalByType();
  const params = useParams<{ applicationCode: string }>();
  const statsLink = params.applicationCode
    ? `https://app.pushwoosh.com/applications/${applicationCode}/campaigns/${campaignCode}/statistics`
    : `https://go.pushwoosh.com/cp/applications/${applicationCode}/campaigns/${campaignCode}/statistics`;

  const handleClickToStat = () => {
    window.open(statsLink, '_blank');
  };

  const modalSettings = {
    type: ModalType.EDITING_FORM_POINT,
    data: {
      pointId
    }
  } as TModal;

  const pointData = getPointData(pointId) as TSendEmailPoint;

  const emailTemplate = getEmailByAppCodeAndEmailCode(applicationCode, pointData.data.presetCode);

  return (
    <Modal
      title={
        <>
          Email:&nbsp;
          <PointTitle>{pointTitle}</PointTitle>
        </>
      }
      footerRight={
        <ExtraOneButton
          actionButtonName="Close"
          onClickActionButton={() => closeModalByType(ModalType.DETAIL_POINT)}
        />
      }
      footerLeft={
        <ModalButtonsWrap>
          <Button
            color="primary"
            size="field"
            view="ghost"
            iconType={IconEnum.OPEN_MEDIUM_LINED}
            onClick={handleClickToStat}
          >
            Full Statistics
          </Button>
        </ModalButtonsWrap>
      }
      isOpen
    >
      <Section direction="column">
        <MainStat sent={sent} clicked={clicked} opened={opened} unsubscribed={unsubscribed} />
        <ItemDetails>
          <DropOffStat pointId={pointId} modal={modalSettings} />
        </ItemDetails>
        <Row>
          <DetailTitle>Content:</DetailTitle>
          {emailTemplate !== null && (
            <Link
              size={FontSize.REGULAR}
              isExternal
              to={getEmailTemplateLink(
                applicationCode,
                emailTemplate.template,
                pointData.data.templateIsBlockBased
              )}
              target="_blank"
              afterIcon={IconEnum.OPEN_MEDIUM_LINED}
            >
              {emailTemplate.name}
            </Link>
          )}
        </Row>
        {enabledPersonalise && (
          <DetailBlock>
            <DetailTitle>Personalise message with event attributes:</DetailTitle>
            {placeholders.map((placeholder) => (
              <TagWrap>
                <Tag label="Overwrite placeholder" value={placeholder.name} />
                <Tag label="Source event" value={placeholder.eventName} />
                <Tag label="Dynamic attribute" value={placeholder.attribute} />
              </TagWrap>
            ))}
          </DetailBlock>
        )}
        <DetailTitle>Options:</DetailTitle>
        {isEnabledBehaviorSplitter && (
          <DetailBlock>
            <OptionsRow>
              <Icon type={IconEnum.CHECK_ROUND_SMALL_FILLED} fill={Color.BRIGHT} />
              <OptionsText>Split flow if message is not opened</OptionsText>
              <GrayText>wait period</GrayText>
              <InitialTag value={`${time.dd} days ${time.hh} hours ${time.mm} minutes`} />
            </OptionsRow>
          </DetailBlock>
        )}
        {sendOnlyToSubscribed || (
          <DetailBlock>
            <OptionsRow>
              <Icon type={IconEnum.CHECK_ROUND_SMALL_FILLED} fill={Color.BRIGHT} />
              <OptionsText>Sending to unsubscribed</OptionsText>
            </OptionsRow>
          </DetailBlock>
        )}
      </Section>
    </Modal>
  );
}
