import * as React from 'react';
import { parseToRgb, toColorString } from 'polished';

// Constants
import { Color } from '@pushwoosh/kit-constants';

// Styled
import { Container, Content } from './styled';

// Types
import { Props } from './Badge.types';

export const Badge = (props: Props): JSX.Element => {
  const { className, children, color = Color.CLEAR, isSmall, isBoldText, isFilled } = props;

  const colorTextRGB = parseToRgb(color);
  const colorTextRGBA = {
    ...colorTextRGB,
    alpha: 0.1
  };
  return (
    <Container
      className={className}
      color={isFilled ? color : toColorString(colorTextRGBA)}
      isSmall={isSmall}
    >
      <Content color={isFilled ? Color.CLEAR : color} isBold={isBoldText} isSmall={isSmall}>
        {children}
      </Content>
    </Container>
  );
};
