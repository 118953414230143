import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgCanvas(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.87a.5.5 0 01.751-.431l4.508 2.63a.5.5 0 010 .863l-4.508 2.63A.5.5 0 0110 8.128V6.264a.014.014 0 00-.014-.014H5.108a.018.018 0 00-.017.012 2.227 2.227 0 110-1.524c.003.007.01.012.017.012H9.985a.014.014 0 00.014-.014V2.87zM5.77 9.165a41.467 41.467 0 00-.198-.403C5.4 8.418 5.647 8 6.03 8h.53c.188 0 .361.106.445.276l.113.232.066.136c.412.85.765 1.578 1.214 2.12.438.531.918.822 1.6.822a.01.01 0 00.01-.01v-1.174a.5.5 0 01.766-.424l3.046 1.904a.5.5 0 010 .848l-3.046 1.904a.5.5 0 01-.765-.424v-1.114a.01.01 0 00-.01-.01c-1.239 0-2.111-.584-2.758-1.366-.568-.688-.997-1.574-1.382-2.37l-.09-.186z"
        fill="#7AA0CC"
      />
    </svg>
  );
}

export default SvgCanvas;
