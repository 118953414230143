export const WeeklyValues = [
  {
    label: 'Sun',
    value: '0'
  },
  {
    label: 'Mon',
    value: '1'
  },
  {
    label: 'Tue',
    value: '2'
  },
  {
    label: 'Wed',
    value: '3'
  },
  {
    label: 'Thu',
    value: '4'
  },
  {
    label: 'Fri',
    value: '5'
  },
  {
    label: 'Sat',
    value: '6'
  }
];
