import { H4, Paragraph } from '@pushwoosh/kit-typography';
import React from 'react';
import { branchNames } from '@src/common/constants/ab';
import { useStoreStatisticsByJourney, usePointOutputsStats } from '@src/store/hooks';
import { Item } from '@src/ui/form/styled';
import { Button } from '@src/ui/kit';
import { useUpdateABSplitterByJourneyId } from '@src/store/hooks/journeys/useUpdateABSplitterByJourneyId';
import { sendMixpanelEvent } from '@src/common/analytics';
import { Row } from './components/Row';
import { calculateChartsData } from './helpers/calculateChartsData';
import { Container, Header, WinnerWrapper, WinnerFooter, Error } from './styled';

type Props = {
  split: number[];
  pointId: string;
  goalId: string;
};

export function BranchTable(props: Props): JSX.Element {
  const { split, pointId, goalId } = props;

  const [handleUpdateABSplitterByJourneyId, isLoading] = useUpdateABSplitterByJourneyId();
  const [hasWinner, setHasWinner] = React.useState(false);

  const [error, setError] = React.useState(null);

  const statisticsByJourney = useStoreStatisticsByJourney();
  const pointOutputsStats = usePointOutputsStats();

  const pointStat = React.useMemo(
    () => pointOutputsStats.find((item) => item.toPointId === pointId),
    [pointId, pointOutputsStats]
  );
  const { abSplitStats } = statisticsByJourney;
  const statOutputs = abSplitStats?.[pointId]?.outputs;
  const data = calculateChartsData({ statOutputs, split, goalId });

  const handleSelectWinner = () => {
    const winnerSplit = data.reduce((acc, item) => {
      if (item.isWinner) {
        return [...acc, 100];
      }
      return [...acc, 0];
    }, []);

    handleUpdateABSplitterByJourneyId({ pointId, split: winnerSplit })
      .then(() => {
        if (error !== null) {
          setError(null);
        }
      })
      .catch(() => setError('Server temporarily unavailable. Try again in a moment.'));

    sendMixpanelEvent({
      eventName: 'CustomerJourneyABTestWinnerSelected'
    });
  };

  return (
    <>
      <Container>
        <Header>
          <H4>Total entries</H4>
          <Paragraph>{pointStat.count}</Paragraph>
        </Header>
        {data.map((item, index) => {
          const { branchStat, branchValue, charts, isWinner } = item;
          if (isWinner && !hasWinner) {
            setHasWinner(true);
          }

          return (
            <Row
              key={index}
              branchName={branchNames[index]}
              branchValue={branchValue}
              visits={branchStat?.visits ?? 0}
              goals={branchStat?.goals ?? 0}
              conversion={branchStat?.conversion ?? 0}
              isBaseline={index === 0}
              isSignificant={branchStat?.significant ?? false}
              zScore={branchStat?.zScore ?? 0}
              pValue={branchStat?.pValue ?? 0}
              charts={charts}
              isWinner={isWinner}
              uplift={branchStat?.uplift}
              baseLineConversion={data[0].branchStat?.conversion}
            />
          );
        })}
      </Container>
      <Item>
        {hasWinner ? (
          <WinnerWrapper>
            <H4>Congratulations! You have a statistically significant winner</H4>
            <p>
              We’re 90% confident in the test results. Deactivate less effective branches to
              optimize the campaign effectiveness. After deactivation, new users will be directed to
              the winning branch, while existing users in deactivated branches will continue until
              completion.
            </p>
            <WinnerFooter>
              <Button onClick={handleSelectWinner} isLoading={isLoading}>
                KEEP ONLY WINNER
              </Button>
              {error !== null && <Error>{error}</Error>}
            </WinnerFooter>
          </WinnerWrapper>
        ) : (
          <Paragraph>
            The test results have not reached statistical significance. To accurately identify a
            winner, continue testing and monitor branch performance.
          </Paragraph>
        )}
      </Item>
    </>
  );
}
