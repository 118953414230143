import * as React from 'react';

// Components
import { Icon, IconEnum } from '@src/ui/kit/Icon';

// Types
import { IProps } from './SplitedList.types';

// Styled
import { SplittedListWrap, IconWrap } from './styled';

export function SplitedList(props: IProps): JSX.Element {
  const {
    children,
    direction = 'horizontal',
    separatorType = IconEnum.SEPARATOR,
    separatorFill,
    renderSeparator
  } = props;

  return (
    <SplittedListWrap direction={direction}>
      {React.Children.map(children, (child, index): React.ReactNode => {
        if (index === React.Children.count(children) - 1 || !child) {
          return child;
        }

        return (
          <>
            {child}
            {renderSeparator ? (
              renderSeparator()
            ) : (
              <IconWrap>
                <Icon fill={separatorFill} type={separatorType} />
              </IconWrap>
            )}
          </>
        );
      })}
    </SplittedListWrap>
  );
}
