import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgArrowRoundUpSmallFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 8a7 7 0 1014 0A7 7 0 001 8zm10.53-.47a.75.75 0 01-1.06 0L8.75 5.81V12a.75.75 0 01-1.5 0V5.81L5.53 7.53a.75.75 0 01-1.06-1.06l3-3a.75.75 0 011.06 0l3 3a.75.75 0 010 1.06z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgArrowRoundUpSmallFilled;
