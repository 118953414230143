import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgAudienceBasedEntryPoint(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 15c0-2.762 2.238-5 5-5s5 2.238 5 5-2.238 5-5 5-5-2.238-5-5zM8 27.933l.002-.105c.064-2.692 2.373-5.343 5.03-5.778.052-.008-.155.02-.214.028-.008.002-.008.002 0 0l.026-.003c2.916-.405 5.441-.41 8.323-.012l.042.006-.016-.002a4.688 4.688 0 00-.21-.027c2.632.437 4.943 3.09 5.015 5.757l.002.127v.135A2 2 0 0124.06 30H9.946a2 2 0 01-1.946-1.946L8 27.933zM25 15a4.993 4.993 0 01-2.139 4.101c-.383.268-.329.899.139.899 2.762 0 5-2.238 5-5s-2.238-5-5-5c-.468 0-.522.63-.139.899A4.993 4.993 0 0125 15zm4.925 15H26.73c.344 0 .515 0 .66-.021a2 2 0 001.689-1.69c.021-.144.021-.315.021-.659 0-.713 0-1.07-.054-1.47-.182-1.343-.979-2.69-2.06-3.667-.168-.152-.043-.46.18-.43l.036.005-.009-.001a4.688 4.688 0 00-.21-.027c2.632.437 4.943 3.09 5.015 5.757l.002.127v.135A2 2 0 0130.06 30h-.135zm-2.723-7.932h.002c.003 0 .003 0 0 0h-.002z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgAudienceBasedEntryPoint;
