import * as React from 'react';
import { sendMixpanelEvent } from '@src/common/analytics';
import { Button, DefaultButtons, IconEnum, Modal, Section } from '@src/ui/kit';
import { H4, Paragraph } from '@pushwoosh/kit-typography';
import { IUniqueId } from '@src/common/types/entities';
import {
  useCurrentJourneyId,
  useEditJourneyById,
  useGetJourneyById,
  useGetPointById,
  useUpdatePoint
} from '@src/store/hooks';
import { useForm } from '@src/ui/hooks';
import { RadioButtonGroup } from '@src/ui/kit/RadioButtonGroup';
import { RadioButton } from '@src/ui/kit/RadioButton';
import { useStoreActions } from '@src/store/store';
import { JourneyStatus, REACHABILITY_CHECK_TYPES } from '@src/common/constants';
import { TReachabilityCheckPoint } from '@src/common/types/points';

interface IProps {
  pointId: IUniqueId['id'];
  closeModal: () => void;
}

function ReachabilityCheck(props: IProps): JSX.Element {
  const { pointId, closeModal } = props;

  const journeyId = useCurrentJourneyId();
  const getPointById = useGetPointById();
  const getJourneyById = useGetJourneyById();
  const updatePoint = useUpdatePoint();
  const editJourneyById = useEditJourneyById();
  const editPoint = useStoreActions((actions) => actions.points.update);

  const point = getPointById(pointId) as TReachabilityCheckPoint;
  const { status } = getJourneyById(journeyId);

  const isJourneyActive = status === JourneyStatus.RUNNING;

  const showDocumentationMenu = useStoreActions((actions) => actions.documentationMenu.show);

  const { values, setFieldValue, handleSubmit } = useForm({
    initialValues: {
      filterType: point.data.filterType || REACHABILITY_CHECK_TYPES.PUSH
    },
    onSubmit(formValues): void {
      const newPoint = {
        id: point.id,
        data: {
          filterType: formValues.filterType
        }
      };

      if (!isJourneyActive) {
        updatePoint(newPoint);
        closeModal();
      } else {
        editPoint(newPoint);
        editJourneyById(journeyId);
        closeModal();
      }

      sendMixpanelEvent({
        eventName: 'ReachabilityCheckConfigured',
        eventData: {
          FilterType: formValues.filterType
        }
      });
    }
  });

  const handleCancel = React.useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleChangeMode = React.useCallback(
    (value) => {
      setFieldValue('filterType', value);
    },
    [setFieldValue]
  );

  const handleShowDocumentation = React.useCallback(() => {
    showDocumentationMenu();
  }, [showDocumentationMenu]);

  return (
    <Modal
      title="Reachability check"
      footerRight={
        <DefaultButtons onClickActionButton={handleSubmit} onClickCancelButton={handleCancel} />
      }
      footerLeft={
        <Button
          color="primary"
          size="field"
          view="ghost"
          iconPosition="left"
          iconType={IconEnum.DOCS_MEDIUM_LINED}
          onClick={handleShowDocumentation}
        >
          How to Use
        </Button>
      }
      isOpen
    >
      <Section direction="column">
        <Paragraph>
          Here, we’ll split the journey travelers between two branches, depending on whether they
          are reachable via a channel you choose to check.
        </Paragraph>
      </Section>
      <Section direction="column">
        <H4>Reachable by:</H4>
        <RadioButtonGroup direction="column" value={values.filterType} onChange={handleChangeMode}>
          <RadioButton label="Push Notifications" value={REACHABILITY_CHECK_TYPES.PUSH} />
          <RadioButton label="Email" value={REACHABILITY_CHECK_TYPES.EMAIL} />
        </RadioButtonGroup>
        <Paragraph>
          For those who are not reachable for pushes or emails, consider reaching out by other
          available means – check out the remaining channel reachability or use In-Apps to ask users
          for a subscription.
        </Paragraph>
      </Section>
    </Modal>
  );
}

export default React.memo(ReachabilityCheck);
