import { IArgsGA } from './types';

declare global {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  interface Window {
    ym: any;
  }
}

export const sendEventToGA = ({
  hitType = 'event',
  eventCategory = 'CJ',
  eventAction,
  eventLabel,
  eventValue = 1
}: IArgsGA): void => {
  const { ga } = window as any;
  if (typeof ga === 'function' && typeof ga.getAll === 'function') {
    ga(
      `${ga.getAll()[0].get('name')}.send`,
      hitType,
      eventCategory,
      eventAction,
      eventLabel,
      eventValue
    );
  }
};

type TTypeYandexMetrika =
  | 'СjGoToDocumentation'
  | 'CjToCPFromMenu'
  | 'CjToCPFromSidebar'
  | 'cj-widjet-helper'
  | 'cj-widjet-helper-item'
  | 'JB_MENU_Subscription_based_mobile_apps'
  | 'JB_Ecommerce_Retail'
  | 'JB_Gaming'
  | 'JB_Media'
  | 'JB_SaaS';

const NUMBER_COUNTER = 61142524;

export function sendYandexMetrika(targetName: TTypeYandexMetrika): void {
  if (window.ym) {
    window.ym(NUMBER_COUNTER, 'reachGoal', targetName);
  }
}
