import React from 'react';
import { cloneDeep } from 'lodash';

// Components
import { TagValueList } from '@src/ui/features/SetTags/components/TagValueList';
import { Select, TSelectOption } from '@src/ui/kit/Select';
import { H3, H4 } from '@pushwoosh/kit-typography';
import { Button } from '@src/ui/kit/Button';

// Enums
import { IconEnum } from '@src/ui/kit/Icon';

// Helpers
import { getDefaultValueTag } from '@src/common/helpers';

// Types
import { Props } from './ManualTagsList.types';

// Styled
import { Container, Tag, Name, Value, Remove, Controls, Description, Labels } from './styled';

const MAX_TAGS = 10;

export const ManualTagsList = (props: Props): JSX.Element => {
  const { names, values, existTags, setFieldValue, setFieldTouched, removeDisabled } = props;
  const { updateTags } = values;

  const handleChangeOperation = React.useCallback(
    (type, indexTag) => {
      const newUpdateTags = updateTags.map((item, index) => {
        if (index === indexTag) {
          return { ...item, type };
        }

        return item;
      });

      setFieldValue(names.updateTags, newUpdateTags);
    },
    [updateTags, names.updateTags, setFieldValue]
  );

  const handleChangeName = React.useCallback(
    (name, indexTag) => {
      const { type } = existTags.find((item) => item.name === name) || {};
      const newUpdateTags = updateTags.map((item, index) => {
        if (index === indexTag) {
          const existTag = existTags.filter((item) => item.name === name && item.type === type);

          if (existTag.length === 0) {
            return {
              name,
              type
            };
          }

          return {
            ...existTag[0],
            value: getDefaultValueTag(existTag[0].type)
          };
        }
        return item;
      });
      setFieldValue(names.updateTags, newUpdateTags);
    },
    [updateTags, existTags, names, setFieldValue]
  );

  const handleUpdateValue = React.useCallback(
    (value, indexTag) => {
      const newTags = cloneDeep(updateTags);

      newTags[indexTag].value = value;
      setFieldValue(names.updateTags, [...newTags]);
    },
    [names, updateTags, setFieldValue]
  );

  const handleAddTag = React.useCallback(() => {
    setFieldValue(names.updateTags, [...updateTags, {}]);
  }, [names, updateTags, setFieldValue]);

  const handleRemoveTag = React.useCallback(
    (indexTag: number) => {
      setFieldValue(names.updateTags, [...updateTags.filter((_, index) => index !== indexTag)]);
    },
    [names, updateTags, setFieldValue]
  );

  const isAddNewTag = updateTags.length <= existTags.length && updateTags.length < MAX_TAGS;

  const getTagNameOptions = React.useMemo((): TSelectOption[] => {
    const options = existTags.filter((item) => {
      const isExistUpdateTags = updateTags.filter((itemUpdate) => itemUpdate.name === item.name);
      if (isExistUpdateTags.length) {
        return false;
      }
      return true;
    });
    return options.map((item) => ({
      label: item.name,
      value: item.type
    }));
  }, [existTags, updateTags]);

  return (
    <Container>
      <H3>Manual Tag Value</H3>
      <Description>Select the tag and set the value manually.</Description>
      {updateTags.length > 0 && (
        <Labels>
          <Name>
            <H4>Tag</H4>
          </Name>
          <Value>
            <H4>Value</H4>
          </Value>
        </Labels>
      )}
      {updateTags.map((item, index) => {
        if (typeof item.value === 'object' && !Array.isArray(item.value)) {
          return null;
        }

        return (
          <Tag key={item.id || index}>
            <Name>
              <Select
                name="name"
                value={
                  item.name
                    ? {
                        label: item.name,
                        value: item.name
                      }
                    : null
                }
                options={getTagNameOptions}
                placeholder="Select"
                isSearchable
                onChange={(currentValue: TSelectOption): void =>
                  handleChangeName(currentValue.label, index)
                }
                onBlur={(): boolean => false}
              />
            </Name>
            <Value>
              <TagValueList
                index={index}
                tag={item}
                onChangeTag={handleUpdateValue}
                onChangeOperation={handleChangeOperation}
                setFieldTouched={setFieldTouched}
              />
            </Value>
            <Remove>
              <Button
                disabled={removeDisabled}
                view="ghost"
                color="secondary"
                size="compact"
                onClick={(): void => handleRemoveTag(index)}
                iconType={IconEnum.CLOSE_SMALL}
              />
            </Remove>
          </Tag>
        );
      })}
      <Controls>
        <Button
          view="ghost"
          color="secondary"
          size="compact"
          iconPosition="left"
          iconType={IconEnum.PLUS_MEDIUM}
          onClick={handleAddTag}
          disabled={!isAddNewTag}
        >
          {isAddNewTag ? 'Manual Value' : 'Update User Profile limit reached'}
        </Button>
      </Controls>
    </Container>
  );
};
