import styled from 'styled-components';

import { Color, Spacing } from '@pushwoosh/kit-constants';

export const ButtonsWrap = styled.div`
  display: grid;
  grid-template: 'auto auto';
  gap: ${Spacing.S3};
`;

type IconWrapProps = {
  color: Color;
};

export const StyledMessageIconWrapper = styled.div<IconWrapProps>`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: ${({ color }) => color};
  padding: ${Spacing.S3};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
