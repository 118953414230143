// Api
import { GetOptions, HttpClient } from '@pushwoosh/http-client';

// Helpers
import { getApiOrigin } from '@src/store/helpers';

// Types
import { TFetchByAppCodeResponse } from './types';

export const service = {
  async fetchByAppCode(applicationCode: string, api: HttpClient): Promise<TFetchByAppCodeResponse> {
    const options: GetOptions<TFetchByAppCodeResponse, { code: string }, never> = {
      params: {
        code: applicationCode
      },
      withAuthorization: true
    };

    // eslint-disable-next-line camelcase
    return api.get<TFetchByAppCodeResponse, { code: string }, never>(
      `${getApiOrigin()}/api/application/:code/preset`,
      options
    );
  }
};
