import React from 'react';
import { Footnote, Paragraph } from '@pushwoosh/kit-typography';
import { TimeStepper } from '@src/ui/kit/TimeStepper';
import { Switch } from '@src/ui/kit';
import { JourneyStatus } from '@src/common/constants';
import { Item } from '@src/ui/form/styled';
import { SplitFlowProps } from './SplitFlow.types';
import { Label, TimeStepperWrap } from './styled';

const SEVEN_DAYS_IN_SECONDS = 604800;
const ONE_MINUTE_IN_SECONDS = 60;

export function SplitFlow(props: SplitFlowProps): JSX.Element {
  const { journeyStatus, values, names, setValues, setFieldValue } = props;

  const isDisabled = journeyStatus === JourneyStatus.RUNNING;

  const handleSwitchBehavioralSplitter = React.useCallback(
    (value: boolean) => {
      setValues({
        ...values,
        [names.enabledBehaviorSplitter]: value,
        [names.intervalBehaviorSplitter]: value ? 86400 : 0
      });
    },
    [setValues, names, values]
  );

  const handleChangeIntervalBehavioralSplitter = React.useCallback(
    (name: string, value: number) => {
      setFieldValue(name, value);
    },
    [setFieldValue]
  );

  return (
    <div>
      <Switch
        isDisabled={isDisabled}
        defaultEnabled={values.enabledBehaviorSplitter}
        onChange={handleSwitchBehavioralSplitter}
      >
        Split flow if message is not opened
      </Switch>
      <Item>
        <Paragraph>
          Split flow depending on whether this message is opened or ignored (not opened). If the
          user opens the message within the specified time period, the “opened” branch will be
          activated.
        </Paragraph>
      </Item>
      {values.enabledBehaviorSplitter && (
        <>
          <Label>How long to wait to check message opens</Label>
          <TimeStepperWrap>
            <TimeStepper
              disabled={isDisabled}
              name={names.intervalBehaviorSplitter}
              value={values.intervalBehaviorSplitter}
              onChange={handleChangeIntervalBehavioralSplitter}
              maxInSeconds={SEVEN_DAYS_IN_SECONDS}
              minInSeconds={ONE_MINUTE_IN_SECONDS}
            />
          </TimeStepperWrap>
          <Footnote>
            If the user opens the message within the specified time
            <br />
            period, the “opened” branch will be activated. Max 7 days.
          </Footnote>
        </>
      )}
    </div>
  );
}
