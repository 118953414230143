import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgCloseMedium(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.995 6.934a.75.75 0 10-1.06 1.06L10.938 12l-4.005 4.005a.75.75 0 001.06 1.06L12 13.062l4.005 4.005a.75.75 0 001.06-1.06L13.062 12l4.005-4.005a.75.75 0 10-1.06-1.061L12 10.939 7.995 6.934z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgCloseMedium;
