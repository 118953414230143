import React from 'react';

// Components
import { FormInput } from '@src/ui/form/FormInput';
import { H4 } from '@pushwoosh/kit-typography';
import { Button, IconEnum } from '@src/ui/kit';
import { HeaderItem } from '@src/ui/features/Webhook/components/HeaderItem';
import { EmptyHeader } from '@src/ui/features/Webhook/components/EmptyHeader';
import { FormSelect } from '@src/ui/form/FormSelect';

// Types
import { IUrlSettingsProps } from './UrlSettings.types';
import { IWebhookFormValues } from '../../Webhook.types';

// Styled
import { UrlSettingsContainer, AddHeaderItem, UrlContainer } from './styled';

export function UrlSettings(props: IUrlSettingsProps): JSX.Element {
  const { values, names, setFieldValue, errors, onBlurField } = props;

  const removeHeaderItem = React.useCallback(
    (index: number) => {
      const newValues = [...values.headers];

      newValues.splice(index, 1);

      setFieldValue(names.headers, newValues);
    },
    [names.headers, setFieldValue, values.headers]
  );

  const addHeaderItem = React.useCallback(() => {
    const newValues = [...values.headers, { name: '', value: '' }];

    setFieldValue(names.headers, newValues);
  }, [values.headers, setFieldValue, names.headers]);

  return (
    <UrlSettingsContainer>
      <UrlContainer>
        <FormInput
          name={names.url}
          value={values.url}
          onChange={setFieldValue}
          onBlur={onBlurField}
          label="URL"
          error={errors.url}
          placeholder="https://api.domain.com"
        />
        <FormSelect
          name="method"
          label="Request Type"
          value="POST"
          options={[{ label: 'POST', value: 'POST' }]}
          onChange={() => true}
          isDisabled
        />
      </UrlContainer>
      <>
        <H4>Headers:</H4>
        {values.headers.length === 0 && <EmptyHeader />}
        {values.headers.map((header, index) => (
          <HeaderItem
            key={index}
            header={header}
            index={index}
            onBlur={onBlurField}
            setFieldValue={setFieldValue}
            name={names.headers}
            removeHeaderItem={removeHeaderItem}
            errors={errors as IWebhookFormValues}
          />
        ))}
        <AddHeaderItem>
          <Button
            color="secondary"
            size="compact"
            view="ghost"
            iconPosition="left"
            iconType={IconEnum.PLUS_SMALL}
            onClick={addHeaderItem}
          >
            Add header
          </Button>
        </AddHeaderItem>
      </>
    </UrlSettingsContainer>
  );
}
