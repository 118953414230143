import React from 'react';

// Components
import { StatItem, StatWrap } from '@src/ui/features/PointStat';

// Types
import { MainStatProps } from './MainStat.types';

export function MainStat(props: MainStatProps): JSX.Element {
  const { sent, clicked = 0, opened = 0, unsubscribed = 0 } = props;

  const clickedPercent = clicked === 0 ? '0%' : `${((100 * clicked) / sent).toFixed(2)}%`;
  const openedPercent = opened === 0 ? '0%' : `${((100 * opened) / sent).toFixed(2)}%`;

  return (
    <StatWrap>
      <StatItem title="Sent" value={sent} />
      <StatItem title="Opened" value={opened} percent={openedPercent} />
      <StatItem title="Clicked" value={clicked} percent={clickedPercent} />
      <StatItem title="Unsubsribed" value={unsubscribed} />
    </StatWrap>
  );
}
