import React from 'react';

// Constants
import { Color } from '@pushwoosh/kit-constants';

// Components
import { Icon, IconEnum } from '../Icon';

// Types
import { MessageProps } from './Message.types';

// Styled
import {
  MessageContainer,
  Header,
  CloseIcon,
  StyledH4,
  StyledFootnote,
  IconWrap,
  Wrap
} from './styled';

export function Message(props: MessageProps): JSX.Element {
  const {
    color = Color.SOFT_LIGHT,
    withClosing,
    fullWidth = false,
    title,
    message,
    onClose = () => null,
    icon: IconBefore
  } = props;

  return (
    <Wrap>
      {IconBefore && <IconWrap color={color}>{IconBefore}</IconWrap>}
      <MessageContainer fullWidth={fullWidth} color={color}>
        <Header>
          <StyledH4>{title}</StyledH4>
          {withClosing && (
            <CloseIcon onClick={onClose}>
              <Icon type={IconEnum.CLOSE_SMALL} />
            </CloseIcon>
          )}
        </Header>
        {message && <StyledFootnote>{message}</StyledFootnote>}
      </MessageContainer>
    </Wrap>
  );
}
