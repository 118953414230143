import * as React from 'react';

// Styled
import { StyledTooltipInfoField, StyledText } from '@src/canvas/components/TooltipInfoField/styled';

// Types
import { Color, FontWeight } from '@pushwoosh/kit-constants';
import { ITooltipInfoFieldProps } from './TooltipInfoField.types';

function TooltipInfoField(props: ITooltipInfoFieldProps): JSX.Element {
  const { label, value } = props;

  return (
    <StyledTooltipInfoField>
      <StyledText fontWeight={FontWeight.REGULAR} color={Color.PHANTOM}>{`${label}:`}</StyledText>
      <StyledText fontWeight={FontWeight.MEDIUM} color={Color.CLEAR}>
        {value.toString()}
      </StyledText>
    </StyledTooltipInfoField>
  );
}

export default React.memo(TooltipInfoField);
