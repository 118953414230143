import { useCallback } from 'react';

// Store
import { useStoreActions } from '@src/store/store';

// Enums
import { IVector2 } from '@src/common/types/types';
import { IUniqueId } from '@src/common/types/entities';

type TUpdatePositionAtPoint = (pointId: IUniqueId['id'], position: IVector2) => void;

export function useActionUpdatePositionAtPoint(): TUpdatePositionAtPoint {
  const actionUpdatePositionAtPoint = useStoreActions(
    (actions): typeof actions.points.updatePositionAtPoint => actions.points.updatePositionAtPoint
  );

  const updatePositionAtPoint = useCallback<TUpdatePositionAtPoint>(
    (pointId, position): void => {
      actionUpdatePositionAtPoint({
        pointId,
        position
      });
    },
    [actionUpdatePositionAtPoint]
  );

  return updatePositionAtPoint;
}
