import styled from 'styled-components';
import { Shadow, Color } from '@pushwoosh/kit-constants';

const HEADER_HEIGHT = '72px';
const SIDEBAR_WIDTH = '260px';

export const LayoutWrap = styled.article`
  position: relative;
  display: flex;
  min-width: 1024px;
  min-height: 100%;
`;

export const HeaderWrap = styled.header`
  width: 100%;
  height: ${HEADER_HEIGHT};
`;

export const SidebarWrap = styled.aside`
  display: flex;
  flex-direction: column;
  min-width: ${SIDEBAR_WIDTH};
  width: ${SIDEBAR_WIDTH};
  background: ${Color.CLEAR};
  box-shadow: ${Shadow.SMALL};
  overflow: auto;
  height: 100vh;
  position: fixed;
`;

type ContentProps = {
  isHiddenSidebar: boolean;
};
export const Content = styled.div<ContentProps>`
  flex: 1;
  margin-left: ${({ isHiddenSidebar }) => (isHiddenSidebar ? '0' : '260px')};
`;
