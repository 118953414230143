import React from 'react';

// UI Components
import { ExtraOneButton, Modal, Section, Tag } from '@src/ui/kit';
import { RowDetails, BlockDetail } from '@src/ui/kit/DetailsPoint/styled';
import { useGetPointById } from '@src/store/hooks';

// Helpers
import { addZero, decodeDelay, formatTime } from '@src/common/helpers';

// Types
import { TWaitPoint } from '@src/common/types/points';
import { ModalType } from '@src/common/constants/modals';
import { TModal } from '@src/common/types/modal';
import { useCloseModalByType } from '@src/store/hooks/modals/useCloseModalByType';
import { DelayOptions, WaitMode } from '@src/common/constants/wait';
import { DropOffStat } from '../../DropOffStat';
import { useTimeDelay } from '../hooks/useTimeDelay';
import { getTimeDelayTitle } from '../helpers/getTimeDelayTitle';
import { getDayOfWeek } from '../helpers/getDayOfWeek';

interface IProps {
  delay: number;
  exactTime: TWaitPoint['data']['exactTime'];
  dynamicTime: TWaitPoint['data']['dynamicTime'];
  exactDateTime: TWaitPoint['data']['exactDateTime'];
  exactWeekDaysTime: TWaitPoint['data']['exactWeekDaysTime'];
  pointId: string;
}

export function DetailWaitUntil(props: IProps): JSX.Element {
  const { delay, exactTime, pointId, dynamicTime, exactDateTime, exactWeekDaysTime } = props;
  const getPointById = useGetPointById();
  const timeValue = formatTime(decodeDelay(delay));
  const closeModalByType = useCloseModalByType();
  const point = getPointById(pointId) as TWaitPoint;
  const { waitMode } = useTimeDelay(point);
  const modalSettings = {
    type: ModalType.EDITING_FORM_POINT,
    data: {
      pointId
    }
  } as TModal;

  return (
    <Modal
      title="Time Delay"
      footerRight={
        <ExtraOneButton
          actionButtonName="Close"
          onClickActionButton={() => closeModalByType(ModalType.DETAIL_POINT)}
        />
      }
      isOpen
    >
      <Section direction="column">
        <DropOffStat pointId={pointId} modal={modalSettings} />
      </Section>
      <Section direction="column">
        <Tag label="Time delay option:" value={getTimeDelayTitle(waitMode)} />
        <BlockDetail>
          {delay && <Tag label="Time period:" value={timeValue} />}
          {exactTime && (
            <Tag label="Exact time:" value={`${exactTime.hour} hour, ${exactTime.minute} minute`} />
          )}
          {dynamicTime && (
            <RowDetails>
              {dynamicTime.tagName && <Tag label="Tag:" value={dynamicTime?.tagName} />}
              {dynamicTime.eventName && (
                <>
                  <Tag label="Event:" value={dynamicTime.eventName} />
                  <Tag label="Attribute:" value={dynamicTime.eventAttribute} />
                </>
              )}
              {dynamicTime.direction === DelayOptions.RIGHT_ON_TIME ? (
                <Tag label="Delay Option:" value="Right On Time" />
              ) : (
                <Tag
                  label="Delay Option:"
                  value={`${dynamicTime.direction} ${dynamicTime.duration} ${dynamicTime.unit}`}
                />
              )}
            </RowDetails>
          )}
          {waitMode === WaitMode.Date && (
            <Tag
              label="Exact time:"
              value={`${addZero(exactDateTime?.day)}-${addZero(exactDateTime?.month)}-${
                exactDateTime?.year
              } ${addZero(exactDateTime?.hour)}:${addZero(exactDateTime?.minute)}`}
            />
          )}
          {waitMode === WaitMode.Week && (
            <Tag
              label="Exact time:"
              value={`${getDayOfWeek(Number(Object.keys(exactWeekDaysTime)?.[0]))} ${addZero(
                Object.values(exactWeekDaysTime)?.[0].hour
              )}:${addZero(Object.values(exactWeekDaysTime)?.[0].hour)}`}
            />
          )}
        </BlockDetail>
      </Section>
    </Modal>
  );
}
