import styled from 'styled-components';
import { Color, FontSize, FontWeight, Spacing } from '@pushwoosh/kit-constants';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${Spacing.S2};
  padding-bottom: ${Spacing.S2};
`;

export const RadioInput = styled.input`
  display: none;
`;

type LabelProps = {
  checked: boolean;
  disabled: boolean;
};

export const Label = styled.label`
  font-size: ${FontSize.REGULAR};
  font-weight: ${FontWeight.REGULAR};
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  color: ${({ disabled }: LabelProps) => (disabled ? Color.LOCKED : Color.MAIN)};

  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: ${({ disabled }: LabelProps) => (disabled ? Color.PHANTOM : Color.CLEAR)};
    border: ${({ checked, disabled }: LabelProps) => {
      if (disabled) {
        return `2px solid ${Color.LOCKED}`;
      }
      return checked ? `6px solid ${Color.PRIMARY}` : `2px solid ${Color.PHANTOM}`;
    }};
    border-radius: 50%;
    margin-right: ${Spacing.S3};
  }
`;
