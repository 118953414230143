import styled from 'styled-components';
import { Spacing, Color, ShapeRadius } from '@pushwoosh/kit-constants';
import { Day } from '../Datepicker';

export const StyledDay = styled(Day)`
  &:not(:last-child) {
    margin-right: initial;
  }

  &:not(:nth-child(7n)) {
    margin-right: ${Spacing.S1};
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type DaypickerWrapProps = {
  isDisabled: boolean;
};

export const DaypickerWrap = styled.div<DaypickerWrapProps>`
  width: 282px;
  padding: ${Spacing.S5};
  background-color: ${Color.CLEAR};
  border: 1px solid ${Color.FORM};
  border-radius: ${ShapeRadius};
  display: flex;
  flex-wrap: wrap;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
`;
