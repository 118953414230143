import * as React from 'react';

// UI Components
import { Modal, ExtraOneButton, Section } from '@src/ui/kit';
import { DetailFrequencyCapping } from '@src/ui/features/FrequencyCapping/components/DetailFrequencyCapping';

// Helpers
import { defineFrequencyCapping } from '@src/common/helpers/defineFrequencyCapping';

// Store Hooks
import { useCloseAllModals, useGetJourneyById, useCurrentJourneyId } from '@src/store/hooks';

const descriptionIsRead =
  'Frequency capping for users entry is configured for this journey. Users will be able to enter this journey according to the settings below.';

export function ModalDetailFrequencyCapping(): JSX.Element {
  const closeAllModals = useCloseAllModals();

  const journeyId = useCurrentJourneyId();
  const getJourneyById = useGetJourneyById();
  const journey = getJourneyById(journeyId);

  const { isEnabled, period } = defineFrequencyCapping(journey);

  return (
    <Modal
      title="Frequency Capping"
      footerRight={<ExtraOneButton actionButtonName="Close" onClickActionButton={closeAllModals} />}
      isOpen
    >
      <Section>{descriptionIsRead}</Section>
      <DetailFrequencyCapping isEnabled={isEnabled} period={period} />
    </Modal>
  );
}
