import { thunk, actionOn } from 'easy-peasy';

import { TFilter } from '@src/common/types/filter';
import { IFilterModel } from './filter.types';

export const model: IFilterModel = {
  items: [],

  load: thunk(async (_, __, helpers): Promise<TFilter[]> => {
    const {
      injections: { filterService, api }
    } = helpers;

    const response = await filterService.fetch(api);

    return response.payload;
  }),

  onLoad: actionOn(
    (state): string => state.load.successType,
    (state, target): void => {
      state.items = target.result;
    }
  )
};
