import styled from 'styled-components';
import { Color, Spacing } from '@pushwoosh/kit-constants';

export const Row = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 305px 155px;
  grid-template-rows: repeat(2, auto);
  row-gap: ${Spacing.S3};
  column-gap: ${Spacing.S3};

  padding-left: ${Spacing.S3};
  border-left: 2px solid ${Color.PHANTOM};
`;

export const FilterValueWrap = styled.div`
  grid-column: 1/-1;
`;

export const Join = styled.div`
  position: absolute;
  left: -8px;
  bottom: -15px;

  svg {
    width: 14px;
    height: 14px;

    path {
      fill: ${Color.PHANTOM};
      stroke: ${Color.PHANTOM};
      stroke-width: 1px;
    }
  }
`;
