import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgZoomZoomOutMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8 9.75a.75.75 0 000 1.5h5a.75.75 0 000-1.5H8z" fill={props.fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 17a6.473 6.473 0 004.035-1.404l4.081 4.08a.5.5 0 00.707 0l.354-.353a.5.5 0 000-.707l-4.081-4.08A6.5 6.5 0 1010.5 17zm0-1.5a5 5 0 100-10 5 5 0 000 10z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgZoomZoomOutMediumLined;
