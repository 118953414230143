import styled from 'styled-components';
import { RadioButtonGroup } from '@src/ui/kit/RadioButtonGroup';
import { FormSelect } from '@src/ui/form/FormSelect';
import { FormTextarea } from '@src/ui/form/FormTextarea';
import { Spacing, Color } from '@pushwoosh/kit-constants';

export const StyledRadioButtonGroup = styled(RadioButtonGroup)``;

export const FormSelectWithOffset = styled(FormSelect)`
  margin-top: ${Spacing.S5};
  padding: 0;
  width: 100%;
`;

export const Option = styled.div`
  display: grid;
  box-sizing: border-box;

  padding: ${Spacing.S5};
  cursor: pointer;

  &:hover {
    background: ${Color.EXCEPTIONAL_LIGHT};
  }
`;

export const FormTextareaWithOffset = styled(FormTextarea)`
  margin: ${Spacing.S5} 0px ${Spacing.S1} 0px;
  padding: 0;
  width: 100%;
`;

export const MessageWrap = styled.div`
  margin-top: ${Spacing.S5};
`;
