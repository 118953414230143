import { action } from 'easy-peasy';

import { ISidebarModel } from './sidebar.types';

export const model: ISidebarModel = {
  isDraggingNewPoint: false,

  draggedPointInfo: null,

  dragPointStart: action((state, pointInfo): void => {
    state.isDraggingNewPoint = true;
    state.draggedPointInfo = pointInfo as typeof state.draggedPointInfo;
  }),

  dragPointEnd: action((state): void => {
    state.isDraggingNewPoint = false;
  })
};
