import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';

// Components
import { Select as SelectComponent, PREFIX } from '@src/ui/kit/Select';

export const Select = styled(SelectComponent)`
  .${PREFIX}__control {
    min-width: 100%;
    height: 36px;
  }
`;

export const Grid = styled.div<{ hasButton?: boolean }>`
  display: grid;
  grid-template-columns: ${(p) => (p.hasButton ? '1fr auto' : 'auto')};
  grid-gap: ${Spacing.S3};
`;
