import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgAddNotesPointFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={24} height={24} rx={4} fill="#7AA0CC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.02 5.867a.5.5 0 00-.683.183l-1.315 2.278a.5.5 0 00.183.683l3.464 2a.5.5 0 00.683-.183l1.315-2.278a.5.5 0 00-.183-.683l-3.464-2zm-3.067 4.312a.5.5 0 01.683-.183l3.464 2a.5.5 0 01.183.683l-2.061 3.57a.5.5 0 00.433.75h4.595a.75.75 0 010 1.5H7.8a.75.75 0 01-.7-.48c-.017-.044-.036-.087-.055-.13a.503.503 0 01-.024-.07l-.734-2.729a.5.5 0 01.05-.38l2.616-4.53z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgAddNotesPointFilled;
