import * as React from 'react';
import { Color } from '@pushwoosh/kit-constants';

// Components
import { Section } from '@src/ui/kit';

// Helpers
import { formatNumberDouble } from '@src/common/helpers/formatNumber';

// Styled
import { formatWeekDaysValues } from '@src/ui/features/SilentHours/helpers/formatWeekDaysValues';
import { WeeklyValues } from '@src/common/constants/datetime';
import { IChannelSilentHours, SilentHoursBehavior } from '@src/common/types/silentHours';
import { EnabledChannels, Hint, Row, SmallBadge, SpecifiedDaysWrapper, Title } from './styled';

// Types
import { PropsDetailSilentHours } from './DetailSilentHours.types';

const BEHAVIOR_TO_TEXT: Record<SilentHoursBehavior, string> = {
  waitAndSend: 'Send when Silent Hours End',
  dropAndGo: 'Cancel Message and Move Forward',
  waitAndDrop: 'Cancel Message, Then Forward Post-Silent Hours'
};

export const DetailSilentHours = (props: PropsDetailSilentHours): JSX.Element => {
  const { inAppParams, smsParams, pushParams, emailParams } = props;

  const channels: [string, IChannelSilentHours | undefined][] = [
    ['Silent Hours for Push Notifications', pushParams],
    ['Silent Hours for In-App Messages', inAppParams],
    ['Silent Hours for Emails', emailParams],
    ['Silent Hours for SMSs', smsParams]
  ];

  const enabledChannels = channels.filter((channel) => channel[1]?.enabled);

  return (
    <Section direction="column">
      {enabledChannels.map(([title, params]) => (
        <EnabledChannels key={title}>
          <Row>
            <Title>{title}:</Title>
            <SmallBadge color={Color.MAIN}>
              {formatNumberDouble(params.fromTime.hour)}:
              {formatNumberDouble(params.fromTime.minute)} -{' '}
              {formatNumberDouble(params.toTime.hour)}:{formatNumberDouble(params.toTime.minute)}
            </SmallBadge>
            <Hint>on users timezone</Hint>
          </Row>

          {params.weekDays.includes(true) && (
            <Row>
              <Title>Silent days:</Title>
              <SpecifiedDaysWrapper>
                {formatWeekDaysValues(params.weekDays).map((day, index) => (
                  <SmallBadge color={Color.MAIN} key={index}>
                    {WeeklyValues.find((item) => item.value === day).label}
                  </SmallBadge>
                ))}
              </SpecifiedDaysWrapper>
            </Row>
          )}

          <Row>
            <Title>Messages behavior:</Title>
            <SmallBadge color={Color.MAIN} isBoldText>
              {BEHAVIOR_TO_TEXT[params.behavior]}
            </SmallBadge>
          </Row>
        </EnabledChannels>
      ))}
    </Section>
  );
};
