import * as React from 'react';

// Store
import { useStoreActions, useStoreState } from '@src/store/store';

// Hooks
import {
  useSetMode,
  useAddNotifications,
  useCurrentJourneyId,
  useGetJourneyById
} from '@src/store/hooks';

// Enums
import { NotificationType, NOTIFICATION_TITLE_SUCCESS } from '@src/common/constants/notification';

// Helpers
import { getModeByJourneyStatus } from '@src/store/helpers';

// Types
import { TJourney } from '@src/common/types/journey';
import { TRequest } from '@src/store/models/requests/requests.types';
import { IUniqueId } from '@src/common/types/entities';
import { JourneyStatus } from '@src/common/constants';

type TUpdateData = {
  title?: TJourney['title'];
  params?: TJourney['params'];
};
type TActionUpdateById = (data: { id: IUniqueId['id']; title?: string }) => Promise<TJourney>;
type TUpdateById = (
  id: IUniqueId['id'],
  updateData?: TUpdateData,
  paramType?: 'silentHours' | 'frequencyCapping'
) => Promise<TJourney> | void;
type TResult = [TUpdateById, boolean];

export function useUpdateJourneyById(): TResult {
  const setMode = useSetMode();
  const journeyId = useCurrentJourneyId();
  const getJourneyById = useGetJourneyById();
  const currentJourney = getJourneyById(journeyId);
  const addNotifications = useAddNotifications();

  const updateById = useStoreActions((actions): TActionUpdateById => actions.journeys.updateById);
  const updateEntiti = useStoreActions((actions) => actions.journeys.updateEntiti);

  const request: TRequest = useStoreState<TRequest>(
    (state): TRequest => state.requests.entities['journeys.updateById']
  );

  const handleJourneyUpdateById: TUpdateById = React.useCallback<TUpdateById>(
    async (id, updateData, paramType): Promise<TJourney> => {
      if (currentJourney.status === JourneyStatus.PAUSED) {
        const newJourney: TJourney = {
          ...currentJourney,
          params: {
            ...currentJourney.params,
            ...updateData.params
          }
        };

        updateEntiti({ journey: newJourney, journeyId: id, paramType });

        return newJourney;
      }

      const journey: TJourney = await updateById({
        id,
        ...updateData
      });

      const mode = getModeByJourneyStatus(journey.status);
      setMode(mode);
      addNotifications([
        {
          title: NOTIFICATION_TITLE_SUCCESS.updateJourney,
          type: NotificationType.SUCCESS
        }
      ]);

      return journey;
    },
    [updateById, setMode, addNotifications, currentJourney, updateEntiti]
  );

  return [handleJourneyUpdateById, Boolean(request)];
}
