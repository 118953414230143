import { action } from 'easy-peasy';

// Types
import { IPointEditMenuModel } from './pointEditMenu.types';

export const model: IPointEditMenuModel = {
  isVisible: false,

  open: action((state): void => {
    state.isVisible = true;
  }),

  close: action((state): void => {
    state.isVisible = false;
  })
};
