import styled from 'styled-components';

// Constants
import { Spacing } from '@pushwoosh/kit-constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin: ${Spacing.S5} 0;
`;

export const Line = styled.div`
  display: grid;
  grid-template-columns: auto min-content;
  align-items: center;
  margin: ${Spacing.S0} 0;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: ${Spacing.S1};
`;
