import styled from 'styled-components';
import { Color, FontWeight, FontSize, LineHeight } from '@pushwoosh/kit-constants';

export const ButtonWrap = styled.div`
  border-radius: 8px;
  background-color: ${Color.CLEAR};
`;

export const ButtonField = styled.p`
  font-weight: ${FontWeight.MEDIUM};
  font-size: ${FontSize.REGULAR};
  line-height: ${LineHeight.REGULAR};
  color: ${Color.MAIN};
`;

export const StyledButton = styled.button`
  height: 52px;
  border: transparent;
  outline: transparent;
  display: flex;
  align-items: center;
  padding: 16px 20px;
  cursor: pointer;
  background: ${Color.CLEAR};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06), 0px 10px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  &:hover {
    background: ${Color.BRIGHT_LIGHT};

    ${ButtonField} {
      color: ${Color.PRIMARY_HOVER};
    }
  }

  &:active {
    opacity: 0.5;
  }
`;
