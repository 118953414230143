import styled from 'styled-components';

interface IIconWrapperProps {
  rectFill?: string;
  fill?: string;
}

export const IconWrapper = styled.div`
  & > svg {
    display: block;

    ${({ rectFill, fill }: IIconWrapperProps) => {
      if (rectFill) {
        return `
          & > rect {
            fill: ${rectFill};
          }
          
          & path {
            fill: ${fill};
          }
        `;
      }
      return '';
    }}}
  }
`;
