// Helpers
import { formatNumber } from '@src/common/helpers';

export function getPercentageOfTotal(total: number, value: number): string {
  const valueOnePercent = total * 0.01;

  const result = valueOnePercent && value ? value / valueOnePercent : 0;

  return `${formatNumber(result, '0.[0]')}%`;
}
