import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgWarningRoundSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.5C4.96 2.5 2.5 4.96 2.5 8s2.46 5.5 5.5 5.5 5.5-2.46 5.5-5.5S11.04 2.5 8 2.5zM1 8c0-3.869 3.13-7 7-7 3.869 0 7 3.13 7 7 0 3.869-3.13 7-7 7-3.869 0-7-3.13-7-7zm6.24-3a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v3.5a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5V5zm.51 5a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h.5a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-.5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgWarningRoundSmallLined;
