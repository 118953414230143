import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgArrowRoundDownSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.465 10.86L5.136 8.53a.75.75 0 111.061-1.06L7.25 8.523V5.667a.75.75 0 111.5 0v2.856L9.803 7.47a.75.75 0 111.06 1.06L8.53 10.864a.747.747 0 01-.523.22H8a.748.748 0 01-.535-.225z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.583 8A6.583 6.583 0 111.417 8a6.583 6.583 0 0113.166 0zM8 13.083A5.083 5.083 0 108 2.917a5.083 5.083 0 000 10.166z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgArrowRoundDownSmallLined;
