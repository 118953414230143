import styled from 'styled-components';
import { Color, FontWeight, Spacing } from '@pushwoosh/kit-constants';

export const StyledCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledConditions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${Spacing.S2};
  color: ${Color.LOCKED};
  font-weight: ${FontWeight.REGULAR};
`;
