import styled from 'styled-components';
import { Color, Shadow } from '@pushwoosh/kit-constants';

export const DraggableContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: ${Color.CLEAR};
  border-radius: 8px;
  box-shadow: ${Shadow.REGULAR};
  z-index: 10;
  height: 40px;
  width: 264px;
  display: flex;
  align-items: center;
`;
