import styled from 'styled-components';

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  user-select: none;
`;

export const RichMediaPreviewIFrameContainer = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 200%;
  height: 200%;

  border-radius: 4px;

  transform: scale(0.5);
  transform-origin: 0 0;
  user-select: none;
`;
