// Constants
import { OperatorTypeEnum, AttributeTypeEnum } from '@src/common/constants/attribute';

// Types
import { IEditOnePointValues } from '@src/ui/features/EditEvents/components/FormEditOneEvent';
import { TFormErrors } from '@src/ui/hooks/useForm';
import { IEventPointCondition } from '@src/common/types/points';

const EVENT_ERROR_TEXT = 'Please choose an event';
const ATTRIBUTE_ERROR_TEXT = 'Please specify an attribute';
const OPERATOR_ERROR_TEXT = 'Please select an operator';
const VALUE_ERROR_TEXT = 'Please specife value(s) for the attribute';

const BETWEEN_FIRST_VALUE_ERROR_TEXT = 'Please specify the first value';
const BETWEEN_TEXT_SEPARATOR = ' and ';
const BETWEEN_SECOND_VALUE_ERROR_TEXT = 'Please specify the second value';

const LIST_ERROR_TEXT = 'Please add at least one value for the attribute';

export function validateFormEvent(values: IEditOnePointValues): TFormErrors<IEditOnePointValues> {
  const errors: TFormErrors<IEditOnePointValues> = {};

  if (!values.eventCode) {
    errors.eventCode = EVENT_ERROR_TEXT;
  }

  if (values.eventConditions.length > 0) {
    const errorsEventConditions = values.eventConditions.reduce(
      (acc, condition, index): TFormErrors<IEventPointCondition>[] => {
        const conditionErrors: TFormErrors<IEventPointCondition> = {};

        if (!condition.attributeName) {
          conditionErrors.attributeName = ATTRIBUTE_ERROR_TEXT;
        }
        if (!condition.type) {
          conditionErrors.type = ATTRIBUTE_ERROR_TEXT;
        }

        if (!condition.operator || condition.operator === OperatorTypeEnum.UNKNOWN) {
          conditionErrors.operator = OPERATOR_ERROR_TEXT;
        } else {
          if (condition.value === undefined || condition.value === '' || condition.value === null) {
            conditionErrors.value = VALUE_ERROR_TEXT;
          }

          if (Array.isArray(condition.value)) {
            if (condition.operator === OperatorTypeEnum.BETWEEN) {
              const err = [];

              if (!condition.value[0]) {
                err.push(BETWEEN_FIRST_VALUE_ERROR_TEXT);
              }

              if (!condition.value[1]) {
                err.push(BETWEEN_SECOND_VALUE_ERROR_TEXT);
              }

              conditionErrors.value = err.join(BETWEEN_TEXT_SEPARATOR);
            }

            if (AttributeTypeEnum.LIST && condition.value.length === 0) {
              conditionErrors.value = LIST_ERROR_TEXT;
            }
          }
        }

        if (Object.values(conditionErrors).some(Boolean)) {
          acc[index] = conditionErrors;
        }

        return acc;
      },
      []
    );

    if (errorsEventConditions.length > 0) {
      errors.eventConditions = errorsEventConditions;
    }
  }

  return errors;
}
