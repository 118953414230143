import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgDocumentSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10 4H5v1.5h5V4zM11 7H5v1.5h6V7zM5 10h3v1.5H5V10z" fill={props.fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2.5A1.5 1.5 0 013.5 1h9A1.5 1.5 0 0114 2.5v11a1.5 1.5 0 01-1.5 1.5h-9A1.5 1.5 0 012 13.5v-11zm1.5 0h9v11h-9v-11z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgDocumentSmallLined;
