import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgLinkMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.592 12.697a3.522 3.522 0 005.308.38l2.112-2.112a3.52 3.52 0 00-4.977-4.977l-1.211 1.204"
        stroke={props.fill}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.408 11.289a3.52 3.52 0 00-5.308-.38L5.988 13.02a3.52 3.52 0 004.977 4.977l1.204-1.204"
        stroke={props.fill}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.517 4.25a4.27 4.27 0 00-3.003 1.199L11.295 6.66a.75.75 0 001.058 1.064l1.207-1.2a2.77 2.77 0 013.917 3.916l-2.107 2.107a2.77 2.77 0 01-4.177-.3.75.75 0 10-1.202.9 4.269 4.269 0 006.44.46l2.111-2.111.01-.01a4.27 4.27 0 00-3.035-7.236z"
        fill={props.fill}
      />
      <path
        d="M10.894 9.138a4.27 4.27 0 00-3.324 1.24L5.458 12.49l-.01.01a4.27 4.27 0 006.038 6.038l1.213-1.214a.75.75 0 10-1.06-1.06l-1.2 1.199a2.77 2.77 0 01-3.916-3.917L8.63 11.44a2.77 2.77 0 014.177.3.75.75 0 001.202-.9 4.27 4.27 0 00-3.115-1.7z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgLinkMediumLined;
