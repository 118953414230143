export enum ModalType {
  CONFIRM = 'CONFIRM',
  CREATE_JOURNEY = 'CREATE_JOURNEY',
  CLONE_JOURNEY = 'CLONE_JOURNEY',
  EDITING_FORM_POINT = 'EDITING_FORM_POINT',
  DETAIL_POINT = 'DETAIL_POINT',
  JOURNEY_GOAL_SETTINGS = 'JOURNEY_GOAL_SETTINGS',
  SILENT_HOURS_SETTING = 'SILENT_HOURS_SETTING',
  DETAIL_SILENT_HOURS_SETTING = 'DETAIL_SILENT_HOURS_SETTING',
  FILTER_ATTRIBUTES = 'FILTER_ATTRIBUTES',
  FREQUENCY_CAPPING = 'FREQUENCY_CAPPING',
  DETAIL_FREQUENCY_CAPPING = 'DETAIL_FREQUENCY_CAPPING',
  DETAIL_POINT_GOAL_EVENT_LIST = 'DETAIL_POINT_GOAL_EVENT_LIST',
  RENAME_JOURNEY = 'RENAME_JOURNEY',
  FULL_SCREEN_EDITOR = 'FULL_SCREEN_EDITOR',
  SELECT_APPLICATION = 'SELECT_APPLICATION',
  FULL_STATISTIC = 'FULL_STATISTIC',
  PREMIUM = 'PREMIUM',
  CONTACT_SALES = 'CONTACT_SALES',
  CONTACT_SALES_SUCCESS = 'CONTACT_SALES_SUCCESS'
}
