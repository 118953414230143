import React from 'react';
import { useParams } from 'react-router-dom';

// Store Hooks
import { useCloseAllModals, useUpdateJourneyById } from '@src/store/hooks';
import { useApplicationsWithAutoLoad } from '@src/ui/features/EditEvents/hooks/useApplicationsWithAutoLoad';

// UI Components
import { Section } from '@src/ui/kit';
import { Modal } from '@src/ui/kit/Modal';
import { DefaultButtons } from '@src/ui/kit/ModalButtons';
import { FormSelect } from '@src/ui/form';

// Helpers
import { useForm } from '@src/ui/hooks';
import { makeApplicationOptions } from '@src/ui/helpers';

// Types
import { TFormErrors } from '@src/ui/hooks/useForm';
import { IUniqueId } from '@src/common/types/entities';
import { TFormFnSetFieldValue } from '@src/common/types/form/form';
import { SelectApplicationValues } from '../ModalSelectApplication.types';

interface IProps {
  journeyId: IUniqueId['id'];
}

export function ModalSelectApplication(props: IProps): JSX.Element {
  const { journeyId } = props;
  const closeAllModals = useCloseAllModals();
  const [updateJourneysById, isUpdatingJourney] = useUpdateJourneyById();
  const [applications, isLoadingApplications] = useApplicationsWithAutoLoad();
  const applicationSelectOptions = makeApplicationOptions(applications);
  const params = useParams<{ applicationCode: string }>();
  const appLink = params.applicationCode
    ? 'https://app.pushwoosh.com/applications'
    : 'https://go.pushwoosh.com/cp/applications';

  const { values, errors, touched, setFieldTouched, setFieldValue, handleSubmit } =
    useForm<SelectApplicationValues>({
      initialValues: {
        applicationCode: ''
      },
      validate(values): TFormErrors<SelectApplicationValues> {
        const errors: TFormErrors<SelectApplicationValues> = {};

        if (!values.applicationCode) {
          errors.applicationCode = 'Application is required';
        }

        return errors;
      },
      onSubmit: async (formValues): Promise<void> => {
        updateJourneysById(journeyId, {
          params: {
            applicationCode: formValues.applicationCode
          }
        });

        closeAllModals();
      }
    });

  return (
    <Modal
      title="Select application"
      widthType="short"
      footerRight={
        <DefaultButtons
          isDisabledActionButton={isUpdatingJourney}
          actionButtonName="Save"
          onClickActionButton={handleSubmit}
          onClickCancelButton={closeAllModals}
        />
      }
      isOpen
    >
      <Section>
        {'Every journey needs its own application. Please select one from the list.'}
      </Section>
      <FormSelect
        label="Application"
        name="applicationCode"
        url={appLink}
        value={values.applicationCode}
        error={touched.applicationCode ? errors.applicationCode : ''}
        options={applicationSelectOptions}
        placeholder="Select application"
        isLoading={isLoadingApplications}
        onChange={setFieldValue as TFormFnSetFieldValue}
        onBlur={setFieldTouched}
      />
    </Modal>
  );
}
