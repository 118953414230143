import styled from 'styled-components';
import { Spacing, Color } from '@pushwoosh/kit-constants';
import { Paragraph } from '@pushwoosh/kit-typography';

export const Wrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto min-content;
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 24px;
  gap: ${Spacing.S6};
`;

export const ExpirationSelectContainer = styled.div`
  margin-top: ${Spacing.S4};
  display: grid;
  grid-gap: ${Spacing.S4};
  grid-template-columns: min-content 120px auto;
  align-items: center;
`;

export const ExpirationHint = styled(Paragraph)`
  color: ${Color.LOCKED};
  margin-top: ${Spacing.S4};
`;
