import { TimePeriod } from '@src/common/constants/stats';

export function getTimePeriodTitle(period: TimePeriod): string {
  switch (period) {
    case TimePeriod.CUSTOM_RANGE: {
      return 'Custom range';
    }

    case TimePeriod.LAST_30_DAYS: {
      return 'Last 30 days';
    }

    case TimePeriod.THIS_MONTH: {
      return 'This month';
    }

    case TimePeriod.THIS_WEEK: {
      return 'This week';
    }

    case TimePeriod.TODAY: {
      return 'Today';
    }

    case TimePeriod.YESTERDAY: {
      return 'Yesterday';
    }

    default: {
      return 'All time';
    }
  }
}
