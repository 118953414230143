import React, { forwardRef } from 'react';
import { format } from 'date-fns';

// Constants
import { Color } from '@pushwoosh/kit-constants';

// Components
import { Icon, IconEnum } from '../Icon';
import { Dropdown, useDropdown } from '../Dropdown';
import { Datepicker } from '../Datepicker';

// Types
import { DatepickerProps } from '../Datepicker/Datepicker.types';

// Styled
import { StyledInput, InputWrap, IconWrap, DatepickerWrap } from './styled';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function InputDp(props: DatepickerProps, ref: any): JSX.Element {
  const { value, onChange, minDate, maxDate, isDisabled } = props;
  const formattedValue = value ? format(value, 'dd-LL-yyyy') : '';
  const inputRef = React.useRef(ref);
  const dropdown = useDropdown({
    position: 'bottom-start',
    target: ref || inputRef
  });

  const toggleDatepicker = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      dropdown.toggleDropdown(e);
    },
    [dropdown]
  );

  const handlerChange = React.useCallback(
    (date: Date) => {
      onChange(date);

      dropdown.toggleDropdown();
    },
    [dropdown, onChange]
  );

  return (
    <>
      <InputWrap ref={ref || inputRef} onClick={toggleDatepicker} isDisabled={isDisabled}>
        <StyledInput value={formattedValue} readOnly placeholder="dd-mm-yyyy" />
        <IconWrap>
          <Icon type={IconEnum.CALENDAR_MEDIUM_LINED} fill={Color.MAIN} />
        </IconWrap>
      </InputWrap>
      <Dropdown dropdown={dropdown} withArrow={false}>
        <DatepickerWrap>
          <Datepicker
            value={value}
            onChange={handlerChange}
            minDate={minDate}
            maxDate={maxDate}
            withBorder={false}
          />
        </DatepickerWrap>
      </Dropdown>
    </>
  );
}

export const InputDatepicker = forwardRef(InputDp);
