import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgRewardMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.25 12A6.25 6.25 0 007.765 7.404L9.23 8.87a.75.75 0 01-.53 1.28h-4a.75.75 0 01-.75-.75v-4a.75.75 0 011.28-.53l1.473 1.473a7.75 7.75 0 11-2.132 7.871.75.75 0 111.438-.428A6.25 6.25 0 0018.25 12zM5.45 7.21v1.44h1.44L5.45 7.21z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgRewardMediumLined;
