// Analytics
import {
  GetOptions,
  PostOptions,
  PutOptions,
  DeleteOptions,
  HttpClient,
  Response
} from '@pushwoosh/http-client';

// Helpers
import { getApiOrigin } from '@src/store/helpers';

// Types
import { TPoints } from '@src/common/types/points';
import { TComment } from '@src/common/types/comments';
import { IUniqueId } from '@src/common/types/entities';
import { TJourney, ParamsJourneyList } from '@src/common/types/journey';
import {
  TFetchResponse,
  TFetchByIdResponse,
  TUpdateByIdResponse,
  TCreateResponse,
  TActivateResponse,
  TDeactivateResponse,
  TArchiveByIdResponse,
  TDeleteByIdResponse,
  TEditByIdResponse,
  TCreateBody,
  TEditByIdBody,
  TUpdateByIdBody,
  TPauseJourney,
  TResumeJourney,
  TRenameByIdResponse,
  TRenameByIdBody,
  TUpdateABSplitterByJourneyId
} from './types';
import { validateResponse } from './helpers/validateResponse';

export const service = {
  async fetch(params: ParamsJourneyList, api: HttpClient): Promise<TFetchResponse> {
    const options: GetOptions<TFetchResponse, ParamsJourneyList, any> = {
      params,
      query: params,
      withAuthorization: true
    };
    // eslint-disable-next-line camelcase
    return api.get(`${getApiOrigin()}/api/journey`, options);
  },

  async fetchById(id: IUniqueId['id'], api: HttpClient): Promise<TFetchByIdResponse> {
    const options: GetOptions<TFetchByIdResponse, { id: string }, never> = {
      params: {
        id
      },
      withAuthorization: true
    };

    // eslint-disable-next-line camelcase
    return api.get(`${getApiOrigin()}/api/journey/:id`, options);
  },

  async create(
    points: TPoints[],
    title: string,
    params: TJourney['params'],
    comments: TJourney['comments'],
    api: HttpClient
  ): Promise<TCreateResponse> {
    const options: PostOptions<TCreateResponse, never, never, TCreateBody> = {
      body: {
        payload: {
          title,
          points,
          params,
          comments
        }
      },
      withAuthorization: true
    };

    // eslint-disable-next-line camelcase
    return api.post<TCreateResponse, never, never, TCreateBody>(
      `${getApiOrigin()}/api/journey`,
      options
    );
  },

  async editJourneyById(
    id: IUniqueId['id'],
    points: TPoints[],
    data: { title: TJourney['title']; params: TJourney['params'] },
    comments: TComment[],
    api: HttpClient
  ): Promise<TEditByIdResponse> {
    const { title, params } = data;
    const options: PutOptions<TEditByIdResponse, { id: string }, never, TEditByIdBody> = {
      body: {
        payload: {
          points,
          title,
          params,
          comments
        }
      },
      params: {
        id
      },
      withAuthorization: true
    };

    // eslint-disable-next-line camelcase
    return api.put<TEditByIdResponse, { id: string }, never, TEditByIdBody>(
      `${getApiOrigin()}/api/journey/:id/edit`,
      options
    );
  },

  async resumeById(
    id: IUniqueId['id'],
    journey: {
      title: TJourney['title'];
      params: TJourney['params'];
      points: TJourney['points'];
      comments: TJourney['comments'];
    },
    api: HttpClient,
    errorsPoint: any,
    sidebars: any
  ): Promise<TResumeJourney> {
    const options: PostOptions<TResumeJourney, { id: string }, never, TUpdateByIdBody> = {
      body: {
        payload: {
          points: journey.points,
          title: journey.title,
          params: journey.params,
          comments: journey.comments
        }
      },
      params: {
        id
      },
      withAuthorization: true,
      responseMiddleware: async (response) =>
        validateResponse(response as Response, errorsPoint, sidebars)
    };

    // eslint-disable-next-line camelcase
    return api.post<TResumeJourney, { id: string }, never, TUpdateByIdBody>(
      `${getApiOrigin()}/api/journey/:id/resume`,
      options
    );
  },

  async updateById(
    id: IUniqueId['id'],
    points: TPoints[],
    data: { title: TJourney['title']; params: TJourney['params'] },
    comments: TComment[],
    api: HttpClient
  ): Promise<TUpdateByIdResponse> {
    const { title, params } = data;
    const options: PutOptions<TUpdateByIdResponse, { id: string }, never, TUpdateByIdBody> = {
      body: {
        payload: {
          points,
          title,
          params,
          comments
        }
      },
      params: {
        id
      },
      withAuthorization: true
    };

    // eslint-disable-next-line camelcase
    return api.put<TUpdateByIdResponse, { id: string }, never, TUpdateByIdBody>(
      `${getApiOrigin()}/api/journey/:id`,
      options
    );
  },

  async updateABSplitterByJourneyId(
    id: IUniqueId['id'],
    pointId: IUniqueId['id'],
    split: number[],
    api: HttpClient
  ): Promise<TUpdateByIdResponse> {
    const options = {
      body: {
        payload: {
          pointUUID: pointId,
          params: split
        }
      },
      params: {
        id
      },
      withAuthorization: true
    };

    return api.put<TUpdateByIdResponse, { id: string }, never, TUpdateABSplitterByJourneyId>(
      `${getApiOrigin()}/api/journey/:id/update/absplitter`,
      options
    );
  },

  async renameById(
    id: IUniqueId['id'],
    data: {
      title: TJourney['title'];
    },
    api: HttpClient
  ): Promise<TRenameByIdResponse> {
    const { title } = data;
    const options: PutOptions<TRenameByIdResponse, { id: string }, never, TRenameByIdBody> = {
      body: {
        payload: {
          title
        }
      },
      params: {
        id
      },
      withAuthorization: true
    };

    // eslint-disable-next-line camelcase
    return api.post<
      TRenameByIdResponse,
      {
        id: string;
      },
      never,
      TRenameByIdBody
    >(`${getApiOrigin()}/api/journey/:id/rename`, options);
  },

  async pauseById(id: IUniqueId['id'], api: HttpClient): Promise<TPauseJourney> {
    const options: PostOptions<TPauseJourney, { id: string }, never, never> = {
      params: {
        id
      },
      withAuthorization: true
    };

    return api.post<TPauseJourney, { id: string }, never, never>(
      `${getApiOrigin()}/api/journey/:id/pause`,
      options
    );
  },

  async validateById(
    id: IUniqueId['id'],
    api: HttpClient,
    errorsPoint: any,
    sidebars: any
  ): Promise<TActivateResponse> {
    const options: GetOptions<TActivateResponse, { id: string }, never> = {
      params: {
        id
      },
      withAuthorization: true,
      responseMiddleware: async (response) => validateResponse(response, errorsPoint, sidebars)
    };

    // eslint-disable-next-line camelcase
    return api.get<TActivateResponse, { id: string }, never>(
      `${getApiOrigin()}/api/journey/:id/validate`,
      options
    );
  },

  async activateById(
    id: IUniqueId['id'],
    api: HttpClient,
    notifications: any,
    errorsPoint: any,
    sidebars: any
  ): Promise<TActivateResponse> {
    const options: PostOptions<TActivateResponse, { id: string }, never, never> = {
      params: {
        id
      },
      withAuthorization: true,
      responseMiddleware: async (response) => validateResponse(response, errorsPoint, sidebars)
    };

    // eslint-disable-next-line camelcase
    return api.post<TActivateResponse, { id: string }, never, { id: string }>(
      `${getApiOrigin()}/api/journey/:id/start`,
      options
    );
  },

  async archiveById(id: IUniqueId['id'], api: HttpClient): Promise<TArchiveByIdResponse> {
    const options: PostOptions<TArchiveByIdResponse, { id: string }, never, never> = {
      params: {
        id
      },
      withAuthorization: true
    };

    // eslint-disable-next-line camelcase
    return api.post<TArchiveByIdResponse, { id: string }, never, { id: string }>(
      `${getApiOrigin()}/api/journey/:id/archive`,
      options
    );
  },

  async deleteById(id: IUniqueId['id'], api: HttpClient): Promise<TDeleteByIdResponse> {
    const options: DeleteOptions<TDeleteByIdResponse, { id: string }, never, never> = {
      params: {
        id
      },
      withAuthorization: true
    };

    // eslint-disable-next-line camelcase
    return api.delete<TDeleteByIdResponse, { id: string }, never, never>(
      `${getApiOrigin()}/api/journey/:id`,
      options
    );
  },

  async deactivateById(id: IUniqueId['id'], api: HttpClient): Promise<TDeactivateResponse> {
    const options: PostOptions<TDeactivateResponse, { id: string }, never, never> = {
      params: {
        id
      },
      withAuthorization: true
    };

    // eslint-disable-next-line camelcase
    return api.post<TDeactivateResponse, { id: string }, never, { id: string }>(
      `${getApiOrigin()}/api/journey/:id/finish`,
      options
    );
  }
};
