// Types
import { TSelectOption } from '@src/ui/kit/Select';
import { Tag, UpdateTag } from '@src/common/types/tag';

export const getAvailableOptionsTags = (
  existTags: Tag[],
  updateTags: UpdateTag[]
): TSelectOption[] => {
  const options = existTags.filter((item) => {
    const isExistUpdateTags = updateTags.filter((itemUpdate) => itemUpdate.name === item.name);
    if (isExistUpdateTags.length) {
      return false;
    }
    return true;
  });
  return options.map((item) => ({
    label: item.name,
    value: item.type
  }));
};
