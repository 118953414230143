import { useHistory } from '@src/common/contexts/history';
import { useGrpcBridge, useGrpcClient, useHttpClient } from '@src/common/contexts/http-client';
import { useSession } from '@src/common/contexts/session';
import React from 'react';
import { mountRootParcel } from 'single-spa';
import Parcel from 'single-spa-react/parcel';

type Props = {
  richMediaCode: string;
  campaignCode: string;
  messageCode: string;
  applicationCode: string;
  onClose: () => void;
};

export function RichMediaParcel(props: Props) {
  const { richMediaCode, campaignCode, messageCode, applicationCode, onClose } = props;
  const grpcClient = useGrpcClient();
  const grpcBridge = useGrpcBridge();
  const api = useHttpClient();
  const history = useHistory();
  const session = useSession();
  const params = {
    richMediaCode,
    campaignCode
  };
  const parcelConfig = React.useMemo(() => {
    let parcel: { render: (arg0: any) => void; unmount: () => void };
    return {
      bootstrap(props: { domElement: any }) {
        const moduleToImport = '@pushwoosh/richmedia-statistics';

        // eslint-disable-next-line no-undef
        return System.import(moduleToImport).then((module) => {
          parcel = module.parcel(props.domElement);
        });
      },
      mount(props: any) {
        parcel.render(props);
        return Promise.resolve();
      },
      unmount() {
        parcel.unmount();
        return Promise.resolve();
      },
      update(props: any) {
        parcel.render(props);
        return Promise.resolve();
      }
    };
  }, []);

  return (
    <Parcel
      config={parcelConfig}
      mountParcel={mountRootParcel}
      wrapWith="div"
      grpcClient={grpcClient}
      grpcBridge={grpcBridge}
      history={history}
      httpClient={api}
      params={params}
      session={{ account: session.account, user: session.user }}
      applicationCode={applicationCode}
      headerOnClickBackButton={onClose}
    />
  );
}
