import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgChevronRightSmall(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.52 11.576a.75.75 0 001.056-.096l2.5-3a.75.75 0 000-.96l-2.5-3a.75.75 0 10-1.152.96L8.524 8l-2.1 2.52a.75.75 0 00.096 1.056z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgChevronRightSmall;
