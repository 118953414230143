import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPlatformLogoFirefoxMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.353 9.24c-.395-.944-1.186-1.96-1.809-2.279a9.36 9.36 0 01.914 2.732v.017c-1.016-2.542-2.743-3.565-4.154-5.796a7.214 7.214 0 01-.214-.345c-.04-.07-.07-.13-.1-.186a1.642 1.642 0 01-.14-.373.023.023 0 00-.014-.008.033.033 0 00-.023 0v.005c0 .004-.007 0-.007 0v-.005c-2.26 1.325-3.03 3.78-3.1 5.006.106-.007.21-.014.317-.014a4.548 4.548 0 013.948 2.301 3.111 3.111 0 00-2.172-.525c3.241 1.623 2.372 7.207-2.12 6.993a4.009 4.009 0 01-1.437-.332l-.153-.07h.007a3.444 3.444 0 01-.128-.065c.042.023.081.047.123.063-1.1-.57-2.009-1.644-2.123-2.953 0 0 .419-1.546 2.976-1.546.28 0 1.07-.774 1.088-1-.004-.07-1.571-.697-2.185-1.297a11.092 11.092 0 00-.619-.593 2.697 2.697 0 00-.232-.176 4.178 4.178 0 01-.023-2.21A6.673 6.673 0 006.8 8.265c-.36-.452-.335-1.944-.314-2.256a1.61 1.61 0 00-.302.158 6.564 6.564 0 00-.883.756 7.868 7.868 0 00-.837 1.011 7.61 7.61 0 00-1.21 2.73l-.018.058c-.016.077-.093.481-.104.567-.07.416-.114.835-.133 1.256v.046a9.011 9.011 0 0017.89 1.525c.017-.116.028-.232.042-.348a9.266 9.266 0 00-.581-4.527h.002z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPlatformLogoFirefoxMediumFilled;
