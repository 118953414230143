import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';

export const Container = styled.div`
  margin-top: ${Spacing.S5};
`;

export const WaitSettings = styled.div`
  display: grid;
  grid-template-columns: 100px 100px;
  grid-column-gap: ${Spacing.S3};
`;

export const DayWrap = styled.div`
  margin-bottom: ${Spacing.S5};
`;
