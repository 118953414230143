/* eslint-disable no-useless-return */
import { Context } from 'konva/lib/Context';
import { Shape, ShapeConfig } from 'konva/lib/Shape';

const RADIUS = 20;
const POINT_WIDTH = 160;
const POINT_HEIGHT = 56;
const MIN_DISTANCE = 35;
const ARROW_WIDTH = 10;

export function calculateArrowPoints(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  context: Context,
  shape: Shape<ShapeConfig>
) {
  const width = Math.abs(x2 - x1) < RADIUS ? RADIUS : x2 - x1;
  const height = y2 - y1;
  const dir = Math.sign(height);
  const radius = Math.min(RADIUS, Math.abs(height / 2), Math.abs(width / 2));
  let nextX;
  let nextY;

  // Если второй поинт находится дальше минимального расстояния
  if (width > MIN_DISTANCE) {
    context.beginPath();
    context.moveTo(x1, y1);
    nextX = x1 + width / 2 - radius;
    context.lineTo(nextX, y1);
    nextX = x1 + width / 2;
    nextY = y1 + dir * radius;
    context.quadraticCurveTo(nextX, y1, nextX, nextY);
    nextY = y2 - dir * radius;
    context.lineTo(nextX, nextY);
    context.quadraticCurveTo(nextX, y2, nextX + radius, y2);
    context.lineTo(x2 - ARROW_WIDTH, y2);
    context.fillStrokeShape(shape);

    return;
  }

  // Если поинты рядом друг с другом
  if (Math.abs(x2 - x1) < 40 && Math.abs(y2 - y1) < 40) {
    context.beginPath();
    context.moveTo(x1, y1);
    context.bezierCurveTo(x1, y1, x1 + Math.abs(x2 - x1) - 20 * 2, y1, x2, y2);
    context.fillStrokeShape(shape);

    return;
  }

  // Если второй поинт находится ближе минимального расстояния и расстояние между ними меньше высоты поинта
  if (width < MIN_DISTANCE && Math.abs(y2 - y1) < POINT_HEIGHT * 2) {
    context.beginPath();
    context.moveTo(x1, y1);
    nextX = x2 + POINT_WIDTH + MIN_DISTANCE - RADIUS;
    nextX = nextX > x1 + MIN_DISTANCE ? nextX : x1 + MIN_DISTANCE - RADIUS;
    context.lineTo(nextX, y1);
    nextX = x2 + POINT_WIDTH + MIN_DISTANCE;
    nextX = nextX > x1 + MIN_DISTANCE ? nextX : x1 + MIN_DISTANCE;
    nextY = y1 + dir * RADIUS;
    context.quadraticCurveTo(nextX, y1, nextX, nextY);
    nextY = y2 + dir * POINT_HEIGHT - dir * RADIUS;
    context.lineTo(nextX, nextY);
    nextY = y2 + dir * POINT_HEIGHT;
    if (y2 < y1) {
      context.quadraticCurveTo(nextX, nextY, nextX + dir * RADIUS, nextY);
    } else {
      context.quadraticCurveTo(nextX, nextY, nextX - dir * RADIUS, nextY);
    }
    nextX = x2 - MIN_DISTANCE - ARROW_WIDTH + RADIUS;
    context.lineTo(nextX, nextY);
    nextX = x2 - MIN_DISTANCE - ARROW_WIDTH;
    context.quadraticCurveTo(nextX, nextY, nextX, nextY - dir * RADIUS);
    nextY = y2 + dir * RADIUS;
    context.lineTo(nextX, nextY);
    if (y2 < y1) {
      context.quadraticCurveTo(nextX, y2, nextX - dir * RADIUS, y2);
    } else {
      context.quadraticCurveTo(nextX, y2, nextX + dir * RADIUS, y2);
    }
    context.lineTo(x2 - ARROW_WIDTH, y2);
    context.fillStrokeShape(shape);

    return;
  }

  // Если второй поинт находится ближе минимального расстояния и расстояние между ними больше высоты поинта
  if (width < MIN_DISTANCE && Math.abs(y2 - y1) > POINT_HEIGHT * 2) {
    context.beginPath();
    context.moveTo(x1, y1);
    nextX = x1 + MIN_DISTANCE - RADIUS;
    context.lineTo(nextX, y1);
    nextX = x1 + MIN_DISTANCE;
    nextY = y1 + dir * RADIUS;
    context.quadraticCurveTo(nextX, y1, nextX, nextY);
    nextY = y1 + height / 2 - dir * radius;
    context.lineTo(nextX, nextY);
    nextY = y1 + height / 2;
    if (y2 < y1) {
      context.quadraticCurveTo(nextX, nextY, nextX + dir * RADIUS, nextY);
    } else {
      context.quadraticCurveTo(nextX, nextY, nextX - dir * RADIUS, nextY);
    }
    nextX = x2 - MIN_DISTANCE - ARROW_WIDTH + RADIUS;
    context.lineTo(nextX, nextY);
    nextX = x2 - MIN_DISTANCE - ARROW_WIDTH;
    context.quadraticCurveTo(nextX, nextY, nextX, nextY + dir * RADIUS);
    nextY = y2 - dir * radius;
    context.lineTo(nextX, nextY);
    if (y2 < y1) {
      context.quadraticCurveTo(nextX, y2, nextX - dir * RADIUS, y2);
    } else {
      context.quadraticCurveTo(nextX, y2, nextX + dir * RADIUS, y2);
    }
    context.lineTo(x2 - ARROW_WIDTH, y2);
    context.fillStrokeShape(shape);

    return;
  }
}
