import * as React from 'react';

// UI Components
import { ExtraOneButton, Modal, Section } from '@src/ui/kit';
import { DetailSilentHours } from '@src/ui/features/SilentHours/components/DetailSilentHours';

// Store Hooks
import { useCloseAllModals, useCurrentJourneyId, useGetJourneyById } from '@src/store/hooks';

// Helpers
import { defineSilentHours } from '@src/common/helpers/defineSilentHours';

export function ModalDetailSilentHours(): JSX.Element {
  const closeAllModals = useCloseAllModals();
  const journeyId = useCurrentJourneyId();
  const getJourneyById = useGetJourneyById();
  const journey = getJourneyById(journeyId);
  const silentHours = defineSilentHours(journey);

  return (
    <Modal
      title="Silent Hours"
      footerRight={<ExtraOneButton actionButtonName="Close" onClickActionButton={closeAllModals} />}
      isOpen
    >
      <Section>
        Silent Hours are set, and users won’t receive the messages via chosen communication channels
        within the specified period.
      </Section>
      <DetailSilentHours
        inAppParams={silentHours.inAppParams}
        pushParams={silentHours.pushParams}
        emailParams={silentHours.emailParams}
        smsParams={silentHours.smsParams}
      />
    </Modal>
  );
}
