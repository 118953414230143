import { action } from 'easy-peasy';

// Types
import { IModalsModel } from './modals.types';

export const model: IModalsModel = {
  items: [],
  isOpened: false,

  openNewModal: action((state, payload): void => {
    const isAlreadyOpen = state.items.some((modal): boolean => modal.type === payload.type);

    if (!isAlreadyOpen) {
      state.items.push(payload);
      state.isOpened = true;
    }
  }),

  closeAllModal: action((state): void => {
    state.items = [];
    state.isOpened = false;
  }),

  closeModalByType: action((state, payload): void => {
    state.items = state.items.filter((item) => item.type !== payload);
  })
};
