import React, { useState, useEffect } from 'react';
import { format as formatDate } from 'date-fns';
import { AttributeValuesType } from '@src/common/types/attribute';
import { OperatorTypeEnum } from '@src/common/constants/attribute';
import {
  TFilterConditionMultipleChoiceValue,
  TFilterConditionOneChoiceValue,
  TFilterConditionTwoChoiceValue
} from '@src/ui/features/EditEvents';

interface Props {
  operator: OperatorTypeEnum;
  value: AttributeValuesType;
}

function isList(value: AttributeValuesType): value is TFilterConditionMultipleChoiceValue {
  return Array.isArray(value);
}

function isTuple(value: AttributeValuesType): value is TFilterConditionTwoChoiceValue {
  return Array.isArray(value) && value.length === 2;
}

function isScalar(value: AttributeValuesType): value is TFilterConditionOneChoiceValue {
  return !Array.isArray(value);
}

const isISODate = (val: string | number | Date): val is Date => {
  try {
    const d = new Date(val);
    return !Number.isNaN(d.valueOf()) && d.toISOString() === val;
  } catch (e) {
    return false;
  }
};

function renderScalar(value: TFilterConditionOneChoiceValue) {
  if (typeof value === 'boolean') {
    return <span>{value ? 'true' : 'false'}</span>;
  }

  if (typeof value === 'number') {
    return <span>{value}</span>;
  }

  if (isISODate(value)) {
    return <span>{formatDate(new Date(value), 'yyyy-MM-dd')}</span>;
  }

  if (typeof value === 'string') {
    return <span>{value}</span>;
  }

  return <span>{String(value)}</span>;
}

export const ConditionValue: React.FC<Props> = (props) => {
  const { operator, value } = props;

  if (isScalar(value)) {
    return renderScalar(value);
  }

  if (isTuple(value)) {
    return (
      <>
        {renderScalar(value[0])} <i>and</i> {renderScalar(value[1])}
      </>
    );
  }

  if (isList(value)) {
    const show = value.slice(0, 3);
    const rest = value.slice(3);

    return (
      <>
        {show.map((item, index) => (
          <React.Fragment key={index}>
            {index !== 0 && <i>or</i>}
            {renderScalar(item)}
          </React.Fragment>
        ))}

        {rest.length > 0 && <i>and {rest.length} more</i>}
      </>
    );
  }

  return <>{String(value)}</>;
};
