import React from 'react';

import { StyledRow } from './styled';

type IProps = {
  children: JSX.Element | JSX.Element[];
};

export function Row({ children }: IProps) {
  return <StyledRow>{children}</StyledRow>;
}
