import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';

export const TimeFieldWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: ${Spacing.S5};
`;

export const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  margin-right: ${Spacing.S3};
`;

export const TimeSelect = styled.div`
  display: flex;
`;
