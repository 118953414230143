import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgFolderMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 5.5A1.25 1.25 0 004.5 6.75v9A1.25 1.25 0 005.75 17h12A1.25 1.25 0 0019 15.75v-7a1.25 1.25 0 00-1.25-1.25h-5a.75.75 0 01-.53-.22L10.44 5.5H5.75zm-1.945-.695A2.75 2.75 0 015.75 4h5a.75.75 0 01.53.22L13.06 6h4.69a2.75 2.75 0 012.75 2.75v7a2.75 2.75 0 01-2.75 2.75h-12A2.75 2.75 0 013 15.75v-9c0-.73.29-1.429.805-1.945z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgFolderMediumLined;
