import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSuperUserMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1h2v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1h2v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5V8a2 2 0 01-1.623 1.965l1.22 5.285H17c.966 0 1.75.784 1.75 1.75v2a.75.75 0 01-.75.75H6a.75.75 0 01-.75-.75v-2c0-.966.784-1.75 1.75-1.75h.403l1.22-5.285A2 2 0 017 8V4.5zM15.5 7h-7v1a.5.5 0 00.5.5h6a.5.5 0 00.5-.5V7zm-6.557 8.25h6.114l-1.154-5h-3.806l-1.154 5zM7 16.75a.25.25 0 00-.25.25v1.25h10.5V17a.25.25 0 00-.25-.25H7z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgSuperUserMediumLined;
