import styled, { css } from 'styled-components';
import { Color, Shadow } from '@pushwoosh/kit-constants';

type Props = {
  isActive: boolean;
};

const hoverCSS = css`
  &:hover {
    color: ${Color.PRIMARY_HOVER};
  }

  &:active {
    color: ${Color.PRIMARY_HOVER};
    opacity: 0.5;
  }
`;

export const StyledButton = styled.button<Props>`
  background-color: ${({ isActive }) => (isActive ? Color.MAIN : Color.CLEAR)};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  box-shadow: ${Shadow.REGULAR};
  border-radius: 8px;
  border: none;
  outline: transparent;
  color: ${({ isActive }) => (isActive ? Color.CLEAR : Color.MAIN)};

  ${({ isActive }) => !isActive && hoverCSS}
`;
