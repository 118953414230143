import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgWaitForTriggerPoint(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.137 8.506a1.5 1.5 0 011.334 1.2l2.68 13.403c.098.487.77.549.955.087l1.501-3.753A1.5 1.5 0 0126 18.5h5.5a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-4.146a.5.5 0 00-.464.314l-3.497 8.743a1.5 1.5 0 01-2.864-.263l-2.68-13.403c-.098-.487-.77-.549-.955-.087l-1.501 3.753A1.5 1.5 0 0114 21.5H8.5A.5.5 0 018 21v-2a.5.5 0 01.5-.5h4.146a.5.5 0 00.464-.314l3.497-8.743a1.5 1.5 0 011.53-.937z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgWaitForTriggerPoint;
