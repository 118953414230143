import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgArrowRoundUpSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.535 5.141l2.329 2.329a.75.75 0 11-1.061 1.06L8.75 7.477v2.856a.75.75 0 01-1.5 0V7.477L6.197 8.53a.75.75 0 01-1.06-1.06L7.47 5.136a.747.747 0 011.065.005z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.417 8a6.583 6.583 0 1113.166 0A6.583 6.583 0 011.417 8zM8 2.917a5.083 5.083 0 100 10.166A5.083 5.083 0 008 2.917z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgArrowRoundUpSmallLined;
