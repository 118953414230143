import React from 'react';
import { CheckRoundIcon } from '@pushwoosh/kit-icons';
import { Container, ItemNotChecked, Value, IconWrap } from './styled';

type Props = {
  value: string;
  isChecked: boolean;
};

export function AssistantItem(props: Props): JSX.Element {
  const { value, isChecked } = props;

  return (
    <Container>
      {isChecked ? (
        <IconWrap>
          <CheckRoundIcon size="medium" type="fill" view="filled" />
        </IconWrap>
      ) : (
        <ItemNotChecked />
      )}
      <Value isChecked={isChecked}>{value}</Value>
    </Container>
  );
}
