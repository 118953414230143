import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSetTagPoint(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 10c-2.762 0-5 2.238-5 5s2.238 5 5 5 5-2.238 5-5-2.238-5-5-5zM6.002 27.828L6 27.933v.12a2 2 0 001.947 1.946H22.06A2 2 0 0024 28.06l.001-.135-.002-.127c-.072-2.668-2.383-5.32-5.016-5.757-.057-.01.152.019.211.027l.013.002c.002 0 .002 0 0 0l-.034-.005h-.005c-2.882-.4-5.407-.394-8.323.01l-.041.007.015-.003a6.66 6.66 0 01.215-.028c-2.658.434-4.967 3.085-5.031 5.778zM34 13a1 1 0 01-1 1h-9a1 1 0 01-1-1v-1a1 1 0 011-1h9a1 1 0 011 1v1zm-11 6a1 1 0 001 1h5a1 1 0 001-1v-1a1 1 0 00-1-1h-5a1 1 0 00-1 1v1z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgSetTagPoint;
