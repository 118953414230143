import React from 'react';
import { StyledButton } from './styled';

type Props = {
  children: JSX.Element;
  isActive: boolean;
  onClick: () => void;
};

export function Button(props: Props): JSX.Element {
  const { children, isActive, onClick } = props;

  return (
    <StyledButton onClick={onClick} isActive={isActive}>
      {children}
    </StyledButton>
  );
}
