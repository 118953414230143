export function placeholderValid(placeholder: string): boolean {
  if (!placeholder) {
    return false;
  }

  // check placeholder contains only english letters, numbers and underscore
  const regex = /^[a-zA-Z0-9_]+$/;
  return regex.test(placeholder);
}

export function renderTemplate(
  journeyId: string,
  applicationCode: string,
  placeholders: string[]
): string {
  const placeholdersData = {} as Record<string, string>;

  Array.from(
    new Set(
      placeholders
        .map((s) => s.trim())
        .filter((s) => s.length > 0)
        .filter(placeholderValid)
    )
  )
    .sort()
    .forEach((placeholder) => {
      placeholdersData[placeholder] = 'value';
    });

  const data: any = {};

  data.payload = { attribute_values: placeholdersData };
  data.payload.filter = `A("${applicationCode}") * T("Language", EQ, "en")`;

  const body = JSON.stringify(data, null, 2);

  return [
    `POST https://journey.pushwoosh.com/api/journey/${journeyId}/start/external`,
    '',
    body,
    '',
    '// learn more about condition in https://docs.pushwoosh.com/platform-docs/api-reference/filters/segmentation-language'
  ].join('\n');
}
