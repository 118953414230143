import styled from 'styled-components';

import { H4 } from '@pushwoosh/kit-typography';
import { FormSelect as FormSelectComponent } from '@src/ui/form/FormSelect';
import { FormInput as FormInputComponent } from '@src/ui/form/FormInput';
import { Button as ButtonComponent } from '@src/ui/kit/Button';

// Constants
import { Spacing, Color, ShapeRadius } from '@pushwoosh/kit-constants';

export const ContainerDynamicValues = styled.div`
  display: flex;
  margin-top: ${Spacing.S7};
  margin-bottom: ${Spacing.S7};
  height: 85%;
`;

export const Container = styled.div`
  margin-left: ${Spacing.S3};
  max-width: 240px;
`;

export const Body = styled.div`
  padding: ${Spacing.S5};
  background-color: ${Color.SOFT_LIGHT};
  border-radius: ${ShapeRadius.SECTION};
`;

export const FormSelect = styled(FormSelectComponent)`
  padding-bottom: ${Spacing.S3};
`;

export const Title = styled(H4)`
  margin-bottom: ${Spacing.S1};
`;

export const InsertablePlaceholder = styled.div`
  display: grid;
  grid-template-columns: 164px 36px;
  column-gap: ${Spacing.S3};
  align-items: end;
  margin-top: ${Spacing.S7};
`;

export const FormInput = styled(FormInputComponent)`
  padding-bottom: 0px;
`;

export const Button = styled(ButtonComponent)`
  height: 36px;
`;
