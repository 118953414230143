import * as React from 'react';

// Components
import { Icon } from '@src/canvas/components/Icons';

// Types
import { TPropsIcons } from '@src/canvas/components/Icons/Icon.types';

const PATHS_DATA = [
  'M9.06067 1.99999L2.00001 9.06065L0.939346 7.99999L8.00001 0.939331L9.06067 1.99999Z',
  'M0.939346 1.99999L8.00001 9.06065L9.06067 7.99999L2.00001 0.939331L0.939346 1.99999Z'
];

const WIDTH = 10;
const HEIGHT = 10;

export function IconCross(props: TPropsIcons): JSX.Element {
  return <Icon pathsData={PATHS_DATA} width={WIDTH} height={HEIGHT} {...props} />;
}
