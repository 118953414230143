import styled from 'styled-components';
import { rgba } from 'polished';

import { Spacing, Color } from '@pushwoosh/kit-constants';

// Components
import { Paragraph, H4 } from '@pushwoosh/kit-typography';

export const DocumentationContainer = styled.section`
  padding: ${Spacing.S5} ${Spacing.S7};
`;

export const DocumentationParagraph = styled(Paragraph)`
  margin-bottom: ${Spacing.S5};
`;

export const DocumentationList = styled.ul`
  margin-bottom: ${Spacing.S5};
  padding-left: ${Spacing.S5};
`;

export const DocumentationNumberedList = styled.ol`
  margin-bottom: ${Spacing.S5};
  padding-left: ${Spacing.S5};
`;

export const DocumentationImageContainer = styled.figure`
  flex: 0;

  width: 100%;
  margin: 0 0 ${Spacing.S5} 0;

  line-height: 0;
  font-size: 0;

  cursor: zoom-in;

  > img {
    width: 100%;
  }
`;

export const DocumentationImageContainerZoom = styled(DocumentationImageContainer)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${rgba(Color.CLEAR, 0.8)};

  cursor: zoom-out;

  > img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const DocumentationImageLabel = styled(Paragraph)`
  padding: ${Spacing.S2} 0 0 0;

  color: ${Color.PHANTOM};
  text-align: center;
`;

export const DocumentationSubtitle = styled(H4)`
  margin-bottom: ${Spacing.S3};
`;
