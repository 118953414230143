import styled from 'styled-components';
import { Paragraph } from '@pushwoosh/kit-typography';
import { Spacing, Color, ShapeRadius } from '@pushwoosh/kit-constants';

export const Description = styled(Paragraph)`
  margin-bottom: ${Spacing.S5};
`;

export const ExpirationLabel = styled.span`
  background-color: ${Color.SOFT_LIGHT};
  padding: ${Spacing.S1} ${Spacing.S2};
  border-radius: ${ShapeRadius.CONTROL};
  margin-left: ${Spacing.S2};
`;
