import styled from 'styled-components';
import { Shadow, Color, Spacing } from '@pushwoosh/kit-constants';

const HEADER_HEIGHT = '52px';
const SIDEBAR_WIDTH = '280px';

export const LayoutWrap = styled.article`
  position: relative;
  min-width: 1024px;
  min-height: calc(100vh - ${HEADER_HEIGHT});
`;

export const HeaderWrap = styled.header`
  width: 280px;
  height: ${HEADER_HEIGHT};
  position: fixed;
  left: 0;
  top: 0;
  margin: ${Spacing.S5};
  z-index: 1;
`;

export const SidebarWrap = styled.aside`
  display: flex;
  width: ${SIDEBAR_WIDTH};
  background: ${Color.CLEAR};
  box-shadow: ${Shadow.REGULAR};
  margin: ${Spacing.S5};
  margin-top: calc(${HEADER_HEIGHT} + ${Spacing.S7});
  height: calc(100vh - ${HEADER_HEIGHT} - ${Spacing.S11});
  z-index: 1;
  position: fixed;
  border-radius: 8px;
  box-shadow: ${Shadow.SMALL};
`;

export const Content = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

type CampaignBarProps = {
  isVisibleSidebar: boolean;
};

export const CampaignBar = styled.div<CampaignBarProps>`
  position: absolute;
  right: ${({ isVisibleSidebar }) => (isVisibleSidebar ? '332px' : '16px')};
  top: 16px;
  z-index: 3;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: ${Spacing.S3};
`;
