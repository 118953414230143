import React from 'react';
import { Tooltip } from '@pushwoosh/kit-tooltip';

// Types
import { JourneyTitleProps } from './JourneyTitle.types';

// Styled
import { Title, TooltipBox } from './styled';

export function JourneyTitle(props: JourneyTitleProps): JSX.Element {
  const { value } = props;
  const [isVisibleTooltip, setVisibleTooltip] = React.useState(false);
  const titleRef = React.useRef(null);

  const handleMouseEnter = React.useCallback(() => {
    if (titleRef.current.scrollWidth > titleRef.current.clientWidth) {
      setVisibleTooltip(true);
    }
  }, []);

  const handleMouseLeave = React.useCallback(() => {
    setVisibleTooltip(false);
  }, []);

  return (
    <Tooltip
      isVisible={isVisibleTooltip}
      position="top-start"
      content={<TooltipBox>{value}</TooltipBox>}
    >
      <Title onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={titleRef}>
        {value}
      </Title>
    </Tooltip>
  );
}
