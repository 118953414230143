import styled from 'styled-components';
import { Spacing, Color, ShapeRadius, Shadow } from '@pushwoosh/kit-constants';

export const TooltipWrapp = styled.div`
  height: fit-content;
  padding: ${Spacing.S5} ${Spacing.S6};
  background-color: ${Color.CLEAR};
  border-radius: ${ShapeRadius.CONTROL};
  box-shadow: ${Shadow.REGULAR};
  bottom: 0;
  position: absolute;
  left: 0;
  width: 100%;

  &:after {
    content: '';
    border-left: ${Spacing.S2} solid transparent;
    border-right: ${Spacing.S2} solid transparent;
    border-top: ${Spacing.S2} solid #ffffff;
    bottom: -${Spacing.S2};
    position: absolute;
    right: calc(50% - ${Spacing.S2});
  }
`;
