import styled from 'styled-components';
import { Paragraph } from '@pushwoosh/kit-typography';
import { Spacing, Color } from '@pushwoosh/kit-constants';

export const Container = styled.div``;

export const Content = styled.div`
  padding: ${Spacing.S5};
`;

export const Description = styled(Paragraph)`
  margin-bottom: ${Spacing.S7};
`;

export const DateWrap = styled.div`
  margin-top: ${Spacing.S7};
`;

export const Footer = styled.div`
  padding: ${Spacing.S5};
  align-items: center;
  justify-content: flex-end;
  bottom: 0;
  position: fixed;
  box-sizing: border-box;
  bottom: ${Spacing.S5};
  width: 280px;
  border-top: 1px solid ${Color.DIVIDER};
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: ${Spacing.S3};
`;
