import * as React from 'react';

// Store hooks
import { useAllPoints } from '@src/store/hooks';

// Constants
import { PointType } from '@src/common/constants/point';

// Types
import { TSelectOption } from '@src/ui/kit/Select';

export function usePointsWithEvent(): TSelectOption[] {
  const points = useAllPoints();

  const memoPoints = React.useMemo(() => {
    const events: TSelectOption[] = [];
    for (let index = 0; index < points.length; index++) {
      const currentPoint = points[index];

      switch (currentPoint?.type) {
        case PointType.EVENT: {
          if (currentPoint.data?.name) {
            events.push({
              label: `${currentPoint?.title} - ${currentPoint.data?.name}`,
              value: [
                currentPoint?.id,
                currentPoint?.data?.name,
                currentPoint.data?.applicationCode
              ]
            });
          }
          break;
        }

        case PointType.WAIT_EVENT: {
          currentPoint.data?.waitEvents?.forEach((item) => {
            if (item.name) {
              events.push({
                label: `${currentPoint?.title} - ${item?.name}`,
                value: [currentPoint?.id, item?.name, item?.applicationCode]
              });
            }
          });
          break;
        }

        case PointType.API: {
          const attributes = currentPoint.data?.attributes?.filter(Boolean);
          const applicationCode = currentPoint.data?.applicationCode;

          if (attributes && applicationCode) {
            events.push({
              label: `${currentPoint?.title}`,
              value: [currentPoint?.id, 'PW_journey_api_entry', applicationCode]
            });
          }

          break;
        }
        default: {
          break;
        }
      }
    }
    return events;
  }, [points]);

  return memoPoints;
}
