import styled, { css } from 'styled-components';
import { NavLink, Link } from '@src/ui/kit';
import { Spacing, FontSize, Color, FontWeight } from '@pushwoosh/kit-constants';

export const Container = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

type ItemProps = {
  height?: number;
  isTopBorder?: boolean;
  isFixedBottom?: boolean;
};

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${(props: ItemProps) =>
    typeof props.height === 'number' ? `${props.height}px` : '48px'};
  padding-left: ${Spacing.S5};
  padding-right: ${Spacing.S5};
  font-size: ${FontSize.REGULAR};
  ${(props) => (props.isTopBorder ? 'border-top' : 'border-bottom')}: 1px solid #EDEDED;
  ${(props) =>
    props.isFixedBottom &&
    `
    margin-top: auto;
  `};
`;

const styleLink = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  color: ${Color.MAIN};
  font-size: ${FontSize.REGULAR};
  font-weight: ${FontWeight.MEDIUM};
  text-decoration: none;

  :hover {
    color: ${Color.PRIMARY};
  }

  &.active {
    color: ${Color.PRIMARY};
  }
`;

export const StyledNavLink = styled(NavLink)`
  ${styleLink}
`;

export const StyledLink = styled(Link)`
  ${styleLink}
`;
