import * as React from 'react';

// UI Components
import {
  FormEditOneEvent as FormEditOneEventComponent,
  IEditOnePointValues,
  IFormEditOneEventProps
} from '@src/ui/features/EditEvents/components/FormEditOneEvent';
import { useCurrentJourneyId, useJourneyAppCode } from '@src/store/hooks';

// UI Hooks
import { useDidUpdate, useForm } from '@src/ui/hooks';

// Containers Hooks
import { useEventsByAppCodeWithAutoLoad } from '@src/ui/features/EditEvents/hooks/useEventsByAppCodeWithAutoLoad';

// Helpers
import { touchErrors, treeShakeErrors } from '@src/common/helpers';
import {
  findEventAttributesByEventName,
  makeAttributeOptions,
  makeEventOptions
} from '@src/ui/helpers';

// Types
import { IEventPointCondition, TGoalEventPoint } from '@src/common/types/points';
import { TFormErrors } from '@src/ui/hooks/useForm';

import { validateFormEvent } from '../helpers/validateFormEvent';

interface IEditOneEventProps {
  initialValues: IFormEditOneEventProps['values'];
  index: number;
  errors?: TFormErrors<IEditOnePointValues>;
  onRemoveEvent?: IFormEditOneEventProps['onRemove'];
  onUpdatedForm: (formInfo: {
    index: number;
    data: TGoalEventPoint['data'];
    values: IFormEditOneEventProps['values'];
    touched: IFormEditOneEventProps['touched'];
    errors: IFormEditOneEventProps['errors'];
  }) => void;
  isShowThrowOut?: boolean;
  view?: 'light' | 'dark' | 'transparent';
}

export function EditOneEvent(props: IEditOneEventProps): JSX.Element {
  const journeyId = useCurrentJourneyId();
  const applicationCode = useJourneyAppCode(journeyId);

  const {
    initialValues,
    index,
    errors: externalErrors,
    onRemoveEvent,
    onUpdatedForm,
    isShowThrowOut,
    view
  } = props;

  const {
    values,
    errors: internalErrors,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur
  } = useForm<IEditOnePointValues>({
    initialValues: {
      eventCode: initialValues.eventCode || '',
      isThrowOut: initialValues.isThrowOut || false,
      eventConditions: initialValues.eventConditions || []
    },
    // externalErrors, // this won't work
    // externalTouched, // this won't work
    onSubmit: null,
    validate: validateFormEvent
  });

  const errors = React.useMemo(() => {
    if (treeShakeErrors(internalErrors)) {
      return internalErrors;
    }

    return externalErrors || {};
  }, [internalErrors, externalErrors]);

  // const touched = React.useMemo(() => {
  //   // this is not mistake, we really check internalErrors
  //   if (treeShakeErrors(internalErrors)) {
  //     return internalTouched;
  //   }
  //
  //   return externalTouched || {};
  // }, [internalErrors, internalTouched, externalTouched]);

  const touched = React.useMemo(() => touchErrors(errors), [errors]);

  useDidUpdate((): void => {
    const eventConditions: IEventPointCondition[] = values.eventConditions.map(
      (formCondition): IEventPointCondition => ({
        attributeName: formCondition.attributeName,
        type: formCondition.type,
        operator: formCondition.operator,
        value: formCondition.value
      })
    );

    const data: TGoalEventPoint['data'] = {
      applicationCode,
      name: values.eventCode,
      isThrowOut: values.isThrowOut,
      eventConditions
    };

    onUpdatedForm({
      index,
      data,
      values,
      errors,
      touched
    });
  }, [values, errors, touched]);

  const [events, isLoadingEvents] = useEventsByAppCodeWithAutoLoad(applicationCode);

  const handleAddFormCondition = React.useCallback<IFormEditOneEventProps['onAddCondition']>(
    (_, remainingAttributeOptions): void => {
      if (remainingAttributeOptions.length === 0) {
        return;
      }

      const nextAttributeOption = remainingAttributeOptions[0];

      const conditions: IEditOnePointValues['eventConditions'] = [
        ...values.eventConditions,
        {
          attributeName: nextAttributeOption.label,
          type: nextAttributeOption.value,
          operator: null,
          value: null
        }
      ];

      setFieldValue('eventConditions', conditions);
    },
    [setFieldValue, values.eventConditions]
  );

  const handleRemoveFormCondition = React.useCallback<IFormEditOneEventProps['onRemoveCondition']>(
    (index): void => {
      const newEventConditions = values.eventConditions.filter(
        (_, i: number): boolean => index !== i
      );

      setFieldValue('eventConditions', newEventConditions);
    },
    [values, setFieldValue]
  );

  const eventAttributes = findEventAttributesByEventName(events, values.eventCode);

  const eventSelectOptions = makeEventOptions(events);
  const attributeSelectOptions = makeAttributeOptions(eventAttributes);

  return (
    <FormEditOneEventComponent
      applicationCode={applicationCode}
      eventSelectOptions={eventSelectOptions}
      attributeSelectOptions={attributeSelectOptions}
      names={{
        eventCode: 'eventCode',
        isThrowOut: 'isThrowOut',
        eventConditions: 'eventConditions'
      }}
      values={values}
      errors={errors}
      touched={touched}
      isLoadingEventOptions={isLoadingEvents}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      onChangeField={handleChange}
      onBlurField={handleBlur}
      onAddCondition={handleAddFormCondition}
      onRemoveCondition={handleRemoveFormCondition}
      isShowThrowOut={isShowThrowOut}
      onRemove={onRemoveEvent}
      view={view}
    />
  );
}
