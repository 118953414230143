// Types
import { IVector2 } from '@src/common/types/types';

export function getMousePositionToPage(event: MouseEvent): IVector2 {
  const elemBox = (event.currentTarget as HTMLElement).getBoundingClientRect();

  const x = event.clientX - elemBox.left;
  const y = event.clientY - elemBox.top;

  return { x, y };
}
