import * as Sentry from '@sentry/browser';

// Types
import { IUniqueId } from '../types/entities';

const { isProduction } = process.env;

interface IUserData {
  id: IUniqueId['id'];
}

const production = isProduction && window.location.hostname === 'app.pushwoosh.com';

export const setUserData = ({ id }: IUserData): void => {
  if (production) {
    Sentry.configureScope((scope) => {
      scope.setUser({ id });
    });
  }
};
