import * as React from 'react';

// Components
import { Point } from '../Point';

// Types
import { IProps } from './PointList.types';

export const PointList = ({
  points,
  isRead,
  onDragStart,
  handleSetActivePopover,
  activePopoverPointType
}: IProps): JSX.Element => (
  <>
    {points.map((point): JSX.Element => {
      const handlePointMouseDown = (): void => {
        onDragStart(point);
      };

      return (
        <Point
          key={point.id}
          type={point.type}
          onMouseDown={handlePointMouseDown}
          isRead={isRead}
          onSetActivePopover={handleSetActivePopover}
          activePopoverPointType={activePopoverPointType}
        />
      );
    })}
  </>
);
