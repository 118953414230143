import React from 'react';

// Components
import { Icon, IconEnum } from '@src/ui/kit';

// Types
import { ICommentProps } from './Comment.types';

// Styled
import { StyledComment, Name } from './styled';

export function Comment(props: ICommentProps): JSX.Element {
  const { onMouseDown } = props;

  return (
    <StyledComment onMouseDown={onMouseDown}>
      <Icon type={IconEnum.ADD_NOTES_POINT_FILLED} />
      <Name>Add notes</Name>
    </StyledComment>
  );
}
