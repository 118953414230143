import { PointType, IconPointDefault, IconPointSmall } from '@src/common/constants';

type TResult = {
  widthIcon: number;
  heightIcon: number;
};

export const getSizeIconPoint = (type: PointType): TResult => {
  if (type === PointType.BOOLEAN_SPLITTER) {
    return {
      widthIcon: IconPointSmall.WIDTH,
      heightIcon: IconPointSmall.HEIGHT
    };
  }

  return {
    widthIcon: IconPointDefault.WIDTH,
    heightIcon: IconPointDefault.HEIGHT
  };
};
