// Types
import { IFormCommonProps } from '@src/common/types/form';
import { FrequencyCapping } from '@src/common/types/frequencyCapping';

export interface IEditFrequencyCappingValues {
  isEnabled: FrequencyCapping['isEnabled'];
  period: FrequencyCapping['period'];
}

export type TEditFrequencyCappingProps = IFormCommonProps<IEditFrequencyCappingValues>;

export enum FrequencyCappingMode {
  NO_LIMITS = 'noLimits',
  CUSTOM_PERIOD = 'customPeriod'
}
