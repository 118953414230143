import { createContext, useContext } from 'react';

import type { HttpClient, GrpcClient } from '@pushwoosh/http-client';
import type { GrpcBridge } from '@pushwoosh/grpc-bridge';

const HttpClientContext = createContext<HttpClient | null>(null);
export const HttpClientProvider = HttpClientContext.Provider;

export function useHttpClient(): HttpClient {
  const httpClient = useContext(HttpClientContext);
  if (!httpClient) {
    throw new Error(
      'HttpClientContext is not defined! Please check what are you using useHttpClient in a child of HttpClientProvider!'
    );
  }

  return httpClient;
}

const GrpcClientContext = createContext<GrpcClient>(null as any);
export const GrpcClientProvider = GrpcClientContext.Provider;
export function useGrpcClient(): GrpcClient {
  return useContext(GrpcClientContext);
}

const GrpcBridgeContext = createContext<GrpcBridge>(null as any);
export const GrpcBridgeProvider = GrpcBridgeContext.Provider;
export function useGrpcBridge(): GrpcBridge {
  return useContext(GrpcBridgeContext);
}
