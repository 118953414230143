import * as React from 'react';

// Styled
import { Container, FirstCircle, SecondCircle, ThirdCircle } from './styled';

// Types
import { Props } from './Loading.types';

export function Loading(props: Props): JSX.Element {
  const { size } = props;
  return (
    <Container>
      <FirstCircle size={size} />
      <SecondCircle size={size} />
      <ThirdCircle size={size} />
    </Container>
  );
}
