import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPushSmallFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.586 2.586C1 3.172 1 4.114 1 6v3c0 1.886 0 2.828.586 3.414C2.172 13 3.114 13 5 13H4v1.598a.5.5 0 00.765.424L8 13h3c1.886 0 2.828 0 3.414-.586C15 11.828 15 10.886 15 9V6c0-1.886 0-2.828-.586-3.414C13.828 2 12.886 2 11 2H5c-1.886 0-2.828 0-3.414.586zM4 5.75A.75.75 0 014.75 5h6.5a.75.75 0 010 1.5h-6.5A.75.75 0 014 5.75zM4.75 8a.75.75 0 000 1.5h3.5a.75.75 0 000-1.5h-3.5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPushSmallFilled;
