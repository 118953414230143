import * as React from 'react';

// Components
import { Button } from '@src/ui/kit/Button';

// Types
import { Props } from './ExtraOneButton.types';

export function ExtraOneButton(props: Props): JSX.Element {
  const { actionButtonName = 'Apply', onClickActionButton, disabled = false } = props;

  return (
    <Button disabled={disabled} color="secondary" onClick={onClickActionButton as () => void}>
      {actionButtonName}
    </Button>
  );
}
