import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPlatformLogoAndroidMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.908 3.662l.975-.974a.4.4 0 00-.556-.576l-1.146 1.146a5.475 5.475 0 00-4.362 0L8.683 2.121a.4.4 0 00-.566.566l.975.975A4.222 4.222 0 007.2 7.204c0 .221.179.4.4.4h8.8a.4.4 0 00.4-.4 4.222 4.222 0 00-1.892-3.542zM10 6.004a.4.4 0 100-.8.4.4 0 000 .8zm4 0a.4.4 0 100-.8.4.4 0 000 .8z"
        fill={props.fill}
      />
      <path
        d="M5.2 8.404a1.2 1.2 0 00-1.2 1.2v5.6a1.2 1.2 0 102.4 0v-5.6a1.2 1.2 0 00-1.2-1.2zM18.8 8.404a1.2 1.2 0 00-1.2 1.2v5.6a1.2 1.2 0 102.4 0v-5.6a1.2 1.2 0 00-1.2-1.2zM7.6 8.404h8.8c.22 0 .4.18.4.4v6.4a2 2 0 01-1.6 1.96v2.84a1.2 1.2 0 11-2.4 0v-2.8h-1.6v2.8a1.2 1.2 0 11-2.4 0v-2.84a2 2 0 01-1.6-1.96v-6.4c0-.22.179-.4.4-.4z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPlatformLogoAndroidMediumFilled;
