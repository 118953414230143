export enum WaitMode {
  Wait = 'exactTime',
  Delay = 'delay',
  Dynamic = 'dynamicTime',
  Date = 'exactDateTime',
  Week = 'exactWeekDaysTime'
}

export enum DelayOptions {
  RIGHT_ON_TIME = 'right_on_time',
  AFTER = 'after',
  BEFORE = 'before'
}
