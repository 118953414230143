import * as React from 'react';

// Store Hooks
import { useGoalEventPoints, useStoreStatisticsByJourney, useApplications } from '@src/store/hooks';

// UI Components
import { DetailPointGoalEvent as DetailPointGoalEventComponent } from '../DetailPointGoalEvent';

export function DetailPointGoalEventList(): JSX.Element {
  const { goalEventPoints } = useGoalEventPoints();
  const statisticsByJourney = useStoreStatisticsByJourney();
  const goalsList = statisticsByJourney?.goals ?? {};
  const applications = useApplications();

  const goals = goalEventPoints.map((item) => {
    const goalStat = goalsList[item.data.name];
    const application = applications.filter((app) => app.code === item.data.applicationCode);

    return {
      ...item.data,
      applicationName: application.length ? application[0].title : 'unknown',
      achievedGoal: goalStat
    };
  });

  const goalEventList = goals.map(
    (goalEventPoint, index): JSX.Element => (
      <DetailPointGoalEventComponent
        key={index}
        applicationCode={goalEventPoint.applicationCode}
        applicationName={goalEventPoint.applicationName}
        eventName={goalEventPoint.name}
        isThrowOut={goalEventPoint.isThrowOut}
        eventConditions={goalEventPoint.eventConditions}
        achievedGoal={goalEventPoint.achievedGoal}
        totalAchievedGoal={statisticsByJourney.achievedGoal}
      />
    )
  );

  return <>{goalEventList}</>;
}
