import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgTimeMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.75 7.2a.75.75 0 00-1.5 0V12c0 .284.16.544.415.67l3.2 1.6a.75.75 0 10.67-1.34l-2.785-1.393V7.2z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.25a8.75 8.75 0 100 17.5 8.75 8.75 0 000-17.5zM4.75 12a7.25 7.25 0 1114.5 0 7.25 7.25 0 01-14.5 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgTimeMediumLined;
