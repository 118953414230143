import * as React from 'react';

// Components
import { RadioButton } from '@src/ui/kit/RadioButton';
import { RadioButtonGroup } from '@src/ui/kit/RadioButtonGroup';
import { Select, TSelectOption } from '@src/ui/kit/Select';
import { Section } from '@src/ui/kit';

// Constants
import { OPTIONS_FREQUENCY_CAPPING } from '@src/common/constants/frequencyCapping';

// Types
import { TEditFrequencyCappingProps, FrequencyCappingMode } from './EditFrequencyCapping.types';

// Styled
import { Field, FieldTitle, Text } from './styled';

export const EditFrequencyCapping = (props: TEditFrequencyCappingProps): JSX.Element => {
  const { names, values, setFieldValue } = props;

  const valueLimit = values.isEnabled
    ? FrequencyCappingMode.CUSTOM_PERIOD
    : FrequencyCappingMode.NO_LIMITS;

  const handleChangeTypeLimit = React.useCallback(
    (value: string): void => {
      if (value === FrequencyCappingMode.CUSTOM_PERIOD) {
        setFieldValue(names.isEnabled, true);
        return;
      }

      setFieldValue(names.isEnabled, false);
      setFieldValue(names.period, 0);
    },
    [names, setFieldValue]
  );

  const handleChangePeriod = React.useCallback(
    (selectedValue: TSelectOption) => {
      setFieldValue(names.period, selectedValue.value);
    },
    [names, setFieldValue]
  );

  return (
    <Section direction="column">
      <RadioButtonGroup value={valueLimit} onChange={handleChangeTypeLimit}>
        <RadioButton value={FrequencyCappingMode.NO_LIMITS} label="No limits" />
        <RadioButton value={FrequencyCappingMode.CUSTOM_PERIOD} label="Custom period" />
      </RadioButtonGroup>
      {values.isEnabled === true && (
        <Field>
          <FieldTitle>Once in</FieldTitle>
          <Select
            value={{
              label: OPTIONS_FREQUENCY_CAPPING.find((item) => item.value === values.period).label,
              value: values.period
            }}
            options={OPTIONS_FREQUENCY_CAPPING}
            onChange={handleChangePeriod}
            isSearchable
          />
        </Field>
      )}
      {values.isEnabled === false && (
        <Text>User will be able to enter the journey without any limitations.</Text>
      )}
      {values.isEnabled === true && values.period === 0 && (
        <Text>User will enter the journey only once ever.</Text>
      )}
      {values.isEnabled === true && values.period !== 0 && (
        <Text>User will be able to enter the journey according to the limitations.</Text>
      )}
    </Section>
  );
};
