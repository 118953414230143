import * as React from 'react';

// Components
import { FormInput } from '@src/ui/form/FormInput';
import { InputDatepicker } from '@src/ui/kit';
import { FormControl } from '@src/ui/form/FormControl';

// Constants
import { AttributeTypeEnum } from '@src/common/constants/attribute';

// Helpers
import { safeDate } from '@src/ui/features/EditEvents/helpers/safeDate';

// Types

// Styled
import { ErrorMessage } from '@src/ui/form/FormControl/styled';
import { OperatorMultipleChoice, Operator } from './styled';
import { IFilterConditionTwoChoiceProps } from './FilterConditionTwoChoice.types';

export function FilterConditionTwoChoice(props: IFilterConditionTwoChoiceProps): JSX.Element {
  const { attributeType, value, name, error, touched, setFieldValue, onBlurField } = props;

  const handleInputIntegerChange = React.useCallback(
    (name, value): void => {
      const currentValue = value === '' ? '' : parseInt(value, 10);

      if (currentValue === '' || !Number.isNaN(currentValue)) {
        setFieldValue(name, currentValue);
      }
    },
    [setFieldValue]
  );

  if (!Array.isArray(value)) {
    return null;
  }

  if (attributeType === AttributeTypeEnum.INTEGER) {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    return (
      <>
        <OperatorMultipleChoice>
          <FormInput
            placeholder="Enter from integer"
            value={value[0] as any}
            name={`${name}[0]`}
            onChange={handleInputIntegerChange}
            onBlur={onBlurField}
          />

          <Operator>and</Operator>

          <FormInput
            placeholder="Enter to integer"
            value={value[1] as any}
            name={`${name}[1]`}
            onChange={handleInputIntegerChange}
            onBlur={onBlurField}
          />
        </OperatorMultipleChoice>
        {error && touched && <ErrorMessage>{error}</ErrorMessage>}
      </>
    );
    /* eslint-enable @typescript-eslint/no-explicit-any */
  }

  if (attributeType === AttributeTypeEnum.DATE) {
    const selectedFrom = safeDate(value[0]);
    const selectedTo = safeDate(value[1]);

    const onChangeFrom = (date: Date): void => {
      setFieldValue(`${name}[0]`, date);
    };

    const onChangeTo = (date: Date): void => {
      setFieldValue(`${name}[1]`, date);
    };

    return (
      <>
        <OperatorMultipleChoice>
          <FormControl>
            <InputDatepicker value={selectedFrom} onChange={onChangeFrom} />
          </FormControl>

          <Operator>and</Operator>

          <FormControl>
            <InputDatepicker value={selectedTo} onChange={onChangeTo} />
          </FormControl>
        </OperatorMultipleChoice>
        {error && touched && <ErrorMessage>{error}</ErrorMessage>}
      </>
    );
  }

  return null;
}
