import styled from 'styled-components';

// Components
import { Badge } from '@src/ui/kit/Badge';

// Constants
import { Color, Spacing, FontSize, FontWeight } from '@pushwoosh/kit-constants';
import { FormInput, FormItem } from '@src/ui/form';

export const TextWithOffset = styled.div`
  margin-right: ${Spacing.S3};
  margin-bottom: ${Spacing.S1};
`;

export const BadgeFrequency = styled(Badge)`
  margin-right: ${Spacing.S3};
  margin-bottom: ${Spacing.S1};
`;

export const BadgeValue = styled(Badge)`
  margin-right: ${Spacing.S1};
  margin-bottom: ${Spacing.S1};
`;

export const Gray = styled.span`
  font-size: ${FontSize.REGULAR};
  font-weight: ${FontWeight.REGULAR};
  color: ${Color.LOCKED};
`;

export const StyledInput = styled(FormInput)`
  padding-bottom: 0;
  margin-top: ${Spacing.S5};
  width: 100%;
`;

export const FormItemWithButton = styled(FormItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
