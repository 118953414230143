import styled from 'styled-components';
import { Color, Spacing } from '@pushwoosh/kit-constants';
import { Footnote, H4 } from '@pushwoosh/kit-typography';

export const Container = styled.div`
  border-top: 1px solid ${Color.DIVIDER};
  display: grid;
  grid-template-columns: 228px 128px 172px;
  grid-column-gap: ${Spacing.S5};
  padding: ${Spacing.S5};
  box-sizing: border-box;
`;

export const Cell = styled.div`
  display: grid;
  grid-template-rows: 22px auto;
  grid-row-gap: ${Spacing.S1};
`;

export const Item = styled.div`
  display: grid;
  grid-template-columns: 8px auto auto auto;
  grid-column-gap: ${Spacing.S1};
  justify-content: start;
  align-items: center;
`;

export const Stack = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: ${Spacing.S1};
  justify-content: start;
  height: 16px;
  align-items: center;
`;

export const Status = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-sizing: border-box;
  border: ${({ isDisabled }: { isDisabled: boolean }) =>
    `2px solid ${isDisabled ? Color.LOCKED : Color.BRIGHT}`};
  background-color: ${({ isDisabled }: { isDisabled: boolean }) =>
    isDisabled ? Color.CLEAR : Color.BRIGHT};
`;

export const Values = styled(Footnote)`
  color: ${Color.LOCKED};
`;

export const Significant = styled(Footnote)`
  color: ${Color.LOCKED};
  margin-left: ${Spacing.S3};
`;

export const ConversionWrap = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-column-gap: ${Spacing.S2};
`;

type IconWrapProps = {
  conversionDiff: number;
};

export const BadgeValue = styled.div`
  display: flex;
`;

export const IconWrap = styled.div<IconWrapProps>`
  transform: rotate(${({ conversionDiff }) => (conversionDiff < 0 ? '45deg' : '-45deg')});
`;

export const StyledH4 = styled(H4)`
  color: ${({ isDisabled }: { isDisabled: boolean }) => (isDisabled ? Color.LOCKED : Color.MAIN)};
`;
