// Api
import { GetOptions, HttpClient } from '@pushwoosh/http-client';

// Helpers
import { getApiOrigin } from '@src/store/helpers';

// Types
import { TFetchRestrictions } from './types';

export const service = {
  async fetch(api: HttpClient): Promise<TFetchRestrictions> {
    const options: GetOptions<TFetchRestrictions, never, never> = {
      withAuthorization: true
    };
    return api.get<TFetchRestrictions, never, never>(
      `${getApiOrigin()}/api/account/restrictions`,
      options
    );
  }
};
