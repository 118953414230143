import React from 'react';
import { IUniqueId } from '@src/common/types/entities';
import { ModalType } from '@src/common/constants/modals';
// Store Hooks
import { useOpenNewModal } from '@src/store/hooks';
import { JourneyStatus } from '@src/common/constants';

export const useRenameJourney = (
  journeyId: IUniqueId['id'],
  journeyStatus: JourneyStatus,
  title: string,
  beforeLoadJourney: boolean
): (() => void) => {
  const openNewModal = useOpenNewModal();
  const renameJourneyHandle = React.useCallback((): void => {
    openNewModal({
      type: ModalType.RENAME_JOURNEY,
      data: {
        journeyId,
        journeyStatus,
        title,
        actionButtonName: 'Rename',
        beforeLoadJourney
      }
    });
  }, [openNewModal, journeyId, title, beforeLoadJourney, journeyStatus]);

  return renameJourneyHandle;
};
