import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgEmailMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 5.5A1.5 1.5 0 003 7v10a1.5 1.5 0 001.5 1.5h15A1.5 1.5 0 0021 17V7a1.5 1.5 0 00-1.5-1.5h-15zm1.835 2.83a.75.75 0 00-.67 1.34l6 3a.75.75 0 00.67 0l6-3a.75.75 0 10-.67-1.34L12 11.161 6.335 8.329z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgEmailMediumFilled;
