import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSendSmsPointFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={40} height={40} rx={3} fill="#B255E0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 20c0 6.627-5.373 12-12 12H9.207a.5.5 0 01-.353-.854l2.307-2.307a.517.517 0 00.008-.714A11.957 11.957 0 018 20c0-6.627 5.373-12 12-12s12 5.373 12 12zm-19.5-4a1.5 1.5 0 00-1.5 1.5v1.25a1.5 1.5 0 001.5 1.5h1.2a.3.3 0 01.3.3v2.15a.3.3 0 01-.3.3h-.4a.3.3 0 01-.3-.3v-.45a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v.25a1.5 1.5 0 001.5 1.5h2a1.5 1.5 0 001.5-1.5v-1.75a1.5 1.5 0 00-1.5-1.5h-1.2a.3.3 0 01-.3-.3V17.3a.3.3 0 01.3-.3h.4a.3.3 0 01.3.3v.2a.5.5 0 00.5.5h1a.5.5 0 00.5-.5 1.5 1.5 0 00-1.5-1.5h-2zm5.32.183a.5.5 0 00-.82.384V23.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-3.45a.15.15 0 01.24-.12l.58.435a.3.3 0 00.36 0l.58-.435a.15.15 0 01.24.12v3.45a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-6.933a.5.5 0 00-.82-.384l-2.084 1.737a.15.15 0 01-.192 0l-2.084-1.737zM24 17.5a1.5 1.5 0 011.5-1.5h2a1.5 1.5 0 011.5 1.5.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-.2a.3.3 0 00-.3-.3h-.4a.3.3 0 00-.3.3v1.65a.3.3 0 00.3.3h1.2a1.5 1.5 0 011.5 1.5v1.75a1.5 1.5 0 01-1.5 1.5h-2a1.5 1.5 0 01-1.5-1.5v-.25a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v.45a.3.3 0 00.3.3h.4a.3.3 0 00.3-.3v-2.15a.3.3 0 00-.3-.3h-1.2a1.5 1.5 0 01-1.5-1.5V17.5z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgSendSmsPointFilled;
