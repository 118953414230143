import React from 'react';
import { isBefore, isAfter } from 'date-fns';
import { useStoreActions, useStoreState } from '@src/store/store';
import { RightDrawerSidebar } from '@src/ui/kit';
import { InfoRoundIcon } from '@pushwoosh/kit-icons';
import { Color } from '@pushwoosh/kit-constants';
import { Footnote } from '@pushwoosh/kit-typography';
import { Container, EmptyChangeLog, ChangeLogInfo, Description, EmptyContainer } from '../styled';
import { ChangeItemComponent } from '../components/ChangeItem';
import folderAsset from '../assets/folder.svg';

export function ChangeLog(): JSX.Element {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeLog = useStoreState((state) => state.changeLog.changeLog) || [];
  const changeCounts = useStoreState((state) => state.changeLog.changeCounts);
  const hideSidepanel = useStoreActions((actions) => actions.sidebars.closeAllSidebars);
  const sortedChangeLog = React.useMemo(
    () =>
      changeLog?.sort((a, b) => {
        if (isBefore(new Date(a?.time), new Date(b?.time))) {
          return 1;
        }

        if (isAfter(new Date(a?.time), new Date(b?.time))) {
          return -1;
        }

        return 0;
      }),
    [changeLog]
  );

  const handleClose = React.useCallback((): void => {
    hideSidepanel();
  }, [hideSidepanel]);

  return (
    <RightDrawerSidebar title="Change log" isBack={false} isOpen onClose={handleClose}>
      <Container>
        {changeLog.length === 0 && (
          <EmptyContainer>
            <img src={folderAsset} alt="folder asset" />
            <EmptyChangeLog>There is no action yet</EmptyChangeLog>
            <Footnote>
              Change something on canvas or inside point and you will see this action here
            </Footnote>
          </EmptyContainer>
        )}
        {changeLog.length > 0 && (
          <>
            <ChangeLogInfo>
              <InfoRoundIcon size="small" view="lined" color={Color.LOCKED} />
              <Description>
                <Footnote>
                  {changeCounts} {changeCounts > 1 ? 'changes' : 'change'} will be applied
                </Footnote>
              </Description>
            </ChangeLogInfo>
            {sortedChangeLog.map((item) => (
              <ChangeItemComponent key={item.id} item={item} />
            ))}
          </>
        )}
      </Container>
    </RightDrawerSidebar>
  );
}
