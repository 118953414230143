import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgCloseRoundSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 13.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM8 15A7 7 0 108 1a7 7 0 000 14zM5.348 5.348a.75.75 0 011.061 0L8 6.94l1.591-1.59a.75.75 0 111.06 1.06L9.062 8l1.59 1.591a.75.75 0 11-1.06 1.06L8 9.062l-1.591 1.59a.75.75 0 11-1.06-1.06L6.938 8 5.35 6.409a.75.75 0 010-1.06z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgCloseRoundSmallLined;
