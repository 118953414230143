import React from 'react';
import { TWaitPoint } from '@src/common/types/points';
import { WaitMode } from '@src/common/constants/wait';

export function useTimeDelay(point: TWaitPoint) {
  const waitMode = React.useMemo(() => {
    if (point.data.exactTime) {
      return WaitMode.Wait;
    }

    if (point.data.delay) {
      return WaitMode.Delay;
    }

    if (point.data.dynamicTime) {
      return WaitMode.Dynamic;
    }

    if (point.data.exactDateTime) {
      return WaitMode.Date;
    }

    if (point.data.exactWeekDaysTime) {
      return WaitMode.Week;
    }

    return WaitMode.Delay;
  }, [point.data]);

  return {
    waitMode
  };
}
