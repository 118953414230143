import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';
import { Button } from '@src/ui/kit';

export const FormItem = styled.div`
  flex: 1;

  &:not(:last-child) {
    margin-right: ${Spacing.S5};
  }
`;

export const FormBlock = styled.div`
  margin-top: ${Spacing.S5};
`;

export const ModalButtonsWrap = styled.div`
  display: grid;
  grid-template: 'auto auto';
  gap: ${Spacing.S3};
`;

export const OptionsWrap = styled.div`
  margin-bottom: ${Spacing.S7};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Item = styled.div`
  margin-top: ${Spacing.S3};
`;

export const TitleWrap = styled.div`
  margin-bottom: ${Spacing.S7};
`;

export const SelectButton = styled(Button)`
  height: unset;
  width: unset;
`;
