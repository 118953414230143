import React from 'react';
import { sendMixpanelEvent } from '@src/common/analytics';
import { Paragraph } from '@pushwoosh/kit-typography';
import { Button, Icon, IconEnum, Message, Section } from '@src/ui/kit';
import { FormBlock } from '@src/ui/form/styled';
import { JourneyStatus, ModalType } from '@src/common/constants';
import { StyledPremiumIconWrapper } from '@src/ui/features/StartSegment/components/ScheduledLaunch/styled';
import { Color } from '@pushwoosh/kit-constants';
import { PremiumFeatures } from '@src/ui/features/Modals/PremiumModal/types';
import { useCloseAllModals, useOpenNewModal, useRestrictions } from '@src/store/hooks';
import { WaitingPeriodTypeProps } from '../WaitPeriodTime';
import {
  Branch,
  Container,
  Header,
  HeaderBranchKey,
  HeaderTitle,
  Content
} from './components/Branch';
import { OrSeparator } from './styled';

type Props = {
  setSaveDisabled: (value: boolean) => void;
  status: JourneyStatus;
} & WaitingPeriodTypeProps;

export function EventBranches(props: Props) {
  const { names, values, errors, status, setFieldValue, setSaveDisabled } = props;
  const { branches } = values;
  const canDelete = branches.length > 1;

  const openNewModal = useOpenNewModal();
  const closeAllModals = useCloseAllModals();
  const { allowCjAddBranchesInWaitForTrigger } = useRestrictions();

  const deleteBranch = React.useCallback(
    (branchIndex: number) => {
      const newBranches = branches.filter((_, index) => !(index === branchIndex));

      setFieldValue(names.branches, newBranches);

      sendMixpanelEvent({
        eventName: 'CustomerJourneyWaitFotTriggerBranchRemoved'
      });
    },
    [names, branches, setFieldValue]
  );

  const renameBranch = React.useCallback(
    (branchIndex: number, title: string) => {
      const newBranches = branches.map((branch, index) => {
        if (index === branchIndex) {
          return {
            ...branch,
            title
          };
        }

        return branch;
      });

      setFieldValue(names.branches, newBranches);
    },
    [names, branches, setFieldValue]
  );

  const toggleOpen = React.useCallback(
    (branchIndex: number) => {
      const newBranches = branches.map((branch, index) => {
        if (index === branchIndex) {
          return {
            ...branch,
            open: !branch.open
          };
        }

        return branch;
      });

      setFieldValue(names.branches, newBranches);
    },
    [names, branches, setFieldValue]
  );

  const handleContactSalesClick = React.useCallback(() => {
    // onSubmit();
    closeAllModals();
    openNewModal({
      type: ModalType.PREMIUM,
      data: {
        premiumType: PremiumFeatures.OVERWRITE
      }
    });
    sendMixpanelEvent({
      eventName: 'JourneyCanvasRestrictionButtonClicked',
      eventData: {
        point: 'Content Personalization'
      }
    });
  }, [closeAllModals, openNewModal]);

  return (
    <Section direction="column">
      <Paragraph>
        Add event branches that will determine user transitions from the current stage. You can
        create up to three such branches.
      </Paragraph>

      {allowCjAddBranchesInWaitForTrigger || (
        <>
          <Message
            icon={
              <StyledPremiumIconWrapper>
                <Icon type={IconEnum.PREMIUM_MONO} width={16} height={16} />
              </StyledPremiumIconWrapper>
            }
            color={Color.BRIGHT_LIGHT}
            fullWidth
            title="Upgrade your plan to configure multiple branches"
          />

          <Button
            view="ghost"
            color="secondary"
            size="compact"
            iconPosition="left"
            iconType={IconEnum.OPEN_SMALL_LINED}
            onClick={handleContactSalesClick}
          >
            CONTACT SALES
          </Button>
        </>
      )}

      <FormBlock>
        {branches.map((branch, index) => (
          <React.Fragment key={branch.key}>
            <Branch
              setFieldValue={setFieldValue}
              order={index + 1}
              title={branch.title}
              open={branch.open}
              events={branch.events}
              errors={errors?.branches}
              setSaveDisabled={setSaveDisabled}
              status={status}
              deleteBranch={deleteBranch}
              canDelete={canDelete}
              renameBranch={renameBranch}
              toggleOpen={toggleOpen}
            />
            <OrSeparator>OR</OrSeparator>
          </React.Fragment>
        ))}

        <Container>
          <Header kind="secondary">
            <HeaderTitle>
              <HeaderBranchKey>{'Branch "NOT TRIGGERED"'}</HeaderBranchKey>
            </HeaderTitle>
          </Header>
          <Content>
            <Paragraph>
              Users who have not triggered specified events or met the conditions within the waiting
              period will be directed to the “Not triggered” branch.
            </Paragraph>
          </Content>
        </Container>
      </FormBlock>
    </Section>
  );
}
