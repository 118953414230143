import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgChevronLeftSmall(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.48 4.424a.75.75 0 00-1.056.096l-2.5 3a.75.75 0 000 .96l2.5 3a.75.75 0 101.152-.96L7.476 8l2.1-2.52a.75.75 0 00-.096-1.056z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgChevronLeftSmall;
