import React from 'react';
import { Section, Switch } from '@src/ui/kit';
import { JourneyStatus } from '@src/common/constants';
import { TFormFnSetFieldValue } from '@src/common/types/form';
import { IEditPointSendSmsValues } from '../EditPointSendSms/EditPointSendSms.types';

type Props = {
  journeyStatus: JourneyStatus;
  values: IEditPointSendSmsValues;
  names: Record<keyof IEditPointSendSmsValues, string>;
  setFieldValue: TFormFnSetFieldValue;
};

export function SendByUserId(props: Props): JSX.Element {
  const { journeyStatus, values, names, setFieldValue } = props;
  const isDisabled = journeyStatus === JourneyStatus.RUNNING;

  const handleSwitchSendByUserId = React.useCallback(
    (value: boolean) => {
      setFieldValue(names.sendByUserID, value);
    },
    [setFieldValue, names]
  );

  return (
    <Section direction="column">
      <Switch
        isDisabled={isDisabled}
        defaultEnabled={values.sendByUserID}
        onChange={handleSwitchSendByUserId}
      >
        Send Sms notification across users devices with UserID
      </Switch>
    </Section>
  );
}
