import styled, { css } from 'styled-components';
import { Spacing, FontWeight, FontSize, Color, ShapeRadius } from '@pushwoosh/kit-constants';

export const DayLabelsWrap = styled.div`
  margin-top: ${Spacing.S5};
  display: flex;
  align-items: center;
`;

export const DayLabel = styled.div`
  width: 32px;
  height: 32px;
  font-weight: ${FontWeight.MEDIUM};
  font-size: ${FontSize.SMALL};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Color.LOCKED};
  text-transform: uppercase;

  &:not(:last-child) {
    margin-right: ${Spacing.S1};
  }
`;

export const Week = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${Spacing.S1}; ;
`;

type DayProps = {
  isCurrentMonth?: boolean;
  isSameDay: boolean;
  isDisabled?: boolean;
};

const sameDay = css`
  background: ${Color.BRIGHT};
  color: ${Color.CLEAR};
`;

const notSameDay = css`
  &:hover {
    background: ${Color.BRIGHT_LIGHT};
    color: ${Color.PRIMARY_HOVER};
  }

  &:active {
    background: ${Color.BRIGHT_LIGHT};
    color: ${Color.PRIMARY_PRESSED};
  }
`;

const disabled = css`
  border: 1px solid ${Color.FORM};
  background: linear-gradient(
    to top left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 0.8px),
    ${Color.FORM} 50%,
    rgba(0, 0, 0, 0) calc(50% + 0.8px),
    rgba(0, 0, 0, 0) 100%
  );
  pointer-events: none;
`;

export const Day = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${({ isCurrentMonth }: DayProps) =>
    isCurrentMonth ? FontWeight.MEDIUM : FontWeight.REGULAR};
  font-size: ${FontSize.REGULAR};
  color: ${Color.MAIN};
  cursor: pointer;
  opacity: ${({ isCurrentMonth }) => (isCurrentMonth ? 'unset' : '0.8')};
  flex-direction: column;
  border-radius: ${ShapeRadius.CONTROL};

  ${({ isSameDay, isDisabled }) => (isSameDay && !isDisabled ? sameDay : notSameDay)}

  ${({ isDisabled }) => isDisabled && disabled}

  &:not(:last-child) {
    margin-right: ${Spacing.S1};
  }
`;
