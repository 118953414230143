import React from 'react';

import { useStoreState } from '@src/store/store';
import { IUniqueId } from '@src/common/types/entities';
import { GoalsToPoints, PointGoalsStat, PointsToGoals } from '@src/common/types/statistics';

type TGetGoalsToPoints = (goalId: IUniqueId['id']) => PointGoalsStat[];
type TGetPointsToGoals = (pointId: IUniqueId['id']) => PointGoalsStat[];

export function useGoalsToPointsStat(): TGetGoalsToPoints {
  const goalsToPoints = useStoreState<GoalsToPoints>(
    (state) => state.statistics.journey.goalsToPointsMap
  );

  const handleGetPointsByGoalId = React.useCallback<TGetGoalsToPoints>(
    (goalId: string): PointGoalsStat[] => goalsToPoints[goalId],
    [goalsToPoints]
  );

  return handleGetPointsByGoalId;
}

export function usePointsToGoalsStat(): TGetPointsToGoals {
  const pointsToGoals = useStoreState<PointsToGoals>(
    (state) => state.statistics.journey.pointsToGoalsMap
  );

  const handleGetGoalsByPointId = React.useCallback<TGetPointsToGoals>(
    (pointId: string): PointGoalsStat[] => pointsToGoals[pointId],
    [pointsToGoals]
  );

  return handleGetGoalsByPointId;
}
