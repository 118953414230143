import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgAbTest(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.904 8.1a1 1 0 00-1.85-.62l-7.908 13A1 1 0 0014 22h3.342a.5.5 0 01.498.55l-.835 8.35a1 1 0 001.847.624l8-13A1 1 0 0026 17h-3.433a.5.5 0 01-.498-.55l.835-8.35zM19 20h-2.332a.5.5 0 01-.427-.76l3.091-5.081c.277-.455.978-.22.925.31l-.343 3.432A1 1 0 0020.909 19h2.407a.5.5 0 01.425.762l-3.174 5.158c-.278.452-.976.216-.923-.312l.35-3.509A1 1 0 0019 20zM7.745 10.859a1 1 0 01.99-.859h.53a1 1 0 01.99.859l.082.57a.5.5 0 01-.495.571H8.158a.5.5 0 01-.495-.57l.082-.571zM7.729 14a.5.5 0 00-.495.43L6.99 16.14a1 1 0 01-1.98-.282l.755-5.283A3 3 0 018.735 8h.53a3 3 0 012.97 2.576l.755 5.283a1 1 0 01-1.98.282l-.245-1.712A.5.5 0 0010.27 14H7.73zM29 22a1 1 0 00-1 1v7a1 1 0 001 1h2.5a3 3 0 002.08-5.164.663.663 0 01-.173-.654A2.5 2.5 0 0031 22h-2zm2 3a.5.5 0 000-1h-.5a.5.5 0 100 1h.5zm-.5 2a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a1 1 0 000-2h-1z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgAbTest;
