import * as React from 'react';
import { Tooltip } from '@pushwoosh/kit-tooltip';

// Components
import { FormControl } from '@src/ui/form/FormControl';
import { TextInput } from '@src/ui/kit/TextInput';
import { Icon, IconEnum } from '@src/ui/kit';
import { Color } from '@pushwoosh/kit-constants';

// Types
import { IProps } from './FormInput.types';

// Styled
import { InputWrap, Error } from './styled';

export function FormInput(props: IProps): JSX.Element {
  const {
    className,
    name,
    label,
    value,
    placeholder,
    onChange,
    onBlur,
    error,
    disabled,
    autoFocus
  } = props;
  const errorRef = React.useRef(null);
  const [isVisibleTooltip, setVisibleTooltip] = React.useState(false);

  const handleFocus = React.useCallback(() => {
    setVisibleTooltip(true);
  }, []);

  const handleBlur = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setVisibleTooltip(false);
      if (typeof onBlur === 'function') {
        onBlur(event);
      }
    },
    [onBlur]
  );

  const handleChange = React.useCallback(
    (name: string, value: string) => {
      if (typeof onChange === 'function') {
        onChange(name, value);
      }
    },
    [onChange]
  );

  return (
    <FormControl className={className} label={label} disabled={disabled}>
      <InputWrap ref={errorRef}>
        <TextInput
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          isError={!!error}
          disabled={disabled}
          autoFocus={autoFocus}
        />
        {error && (
          <Tooltip isVisible={error && isVisibleTooltip} position="right-middle" content={error}>
            <Error
              onMouseEnter={() => setVisibleTooltip(true)}
              onMouseLeave={() => setVisibleTooltip(false)}
            >
              <Icon type={IconEnum.DANGER_TRIANGLE_SMALL_LINED} fill={Color.WARNING} />
            </Error>
          </Tooltip>
        )}
      </InputWrap>
    </FormControl>
  );
}
