import * as React from 'react';

// Store
import { useStoreActions } from '@src/store/store';

// Hooks
import { useSetMode } from '@src/store/hooks';

// Enums
import { Modes } from '@src/common/constants';

// Types
import { TInfoToUpdatePoint } from '@src/common/types/points';

type TUpdatePoint = (point: TInfoToUpdatePoint) => void;
type TResult = TUpdatePoint;

export function useUpdatePoint(): TResult {
  const setMode = useSetMode();

  const updatePoint: TUpdatePoint = useStoreActions<TUpdatePoint>(
    (actions): TUpdatePoint => actions.points.update
  );

  const handlePointUpdate: TUpdatePoint = React.useCallback<TUpdatePoint>(
    (point): void => {
      updatePoint(point);
      setMode(Modes.CHANGED);
    },
    [updatePoint, setMode]
  );

  return handlePointUpdate;
}
