// Types
import { TPreset } from '@src/common/types/preset';
import { IOption } from '@src/common/types/select';

export function makePresetsOptions(presets: TPreset[]): IOption<string>[] {
  return presets.map(
    (preset): IOption<string> => ({
      label: preset.name,
      value: preset.code,
      message: preset.content
    })
  );
}
