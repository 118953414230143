import _includes from 'lodash/includes';
import _filter from 'lodash/filter';
import { useActionUpdatePositionAtPoint } from '@src/store/hooks/points';
import { PointType } from '@src/common/constants';
import { TPoints } from '@src/common/types/points';
import { IUniqueId } from '@src/common/types/entities';
import { useStoreState } from '@src/store/store';

interface IPointCoords {
  pointId: IUniqueId['id'];
  position: {
    x: number;
    y: number;
  };
}

export function useMagic() {
  const updatePointPosition = useActionUpdatePositionAtPoint();

  const entities = useStoreState((state) => state.points.entities);

  const entries = _filter(entities, (point) =>
    _includes([PointType.API, PointType.EVENT, PointType.SEGMENT], point.type)
  );

  let startY: number | null = null;
  let prevX: number | null = null;
  let lowY: number | null = null;
  let highX: number | null = null;

  const exitPoints = new Set([]);

  const pointsWithNewCoords: {
    [pointId: IUniqueId['id']]: { position: { x: number; y: number } };
  } = {};

  const pointsWithOldCoords: IPointCoords[] = [];

  let coordsGrid: {
    [x: number]: number[];
  } = {};

  const checkCoordsCollision = (x: number, y: number): number => {
    if (coordsGrid[x]) {
      if (coordsGrid[x].find((item) => item === y)) {
        return checkCoordsCollision(x, y + 250);
      }
    }

    coordsGrid = {
      ...coordsGrid,
      [x]: [...(coordsGrid[x] ? coordsGrid[x] : []), y]
    };

    return y;
  };

  const alignCoords = (point: TPoints) => {
    // проверяем нет ли поинта в уже подвинутых
    if (!pointsWithNewCoords[point.id]) {
      const newX = prevX + 250;
      const newY = checkCoordsCollision(newX, lowY);

      // сохраняем позицию для отмены
      pointsWithOldCoords.push({
        pointId: point.id,
        position: point.position
      });
      // добавляем со смещением вправо
      pointsWithNewCoords[point.id] = {
        position: {
          x: newX,
          y: newY
        }
      };

      // сохраняем новую точку по оси Х
      prevX = newX;
    } else {
      const { x, y } = pointsWithNewCoords[point.id].position;
      coordsGrid[x] = coordsGrid[x].filter((item) => item !== y);
      const newX = prevX + 250;
      const calcY = lowY <= y ? lowY : y;
      const newY = checkCoordsCollision(newX, calcY);

      // если поинт уже двигался надо сверить его координаты
      pointsWithNewCoords[point.id] = {
        position: {
          x: newX,
          y: newY
        }
      };
      prevX = newX;
      lowY = newY;
    }

    // сверяемся с самой крайней точкой, нужно для смещения Exit поинтов
    if (prevX > highX) {
      highX = prevX;
    }
  };

  const moveExitPoint = (point: TPoints, index: number) => {
    pointsWithOldCoords.push({
      pointId: point.id,
      position: point.position
    });
    // считаем разницу между самой верхней точкой (стартовой) и самой нижней чтобы поместить Exit посередине
    const calculatedY = startY + Math.abs(lowY - startY) / 2;
    pointsWithNewCoords[point.id] = {
      position: {
        x: highX + 500,
        y: exitPoints.size === 1 ? calculatedY : calculatedY + (index + 1) * 100
      }
    };
  };

  const moveByArrows = (point: TPoints) => {
    // двигаем поинт
    alignCoords(point);

    let entryX: number | null = null;
    let entryY: number | null = null;

    // обходим outputs и ищем следующий поинт в цепочке
    if (point.outputs !== []) {
      // если из поинта выходит несколько стрелок запоминаем его координаты
      if (point.outputs.length > 1) {
        entryX = pointsWithNewCoords[point.id].position.x;
        entryY = pointsWithNewCoords[point.id].position.y;

        // if (point.type !== PointType.BOOLEAN_SPLITTER) {
        //   point.outputs.reverse().map((output, index) => {
        //     // if (point.outputs.length > 1 && index === 0) {
        //     //   lowY = entryY + 250 * point.outputs.length -1;
        //     // }
        //
        //     // если это уже не первая стрелка из поинта, то
        //     if (index > 0) {
        //       const eC = entryY + 250 * index;
        //       const lC = lowY + 250;
        //       lowY = lowY <= entryY ? eC : lC;
        //       prevX = entryX;
        //     }
        //
        //     const nextPoint = entities[output.id];
        //
        //     // if (
        //     //   index === point.outputs.length - 1 &&
        //     //   point.outputs.length > 1 &&
        //     //   nextPoint.outputs.length > 1
        //     // ) {
        //     //   lowY += 250 * index;
        //     // }
        //
        //     if (nextPoint.type === PointType.EXIT) {
        //       // если следующий поинт это выход, то кладем его отдельно
        //       return exitPoints.add(nextPoint.id);
        //     }
        //
        //     // уходим в рекурсию чтобы обойти все поинты в цепочке
        //     return moveByArrows(nextPoint);
        //   });
        // }
      }

      point.outputs.map((output, index) => {
        const nextPoint = entities[output.id];

        if (nextPoint.type === PointType.EXIT) {
          // если следующий поинт это выход, то кладем его отдельно
          return exitPoints.add(nextPoint.id);
        }

        // если это уже не первая стрелка из поинта, то
        if (index > 0) {
          const eC = entryY + 250 * index;
          const lC = lowY + 250;
          lowY = lowY >= entryY ? lC : eC;
          prevX = entryX;
        }

        // уходим в рекурсию чтобы обойти все поинты в цепочке
        return moveByArrows(nextPoint);
      });

      entryY = null;
      entryX = null;
    }
  };

  const doMagic = () => {
    // eslint-disable-next-line array-callback-return
    entries.map((entry, index) => {
      pointsWithOldCoords.push({
        pointId: entry.id,
        position: entry.position
      });
      if (index === 0) {
        startY = Math.round(entry.position.y);
        highX = Math.round(entry.position.x);
      }

      if (prevX === null && lowY === null) {
        prevX = Math.round(entry.position.x);
        lowY = Math.round(entry.position.y);
      }

      moveByArrows(entry);
    });

    Array.from(exitPoints).map((pointId, index) => moveExitPoint(entities[pointId], index));

    // eslint-disable-next-line array-callback-return
    Object.keys(pointsWithNewCoords).map((pointId) => {
      updatePointPosition(pointId, pointsWithNewCoords[pointId].position);
    });

    return pointsWithOldCoords;
  };

  const undoMagic = (oldPoints: IPointCoords[]) => {
    oldPoints.map((point) => updatePointPosition(point.pointId, point.position));
  };

  return {
    doMagic,
    undoMagic
  };
}
