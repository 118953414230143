import React from 'react';
import { FormSelect } from '@src/ui/form/FormSelect';
import { periodOptions, TimePeriod } from '@src/common/constants/stats';
import { InputDatepicker, Button } from '@src/ui/kit';
import { H4 } from '@pushwoosh/kit-typography';
import { PeriodSettings } from '@src/common/types/statistics';
import { useForm } from '@src/ui/hooks';
import { useStoreActions } from '@src/store/store';
import { getPreset } from '../../helpers/getPreset';
import { Container, Content, Description, DateWrap, Footer } from './styled';

type Props = {
  periodSettings: PeriodSettings;
  onClose: () => void;
  isOpen: boolean;
  createdAt: string;
};

export function PeriodForm(props: Props): JSX.Element {
  const { periodSettings, createdAt, isOpen, onClose } = props;
  const setPeriodSettings = useStoreActions((actions) => actions.statistics.setPeriodSettings);
  const dateFromRef = React.useRef(null);

  const { values, setFieldValue, handleSubmit } = useForm<PeriodSettings>({
    initialValues: periodSettings,
    onSubmit(formValues): void {
      setPeriodSettings(formValues);
      onClose();
    }
  });

  return (
    <Container>
      <Content>
        <Description>
          Set to view results for a specific period. Dates are stored and used in UTC.
        </Description>
        <FormSelect
          name="timePeriod"
          options={periodOptions}
          value={values.period}
          label="Time period"
          onChange={(name, value) => {
            const preset = getPreset(value, createdAt);

            setFieldValue('period', value);
            setFieldValue('dateFrom', preset.dateFrom);
            setFieldValue('dateTo', preset.dateTo);

            if (preset.period === TimePeriod.CUSTOM_RANGE) {
              dateFromRef.current.firstElementChild.click();
            }
          }}
        />
        <DateWrap>
          <H4>From</H4>
          <InputDatepicker
            value={values.dateFrom}
            ref={dateFromRef}
            onChange={(value) => {
              setFieldValue('period', TimePeriod.CUSTOM_RANGE);
              setFieldValue('dateFrom', value);
            }}
          />
        </DateWrap>
        <DateWrap>
          <H4>To</H4>
          <InputDatepicker
            value={values.dateTo}
            onChange={(value) => {
              setFieldValue('period', TimePeriod.CUSTOM_RANGE);
              setFieldValue('dateTo', value);
            }}
          />
        </DateWrap>
      </Content>
      {isOpen && (
        <Footer>
          <Button color="secondary" size="field" view="shape" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" size="field" view="shape" onClick={handleSubmit}>
            Apply time range
          </Button>
        </Footer>
      )}
    </Container>
  );
}
