import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSpinner(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle opacity={0.2} cx={12} cy={12} r={7} stroke="#fff" strokeWidth={3} />
      <path d="M19 12a7 7 0 00-7-7" stroke="#fff" strokeWidth={3} strokeLinecap="round" />
    </svg>
  );
}

export default SvgSpinner;
