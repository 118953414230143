import * as React from 'react';

// Store
import { useStoreActions } from '@src/store/store';

// Types
import { TModal } from '@src/common/types/modal';

type TOpenNewModal = (modal: TModal) => void;
type TResult = TOpenNewModal;

export function useOpenNewModal(): TResult {
  const openNewModal: TOpenNewModal = useStoreActions<TOpenNewModal>(
    (actions): TOpenNewModal => actions.modals.openNewModal
  );

  const handleNewModalOpen = React.useCallback<TOpenNewModal>(
    (modal): void => {
      openNewModal(modal);
    },
    [openNewModal]
  );

  return handleNewModalOpen;
}
