// Store
import { useStoreState } from '@src/store/store';

// Types
import { TPresetByAppCodeAndPresetCode } from '@src/store/models/preset/preset.types';

export function useGetPresetByAppCodeAndPresetCode(): TPresetByAppCodeAndPresetCode {
  return useStoreState(
    (state): TPresetByAppCodeAndPresetCode => state.preset.presetByAppCodeAndPresetCode
  );
}
