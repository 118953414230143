import styled, { css } from 'styled-components';
import { Spacing, Color, FontSize, FontWeight, ShapeRadius } from '@pushwoosh/kit-constants';
import { NumberStepper as NumberStepperComponent } from '@pushwoosh/kit-number-stepper';
import { H5 } from '@pushwoosh/kit-typography';

export const Options = styled.div`
  display: grid;
  grid-template-columns: 144px repeat(4, 100px);
  column-gap: ${Spacing.S3};
  margin-top: ${Spacing.S1};
`;

export const Option = styled.div`
  font-size: ${FontSize.REGULAR};
  font-weight: ${FontWeight.MEDIUM};
  text-wrap: nowrap;

  &:first-child {
    margin-right: ${Spacing.S5};
  }
`;

export const Labels = styled.div`
  display: grid;
  grid-template-columns: 144px repeat(4, 100px);
  column-gap: ${Spacing.S3};
`;

export const Description = styled(H5)`
  color: ${Color.PHANTOM};
  display: inline-block;
  margin-left: ${Spacing.S1};
`;

export const NumberStepper = styled(NumberStepperComponent)`
  width: 100%;
  ${({ isErrored }) =>
    isErrored &&
    css`
      border: 1px solid ${Color.DANGER};
      border-radius: ${ShapeRadius.CONTROL};
    `}
`;

export const ResetButtonWrap = styled.div`
  margin-left: -${Spacing.S4};
  margin-top: ${Spacing.S4};
`;
