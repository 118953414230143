import * as React from 'react';
import { cloneDeep } from 'lodash';

// Analytics
import { sendMixpanelEvent } from '@src/common/analytics';

// UI Components
import { Modal } from '@src/ui/kit/Modal';
import { Section } from '@src/ui/kit';
import { DefaultButtons } from '@src/ui/kit/ModalButtons';
import {
  EditSilentHours,
  IFormEditSilentHoursValues
} from '@src/ui/features/SilentHours/components/EditSilentHours';

// Store Hooks
import {
  useCloseAllModals,
  useGetJourneyById,
  useUpdateJourneyById,
  useCurrentJourneyId
} from '@src/store/hooks';

// UI Hooks
import { useForm } from '@src/ui/hooks';

// Helpers
import { defineSilentHours } from '@src/common/helpers/defineSilentHours';

// Enums
import { JourneyStatus } from '@src/common/constants/journey';

export function ModalEditSilentHours(): JSX.Element {
  const closeAllModals = useCloseAllModals();
  const journeyId = useCurrentJourneyId();
  const [updateJourneysById] = useUpdateJourneyById();
  const getJourneyById = useGetJourneyById();
  const journey = getJourneyById(journeyId);
  const silentHours = defineSilentHours(journey);

  const handleSilentHoursSave = React.useCallback(
    async (formValues: IFormEditSilentHoursValues): Promise<void> => {
      sendMixpanelEvent({
        eventName: 'SetSilentHours',
        eventData: {
          PushEnabled: formValues.pushParams?.enabled,
          EmailEnabled: formValues.emailParams?.enabled,
          InappEnabled: formValues.inAppParams?.enabled,
          SmsEnabled: formValues.smsParams?.enabled
        }
      });

      updateJourneysById(
        journeyId,
        {
          params: {
            silentHours: cloneDeep(formValues)
          }
        },
        'silentHours'
      );

      closeAllModals();
    },
    [closeAllModals, journeyId, updateJourneysById]
  );

  const { values, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit } =
    useForm<IFormEditSilentHoursValues>({
      initialValues: cloneDeep(silentHours),
      onSubmit: handleSilentHoursSave
    });

  return (
    <Modal
      title="Silent Hours"
      footerRight={
        <DefaultButtons
          actionButtonName={journey.status === JourneyStatus.RUNNING ? 'Save' : 'Apply'}
          onClickActionButton={handleSubmit}
          onClickCancelButton={closeAllModals}
        />
      }
      isOpen
    >
      <Section>
        Not to disturb users at an inappropriate time, schedule Silent Hours when users don’t
        receive messages. Choose communication channels Silent Hours will apply to and set the
        period to be silent.
      </Section>
      <EditSilentHours
        names={{
          pushParams: 'pushParams',
          emailParams: 'emailParams',
          inAppParams: 'inAppParams',
          smsParams: 'smsParams'
        }}
        values={values}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        onBlurField={handleBlur}
        onChangeField={handleChange}
      />
    </Modal>
  );
}
