import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgCheckRoundSmallFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15A7 7 0 108 1a7 7 0 000 14zm3.642-8.22a.75.75 0 10-1.061-1.06L6.833 9.467 5.42 8.053a.75.75 0 00-1.06 1.06l1.944 1.945a.75.75 0 001.06 0l4.279-4.278z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgCheckRoundSmallFilled;
