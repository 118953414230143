import React from 'react';

import { StyledTable } from './styled';

type IProps = {
  children: JSX.Element | JSX.Element[];
};

export function Table({ children }: IProps) {
  return <StyledTable>{children}</StyledTable>;
}
