import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';
import { Button as ButtonComponent } from '@src/ui/kit';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Right = styled.div`
  align-self: flex-start;
  min-width: 160px;
  margin: auto;
  margin-right: 0;
`;

export const Button = styled(ButtonComponent)`
  margin-left: ${Spacing.S7};
`;

export const Header = styled.div`
  display: flex;
  align-content: center;
  margin-bottom: ${Spacing.S11};
  width: 100%;
`;

export const StyledImg = styled.img`
  max-width: 100%;
`;
