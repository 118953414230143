import * as React from 'react';

import { Color } from '@pushwoosh/kit-constants';

// Components
import { Icon, IconEnum } from '../Icon';

// Types
import { CheckboxProps } from './Checkbox.types';

// Styles
import { NativeCheckbox, StyledCheckbox } from './styled';

export function Checkbox(props: CheckboxProps): JSX.Element {
  const { className, htmlFor, name, value, isDisabled, onChange, onBlur, onFocus, readOnly } =
    props;

  return (
    <label className={className} htmlFor={htmlFor}>
      <NativeCheckbox
        id={htmlFor}
        name={name}
        readOnly={readOnly}
        value={value.toString()}
        checked={value}
        type="checkbox"
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={isDisabled}
      />

      <StyledCheckbox>
        {value ? <Icon fill={Color.CLEAR} type={IconEnum.CHECK_MEDIUM} /> : null}
      </StyledCheckbox>
    </label>
  );
}
