import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgRefreshSmallFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.192 9.547a.75.75 0 10-1.437-.427 3.919 3.919 0 01-6.212 1.93l.787-.786a.75.75 0 00-.53-1.28H3.133a.75.75 0 00-.75.75V12.4a.75.75 0 001.28.53l.815-.814a5.419 5.419 0 008.714-2.568zM2.808 6.453a.75.75 0 001.437.428 3.919 3.919 0 016.212-1.932l-.787.787a.75.75 0 00.53 1.28h2.667a.75.75 0 00.75-.75V3.6a.75.75 0 00-1.28-.53l-.815.814a5.419 5.419 0 00-8.714 2.568z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgRefreshSmallFilled;
