import * as React from 'react';

// Styled
import { TextInputContainer } from './styled';

// Types
import { ITextInputProps } from './TextInput.types';

export function TextInput(props: ITextInputProps): JSX.Element {
  const {
    className,
    name,
    value,
    placeholder,
    isError,
    disabled,
    onChange,
    onValidation,
    onFocus,
    onBlur,
    onMouseEnter,
    onMouseLeave,
    autoFocus = false,
    autocomplete = 'on'
  } = props;

  const [errorValidation, setErrorValidation] = React.useState(false);

  const handleValidation = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>): void => {
      if (typeof onBlur === 'function') {
        onBlur(event);
      }

      const { value: currentValue } = event.currentTarget;
      if (!currentValue) {
        setErrorValidation(false);
        return;
      }
      if (typeof onValidation === 'function') {
        const resultValidation = onValidation(currentValue);
        setErrorValidation(!resultValidation);
      }
    },
    [setErrorValidation, onBlur, onValidation]
  );

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.currentTarget;
      if (typeof onChange === 'function') {
        onChange(name, value);
      }
    },
    [onChange, name]
  );

  return (
    <TextInputContainer
      autoComplete={autocomplete}
      className={className}
      value={value || ''}
      name={name}
      placeholder={placeholder || ''}
      disabled={disabled}
      isError={isError || errorValidation}
      onChange={handleChange}
      onBlur={handleValidation}
      onFocus={onFocus}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      autoFocus={autoFocus}
    />
  );
}
