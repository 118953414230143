import React from 'react';
import { NotificationIcon } from '@pushwoosh/kit-icons';
import {
  StyledButton,
  StyledPremiumIcon
} from '@src/ui/features/Settings/components/SettingsPanel/styled';
import { IconEnum, Link } from '@src/ui/kit';
import { Popover } from '@src/ui/kit/Popover';
import { SettingsButton } from '@src/ui/features/Settings/components/SetttingsButton';
import { useCurrentJourneyId, useOpenNewModal, useRestrictions } from '@src/store/hooks';
import { addZero } from '@src/common/helpers/formatTime';
import { IJourney } from '@src/common/types/journey';
import { ModalType } from '@src/common/constants';

interface ISilentHoursButtonProps {
  onLinkClick: (event: React.MouseEvent) => void;
  isVisibleSidebar: boolean;
  isReadMode: boolean;
  journey: IJourney;
  isPopoverVisible: boolean;
  onSetPopoverType: (popoverType: string) => void;
}

export function SilentHoursButton(props: ISilentHoursButtonProps) {
  const { onSetPopoverType, isVisibleSidebar, isReadMode, journey, onLinkClick, isPopoverVisible } =
    props;
  const openNewModal = useOpenNewModal();
  const { allowCjSilentHours } = useRestrictions();
  const journeyId = useCurrentJourneyId();

  const [isHovered, setIsHovered] = React.useState(false);
  const [popoverXCoord, setPopoverCoord] = React.useState(null);

  const silentHours = journey?.params?.silentHours;

  const isEnabledSilentHours = [
    silentHours?.emailParams?.enabled,
    silentHours?.pushParams?.enabled,
    silentHours?.inAppParams?.enabled,
    silentHours?.smsParams?.enabled
  ].includes(true);

  const setSilentHoursHandle = React.useCallback((): void => {
    openNewModal({
      type: ModalType.SILENT_HOURS_SETTING,
      data: { journeyId }
    });
  }, [openNewModal, journeyId]);

  const setDetailSilentHoursHandle = React.useCallback((): void => {
    openNewModal({
      type: ModalType.DETAIL_SILENT_HOURS_SETTING,
      data: { journeyId }
    });
  }, [openNewModal, journeyId]);

  const handleSilentHoursClick = (event: React.MouseEvent) => {
    if (allowCjSilentHours) {
      if (isReadMode) {
        setSilentHoursHandle();
      } else {
        setDetailSilentHoursHandle();
      }
    } else {
      const { y } = event.currentTarget.getBoundingClientRect();
      setPopoverCoord(y);
      onSetPopoverType('silentHours');
    }
  };

  const popoverX = popoverXCoord - 50;
  const popoverY = 60;

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsHovered(false);
    onSetPopoverType(null);
  };

  if (!isReadMode && !isEnabledSilentHours) return null;

  return (
    <SettingsButton
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleSilentHoursClick}
      icon={<NotificationIcon size="medium" type="silent" view="filled" />}
      isCompact={isVisibleSidebar}
    >
      <>
        {!allowCjSilentHours && (
          <StyledPremiumIcon
            type={isHovered ? IconEnum.PREMIUM_ENABLED : IconEnum.PREMIUM_DISABLED}
          />
        )}
        {'Silent Hours'}
        {!allowCjSilentHours && isPopoverVisible && (
          <Popover
            title="Silent Hours"
            description="Make sure that you won’t bother your audience during the night time. Upgrade to Marketing plan to enhance your communications."
            x={popoverX}
            y={popoverY}
            onClose={handleClose}
          >
            <Link
              onClick={onLinkClick}
              to="https://app.pushwoosh.com/products"
              isExternal
              target="_blank"
            >
              <StyledButton>Upgrade now</StyledButton>
            </Link>
          </Popover>
        )}
      </>
    </SettingsButton>
  );
}
