import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgChevronLeftMedium(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.53 7.47a.75.75 0 010 1.06L10.06 12l3.47 3.47a.75.75 0 11-1.06 1.06l-4-4a.75.75 0 010-1.06l4-4a.75.75 0 011.06 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgChevronLeftMedium;
