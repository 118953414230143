exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles__wrap___2-ag9{overflow:hidden;white-space:nowrap}.styles__clip___3b4Xg{text-overflow:clip}.styles__ellipsis___hzCKm{text-overflow:ellipsis}.styles__fade___3kT4X{text-overflow:fade}", ""]);

// Exports
exports.locals = {
	"wrap": "styles__wrap___2-ag9",
	"clip": "styles__clip___3b4Xg",
	"ellipsis": "styles__ellipsis___hzCKm",
	"fade": "styles__fade___3kT4X"
};