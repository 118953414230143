import * as React from 'react';
import { ModalManager } from '@src/ui/features/Modals/ModalManager';

// Components
import { SidebarMenu } from '@src/ui/features/SidebarMenu';
import { LayoutDefault } from '@src/ui/templates';

// Features
import { JourneyList } from '@src/ui/features/JourneyList';

// Containers
import { HeaderJourneyList } from '@src/containers/components/HeaderJourneyList';

// Styled
import { Container, Content } from './styled';

export function PageJourneyList(): JSX.Element {
  return (
    <>
      <LayoutDefault Header={HeaderJourneyList} Sidebar={SidebarMenu}>
        <Container>
          <Content>
            <JourneyList />
          </Content>
        </Container>
      </LayoutDefault>
      <ModalManager />
    </>
  );
}
