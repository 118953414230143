import { action } from 'easy-peasy';

// Types
import { ISidebarsModel } from './sidebars.types';

export const model: ISidebarsModel = {
  sidebar: null,
  isOpened: false,

  openNewSidebar: action((state, payload): void => {
    const isAlreadyOpen = state.sidebar?.type === payload.type;

    if (!isAlreadyOpen) {
      state.sidebar = payload;
      state.isOpened = true;
    }
  }),

  closeAllSidebars: action((state): void => {
    state.sidebar = null;
    state.isOpened = false;
  })
};
