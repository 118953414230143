import * as React from 'react';

// Components
import { Paragraph } from '@pushwoosh/kit-typography';
import { Button } from '@src/ui/kit/Button';
import { DynamicTag } from '@src/ui/features/SetTags/components/DynamicTag';
import { Message } from '@src/ui/kit';
import { StyledPremiumIconWrapper } from '@src/ui/features/StartSegment/components/ScheduledLaunch/styled';

// Store Hooks
import {
  useCloseAllModals,
  useLoadEvents,
  useOpenNewModal,
  useRestrictions
} from '@src/store/hooks';

// Helpers
import { getDefaultValueTag } from '@src/common/helpers';

// Constants
import { TypeTag } from '@src/common/constants/tag';
import { Icon, IconEnum } from '@src/ui/kit/Icon';
import { Color } from '@pushwoosh/kit-constants';

// Types
import { UpdateTag } from '@src/common/types/tag';

// Styled
import { ModalType } from '@src/common/constants';
import { PremiumFeatures } from '@src/ui/features/Modals/PremiumModal/types';
import { sendMixpanelEvent } from '@src/common/analytics';
import { Container, H3, Labels, TagLabel, EventLabel, ValueLabel, Controls } from './styled';

import { Props } from './DynamicTagList.types';

const MAX_TAGS = 10;

export function DynamicTagList(props: Props): JSX.Element {
  const { values, names, existTags, setFieldValue, removeDisabled = false } = props;
  const { updateTags } = values;

  const [loadedAppCodes, setLoadedAppCodes] = React.useState(new Map());
  const [isLoadAppCodes, setIsLoadAppCodes] = React.useState(new Map());

  const [loadEventsByAppCode] = useLoadEvents();
  const { allowCjDynamicSetTagsInUpdateProfile } = useRestrictions();

  const handleAddNewTag = React.useCallback(() => {
    const newTag: UpdateTag = {
      id: null,
      name: '',
      value: getDefaultValueTag(TypeTag.DYNAMIC),
      type: TypeTag.DYNAMIC
    };
    setFieldValue(names.updateTags, [...values.updateTags, newTag]);
  }, [names.updateTags, values.updateTags, setFieldValue]);

  const handleLoadAppCode = React.useCallback(
    (appCode: string) => {
      const loadAppCodes = loadedAppCodes.get(appCode);
      if (!loadAppCodes) {
        setIsLoadAppCodes(new Map(isLoadAppCodes.set(appCode, true)));
        loadEventsByAppCode(appCode).then((res) => {
          setLoadedAppCodes(new Map(loadedAppCodes.set(appCode, true)));
          isLoadAppCodes.delete(appCode);
          setIsLoadAppCodes(new Map(isLoadAppCodes));
          return res;
        });
      }
    },
    [isLoadAppCodes, loadedAppCodes, setLoadedAppCodes, setIsLoadAppCodes, loadEventsByAppCode]
  );

  const removeTag = React.useCallback(
    (indexTag: number) => {
      const newUpdateTags = updateTags.filter((_, index) => index !== indexTag);
      setFieldValue(names.updateTags, newUpdateTags);
    },
    [names, updateTags, setFieldValue]
  );

  const isAddNewTag = updateTags.length <= existTags.length && updateTags.length < MAX_TAGS;

  const closeAllModals = useCloseAllModals();
  const openNewModal = useOpenNewModal();

  const handleContactSalesClick = React.useCallback(() => {
    // onSubmit();
    closeAllModals();
    openNewModal({
      type: ModalType.PREMIUM,
      data: {
        premiumType: PremiumFeatures.OVERWRITE
      }
    });
    sendMixpanelEvent({
      eventName: 'JourneyCanvasRestrictionButtonClicked',
      eventData: {
        point: 'Content Personalization'
      }
    });
  }, [closeAllModals, openNewModal]);

  return (
    <Container>
      <H3>Dynamic Tag Value</H3>
      <Paragraph>
        Select the tag and set the value dynamically based on the event attribute. Remember to use
        event that is triggered before the Set Tag.
      </Paragraph>

      {allowCjDynamicSetTagsInUpdateProfile || (
        <Message
          icon={
            <StyledPremiumIconWrapper>
              <Icon type={IconEnum.PREMIUM_MONO} width={16} height={16} />
            </StyledPremiumIconWrapper>
          }
          color={Color.BRIGHT_LIGHT}
          fullWidth
          title="Upgrade your plan to use this feature"
        />
      )}

      {updateTags.length > 0 && (
        <Labels>
          <TagLabel>Tag</TagLabel>
          <EventLabel>Event</EventLabel>
          <ValueLabel>Dynamic Value</ValueLabel>
        </Labels>
      )}
      {updateTags.map((item, index) => {
        if (item.value === null || typeof item.value !== 'object' || Array.isArray(item.value)) {
          return null;
        }
        const { applicationCode } = item.value;

        return (
          <DynamicTag
            key={index}
            index={index}
            item={item}
            applicationCode={applicationCode}
            existTags={existTags}
            values={values}
            names={names}
            isLoadAppCodes={isLoadAppCodes}
            removeDisabled={removeDisabled}
            setFieldValue={setFieldValue}
            handleLoadAppCode={handleLoadAppCode}
            removeTag={removeTag}
          />
        );
      })}

      <Controls>
        {allowCjDynamicSetTagsInUpdateProfile ? (
          <Button
            view="ghost"
            color="secondary"
            size="compact"
            iconPosition="left"
            iconType={IconEnum.PLUS_MEDIUM}
            onClick={handleAddNewTag}
            disabled={!isAddNewTag}
          >
            {isAddNewTag ? 'Dynamic Value' : 'Update User Profile limit reached'}
          </Button>
        ) : (
          <Button
            view="ghost"
            color="secondary"
            size="compact"
            iconPosition="right"
            iconType={IconEnum.OPEN_SMALL_LINED}
            onClick={handleContactSalesClick}
          >
            CONTACT SALES
          </Button>
        )}
      </Controls>
    </Container>
  );
}
