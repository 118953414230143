import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgTag(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.222 9.222a2 2 0 00-2 2v8.485a2 2 0 00.586 1.414l9.606 9.607a2 2 0 002.829 0l8.485-8.485a2 2 0 000-2.829l-9.607-9.606a2 2 0 00-1.414-.586h-8.485zm0 10.485v-8.485h8.485l9.607 9.606-8.486 8.486-9.606-9.607zm5.01-2.768a.5.5 0 11.708-.707.5.5 0 01-.708.707zm-1.414-2.121a2.5 2.5 0 103.536 3.536 2.5 2.5 0 00-3.536-3.536z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgTag;
