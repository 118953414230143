import * as React from 'react';

// Analytics
import { sendMixpanelEvent } from '@src/common/analytics';
import { SidebarType } from '@src/common/constants/sidebars';

// Store Hooks
import {
  useCurrentJourneyId,
  useGetJourneyById,
  useGetPointById,
  useJourneyAppCode,
  useOpenNewModal,
  useUpdatePoint,
  useEditJourneyById
} from '@src/store/hooks';

// UI Hooks
import { useForm } from '@src/ui/hooks';
import { useStoreActions, useStoreState } from '@src/store/store';

// Containers Hooks
import { useRichMediasByAppCodeWithAutoLoad } from '@src/ui/features/SendInApp/hooks/useRichMediasByAppCodeWithAutoLoad';

// UI Components
import { Section, Button, IconEnum } from '@src/ui/kit';
import { FormInput } from '@src/ui/form';
import {
  SendInAppForm,
  ISendInAppFormValues
} from '@src/ui/features/SendInApp/components/SendInAppForm';
import { Modal } from '@src/ui/kit/Modal';
import { DefaultButtons } from '@src/ui/kit/ModalButtons';

// Helpers
import { makeRichMediaOptions } from '@src/ui/helpers';

// Types
import { TSendInAppPoint } from '@src/common/types/points';
import { IUniqueId } from '@src/common/types/entities';
import { JourneyStatus, ModalType } from '@src/common/constants';

import { Description } from '../styled';

interface IProps {
  pointId: IUniqueId['id'];
  closeModal: () => void;
}

export function SendInApp(props: IProps): JSX.Element {
  const { pointId, closeModal } = props;

  const getPointById = useGetPointById();
  const updatePoint = useUpdatePoint();
  const journeyId = useCurrentJourneyId();
  const applicationCode = useJourneyAppCode(journeyId);
  const showDocumentationMenu = useStoreActions((actions) => actions.documentationMenu.show);
  const getJourneyById = useGetJourneyById();
  const openNewModal = useOpenNewModal();
  const editPoint = useStoreActions((actions) => actions.points.update);
  const handleEditJourneyById = useEditJourneyById();
  const sidebars = useStoreState((state) => state.sidebars);
  const openNewSidebar = useStoreActions((actions) => actions.sidebars.openNewSidebar);
  const closeAllSidebars = useStoreActions((actions) => actions.sidebars.closeAllSidebars);

  const { status } = getJourneyById(journeyId);

  const point = getPointById(pointId) as TSendInAppPoint;

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur,
    handleSubmit
  } = useForm<ISendInAppFormValues>({
    initialValues: {
      richMediaCode: point.data.richMediaCode,
      title: point.title,
      expireInDays: point.data.expireInDays
    },
    onSubmit(formValue): void {
      const pointData: TSendInAppPoint['data'] = {
        applicationCode,
        richMediaCode: formValue.richMediaCode,
        expireInDays: formValue.expireInDays
      };
      const newPoint = {
        id: pointId,
        title: formValue.title,
        data: pointData
      };

      if (status !== JourneyStatus.RUNNING) {
        updatePoint(newPoint);

        closeModal();
      } else {
        editPoint(newPoint);

        handleEditJourneyById(journeyId);

        sendMixpanelEvent({
          eventName: 'JourneyEditModeChangesApplied',
          eventData: {
            PointType: 'SendInApp'
          }
        });

        closeModal();

        openNewModal({
          type: ModalType.DETAIL_POINT,
          data: {
            pointId
          }
        });
      }
    }
  });

  const [richMedias, isLoadingRichMedias, richMedia, isContentLoading] =
    useRichMediasByAppCodeWithAutoLoad(applicationCode, values.richMediaCode);

  const richMediaSelectOptions = makeRichMediaOptions(richMedias);

  const handleCancel = React.useCallback(() => {
    const { title, richMediaCode } = values;

    const eventData = {
      PointType: 'SendInApp',
      PointChanged: point.title !== title || point.data.richMediaCode !== richMediaCode
    };

    if (status === JourneyStatus.RUNNING) {
      sendMixpanelEvent({
        eventName: 'JourneyEditModeChangesCancelled',
        eventData: {
          PointType: 'SendInApp'
        }
      });
    } else {
      sendMixpanelEvent({
        eventName: 'ConfigurePointCancelled',
        eventData
      });
    }

    closeModal();
  }, [closeModal, values, point, status]);

  const onShowDocumentation = React.useCallback((): void => {
    if (sidebars.isOpened) {
      closeAllSidebars();
    } else {
      openNewSidebar({
        type: SidebarType.DOCUMENTATION
      });
      showDocumentationMenu();
      sendMixpanelEvent({ eventName: 'ShowDocumentation' });
    }
  }, [showDocumentationMenu, sidebars, closeAllSidebars, openNewSidebar]);

  return (
    <Modal
      title="In-App"
      footerRight={
        <DefaultButtons
          onClickActionButton={handleSubmit}
          onClickCancelButton={handleCancel}
          actionButtonName={status === JourneyStatus.RUNNING ? 'Save' : 'Apply'}
        />
      }
      footerLeft={
        <Button
          color="primary"
          size="field"
          view="ghost"
          iconPosition="left"
          iconType={IconEnum.DOCS_MEDIUM_LINED}
          onClick={onShowDocumentation}
        >
          How to Use
        </Button>
      }
      isOpen
    >
      <Section direction="column">
        <Description>
          Reach users with rich media content specifically designed for mobile apps. User has to
          opt-in into receiving push notifications. To target all users, even those who opted-out
          from receiving push notifications, please see In-App module in Pushwoosh.
        </Description>
        <FormInput label="Point Name" name="title" value={values.title} onChange={setFieldValue} />
      </Section>
      <SendInAppForm
        applicationCode={applicationCode}
        richMediaSelectOptions={richMediaSelectOptions}
        values={values}
        names={{
          richMediaCode: 'richMediaCode',
          expireInDays: 'expireInDays',
          title: 'title'
        }}
        touched={touched}
        errors={errors}
        isLoadingRichMediaOptions={isLoadingRichMedias || isContentLoading}
        isContentLoading={isContentLoading}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        onChangeField={handleChange}
        onBlurField={handleBlur}
        richMedia={richMedia}
      />
    </Modal>
  );
}
