import styled from 'styled-components';
import { H4 } from '@pushwoosh/kit-typography';
import { FormInput } from '@src/ui/form/FormInput';

// Constants
import { Spacing } from '@pushwoosh/kit-constants';

export const DetailBlock = styled.div`
  margin-bottom: ${Spacing.S5};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const DetailTitle = styled(H4)`
  margin-bottom: ${Spacing.S1};
`;

export const TagWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledInput = styled(FormInput)`
  padding-bottom: 0;
  margin-top: ${Spacing.S5};
  width: 100%;
`;
