import React from 'react';
import { listTimeZones } from 'timezone-support';
// Components
import { Select } from '@src/ui/kit/Select';

// Types
import { ISelectOption } from '@src/common/types/form';

// Styled
import { H4 } from '@pushwoosh/kit-typography';
import { TimezoneWrapper } from './styled';

interface IProps {
  name: string;
  handleChange: (name: string, value: string) => void;
  value: string;
  isDisabled: boolean;
}

export function TimezoneSelect(props: IProps): JSX.Element {
  const { name, value, handleChange, isDisabled } = props;

  const listOfTimezones = React.useMemo(
    () =>
      listTimeZones().map((timezone) => ({
        label: timezone,
        value: timezone
      })),
    []
  );

  const handleTimezoneChange = React.useCallback<React.ComponentProps<typeof Select>['onChange']>(
    (timezone: ISelectOption<string>): void => {
      handleChange(name, timezone.value);
    },
    [handleChange, name]
  );

  return (
    <TimezoneWrapper>
      <H4>Timezone</H4>
      <Select
        isDisabled={isDisabled}
        name={name}
        options={listOfTimezones}
        value={{
          label: value,
          value
        }}
        onChange={handleTimezoneChange}
        isSearchable
        backspaceRemovesValue
      />
    </TimezoneWrapper>
  );
}
