import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSendEmailPoint(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 11a2 2 0 00-2 2v14a2 2 0 002 2h20a2 2 0 002-2V13a2 2 0 00-2-2H10zm.74 3.927a1 1 0 00.331 1.374l8.147 4.979.26.16a1 1 0 001.043 0l.261-.16L28.93 16.3a1 1 0 00.332-1.374l-.522-.854a1 1 0 00-1.375-.332l-6.843 4.182a1 1 0 01-1.042 0l-6.843-4.181a1 1 0 00-1.375.331l-.522.854z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgSendEmailPoint;
