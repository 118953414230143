import * as React from 'react';

// Store Hooks
import { useGetEmailsByApplicationCode, useLoadEmails } from '@src/store/hooks';

// Types
import { TEmail } from '@src/common/types/email';
import { TApplication } from '@src/common/types/application';

export function useEmailsByAppCodeWithAutoLoad(appCode: TApplication['code']): [TEmail[], boolean] {
  const getEmailsByApplicationCode = useGetEmailsByApplicationCode();
  const emails = getEmailsByApplicationCode(appCode);
  const [loadEmailsByAppCode, isLoadingEmails] = useLoadEmails();

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (appCode && !isLoadingEmails) {
      loadEmailsByAppCode(appCode);
    }
  }, [appCode]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return [emails, isLoadingEmails];
}
