// Api
import { GetOptions, HttpClient } from '@pushwoosh/http-client';

// Helpers
import { getApiOrigin } from '@src/store/helpers';

// Types
import { TFetchApplicationsResponse } from './types';

export const service = {
  async fetch(api: HttpClient): Promise<TFetchApplicationsResponse> {
    const options: GetOptions<TFetchApplicationsResponse, never, never> = {
      withAuthorization: true
    };
    // eslint-disable-next-line camelcase
    return api.get<TFetchApplicationsResponse, never, never>(
      `${getApiOrigin()}/api/application`,
      options
    );
  }
};
