import * as React from 'react';

import { sendMixpanelEvent } from '@src/common/analytics';
import { getMixpanelEventByType } from '@src/ui/features/JourneySidebar/helpers/getMixpanelEventByType';

// Components
import { Draggable } from '@src/ui/features/JourneySidebar/components/Draggable';
import { PointList } from '@src/ui/features/JourneySidebar/components/PointList';
import { Point } from '@src/ui/features/JourneySidebar/components/Point';
import { Comment } from '@src/ui/features/JourneySidebar/components/Comment';

// Enums
import { ENTRY_GROUP, FLOW_GROUP, CHANNEL_GROUP, PointType } from '@src/common/constants';

// Helpers
import { filterPointsByGroup } from '@src/ui/helpers';

// Types
import { TPoints } from '@src/common/types/points';
import { TComment } from '@src/common/types/comments';
import { IItemsProps } from './Items.types';

// Styled
import { ItemsContainer, CustomTitle, ItemsGroup } from './styled';

export function Items(props: IItemsProps): JSX.Element {
  const { points, draggablePoint, isDragging, isRead, onDragStart } = props;

  const [activePopoverPointType, setPopoverPointType] = React.useState(null);

  const entry = filterPointsByGroup(points, ENTRY_GROUP);
  const flow = filterPointsByGroup(points, FLOW_GROUP);
  const channel = filterPointsByGroup(points, CHANNEL_GROUP);

  React.useEffect(() => {
    const prevCursor = document.body.style.cursor;

    if (isDragging) {
      document.body.style.cursor = 'grabbing';
    }

    return (): void => {
      document.body.style.cursor = prevCursor;
    };
  }, [isDragging]);

  const handleSetActivePopover = (pointType: PointType) => {
    sendMixpanelEvent(getMixpanelEventByType(pointType));

    setPopoverPointType(pointType);
  };

  return (
    <ItemsContainer>
      {isDragging && (
        <Draggable>
          {draggablePoint.type === 'comment' ? (
            <Comment onMouseDown={() => onDragStart(draggablePoint as TComment)} />
          ) : (
            <Point
              type={draggablePoint.type as TPoints['type']}
              onMouseDown={() => onDragStart(draggablePoint as TPoints)}
            />
          )}
        </Draggable>
      )}
      <CustomTitle>Entry</CustomTitle>
      <ItemsGroup>
        <PointList
          activePopoverPointType={activePopoverPointType}
          handleSetActivePopover={handleSetActivePopover}
          points={entry}
          onDragStart={onDragStart}
          isRead={isRead}
        />
      </ItemsGroup>
      <CustomTitle>Channels</CustomTitle>
      <ItemsGroup>
        <PointList
          activePopoverPointType={activePopoverPointType}
          handleSetActivePopover={handleSetActivePopover}
          points={channel}
          onDragStart={onDragStart}
          isRead={isRead}
        />
      </ItemsGroup>
      <CustomTitle>Flow</CustomTitle>
      <ItemsGroup>
        <PointList
          activePopoverPointType={activePopoverPointType}
          handleSetActivePopover={handleSetActivePopover}
          points={flow}
          onDragStart={onDragStart}
          isRead={isRead}
        />
      </ItemsGroup>
    </ItemsContainer>
  );
}
