/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */

import { BranchType } from '@src/common/types/branch';
import { OperationsType } from '../constants/operations';
import { ICommonEvent } from '../types/event';

export abstract class LeafBase {
  public title?: string;

  public output?: string;

  public type: string;

  public toJson(): string {
    return '';
  }

  public toObj(): unknown {
    return {};
  }

  public addOutput(output: string): void {
    this.output = output;
  }

  public addTitle(title: string): void {
    this.title = title;
  }

  private static getEvents(
    ops: LeafType[],
    events: ICommonEvent[],
    eventsList?: ICommonEvent[],
    eventOp?: string,
    parentOp?: string
  ): ICommonEvent[] {
    const result = eventsList || [];
    const [op, ...otherOps] = ops;
    const secondOp = otherOps[0];

    if (op.type === 'val') {
      const operator = op as ValueLeaf;

      const event = events[operator.value];
      const eventWithOp = {
        ...event,
        operation: {
          type: eventOp === 'and' ? OperationsType.AND : OperationsType.OR,
          toIndex: operator.value
        }
      };

      result.push(eventWithOp);
    }

    if (op.type === 'and' || op.type === 'or') {
      const operator = op as OrLeaf | AndLeaf;

      this.getEvents(operator.ops, events, result, operator.type, eventOp);
    }

    if (secondOp?.type === 'and' || secondOp?.type === 'or') {
      const operator = secondOp as OrLeaf | AndLeaf;

      this.getEvents(operator.ops, events, result, operator.type);
    }

    if (secondOp?.type === 'val') {
      const operator = secondOp as ValueLeaf;

      const event = events[operator.value];
      const eventWithOp = {
        ...event,
        operation: {
          type: parentOp === 'and' ? OperationsType.AND : OperationsType.OR,
          toIndex: operator.value
        }
      };

      result.push(eventWithOp);
    }

    return result;
  }

  public static getBranchesFromJsom(leafJson?: string, events?: ICommonEvent[]): BranchType[] {
    events = events || [];

    if (!leafJson) {
      return [
        {
          key: generateLeafKey(),
          title: 'Trigger group 1',
          open: true,
          events: events.map((event) => ({
            ...event,
            operation: {
              type: OperationsType.OR,
              toIndex: null
            }
          }))
        }
      ];
    }

    const leafObj = JSON.parse(leafJson) as OrLeaf;

    const ops = leafObj.ops as OrLeaf['ops'] | AndLeaf['ops'];

    return ops.map((op, index) => {
      const operator = op as OrLeaf | AndLeaf;
      const single = ops.length === 1;

      if (op.type === 'val') {
        const opr = op as ValueLeaf;
        const event = events[opr.value];
        const eventWithOp = {
          ...event,
          operation: {
            type: OperationsType.OR,
            toIndex: opr.value
          }
        };

        return {
          key: generateLeafKey(),
          title: op.title || `Trigger group ${index + 1}`,
          open: single,
          events: [eventWithOp]
        };
      }

      return {
        key: generateLeafKey(),
        title: op.title || `Trigger group ${index + 1}`,
        open: single,
        events: this.getEvents(operator.ops, events, [], operator.type)
      };
    });
  }
}

type LeafType = OrLeaf | AndLeaf | ValueLeaf;

class OrLeaf extends LeafBase {
  public ops: LeafType[];

  constructor() {
    super();
    this.type = 'or';
  }

  public get haveOps() {
    return this.ops.length > 0;
  }

  public addOps(ops: LeafType[]) {
    this.ops = ops;
  }

  public getOps() {
    return this.ops;
  }

  public toObj(): unknown {
    return {
      type: 'or',
      output: this.output,
      title: this.title,
      ops: this.ops.map((op) => op.toObj())
    };
  }

  public toJson(): string {
    return JSON.stringify(this.toObj());
  }
}

class AndLeaf extends LeafBase {
  public ops: LeafType[];

  constructor() {
    super();
    this.type = 'and';
  }

  public get haveOps() {
    return this.ops.length > 0;
  }

  public addOps(ops: LeafType[]) {
    this.ops = ops;
  }

  public getOps() {
    return this.ops;
  }

  public toObj(): unknown {
    return {
      type: 'and',
      output: this.output,
      title: this.title,
      ops: this.ops.map((op) => op.toObj())
    };
  }

  public toJson(): string {
    return JSON.stringify(this.toObj());
  }
}

class ValueLeaf extends LeafBase {
  public value: number;

  public operator: OperationsType;

  constructor(value: number, operator: OperationsType) {
    super();
    this.value = value;
    this.type = 'val';
    this.operator = operator;
  }

  public getValue() {
    return this.value;
  }

  public getOperator() {
    return this.operator;
  }

  public toObj(): unknown {
    return {
      type: 'val',
      output: this.output,
      title: this.title,
      value: this.value
    };
  }

  public toJson() {
    return JSON.stringify(this.toObj());
  }
}

function makeLeaf(value?: unknown, type?: OperationsType, operator?: OperationsType) {
  if (type === OperationsType.AND) {
    return new AndLeaf();
  }

  if (type === OperationsType.OR) {
    return new OrLeaf();
  }

  return new ValueLeaf(value as number, operator);
}

function createRootLeaf(
  events: ICommonEvent[],
  operationType?: OperationsType,
  rootLeaf?: LeafType
) {
  const [event, secondEvent, ...otherEvents] = events;
  const eventLeaf = makeLeaf(event.operation.toIndex, null, event.operation.type);
  const leaf = makeLeaf(null, operationType || event.operation.type);
  const secondEventLeaf =
    secondEvent && makeLeaf(secondEvent.operation.toIndex, null, event.operation.type);

  if (!rootLeaf) {
    if (leaf instanceof OrLeaf || leaf instanceof AndLeaf) {
      if (!secondEventLeaf) {
        leaf.addOps([eventLeaf]);
        return leaf;
      }

      if (otherEvents[0]) {
        const rootLeaf = makeLeaf(null, secondEvent.operation.type) as OrLeaf | AndLeaf;

        leaf.addOps([eventLeaf, secondEventLeaf]);

        rootLeaf.addOps([
          leaf,
          createRootLeaf(otherEvents, otherEvents[0].operation.type, rootLeaf)
        ]);

        return rootLeaf;
      }

      leaf.addOps([eventLeaf, secondEventLeaf]);

      return leaf;
    }
  }

  if (rootLeaf instanceof OrLeaf || rootLeaf instanceof AndLeaf) {
    if (!secondEventLeaf) {
      return eventLeaf;
    }

    if (otherEvents[0]) {
      const newLeaf = makeLeaf(null, operationType) as OrLeaf | AndLeaf;

      newLeaf.addOps([
        rootLeaf,
        createRootLeaf(otherEvents, otherEvents[0].operation.type, newLeaf)
      ]);

      return newLeaf;
    }
    const newLeaf = makeLeaf(null, operationType) as OrLeaf | AndLeaf;

    newLeaf.addOps([eventLeaf, secondEventLeaf]);

    return newLeaf;
  }

  return leaf;
}

export function createLeaf(branches: BranchType[]) {
  const rootLeaf = new OrLeaf();

  const branchLeafs = branches.map((branch, index) => {
    const branchLeaf = createRootLeaf(branch.events);

    branchLeaf.addOutput(`output${index + 1}`);
    branchLeaf.addTitle(branch.title || `Trigger group ${index + 1}`);

    return branchLeaf;
  });

  rootLeaf.addOps(branchLeafs);

  return rootLeaf;
}

export function generateLeafKey(): string {
  return URL.createObjectURL(new Blob([])).slice(-36);
}
