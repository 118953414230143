import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgEmailMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 5.25A2.25 2.25 0 003.25 7.5v9a2.25 2.25 0 002.25 2.25h13a2.25 2.25 0 002.25-2.25V7.764 7.5a2.25 2.25 0 00-2.25-2.25h-13zm13.729 2.073a.75.75 0 00-.729-.573h-13a.75.75 0 00-.729.573L12 11.39l7.229-4.066zM4.75 9.033V16.5c0 .414.336.75.75.75h13a.75.75 0 00.75-.75V9.032l-6.882 3.872a.75.75 0 01-.736 0L4.75 9.032z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgEmailMediumLined;
