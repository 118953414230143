import * as React from 'react';

// UI Components
import { Section } from '@src/ui/kit';

// Components
import { FormSelect } from '@src/ui/form';
import { SelectButton } from '@src/ui/form/styled';
import { SegmentBuilder } from '@src/ui/features/SegmentBuilder';

// Hooks
import { useDidMount } from '@src/ui/hooks';

// Types
import {
  TFormEditingPointBooleanSplitterProps,
  IFormEditingPointBooleanSplitterValues
} from './FormEditingPointBooleanSplitter.types';

export function FormEditingPointBooleanSplitter(
  props: TFormEditingPointBooleanSplitterProps
): JSX.Element {
  const {
    applicationCode,
    names,
    values,
    filters,
    isLoadingFilter,
    setFieldValue,
    setFieldTouched,
    onLoadFilter
  } = props;

  const [segmentModalOpen, setSegmentModalOpen] = React.useState(false);

  useDidMount(() => {
    onLoadFilter();
  });

  const selectFilter = React.useCallback(
    (name: string, value: IFormEditingPointBooleanSplitterValues['code'], label: string) => {
      setFieldValue(name, value);
      setFieldValue(names.title, label);
    },
    [names.title, setFieldValue]
  );

  const handleCreateNewSegmentClick = React.useCallback(() => {
    setSegmentModalOpen(true);
  }, [setSegmentModalOpen]);

  return (
    <>
      <Section direction="column">
        <FormSelect
          label="Existing segment"
          isSearchable
          url="https://go.pushwoosh.com/v2/filters"
          name={names.code}
          options={filters}
          placeholder="Select filter"
          value={values.code}
          isLoading={isLoadingFilter}
          onBlur={setFieldTouched}
          onChange={selectFilter}
          rightButton={
            <SelectButton color="secondary" onClick={handleCreateNewSegmentClick}>
              Create Segment
            </SelectButton>
          }
        />

        <SegmentBuilder
          applicationCode={applicationCode}
          open={segmentModalOpen}
          onClose={() => setSegmentModalOpen(false)}
          onSaved={(segment) => {
            onLoadFilter();

            setFieldValue(names.code, segment.code);
            setFieldValue(names.title, segment.name);
            setSegmentModalOpen(false);
          }}
        />
      </Section>
    </>
  );
}
