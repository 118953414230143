import * as React from 'react';

// Components
import { DocElements } from '@src/ui/features/Documentation/components/DocElements';

export function DocumentationPointExit(): JSX.Element {
  return (
    <DocElements>
      <DocElements.Paragraph>
        <strong>Exit </strong>
        {
          'is a point where the journey ends. Once you specified all communications in a single journey, add the'
        }
        <strong> Exit </strong>
        element to complete it.
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-point-exit_gif-1.gif"
        label="Setting Exit from the journey"
        alt="Setting Exit from the journey"
      />
    </DocElements>
  );
}
