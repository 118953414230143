import * as React from 'react';
import { NumberStepper } from '@pushwoosh/kit-number-stepper';

import { decodeDelay, encodeDelay } from '@src/common/helpers';
import { isNaN } from 'lodash';
import { TimeStepperContainer } from './styled';
import { TTimeStepperProps } from './TimeStepper.types';

export function TimeStepper(props: TTimeStepperProps): JSX.Element {
  const {
    name,
    value,
    onChange,
    maxInSeconds,
    minInSeconds,
    maxDayValue = 30,
    disabled = false
  } = props;

  const time = decodeDelay(value);

  const onChangeTime = React.useCallback(
    (stepperName: string, value: number): void => {
      let delay = encodeDelay({
        ...time,
        [stepperName]: isNaN(value) ? 0 : value
      });

      if (delay >= maxInSeconds) {
        delay = maxInSeconds;
      }

      if (delay <= minInSeconds) {
        delay = minInSeconds;
      }

      onChange(name, delay);
    },
    [time, name, maxInSeconds, minInSeconds, onChange]
  );

  return (
    <TimeStepperContainer>
      <NumberStepper
        isDisabled={disabled}
        dimension="day"
        name="dd"
        value={time.dd}
        max={maxDayValue}
        onChange={({ value }) => onChangeTime('dd', value)}
      />

      <NumberStepper
        isDisabled={disabled}
        dimension="hour"
        name="hh"
        value={time.hh}
        max={23}
        onChange={({ value }) => onChangeTime('hh', value)}
      />

      <NumberStepper
        isDisabled={disabled}
        dimension="min"
        name="mm"
        value={time.mm}
        max={59}
        onChange={({ value }) => onChangeTime('mm', value)}
      />
    </TimeStepperContainer>
  );
}
