// Store
import { useStoreActions, useStoreState } from '@src/store/store';

// Types
import { TApplication } from '@src/common/types/application';
import { TRequest } from '@src/store/models/requests/requests.types';

type TActionLoad = (payload?: void) => Promise<TApplication[]>;
type TResult = [TActionLoad, boolean];

export function useLoadApplications(): TResult {
  const load: TActionLoad = useStoreActions((actions): TActionLoad => actions.application.load);

  const request: TRequest = useStoreState(
    (state): TRequest => state.requests.entities['application.load']
  );

  return [load, Boolean(request)];
}
