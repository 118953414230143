import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgColorMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.823 8.288l-.353-.354a.75.75 0 00-1.061 1.06l.354.354-4.243 4.243a1.745 1.745 0 00-.473.865c-.038.178-.06.357-.075.506l-.01.083c-.012.122-.023.223-.039.323-.04.253-.09.324-.11.344l-.283.283a1.35 1.35 0 000 1.91l.566.565a1.35 1.35 0 001.909 0l.283-.283c.02-.02.091-.07.344-.11.1-.016.2-.027.323-.04l.083-.009c.149-.016.328-.037.506-.075.317-.07.62-.227.865-.473l4.243-4.243.353.354a.75.75 0 001.06-1.06l-.353-.354 2.475-2.475a2.75 2.75 0 00-3.889-3.89l-2.475 2.476zm1.768 3.889l-1.768-1.768-4.242 4.243a.246.246 0 00-.068.122c-.02.093-.034.203-.05.35l-.007.068a8.21 8.21 0 01-.051.41c-.052.328-.162.801-.532 1.17a.25.25 0 00.354.355c.37-.37.843-.48 1.171-.532.141-.022.288-.038.41-.05l.069-.008a3.43 3.43 0 00.349-.05.244.244 0 00.122-.068l4.243-4.242z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgColorMediumLined;
