import styled from 'styled-components';
import { Spacing, Color, ShapeRadius } from '@pushwoosh/kit-constants';
import { Paragraph } from '@pushwoosh/kit-typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${Color.DIVIDER};
  border-radius: ${ShapeRadius.CONTROL};
  box-sizing: border-box;
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing.S3} ${Spacing.S5};

  > p {
    display: flex;
    align-items: center;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${Color.DIVIDER};
  }
`;

export const Value = styled(Paragraph)`
  margin: 0 !important;
`;

export const LinkWrap = styled.div`
  margin-left: ${Spacing.S3};
  cursor: pointer;
`;

export const DescriptionWrap = styled.div`
  margin-left: ${Spacing.S3};
  color: ${Color.LOCKED};
`;

export const Title = styled(Paragraph)`
  min-width: 120px;
`;
