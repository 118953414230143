import { thunk, actionOn, computed } from 'easy-peasy';

import { TApplication } from '@src/common/types/application';
import { IApplicationModel, TApplicationByAppCode } from './application.types';

export const model: IApplicationModel = {
  items: [],

  applicationByAppCode: computed(
    (state): TApplicationByAppCode =>
      (appCode): TApplication | undefined =>
        state.items.find((application) => application.code === appCode)
  ),

  load: thunk(async (_, __, helpers): Promise<TApplication[]> => {
    const {
      injections: { applicationService, api }
    } = helpers;

    const response = await applicationService.fetch(api);

    return response.payload;
  }),

  onLoad: actionOn(
    (state): string => state.load.successType,
    (state, target): void => {
      state.items = target.result;
    }
  )
};
