import styled from 'styled-components';

// Components
import { Button } from '@src/ui/kit';

import { Spacing } from '@pushwoosh/kit-constants';

export const FormButtons = styled.div`
  display: flex;
  margin-top: ${Spacing.S5};
`;

export const SaveButton = styled(Button)`
  margin-right: ${Spacing.S3};
`;
