import React from 'react';
import { Shape } from 'react-konva';
import { calculateArrowPoints } from '../Arrow/helpers/calculateArrowPoints';

type Props = {
  fromX: number;
  fromY: number;
  toX: number;
  toY: number;
  stroke: string;
  opacity: number;
};

export function RoundedLine(props: Props): JSX.Element {
  const { fromX, fromY, toX, toY, stroke, opacity } = props;

  return (
    <Shape
      fillEnabled={false}
      stroke={stroke}
      opacity={opacity}
      strokeWidth={2}
      perfectDrawEnabled={false}
      shadowForStrokeEnabled={false}
      sceneFunc={(context, shape) => {
        calculateArrowPoints(fromX, fromY, toX, toY, context, shape);
      }}
    />
  );
}
