import { thunk, action } from 'easy-peasy';
import { IRestrictionsModel } from './restrictions.types';

export const model: IRestrictionsModel = {
  entities: {
    allowCjWebhook: false,
    allowCjInapp: false,
    allowCjScheduledSegment: false,
    allowCjContentPersonalization: false,
    allowCjSetTags: false,
    allowCjDynamicDelay: false,
    allowCjSilentHours: false,
    allowCjFrequencyCapping: false,
    allowCjSMS: false,
    allowCjApiEntry: false,
    allowCjDynamicSetTagsInUpdateProfile: false,
    allowCjAddBranchesInWaitForTrigger: false
  },

  setRestrictions: action((state, payload): void => {
    state.entities = payload;
  }),

  load: thunk(async (actions, _, { injections }): Promise<void> => {
    const { restrictionsService, api } = injections;

    const { payload } = await restrictionsService.fetch(api);

    actions.setRestrictions(payload);
  })
};
