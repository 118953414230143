import styled from 'styled-components';
import { Footnote, H4 as H4Component } from '@pushwoosh/kit-typography';
import { Color, Spacing, UnitSize } from '@pushwoosh/kit-constants';

export const FormSelectWrap = styled.div`
  position: relative;
  height: fit-content;
  font-size: 14px;
  margin-bottom: 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${Spacing.S1};
`;

interface H4Props {
  disabled: boolean;
}

export const H4 = styled(H4Component)`
  color: ${(props: H4Props) => (props.disabled ? Color.LOCKED : Color.MAIN)};
`;

export const ErrorMessage = styled(Footnote)`
  color: ${Color.DANGER};
  margin-top: ${Spacing.S1};
  bottom: 0;
`;

export const ExternalLink = styled.a`
  width: ${UnitSize.ICON_SMALL};
  height: ${UnitSize.ICON_SMALL};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${Spacing.S1};
  fill: ${Color.SOFT};

  &:hover {
    fill: ${Color.PRIMARY_HOVER};
  }
`;

export const ActionWrap = styled.div`
  margin-left: auto;
`;
