import * as React from 'react';
import uniq from 'lodash/uniq';

// Hooks
import { useDidMount } from '@src/ui/hooks';

// UI Components
import { Section } from '@src/ui/kit/Section';
import { Paragraph } from '@pushwoosh/kit-typography';
import { MessageRateLimits } from '@src/ui/features/ApiEntry/components/MessageRateLimits';
import { PlaceholdersEdit } from '@src/ui/features/ApiEntry/components/PlaceholdersEdit';
import { Icon, IconEnum, Message } from '@src/ui/kit';
import { StyledPremiumIconWrapper } from '@src/ui/features/StartSegment/components/ScheduledLaunch/styled';
import { Color } from '@pushwoosh/kit-constants';
import {
  CopyButton,
  Example,
  Link,
  StyledInput,
  StyledTextarea,
  SubHeader
} from '@src/ui/features/ApiEntry/styled';

// Types
import { useUser } from '@src/store/hooks';
import { placeholderValid, renderTemplate } from '@src/ui/features/ApiEntry/helepers/placeholders';
import { EditApiEntryProps } from './EditApiEntry.types';

const sanitizeAttributes = (attributes: string[]): string[] =>
  uniq(attributes.map((s) => s.trim()).filter((s) => s.length > 0)).filter(placeholderValid);

export function EditApiEntry(props: EditApiEntryProps): JSX.Element {
  const { applicationCode, names, values, journeyId, filters, setFieldValue, onLoadFilter } = props;

  const { accountId } = useUser();

  const [placeholders, setPlaceholders] = React.useState(values.attributes);

  useDidMount(() => {
    onLoadFilter();
  });

  const handleChangeName = React.useCallback(
    (name: string, value: string) => {
      setFieldValue(name, value);
    },
    [setFieldValue]
  );

  const handleAddPlaceholderClick = React.useCallback(() => {
    const newValue = [...placeholders, ''];

    setPlaceholders(newValue);
    setFieldValue('attributes', sanitizeAttributes(newValue));
  }, [placeholders, setFieldValue, setPlaceholders]);

  const handleRemovePlaceholderClick = React.useCallback(
    (i: number) => {
      const newValue = placeholders.filter((_, index) => index !== i);

      setPlaceholders(newValue);
      setFieldValue('attributes', sanitizeAttributes(newValue));
    },
    [placeholders, setFieldValue, setPlaceholders]
  );

  const handleUpdatePlaceholder = React.useCallback(
    (i: number, value: string) => {
      const newValue = placeholders.map((placeholder, index) =>
        index === i ? value : placeholder
      );

      setPlaceholders(newValue);
      setFieldValue('attributes', sanitizeAttributes(newValue));
    },
    [placeholders, setFieldValue, setPlaceholders]
  );

  // const handleRemovePlaceholderClick = React.useCallback(

  const template = React.useMemo(
    () => renderTemplate(journeyId, applicationCode, placeholders),
    [journeyId, filters, placeholders, applicationCode]
  );

  const handleCopyClick = React.useCallback(async () => {
    await navigator.clipboard.writeText(template);
  }, [template]);

  // const isDisabled = !applicationCode || journeyStatus === JourneyStatus.RUNNING;

  return (
    <div>
      <Section direction="column">
        <Paragraph>
          To launch the journey, send an API request created using the template below. The journey
          can be re-launched via a new request only once per minute.
        </Paragraph>
        <StyledInput
          label="Point Name"
          name={names.title}
          value={values.title}
          onChange={handleChangeName}
        />
      </Section>

      <Section direction="column">
        <SubHeader>Overwrite text in Push or Email elements</SubHeader>
        <Paragraph>
          You can modify messages text in a request. Add the placeholder name here and specify its
          value in the request. After that, go to the message element settings, turn on Overwrite
          personalization and select Webhook-based entry as the source.
        </Paragraph>

        <PlaceholdersEdit
          placeholders={placeholders}
          addNewPlaceholder={handleAddPlaceholderClick}
          removePlaceholder={handleRemovePlaceholderClick}
          updatePlaceholder={handleUpdatePlaceholder}
        />

        {placeholders.filter(Boolean).length > 0 && (
          <Message
            icon={
              <StyledPremiumIconWrapper>
                <Icon
                  fill={Color.BRIGHT}
                  type={IconEnum.INFO_ROUND_MEDIUM_FILLED}
                  width={20}
                  height={20}
                />
              </StyledPremiumIconWrapper>
            }
            color={Color.BRIGHT_LIGHT}
            fullWidth
            title="Don’t forget to update personalization settings in journey elements."
          />
        )}
      </Section>

      <Section direction="column">
        <SubHeader>Use request template</SubHeader>
        <Paragraph>
          To launch the journey, you have to specify who will enter it. In the request, add
          conditions to the “filter” parameter (use{' '}
          <Link
            to="https://docs.pushwoosh.com/platform-docs/api-reference/filters/segmentation-language/"
            isExternal
          >
            Segmentation language
          </Link>
          ) and add personalization content instead of “value” to the placeholder parameters.
        </Paragraph>

        <Example>
          <StyledTextarea name={'requestTemplate'} readonly value={template} />
          <CopyButton
            color="secondary"
            size="compact"
            view="shape"
            iconPosition="left"
            iconType={IconEnum.COPY_MEDIUM_LINED}
            onClick={handleCopyClick}
          />
        </Example>
      </Section>

      <Section direction="column">
        <MessageRateLimits
          names={names}
          values={values}
          setFieldValue={setFieldValue}
          min={accountId === 105486 ? 1 : 100}
        />
      </Section>
    </div>
  );
}
