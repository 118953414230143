// Constants
import { JourneyStatus } from '@src/common/constants/journey';

// Types
import { Pagination } from '@src/common/types/pagination';
import { TApplication } from '@src/common/types/application';

export enum ColumnConstant {
  TITLE = 'title',
  STATUS = 'status',
  DATA_MODIFIED = 'dataModified',
  CATEGORY = 'category',
  TOTAL_ENTRIES = 'totalEntires',
  GOAL_REACHED = 'goalReached',
  ACTION = 'action',
  AVR_CTR = 'avrCTR'
}

export enum SortType {
  ASC = 'asc',
  DESC = 'desc'
}

export interface Column {
  name: string;
  key: ColumnConstant;
}

export interface CellType {
  id: string;
  application?: TApplication;
  [ColumnConstant.TITLE]: string;
  [ColumnConstant.STATUS]: JourneyStatus;
  [ColumnConstant.DATA_MODIFIED]: string;
  [ColumnConstant.CATEGORY]: string;
  [ColumnConstant.TOTAL_ENTRIES]: number;
  [ColumnConstant.AVR_CTR]: number;
  [ColumnConstant.GOAL_REACHED]: number;
}

interface StateTable {
  sortColumn: ColumnConstant;
  sortType: SortType;
  page: Pagination['page'];
  showArchive: boolean;
  perPage: Pagination['perPage'];
  filterTitle: string;
  filterStatus: JourneyStatus[];
}

export type StateTablePartial = Partial<StateTable>;
export interface TableProps {
  cells: CellType[];
  pagination: Pagination;
  isLoadingJourneys: boolean;
  isLoadingStats: boolean;
  onChange: (state: StateTablePartial) => void;
  onReloadJourneys: () => void;
}
