import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgReachabilityCheckPoint(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.232 15.495a7.773 7.773 0 0115.535 0L28 22c0 1.448 1.571 2.895 2.44 3.584.341.272.56.678.559 1.115A1.303 1.303 0 0129.696 28H10.304a1.303 1.303 0 01-1.303-1.301c0-.437.218-.843.56-1.115C10.427 24.894 12 23.447 12 22l.232-6.505zM17.8 30c-.442 0-.811.365-.695.79a3.001 3.001 0 005.79 0c.116-.425-.253-.79-.695-.79h-4.4zm2.26-7.44l5.435-5.434a.8.8 0 000-1.131l-.99-.99a.8.8 0 00-1.131 0l-3.808 3.808a.8.8 0 01-1.132 0l-1.308-1.308a.8.8 0 00-1.131 0l-.99.99a.8.8 0 000 1.131l2.934 2.935.495.495a.8.8 0 001.132 0l.495-.495z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgReachabilityCheckPoint;
