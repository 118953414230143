import { format } from 'date-fns';

// Types
import { TSegmentPoint } from '@src/common/types/points';

// Helpers
import { mapWeekDay } from './mapWeekDay';

type Repeat = {
  enabledRepeat: TSegmentPoint['data']['repeat']['enabled'];
  frequencyRepeat: TSegmentPoint['data']['repeat']['frequency'];
  everyRepeat: TSegmentPoint['data']['repeat']['every'];
  datesRepeat?: TSegmentPoint['data']['repeat']['dates'];
};

type Result = {
  label: string;
  union?: string;
  value?: string[];
};
export const defineRepeatValue = (repeat: Repeat): Result => {
  const { enabledRepeat, frequencyRepeat, everyRepeat, datesRepeat = [] } = repeat;

  if (!enabledRepeat) {
    return {
      label: 'repeat',
      value: []
    };
  }

  if (frequencyRepeat === 'daily') {
    return {
      label: everyRepeat === 1 ? 'Every Day' : `Every ${everyRepeat} Days`,
      value: []
    };
  }

  if (frequencyRepeat === 'weekly') {
    return {
      label: everyRepeat === 1 ? 'Every Week' : `Every ${everyRepeat} Weeks`,
      union: 'at',
      value: (datesRepeat as string[]).map((item) => mapWeekDay(item))
    };
  }

  if (frequencyRepeat === 'monthly') {
    return {
      label: everyRepeat === 1 ? 'Every Month' : `Every ${everyRepeat} Month`,
      union: 'at Days',
      value: (datesRepeat as string[]).map((item) => `${item}th`)
    };
  }

  if (frequencyRepeat === 'dates') {
    return {
      label: 'Dates Specified',
      value: (datesRepeat as string[]).map((item) => format(new Date(item), 'dd-MM-yyyy'))
    };
  }

  return {
    label: 'unknown',
    value: []
  };
};
