import * as React from 'react';
import { IconEnum, Icon } from '@src/ui/kit';

// Styled
import { Container, Target, Title, Children, StyledIcon } from './styled';

// Types
import { CollapseProps } from './Collapse.types';

export const Collapse = (props: CollapseProps): JSX.Element => {
  const { opened = false, title, children } = props;
  const [show, setShow] = React.useState(opened);

  const handleClick = React.useCallback(() => {
    setShow(!show);
  }, [show]);

  return (
    <Container>
      <Target onClick={handleClick}>
        <Title>{title}</Title>
        <StyledIcon isRotate={show}>
          <Icon type={IconEnum.CHEVRO_RIGHT_MEDIUM} />
        </StyledIcon>
      </Target>
      {show ? <Children>{children}</Children> : null}
    </Container>
  );
};
