import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgFlashSmallFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.046 2.951c.086-.558-.674-.808-.937-.309L4.963 8.621a.6.6 0 00.53.879h1.783a.2.2 0 01.198.223l-.399 3.458c-.064.555.694.781.944.28l3.047-6.093a.6.6 0 00-.537-.868H8.733a.2.2 0 01-.198-.23l.511-3.319z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgFlashSmallFilled;
