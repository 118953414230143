import * as React from 'react';

// Analytics
import { sendMixpanelEvent } from '@src/common/analytics';

// Store
import { useStoreActions, useStoreState } from '@src/store/store';

// Hooks
import { useCurrentJourneyId, useGetJourneyById, useSetMode } from '@src/store/hooks';

// Enums
import { JourneyStatus, Modes } from '@src/common/constants';

// Types
import { IUniqueId } from '@src/common/types/entities';
import { useConfirmService } from '@src/ui/features/Modals/Confirm/hooks/useConfirmService';

type TDeletePoint = (pointId: IUniqueId['id']) => void;
type TResult = TDeletePoint;

export function useDeletePoint(): TResult {
  const setMode = useSetMode();
  const confirm = useConfirmService();
  const currentJourneyId = useCurrentJourneyId();
  const getJourneyById = useGetJourneyById();
  const journey = getJourneyById(currentJourneyId);

  const getPointById = useStoreState((state) => state.points.pointById);

  const deletePoint: TDeletePoint = useStoreActions<TDeletePoint>(
    (actions): TDeletePoint => actions.points.delete
  );

  const handleConfirmPointDelete = React.useCallback(
    (pointId) =>
      confirm({
        description: 'All statistics for this point will be lost.',
        onAfterConfirm(): void {
          deletePoint(pointId);
        },
        title: 'Delete the point',
        confirmLabel: 'Delete',
        level: 'danger'
      }),
    [confirm, deletePoint]
  );

  const handlePointDelete: TDeletePoint = React.useCallback<TDeletePoint>(
    (pointId): void => {
      const point = getPointById(pointId);

      sendMixpanelEvent({
        eventName: 'PointRemove',
        eventData: {
          PointType: point.type
        }
      });

      deletePoint(pointId);
      setMode(Modes.CHANGED);
    },
    [deletePoint, setMode, getPointById]
  );

  return journey?.status === JourneyStatus.PAUSED ? handleConfirmPointDelete : handlePointDelete;
}
