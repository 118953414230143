import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgNotificationEnabledMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.288 5.288A5.25 5.25 0 0117.25 9c0 2.514.538 4.076 1.033 4.985a4.457 4.457 0 00.747 1.023l.081.084a.75.75 0 01.388.69.65.65 0 01-.113.366.75.75 0 01-.636.352H5.25a.747.747 0 01-.637-.354c-.113-.179-.138-.336-.108-.48a.75.75 0 01.379-.57l.058-.06.115-.118c.168-.178.41-.477.66-.933.495-.909 1.033-2.47 1.033-4.985a5.25 5.25 0 011.538-3.712zm8.679 9.415c.057.104.114.204.171.297H6.862a7.09 7.09 0 00.171-.297C7.663 13.549 8.25 11.736 8.25 9a3.75 3.75 0 017.5 0c0 2.736.587 4.549 1.217 5.703z"
        fill={props.fill}
      />
      <path
        d="M11.351 18.374a.75.75 0 00-1.297.752 2.25 2.25 0 003.892 0 .75.75 0 00-1.297-.752.75.75 0 01-1.298 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgNotificationEnabledMediumLined;
