// Store
import { useStoreActions, useStoreState } from '@src/store/store';

// Types
import { TRequest } from '@src/store/models/requests/requests.types';
import { TEvent } from '@src/common/types/event';

type TActionLoad = (payload: string) => Promise<{ code: string; events: TEvent[] }>;
type TResult = [TActionLoad, boolean];

export function useLoadEvents(): TResult {
  const load: TActionLoad = useStoreActions((actions): TActionLoad => actions.event.loadByAppCode);

  const request: TRequest = useStoreState(
    (state): TRequest => state.requests.entities['event.loadByAppCode']
  );

  return [load, Boolean(request)];
}
