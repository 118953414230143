import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgFolderSmallFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 11.615c0 .368-.158.72-.44.98-.28.26-.662.405-1.06.405h-11c-.398 0-.78-.146-1.06-.405a1.333 1.333 0 01-.44-.98v-8.23c0-.368.158-.72.44-.98C1.72 2.146 2.101 2 2.5 2h4.75l1.5 1.385h4.75c.398 0 .78.145 1.06.405.282.26.44.612.44.98v6.845z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgFolderSmallFilled;
