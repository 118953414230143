import { RepeatType, RepeatFrequency } from '@src/common/constants/repeatSegment';
import { RepeatSegment } from '@src/common/types/repeatSegment';

type RepeatOptions = {
  repeatFrequency: RepeatSegment['frequency'];
  repeatEvery?: RepeatSegment['every'];
  repeatDates?: RepeatSegment['dates'];
  repeatType: RepeatSegment['type'];
};

export const getOptionsByType = (repeatType: RepeatType): RepeatOptions => {
  switch (repeatType) {
    case RepeatType.CUSTOM: {
      return {
        repeatFrequency: RepeatFrequency.DAILY,
        repeatEvery: 1,
        repeatDates: undefined,
        repeatType
      };
    }

    case RepeatType.DATES_SPECIFIED: {
      return {
        repeatFrequency: RepeatFrequency.DATES,
        repeatEvery: undefined,
        repeatDates: [''],
        repeatType
      };
    }

    case RepeatType.EVERY_DAY: {
      return {
        repeatFrequency: RepeatFrequency.DAILY,
        repeatEvery: 1,
        repeatDates: undefined,
        repeatType
      };
    }

    case RepeatType.EVERY_MONTH: {
      return {
        repeatFrequency: RepeatFrequency.MONTHLY,
        repeatEvery: 1,
        repeatDates: undefined,
        repeatType
      };
    }

    case RepeatType.EVERY_WEEK: {
      return {
        repeatFrequency: RepeatFrequency.WEEKLY,
        repeatEvery: 1,
        repeatDates: undefined,
        repeatType
      };
    }

    default: {
      return {
        repeatFrequency: RepeatFrequency.DAILY,
        repeatEvery: 1,
        repeatType: RepeatType.EVERY_DAY,
        repeatDates: undefined
      };
    }
  }
};
