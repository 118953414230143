import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgEmailSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 7c0-1.886 0-2.828.586-3.414C2.172 3 3.114 3 5 3h6c1.886 0 2.828 0 3.414.586C15 4.172 15 5.114 15 7v2.5c0 1.886 0 2.828-.586 3.414-.586.586-1.528.586-3.414.586H5c-1.886 0-2.828 0-3.414-.586C1 12.328 1 11.386 1 9.5V7zm4-2.5h6c.985 0 1.573.003 1.995.06a1.413 1.413 0 01.354.084l.005.002.002.005a1.247 1.247 0 01.075.294L8 7.662 2.568 4.946a1.25 1.25 0 01.076-.295l.002-.005.005-.002a1.412 1.412 0 01.354-.084C3.427 4.503 4.015 4.5 5 4.5zm-2.354.146c.001 0 .001 0 0 0 0 .001 0 .001 0 0zm0 0zm0 0zM2.5 6.59V9.5c0 .985.003 1.573.06 1.995a1.413 1.413 0 00.084.354l.002.005.005.002a1.414 1.414 0 00.354.084c.422.057 1.01.06 1.995.06h6c.985 0 1.573-.003 1.995-.06a1.415 1.415 0 00.354-.084l.005-.002.002-.005a1.415 1.415 0 00.084-.354c.057-.422.06-1.01.06-1.995V7v-.411L8.335 9.17a.75.75 0 01-.67 0L2.5 6.589z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgEmailSmallLined;
