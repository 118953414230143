import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgKebabMedium(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.5 6A1.75 1.75 0 1110 6a1.75 1.75 0 013.5 0zM13.5 12a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0zM11.75 19.75a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgKebabMedium;
