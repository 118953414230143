// Api
import { GetOptions, HttpClient } from '@pushwoosh/http-client';

// Helpers
import { getApiOrigin } from '@src/store/helpers';

// Types
import { TFetchByAppCodeResponse, TFetchPlaceholdersResponse } from './types';

export const service = {
  async fetchByAppCode(applicationCode: string, api: HttpClient): Promise<TFetchByAppCodeResponse> {
    const options: GetOptions<TFetchByAppCodeResponse, { code: string }, never> = {
      params: {
        code: applicationCode
      },
      withAuthorization: true
    };

    return api.get<TFetchByAppCodeResponse, { code: string }, never>(
      `${getApiOrigin()}/api/application/:code/email-preset`,
      options
    );
  },

  async fetchPlaceholders(
    templateCode: string,
    api: HttpClient
  ): Promise<TFetchPlaceholdersResponse> {
    const options: GetOptions<TFetchPlaceholdersResponse, { code: string }, never> = {
      params: {
        code: templateCode
      },
      withAuthorization: true
    };

    return api.get<TFetchPlaceholdersResponse, { code: string }, never>(
      `${getApiOrigin()}/api/placeholders/:code`,
      options
    );
  }
};
