import * as React from 'react';

// Analytics
import { sendMixpanelEvent } from '@src/common/analytics';

// UI Components
import { Section } from '@src/ui/kit';
import { Modal } from '@src/ui/kit/Modal';
import { DefaultButtons } from '@src/ui/kit/ModalButtons';
import {
  EditFrequencyCapping,
  IEditFrequencyCappingValues
} from '@src/ui/features/FrequencyCapping/components/EditFrequencyCapping';

// Helpers
import { defineFrequencyCapping } from '@src/common/helpers/defineFrequencyCapping';

// Store Hooks
import {
  useCloseAllModals,
  useGetJourneyById,
  useUpdateJourneyById,
  useCurrentJourneyId
} from '@src/store/hooks';

// UI Hooks
import { useForm } from '@src/ui/hooks';

// Enums
import { JourneyStatus } from '@src/common/constants/journey';

const descriptionIsEdit =
  'Define whether or not a person should enter this journey multiple times. Frequency capping will allow you to control the amount of times people should enter this journey.';

export function ModalEditFrequencyCapping(): JSX.Element {
  const closeAllModals = useCloseAllModals();

  const journeyId = useCurrentJourneyId();
  const getJourneyById = useGetJourneyById();
  const [updateJourneysById] = useUpdateJourneyById();
  const journey = getJourneyById(journeyId);

  const { isEnabled, period } = defineFrequencyCapping(journey);

  const handleFrequencyCappingSave = React.useCallback(
    async (formValues: IEditFrequencyCappingValues) => {
      sendMixpanelEvent({
        eventName: 'SetCapping',
        eventData: {
          IsEnabled: formValues.isEnabled
        }
      });

      updateJourneysById(
        journeyId,
        {
          params: {
            frequencyCapping: {
              isEnabled: formValues.isEnabled,
              period: formValues.period
            }
          }
        },
        'frequencyCapping'
      );

      closeAllModals();
    },
    [closeAllModals, journeyId, updateJourneysById]
  );

  const { values, setFieldValue, handleSubmit } = useForm<IEditFrequencyCappingValues>({
    initialValues: {
      isEnabled,
      period
    },
    onSubmit: handleFrequencyCappingSave
  });

  return (
    <Modal
      title="Frequency Capping"
      footerRight={
        <DefaultButtons
          onClickCancelButton={closeAllModals}
          actionButtonName={journey.status === JourneyStatus.RUNNING ? 'Save' : 'Apply'}
          onClickActionButton={handleSubmit}
        />
      }
      isOpen
    >
      <Section>{descriptionIsEdit}</Section>
      <EditFrequencyCapping
        names={{
          isEnabled: 'isEnabled',
          period: 'period'
        }}
        values={values}
        setFieldValue={setFieldValue}
      />
    </Modal>
  );
}
