import { useCallback } from 'react';

// Store
import { useStoreActions } from '@src/store/store';

import { useCheckOverlapPoints } from './useCheckOverlapPoints';

type TClonePointsFromBuffer = () => void;

export function useClonePointsFromBuffer(): TClonePointsFromBuffer {
  const clonePointsFromBuffer = useStoreActions(
    (actions): typeof actions.points.clonePointsFromBuffer => actions.points.clonePointsFromBuffer
  );
  const checkOverlapPoints = useCheckOverlapPoints();

  return useCallback((): void => {
    clonePointsFromBuffer();
    checkOverlapPoints();
  }, [clonePointsFromBuffer, checkOverlapPoints]);
}
