import React from 'react';
import { Paragraph } from '@pushwoosh/kit-typography';
import { Color } from '@pushwoosh/kit-constants';
import { Container, Value } from './styled';

type Props = {
  label: string | JSX.Element;
  value: unknown;
  valueColor?: Color;
  bgColor?: Color;
  onClick?: () => void;
};

export function StatisticCell(props: Props): JSX.Element {
  const { label, value, valueColor, bgColor, onClick } = props;

  return (
    <Container bgColor={bgColor} onClick={onClick}>
      <Paragraph>{label}</Paragraph>
      <Value color={valueColor}>{value}</Value>
    </Container>
  );
}
