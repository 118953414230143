import * as React from 'react';

// Store
import { useStoreActions, useStoreState } from '@src/store/store';

// Hooks
import { useAddNotifications } from '@src/store/hooks';

// Enums
import { NotificationType, NOTIFICATION_TITLE_SUCCESS } from '@src/common/constants/notification';

// Types
import { TRequest } from '@src/store/models/requests/requests.types';
import { IUniqueId } from '@src/common/types/entities';

type TDeleteJourneyById = (journeyId: IUniqueId['id']) => Promise<void>;
type TResult = [TDeleteJourneyById, boolean];

export function useDeleteJourneyById(): TResult {
  const addNotifications = useAddNotifications();

  const deleteJourneyById: TDeleteJourneyById = useStoreActions<TDeleteJourneyById>(
    (actions): TDeleteJourneyById => actions.journeys.deleteById
  );

  const request: TRequest = useStoreState<TRequest>(
    (state): TRequest => state.requests.entities['journeys.deleteById']
  );

  const handleJourneyDeleteById = React.useCallback<TDeleteJourneyById>(
    async (journeyId): Promise<void> => {
      await deleteJourneyById(journeyId);

      addNotifications([
        {
          title: NOTIFICATION_TITLE_SUCCESS.deleteJourney,
          type: NotificationType.SUCCESS
        }
      ]);
    },
    [deleteJourneyById, addNotifications]
  );

  return [handleJourneyDeleteById, Boolean(request)];
}
