import * as React from 'react';
import { useCurrentJourneyId } from '@src/store/hooks';
import { IUniqueId } from '@src/common/types/entities';
import { TJourney } from '@src/common/types/journey';
import { useStoreActions, useStoreState } from '@src/store/store';
import { TRequest } from '@src/store/models/requests/requests.types';

type TUpdateABSplitterData = {
  pointId: IUniqueId['id'];
  split: number[];
};

type TUpdateById = (updateData: TUpdateABSplitterData) => Promise<TJourney>;

type TResult = [TUpdateById, boolean];

export function useUpdateABSplitterByJourneyId(): TResult {
  const journeyId = useCurrentJourneyId();
  const updateABSplitterByJourneyId = useStoreActions(
    (actions) => actions.journeys.updateABSplitterByJourneyId
  );

  const editPoint = useStoreActions((actions) => actions.points.edit);

  const request: TRequest = useStoreState<TRequest>(
    (state): TRequest => state.requests.entities['journeys.updateABSplitterByJourneyId']
  );

  const handleUpdateABSplitterByJourneyId: TUpdateById = React.useCallback<TUpdateById>(
    async (updateData): Promise<TJourney> => {
      const { pointId, split } = updateData;
      const journey: TJourney = await updateABSplitterByJourneyId({
        id: journeyId,
        pointId,
        split
      });

      const { points } = journey;

      const newPoint = points.find((point) => point.id === pointId);

      editPoint(newPoint);

      return journey;
    },
    [journeyId, editPoint, updateABSplitterByJourneyId]
  );

  return [handleUpdateABSplitterByJourneyId, Boolean(request)];
}
