import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgHelpRoundMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12c0-4.974 4.025-9 9-9 4.974 0 9 4.025 9 9 0 4.974-4.025 9-9 9-4.974 0-9-4.025-9-9zm9.223-3.562a1.75 1.75 0 00-1.947 1.144l-.166.471a.5.5 0 01-.638.306l-.943-.332a.5.5 0 01-.306-.637l.166-.472a3.75 3.75 0 017.288 1.25c0 1.447-1.073 2.395-1.82 2.893a7.052 7.052 0 01-.647.381.52.52 0 00-.283.458v.35a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1.333a1 1 0 01.682-.949l.014-.004a5.204 5.204 0 00.328-.133c.223-.1.513-.245.796-.434.627-.418.93-.845.93-1.23v-.002a1.75 1.75 0 00-1.454-1.727zM13.25 17a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgHelpRoundMediumFilled;
