import { RepeatFrequency } from '@src/common/constants/repeatSegment';

export const makeTextRepeatFrequency = (repeatFrequency: RepeatFrequency): string => {
  switch (repeatFrequency) {
    case RepeatFrequency.DAILY: {
      return 'Day';
    }

    case RepeatFrequency.MONTHLY: {
      return 'Month';
    }

    case RepeatFrequency.WEEKLY: {
      return 'Week';
    }

    default: {
      return 'Day';
    }
  }
};
