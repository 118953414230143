import React from 'react';
import { LimitIcon } from '@pushwoosh/kit-icons';
import {
  StyledButton,
  StyledPremiumIcon
} from '@src/ui/features/Settings/components/SettingsPanel/styled';
import { IconEnum, Link } from '@src/ui/kit';
import { Popover } from '@src/ui/kit/Popover';
import { SettingsButton } from '@src/ui/features/Settings/components/SetttingsButton';
import { IJourney } from '@src/common/types/journey';
import { useCurrentJourneyId, useOpenNewModal, useRestrictions } from '@src/store/hooks';
import { ModalType } from '@src/common/constants';
import { OPTIONS_FREQUENCY_CAPPING } from '@src/common/constants/frequencyCapping';

interface IFrequencyCappingButtonProps {
  onLinkClick: (event: React.MouseEvent) => void;
  isVisibleSidebar: boolean;
  isReadMode: boolean;
  journey: IJourney;
  isPopoverVisible: boolean;
  onSetPopoverType: (popoverType: string) => void;
}

export function FrequencyCappingButton(props: IFrequencyCappingButtonProps) {
  const { isPopoverVisible, onLinkClick, isVisibleSidebar, isReadMode, journey, onSetPopoverType } =
    props;
  const openNewModal = useOpenNewModal();
  const { allowCjFrequencyCapping } = useRestrictions();
  const journeyId = useCurrentJourneyId();

  const [isHovered, setIsHovered] = React.useState(false);
  const [popoverXCoord, setPopoverCoord] = React.useState(null);

  const frequencyCapping = journey?.params?.frequencyCapping;
  const frequencyCappingText = OPTIONS_FREQUENCY_CAPPING.find(
    (item) => item.value === frequencyCapping?.period
  )?.label;
  const isHiddenFrequencyCapping = !isReadMode && !frequencyCapping?.isEnabled;

  const setFrequencyCappingHandle = React.useCallback((): void => {
    openNewModal({
      type: ModalType.FREQUENCY_CAPPING,
      data: { journeyId }
    });
  }, [openNewModal, journeyId]);

  const setDetailFrequencyCappingHandle = React.useCallback((): void => {
    openNewModal({
      type: ModalType.DETAIL_FREQUENCY_CAPPING,
      data: { journeyId }
    });
  }, [openNewModal, journeyId]);

  const handleCappingClick = (event: React.MouseEvent) => {
    if (allowCjFrequencyCapping) {
      if (isReadMode) {
        setFrequencyCappingHandle();
      } else {
        setDetailFrequencyCappingHandle();
      }
    } else {
      const { y } = event.currentTarget.getBoundingClientRect();
      setPopoverCoord(y);
      onSetPopoverType('capping');
    }
  };

  const popoverX = popoverXCoord - 50;
  const popoverY = 60;

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsHovered(false);
    onSetPopoverType(null);
  };

  return (
    !isHiddenFrequencyCapping && (
      <SettingsButton
        onMouseLeave={() => setIsHovered(false)}
        onMouseEnter={() => setIsHovered(true)}
        onClick={handleCappingClick}
        icon={<LimitIcon size="medium" view="filled" />}
        isCompact={isVisibleSidebar}
      >
        <>
          {!allowCjFrequencyCapping && (
            <StyledPremiumIcon
              type={isHovered ? IconEnum.PREMIUM_ENABLED : IconEnum.PREMIUM_DISABLED}
            />
          )}
          {frequencyCapping?.isEnabled ? `Once in ${frequencyCappingText}` : 'Frequency Capping'}

          {!allowCjFrequencyCapping && isPopoverVisible && (
            <Popover
              title="Frequency Capping"
              description="Save your user from receiving same message twice. Upgrade to Marketing plan to enhance your communications."
              x={popoverX}
              y={popoverY}
              onClose={handleClose}
            >
              <Link
                onClick={onLinkClick}
                to="https://app.pushwoosh.com/products"
                isExternal
                target="_blank"
              >
                <StyledButton>Upgrade now</StyledButton>
              </Link>
            </Popover>
          )}
        </>
      </SettingsButton>
    )
  );
}
