// Store
import { useStoreActions, useStoreState } from '@src/store/store';

// Types
import { IPopupMenuParams, IVector2 } from '@src/common/types/types';
import { IUniqueId } from '@src/common/types/entities';

export interface IResult {
  isVisible: boolean;
  menuPosition: IVector2;
  mousePosition: IVector2;
  fromPointId: IUniqueId['id'];
  pointOutputKey: string;
  pointOutputOrder: number;
  open: (connect: IPopupMenuParams) => void;
  close: (payload?: void) => void;
}

export function usePopupMenuStore(): IResult {
  const isVisible = useStoreState((state): boolean => state.popupMenu.isVisible);

  const menuPosition = useStoreState((state): IVector2 => state.popupMenu.menuPosition);

  const mousePosition = useStoreState((state): IVector2 => state.popupMenu.canvasMousePosition);

  const fromPointId = useStoreState((state) => state.popupMenu.fromPointId);

  const pointOutputKey = useStoreState((state): string => state.popupMenu.pointOutputKey);

  const pointOutputOrder = useStoreState((state): number => state.popupMenu.pointOutputOrder);

  const open = useStoreActions(
    (actions): ((connect: IPopupMenuParams) => void) => actions.popupMenu.showMenu
  );

  const close = useStoreActions(
    (actions): ((payload?: void) => void) => actions.popupMenu.closeMenu
  );

  return {
    isVisible,
    menuPosition,
    mousePosition,
    fromPointId,
    pointOutputKey,
    pointOutputOrder,
    open,
    close
  };
}
