import styled from 'styled-components';
import { Spacing, Color, Shadow } from '@pushwoosh/kit-constants';

export const ControlsPanelContainer = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const GettingStartedContainer = styled.div`
  position: relative;
  z-index: 4;
  display: flex;
  background: ${Color.CLEAR};
  margin-bottom: ${Spacing.S3};
  box-shadow: ${Shadow.REGULAR};
  border-radius: 8px;
`;

export const NotOpened = styled.div`
  position: absolute;
  right: 3px;
  top: 3px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${Color.WARNING};
`;

export const Countdown = styled.div`
  width: 16px;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 10px;
  left: -24px;
  opacity: 0.7;
  color: ${({ color }) => color};
`;
