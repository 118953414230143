import * as React from 'react';
import { Group, Rect } from 'react-konva';

// Constants
import { GroupSizeContent, DEFAULT_POINT_BLOCK_OFFSET } from '@src/common/constants/point';

// Helpers
import { getSizePoint } from '@src/common/helpers';

// Types
import { IProps } from './PointContent.types';

export function PointContent(props: IProps): JSX.Element {
  const { type, children, fill = '#ffffff', order } = props;

  const { widthPoint, heightPoint } = getSizePoint(type);

  const doubleOffset = DEFAULT_POINT_BLOCK_OFFSET * 2;

  let y = DEFAULT_POINT_BLOCK_OFFSET;
  if (order && order !== 1) {
    const amountOffset = order * DEFAULT_POINT_BLOCK_OFFSET;
    const amountHeight = (order - 1) * GroupSizeContent.HEIGHT;
    y = amountOffset + amountHeight;
  }

  const heightContentBlock = order ? GroupSizeContent.HEIGHT : heightPoint - doubleOffset;
  const widthContentBlock = order
    ? GroupSizeContent.WIDTH - doubleOffset
    : widthPoint - doubleOffset;

  return (
    <Group x={DEFAULT_POINT_BLOCK_OFFSET} y={y} perfectDrawEnabled={false}>
      <Rect
        x={0}
        y={0}
        fill={fill}
        height={heightContentBlock}
        width={widthContentBlock}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
      />
      {children}
    </Group>
  );
}
