import React, { ReactNode, useMemo } from 'react';
import singleSpa from 'single-spa';
import { useSession } from '@src/common/contexts/session';
import { useGrpcBridge, useGrpcClient } from '@src/common/contexts/http-client';
import { type GrpcBridge, type SegmentData } from '@pushwoosh/grpc-bridge';
import Parcel from 'single-spa-react/parcel';
import { type ParcelProps } from './types';

interface RenderProps {
  state: { name: string };
  onChangeName: (name: string) => void;
  groups: ReactNode;
  onSubmit: () => void;
  submitting: boolean;
  error?: string;
  renderHelp: () => ReactNode;
}

interface Props {
  applicationCode?: string;
  onSaved?: (segment: SegmentData, isNew: boolean) => void;
  onEvent?: (type: string) => void;
  // onBlockedAction?: () => void;
  render?: (props: RenderProps) => void;
}

export type GrpcConnectedBuilderProps = {
  grpcBridge: GrpcBridge;
  showMode?: boolean;
  allowGroups?: boolean;
  allowEvents?: boolean;
  applicationCode: string;
  createHighSpeed?: boolean;
  segmentCode?: string;
  onSaved?: (segment: SegmentData, isNew: boolean) => void;
  onEvent?: (type: string) => void;
  onBlockedAction?: (type: string) => void;
  render?: (props: RenderProps) => ReactNode;
};

export function SegmentParcel(props: Props) {
  const { applicationCode, onSaved, onEvent, render } = props;

  const session = useSession();
  const grpcClient = useGrpcClient();
  const grpcBridge = useGrpcBridge();
  const parcelConfig = useMemo(() => {
    let parcel: any;
    return {
      bootstrap(props: ParcelProps) {
        const moduleToImport = '@pushwoosh/segment-builder';
        // eslint-disable-next-line no-undef
        return System.import(moduleToImport).then((module) => {
          parcel = module.parcel(props.domElement);
        });
      },
      mount(props: ParcelProps) {
        parcel.render(props);
        return Promise.resolve();
      },
      unmount() {
        parcel.unmount();
        return Promise.resolve();
      },
      update(props: ParcelProps) {
        parcel.render(props);
        return Promise.resolve();
      }
    };
  }, []);

  const { restrictions } = session;

  // TODO: Use following code when root-config will be updated to new session store dependency
  // const { allowSegmentsGroups, allowEventSegmentation } = session.restrictions;

  return (
    <Parcel
      config={parcelConfig}
      mountParcel={singleSpa.mountRootParcel}
      wrapWith="div"
      grpcClient={grpcClient}
      grpcBridge={grpcBridge}
      allowGroups={restrictions.allowSegmentsGroups}
      allowEvents={restrictions.allowEventSegmentation}
      applicationCode={applicationCode}
      onSaved={onSaved}
      onEvent={onEvent}
      render={render}
    />
  );
}
