import { DropOff } from '@src/common/types/statistics';
import { getDropOffLabel } from './getDropOffLabel';

export function getFormattedDropOffs(dropOffs: DropOff[]) {
  const allDropOffs = dropOffs.reduce((prevValue, dropOff) => prevValue + dropOff.value, 0);
  const result = [{ label: 'Drop-offs', value: allDropOffs, type: 'All' }];

  dropOffs.forEach((dropOff) => {
    result.push({
      label: getDropOffLabel(dropOff.type),
      value: dropOff.value,
      type: dropOff.type
    });
  });

  return result;
}
