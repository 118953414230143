import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgReloadMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 12a6.25 6.25 0 0110.485-4.596L14.77 8.87a.75.75 0 00.53 1.28h4a.75.75 0 00.75-.75v-4a.75.75 0 00-1.28-.53l-1.473 1.473a7.75 7.75 0 102.132 7.871.75.75 0 10-1.438-.428A6.25 6.25 0 015.75 12zm12.8-4.79v1.44h-1.44l1.44-1.44z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgReloadMediumLined;
