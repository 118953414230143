import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPlaceSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM7.25 7a.75.75 0 111.5 0 .75.75 0 01-1.5 0z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.584 14.124L8 13.5l-.416.624a.75.75 0 00.832 0L8 13.5l.416.624.002-.001.004-.003.012-.008.044-.03c.036-.026.089-.062.154-.11a15.965 15.965 0 002.162-1.894C11.95 10.86 13.25 9.051 13.25 7a5.25 5.25 0 10-10.5 0c0 2.051 1.3 3.86 2.456 5.078a15.96 15.96 0 002.316 2.004l.044.03.012.008.004.003h.002zM5.348 4.348A3.75 3.75 0 0111.75 7c0 1.449-.95 2.89-2.044 4.047A14.47 14.47 0 018 12.574l-.236-.184a14.47 14.47 0 01-1.47-1.343C5.2 9.89 4.25 8.449 4.25 7a3.75 3.75 0 011.098-2.652z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPlaceSmallLined;
