import styled from 'styled-components';
import { Color, FontWeight, FontSize, LineHeight, Spacing } from '@pushwoosh/kit-constants';

type ButtonProps = {
  isDisabled?: boolean;
};

export const ButtonField = styled.p<ButtonProps>`
  font-weight: ${FontWeight.MEDIUM};
  font-size: ${FontSize.REGULAR};
  line-height: ${LineHeight.REGULAR};
  color: ${({ isDisabled }) => (isDisabled ? Color.PHANTOM : Color.CLEAR)};
`;

export const StyledButton = styled.button<ButtonProps>`
  height: 52px;
  border: transparent;
  outline: transparent;
  display: flex;
  align-items: center;
  padding: 16px 20px;
  cursor: pointer;
  background: ${Color.PRIMARY};
  box-shadow: ${({ isDisabled }) =>
    isDisabled
      ? '0px 4px 6px rgba(0, 0, 0, 0.06), 0px 0px 4px rgba(0, 0, 0, 0.06);'
      : '0px 4px 6px rgba(14, 114, 229, 0.28), 0px 0px 4px rgba(14, 114, 229, 0.12);'};
  border-radius: 8px;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};

  &:hover {
    background: ${Color.PRIMARY_HOVER};
  }

  &:active {
    opacity: 0.5;
  }
`;

export const LoaderWrap = styled.div`
  margin-right: ${Spacing.S1};
`;
