import { action } from 'easy-peasy';

// Types
import { INotificationModel } from './notifications.types';

export const model: INotificationModel = {
  items: [],

  add: action((state, payload): void => {
    state.items = [...payload];
  })
};
