/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from '@src/ui/kit';
import { FontSize } from '@pushwoosh/kit-constants';
import { Container, Cell, Value, LinkWrap, DescriptionWrap, Title } from './styled';

type Item = {
  label: string;
  value: unknown;
  actionText?: string;
  onActionClick?: () => void;
  description?: string;
};

type Props = {
  data: Item[];
  renderFirstRow?: (item: Item) => JSX.Element;
};

export function StatisticGrid(props: Props): JSX.Element {
  const { data, renderFirstRow } = props;

  return (
    <Container>
      {data.map((item, index) =>
        renderFirstRow && index === 0 ? (
          renderFirstRow(item)
        ) : (
          <Cell key={index}>
            <Title>
              {item.label}
              {item?.actionText && (
                <LinkWrap onClick={item?.onActionClick}>
                  <Link isExternal to="#" size={FontSize.REGULAR}>
                    {item.actionText}
                  </Link>
                </LinkWrap>
              )}
            </Title>
            {item?.description && <DescriptionWrap>{item.description}</DescriptionWrap>}
            <Value>{item.value}</Value>
          </Cell>
        )
      )}
    </Container>
  );
}
