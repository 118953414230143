import styled from 'styled-components';
import { Button } from '@src/ui/kit';
import { Spacing } from '@pushwoosh/kit-constants';

export const Conditional = styled.div`
  display: grid;
  grid-template-columns: max-content min-content;
  column-gap: ${Spacing.S3};
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type VerticalLineTopProps = {
  isHidden: boolean;
};

export const WrapHeightRight = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
`;

export const AddConditionButton = styled(Button)`
  margin-left: -${Spacing.S2};
`;
