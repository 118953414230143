import styled from 'styled-components';
import { Spacing, Color } from '@pushwoosh/kit-constants';

export const StyledButton = styled.button`
  width: 100%;
  padding: ${Spacing.S3};
  background-color: ${Color.BRAND_GREEN};
  border: none;
  outline: transparent;
  border-radius: 32px;
  color: ${Color.MAIN};
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
`;
