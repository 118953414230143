import * as React from 'react';

import { useDidUpdate } from '@src/ui/hooks';
import { TRichMediaPreviewIFrameProps } from './RichMediaPreviewIFrame.types';
import { RichMediaTemplate } from './RichMediaPreviewIFrame.helpers';
import { Overlay, RichMediaPreviewIFrameContainer } from './styled';

function RichMediaPreviewIFrame(props: TRichMediaPreviewIFrameProps): JSX.Element {
  const { richMediaCode, zipContent, params, language = 'en' } = props;

  const iFrameRef = React.useRef<HTMLIFrameElement | null>(null);
  const [template, setTemplate] = React.useState<RichMediaTemplate | null>(null);

  React.useEffect((): void => {
    setTemplate(new RichMediaTemplate(richMediaCode, zipContent, params, language));
  }, [richMediaCode, zipContent, params, language]);

  useDidUpdate(async (): Promise<void> => {
    if (!iFrameRef.current) {
      return;
    }

    const html = await template.buildHTML();

    const iFrameDocument = iFrameRef.current.contentWindow.document;
    iFrameDocument.open('text/html', 'replace').write(html);
    iFrameDocument.close();
  }, [iFrameRef.current, template]);

  return (
    <>
      <Overlay />
      <RichMediaPreviewIFrameContainer
        ref={iFrameRef}
        title="rich media preview"
        frameBorder="0"
        sandbox="allow-same-origin"
      />
    </>
  );
}

export const RichMediaPreviewIFrameMemo = React.memo(RichMediaPreviewIFrame);
