import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgBulletTriangleFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.723 11a1 1 0 01-.868-1.496l2.277-3.985a1 1 0 011.736 0l2.277 3.985A1 1 0 0110.277 11H5.723z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgBulletTriangleFilled;
