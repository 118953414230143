import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSortAscSmall(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 11.75a.75.75 0 01-1.5 0V5.56l-.72.72a.75.75 0 01-1.06-1.06l2-2a.75.75 0 011.06 0l2 2a.75.75 0 11-1.06 1.06l-.72-.72v6.19z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgSortAscSmall;
