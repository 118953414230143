import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';

export const PlaceholderWrap = styled.div`
  margin-bottom: ${Spacing.S3};
  display: grid;
  grid-template-columns: auto 192px 192px;
  grid-column-gap: ${Spacing.S3};
`;

export const PlaceholderName = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: ${Spacing.S3} 0;
`;
