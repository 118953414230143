import numeral from 'numeral';

export function formatNumber(count: number, format = '0.[0]a'): string {
  return numeral(count).format(format);
}

export function formatNumberDouble(count: number, format = '00'): string {
  return numeral(count).format(format);
}

export function formatNumberWithSpace(count: number, format = '0,0,0'): string {
  return numeral(count).format(format).replace(/,/g, ' ');
}
