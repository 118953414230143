// Api
import { GetOptions, HttpClient } from '@pushwoosh/http-client';

// Helpers
import { getApiOrigin } from '@src/store/helpers';

// Types
import { TFetchFilterResponse } from './types';

export const service = {
  async fetch(api: HttpClient): Promise<TFetchFilterResponse> {
    const options: GetOptions<TFetchFilterResponse, never, never> = {
      withAuthorization: true
    };

    // eslint-disable-next-line camelcase
    return api.get<TFetchFilterResponse, never, never>(`${getApiOrigin()}/api/filter`, options);
  }
};
