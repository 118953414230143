import React from 'react';

// Store
import { useStoreActions } from '@src/store/store';
import { ModalType } from '@src/common/constants';

type TCloseModalByType = (type: ModalType) => void;
type TResult = TCloseModalByType;

export function useCloseModalByType(): TResult {
  const closeModalByType: TCloseModalByType = useStoreActions<TCloseModalByType>(
    (actions) => actions.modals.closeModalByType
  );

  return React.useCallback<TCloseModalByType>(
    (type): void => {
      closeModalByType(type);
    },
    [closeModalByType]
  );
}
