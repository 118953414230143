import styled from 'styled-components';
import { Spacing, Color, FontSize, FontWeight } from '@pushwoosh/kit-constants';

export const GoalItem = styled.div`
  display: flex;
  justify-content: space-between;

  padding: ${Spacing.S5} 0;
  border-bottom: 1px solid ${Color.DIVIDER};

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

export const GoalData = styled.div``;

export const GoalMainInfo = styled.div`
  display: flex;
`;

export const GoalTitle = styled.div`
  font-size: ${FontSize.REGULAR};
  font-weight: ${FontWeight.BOLD};
`;

export const GoalLabelExit = styled.div`
  color: ${Color.SOFT};
  font-size: ${FontSize.REGULAR};
`;

export const GoalMeta = styled.div`
  margin-top: ${Spacing.S1};
  color: ${Color.LOCKED};
  font-size: ${FontSize.SMALL};
  word-wrap: break-word;
`;

export const GoalStatistics = styled.div`
  width: 54px;
  margin-left: ${Spacing.S1};
`;

export const GoalPercentAchieved = styled.div`
  font-size: ${FontSize.REGULAR};
  font-weight: ${FontWeight.BOLD};
`;

export const GoalTotalAchieved = styled.div`
  margin-top: ${Spacing.S1};
  color: ${Color.LOCKED};
  font-size: ${FontSize.SMALL};
`;
