import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgLanguageSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 8c0-3.312 2.682-6 5.994-6A6.003 6.003 0 0114 8c0 3.312-2.688 6-6.006 6A5.997 5.997 0 012 8zm8.382-2.4h1.77a4.818 4.818 0 00-2.598-2.136 9.39 9.39 0 01.828 2.136zM8 3.224c.498.72.888 1.518 1.146 2.376H6.854A8.452 8.452 0 018 3.224zM3.2 8c0 .414.06.816.156 1.2h2.028A9.91 9.91 0 015.3 8c0-.408.036-.804.084-1.2H3.356A4.945 4.945 0 003.2 8zm.648 2.4h1.77c.192.75.468 1.47.828 2.136A4.792 4.792 0 013.848 10.4zm0-4.8h1.77a9.39 9.39 0 01.828-2.136A4.792 4.792 0 003.848 5.6zM8 12.776A8.452 8.452 0 016.854 10.4h2.292A8.452 8.452 0 018 12.776zM6.596 9.2h2.808c.054-.396.096-.792.096-1.2 0-.408-.042-.81-.096-1.2H6.596c-.054.39-.096.792-.096 1.2 0 .408.042.804.096 1.2zm2.958 3.336a9.39 9.39 0 00.828-2.136h1.77a4.818 4.818 0 01-2.598 2.136zM10.7 8c0 .408-.036.804-.084 1.2h2.028c.096-.384.156-.786.156-1.2 0-.414-.06-.816-.156-1.2h-2.028c.048.396.084.792.084 1.2z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgLanguageSmallLined;
