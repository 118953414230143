import React from 'react';
import { sendMixpanelEvent } from '@src/common/analytics/mixpanel';
import { useStoreActions } from '@src/store/store';
import { useErrorsPoint } from '@src/store/hooks';
import { RightDrawerSidebar } from '@src/ui/kit';
import { ValidationFooter } from '../components/ValidationFooter';
import { ValidationItem } from '../components/ValidationItem';
import { ValidationContent } from '../styled';

export function ValidationSidebar(): JSX.Element {
  const hideSidepanel = useStoreActions((actions) => actions.sidebars.closeAllSidebars);
  const closeErrors = useStoreActions((actions) => actions.errorsPoint.close);
  const { errorsPoint, currentError, nextError, prevError, setCurrentError } = useErrorsPoint();

  React.useEffect(() => {
    if (errorsPoint.length === 0) {
      hideSidepanel();
    }
  }, [errorsPoint.length, hideSidepanel]);

  const handleClose = React.useCallback((): void => {
    sendMixpanelEvent({
      eventName: 'Canvas:Drawer:Validation',
      eventData: { DrawerAction: 'CloseDrawer' }
    });
    hideSidepanel();
  }, [hideSidepanel]);

  const stopValidation = React.useCallback((): void => {
    sendMixpanelEvent({
      eventName: 'Canvas:Drawer:Validation',
      eventData: { DrawerAction: 'StopValidation' }
    });
    hideSidepanel();
    closeErrors();
  }, [hideSidepanel, closeErrors]);

  return (
    <RightDrawerSidebar
      title="Validation"
      isBack={false}
      isOpen
      onClose={handleClose}
      renderFooter={() => (
        <ValidationFooter
          nextError={nextError}
          prevError={prevError}
          stopValidation={stopValidation}
        />
      )}
    >
      <ValidationContent>
        {errorsPoint.map((errorPoint, index) => {
          const order = index + 1;
          const isSelected = currentError === index;

          return (
            <ValidationItem
              isSelected={isSelected}
              key={index}
              id={index}
              order={order}
              value={errorPoint.message}
              setCurrentError={setCurrentError}
            />
          );
        })}
      </ValidationContent>
    </RightDrawerSidebar>
  );
}
