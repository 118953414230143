import * as React from 'react';
import { Notification as makeNotification } from '@pushwoosh/ui/lib/molecules/Notification';

// Components
import { Alert } from '@src/ui/molecules';

// Styles
import styles from './styles/notification.scss';

// Types
import { IProps } from './Notification.types';

const { show, toastContainer } = makeNotification();

export function Notification(props: IProps): typeof toastContainer {
  const { notifications } = props;

  notifications.map((notification): void =>
    show({
      component: <Alert {...notification} />,
      options: {
        className: styles.notification,
        onClick: notification.onClickButton,
        autoClose: 3000
      }
    })
  );

  return toastContainer;
}
