// Store
import { useStoreState } from '@src/store/store';

export function useUnsavedChanges(): boolean {
  const isUnsavedChanges: boolean = useStoreState(
    (state): boolean => state.journeys.isUnsavedChanges
  );

  return isUnsavedChanges;
}
