import { useStoreState } from '@src/store/store';

interface IUIModeFlags {
  isOpenedModal: boolean;
  isOpenedPopupMenu: boolean;
  isSelectedPoint: boolean;
  isErrorsPaginate: boolean;
  isBufferPointIdsNotEmpty: boolean;
}

export function useUIModeFlags(): IUIModeFlags {
  const isOpenedPopupMenu = useStoreState((state): boolean => state.popupMenu.isVisible);
  const isOpenedModal = useStoreState((state): boolean => state.modals.isOpened);
  const isSelectedPoint = useStoreState((state): boolean => state.points.isSelectedPoint);
  const isErrorsPaginate = useStoreState(
    (state): boolean => state.errorsPoint.isVisibleErrorsPaginate
  );
  const isBufferPointIdsNotEmpty = useStoreState(
    (state): boolean => state.points.isBufferPointsNotEmpty
  );

  return {
    isOpenedModal,
    isOpenedPopupMenu,
    isSelectedPoint,
    isErrorsPaginate,
    isBufferPointIdsNotEmpty
  };
}
