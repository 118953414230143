import { FormikErrors } from 'formik';
import { IEditPointSendSmsValues } from '@src/ui/features/SendSms/components/EditPointSendSms';

export function validate(values: IEditPointSendSmsValues): FormikErrors<IEditPointSendSmsValues> {
  const errors: FormikErrors<IEditPointSendSmsValues> = {};

  const isPlaceholderError = values.placeholders.some(
    (placeholder) => placeholder.isSelected && (!placeholder.eventName || !placeholder.attribute)
  );

  if (isPlaceholderError) {
    errors.placeholders = 'Please enter a correct placeholder settings';
  }

  return errors;
}
