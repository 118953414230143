import * as React from 'react';
import { Icon } from '@pushwoosh/ui/lib/atoms/Icon';
import { Alert as BaseAlert } from '@pushwoosh/ui/lib/molecules/Alert';
import { Paragraph } from '@pushwoosh/ui/lib/atoms/Paragraph';
import { useTheme } from '@pushwoosh/ui/lib/themes';

// Constants
import { NotificationType } from '@src/common/constants/notification';

// Styles
import styles from './styles/alert-error.scss';

// Types
import { IProps } from './Alert.types';

export function Alert(props: IProps): JSX.Element {
  const { title, message, type } = props;
  const themeClass = useTheme(styles['alert-error']);

  const typeAlert =
    type === NotificationType.ERROR || type === NotificationType.ERROR_POINT
      ? NotificationType.ERROR
      : NotificationType.SUCCESS;

  return (
    <div className={themeClass}>
      <BaseAlert className={styles['custom-alert']} type={typeAlert}>
        <div className={styles['text-container']}>
          {title && <Paragraph className={styles['alert-title']}>{title}</Paragraph>}

          {message && <Paragraph className={styles['alert-message']}>{message}</Paragraph>}
        </div>

        <Icon className={styles['custom-icon']} glyph="delete-cross" />
      </BaseAlert>
    </div>
  );
}
