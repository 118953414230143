import * as React from 'react';

// Store
import { useStoreActions } from '@src/store/store';

export type TCloseAllModals = (payload?: void) => void;
type TResult = TCloseAllModals;

export function useCloseAllModals(): TResult {
  const closeAllModals: TCloseAllModals = useStoreActions<TCloseAllModals>(
    (actions): TCloseAllModals => actions.modals.closeAllModal
  );

  const handleAllModalsClose = React.useCallback<TCloseAllModals>((): void => {
    closeAllModals();
  }, [closeAllModals]);

  return handleAllModalsClose;
}
