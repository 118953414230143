import * as React from 'react';

// Components
import { Tooltip } from '@src/canvas/components/Tooltip';
import { TooltipInfoField } from '@src/canvas/components/TooltipInfoField';

// Helpers
import { formatNumber } from '@src/common/helpers';

// Types
import { ITooltipPointSendPushStatsProps } from './TooltipPointSendPushStats.types';

function TooltipPointSendPushStats(props: ITooltipPointSendPushStatsProps): JSX.Element {
  const {
    className,
    sent,
    opened,
    conversion,
    error = 0,
    id,
    x,
    y,
    usersEngaged,
    isShowUsersEngaged,
    onClick,
    onMouseEnter,
    onMouseLeave
  } = props;

  return (
    <Tooltip
      className={className}
      id={id}
      x={x}
      y={y}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <TooltipInfoField label="Sent" value={sent} />
      {isShowUsersEngaged && <TooltipInfoField label="Users engaged" value={usersEngaged} />}
      <TooltipInfoField label="Opened / Clicked" value={opened} />
      <TooltipInfoField label="CTR" value={`${formatNumber(conversion, '0.[00]')}%`} />
      {/* <TooltipInfoField label="Goals Reached" value={goalsReached} /> */}
      <TooltipInfoField label="Drop-offs" value={error} />
    </Tooltip>
  );
}

export default React.memo(TooltipPointSendPushStats);
