import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPlatformLogoiExplorerMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 12.253c0-1.423-.368-2.76-1.013-3.922 2.75-6.223-2.947-5.312-3.264-5.25a14.484 14.484 0 00-3.353 1.096 8.092 8.092 0 00-8.343 6.261c2.026-2.272 3.443-3.19 4.292-3.556-.134.12-.267.243-.398.367-.044.04-.085.083-.128.124a20.11 20.11 0 00-.256.249l-.148.15c-.074.075-.148.151-.22.227-.053.055-.103.109-.153.163a20.274 20.274 0 00-1.013 1.164 23.9 23.9 0 00-.157.197l-.15.19-.138.18c-.052.068-.104.136-.154.204l-.106.144a25.083 25.083 0 00-1.08 1.598l-.01.018a26.35 26.35 0 00-.191.313l-.007.011c-.158.263-.311.529-.46.797-.728 1.32-1.083 2.243-1.098 2.298-2.3 8.224 4.88 4.751 5.881 4.233a8.091 8.091 0 0011.201-4.547h-4.25c-.628 1.062-1.839 1.781-3.23 1.781-2.038 0-3.69-1.544-3.69-3.449h11.57c.043-.34.066-.688.066-1.041zm-1.511-7.96c.697.47 1.255 1.208.296 3.695a8.116 8.116 0 00-3.95-3.28c.748-.36 2.602-1.125 3.654-.415zM4.686 19.492c-.567-.582-.668-1.999.584-4.581a8.112 8.112 0 003.522 4.304c-.81.447-2.962 1.45-4.106.277zm4.66-8.41c.065-1.85 1.676-3.333 3.656-3.333 1.98 0 3.59 1.482 3.655 3.333h-7.31z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPlatformLogoiExplorerMediumFilled;
