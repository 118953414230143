import React from 'react';
import { useParams } from 'react-router-dom';

// Store hooks
import { useUsername, useAuth, useSubscripttionPlan } from '@src/store/hooks';

import { TemplateCategories } from '@src/common/constants/templates';
import { SidebarNav } from '../components/SidebarNav';

export function SidebarMenu(): JSX.Element {
  const params = useParams<{ id: string }>();
  const username = useUsername();
  const subscriptionPlan = useSubscripttionPlan();
  const { logout } = useAuth();

  return (
    <SidebarNav
      username={username}
      idCategory={params.id as TemplateCategories}
      onLogout={logout}
      subscriptionPlan={subscriptionPlan}
    />
  );
}
