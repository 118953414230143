import * as React from 'react';

// Components
import { IconEnum, Button } from '@src/ui/kit';
import {
  FilterCondition,
  IFilterConditionProps
} from '@src/ui/features/EditEvents/components/FilterCondition';

// Helpers
import { makeOperatorOptionsByAttributeType } from '@src/ui/helpers/makeOperatorOptionsByAttributeType';

// Types
import { TOperatorSelectOption, TAttributeSelectOption } from '@src/common/types/form';
import { FormBlock } from '@src/ui/form/styled';
import { IFilterConditionListProps } from './FilterConditionList.types';

// Styled
import { Conditional, WrapHeightRight, AddConditionButton } from './styled';

export function FilterConditionList(props: IFilterConditionListProps): JSX.Element {
  const {
    attributeSelectOptions,
    formListName,
    applicationCode,
    eventCode,
    names,
    values,
    errors = [],
    touched = [],
    setFieldValue,
    setFieldTouched,
    canMoreAdd,
    onChangeField,
    onBlurField,
    onRemove,
    onAdd
  } = props;

  const btnRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (btnRef.current) {
      btnRef.current.click();
    }
  }, [btnRef.current]);

  const selectedAttributes = values.reduce<TAttributeSelectOption[]>(
    (acc, { attributeName, type }): TAttributeSelectOption[] => {
      const option = attributeSelectOptions.find((option): boolean => {
        const isEqualLabel = option.label === attributeName;
        const isEqualValue = option.value === type;

        return isEqualLabel && isEqualValue;
      });

      if (option) {
        acc.push(option);
      }

      return acc;
    },
    []
  );

  const conditionFilterList = values.map((conditionValues, index, coll): JSX.Element => {
    const conditionNames: IFilterConditionProps['names'] = {
      attributeName: `${formListName}[${index}].${names.attributeName}`,
      type: `${formListName}[${index}].${names.type}`,
      operator: `${formListName}[${index}].${names.operator}`,
      value: `${formListName}[${index}].${names.value}`
    };
    const conditionTouched = touched[index] || {};
    const conditionErrors = errors[index] || {};

    const isLastElement = coll.length === index + 1;

    const attributeOptions = attributeSelectOptions.filter((option): boolean => {
      const hasAttributeAlreadySelected = selectedAttributes.some((selectedOption): boolean => {
        const isEqualLabel = selectedOption.label === option.label;
        const isEqualValue = selectedOption.value === option.value;

        return isEqualLabel && isEqualValue;
      });

      const isCurrentEqualLabel = conditionValues.attributeName === option.label;
      const isCurrentEqualValue = conditionValues.type === option.value;

      return !hasAttributeAlreadySelected || (isCurrentEqualLabel && isCurrentEqualValue);
    });

    const operatorOptions: TOperatorSelectOption[] = makeOperatorOptionsByAttributeType(
      conditionValues.type
    );

    return (
      <FormBlock key={index}>
        <Conditional>
          <FilterCondition
            applicationCode={applicationCode}
            eventCode={eventCode}
            attributeSelectOptions={attributeOptions}
            operatorSelectOptions={operatorOptions}
            names={conditionNames}
            values={conditionValues}
            errors={conditionErrors}
            touched={conditionTouched}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            onChangeField={onChangeField}
            onBlurField={onBlurField}
            isLastElement={isLastElement}
          />

          {onRemove ? (
            <WrapHeightRight>
              <Button
                iconType={IconEnum.CLOSE_SMALL}
                onClick={() => onRemove(index)}
                size="compact"
                color="secondary"
                view="ghost"
              />
            </WrapHeightRight>
          ) : null}
        </Conditional>
      </FormBlock>
    );
  });

  const handleAddCondition = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.preventDefault();

      const remainingAttributeOptions = attributeSelectOptions.filter((option): boolean => {
        const hasAttributeAlreadySelected = selectedAttributes.some((selectedOption): boolean => {
          const isEqualLabel = selectedOption.label === option.label;
          const isEqualValue = selectedOption.value === option.value;

          return isEqualLabel && isEqualValue;
        });

        return !hasAttributeAlreadySelected;
      });

      onAdd(event, remainingAttributeOptions);
    },
    [attributeSelectOptions, selectedAttributes, onAdd]
  );

  return (
    <div>
      {conditionFilterList}

      {canMoreAdd ? (
        <FormBlock>
          <AddConditionButton
            view="ghost"
            size="compact"
            color="secondary"
            ref={btnRef}
            onClick={handleAddCondition}
            iconType={IconEnum.PLUS_SMALL}
          >
            Add condition
          </AddConditionButton>
        </FormBlock>
      ) : null}
    </div>
  );
}
