import React from 'react';
import { format } from 'date-fns';

// Components
import { Select, TSelectOption } from '@src/ui/kit/Select';
import { Input } from '@src/ui/atoms/Input';
import { InputDatepicker } from '@src/ui/kit/InputDatepicker';
import { AutoComplete } from '@src/ui/kit/AutoComplete';

// Hooks
import { useGetTagAutocompleteValues } from '@src/ui/hooks/useGetTagAutocompleteValues';

// Enums
import { TypeTag } from '@src/common/constants/tag';

// Types
import { Props } from './TagValueList.types';

// Styled
import { ListTagValue, OperationSelect, ListValues } from './styled';

export const TagValueList = (props: Props): JSX.Element => {
  const { tag, index, onChangeTag, onChangeOperation } = props;

  const [requestToApiTagAutocomplete] = useGetTagAutocompleteValues();

  const handleLoadAutocomplete = React.useCallback(
    (inputValue, callback): void => {
      requestToApiTagAutocomplete(inputValue, tag.name, tag.type).then((options) =>
        callback(options)
      );
    },
    [tag.name, tag.type, requestToApiTagAutocomplete]
  );

  return (
    <>
      {tag.type === TypeTag.INTEGER && (
        <>
          <Input
            type="number"
            name="integer"
            placeholder="Set a tag value"
            value={typeof tag.value === 'number' ? `${tag.value}` : ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
              onChangeTag(parseInt(event.target.value, 10), index)
            }
          />
        </>
      )}
      {tag.type === TypeTag.PRICE && (
        <>
          <Input
            type="number"
            name="price"
            placeholder="Set a tag value"
            value={`${tag.value}`}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
              onChangeTag(event.target.value, index)
            }
            onBlur={(event: React.FocusEvent<HTMLInputElement>): void => {
              const fixedValue = parseFloat(event.target.value).toFixed(2);
              onChangeTag(parseFloat(fixedValue), index);
            }}
          />
        </>
      )}
      {tag.type === TypeTag.STRING && (
        <>
          <AutoComplete
            placeholder="Set a tag value"
            value={tag.value ? { label: tag.value as string, value: tag.value as string } : null}
            loadOptions={handleLoadAutocomplete}
            onChange={(selectedOption) => {
              const option = Array.isArray(selectedOption) ? null : selectedOption;
              onChangeTag(option?.value as string, index);
            }}
          />
        </>
      )}
      {tag.type === TypeTag.VERSION && (
        <>
          <Input
            type="text"
            name="version"
            placeholder="Set a tag value"
            value={(tag.value as string) || ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
              onChangeTag(event.target.value, index)
            }
          />
        </>
      )}
      {tag.type === TypeTag.BOOLEAN && (
        <>
          <Select
            name="boolean"
            value={
              typeof tag.value === 'boolean'
                ? {
                    label: `${tag.value}`,
                    value: `${tag.value}`
                  }
                : null
            }
            options={[
              { label: 'true', value: 'true' },
              { label: 'false', value: 'false' }
            ]}
            placeholder="Set a tag value"
            isSearchable
            onChange={(currentValue: TSelectOption): void =>
              onChangeTag(currentValue.value === 'true', index)
            }
          />
        </>
      )}
      {(tag.type === TypeTag.LIST || tag.type === TypeTag.LIST_APPEND) && (
        <ListTagValue>
          <ListValues>
            <AutoComplete
              placeholder="Set a tag value"
              value={
                Array.isArray(tag.value)
                  ? tag.value.map((item) => ({ label: item, value: item }))
                  : []
              }
              loadOptions={handleLoadAutocomplete}
              onChange={(selectedOption) => {
                const options = Array.isArray(selectedOption) ? selectedOption : [];
                onChangeTag(options.map((item) => item.value) as string[], index);
              }}
              isMulti
            />
          </ListValues>
          <OperationSelect
            options={[
              { label: 'Add', value: TypeTag.LIST_APPEND },
              { label: 'Overwrite', value: TypeTag.LIST }
            ]}
            value={
              tag.type === TypeTag.LIST
                ? {
                    label: 'Overwrite',
                    value: TypeTag.LIST
                  }
                : {
                    label: 'Add',
                    value: TypeTag.LIST_APPEND
                  }
            }
            isSearchable={false}
            onChange={({ value }) => {
              onChangeOperation(value as TypeTag, index);
            }}
          />
        </ListTagValue>
      )}
      {tag.type === TypeTag.DATE && (
        <>
          <InputDatepicker
            value={tag.value ? new Date(tag.value as string) : new Date()}
            onChange={(value: Date): void => onChangeTag(format(value, 'yyyy-MM-dd'), index)}
          />
        </>
      )}
    </>
  );
};
