import { createContext, useContext } from 'react';

import { Rpc } from '@src/store/api/configured/rpc';

const RpcContext = createContext<Rpc | null>(null);
export const RpcProvider = RpcContext.Provider;

export function useRpc(): Rpc {
  const rpc = useContext(RpcContext);
  if (!rpc) {
    throw new Error(
      'rpc is not defined! Please check what are you using useHttpClient in a child of HttpClientProvider!'
    );
  }

  return rpc;
}
