// Types
import { IOption } from '@src/common/types/select';

export function findOption<Option extends IOption<unknown>>(
  options: Option[],
  searchableValue: Option['value'],
  searchableLabel?: string
): Option {
  if (searchableLabel) {
    return (
      options.find(
        (option): boolean => option.value === searchableValue && option.label === searchableLabel
      ) || null
    );
  }

  return options.find((option): boolean => option.value === searchableValue) || null;
}
