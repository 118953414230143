// Store
import { useStoreState } from '@src/store/store';

// Types
import { TJourney } from '@src/common/types/journey';
import { IUniqueId } from '@src/common/types/entities';

type TGetJourneyAppCode = (journeyId: IUniqueId['id']) => TJourney['params']['applicationCode'];

export function useJourneyAppCode(journeyId: IUniqueId['id']): string {
  const getJourneyAppCode: TGetJourneyAppCode = useStoreState(
    (state): TGetJourneyAppCode => state.journeys.journeyAppCode
  );

  return getJourneyAppCode(journeyId);
}
