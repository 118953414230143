import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgMinusSmall(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 8A.75.75 0 014 7.25h8a.75.75 0 010 1.5H4A.75.75 0 013.25 8z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgMinusSmall;
