import * as React from 'react';

// Analytics
import { sendMixpanelEvent } from '@src/common/analytics';
import { SidebarType } from '@src/common/constants/sidebars';

// Store Hooks
import {
  useCurrentJourneyId,
  useGetJourneyById,
  useGetPointById,
  useJourneyAppCode,
  useOpenNewModal,
  useUpdatePoint,
  useEditJourneyById
} from '@src/store/hooks';
import { useStoreActions, useStoreState } from '@src/store/store';

// UI Hooks
import { useForm } from '@src/ui/hooks';

// Container Hooks
import { useTagsWithAutoLoad } from '@src/ui/features/SetTags/hooks/useTagsWithAutoLoad';

// UI Components
import { Section, Button, IconEnum } from '@src/ui/kit';
import { Paragraph } from '@pushwoosh/kit-typography';
import { FormInput } from '@src/ui/form';
import { FormBlock } from '@src/ui/form/styled';
import { Modal } from '@src/ui/kit/Modal';
import { DefaultButtons } from '@src/ui/kit/ModalButtons';
import {
  EditFormSetTags,
  EditFormValues
} from '@src/ui/features/SetTags/components/EditFormSetTags';

// Types
import { TSetTagsPoint } from '@src/common/types/points';
import { IUniqueId } from '@src/common/types/entities';
import { isEqual } from 'lodash';
import { JourneyStatus, ModalType } from '@src/common/constants';

interface IProps {
  pointId: IUniqueId['id'];
  closeModal: () => void;
}

function EditSetTagsComponent(props: IProps): JSX.Element {
  const { pointId, closeModal } = props;

  const journeyId = useCurrentJourneyId();
  const applicationCode = useJourneyAppCode(journeyId);
  const showDocumentationMenu = useStoreActions((actions) => actions.documentationMenu.show);

  const getPointById = useGetPointById();
  const updatePoint = useUpdatePoint();
  const editPoint = useStoreActions((actions) => actions.points.update);
  const handleEditJourneyById = useEditJourneyById();
  const sidebars = useStoreState((state) => state.sidebars);
  const openNewSidebar = useStoreActions((actions) => actions.sidebars.openNewSidebar);
  const closeAllSidebars = useStoreActions((actions) => actions.sidebars.closeAllSidebars);

  const getJourneyById = useGetJourneyById();
  const openNewModal = useOpenNewModal();

  const { status } = getJourneyById(journeyId);

  const point = getPointById(pointId) as TSetTagsPoint;

  const [tags, isLoadTags] = useTagsWithAutoLoad();

  const isJourneyActive = status === JourneyStatus.RUNNING;

  const {
    values,
    setFieldValue,
    setValues,
    setFieldTouched,
    handleSubmit,
    handleChange,
    handleBlur
  } = useForm<EditFormValues>({
    initialValues: {
      title: point.title,
      updateTags: point.data.tags
    },
    onSubmit(formValues): void {
      const newPoint = {
        id: point.id,
        title: formValues.title,
        data: {
          applicationCode,
          tags: formValues.updateTags
        }
      };

      if (!isJourneyActive) {
        updatePoint(newPoint);

        closeModal();
      } else {
        editPoint(newPoint);

        handleEditJourneyById(journeyId);

        sendMixpanelEvent({
          eventName: 'JourneyEditModeChangesApplied',
          eventData: {
            PointType: 'SetTags'
          }
        });

        closeModal();

        openNewModal({
          type: ModalType.DETAIL_POINT,
          data: {
            pointId
          }
        });
      }
    }
  });

  const handleCancel = React.useCallback(() => {
    if (status === JourneyStatus.RUNNING) {
      sendMixpanelEvent({
        eventName: 'JourneyEditModeChangesCancelled',
        eventData: {
          PointType: 'SetTags'
        }
      });
    } else {
      sendMixpanelEvent({
        eventName: 'ConfigurePointCancelled',
        eventData: {
          PointType: 'SetTags',
          PointChanged: values.title !== point.title || !isEqual(values.updateTags, point.data.tags)
        }
      });
    }

    closeModal();
  }, [closeModal, values, point, status]);

  const onShowDocumentation = React.useCallback((): void => {
    if (sidebars.isOpened) {
      closeAllSidebars();
    } else {
      openNewSidebar({
        type: SidebarType.DOCUMENTATION
      });
      showDocumentationMenu();
      sendMixpanelEvent({ eventName: 'ShowDocumentation' });
    }
  }, [showDocumentationMenu, sidebars, closeAllSidebars, openNewSidebar]);

  return (
    <Modal
      title="Update User Profile"
      widthType="widest"
      footerRight={
        <DefaultButtons
          onClickActionButton={handleSubmit}
          onClickCancelButton={handleCancel}
          actionButtonName={status === JourneyStatus.RUNNING ? 'Save' : 'Apply'}
        />
      }
      footerLeft={
        <Button
          color="primary"
          size="field"
          view="ghost"
          iconPosition="left"
          iconType={IconEnum.DOCS_MEDIUM_LINED}
          onClick={onShowDocumentation}
        >
          How to Use
        </Button>
      }
      isOpen
    >
      <Section direction="column">
        <Paragraph>
          Use this feature to set the value for the tag. You&apos;ll be able to segment your users
          according to these tags and use them in personalization. You can set up to 10 tags to
          update.
        </Paragraph>
        <FormBlock>
          <FormInput
            label="Point Name"
            name="title"
            value={values.title}
            onChange={setFieldValue}
          />
        </FormBlock>
      </Section>
      <EditFormSetTags
        journeyStatus={status}
        applicationCode={applicationCode}
        isLoadTags={isLoadTags}
        existTags={tags}
        values={values}
        names={{
          updateTags: 'updateTags',
          title: 'title'
        }}
        setFieldValue={setFieldValue}
        setValues={setValues}
        setFieldTouched={setFieldTouched}
        onBlurField={handleBlur}
        onChangeField={handleChange}
      />
    </Modal>
  );
}

export const SetTags = React.memo(EditSetTagsComponent);
