import * as React from 'react';
import * as ReactDOM from 'react-dom';

// Store Hooks
import { useModals } from '@src/store/hooks';

// Containers
import { ModalConfirm } from '@src/ui/features/Modals/Confirm';
import { ModalCreateJourney } from '@src/ui/features/Modals/CreateJourney';
import { ModalCloningJourney } from '@src/ui/features/CloningJourney';
import {
  ModalConversionGoals,
  ModalDetailPointGoalEventList
} from '@src/ui/features/ConversionGoals';
import { EditDataPoint } from '@src/ui/features/Modals/EditDataPoint';
import { SwitchDetailPoint } from '@src/ui/features/SwitchDetailPoint';
import { ModalDetailSilentHours, ModalEditSilentHours } from '@src/ui/features/SilentHours';
import {
  ModalDetailFrequencyCapping,
  ModalEditFrequencyCapping
} from '@src/ui/features/FrequencyCapping';
import { ModalRenameJourney } from '@src/ui/features/Modals/RenameJourney';
import { FullScreenEditor } from '@src/ui/features/Webhook';
import { ModalSelectApplication } from '@src/ui/features/Modals/SelectApplication';

// Enums
import { ModalType } from '@src/common/constants/modals';

// Types
import { TModal } from '@src/common/types/modal';
import { FullStatistic } from '@src/ui/features/JourneyStatistics/components/FullStatistic';
import { PremiumModal } from '@src/ui/features/Modals/PremiumModal';
import { ContactSales } from '@src/ui/features/Modals/ContactSales';
import { ContactSalesSuccess } from '@src/ui/features/Modals/ContactSales/ContactSalesSuccess';

function renderModalByType(modal: TModal): JSX.Element {
  let modalElement: JSX.Element = null;

  const portalContainer =
    document.getElementById('editor-page-modal') || document.getElementById('modal');

  switch (modal.type) {
    case ModalType.CONFIRM: {
      modalElement = (
        <ModalConfirm
          title={modal.data.title}
          description={modal.data.description}
          cancelLabel={modal.data.cancelLabel}
          confirmLabel={modal.data.confirmLabel}
          level={modal.data.level}
          isLoadingCancel={modal.data.isLoadingCancel}
          isLoadingConfirm={modal.data.isLoadingConfirm}
          onAfterCancel={modal.data.onAfterCancel}
          onAfterConfirm={modal.data.onAfterConfirm}
          onBeforeCancel={modal.data.onBeforeCancel}
          onBeforeConfirm={modal.data.onBeforeConfirm}
          warningMessage={modal.data.warningMessage}
        />
      );
      break;
    }

    case ModalType.CREATE_JOURNEY: {
      modalElement = <ModalCreateJourney />;
      break;
    }

    case ModalType.SELECT_APPLICATION: {
      modalElement = <ModalSelectApplication journeyId={modal.data.journeyId} />;
      break;
    }

    case ModalType.CLONE_JOURNEY: {
      modalElement = <ModalCloningJourney journeyId={modal?.data?.journeyId} />;
      break;
    }

    case ModalType.JOURNEY_GOAL_SETTINGS: {
      modalElement = <ModalConversionGoals />;
      break;
    }

    case ModalType.FULL_SCREEN_EDITOR: {
      modalElement = (
        <FullScreenEditor
          pointId={modal.data.pointId}
          requestTemplate={modal.data.requestTemplate}
        />
      );
      break;
    }

    case ModalType.SILENT_HOURS_SETTING: {
      modalElement = <ModalEditSilentHours />;
      break;
    }

    case ModalType.DETAIL_SILENT_HOURS_SETTING: {
      modalElement = <ModalDetailSilentHours />;
      break;
    }

    case ModalType.FREQUENCY_CAPPING: {
      modalElement = <ModalEditFrequencyCapping />;
      break;
    }

    case ModalType.DETAIL_FREQUENCY_CAPPING: {
      modalElement = <ModalDetailFrequencyCapping />;
      break;
    }

    case ModalType.RENAME_JOURNEY: {
      modalElement = (
        <ModalRenameJourney
          journeyId={modal.data.journeyId}
          title={modal.data.title}
          actionButtonName={modal.data.actionButtonName}
          beforeLoadJourney={modal.data.beforeLoadJourney}
        />
      );
      break;
    }

    case ModalType.DETAIL_POINT_GOAL_EVENT_LIST: {
      modalElement = <ModalDetailPointGoalEventList isChanged={modal.data?.isChanged} />;
      break;
    }

    case ModalType.EDITING_FORM_POINT: {
      modalElement = <EditDataPoint pointId={modal.data.pointId} />;
      break;
    }

    case ModalType.DETAIL_POINT: {
      modalElement = (
        <SwitchDetailPoint pointId={modal.data.pointId} isChanged={modal.data.isChanged} />
      );
      break;
    }

    case ModalType.FULL_STATISTIC: {
      modalElement = <FullStatistic journeyId={modal.data.journeyId} />;
      break;
    }

    case ModalType.PREMIUM: {
      modalElement = <PremiumModal premiumType={modal.data.premiumType} />;
      break;
    }

    case ModalType.CONTACT_SALES: {
      modalElement = <ContactSales />;
      break;
    }

    case ModalType.CONTACT_SALES_SUCCESS: {
      modalElement = <ContactSalesSuccess />;
      break;
    }

    default: {
      break;
    }
  }

  return ReactDOM.createPortal(modalElement, portalContainer, modal.type);
}

function ModalManagerComponent(): JSX.Element {
  const modals = useModals();

  const modalComponents = React.useMemo(() => modals.map(renderModalByType), [modals]);

  return <>{modalComponents}</>;
}

export const ModalManager = React.memo(ModalManagerComponent);
