import * as React from 'react';

// Store Hooks
import { useTag, useLoadTag } from '@src/store/hooks';

// Types
import { Tag } from '@src/common/types/tag';

export function useTagsWithAutoLoad(): [Tag[], boolean] {
  const tags = useTag();
  const [loadTags, isLoadingTags] = useLoadTag();

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (!isLoadingTags) {
      loadTags();
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return [tags, isLoadingTags];
}
