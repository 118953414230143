import * as React from 'react';

// Hooks
import {
  useLoadApplications,
  useLoadEmails,
  useLoadEvents,
  useLoadPresets,
  useGetPointById,
  useLoadRichMedias
} from '@src/store/hooks';

// Enums
import { PointType } from '@src/common/constants/point';

// Types
import { TApplication } from '@src/common/types/application';
import { useLoadRichMediaWithContent } from '@src/store/hooks/richMedias/useLoadRichMediaWithContent';
import { TSendInAppPoint } from '@src/common/types/points';

interface IWithApplicationCode {
  applicationCode: TApplication['code'];
}

const POINT_WITH_APPLICATIONS = new Set([
  PointType.EVENT,
  PointType.WAIT_EVENT,
  PointType.SEND_PUSH,
  PointType.SEND_EMAIL,
  PointType.SEND_IN_APP,
  PointType.SET_TAGS
]);

const POINT_WITH_PRESETS = new Set([PointType.SEND_PUSH]);

const POINT_WITH_EMAILS = new Set([PointType.SEND_EMAIL]);

const POINT_WITH_EVENTS = new Set([PointType.EVENT]);

const POINT_WITH_RICH_MEDIAS = new Set([PointType.SEND_IN_APP]);

export function useAutoLoadFullPointData(
  pointId: string,
  deps: React.DependencyList = []
): boolean {
  const getPointById = useGetPointById();
  const point = getPointById(pointId);

  const [loadApplications, isLoadingApplications] = useLoadApplications();
  const [loadEmails, isLoadingEmails] = useLoadEmails();
  const [loadEvents, isLoadingEvents] = useLoadEvents();
  const [loadPresets, isLoadingPresets] = useLoadPresets();
  const [loadRichMedia, isLoadingRichMedias] = useLoadRichMediaWithContent();

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (POINT_WITH_APPLICATIONS.has(point.type)) {
      loadApplications();
    }

    if (POINT_WITH_PRESETS.has(point.type)) {
      loadPresets((point.data as IWithApplicationCode).applicationCode);
    }

    if (POINT_WITH_EMAILS.has(point.type)) {
      loadEmails((point.data as IWithApplicationCode).applicationCode);
    }

    if (POINT_WITH_EVENTS.has(point.type)) {
      loadEvents((point.data as IWithApplicationCode).applicationCode);
    }

    if (POINT_WITH_RICH_MEDIAS.has(point.type)) {
      const { data } = point as TSendInAppPoint;

      loadRichMedia({
        appCode: data.applicationCode,
        richMediaCode: data.richMediaCode
      });
    }
  }, deps);
  /* eslint-enable react-hooks/exhaustive-deps */

  const isLoadApplications = POINT_WITH_APPLICATIONS.has(point.type) && isLoadingApplications;
  const isLoadEvents = POINT_WITH_EVENTS.has(point.type) && isLoadingEvents;
  const isLoadPresets = POINT_WITH_PRESETS.has(point.type) && isLoadingPresets;
  const isLoadEmails = POINT_WITH_EMAILS.has(point.type) && isLoadingEmails;
  const isLoadRichMedias = POINT_WITH_RICH_MEDIAS.has(point.type) && isLoadingRichMedias;

  return isLoadApplications || isLoadEvents || isLoadPresets || isLoadEmails || isLoadRichMedias;
}
