import * as React from 'react';

// Styled
import { Container } from './styled';

// Types
import { RadioButtonGroupProps } from './RadioButtonGroup.types';

export function RadioButtonGroup(props: RadioButtonGroupProps): JSX.Element {
  const { className, value, children, onChange, direction } = props;
  const [currentValue, setCurrentValue] = React.useState(value);

  React.useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleOnChange = React.useCallback(
    (value: string) => {
      setCurrentValue(value);
      if (typeof onChange === 'function') {
        onChange(value);
      }
    },
    [onChange]
  );

  return (
    <Container className={className} direction={direction}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          ...child.props,
          checked: child.props.value === currentValue,
          onChange: handleOnChange
        })
      )}
    </Container>
  );
}
