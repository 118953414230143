import React from 'react';
import { isSameDay, format } from 'date-fns';

// Components
import { Tools } from '../Tools';

// Helpers
import { formatDayLabel } from '../../helpers/formatDayLabel';
import { isWithinInterval } from '../../helpers/isWithinInterval';

// Types
import { NavigationType, WeekDay, WeekType, ViewEnum } from '../../Datepicker.types';

// Styled
import { CurrentDateMarker } from '../../styled';
import { DayLabelsWrap, DayLabel, Week, Day } from './styled';

interface IProps {
  cursorDate: Date;
  navigation: NavigationType;
  headers: {
    weekDays: WeekDay[];
  };
  weeks: WeekType[];
  value?: Date;
  minDate?: Date;
  maxDate?: Date;
  onChange: (value: Date) => void;
  setView: (view: ViewEnum) => void;
}

export function MonthView(props: IProps): JSX.Element {
  const { navigation, headers, weeks, value, minDate, maxDate, cursorDate, onChange, setView } =
    props;
  const currentMonth = format(cursorDate, 'MMMM');
  const currentYear = format(cursorDate, 'y');

  return (
    <>
      <Tools
        currentMonth={currentMonth}
        currentYear={currentYear}
        setView={setView}
        toPrev={navigation.toPrev}
        toNext={navigation.toNext}
      />
      <DayLabelsWrap>
        {headers.weekDays.map((day) => (
          <DayLabel key={day.key}>{formatDayLabel(day)}</DayLabel>
        ))}
      </DayLabelsWrap>
      {weeks.map((week) => (
        <Week key={week.key}>
          {week.value.map((day) => {
            const isSelectedDay = isSameDay(value, day.value);
            const isDisabled = isWithinInterval({ value: day.value, minDate, maxDate });
            const isToday = isSameDay(new Date(), day.value);

            return (
              <Day
                key={day.key}
                isCurrentMonth={day.isCurrentMonth}
                isSameDay={isSelectedDay}
                onClick={() => onChange(day.value)}
                isDisabled={isDisabled}
              >
                <div>{day.date}</div>
                {isToday ? <CurrentDateMarker isSameDate={isSelectedDay} /> : null}
              </Day>
            );
          })}
        </Week>
      ))}
    </>
  );
}
