// Types
import { TRichMedia } from '@src/common/types/richMedia';
import { TRichMediaSelectOption } from '@src/common/types/form';

export function makeRichMediaOptions(richMedias: TRichMedia[]): TRichMediaSelectOption[] {
  return richMedias.map(
    (richMedia): TRichMediaSelectOption => ({
      label: richMedia.name,
      value: richMedia.code
    })
  );
}
