import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgExitPoint(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 8.5a1.5 1.5 0 011.418 1.01c.09.26.306.49.582.49h15a1.5 1.5 0 011.5 1.5v10a1.5 1.5 0 01-1.5 1.5h-15a.5.5 0 00-.5.5v4c0 .276.224.5.5.507.971.029 1.867.14 2.571.316.404.1.802.237 1.123.427.255.15.806.54.806 1.25s-.551 1.1-.805 1.25c-.322.19-.72.326-1.124.427-.824.206-1.91.323-3.071.323-1.162 0-2.247-.117-3.071-.323-.404-.1-.802-.237-1.123-.427C8.55 31.1 8 30.71 8 30s.551-1.1.806-1.25c.32-.19.719-.326 1.123-.427a.093.093 0 00.071-.09V10a1.5 1.5 0 011.5-1.5zM9.784 30.498l.021-.016a.104.104 0 01-.021.016zm6.432 0a.1.1 0 01-.021-.016c.016.01.022.016.021.016zm-6.41-.98a.092.092 0 01-.022-.016s.009.005.021.016zm6.389 0a.1.1 0 01.021-.016l-.021.016z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgExitPoint;
