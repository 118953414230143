import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSendInAppPointFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={24} height={24} rx={4} fill="#B255E0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.25 6.5a.5.5 0 01.5-.5h.75a.5.5 0 01.5.5v.75a.5.5 0 01-.5.5h-.75a.5.5 0 01-.5-.5V6.5zM6 9.8c0-.28 0-.42.054-.527a.5.5 0 01.219-.218C6.38 9 6.52 9 6.8 9h10.4c.28 0 .42 0 .527.055a.5.5 0 01.218.218C18 9.38 18 9.52 18 9.8v6.4c0 .28 0 .42-.055.527a.5.5 0 01-.218.218C17.62 17 17.48 17 17.2 17H6.8c-.28 0-.42 0-.527-.055a.5.5 0 01-.218-.218C6 16.62 6 16.48 6 16.2V9.8zm9.5-3.3A.5.5 0 0015 6H6.5a.5.5 0 00-.5.5v.75a.5.5 0 00.5.5H15a.5.5 0 00.5-.5V6.5zM15 12a.5.5 0 01-.5.5h-6A.5.5 0 018 12v-.5a.5.5 0 01.5-.5h6a.5.5 0 01.5.5v.5zm-7 2.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V14a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v.5z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgSendInAppPointFilled;
