import styled from 'styled-components';
import { Color, Shadow, Spacing } from '@pushwoosh/kit-constants';
import { Button } from '@src/ui/kit';

const HEADER_HEIGHT = '60px';
const CONTENT_HEIGHT = '120px';

export const RightDrawerSidebarContainer = styled.section`
  position: fixed;
  top: 16px;
  bottom: 16px;
  right: 16px;
  z-index: 10;

  width: 300px;

  background: ${Color.CLEAR};
  box-shadow: ${Shadow.LARGE};
  border-radius: 8px;
`;

export const RightDrawerSidebarHeader = styled.header`
  display: flex;
  align-items: center;

  width: 100%;
  padding: ${Spacing.S3} ${Spacing.S3} ${Spacing.S3} ${Spacing.S5};

  border-bottom: 1px solid ${Color.FORM};
`;

type RightDrawerSidebarContentProps = {
  withFooter?: boolean;
};

export const RightDrawerSidebarContent = styled.div<RightDrawerSidebarContentProps>`
  display: flex;
  flex-direction: column;

  max-height: ${({ withFooter }) =>
    withFooter ? `calc(100% - ${CONTENT_HEIGHT})` : `calc(100% - ${HEADER_HEIGHT})`};
  height: ${({ withFooter }) =>
    withFooter ? `calc(100% - ${CONTENT_HEIGHT})` : `calc(100% - ${HEADER_HEIGHT})`};

  overflow: auto;
`;

export const RightDrawerSidebarControlBack = styled(Button)`
  margin-right: ${Spacing.S3};
`;

export const RightDrawerSidebarControlClose = styled(Button)`
  margin-left: auto;
`;

export const RightDrawerSidebarFooter = styled.div`
  height: 68px;
  position: fixed;
  bottom: 16px;
  right: 16px;
  width: 300px;
  border-radius: 0 0 8px 8px;
  border-top: 1px solid ${Color.FORM};
  background-color: ${Color.CLEAR};
`;
