import * as React from 'react';
import { Color, Spacing } from '@pushwoosh/kit-constants';

// Components
import { Select } from '@src/ui/kit/Select';

// Types
import { ISelectOption } from '@src/common/types/form';

// Types
import { IProps } from './SelectAddMultiple.types';

// Styles
import styles from './styles/styles.scss';

const KEY_ENTER = 13;

const customStyles = {
  noOptionsMessage: () => ({
    display: 'flex',
    justifyContent: 'flex-start',
    padding: Spacing.S3,
    color: Color.MAIN
  })
};

export function SelectAddMultiple(props: IProps): JSX.Element {
  const {
    name,
    values = [],
    options = [],
    isDisabled = false,
    onChange,
    onBlur,
    onInputChange
  } = props;

  const [selectInputValue, setSelectInputValue] = React.useState<string>('');
  const timerId = React.useRef(null);

  const onInput = React.useCallback(
    (value: string) => {
      clearTimeout(timerId.current);
      setSelectInputValue(value);

      timerId.current = setTimeout((): void => {
        onInputChange(value);
      }, 300);
    },
    [onInputChange]
  );

  const keyDownSelectHandler = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.keyCode === KEY_ENTER) {
        event.preventDefault();

        const newValue = (event.target as HTMLInputElement).value;
        const isDuplicateValue = values.some((item): boolean => item.value === newValue);

        if (!isDuplicateValue && newValue !== '') {
          const newValues = [...values.map((item) => item.value), newValue];
          if (typeof onChange === 'function') {
            onChange(name, newValues);
          }
          setSelectInputValue('');
        }
      }
    },
    [values, name, onChange]
  );

  const changeSelectOptionHandler = React.useCallback<
    React.ComponentProps<typeof Select>['onChange']
  >(
    (currentValues: ISelectOption<string>[]): void => {
      if (typeof onChange === 'function') {
        onChange(name, [...currentValues.map((item) => item.value)]);
      }
      setSelectInputValue('');
    },
    [name, onChange]
  );

  const blurSelectHandler = React.useCallback((): void => {
    onBlur(name, true);
    setSelectInputValue('');
  }, [name, onBlur]);

  const handleDisableOption = React.useCallback(
    (option): boolean => values.includes(option.value),
    [values]
  );

  /* eslint-disable @typescript-eslint/no-explicit-any */
  return (
    <div className={styles['select-add-multiple']}>
      <Select
        value={values}
        inputValue={selectInputValue}
        options={options}
        isOptionDisabled={handleDisableOption}
        onChange={changeSelectOptionHandler}
        onInputChange={onInput}
        onBlur={blurSelectHandler}
        onKeyDown={keyDownSelectHandler}
        styles={customStyles}
        noOptionsMessage={(props): string => `Add: ${props.inputValue}`}
        isDisabled={isDisabled}
        isSearchable
        isMulti
        backspaceRemovesValue
      />
    </div>
  );
  /* eslint-enable @typescript-eslint/no-explicit-any */
}
