import * as React from 'react';

// Types
import { IProps } from './Logo.types';

// Images
import LogoMin from './img/logo-min.svg';
import LogoMax from './img/logo-max.svg';

import { LogoWrap } from './styled';

export function Logo(props: IProps): JSX.Element {
  const { isLarge = false } = props;

  const imgUrl = isLarge ? LogoMax : LogoMin;

  return (
    <LogoWrap>
      <img src={imgUrl} alt="pushwoosh logo" />
    </LogoWrap>
  );
}
