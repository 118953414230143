import styled from 'styled-components';
import { FontSize, FontWeight } from '@pushwoosh/kit-constants';

export const StyledTooltipInfoField = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
`;

interface IStyledTextProps {
  color: string;
  fontWeight: FontWeight;
}

export const StyledText = styled.div`
  color: ${({ color }: IStyledTextProps) => color};
  font-size: ${FontSize.REGULAR};
  font-weight: ${({ fontWeight }: IStyledTextProps) => fontWeight};
  line-height: 1.4;
`;
