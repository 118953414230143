import * as React from 'react';

// Components
import { Select, TSelectOption } from '@src/ui/kit/Select';

// Types
import { DeviceValuesProps } from './DeviceValues.types';

enum DeviceValue {
  ID = 'user_id',
  HWID = 'hwid'
}

const DeviceMockValues = [
  { label: 'User ID', value: `${DeviceValue.ID}` },
  { label: 'Hardware ID', value: `${DeviceValue.HWID}` }
];

export function DeviceValues(props: DeviceValuesProps): JSX.Element {
  const { values, names, setFieldValue } = props;
  const { deviceValue } = values.dynamicValues;

  const getCurrentDevice = React.useMemo(
    () => (deviceValue ? DeviceMockValues.filter((item) => item.value === deviceValue) : null),
    [deviceValue]
  );

  const handleChangeDeviceValue = React.useCallback(
    (selectedOption: TSelectOption) => {
      setFieldValue(names.dynamicValues, {
        ...values.dynamicValues,
        deviceValue: selectedOption.value
      });
    },
    [names, values, setFieldValue]
  );

  return (
    <Select
      placeholder="Select..."
      value={getCurrentDevice}
      options={DeviceMockValues}
      onChange={handleChangeDeviceValue}
    />
  );
}
