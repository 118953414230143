import { capitalize } from 'lodash';
import { Account } from '@pushwoosh/session-store';

export enum ZohoField {
  FORM_ID = 'xnQsjsdp',
  AUTH = 'xmIwtLD',
  ACTION_TYPE = 'actionType',
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  EMAIL = 'Email',
  COMPANY = 'Company',
  MESSAGE = 'LEADCF11',
  LEAD_STATUS = 'Lead Status',
  LEAD_SOURCE_TYPE = 'LEADCF23',
  LEAD_SOURCE_LANDING_NAME = 'Lead Source',
  LEAD_TYPE = 'LEADCF25',
  RETURN_URL = 'returnURL',
  COUNTRY_FOR_REGION = 'LEADCF32',
  COUNTRY_NAME = 'Country'
}

export const registerInZoho = async (formValues: any, account: Account): Promise<void> => {
  const sendingParams: Array<{ name: string; value: string }> = [];

  sendingParams.push({
    name: ZohoField.FORM_ID,
    value: '6c1b28369d668c2c8cecc3b8c05c1b939e9a8dcbd6df14548a8fbd3c85ffb658'
  });
  sendingParams.push({
    name: ZohoField.AUTH,
    value: '5e10a999626f1b155bc4304fe55453cf3c42b024d439de5961dd1fc2d21bdc4c'
  });
  sendingParams.push({ name: ZohoField.ACTION_TYPE, value: 'TGVhZHM=' });
  sendingParams.push({
    name: ZohoField.RETURN_URL,
    value: encodeURIComponent(`${window.location.origin}/demo-success/`)
  });

  sendingParams.push({ name: ZohoField.LEAD_STATUS, value: 'Marketing Captured Lead' });
  sendingParams.push({
    name: ZohoField.LEAD_SOURCE_LANDING_NAME,
    value: 'CustomerJourney_Product'
  });
  sendingParams.push({ name: ZohoField.LEAD_TYPE, value: 'Inbound Lead' });
  sendingParams.push({ name: ZohoField.LEAD_SOURCE_TYPE, value: 'UpsellRequest from Journey' });

  sendingParams.push({
    name: ZohoField.FIRST_NAME,
    value: capitalize(formValues.firstName)
  });
  sendingParams.push({
    name: ZohoField.LAST_NAME,
    value: capitalize(formValues.lastName)
  });
  sendingParams.push({ name: ZohoField.EMAIL, value: formValues.email });

  sendingParams.push({
    name: ZohoField.COMPANY,
    value: encodeURIComponent(formValues.company)
  });
  sendingParams.push({
    name: ZohoField.MESSAGE,
    value: encodeURIComponent(formValues.message)
  });

  sendingParams.push({
    name: ZohoField.COUNTRY_NAME,
    value: account.billing.companyCountry || ''
  });

  const body = sendingParams.map(({ name, value }) => `${name}=${value}`).join('&');

  await fetch('https://crm.zoho.eu/crm/WebToLeadForm', {
    method: 'POST',
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body
  });
};
