import styled from 'styled-components';
import {
  Color,
  Spacing,
  FontSize,
  FontWeight,
  ShapeRadius,
  UnitSize,
  LineHeight
} from '@pushwoosh/kit-constants';
import { Link } from '@src/ui/kit';
import { Footnote } from '@pushwoosh/kit-typography';

// Components
import { Badge as BadgeComponent } from '@src/ui/kit/Badge';
import { Select as SelectComponent, PREFIX } from '@src/ui/kit/Select';

export const ListContainer = styled.div`
  width: 100%;
`;

export const TableContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 1fr) 112px 168px 160px 128px 128px 128px 64px;
  grid-template-rows: ${Spacing.S9};
  grid-auto-rows: ${Spacing.S14};
  gap: 0px 0px;

  border-top: 1px solid ${Color.DIVIDER};
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type HeaderProps = {
  isSortable?: boolean;
  isSortActive?: boolean;
};

export const Header = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Color.DIVIDER};
  padding-left: ${Spacing.S7};

  color: ${({ isSortActive }) => (isSortActive ? Color.MAIN : Color.LOCKED)};
  text-transform: uppercase;
  font-size: ${FontSize.SMALL};
  font-weight: ${FontWeight.MEDIUM};

  ${({ isSortable }) => isSortable && 'cursor: pointer;'}
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${Spacing.S7};

  border-bottom: 1px solid ${Color.DIVIDER};
  font-size: ${FontSize.REGULAR};
`;

export const TitleWrap = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

export const LinkTitle = styled(Link)`
  justify-content: flex-start;
  line-height: ${LineHeight.REGULAR};
`;

export const Application = styled(Footnote)`
  color: ${Color.LOCKED};
`;

export const Badge = styled(BadgeComponent)`
  text-transform: uppercase;
`;

export const GrayText = styled.div`
  color: ${Color.LOCKED};
`;

export const BlackText = styled.div`
  color: ${Color.MAIN};
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing.S7};
`;

export const Pages = styled.div`
  display: flex;
  border: 1px solid ${Color.DIVIDER};
  border-radius: ${ShapeRadius.SECTION};
`;

interface PropsPage {
  isActive?: boolean;
}

export const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: ${UnitSize.FIELD_HEIGHT};
  border-right: 1px solid ${Color.DIVIDER};

  background-color: ${({ isActive }: PropsPage) => (isActive ? Color.MAIN : Color.CLEAR)};

  font-size: ${FontSize.REGULAR};
  color: ${({ isActive }) => (isActive ? Color.CLEAR : Color.MAIN)};
  cursor: pointer;

  &:nth-child(5) {
    border-right: none;
  }
`;

export const ShowOnPage = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const ShowOnPageLabel = styled.div`
  margin-right: ${Spacing.S4};
  font-size: ${FontSize.REGULAR};
`;

export const Select = styled(SelectComponent)`
  .${PREFIX}__control {
    min-width: 67px;
  }
`;
