import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPremiumMono(props: IProps): JSX.Element {
  return (
    <svg
      width={14}
      height={11}
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.788 3.535a.5.5 0 01-.724.135L1.567 1.798a.5.5 0 00-.789.504l1.133 5.3a.5.5 0 00.489.395h9.2a.5.5 0 00.488-.396l1.133-5.285a.5.5 0 00-.788-.505L9.935 3.672a.5.5 0 01-.723-.136L7.423.675a.5.5 0 00-.848 0l-1.787 2.86zm7.211 5.961a.5.5 0 00-.5-.5h-9a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-1z"
        fill="#00A2FF"
      />
    </svg>
  );
}

export default SvgPremiumMono;
