import { action, computed, thunk, actionOn } from 'easy-peasy';

// Helpers
import { makeUniqueId } from '@src/common/helpers/makeUniqueId';

// Types
import { TTooltip } from '@src/common/types/tooltip';
import { IUniqueId } from '@src/common/types/entities';
import { ITooltipModel } from './tooltip.type';

const DEFAULT_DELAY = 500;

export const model: ITooltipModel = {
  entities: {},
  entitiesPendingDelete: {},

  getTooltipById: computed(
    (state): ((pointId: IUniqueId['id']) => TTooltip) =>
      (id): TTooltip =>
        state.entities[id]
  ),

  items: computed(
    [(state): typeof state.entities => state.entities],
    (tooltipEntities): TTooltip[] => Object.values(tooltipEntities)
  ),

  show: action((state, payload): void => {
    const id = payload.id || makeUniqueId();

    const isAlreadyShowing = Boolean(state.entities[id]);
    if (!isAlreadyShowing) {
      state.entities[id] = { id, ...payload };
    }

    const isPendingDelete = Boolean(state.entitiesPendingDelete[id]);
    if (isPendingDelete) {
      const timerId = state.entitiesPendingDelete[id];
      clearTimeout(timerId);

      delete state.entitiesPendingDelete[id];
    }
  }),

  hide: action((state, payload): void => {
    delete state.entities[payload.id];
  }),

  removeFromPendingDelete: action((state, payload): void => {
    const timerId = state.entitiesPendingDelete[payload.id];
    clearTimeout(timerId);

    delete state.entitiesPendingDelete[payload.id];
  }),

  addToPendingDelete: action((state, payload): void => {
    state.entitiesPendingDelete[payload.id] = payload.timerId;
  }),

  hideWithDelay: thunk((actions, payload, helpers): void => {
    const { id, delay = DEFAULT_DELAY } = payload;
    const state = helpers.getState();

    const isPendingDelete = Boolean(state.entitiesPendingDelete[id]);
    if (isPendingDelete) {
      actions.removeFromPendingDelete({ id });
    }

    const timerId = setTimeout((): void => {
      actions.hide({ id });
      actions.removeFromPendingDelete({ id });
    }, delay);

    actions.addToPendingDelete({ id, timerId });
  }),

  onChanging: actionOn(
    (
      _,
      rootState
    ): [
      typeof rootState.canvas.changeZoom,
      typeof rootState.points.update,
      typeof rootState.points.delete
    ] => [rootState.canvas.changeZoom, rootState.points.update, rootState.points.delete],
    (state): void => {
      state.entities = {};
      state.entitiesPendingDelete = {};
    }
  )
};
