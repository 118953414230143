import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgUnion(props: IProps): JSX.Element {
  return (
    <svg
      width={28}
      height={31}
      viewBox="0 0 28 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14c0 4.5-2.046 7.62-4.73 10.143-1.328 1.25-2.825 2.366-4.308 3.433l-.872.624c-1.197.855-2.364 1.69-3.46 2.577a1 1 0 01-1.26 0c-1.096-.887-2.263-1.722-3.46-2.576l-.872-.625c-1.483-1.067-2.98-2.183-4.309-3.433C2.046 21.619 0 18.499 0 14z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgUnion;
