import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgArrowLeftSmall(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.53 5.53a.75.75 0 00-1.06-1.06l-3 3a.75.75 0 000 1.06l3 3a.75.75 0 001.06-1.06L5.81 8.75H13a.75.75 0 000-1.5H5.81l1.72-1.72z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgArrowLeftSmall;
