import * as React from 'react';

// Hooks
import { useDidMount } from '@src/ui/hooks';
import { useStoreActions } from '@src/store/store';

// Container Components
import { SendPush } from '@src/ui/features/SendPush';
import { FormEditingPointEvent } from '@src/containers/components/Form/EditingPointEvent';
import { WaitUntil } from '@src/ui/features/WaitUntil';
import { SendEmail } from '@src/ui/features/SendEmail';
import { FormEditingBooleanSplitter } from '@src/containers/components/Form/EditingPointBooleanSplitter';
import { WaitEvent } from '@src/ui/features/WaitEvent';
import { SendInApp } from '@src/ui/features/SendInApp';
import { StartSegment } from '@src/ui/features/StartSegment';
import { ABSlitter } from '@src/ui/features/ABSplitter';
import { SetTags } from '@src/ui/features/SetTags';
import { Webhook } from '@src/ui/features/Webhook';
import { SendSms } from '@src/ui/features/SendSms';
import { ApiEntry } from '@src/ui/features/ApiEntry';

// Enums
import { PointType } from '@src/common/constants/point';

// Types
import { useGetPointById } from '@src/store/hooks';
import { IUniqueId } from '@src/common/types/entities';

import { TDocumentationMenuRoutePath } from '@src/store/models/documentationMenu/documentationMenu.types';
import { ReachabilityCheck } from '@src/ui/features/ReachabilityCheck';
import { useCloseModalByType } from '@src/store/hooks/modals/useCloseModalByType';
import { ModalType } from '@src/common/constants';

interface IModalEditingPointProps {
  pointId: IUniqueId['id'];
}

function EditDataPoint(props: IModalEditingPointProps): JSX.Element {
  const { pointId } = props;

  const getPointById = useGetPointById();
  const closeModalByType = useCloseModalByType();
  const point = getPointById(pointId);

  const closeModal = () => closeModalByType(ModalType.EDITING_FORM_POINT);

  const setRouteDocumentationMenu = useStoreActions(
    (actions) => actions.documentationMenu.setRoutePath
  );

  useDidMount((): void => {
    setRouteDocumentationMenu(`/point-${point.type}` as TDocumentationMenuRoutePath);
  });

  switch (point.type) {
    case PointType.WAIT: {
      return <WaitUntil pointId={pointId} closeModal={closeModal} />;
    }

    case PointType.EVENT: {
      return <FormEditingPointEvent pointId={pointId} closeModal={closeModal} />;
    }

    case PointType.SEGMENT: {
      return <StartSegment pointId={pointId} closeModal={closeModal} />;
    }

    case PointType.API: {
      return <ApiEntry pointId={pointId} closeModal={closeModal} />;
    }

    case PointType.SEND_EMAIL: {
      return <SendEmail pointId={pointId} closeModal={closeModal} />;
    }

    case PointType.SEND_PUSH: {
      return <SendPush pointId={pointId} closeModal={closeModal} />;
    }

    case PointType.BOOLEAN_SPLITTER: {
      return <FormEditingBooleanSplitter pointId={pointId} closeModal={closeModal} />;
    }

    case PointType.AB_SPLITTER: {
      return <ABSlitter pointId={pointId} closeModal={closeModal} />;
    }

    case PointType.WAIT_EVENT: {
      return <WaitEvent pointId={pointId} closeModal={closeModal} />;
    }

    case PointType.SEND_IN_APP: {
      return <SendInApp pointId={pointId} closeModal={closeModal} />;
    }

    case PointType.SET_TAGS: {
      return <SetTags pointId={pointId} closeModal={closeModal} />;
    }

    case PointType.WEBHOOK: {
      return <Webhook pointId={pointId} closeModal={closeModal} />;
    }

    case PointType.SEND_SMS: {
      return <SendSms pointId={pointId} closeModal={closeModal} />;
    }

    case PointType.REACHABILITY_CHECK: {
      return <ReachabilityCheck pointId={pointId} closeModal={closeModal} />;
    }

    default: {
      return null;
    }
  }
}

export default React.memo(EditDataPoint);
