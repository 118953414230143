// Store
import { useStoreState } from '@src/store/store';

// Types
import { TPoints } from '@src/common/types/points';

export function useAvailablePoints(): TPoints[] {
  const availablePoints = useStoreState((state): TPoints[] => state.points.availablePoints);

  return availablePoints;
}
