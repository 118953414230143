import * as React from 'react';
import { Color } from '@pushwoosh/kit-constants';

// Components
import { Icon, IconEnum } from '../Icon';

// Styled
import { Container, IconSearch, IconResetContainer, Input } from './styled';

// Types
import { InputAsyncProps } from './InputAsync.types';

export const InputAsync = (props: InputAsyncProps): JSX.Element => {
  const {
    wait = false,
    afterIdle = 1000,
    isDisabled = false,
    withIcon = true,
    value: valueProps,
    onChange,
    onBlur,
    placeholder
  } = props;
  const [value, setValue] = React.useState(valueProps ?? '');

  const timerId = React.useRef(null);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      clearTimeout(timerId.current);
      const { value: valueInput } = event.currentTarget;
      setValue(valueInput);

      timerId.current = setTimeout((): void => {
        if (typeof onChange === 'function') {
          onChange(valueInput);
        }
      }, afterIdle);
    },
    [timerId, afterIdle, onChange]
  );

  const handleReset = React.useCallback(() => {
    setValue('');
    if (typeof onChange === 'function') {
      onChange('');
    }
  }, [onChange]);

  const isShowReset = !wait && !isDisabled;
  const isInputDisabled = isDisabled || wait;

  return (
    <Container isDisabled={isInputDisabled}>
      {withIcon && (
        <IconSearch
          type={IconEnum.SEARCH_MEDIUM_LINED}
          fill={isDisabled ? Color.LOCKED : Color.PHANTOM}
        />
      )}
      <Input
        value={value}
        placeholder={placeholder || (isInputDisabled ? '' : 'Search by name…')}
        onChange={handleChange}
        disabled={isInputDisabled}
        onBlur={onBlur}
      />
      {isShowReset && (
        <IconResetContainer onClick={handleReset}>
          <Icon type={IconEnum.CLOSE_SMALL} fill={Color.PHANTOM} />
        </IconResetContainer>
      )}
    </Container>
  );
};
