import { Color } from '@pushwoosh/kit-constants';

export const getArrowColor = (
  color: string,
  removeButtonHovered: boolean,
  isMouseEnter: boolean
) => {
  if (removeButtonHovered) {
    return Color.DANGER;
  }

  if (isMouseEnter) {
    return Color.PRIMARY_HOVER;
  }

  return color;
};
