import { TimePeriod } from '@src/common/constants/stats';
import { PeriodSettings } from '@src/common/types/statistics';
import { last30Days, thisMonth, thisWeek, today, yesterday, allTime, customRange } from './presets';

export function getPreset(period: TimePeriod, createdAt: string): PeriodSettings {
  switch (period) {
    case TimePeriod.ALL_TIME: {
      return allTime(createdAt);
    }

    case TimePeriod.CUSTOM_RANGE: {
      return customRange();
    }

    case TimePeriod.LAST_30_DAYS: {
      return last30Days();
    }

    case TimePeriod.THIS_MONTH: {
      return thisMonth();
    }

    case TimePeriod.THIS_WEEK: {
      return thisWeek();
    }

    case TimePeriod.TODAY: {
      return today();
    }

    case TimePeriod.YESTERDAY: {
      return yesterday();
    }

    default: {
      return allTime(createdAt);
    }
  }
}
