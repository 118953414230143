import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';

// Components
import { Text as TextComponent } from '@src/ui/atoms/Text';

export const Container = styled.div`
  width: 100%;
`;

export const Text = styled(TextComponent)`
  display: block;
  margin-bottom: ${Spacing.S5};
`;

export const OptionsWrap = styled.div`
  margin-top: ${Spacing.S5};
`;
