import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgHelpRoundMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.265 8.191A2 2 0 0010.04 9.5.75.75 0 118.625 9a3.5 3.5 0 016.802 1.166c0 1.315-.977 2.198-1.71 2.686-.381.255-.758.444-1.04.57v.077a.75.75 0 11-1.5 0v-.583a.75.75 0 01.511-.711l.002-.001.016-.006a5.4 5.4 0 00.345-.14c.233-.103.536-.255.835-.454.642-.428 1.04-.92 1.04-1.438v-.001a2 2 0 00-1.661-1.975z"
        fill={props.fill}
      />
      <path d="M12 17a1 1 0 100-2 1 1 0 000 2z" fill={props.fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.473 2 2 6.473 2 12s4.473 10 10 10 10-4.473 10-10S17.527 2 12 2zm0 18.438A8.433 8.433 0 013.562 12 8.433 8.433 0 0112 3.562 8.433 8.433 0 0120.438 12 8.433 8.433 0 0112 20.438z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgHelpRoundMediumLined;
