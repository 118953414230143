import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgBookmarkSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9.323l.67.335 1.83.915V4.5h-5v6.073l1.83-.915.67-.335zM8 11l2.553 1.276A1 1 0 0012 11.382V4a1 1 0 00-1-1H5a1 1 0 00-1 1v7.382a1 1 0 001.447.894L8 11z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgBookmarkSmallLined;
