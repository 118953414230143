import * as React from 'react';

// Components
import { DocElements } from '@src/ui/features/Documentation/components/DocElements';

export function DocumentationPointWaitForEvent(): JSX.Element {
  return (
    <DocElements>
      <DocElements.Paragraph>
        <strong>Wait for Trigger </strong>
        {
          'element lets you set different communication scenarios based on whether a user triggers a particular event within specified time period or not. For example, to recover the abandoned shopping cart, set up the'
        }
        <strong> Wait for Trigger </strong>
        {'element as follows:'}
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-point-wait-for-event_gif-1.gif"
        label="Adding Wait for Trigger element"
        alt="Adding Wait for Trigger element"
      />

      <DocElements.Paragraph>
        {
          "Users who add some products to their cart and purchase within five minutes will exit the journey right after the CheckoutSuccess event is triggered and won't receive any messages. To those users who don't purchase in five minutes after the entry event, you can send a message reminding of items in their carts."
        }
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        {'You can add several events in a single'}
        <strong> Wait for Trigger </strong>
        {'element, and specify attributes for each of them. User who triggered'}
        <strong> any of the events specified </strong>
        {'will follow the [Triggered] branch of the journey.'}
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-point-wait-for-event_img-1.png"
        alt="documentation-point-wait-for-event_img-1"
      />
    </DocElements>
  );
}
