import { PointType } from '@src/common/constants';
import { TRestrictions } from '@src/common/types/restrictions';

export function getPremiumFeatureAvailableByType(
  type: PointType,
  restrictions: TRestrictions
): boolean {
  switch (type) {
    case PointType.WEBHOOK: {
      return restrictions.allowCjWebhook;
    }
    case PointType.SET_TAGS: {
      return restrictions.allowCjSetTags;
    }
    case PointType.SEND_IN_APP: {
      return restrictions.allowCjInapp;
    }
    case PointType.API: {
      return restrictions.allowCjApiEntry;
    }
    case PointType.SEND_SMS: {
      return restrictions.allowCjSMS;
    }
    default: {
      return false;
    }
  }
}
