// Constants
import { JourneyStatus } from '@src/common/constants/journey';

import { useEditJourney } from './useEditJourney';
import { useConfirmActivateJourney } from './useConfirmActivateJourney';
import { useRenameJourney } from './useRenameJourney';
import { useCloneJourney } from './useCloneJourney';
import { useConfirmDeleteJourney } from './useConfirmDeleteJourney';
import { useConfirmArchiveJourney } from './useConfirmArchiveJourney';
import { useConfirmDeactivateJourney } from './useConfirmDeactivateJourney';
import { useConfirmPauseJourney } from './useConfirmPauseJourney';

type JourneyHandlersOptions = {
  status: JourneyStatus;
  id: string;
  title: string;
  beforeLoadJourney: boolean;
  onReloadJourneys?: () => void;
  afterDeleteJourney?: () => void;
};

type JourneyHandlers = {
  handleButtonEditClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleButtonCloneClick: () => void;
  handleButtonStartClick: () => void;
  handleButtonRenameClick: () => void;
  handleButtonDeleteClick: () => void;
  handleButtonFinishClick: () => void;
  handleButtonArchiveClick?: () => void;
  handleButtonPauseClick?: () => void;
};

export const useJourneyHandlers = (options: JourneyHandlersOptions): JourneyHandlers => {
  const { status, id, title, beforeLoadJourney, onReloadJourneys, afterDeleteJourney } = options;

  const { handleButtonEditClick } = useEditJourney(status, id);
  const handleButtonCloneClick = useCloneJourney(id);
  const handleButtonStartClick = useConfirmActivateJourney(id);
  const handleButtonRenameClick = useRenameJourney(id, status, title, beforeLoadJourney);
  const handleButtonDeleteClick = useConfirmDeleteJourney(id, afterDeleteJourney);
  const handleButtonFinishClick = useConfirmDeactivateJourney(id, title);
  const handleButtonArchiveClick = useConfirmArchiveJourney({ id, title, onReloadJourneys });
  const handleButtonPauseClick = useConfirmPauseJourney(id);

  return {
    handleButtonEditClick,
    handleButtonCloneClick,
    handleButtonStartClick,
    handleButtonRenameClick,
    handleButtonDeleteClick,
    handleButtonFinishClick,
    handleButtonPauseClick,
    ...(onReloadJourneys ? { handleButtonArchiveClick } : {})
  };
};
