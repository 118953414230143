import { action } from 'easy-peasy';

// Types
import { TDocumentationMenuRoutePath, IDocumentationMenuModel } from './documentationMenu.types';

const mainRoutePath: TDocumentationMenuRoutePath = '/';

export const model: IDocumentationMenuModel = {
  isVisible: false,
  routePath: mainRoutePath,

  show: action((state): void => {
    state.isVisible = true;
  }),

  close: action((state): void => {
    state.isVisible = false;
    state.routePath = mainRoutePath;
  }),

  setRoutePath: action((state, payload): void => {
    state.routePath = payload;
  })
};
