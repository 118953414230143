// Store
import { useStoreActions, useStoreState } from '@src/store/store';

// Types
import { TJourney } from '@src/common/types/journey';
import { TPoints } from '@src/common/types/points';
import { TRequest } from '@src/store/models/requests/requests.types';

type TActionCreate = (payload?: {
  points: TPoints[];
  title: string;
  applicationCode: string;
}) => Promise<TJourney>;
type TResult = [TActionCreate, boolean];

export function useCreateJourney(): TResult {
  const create: TActionCreate = useStoreActions(
    (actions): TActionCreate => actions.journeys.create
  );

  const request: TRequest = useStoreState(
    (state): TRequest => state.requests.entities['journeys.create']
  );

  return [create, Boolean(request)];
}
