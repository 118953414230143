import React from 'react';

import { Color } from '@pushwoosh/kit-constants';
import { H4 } from '@pushwoosh/kit-typography';

// Components
import { Icon, IconEnum } from '../Icon';
import { Link } from '../Link';

// Types
import { FormFieldProps } from './FormField.types';

// Styled
import { FieldValue, ValueWrap, FieldWrap } from './styled';

export function FormField(props: FormFieldProps): JSX.Element {
  const { label, value, url, direction = 'row', onClick } = props;

  return (
    <FieldWrap direction={direction}>
      <H4>{label}</H4>
      <ValueWrap direction={direction}>
        {(!url || onClick) && (
          <FieldValue onClick={onClick} style={{ color: Color.PRIMARY, cursor: 'pointer' }}>
            {value}
          </FieldValue>
        )}
        {!url && !onClick && <FieldValue>{value}</FieldValue>}
        {!onClick && url && (
          <>
            <Link isExternal to={url} target="_blanc">
              <FieldValue>{value}</FieldValue>
            </Link>
            <a href={url} target="_blanc">
              <Icon type={IconEnum.OPEN_SMALL_LINED} fill={Color.PRIMARY} />
            </a>
          </>
        )}
      </ValueWrap>
    </FieldWrap>
  );
}
