import { createContext, useContext } from 'react';

import { EventBus } from '@src/common/libs/EventBus';

const BusContext = createContext<EventBus<Record<string, any>> | null>(null);
export const BusProvider = BusContext.Provider;

export function useBus(): EventBus<Record<string, any>> {
  const bus = useContext(BusContext);

  return bus;
}
