import * as React from 'react';
import { Text } from 'react-konva';

// Types
interface IProps {
  children: React.ReactNode;
  x?: number;
  y?: number;
  fill?: string;
}

export function Label(props: IProps): JSX.Element {
  const { children, x = 0, y = 0, fill = '#555555' } = props;

  return (
    <Text
      x={x}
      y={y}
      perfectDrawEnabled={false}
      shadowForStrokeEnabled={false}
      fontFamily="Roboto"
      fontSize={14}
      fontStyle="700"
      text={children as string}
      fill={fill}
    />
  );
}
