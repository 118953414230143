import * as React from 'react';
import { Color } from '@pushwoosh/kit-constants';
import { Icon, IconEnum } from '@src/ui/kit';
import { format } from 'date-fns';

// Helpers
import { getPercentageOfTotal } from '@src/ui/helpers';

// Constants
import {
  OperatorTypeEnum,
  OperatorTypesHumans,
  AttributeTypeEnum
} from '@src/common/constants/attribute';

// Styled
import {
  GoalItem,
  GoalData,
  GoalMainInfo,
  GoalTitle,
  GoalLabelExit,
  GoalMeta,
  GoalStatistics,
  GoalTotalAchieved,
  GoalPercentAchieved
} from './styled';

// Types
import { IDetailPointGoalEventProps } from './DetailPointGoalEvent.types';

export function DetailPointGoalEvent(props: IDetailPointGoalEventProps): JSX.Element {
  const {
    applicationCode,
    applicationName,
    eventName,
    eventConditions = [],
    isThrowOut,
    achievedGoal,
    totalAchievedGoal
  } = props;

  return (
    <GoalItem>
      <GoalData>
        <GoalMainInfo>
          <GoalTitle>{eventName}</GoalTitle>
          {isThrowOut && (
            <>
              <Icon type={IconEnum.FINISH_SMALL_LINED} fill={Color.SOFT} />
              <GoalLabelExit>Exit after the event</GoalLabelExit>
            </>
          )}
        </GoalMainInfo>
        {eventConditions.length > 0 && (
          <GoalMeta>
            Conditions: &nbsp;
            {eventConditions.map((item, index): JSX.Element => {
              let value = '';
              if (item.operator === OperatorTypeEnum.BETWEEN) {
                if (item.type === AttributeTypeEnum.DATE) {
                  value = (item.value as [Date, Date])
                    .map((date): string => format(new Date(date), 'yyyy-MM-dd'))
                    .join(' and ');
                }

                if (!value) {
                  value = (item.value as [string, string]).join(' and ');
                }
              }

              if (!value && item.type === AttributeTypeEnum.DATE) {
                value = format(new Date(item.value as string), 'yyyy-MM-dd');
              }

              if (!value && Array.isArray(item.value)) {
                value = (item.value as string[]).join(', ');
              }

              if (!value) {
                value = item.value?.toString() ?? '';
              }

              return (
                <span key={index}>
                  {`${item.attributeName} ${OperatorTypesHumans[item.operator]} ${value}`}
                  {index !== eventConditions.length - 1 ? ' and ' : ''}
                </span>
              );
            })}
          </GoalMeta>
        )}
        <GoalMeta>
          Application: {applicationName} - {applicationCode}
        </GoalMeta>
      </GoalData>
      <GoalStatistics>
        <GoalPercentAchieved>
          {getPercentageOfTotal(totalAchievedGoal, achievedGoal)}
        </GoalPercentAchieved>
        <GoalTotalAchieved>{achievedGoal}</GoalTotalAchieved>
      </GoalStatistics>
    </GoalItem>
  );
}
