// Hooks Store
import {
  useComputedGetPointStatByPointId,
  useGetApplicationByCode,
  useGetEmailByAppCodeAndEmailCode,
  useGetPointById,
  useGetPresetByAppCodeAndPresetCode,
  useGetRichMediaContent,
  usePointOutputsStats
} from '@src/store/hooks';

// Enums
import { PointType } from '@src/common/constants/point';

// Types
import { IUniqueId } from '@src/common/types/entities';
import {
  TABSplitterPoint,
  TEventPoint,
  TSegmentPoint,
  TSendEmailPoint,
  TSendPush,
  TSendSms,
  TWaitEvent,
  TWaitPoint,
  TWebhookPoint
} from '@src/common/types/points';
import { TEmail } from '@src/common/types/email';
import { TApplication } from '@src/common/types/application';
import { TEvent } from '@src/common/types/event';
import { TPreset } from '@src/common/types/preset';
import { TFilter } from '@src/common/types/filter';
import { UpdateTag } from '@src/common/types/tag';
import { TRichMedia } from '@src/common/types/richMedia';
import {
  TPointABStatistics,
  TPointApiEntryStatistics,
  TPointEventStatistics,
  TPointSendPushStatistics,
  TPointSendSmsStatistics
} from '@src/common/types/statistics';

interface IDetailedWaitPointInfo {
  type: PointType.WAIT;
  delay?: TWaitPoint['data']['delay'];
  exactTime: TWaitPoint['data']['exactTime'];
  dynamicTime: TWaitPoint['data']['dynamicTime'];
  pointTitle: string;
  exactDateTime: TWaitPoint['data']['exactDateTime'];
  exactWeekDaysTime: TWaitPoint['data']['exactWeekDaysTime'];
}

interface TDetailerApiEntryInfo {
  type: PointType.API;
  applicationCode?: TApplication['code'];
  pointTitle: string;
  attributes: string[];
  entries: TPointApiEntryStatistics['pointStat']['entries'];
  launches: TPointApiEntryStatistics['pointStat']['launches'];
}

interface IDetailedSendPushPointInfo {
  type: PointType.SEND_PUSH;
  pointTitle: string;
  applicationCode?: TApplication['code'];
  applicationName?: TApplication['title'];
  campaignCode?: string;
  content?: TPreset['content'];
  title?: TPreset['title'];
  presetCode?: TPreset['code'];
  sent: TPointSendPushStatistics['pointStat']['sent'];
  opened: TPointSendPushStatistics['pointStat']['opened'];
  conversion: TPointSendPushStatistics['pointStat']['conversion'];
  behaviorSplitterEnabled: TSendPush['data']['behaviorSplitter']['enabled'];
  behaviorSplitterInterval: TSendPush['data']['behaviorSplitter']['interval'];
  enabledPersonalise: TSendPush['data']['personalise']['enabled'];
  placeholders: TSendPush['data']['personalise']['placeholders'];
  pointOutputStat: TPointSendPushStatistics['pointOutputStat'];
  waitOptimalTime: TSendPush['data']['waitOptimalTime'];
  defaultOptimalTime: TSendPush['data']['defaultOptimalTime'];
}

interface IDetailedSendSmsPointInfo {
  type: PointType.SEND_SMS;
  pointTitle: string;
  applicationCode?: TApplication['code'];
  applicationName?: TApplication['title'];
  campaignCode?: string;
  content?: TPreset['content'];
  sent: TPointSendSmsStatistics['pointStat']['sent'];
  delivered: TPointSendSmsStatistics['pointStat']['delivered'];
  behaviorSplitterEnabled: TSendSms['data']['smsBehaviorSplitter']['enabled'];
  behaviorSplitterInterval: TSendSms['data']['smsBehaviorSplitter']['interval'];
  enabledPersonalise: TSendPush['data']['personalise']['enabled'];
  placeholders: TSendPush['data']['personalise']['placeholders'];
}

interface IDetailedEventPointInfo {
  type: PointType.EVENT;
  applicationCode?: TApplication['code'];
  applicationName?: TApplication['title'];
  eventConditions?: TEventPoint['data']['eventConditions'];
  eventName?: TEvent['name'];
  pointTitle: string;
  stat: TPointEventStatistics['pointOutputStat'];
}

interface IDetailedSegmentPointInfo {
  type: PointType.SEGMENT;
  applicationTitle: TApplication['title'];
  filterTitle: TFilter['name'];
  entryLimitsEnabled: TSegmentPoint['data']['entryLimitsEnabled'];
  entryLimitsPerSecond: TSegmentPoint['data']['entryLimitsPerSecond'];
  repeat: TSegmentPoint['data']['repeat'];
  pointTitle: string;
  filterCode: TFilter['code'];
  applicationId: string;
}

interface IDetailedSetTagsPointInfo {
  type: PointType.SET_TAGS;
  applicationCode?: TApplication['code'];
  applicationTitle?: TApplication['title'];
  tags: UpdateTag[];
  pointTitle: string;
}

interface IDetailedWebhookPointInfo {
  type: PointType.WEBHOOK;
  url?: TWebhookPoint['data']['url'];
  headers?: TWebhookPoint['data']['headers'];
  requestTemplate: TWebhookPoint['data']['request_template'];
  pointTitle: string;
}

interface IDetailedSendEmailPointInfo {
  type: PointType.SEND_EMAIL;
  applicationCode?: TApplication['code'];
  applicationName?: TApplication['title'];
  presetCode?: TEmail['code'];
  presetName?: TEmail['name'];
  campaignCode?: string;
  presetSubjectEn?: string;
  pointTitle: string;
  enabledPersonalise: TSendEmailPoint['data']['personalise']['enabled'];
  sendOnlyToSubscribed: TSendEmailPoint['data']['sendOnlyToSubscribed'];
  behaviorSplitterEnabled: TSendEmailPoint['data']['emailBehaviorSplitter']['enabled'];
  behaviorSplitterInterval: TSendEmailPoint['data']['emailBehaviorSplitter']['interval'];
  placeholders: TSendEmailPoint['data']['personalise']['placeholders'];
}

interface IDetailedBooleanSplitterPointInfo {
  type: PointType.BOOLEAN_SPLITTER;
  filterCode?: TFilter['code'];
  filterName?: TFilter['name'];
  pointTitle: string;
}

interface IDetailedReachabilityCheckPointInfo {
  type: PointType.REACHABILITY_CHECK;
  filterType: string;
}

interface IDetailedABSplitterPointInfo {
  type: PointType.AB_SPLITTER;
  split: TABSplitterPoint['data']['split'];
  statistics: TPointABStatistics['pointStat'][];
  pointTitle: string;
}

type TExtendedWaitEvent = TWaitEvent & { applicationName: string; id?: string };

interface IDetailedWaitEventPointInfo {
  type: PointType.WAIT_EVENT;
  waitEvents: TExtendedWaitEvent[];
  waitScript: string;
  pointTitle: string;
}

interface IDetailSendInAppPointInfo {
  type: PointType.SEND_IN_APP;
  richMedia?: TRichMedia;
  pointTitle: string;
  expireInDays: number;
  campaignCode?: string;
}

type TDetailedPointInfo =
  | IDetailedWaitPointInfo
  | IDetailedSendPushPointInfo
  | IDetailedSendSmsPointInfo
  | IDetailedEventPointInfo
  | IDetailedSegmentPointInfo
  | TDetailerApiEntryInfo
  | IDetailedSendEmailPointInfo
  | IDetailedBooleanSplitterPointInfo
  | IDetailedWaitEventPointInfo
  | IDetailSendInAppPointInfo
  | IDetailedABSplitterPointInfo
  | IDetailedSetTagsPointInfo
  | IDetailedWebhookPointInfo
  | IDetailedReachabilityCheckPointInfo;

export function useDetailedPointInfo(pointId: IUniqueId['id']): TDetailedPointInfo | null {
  const getPointById = useGetPointById();
  const point = getPointById(pointId);
  const getApplicationByCode = useGetApplicationByCode();
  const getPresetByAppCodeAndPresetCode = useGetPresetByAppCodeAndPresetCode();
  const getEmailByAppCodeAndEmailCode = useGetEmailByAppCodeAndEmailCode();
  const getRichMediaContent = useGetRichMediaContent();
  const getPointStatByPointId = useComputedGetPointStatByPointId();
  const pointOutputsStatistics = usePointOutputsStats();

  switch (point.type) {
    case PointType.WAIT: {
      const { delay, exactTime, dynamicTime, exactDateTime, exactWeekDaysTime } = point.data;

      return {
        type: PointType.WAIT,
        delay,
        exactTime: exactTime || null,
        pointTitle: point.title,
        dynamicTime,
        exactDateTime,
        exactWeekDaysTime
      };
    }

    case PointType.SEGMENT: {
      const {
        applicationTitle,
        filterTitle,
        entryLimitsEnabled,
        entryLimitsPerSecond,
        repeat,
        filterCode,
        applicationCode
      } = point.data;

      return {
        type: PointType.SEGMENT,
        applicationTitle,
        filterTitle,
        entryLimitsEnabled,
        entryLimitsPerSecond,
        repeat,
        pointTitle: point.title,
        filterCode,
        applicationId: applicationCode
      };
    }

    case PointType.API: {
      const { applicationCode, attributes } = point.data;

      const { pointStat } = getPointStatByPointId(pointId) as TPointApiEntryStatistics;

      const application = getApplicationByCode(applicationCode);

      return {
        type: PointType.API,
        applicationCode: application.code,
        pointTitle: point.title,
        entries: pointStat?.entries || 0,
        launches: pointStat?.launches || 0,
        attributes
      };
    }

    case PointType.SET_TAGS: {
      const { applicationCode, tags } = point.data;
      const application = getApplicationByCode(applicationCode);

      const applicationInfo =
        application !== undefined
          ? {
              applicationCode: application.code,
              applicationTitle: application.title
            }
          : {};

      return {
        type: PointType.SET_TAGS,
        ...applicationInfo,
        tags,
        pointTitle: point.title
      };
    }

    case PointType.SEND_PUSH: {
      const {
        applicationCode,
        presetCode,
        content,
        campaignCode,
        behaviorSplitter,
        personalise,
        title
      } = point.data;

      const { pointStat, pointOutputStat } = getPointStatByPointId(
        pointId
      ) as TPointSendPushStatistics;

      const application = getApplicationByCode(applicationCode);
      const preset = getPresetByAppCodeAndPresetCode(applicationCode, presetCode);

      const applicationInfo =
        application !== undefined
          ? {
              applicationCode: application.code,
              applicationName: application.title
            }
          : {};

      const pushPresetInfo =
        preset !== null
          ? {
              presetCode: preset.code,
              content: preset.content,
              title: preset.title || ''
            }
          : { content, title: title || '' };

      return {
        type: PointType.SEND_PUSH,
        campaignCode,
        sent: pointStat.sent,
        opened: pointStat.opened,
        conversion: pointStat.conversion,
        behaviorSplitterEnabled: behaviorSplitter.enabled,
        behaviorSplitterInterval: behaviorSplitter.interval,
        pointTitle: point.title,
        enabledPersonalise: personalise?.enabled ?? false,
        waitOptimalTime: point.data.waitOptimalTime ?? false,
        defaultOptimalTime: point.data.defaultOptimalTime,
        placeholders: personalise?.placeholders ?? [],
        pointOutputStat,
        ...applicationInfo,
        ...pushPresetInfo
      };
    }

    case PointType.SEND_SMS: {
      const {
        applicationCode,
        presetCode,
        content,
        campaignCode,
        smsBehaviorSplitter,
        personalise
      } = point.data;

      const { pointStat } = getPointStatByPointId(pointId) as TPointSendSmsStatistics;

      const application = getApplicationByCode(applicationCode);
      const preset = getPresetByAppCodeAndPresetCode(applicationCode, presetCode);

      const applicationInfo =
        application !== undefined
          ? {
              applicationCode: application.code,
              applicationName: application.title
            }
          : {};

      const pushPresetInfo =
        preset !== null
          ? {
              content: preset.content
            }
          : { content };

      return {
        type: PointType.SEND_SMS,
        campaignCode,
        sent: pointStat.sent,
        delivered: pointStat.delivered,
        behaviorSplitterEnabled: smsBehaviorSplitter.enabled,
        behaviorSplitterInterval: smsBehaviorSplitter.interval,
        pointTitle: point.title,
        enabledPersonalise: personalise?.enabled ?? false,
        placeholders: personalise?.placeholders ?? [],
        ...applicationInfo,
        ...pushPresetInfo
      };
    }

    case PointType.EVENT: {
      const { applicationCode, eventConditions, name } = point.data;

      const application = getApplicationByCode(applicationCode);
      const { pointOutputStat } = getPointStatByPointId(pointId) as TPointEventStatistics;

      const applicationInfo =
        application !== undefined
          ? {
              applicationCode: application.code,
              applicationName: application.title
            }
          : {};

      return {
        type: PointType.EVENT,
        eventConditions,
        eventName: name,
        pointTitle: point.title,
        stat: pointOutputStat,
        ...applicationInfo
      };
    }

    case PointType.WAIT_EVENT: {
      const { waitEvents } = point.data;

      const transformWaitEvents = waitEvents.map((item) => {
        const application = getApplicationByCode(item.applicationCode);

        return {
          ...item,
          applicationName: application && application.title ? application.title : 'unknown'
        };
      });

      return {
        type: PointType.WAIT_EVENT,
        waitEvents: transformWaitEvents,
        pointTitle: point.title,
        waitScript: point.data.waitEventsConditionsScript
      };
    }

    case PointType.SEND_EMAIL: {
      const { applicationCode, presetCode, campaignCode, personalise } = point.data;

      const application = getApplicationByCode(applicationCode);
      const emailPreset = getEmailByAppCodeAndEmailCode(applicationCode, presetCode);

      const applicationInfo =
        application !== undefined
          ? {
              applicationCode: application.code,
              applicationName: application.title
            }
          : {};

      const emailPresetInfo =
        emailPreset !== null
          ? {
              presetCode: emailPreset.code,
              presetName: emailPreset.name,
              presetSubjectEn: emailPreset.subjects.en
            }
          : {};

      return {
        type: PointType.SEND_EMAIL,
        campaignCode,
        pointTitle: point.title,
        enabledPersonalise: personalise?.enabled ?? false,
        placeholders: personalise?.placeholders ?? [],
        behaviorSplitterEnabled: point.data?.emailBehaviorSplitter?.enabled ?? false,
        behaviorSplitterInterval: point.data?.emailBehaviorSplitter?.interval,
        sendOnlyToSubscribed:
          point.data?.sendOnlyToSubscribed === undefined ? true : point.data?.sendOnlyToSubscribed,
        ...applicationInfo,
        ...emailPresetInfo
      };
    }

    case PointType.BOOLEAN_SPLITTER: {
      const { name, filterCode } = point.data;

      return {
        type: PointType.BOOLEAN_SPLITTER,
        filterCode,
        pointTitle: point.title,
        filterName: name
      };
    }

    case PointType.AB_SPLITTER: {
      const { split } = point.data;
      const statistics = pointOutputsStatistics
        .filter((item) => item.fromPointId === point.id)
        .map((item) => ({
          key: item.key,
          count: item.count
        }));

      return {
        type: PointType.AB_SPLITTER,
        split,
        pointTitle: point.title,
        statistics
      };
    }

    case PointType.SEND_IN_APP: {
      const { applicationCode, richMediaCode, campaignCode } = point.data;

      const richMedia = getRichMediaContent(applicationCode, richMediaCode);

      return {
        type: PointType.SEND_IN_APP,
        pointTitle: point.title,
        expireInDays: point.data.expireInDays,
        campaignCode,
        richMedia
      };
    }

    case PointType.WEBHOOK: {
      const { url, headers, request_template: requestTemplate } = point.data;

      return {
        type: PointType.WEBHOOK,
        url,
        headers,
        pointTitle: point.title,
        requestTemplate
      };
    }

    case PointType.REACHABILITY_CHECK: {
      const { filterType } = point.data;

      return {
        type: PointType.REACHABILITY_CHECK,
        filterType
      };
    }

    default: {
      return null;
    }
  }
}
