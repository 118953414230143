import * as React from 'react';
import { Group, Path } from 'react-konva';

interface IPropsPointInfoBadge {
  x?: number;
  y?: number;
}

function PointInfoBadge(props: IPropsPointInfoBadge): JSX.Element {
  const { x = 0, y = 0 } = props;

  return (
    <Group x={x} y={y} perfectDrawEnabled={false}>
      <Path
        perfectDrawEnabled={false}
        fill="#EB2F5B"
        shadowForStrokeEnabled={false}
        data="M1 5C1 2.79086 2.79086 1 5 1H11C13.2091 1 15 2.79086 15 5V11C15 13.2091 13.2091 15 11 15H5C2.79086 15 1 13.2091 1 11V5Z"
      />
      <Path
        fill="white"
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
        data="M0 5C0 2.23858 2.23858 0 5 0H11C13.7614 0 16 2.23858 16 5V11C16 13.7614 13.7614 16 11 16H5C2.23858 16 0 13.7614 0 11V5ZM5 2C3.34315 2 2 3.34315 2 5V11C2 12.6569 3.34315 14 5 14H11C12.6569 14 14 12.6569 14 11V5C14 3.34315 12.6569 2 11 2H5Z"
      />
      <Path
        fill="white"
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
        data="M9.25 10.875C9.25 10.1846 8.69036 9.625 8 9.625C7.30964 9.625 6.75 10.1846 6.75 10.875C6.75 11.5654 7.30964 12.125 8 12.125C8.69036 12.125 9.25 11.5654 9.25 10.875Z"
      />
      <Path
        fill="white"
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
        data="M8.5 8.375C8.77614 8.375 9 8.15114 9 7.875V4.375C9 4.09886 8.77614 3.875 8.5 3.875H7.5C7.22386 3.875 7 4.09886 7 4.375V7.875C7 8.15114 7.22386 8.375 7.5 8.375H8.5Z"
      />
    </Group>
  );
}

export const PointInfoBadgeMemo = React.memo(PointInfoBadge);
