import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgUserSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.405 8.287A3.3 3.3 0 104.7 5.3a3.3 3.3 0 004.705 2.987zm.194 1.54A10.773 10.773 0 008 9.7c-.495 0-1.044.042-1.6.127-1.917.293-3.9 1.09-3.9 2.387v1.336c0 .304.246.55.55.55h9.9a.55.55 0 00.55-.55v-1.336c0-1.296-1.983-2.094-3.9-2.387zM8 3.5a1.8 1.8 0 100 3.6 1.8 1.8 0 100-3.6zm-4 8.786a.74.74 0 01.062-.063c.152-.14.443-.318.897-.491.903-.345 2.093-.532 3.041-.532s2.138.187 3.041.532c.454.173.745.352.897.491.03.027.05.048.062.063v.314H4v-.314z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgUserSmallLined;
