import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';
import { Select, PREFIX } from '@src/ui/kit/Select';

export const SelectName = styled(Select)`
  .${PREFIX}__control {
    min-width: 308px;
  }
`;

export const Tag = styled.div`
  display: flex;
  margin-bottom: ${Spacing.S5};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const TagSelect = styled.div`
  flex-basis: 308px;
  margin-right: ${Spacing.S3};
`;

export const EventSelect = styled.div`
  flex-basis: 282px;
  margin-right: ${Spacing.S3};
`;

export const ValueSelect = styled.div`
  flex: 1;
`;

export const TagRemove = styled.div`
  display: flex;
  align-items: center;
`;
