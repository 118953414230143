import React from 'react';
import { H4, Paragraph } from '@pushwoosh/kit-typography';
import { TPointABStatistics } from '@src/common/types/statistics';
import { usePointOutputsStats } from '@src/store/hooks';
import { branchNames } from '@src/common/constants/ab';
import { Container, Header, Row, Item, Status, BranchTitle } from './styled';

type Stats = { branch: number; count: number }[];

type Props = {
  split: number[];
  pointId: string;
  statistics: TPointABStatistics['pointStat'][];
};

export function BranchWithoutGoals(props: Props): JSX.Element {
  const { split, pointId, statistics } = props;
  const pointOutputsStats = usePointOutputsStats();
  const pointStat = React.useMemo(
    () => pointOutputsStats.find((item) => item.toPointId === pointId),
    [pointId, pointOutputsStats]
  );

  const stats = React.useMemo(() => {
    const statisticsOutput: Stats = [];

    split.forEach((item, index) => {
      const countBranch = statistics.find((stat) => stat.key === `output${index}`);
      statisticsOutput.push({
        branch: item,
        count: countBranch ? countBranch.count : 0
      });
    });
    return statisticsOutput;
  }, [split, statistics]);

  return (
    <Container>
      <Header>
        <H4>Total entries</H4>
        <Paragraph>{pointStat.count}</Paragraph>
      </Header>
      {stats.map((stat, index) => (
        <Row key={stat.branch}>
          <BranchTitle>
            <Item>
              <Status />
            </Item>
            <Item>
              <H4>{branchNames[index]}:</H4>
              <H4>{stat.branch}%</H4>
            </Item>
          </BranchTitle>
          <Paragraph>{stat.count}</Paragraph>
        </Row>
      ))}
    </Container>
  );
}
