import styled from 'styled-components';
import { Spacing, FontWeight } from '@pushwoosh/kit-constants';
import { Badge } from '@src/ui/kit/Badge';

export const TextRule = styled.div`
  font-weight: ${FontWeight.MEDIUM};
`;

export const BadgeWithOffset = styled(Badge)`
  margin-left: ${Spacing.S3};
`;
