import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgInfoRoundSmallFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 8A7 7 0 111 8a7 7 0 0114 0zm-5.5 3.5H9V8h.5a.5.5 0 000-1h-3a.5.5 0 000 1H7v3.5h-.5a.5.5 0 000 1h3a.5.5 0 000-1zM8 5.5a1 1 0 100-2 1 1 0 000 2z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgInfoRoundSmallFilled;
