import React from 'react';
import Konva from 'konva';
import { useBus } from '@src/common/contexts/bus';
import { useStoreState, useStoreActions } from '@src/store/store';
import {
  usePopupMenuStore,
  useStatusRequestLoadedById,
  useChangeZoom,
  useZoom,
  useSetActiveComment,
  useActivePointIds,
  usePoints,
  useCenteredPoint,
  useSetActivePoint
} from '@src/store/hooks';
import {
  useCenteredStage,
  useAutoMouseFixedAtMouseLeave,
  useAutoResizeCanvasAtChangeWindow
} from '@src/canvas/hooks';
import { useClickOutside } from '@src/ui/hooks';
import {
  getCoordsCenterStage,
  getMousePositionToPage,
  getCorrectMousePositionByStage,
  calcCanvasScale
} from '../../helpers';
import { useSelection } from './useSelection';
import { useArrows } from './useArrows';

export function useCanvas() {
  const bus = useBus();
  const setActiveComment = useSetActiveComment();
  const setActivePoint = useSetActivePoint();
  const stageRef = React.useRef<Konva.Stage>(null);
  const isOpenedModal = useStoreState((state) => state.modals.isOpened);
  const popupMenuStore = usePopupMenuStore();
  const [isRequestLoadedById] = useStatusRequestLoadedById();
  const changeZoom = useChangeZoom();
  const zoom = useZoom();
  const isArrowDrawing = useStoreState((store) => store.arrow.isDrawingArrow);
  const centeredCommentPosition = useStoreState((state) => state.comments.centeredCommentPosition);
  const setCenteredComment = useStoreActions((state) => state.comments.setCenteredComment);
  const activePointIds = useActivePointIds();
  const points = usePoints();
  const centeredId = useCenteredPoint();

  const [coordsCenteredPoint, setCenteredCoords] = useCenteredStage({
    isRequestLoadedById,
    centeredId,
    points,
    refStage: stageRef
  });

  const {
    refSelection,
    selectionOptions,
    handleSelection,
    handleStartSelection,
    handleEndSelection
  } = useSelection({ zoom, setActivePoint });

  useAutoMouseFixedAtMouseLeave(stageRef);
  useAutoResizeCanvasAtChangeWindow(stageRef);
  useArrows({ stageRef, popupMenuStore });

  React.useLayoutEffect(() => {
    const { scaleX, scaleY, width } = stageRef.current.getStage().getAttrs();

    const height = window.innerHeight;

    if (centeredCommentPosition?.x && centeredCommentPosition?.y) {
      const { x, y } = getCoordsCenterStage({
        x: centeredCommentPosition.x,
        y: centeredCommentPosition.y,
        scaleX,
        scaleY,
        height,
        width
      });
      setCenteredCoords({ x, y });

      setCenteredComment('');
    }
  }, [centeredCommentPosition, setCenteredCoords, stageRef, setCenteredComment]);

  const canvasClickOutside = React.useCallback((): void => {
    if (isOpenedModal) return;

    setActiveComment('');

    if (activePointIds && !popupMenuStore.isVisible) {
      setActivePoint('');
    }
  }, [setActivePoint, activePointIds, popupMenuStore.isVisible, setActiveComment, isOpenedModal]);

  const handleCanvasWheel = React.useCallback(
    (event: Konva.KonvaEventObject<WheelEvent>): void => {
      const stage = event.currentTarget.getStage() as Konva.Stage;

      event.evt.preventDefault();

      bus.emit({ type: 'closePopupMenu$', withCopmplete: false });

      // how to scale? Zoom in? Or zoom out?
      let direction = event.evt.deltaY > 0 ? 1 : -1;

      // when we zoom on trackpad, e.evt.ctrlKey is true
      // in that case lets revert direction
      if (event.evt.ctrlKey) {
        direction = -direction;
      }

      if ((direction > 0 && zoom >= 4) || (direction < 0 && zoom <= 0.18)) {
        event.evt.stopImmediatePropagation();
        return;
      }

      const { newScale, newPosition } = calcCanvasScale(stage, direction);

      stage.scale({ x: newScale, y: newScale });

      changeZoom(direction, newScale);
      stage.position(newPosition);
    },
    [changeZoom, bus, zoom]
  );

  const canvasRef = useClickOutside(canvasClickOutside);

  const handleCanvasMouseUp = React.useCallback(
    (konvaEvent: Konva.KonvaEventObject<MouseEvent>): void => {
      const stage = konvaEvent.currentTarget.getStage();

      bus.emit({ type: 'endDrawArrow$', payload: konvaEvent, withCopmplete: false });

      if (selectionOptions.visible) {
        handleEndSelection(konvaEvent);
      }

      if (stage) {
        bus.emit({
          type: 'addNewPoint$',
          payload: getCorrectMousePositionByStage(stage),
          withCopmplete: false
        });
      }
    },
    [handleEndSelection, selectionOptions.visible, bus]
  );

  const handleCanvasDragMove = React.useCallback(
    (konvaEvent: Konva.KonvaEventObject<MouseEvent>): void => {
      konvaEvent.evt.preventDefault();
      if (konvaEvent.currentTarget === konvaEvent.target) {
        setActiveComment('');
      }

      bus.emit({ type: 'closePopupMenu$', withCopmplete: false });
    },
    [setActiveComment, bus]
  );

  const handleCanvasDragEnd = React.useCallback(
    (konvaEvent: Konva.KonvaEventObject<MouseEvent>): void => {
      if (!konvaEvent.target.attrs.pointId) {
        const stage = konvaEvent.currentTarget.getStage();
        const { x, y } = stage.attrs;
        setCenteredCoords({ x, y });
      }
    },
    [setCenteredCoords]
  );

  const handleCanvasDblClick = React.useCallback(
    (konvaEvent: Konva.KonvaEventObject<MouseEvent>): void => {
      const stage = konvaEvent.currentTarget.getStage();
      const mousePositionToCanvas = getCorrectMousePositionByStage(stage);
      const mousePositionToPage = getMousePositionToPage(konvaEvent.evt);

      const fromPointId = '';
      const pointOutputKey = '';
      const pointOutputOrder = 0;

      const popupMenu = {
        pageX: mousePositionToPage.x,
        pageY: mousePositionToPage.y,
        toX: mousePositionToCanvas.x,
        toY: mousePositionToCanvas.y,
        fromPointId,
        pointOutputKey,
        pointOutputOrder
      };

      bus.emit({ type: 'openPopupMenu$', payload: popupMenu, withCopmplete: false });
    },
    [bus]
  );

  const handleCanvasClick = React.useCallback(
    (konvaEvent: Konva.KonvaEventObject<MouseEvent>): void => {
      if (konvaEvent.evt.shiftKey || konvaEvent.evt.ctrlKey || konvaEvent.evt.metaKey) return;

      const shapeName = konvaEvent.target.parent?.attrs?.name;

      // If click target is comment, clear active points
      if (shapeName === 'comment') {
        setActivePoint(null);

        return;
      }

      // If click target is canvas, clear active points
      if (!konvaEvent.target.parent) {
        setActivePoint(null);
      }

      setActiveComment('');
    },
    [setActiveComment, setActivePoint]
  );

  const isDraggable = !selectionOptions.visible && !isArrowDrawing;

  return {
    canvasRef,
    stageRef,
    refSelection,
    isRequestLoadedById,
    isDraggable,
    coordsCenteredPoint,
    selectionOptions,
    handleCanvasWheel,
    handleSelection,
    handleStartSelection,
    handleCanvasMouseUp,
    handleCanvasDragMove,
    handleCanvasDragEnd,
    handleCanvasDblClick,
    handleCanvasClick
  };
}
