import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgReachabilityCheckLarge(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.361 6.015a6.496 6.496 0 00-6.5 6.5c0 3.593 2.908 6.5 6.5 6.5 3.593 0 6.5-2.908 6.5-6.5 0-3.593-2.907-6.5-6.5-6.5zm3.777 3.987a1 1 0 010 1.414l-3.972 3.972a1 1 0 01-1.415 0l-1.805-1.805a1 1 0 011.414-1.414l.745.745a.5.5 0 00.707 0l2.912-2.912a1 1 0 011.414 0zM27.49 20.616a.528.528 0 01.606.477l.116 1.782a.5.5 0 00.099.268l1.284 1.712c.989 1.319.048 3.2-1.6 3.2h-4.21a3.786 3.786 0 01-7.572 0h-4.21c-1.649 0-2.59-1.881-1.6-3.2l1.284-1.713a.5.5 0 00.1-.268l.44-6.802a7.79 7.79 0 015.904-7.058c.387-.095.684.313.547.688a8.137 8.137 0 00-.303 1.037.549.549 0 01-.35.395 5.79 5.79 0 00-3.801 5.067l-.473 7.284a.997.997 0 01-.296.646.663.663 0 00-.065.074l-.788 1.05a.5.5 0 00.4.8h13.993a.5.5 0 00.4-.8l-.79-1.053a.672.672 0 00-.064-.072.997.997 0 01-.294-.645l-.162-2.502a.274.274 0 01.276-.29c.383 0 .76-.026 1.13-.077zm-9.276 7.439h3.57a1.786 1.786 0 01-3.57 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgReachabilityCheckLarge;
