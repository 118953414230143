import styled from 'styled-components';
import { Spacing, Color, FontWeight, FontSize } from '@pushwoosh/kit-constants';

type ContainerProps = {
  disabled: boolean;
  borderBottom: boolean;
};

const disabledCss = `
  opacity: 0.2;
  cursor: not-allowed;
`;

export const Container = styled.div`
  display: grid;
  grid-template-areas: 'label' 'value';
  row-gap: ${Spacing.S1};
  column-gap: ${Spacing.S3};
  padding: ${Spacing.S5};
  box-sizing: border-box;
  position: relative;

  ${(props: ContainerProps): string => (props.disabled ? disabledCss : '')}
  ${(props): string => (props.borderBottom ? 'border-bottom: 1px solid #EBF0F5' : '')};
`;

export const Value = styled.span`
  grid-area: value;
  font-weight: ${FontWeight.BOLD};
  font-size: ${FontSize.HEADING1};
  color: ${Color.MAIN};
`;

export const Label = styled.span`
  grid-area: label;
  line-height: 16px;
  font-weight: ${FontWeight.MEDIUM};
  font-size: ${FontSize.SMALL};
  text-transform: uppercase;
  color: ${Color.LOCKED};
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
`;

export const IconWrapper = styled.div`
  margin-left: ${Spacing.S3};
`;
