import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgReachabilityCheckPointFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={24} height={24} rx={4} fill="#00A2FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5a5 5 0 00-5 5v2c0 .809-.143 1.005-1.218 2.19a1.082 1.082 0 00.8 1.81h10.836a1.082 1.082 0 00.8-1.81C17.143 13.005 17 12.809 17 12v-2a5 5 0 00-5-5zm1.936 12.117c.294.006.522.267.425.545a2.501 2.501 0 01-4.722 0c-.097-.278.13-.54.425-.545a103.797 103.797 0 013.872 0zm1.163-7.18l.177-.177a.5.5 0 000-.707l-.354-.354a.5.5 0 00-.707 0l-.177.177-2.33 2.33a.5.5 0 01-.707 0l-.577-.577-.177-.177a.5.5 0 00-.707 0l-.354.354a.5.5 0 000 .707l.177.177 1.46 1.46.178.178a.5.5 0 00.707 0l.177-.177 3.214-3.215z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgReachabilityCheckPointFilled;
