import { differenceInDays, differenceInMinutes, differenceInHours, format } from 'date-fns';

const ONE_HOUR = 60;

export const formatDateTimeDiff = (dateTime: string): string => {
  const currentDate = new Date();
  const dateObj = new Date(dateTime);
  const passedDays = differenceInDays(currentDate, dateObj);

  if (passedDays === 0) {
    const passedMinutes = differenceInMinutes(currentDate, dateObj);
    if (passedMinutes < ONE_HOUR) {
      return `${passedMinutes} mins ago`;
    }

    const passedHours = differenceInHours(currentDate, dateObj);
    return `${passedHours} hour ago`;
  }

  return format(dateObj, 'MMMM d, yyyy');
};
