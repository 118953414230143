import React from 'react';
// Store Hooks
import { useActivateJourneyByIdFromList, useIsSetUpGoal } from '@src/store/hooks';

// Features Hooks
import { useConfirmService } from '@src/ui/features/Modals/Confirm/hooks/useConfirmService';

const DESCRIPTION_NOT_SET_GOAL = `
Please note that you do not have any Goals configured for this Journey.
You can configure them in the Goals Section.
`;

export const useConfirmActivateJourney = (journeyId: string): (() => void) => {
  const confirm = useConfirmService();
  const isSetUpGoal = useIsSetUpGoal();
  const description = isSetUpGoal ? '' : DESCRIPTION_NOT_SET_GOAL;
  const [activateJourneyById, isRequestActivateJourneyById] = useActivateJourneyByIdFromList();
  const confirmActivateJourneyHandler = React.useCallback((): void => {
    confirm({
      title: 'Activate Journey',
      description,
      onAfterConfirm(): void {
        activateJourneyById(journeyId);
      },
      isLoadingConfirm: isRequestActivateJourneyById
    });
  }, [confirm, activateJourneyById, journeyId, isRequestActivateJourneyById, description]);

  return confirmActivateJourneyHandler;
};
