import * as React from 'react';
import _startsWith from 'lodash/startsWith';

// Analytics
import { sendMixpanelEvent } from '@src/common/analytics';
import { SidebarType } from '@src/common/constants/sidebars';

// Store Hooks
import {
  useCurrentJourneyId,
  useEditJourneyById,
  useGetJourneyById,
  useGetPointById,
  useGetPresetsByApplicationCode,
  useJourneyAppCode,
  useOpenNewModal,
  useUpdatePoint
} from '@src/store/hooks';
import { useStoreActions, useStoreState } from '@src/store/store';

// UI Hooks
import { useForm } from '@src/ui/hooks';

// Containers Hooks
import { usePresetsByAppCodeWithAutoLoad } from '@src/ui/hooks/usePresetsByAppCodeWithAutoLoad';

// UI Components
import {
  EditPointSendSms,
  IEditPointSendSmsValues
} from '@src/ui/features/SendSms/components/EditPointSendSms';
import { Link } from '@src/ui/kit/Link';
import { Modal } from '@src/ui/kit/Modal';
import { Button, IconEnum, Section } from '@src/ui/kit';
import { Paragraph } from '@pushwoosh/kit-typography';
import { DefaultButtons } from '@src/ui/kit/ModalButtons';

// Helpers
import { getPointNameByType, makePresetsOptions } from '@src/ui/helpers';
import { getPlaceholderFromPreset } from '@src/common/helpers/getPlaceholdersFromPreset';

// Constants
import { FontSize } from '@pushwoosh/kit-constants';

// Types
import { TSendSms } from '@src/common/types/points';
import { IUniqueId } from '@src/common/types/entities';
import { isEqual } from 'lodash';
import { JourneyStatus, ModalType, PointType } from '@src/common/constants';
import { validate } from '../validation/validate';

// Styled
import { StyledInput } from '../styled';

interface IProps {
  pointId: IUniqueId['id'];
  closeModal: () => void;
}

export function SendSms(props: IProps): JSX.Element {
  const { pointId, closeModal } = props;

  const [saveDisabled, setSaveDisabled] = React.useState(false);
  const getPointById = useGetPointById();
  const getPresetsByApplicationCode = useGetPresetsByApplicationCode();
  const updatePoint = useUpdatePoint();
  const journeyId = useCurrentJourneyId();
  const applicationCode = useJourneyAppCode(journeyId);
  const showDocumentationMenu = useStoreActions((actions) => actions.documentationMenu.show);
  const getJourneyById = useGetJourneyById();
  const openNewModal = useOpenNewModal();
  const editPoint = useStoreActions((actions) => actions.points.update);
  const handleEditJourneyById = useEditJourneyById();
  const sidebars = useStoreState((state) => state.sidebars);
  const openNewSidebar = useStoreActions((actions) => actions.sidebars.openNewSidebar);
  const closeAllSidebars = useStoreActions((actions) => actions.sidebars.closeAllSidebars);
  const [presets, isLoadingPresets] = usePresetsByAppCodeWithAutoLoad(applicationCode);

  const { status } = getJourneyById(journeyId);

  const point = getPointById(pointId) as TSendSms;
  const currentPreset = presets.find((item) => item.name === point.data.name);

  const preset = React.useMemo(
    () => ({
      code: currentPreset?.code ?? '',
      title: currentPreset?.title ?? '',
      name: currentPreset?.name ?? '',
      content: currentPreset?.content ?? ''
    }),
    [currentPreset]
  );

  const initialValues = React.useMemo(
    () => ({
      title: point.title || point.data.name,
      presetCode: point.data.presetCode || '',
      content: point.data.content || '',
      enabledBehaviorSplitter: point.data?.smsBehaviorSplitter?.enabled || false,
      intervalBehaviorSplitter: point.data?.smsBehaviorSplitter?.interval || 0,
      isPresetAvailable: point.data.isPresetAvailable,
      enabledPersonalise: point.data?.personalise?.enabled,
      placeholders: getPlaceholderFromPreset(preset).map((placeholder) => {
        const selectedPlaceholder = point.data.personalise.placeholders.find(
          (item) => item.name === placeholder.name
        );

        return selectedPlaceholder ? { ...selectedPlaceholder, isSelected: true } : placeholder;
      }),
      sendByUserID: point.data.sendByUserID ?? false,
      enabledLiquid: !!point.data?.liquidTemplateParams?.enabled,
      liquidEventName: point.data?.liquidTemplateParams?.eventName
    }),
    [point, preset]
  );

  const isJourneyActive = status === JourneyStatus.RUNNING;

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setValues,
    setFieldTouched,
    handleChange,
    handleBlur,
    handleSubmit
  } = useForm<IEditPointSendSmsValues>({
    initialValues,
    validate,
    onSubmit(formValue): void {
      const preset = getPresetsByApplicationCode(applicationCode).find(
        (preset): boolean => preset.code === formValue.presetCode
      );

      const changePoint = {
        id: pointId,
        title: formValue.title,
        data: {}
      };

      if (formValue.isPresetAvailable) {
        changePoint.data = {
          applicationCode,
          presetCode: formValue.presetCode,
          content: formValue.content,
          name: preset ? preset.name : '',
          title: preset ? preset.title : '',
          isPresetAvailable: formValue.isPresetAvailable,
          smsBehaviorSplitter: {
            enabled: formValue.enabledBehaviorSplitter,
            interval: formValue.intervalBehaviorSplitter
          }
        };
      }
      if (!formValue.isPresetAvailable) {
        changePoint.data = {
          applicationCode,
          presetCode: '',
          content: formValue.content,
          name: '',
          title: '',
          isPresetAvailable: formValue.isPresetAvailable,
          smsBehaviorSplitter: {
            enabled: formValue.enabledBehaviorSplitter,
            interval: formValue.intervalBehaviorSplitter
          }
        };
      }

      if (formValue.enabledPersonalise) {
        changePoint.data = {
          ...changePoint.data,
          personalise: {
            enabled: formValue.enabledPersonalise,
            placeholders: formValue.placeholders
              .filter((placeholder) => placeholder.isSelected)
              .map((placeholder) => ({
                name: placeholder.name,
                pointID: placeholder.pointID,
                eventName: placeholder.eventName,
                attribute: placeholder.attribute
              }))
          }
        };
      } else {
        changePoint.data = {
          ...changePoint.data,
          personalise: {
            enabled: formValue.enabledPersonalise,
            placeholders: []
          }
        };
      }

      if (formValue.enabledLiquid) {
        changePoint.data = {
          ...changePoint.data,
          liquidTemplateParams: {
            enabled: formValue.enabledLiquid,
            eventName: formValue.liquidEventName
          }
        };
      } else {
        changePoint.data = {
          ...changePoint.data,
          liquidTemplateParams: {
            enabled: formValue.enabledLiquid,
            eventName: ''
          }
        };
      }

      changePoint.data = {
        ...changePoint.data,
        sendByUserID: formValue.sendByUserID
      };

      if (!isJourneyActive) {
        if (point.data?.smsBehaviorSplitter?.enabled !== formValue.enabledBehaviorSplitter) {
          updatePoint({
            ...changePoint,
            outputs: []
          });
        } else {
          updatePoint(changePoint);
        }

        if (formValue.presetCode || formValue.content) {
          sendMixpanelEvent({
            eventName: 'SendSmsConfigured',
            eventData: {
              CustomContentUsed: !!formValue.content,
              PresetUsed: !!formValue.presetCode
            }
          });
        }

        closeModal();
      } else {
        editPoint(changePoint);

        handleEditJourneyById(journeyId);

        sendMixpanelEvent({
          eventName: 'JourneyEditModeChangesApplied',
          eventData: {
            PointType: 'SendSms'
          }
        });

        closeModal();

        openNewModal({
          type: ModalType.DETAIL_POINT,
          data: {
            pointId
          }
        });
      }
    }
  });

  const defaultTitle = getPointNameByType(PointType.SEND_SMS);
  const [nameChanged, setNameChanged] = React.useState(!_startsWith(values.title, defaultTitle));

  const presetSelectOptions = makePresetsOptions(presets);

  React.useEffect(() => {
    if (preset.name && values.placeholders.length === 0) {
      const placeholders = getPlaceholderFromPreset(preset).map((placeholder) => {
        const selectedPlaceholder = point.data.personalise?.placeholders?.find(
          (item) => item.name === placeholder.name
        );

        return selectedPlaceholder ? { ...selectedPlaceholder, isSelected: true } : placeholder;
      });

      setFieldValue('placeholders', placeholders);
    }
  }, [point.data.personalise?.placeholders, preset, setFieldValue, values.placeholders.length]);

  const handleCancel = React.useCallback(() => {
    if (status === JourneyStatus.RUNNING) {
      sendMixpanelEvent({
        eventName: 'JourneyEditModeChangesCancelled',
        eventData: {
          PointType: 'SendSms'
        }
      });
    } else {
      sendMixpanelEvent({
        eventName: 'ConfigurePointCancelled',
        eventData: {
          PointType: 'SendSms',
          PointChanged: !isEqual(values, initialValues)
        }
      });
    }

    closeModal();
  }, [closeModal, values, initialValues, status]);

  const onShowDocumentation = React.useCallback((): void => {
    if (sidebars.isOpened) {
      closeAllSidebars();
    } else {
      openNewSidebar({
        type: SidebarType.DOCUMENTATION
      });
      showDocumentationMenu();
      sendMixpanelEvent({ eventName: 'ShowDocumentation' });
    }
  }, [showDocumentationMenu, sidebars, closeAllSidebars, openNewSidebar]);

  const handleSetValues = React.useCallback(
    (newValues: IEditPointSendSmsValues) => {
      if (status === JourneyStatus.RUNNING) {
        if (newValues.presetCode === '' || newValues.isPresetAvailable === false) {
          setSaveDisabled(true);
        } else {
          setSaveDisabled(false);
        }
      }

      const values2 = {
        ...newValues,
        title: !nameChanged ? newValues.title : values.title
      };

      setValues(values2);
    },
    [nameChanged, setValues, setSaveDisabled, status, values.title]
  );

  const handleSetFieldValue = (name: string, value: unknown) => {
    if (status === JourneyStatus.RUNNING) {
      const { content } = values;
      if (content === '') {
        setSaveDisabled(true);
      } else {
        setSaveDisabled(false);
      }
    }

    setFieldValue(name, value);
  };

  const handleChangeName = (name: string, value: string) => {
    setNameChanged(true);
    setFieldValue(name, value);
  };

  return (
    <Modal
      title="Sms"
      footerRight={
        <DefaultButtons
          isDisabledActionButton={saveDisabled}
          onClickActionButton={handleSubmit}
          onClickCancelButton={handleCancel}
          actionButtonName={status === JourneyStatus.RUNNING ? 'Save' : 'Apply'}
        />
      }
      footerLeft={
        <Button
          color="primary"
          size="field"
          view="ghost"
          iconPosition="left"
          iconType={IconEnum.DOCS_MEDIUM_LINED}
          onClick={onShowDocumentation}
        >
          How to Use
        </Button>
      }
      isOpen
    >
      <Section direction="column">
        <Paragraph>
          Use presets from your Pushwoosh account or create sms notification content from scratch.
        </Paragraph>
        <Link to="http://pushwoosh.com" isExternal size={FontSize.REGULAR}>
          See guide.
        </Link>
        <StyledInput
          label="Point Name"
          name="title"
          value={values.title}
          onChange={handleChangeName}
        />
      </Section>
      <EditPointSendSms
        applicationCode={applicationCode}
        presets={presets}
        presetSelectOptions={presetSelectOptions}
        values={values}
        names={{
          presetCode: 'presetCode',
          isPresetAvailable: 'isPresetAvailable',
          content: 'content',
          title: 'title',
          enabledBehaviorSplitter: 'enabledBehaviorSplitter',
          intervalBehaviorSplitter: 'intervalBehaviorSplitter',
          enabledPersonalise: 'enabledPersonalise',
          placeholders: 'placeholders',
          sendByUserID: 'sendByUserID',
          enabledLiquid: 'enabledLiquid',
          liquidEventName: 'liquidEventName'
        }}
        touched={touched}
        errors={errors}
        isLoadingPresetOptions={isLoadingPresets}
        setFieldValue={handleSetFieldValue}
        setValues={handleSetValues}
        setFieldTouched={setFieldTouched}
        onChangeField={handleChange}
        onBlurField={handleBlur}
        journeyStatus={status}
        pointId={pointId}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
}
