import React from 'react';
import uniq from 'lodash/uniq';

// UI Components
import { ExtraOneButton, IconEnum, Modal, Section } from '@src/ui/kit';
import { H2 } from '@pushwoosh/kit-typography';
import { PointTitle } from '@src/ui/kit/DetailsPoint/styled';
import { ModalButtonsWrap } from '@src/ui/form/styled';
import { StatItem, StatWrap } from '@src/ui/features/PointStat';

// Helpers
import { renderTemplate } from '@src/ui/features/ApiEntry/helepers/placeholders';

// Hooks
import { useFilter } from '@src/store/hooks';

// Types
import { TPointApiEntryStatistics } from '@src/common/types/statistics';
import { ModalType } from '@src/common/constants/modals';

// Styled
import { useCloseModalByType } from '@src/store/hooks/modals/useCloseModalByType';
import { CopyButton, Example, Placeholder, StyledTextarea } from '@src/ui/features/ApiEntry/styled';
import { H4 } from '@src/ui/form/FormControl/styled';
import { makeFilterOptions } from '@src/ui/helpers';

interface IProps {
  journeyId: string;
  applicationCode: string;
  pointTitle: string;
  attributes?: string[];
  entries: TPointApiEntryStatistics['pointStat']['entries'];
  launches: TPointApiEntryStatistics['pointStat']['launches'];
}

export function DetailApiEntry(props: IProps): JSX.Element {
  const { journeyId, applicationCode, pointTitle, attributes, entries, launches } = props;

  const filters = useFilter();
  const filtersOptions = makeFilterOptions(filters);

  const closeModalByType = useCloseModalByType();

  const template = React.useMemo(
    () => renderTemplate(journeyId, applicationCode, attributes),
    [journeyId, filtersOptions, attributes, applicationCode]
  );

  const handleCopyClick = React.useCallback(async () => {
    await navigator.clipboard.writeText(template);
  }, [template]);

  const placeholdersList = React.useMemo(() => {
    if (!attributes?.length) return null;

    return uniq(attributes)
      .sort()
      .map((attribute) => <Placeholder key={attribute}>{attribute}</Placeholder>);
  }, [attributes]);

  return (
    <Modal
      title={
        <H2>
          Webhook-based entry:&nbsp;
          <PointTitle>{pointTitle}</PointTitle>
        </H2>
      }
      footerRight={
        <ExtraOneButton
          actionButtonName="Close"
          onClickActionButton={() => closeModalByType(ModalType.DETAIL_POINT)}
        />
      }
      footerLeft={<ModalButtonsWrap />}
      isOpen
    >
      <Section direction="column">
        <StatWrap>
          <StatItem title="ENTRIES" value={entries} />
          <StatItem title="LAUNCHES" value={launches} />
        </StatWrap>
      </Section>

      <Section direction="column">
        {placeholdersList.length && (
          <H4 disabled={false}>Personalization placeholders: {placeholdersList}</H4>
        )}

        <Example>
          <StyledTextarea name={'requestTemplate'} readonly value={template} />
          <CopyButton
            color="secondary"
            size="compact"
            view="shape"
            iconPosition="left"
            iconType={IconEnum.COPY_MEDIUM_LINED}
            onClick={handleCopyClick}
          />
        </Example>
      </Section>
    </Modal>
  );
}
