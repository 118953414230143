import styled from 'styled-components';
import { Color, Shadow, Spacing } from '@pushwoosh/kit-constants';
import { TransitionStatus } from 'react-transition-group';
import { Button } from '@src/ui/kit';

type ContainerProps = {
  state: TransitionStatus;
};

export const Container = styled.div`
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  transition: 0.5s ease-in;
  transform: translateX(${({ state }: ContainerProps) => (state === 'entered' ? 0 : '-100%')});
  position: fixed;
  left: calc(280px + ${Spacing.S5});
  width: calc(100% - 280px - ${Spacing.S12});
  height: calc(100% - ${Spacing.S9});
  background-color: ${Color.CLEAR};
  overflow: scroll;
  margin: ${Spacing.S5};
  border-radius: 8px;
  box-shadow: ${Shadow.REGULAR};
  z-index: 3;
`;

export const Header = styled.div`
  padding: ${Spacing.S7};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${Color.DIVIDER};
`;

export const ContentWrapper = styled.div`
  padding: ${Spacing.S7};
`;

export const CloseButton = styled(Button)`
  padding: 0;
`;
