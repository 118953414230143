import styled from 'styled-components';
import { Color, FontSize, Spacing } from '@pushwoosh/kit-constants';

type SectionProps = {
  direction?: 'row' | 'column';
  noBorder?: boolean;
  gap?: string;
};

export const Section = styled.div`
  display: flex;
  padding-left: ${Spacing.S7};
  padding-right: ${Spacing.S7};
  align-items: ${({ direction = 'row' }: SectionProps) =>
    direction === 'column' ? 'stretch' : 'center'};
  flex-direction: ${({ direction = 'row' }: SectionProps) => direction};
  padding-bottom: ${Spacing.S7};
  margin-bottom: ${Spacing.S7};
  border-bottom: ${({ noBorder = false }: SectionProps) =>
    noBorder ? 'none' : `1px solid ${Color.DIVIDER}`};
  font-size: ${FontSize.REGULAR};
  gap: ${({ gap }: SectionProps) => gap || '0px'};

  &:last-child {
    margin-bottom: 0;
  }
`;
