import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgOpenMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.25 5.25a.75.75 0 010 1.5H7.5a.75.75 0 00-.75.75v9c0 .414.336.75.75.75h9a.75.75 0 00.75-.75v-2.75a.75.75 0 011.5 0v2.75a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9A2.25 2.25 0 017.5 5.25h2.75zm-.896 6.54l4.596-4.595-1.174-1.076a.5.5 0 01.338-.869h5.109a.5.5 0 01.5.5v5.109a.5.5 0 01-.869.338l-1.076-1.174-4.596 4.596a.5.5 0 01-.707 0l-2.121-2.121a.5.5 0 010-.707z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgOpenMediumFilled;
