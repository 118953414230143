import * as React from 'react';
import { NumberStepper } from '@pushwoosh/kit-number-stepper';

// Types
import { Time } from '@src/common/types/silentHours';
import { TTimeStepperPeriodProps } from './TimeStepperPeriod.types';

// Styled
import { Help, Label, TimeStepperContainer } from './styled';

export function TimeStepperPeriod(props: TTimeStepperPeriodProps): JSX.Element {
  const { label, helpText, name, values, disabled, onChange } = props;

  const onChangeTime = React.useCallback(
    (name: string, value: Time): void => {
      onChange(name, value);
    },
    [onChange]
  );

  return (
    <TimeStepperContainer>
      <Label>{label}</Label>
      <NumberStepper
        dimension="hh"
        name={name}
        value={values.hour}
        max={23}
        min={0}
        onChange={({ value }) => value >= 0 && onChangeTime(name, { ...values, hour: value })}
        isDisabled={disabled}
      />

      <NumberStepper
        dimension="mm"
        name={name}
        value={values.minute}
        max={59}
        min={0}
        onChange={({ value }) => value >= 0 && onChangeTime(name, { ...values, minute: value })}
        isDisabled={disabled}
      />
      <Help>{helpText || ''}</Help>
    </TimeStepperContainer>
  );
}
