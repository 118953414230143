import { subDays, startOfWeek, endOfWeek, startOfMonth, endOfMonth } from 'date-fns';
import { PeriodSettings } from '@src/common/types/statistics';
import { TimePeriod } from '@src/common/constants/stats';

export function allTime(createdAt: string): PeriodSettings {
  return {
    period: TimePeriod.THIS_WEEK,
    dateFrom: new Date(createdAt),
    dateTo: new Date()
  };
}

export function customRange(): PeriodSettings {
  return {
    period: TimePeriod.CUSTOM_RANGE,
    dateFrom: new Date(),
    dateTo: new Date()
  };
}

export function today(): PeriodSettings {
  return {
    period: TimePeriod.TODAY,
    dateFrom: new Date(),
    dateTo: new Date()
  };
}

export function yesterday(): PeriodSettings {
  return {
    period: TimePeriod.YESTERDAY,
    dateFrom: subDays(new Date(), 1),
    dateTo: subDays(new Date(), 1)
  };
}

export function thisWeek(): PeriodSettings {
  const currentDate = new Date();
  const weekStart = startOfWeek(currentDate);
  const weekEnd = endOfWeek(currentDate);

  return {
    period: TimePeriod.THIS_WEEK,
    dateFrom: weekStart,
    dateTo: weekEnd
  };
}

export function thisMonth(): PeriodSettings {
  const currentDate = new Date();
  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(currentDate);

  return {
    period: TimePeriod.THIS_MONTH,
    dateFrom: monthStart,
    dateTo: monthEnd
  };
}

export function last30Days(): PeriodSettings {
  const currentDate = new Date();
  const dateFrom = subDays(new Date(), 30);

  return {
    period: TimePeriod.LAST_30_DAYS,
    dateFrom,
    dateTo: currentDate
  };
}
