// Types
import { TPoints } from '@src/common/types/points';
import { IVector2 } from '@src/common/types/types';

// Helpers
import { getCoordsAlignStage } from '@src/canvas/helpers';

export const findMaxLeftPoint = (
  points: TPoints[],
  { scaleX, scaleY }: { scaleX: number; scaleY: number }
): IVector2 => {
  const positions = points.map((item: TPoints): IVector2 => item.position);
  positions.sort((a, b): number => a.x - b.x);

  return getCoordsAlignStage({
    x: positions[0].x,
    y: positions[0].y,
    scaleX,
    scaleY
  });
};
