import * as React from 'react';

// UI components
import { Section } from '@src/ui/kit';
import {
  EditChannelHours,
  IFormEditChannelHoursValues
} from '@src/ui/features/SilentHours/components/EditChannelHours';
import { Spacing } from '@pushwoosh/kit-constants';

// Types
import { IFormEditSilentHoursValues, TFormEditSilentHoursProps } from './EditSilentHours.types';

export const EditSilentHours = (props: TFormEditSilentHoursProps): JSX.Element => {
  const { values, setFieldValue } = props;

  const changeHandlerFor =
    (name: keyof IFormEditSilentHoursValues) =>
    (subName: keyof IFormEditChannelHoursValues, value: any) => {
      const newVal = { ...values[name], [subName]: value };
      setFieldValue(name, newVal);
    };

  return (
    <Section direction="column" gap={Spacing.S4}>
      <EditChannelHours
        header={'Push notifications'}
        names={{
          enabled: 'enabled',
          fromTime: 'fromTime',
          toTime: 'toTime',
          weekDays: 'weekDays',
          behavior: 'behavior'
        }}
        values={values.pushParams}
        setFieldValue={changeHandlerFor('pushParams')}
      />

      <EditChannelHours
        header={'Emails'}
        names={{
          enabled: 'enabled',
          fromTime: 'fromTime',
          toTime: 'toTime',
          weekDays: 'weekDays',
          behavior: 'behavior'
        }}
        values={values.emailParams}
        setFieldValue={changeHandlerFor('emailParams')}
      />

      <EditChannelHours
        header={'In-apps'}
        names={{
          enabled: 'enabled',
          fromTime: 'fromTime',
          toTime: 'toTime',
          weekDays: 'weekDays',
          behavior: 'behavior'
        }}
        values={values.inAppParams}
        setFieldValue={changeHandlerFor('inAppParams')}
      />

      <EditChannelHours
        header={'SMS'}
        names={{
          enabled: 'enabled',
          fromTime: 'fromTime',
          toTime: 'toTime',
          weekDays: 'weekDays',
          behavior: 'behavior'
        }}
        values={values.smsParams}
        setFieldValue={changeHandlerFor('smsParams')}
      />
    </Section>
  );
};
