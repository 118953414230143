// Types
import { IDictionary } from '@src/common/types/types';

export function convertArrayToDictionary<T extends { id?: string }>(items: T[]): IDictionary<T> {
  return items.reduce<IDictionary<T>>((dict, item): IDictionary<T> => {
    dict[item.id] = item;

    return dict;
  }, {});
}
