import React from 'react';

import { StyledTableHeader } from './styled';

type IProps = {
  children?: string;
};

export function TableHeader({ children }: IProps) {
  return <StyledTableHeader>{children}</StyledTableHeader>;
}
