import * as React from 'react';

// Components
import { Button } from '@src/canvas/components/Button/Button';
import { IconCross } from '@src/canvas/components/Icons';

// Types
import { IProps as IPropsButton } from '@src/canvas/components/Button/Button.types';
import { TOmit } from '@src/common/types/types';
import { Color } from '@pushwoosh/kit-constants';
import { useHover } from '@src/canvas/hooks';
import Konva from 'konva';

type TProps = TOmit<IPropsButton, 'children'> & {
  handleButtonHovered: (hovered: boolean) => void;
};

export function ButtonRemove(props: TProps): JSX.Element {
  const { handleButtonHovered } = props;
  const { handleMouseEnter, handleMouseLeave } = useHover();

  const onMouseEnter = (event: Konva.KonvaEventObject<MouseEvent>) => {
    handleMouseEnter(event);
    handleButtonHovered(true);
  };

  const onMouseLeave = (event: Konva.KonvaEventObject<MouseEvent>) => {
    handleMouseLeave(event);
    handleButtonHovered(false);
  };

  return (
    <Button
      width={14}
      height={14}
      fill={Color.DANGER}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...props}
    >
      <IconCross x={2} y={2} />
    </Button>
  );
}
