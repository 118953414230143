import * as React from 'react';
import { debounce } from 'lodash';

// Components
import { Select, InputAsync } from '@src/ui/kit';
import { FormControl } from '@src/ui/molecules/FormControl';

// UI hook
import { useSelectableSelect } from '@src/ui/hooks';

// Types
import { ISelectOption } from '@src/common/types/form';
import { IFormInputProps } from './FormInputAutoComplete.types';

export function FormInputAutoComplete(props: IFormInputProps): JSX.Element {
  const {
    applicationCode,
    eventCode,
    attributeName,
    placeholder,
    error = '',
    label = '',
    name,
    value,
    onChangeSelect,
    onBlurSelect,
    onBlur
  } = props;

  const { selectableValues } = useSelectableSelect(
    applicationCode,
    eventCode,
    attributeName,
    value
  );
  const isSelectableValues = selectableValues && selectableValues.length;

  const changeSelectInputHandler = React.useCallback(
    (valueInput, meta): void => {
      if (meta.action === 'input-change') {
        onChangeSelect(name, valueInput);
      }
    },
    [name, onChangeSelect]
  );

  const changeSelectOptionHandler = React.useCallback(
    (option: ISelectOption<string>, meta): void => {
      if (meta.action === 'select-option') {
        onChangeSelect(name, option.value);
      }
    },
    [name, onChangeSelect]
  );

  const blurSelectHandler = React.useCallback((): void => {
    onBlurSelect(name, true);
  }, [name, onBlurSelect]);

  const changeInputValue = React.useCallback(
    (value: string) => {
      onChangeSelect(name, value);
    },
    [onChangeSelect, name]
  );

  if (!isSelectableValues) {
    return (
      <FormControl label={label} error={error}>
        <InputAsync
          placeholder={placeholder}
          wait={false}
          afterIdle={500}
          value={value}
          onChange={changeInputValue}
          onBlur={onBlur}
          withIcon={false}
        />
      </FormControl>
    );
  }

  return (
    <FormControl label={label} error={error}>
      <Select
        placeholder={placeholder}
        value={{
          label: value,
          value
        }}
        options={selectableValues}
        onInputChange={debounce(changeSelectInputHandler, 300)}
        onChange={changeSelectOptionHandler}
        onBlur={blurSelectHandler}
        components={{ DropdownIndicator: (): null => null }}
        isSearchable
        backspaceRemovesValue
      />
    </FormControl>
  );
}
