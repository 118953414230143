import React from 'react';
import { Spinner } from '@src/ui/kit/Spinner';
import { StyledButton, ButtonField, LoaderWrap } from './styled';

type Props = {
  isLoadingConfirm: boolean;
  activateJourney: () => void;
};

export function StartButton(props: Props): JSX.Element {
  const { isLoadingConfirm, activateJourney } = props;

  return (
    <StyledButton isDisabled={isLoadingConfirm} onClick={activateJourney}>
      {isLoadingConfirm ? (
        <>
          <LoaderWrap>
            <Spinner />
          </LoaderWrap>
          <ButtonField isDisabled={isLoadingConfirm}>Validating…</ButtonField>
        </>
      ) : (
        <ButtonField isDisabled={isLoadingConfirm}>Launch campaign</ButtonField>
      )}
    </StyledButton>
  );
}
