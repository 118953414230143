import styled from 'styled-components';
import { Spacing, Color, FontWeight } from '@pushwoosh/kit-constants';

export const Wrap = styled.div`
  padding: ${Spacing.S5} ${Spacing.S5} 0;
  border-top: 1px solid ${Color.DIVIDER};
  margin: 0 -${Spacing.S5};
  color: ${Color.SOFT};
  fill: ${Color.SOFT};
  font-weight: ${FontWeight.MEDIUM};
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: auto;

  &:hover {
    color: ${Color.PRIMARY_HOVER};
    fill: ${Color.PRIMARY_HOVER};
  }
`;

export const LogoutText = styled.div`
  margin-left: ${Spacing.S3};
`;
