import styled from 'styled-components';
import {
  Spacing,
  ShapeRadius,
  FontSize,
  FontWeight,
  UnitSize,
  LineHeight
} from '@pushwoosh/kit-constants';

interface PropsContainer {
  color: string;
  isSmall?: boolean;
}

export const Container = styled.div`
  display: inline-flex;
  align-items: center;
  height: ${({ isSmall }: PropsContainer) =>
    isSmall ? UnitSize.ICON_SMALL : UnitSize.COMPACT_HEIGHT};
  padding: ${({ isSmall }) => (isSmall ? `${Spacing.S0} ${Spacing.S2}` : `0 ${Spacing.S3}`)};
  background-color: ${(props) => props.color};
  border-radius: ${ShapeRadius.DIALOG};
`;

interface PropsText {
  color: string;
  isSmall?: boolean;
  isBold: boolean;
}

export const Content = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ isSmall }: PropsText) => (isSmall ? FontSize.SMALL : FontSize.REGULAR)};
  color: ${(props) => props.color};
  font-weight: ${(props) => (props.isBold ? FontWeight.BOLD : FontWeight.REGULAR)};
  line-height: ${LineHeight.REGULAR};
`;
