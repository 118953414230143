// Store
import { useStoreActions, useStoreState } from '@src/store/store';

type TAction = (payload?: void) => void;
interface IResult {
  isAuthorized: boolean;
  checkAuth: TAction;
  logout: TAction;
}

export function useAuth(): IResult {
  const isAuthorized = useStoreState((state): boolean => state.user.isAuthorized);

  const checkAuth = useStoreActions((actions): TAction => actions.user.checkAuth);

  const logout = useStoreActions((actions): TAction => actions.user.logout);

  return {
    isAuthorized,
    checkAuth,
    logout
  };
}
