import React from 'react';

// Components
import { AccordionItem } from './AccordionItem';

// Styled
import { Wrapper, ContentWrapper, ItemsWrapper } from './styled';

type AccordionItem = {
  key: number;
  title: string;
  description: string;
  content: JSX.Element;
};

type AccordionProps = {
  items: AccordionItem[];
  handleItemClick?: () => void;
};

export function Accordion(props: AccordionProps): JSX.Element {
  const { items, handleItemClick } = props;

  const [activeItem, setActiveItem] = React.useState(items[0].key);

  const { content } = items.find((item) => item.key === activeItem);

  const handleAccordionItemClick = (id: number) => {
    if (handleItemClick) {
      handleItemClick();
    }
    setActiveItem(id);
  };

  return (
    <Wrapper>
      <ItemsWrapper>
        {items.map((item) => (
          <AccordionItem
            key={item.key}
            id={item.key}
            title={item.title}
            description={item.description}
            isActive={activeItem === item.key}
            handleSetActive={handleAccordionItemClick}
          />
        ))}
      </ItemsWrapper>
      <ContentWrapper>{content}</ContentWrapper>
    </Wrapper>
  );
}
