import { TFormErrors } from '@src/ui/hooks/useForm';
import { IWebhookFormValues, Header } from './Webhook.types';

export const validate = (values: IWebhookFormValues): TFormErrors<IWebhookFormValues> => {
  let hasHeaerErrors = false;
  const errors: TFormErrors<IWebhookFormValues> = {};
  // eslint-disable-next-line no-useless-escape
  const httpsRegexp = new RegExp(
    '^(https://www.|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$',
    'i'
  );

  if (!values.url) {
    errors.url = 'Please enter a webhook URL';
  }

  if (!httpsRegexp.test(values.url)) {
    errors.url = 'Please enter a correct URL';
  }

  const headerErrors = values.headers.map((header) => {
    const error: Header = { name: undefined, value: undefined };

    if (!header.name) {
      error.name = 'Please enter a header name';
      hasHeaerErrors = true;
    }

    if (!header.value) {
      error.value = 'Please enter a header value';

      hasHeaerErrors = true;
    }

    return error;
  });

  if (hasHeaerErrors) {
    errors.headers = headerErrors;
  }

  return errors;
};
