import * as React from 'react';

// Styled
import { ButtonCardControl, ButtonCardIcon, ButtonCardTitle } from './styled';

// Types
import { IButtonCardProps } from './ButtonCard.types';

export function ButtonCard(props: IButtonCardProps): JSX.Element {
  const { icon, label, onClick, backgroundColor } = props;

  return (
    <ButtonCardControl onClick={onClick}>
      <ButtonCardIcon backgroundColor={backgroundColor}>{icon}</ButtonCardIcon>

      <ButtonCardTitle>{label}</ButtonCardTitle>
    </ButtonCardControl>
  );
}
