import styled from 'styled-components';
import { Color, ShapeRadius } from '@pushwoosh/kit-constants';

export const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  cursor: pointer;

  box-sizing: border-box;

  background: ${Color.CLEAR};
  border-radius: ${ShapeRadius.CONTROL};
`;

export const NativeCheckbox = styled.input`
  display: none;

  &:not(:checked) ~ ${StyledCheckbox} {
    border: 2px solid ${Color.PHANTOM};
  }

  &:checked ~ ${StyledCheckbox} {
    background-color: ${Color.PRIMARY};
  }
`;
