import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgCloseRoundMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.641 8.641a.75.75 0 011.061 0L12 10.94l2.298-2.298a.75.75 0 011.06 1.06L13.062 12l2.298 2.298a.75.75 0 01-1.06 1.06L12 13.062l-2.298 2.298a.75.75 0 11-1.06-1.06l2.297-2.3-2.298-2.297a.75.75 0 010-1.06z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.473 2 2 6.473 2 12s4.473 10 10 10 10-4.473 10-10S17.527 2 12 2zm0 18.438A8.433 8.433 0 013.562 12 8.433 8.433 0 0112 3.562 8.433 8.433 0 0120.438 12 8.433 8.433 0 0112 20.438z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgCloseRoundMediumLined;
