import * as React from 'react';
import { useVisibleSidebar } from '@src/store/hooks';

// UI Components
import { LayoutEditJourney } from '@src/ui/templates';

// Containers
import { JourneySidebar } from '@src/ui/features/JourneySidebar';
import { PopupMenuCreatePoint } from '@src/containers/components/PopupMenuCreatePoint';
import { Canvas } from '@src/canvas';
import { ControlsPanel } from '@src/ui/features/ControlsPanel';
import { StatBar } from '@src/ui/features/JourneyStatistics/components/StatBar';
import { SidebarManager } from '@src/ui/features/SidebarManager';
import { JourneyHeader } from '@src/ui/features/JourneyHeader';

// Types
import { IStatuses } from '@src/common/types/mode';

// Styled
import { JourneyPage, Viewport, Edit, CanvasWrap, RightPanel } from './styled';

interface IProps {
  mode: IStatuses;
  isLoading: boolean;
}

export function PageJourneyEditor(props: IProps): JSX.Element {
  const { mode, isLoading } = props;
  const isVisibleSidebar = useVisibleSidebar();

  return (
    <LayoutEditJourney
      Header={JourneyHeader}
      Sidebar={mode.isRead ? StatBar : JourneySidebar}
      isLoading={isLoading}
    >
      <JourneyPage>
        <Viewport>
          <Edit>
            <PopupMenuCreatePoint />
          </Edit>

          <CanvasWrap>
            <Canvas />
          </CanvasWrap>

          <RightPanel isVisibleSidebar={isVisibleSidebar}>
            <ControlsPanel />
          </RightPanel>

          <SidebarManager />
        </Viewport>
      </JourneyPage>
    </LayoutEditJourney>
  );
}
