import * as React from 'react';

// Store Hooks
import { useCloseAllModals } from '@src/store/hooks';

// UI Components
import { Modal, Section, Message } from '@src/ui/kit';
import { ItemDetails } from '@src/ui/kit/DetailsPoint/styled';
import { Paragraph } from '@pushwoosh/kit-typography';
import { Color } from '@pushwoosh/kit-constants';
import { WarningRoundIcon } from '@pushwoosh/kit-icons';
import { DangerButtons, DefaultButtons } from '@src/ui/kit/ModalButtons';

// Types
import { IConfirmServiceOptions } from '@src/ui/features/Modals/Confirm/hooks/useConfirmService';

export function ModalConfirm(props: IConfirmServiceOptions): JSX.Element {
  const {
    title,
    description,
    cancelLabel,
    level = 'info',
    confirmLabel,
    warningMessage,
    onAfterCancel,
    onAfterConfirm,
    onBeforeCancel,
    onBeforeConfirm
  } = props;

  const closeAllModals = useCloseAllModals();

  const submitHandler = React.useCallback((): void => {
    if (onBeforeConfirm) {
      onBeforeConfirm();
    }

    closeAllModals();

    if (onAfterConfirm) {
      onAfterConfirm();
    }
  }, [closeAllModals, onBeforeConfirm, onAfterConfirm]);

  const cancelHandler = React.useCallback((): void => {
    if (onBeforeCancel) {
      onBeforeCancel();
    }

    closeAllModals();

    if (onAfterCancel) {
      onAfterCancel();
    }
  }, [closeAllModals, onBeforeCancel, onAfterCancel]);

  return (
    <Modal
      title={title}
      widthType="short"
      footerRight={
        level === 'danger' ? (
          <DangerButtons
            actionButtonName={confirmLabel}
            cancelButtonName={cancelLabel}
            onClickActionButton={submitHandler}
            onClickCancelButton={cancelHandler}
          />
        ) : (
          <DefaultButtons
            actionButtonName={confirmLabel}
            cancelButtonName={cancelLabel}
            onClickActionButton={submitHandler}
            onClickCancelButton={cancelHandler}
          />
        )
      }
      isOpen
    >
      <Section direction="column">
        <Paragraph style={{ whiteSpace: 'pre-line' }}>{description}</Paragraph>
        {warningMessage && (
          <ItemDetails>
            <Message
              title={warningMessage}
              color={Color.WARNING_LIGHT}
              icon={<WarningRoundIcon view="filled" size="small" color="#F08000" />}
            />
          </ItemDetails>
        )}
      </Section>
    </Modal>
  );
}
