import * as React from 'react';

// Components
import { DocElements } from '@src/ui/features/Documentation/components/DocElements';

export function DocumentationPointSendPush(): JSX.Element {
  return (
    <DocElements>
      <DocElements.Paragraph>
        <strong>Push</strong>
        {' element indicates a point to communicate to a customer with a push notification.'}
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        {
          'To specify when to send a push message to a journey traveler, add the Push element to the canvas following the journey point you consider to be the appropriate basis for communication. You can either choose a preset saved in your Pushwoosh account or create message content from scratch.'
        }
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-send-push-1.png"
        label="Adding Push element"
        alt="Adding Push element"
      />

      <DocElements.Paragraph>
        <strong>Split to opened/ignored</strong>
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        {
          'You can split the remaining journey flow based on whether this push notification is opened or ignored. For example, it might be helpful to send emails to those who don’t open pushes or send one more push to those who have read the first one.'
        }
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-send-push-2.jpeg"
        label="Adding Push element"
        alt="Adding Push element"
      />

      <DocElements.Paragraph>
        {
          'Check the checkbox and set the period to wait after the push is sent – after that period, all users who open the push will go to the '
        }
        <strong>Opened</strong>
        {' journey branch, and others will pass through the Not opened branch.'}
      </DocElements.Paragraph>

      <DocElements.Paragraph>{'Waiting period can be set to up to 7 days.'}</DocElements.Paragraph>

      <DocElements.Image
        src="documentation-send-push-3.png"
        label="Adding Push element"
        alt="Adding Push element"
      />
      <DocElements.Paragraph>
        {'When everything’s set, press '}
        <strong>Apply.</strong>
      </DocElements.Paragraph>
    </DocElements>
  );
}
