import * as React from 'react';

// Analytics
import { sendPostEvent } from '@src/common/analytics';

// Store
import { useStoreActions, useStoreState } from '@src/store/store';

// Hooks
import { useAddNotifications, useUsername } from '@src/store/hooks';

// Enums
import { NotificationType, NOTIFICATION_TITLE_SUCCESS } from '@src/common/constants/notification';

// Types
import { TJourney } from '@src/common/types/journey';
import { TRequest } from '@src/store/models/requests/requests.types';
import { IUniqueId } from '@src/common/types/entities';

type TActionActivateById = (id: IUniqueId['id']) => Promise<TJourney>;
type TResult = [TActionActivateById, boolean];

export function useActivateJourneyByIdFromList(): TResult {
  const addNotifications = useAddNotifications();
  const username = useUsername();

  const activateJourneyById: TActionActivateById = useStoreActions(
    (actions): TActionActivateById => actions.journeys.activateById
  );

  const request: TRequest = useStoreState(
    (state): TRequest => state.requests.entities['journeys.activateById']
  );

  const activateById = React.useCallback<TActionActivateById>(
    async (id): Promise<TJourney> => {
      const response = await activateJourneyById(id);

      sendPostEvent('CustomerJourneyActivate', {
        userEmail: username,
        isFirst: response.isFirstActivated
      });

      addNotifications([
        {
          title: NOTIFICATION_TITLE_SUCCESS.activateJourney,
          type: NotificationType.SUCCESS
        }
      ]);

      return response;
    },
    [activateJourneyById, addNotifications, username]
  );

  return [activateById, Boolean(request)];
}
