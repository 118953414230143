import React from 'react';

// Components
import { FormInput } from '@src/ui/form/FormInput';
import { IconEnum, Button } from '@src/ui/kit';

// Types
import { IHeaderItemProps } from './HeaderItem.types';

// Styled
import { StyledItem, HeaderName, HeaderValue } from './styled';

export function HeaderItem(props: IHeaderItemProps): JSX.Element {
  const { header, name, index, removeHeaderItem, setFieldValue, errors, onBlur } = props;

  return (
    <StyledItem>
      <HeaderName>
        <FormInput
          name={`${name}[${index}].name`}
          value={header.name}
          onChange={setFieldValue}
          placeholder="Name"
          onBlur={onBlur}
          error={errors?.headers?.[index]?.name}
        />
      </HeaderName>
      <HeaderValue>
        <FormInput
          name={`${name}[${index}].value`}
          value={header.value}
          onChange={setFieldValue}
          onBlur={onBlur}
          placeholder="Value"
          error={errors?.headers?.[index]?.value}
        />
      </HeaderValue>
      <Button
        iconType={IconEnum.CLOSE_SMALL}
        color="secondary"
        view="ghost"
        size="compact"
        onClick={() => removeHeaderItem(index)}
      />
    </StyledItem>
  );
}
