import * as React from 'react';
import Konva from 'konva';
import { useStoreState } from '@src/store/store';

// Components
import {
  PointABSplitter,
  PointBooleanSplitter,
  PointEvent,
  PointApi,
  PointExit,
  PointReachabilityCheck,
  PointSegment,
  PointSendEmail,
  PointSendInApp,
  PointSendPush,
  PointSetTags,
  PointWait,
  PointWaitEvent,
  PointWebhook
} from '@src/canvas/components/Points';
import {
  useCenteredPoint,
  useModeMap,
  useActivePointIds,
  useDeletePoint,
  useAddPointIdsToBuffer,
  useClonePointsFromBuffer,
  useClearBufferPointIds,
  useUpdatePoint,
  useGetPointById,
  useOpenNewModal,
  useSetActivePoint,
  useActionShowTooltip,
  useActionHideTooltip,
  useArrowHoveredToDelete
} from '@src/store/hooks';
import { useDrawingArrow } from '@src/canvas/hooks';

// Enums
import { PointType } from '@src/common/constants/point';
import { EDITABLE_POINTS_TYPE } from '@src/common/constants';
import { ModalType } from '@src/common/constants/modals';

// Helpers
import { isConfiguredPoint } from '@src/common/helpers/isConfiguredPoint';

// Types
import { IPropsCommonPoint } from '@src/canvas/components/Points/types';
import { PointSendSms } from '@src/canvas/components/Points/PointSendSms';
import { IProps } from './DrawPoint.types';

function DrawPoint(props: IProps): JSX.Element {
  const { point, onMouseUp, onMouseDownOutput } = props;

  const centeredId = useCenteredPoint();
  const isDrawing = useStoreState((store) => store.arrow.isDrawingArrow);
  const modeMap = useModeMap();
  const activePointIds = useActivePointIds();
  const onDeletePoint = useDeletePoint();
  const addPointIdsToBuffer = useAddPointIdsToBuffer();
  const clonePointsFromBuffer = useClonePointsFromBuffer();
  const clearPointsFromBuffer = useClearBufferPointIds();
  const onUpdatePoint = useUpdatePoint();
  const getPointById = useGetPointById();
  const openNewModal = useOpenNewModal();
  const setActivePoint = useSetActivePoint();
  const showTooltip = useActionShowTooltip();
  const hideTooltip = useActionHideTooltip();
  const arrowHoveredToDelete = useArrowHoveredToDelete();
  const { moveDrawArrow } = useDrawingArrow();

  const handlePointShowStatTooltip = React.useCallback(
    (pointId, position): void => {
      if (modeMap.isRead) {
        const point = getPointById(pointId);

        showTooltip({
          id: point.id,
          type: `point-${point.type}-stat` as any,
          position,
          data: {
            pointId
          }
        });
      }
    },
    [getPointById, showTooltip, modeMap.isRead]
  );

  const handlePointHideStatTooltip = React.useCallback(
    (pointId, force): void => {
      if (modeMap.isRead) {
        hideTooltip({ id: pointId, force });
      }
    },
    [hideTooltip, modeMap.isRead]
  );

  const openDetailPoint = React.useCallback(
    (pointId): void => {
      const point = getPointById(pointId);

      if (point && EDITABLE_POINTS_TYPE.has(point.type)) {
        openNewModal({
          type: ModalType.DETAIL_POINT,
          data: {
            pointId: point.id
          }
        });
      }
    },
    [openNewModal, getPointById]
  );

  const openEditableFormPoint = React.useCallback(
    (pointId): void => {
      const point = getPointById(pointId);
      if (point && EDITABLE_POINTS_TYPE.has(point.type)) {
        openNewModal({
          type: ModalType.EDITING_FORM_POINT,
          data: {
            pointId: point.id
          }
        });
      }
    },
    [openNewModal, getPointById]
  );

  const handleClonePoint = React.useCallback((): void => {
    addPointIdsToBuffer([point.id]);
    clonePointsFromBuffer();
    clearPointsFromBuffer();
  }, [addPointIdsToBuffer, clonePointsFromBuffer, clearPointsFromBuffer, point.id]);

  const handlePointUpdate = (event: Konva.KonvaEventObject<MouseEvent>): void => {
    const { x, y } = event.currentTarget.getAttrs();
    onUpdatePoint({ id: point.id, position: { x, y } });
  };

  const handlePointEdit = (event: Konva.KonvaEventObject<MouseEvent>): void => {
    event.cancelBubble = true;

    if (modeMap.isRead) {
      openDetailPoint(point.id);
    } else {
      openEditableFormPoint(point.id);
    }
  };

  const onMouseDown = (event: Konva.KonvaEventObject<MouseEvent>): void => {
    if (event.evt.ctrlKey || event.evt.metaKey) {
      setActivePoint(point.id, true);
    } else if (event.evt.shiftKey) {
      return null;
    } else {
      setActivePoint(point.id);
    }

    return null;
  };

  const handlePointDelete = (): void => {
    onDeletePoint(point.id);
  };

  const handlePointMouseEnter = React.useCallback(
    (event: Konva.KonvaEventObject<MouseEvent>): void => {
      const pointAttrs = event.currentTarget.getAttrs();
      const pointBox = event.currentTarget.getClientRect();
      const canvasBox = (event.evt.currentTarget as HTMLCanvasElement).getBoundingClientRect();

      const tooltipPosition = {
        x: pointBox.x + pointBox.width / 2 + canvasBox.left,
        y: pointBox.y - 6 + canvasBox.top
      };

      handlePointShowStatTooltip(pointAttrs.pointId, tooltipPosition);
    },
    [handlePointShowStatTooltip]
  );

  const handlePointMouseLeave = React.useCallback<Konva.KonvaEventListener<null, MouseEvent>>(
    (event: Konva.KonvaEventObject<MouseEvent>) => {
      const pointAttrs = event.currentTarget.getAttrs();

      handlePointHideStatTooltip(pointAttrs.pointId, true);
    },
    [handlePointHideStatTooltip]
  );

  const propsCommonPoint: IPropsCommonPoint = {
    id: point.id,
    x: point.position.x,
    y: point.position.y,
    title: point.title,
    isInvalid: point.id === centeredId,
    isDraggable: !isDrawing,
    isDrawingArrow: isDrawing,
    isActive: activePointIds.includes(point.id),
    isLinkedOutput: point.outputs.length > 0 || (activePointIds.includes(point.id) && isDrawing),
    isConfigured: isConfiguredPoint(point),
    onMouseUp,
    onDragMove: handlePointUpdate,
    onDblClick: handlePointEdit,
    onDelete: modeMap.isEdit ? handlePointDelete : null,
    onClone: modeMap.isEdit ? handleClonePoint : null,
    onMouseDown,
    onMouseMove: isDrawing ? moveDrawArrow : undefined,
    onMouseDownOutput: modeMap.isEdit ? onMouseDownOutput : null,
    onMouseEnter: handlePointMouseEnter,
    onMouseLeave: handlePointMouseLeave,
    arrowHoveredToDelete
  };

  switch (point.type) {
    case PointType.EVENT: {
      return <PointEvent key={point.id} data={point.data} propsCommonPoint={propsCommonPoint} />;
    }

    case PointType.SEGMENT: {
      return <PointSegment key={point.id} data={point.data} propsCommonPoint={propsCommonPoint} />;
    }

    case PointType.API: {
      return <PointApi key={point.id} data={point.data} propsCommonPoint={propsCommonPoint} />;
    }

    case PointType.WAIT: {
      return (
        <PointWait
          key={point.id}
          data={point.data}
          outputs={point.outputs}
          propsCommonPoint={propsCommonPoint}
        />
      );
    }

    case PointType.SET_TAGS: {
      return <PointSetTags key={point.id} data={point.data} propsCommonPoint={propsCommonPoint} />;
    }

    case PointType.WEBHOOK: {
      return <PointWebhook key={point.id} propsCommonPoint={propsCommonPoint} />;
    }

    case PointType.SEND_PUSH: {
      return (
        <PointSendPush
          key={point.id}
          data={point.data}
          outputs={point.outputs}
          propsCommonPoint={propsCommonPoint}
        />
      );
    }

    case PointType.SEND_EMAIL: {
      return (
        <PointSendEmail
          key={point.id}
          data={point.data}
          propsCommonPoint={propsCommonPoint}
          outputs={point.outputs}
        />
      );
    }

    case PointType.SEND_IN_APP: {
      return (
        <PointSendInApp key={point.id} data={point.data} propsCommonPoint={propsCommonPoint} />
      );
    }

    case PointType.SEND_SMS: {
      return (
        <PointSendSms
          key={point.id}
          data={point.data}
          outputs={point.outputs}
          propsCommonPoint={propsCommonPoint}
        />
      );
    }

    case PointType.EXIT: {
      return <PointExit key={point.id} propsCommonPoint={propsCommonPoint} />;
    }

    case PointType.BOOLEAN_SPLITTER: {
      return (
        <PointBooleanSplitter
          key={point.id}
          countContent={2}
          outputs={point.outputs}
          data={point.data}
          propsCommonPoint={propsCommonPoint}
        />
      );
    }

    case PointType.AB_SPLITTER: {
      return (
        <PointABSplitter
          key={point.id}
          countContent={point.data.split.length}
          outputs={point.outputs}
          data={point.data}
          propsCommonPoint={propsCommonPoint}
        />
      );
    }

    case PointType.WAIT_EVENT: {
      return (
        <PointWaitEvent
          key={point.id}
          outputs={point.outputs}
          data={point.data}
          propsCommonPoint={propsCommonPoint}
        />
      );
    }

    case PointType.REACHABILITY_CHECK: {
      return (
        <PointReachabilityCheck
          key={point.id}
          outputs={point.outputs}
          data={point.data}
          propsCommonPoint={propsCommonPoint}
        />
      );
    }

    default: {
      return null;
    }
  }
}

export default DrawPoint;
