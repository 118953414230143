import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgLockMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5.25A3.75 3.75 0 008.25 9v1.25H8A1.75 1.75 0 006.25 12v6c0 .966.784 1.75 1.75 1.75h8A1.75 1.75 0 0017.75 18v-6A1.75 1.75 0 0016 10.25h-.25V9A3.75 3.75 0 0012 5.25zm2.25 5V9a2.25 2.25 0 00-4.5 0v1.25h4.5zm-6 1.5H8a.25.25 0 00-.25.25v6c0 .138.112.25.25.25h8a.25.25 0 00.25-.25v-6a.25.25 0 00-.25-.25H8.25z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgLockMediumLined;
