import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgEditMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.145 3.85l4.33 2.5a.75.75 0 01.275 1.025L10.5 18.2a.77.77 0 01-.032.05H17.5a.75.75 0 010 1.5h-11a.75.75 0 01-.749-.709.754.754 0 01-.038-.11l-.459-1.705v-.002l-.458-1.704a.75.75 0 01.074-.57l6.25-10.825a.75.75 0 011.025-.275zm2.93 3.425l-1.124 1.948-3.032-1.75 1.125-1.948 3.032 1.75zm-1.874 3.247l-3.032-1.75-3.844 6.659.297 1.106 1.628.94 1.106-.296 3.845-6.659z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgEditMediumLined;
