import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPlatformLogoWindowsMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 6.272l6.539-.89.003 6.307-6.536.037L4 6.272zm6.536 6.144l.005 6.312-6.536-.898v-5.457l6.53.043zm.792-7.15L19.998 4v7.609l-8.67.069V5.264zM20 12.474l-.002 7.574-8.67-1.223-.012-6.365 8.684.014z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPlatformLogoWindowsMediumFilled;
