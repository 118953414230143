import * as React from 'react';
import _reduce from 'lodash/reduce';

// Store Hooks
import {
  useComputedGetTooltipById,
  useComputedGetPointStatByPointId,
  useActionShowTooltip,
  useActionHideTooltip
} from '@src/store/hooks';
import { usePointsToGoalsStat } from '@src/store/hooks/statistics/useGoalsStatistic';
import { useStoreState } from '@src/store/store';

// Types
import { IUniqueId } from '@src/common/types/entities';
import { Tooltip } from '@src/canvas/components/Tooltip';
import { TooltipInfoField } from '@src/canvas/components/TooltipInfoField';

interface ITooltipPointErrorStatsProps {
  tooltipId: IUniqueId['id'];
}

function TooltipPointErrorStats(props: ITooltipPointErrorStatsProps): JSX.Element {
  const getTooltipById = useComputedGetTooltipById();
  const getPointStatByPointId = useComputedGetPointStatByPointId();
  const getGoalStatByPointId = usePointsToGoalsStat();
  const getPointDropOffs = useStoreState((state) => state.statistics.getPointDropOffs);

  const showTooltip = useActionShowTooltip();
  const hideTooltip = useActionHideTooltip();

  const tooltip = getTooltipById(props.tooltipId);
  const dropOffs = getPointDropOffs(tooltip.data.pointId);
  const point = getPointStatByPointId(tooltip.data.pointId);

  const goalsStat = getGoalStatByPointId(tooltip.data.pointId);
  const allDropOffs = dropOffs.reduce((prevValue, dropOff) => prevValue + dropOff.value, 0);

  const goalsReached =
    goalsStat?.length > 0 ? _reduce(goalsStat, (sum, goal) => sum + goal.Count, 0) : 0;

  const handleTooltipMouseEnter = React.useCallback<(tooltipId: IUniqueId['id']) => void>(
    (): void => showTooltip(tooltip),
    [tooltip, showTooltip]
  );

  const handleTooltipMouseLeave = React.useCallback<() => void>(
    (): void => hideTooltip({ id: props.tooltipId }),
    [props.tooltipId, hideTooltip]
  );

  if (!point) {
    return null;
  }

  return (
    <Tooltip
      id={props.tooltipId}
      x={tooltip.position.x}
      y={tooltip.position.y}
      onMouseEnter={handleTooltipMouseEnter}
      onMouseLeave={handleTooltipMouseLeave}
    >
      <TooltipInfoField label="Goals Reached" value={goalsReached} />
      <TooltipInfoField label="Drop-offs" value={allDropOffs} />
    </Tooltip>
  );
}

export default React.memo(TooltipPointErrorStats);
