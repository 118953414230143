import * as React from 'react';

// Store Hooks
import { useGetPresetsByApplicationCode, useLoadPresets } from '@src/store/hooks';

// Types
import { TPreset } from '@src/common/types/preset';

export function usePresetsByAppCodeWithAutoLoad(appCode: string): [TPreset[], boolean] {
  const getPresetsByApplicationCode = useGetPresetsByApplicationCode();
  const presets = getPresetsByApplicationCode(appCode);
  const [loadPresetsByAppCode, isLoadingPresets] = useLoadPresets();

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (appCode && !isLoadingPresets) {
      loadPresetsByAppCode(appCode);
    }
  }, [appCode]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return [presets, isLoadingPresets];
}
