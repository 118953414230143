import * as React from 'react';

// Store
import { useStoreActions } from '@src/store/store';
import { IUniqueId } from '@src/common/types/entities';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ArgsHideTooltip {
  id: IUniqueId['id'];
  delay?: number;
  force?: boolean;
}
type TActionHideTooltip = ({ id, delay, force }: ArgsHideTooltip) => void;

export function useActionHideTooltip(): TActionHideTooltip {
  const actionHideTooltipWithDelay = useStoreActions(
    (actions): typeof actions.tooltip.hideWithDelay => actions.tooltip.hideWithDelay
  );

  const actionHideTooltip = useStoreActions(
    (actions): typeof actions.tooltip.hide => actions.tooltip.hide
  );

  const handleTooltipHide = React.useCallback<TActionHideTooltip>(
    ({ id, delay, force }): void => {
      if (force) {
        actionHideTooltip({ id });
      } else {
        actionHideTooltipWithDelay({ id, delay });
      }
    },
    [actionHideTooltipWithDelay, actionHideTooltip]
  );

  return handleTooltipHide;
}
