import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgBackupMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 10.61V9.5a2.5 2.5 0 11-2.468 2.898C9.497 12.18 9.32 12 9.1 12h-.7c-.22 0-.402.18-.38.4A4 4 0 1012 8V6.89a.3.3 0 00-.492-.23L9.277 8.52a.3.3 0 000 .46l2.23 1.86a.3.3 0 00.493-.23z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.645 3.05c.232-.067.478-.067.71 0l6.726 1.917c.533.152.908.624.915 1.175.026 2-.04 6.764-1.162 9.31-1.13 2.563-4.896 4.694-6.3 5.418a1.16 1.16 0 01-1.068 0c-1.404-.724-5.17-2.855-6.3-5.419-1.121-2.545-1.188-7.309-1.162-9.309a1.244 1.244 0 01.915-1.175l6.726-1.917zM5.502 6.36L12 4.509l6.498 1.853c.009.988-.01 2.521-.147 4.1-.151 1.733-.434 3.352-.89 4.385-.404.919-1.37 1.901-2.574 2.805-1.08.81-2.2 1.445-2.887 1.806a22.134 22.134 0 01-2.887-1.806c-1.205-.904-2.17-1.886-2.574-2.805-.456-1.033-.739-2.652-.89-4.384a44.327 44.327 0 01-.147-4.101z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgBackupMediumLined;
