import * as React from 'react';
import { Group, Rect } from 'react-konva';

export function Separator(): JSX.Element {
  return (
    <Group x={37} y={4} height={100} width={100}>
      <Rect
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
        x={-2}
        y={-1}
        width={1}
        height={24}
        fill="#E6E6E6"
        cornerRadius={4}
      />
    </Group>
  );
}
