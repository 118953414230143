import styled from 'styled-components';
import { Link, NavLink as NavRouterLink } from 'react-router-dom';
import { FontSize, Color, FontWeight, Spacing } from '@pushwoosh/kit-constants';
import { ILinkProps } from './Link.types';

export const RouterLink = styled(Link)<ILinkProps>`
  display: flex;
  align-items: center;
  height: auto;
  justify-content: ${({ $isFullWidth }) => ($isFullWidth ? 'space-between' : 'flex-start')};
  width: ${({ $isFullWidth }) => ($isFullWidth ? '100%' : 'auto')};
  fill: ${({ color }) => color || Color.PRIMARY};
  color: ${({ color }) => color || Color.PRIMARY};
  font-weight: ${({ weight = FontWeight.REGULAR }) => weight};
  font-size: ${({ size = FontSize.SMALL }) => size};
  text-decoration: none;

  &:hover {
    color: ${Color.PRIMARY_HOVER};
    fill: ${Color.PRIMARY_HOVER};
  }
`;

export const ExternalLink = RouterLink.withComponent('a');
export const StyledNavLink = RouterLink.withComponent(NavRouterLink);

export const BeforeWrap = styled.div`
  margin-right: ${Spacing.S3};
`;

export const AfterWrap = styled.div`
  margin-left: ${Spacing.S3};
`;
