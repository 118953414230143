// Enums
import { CHANNEL_GROUP, FLOW_GROUP, ENTRY_GROUP, PointType } from '@src/common/constants/point';
import { Color } from '@pushwoosh/kit-constants';

export function getPointIconColorByType(type: PointType): string {
  if (CHANNEL_GROUP.has(type)) {
    return Color.EXCEPTIONAL;
  }

  if (ENTRY_GROUP.has(type)) {
    return Color.SUCCESS;
  }

  if (FLOW_GROUP.has(type)) {
    return Color.BRIGHT;
  }

  return null;
}
