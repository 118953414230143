import React from 'react';

// Components
import { FormControl } from '@src/ui/form/FormControl';
import { NumberStepper } from '@pushwoosh/kit-number-stepper';

interface IProps {
  label?: string;
  error?: string;
  name: string;
  value: number;
  min?: number;
  max?: number;
  step?: number;
  dimension?: string;
  onChange: (value: number) => void;
  isDisabled?: boolean;
}

export function FormNumberStepper(props: IProps): JSX.Element {
  const { label, error, name, value, min, max, step, dimension, onChange, isDisabled } = props;

  return (
    <FormControl label={label} error={error}>
      <NumberStepper
        name={name}
        min={min}
        step={step}
        value={value}
        max={max}
        dimension={dimension}
        onChange={({ value }) => onChange(value)}
        isDisabled={isDisabled}
      />
    </FormControl>
  );
}
