import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSetTagPointFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={24} height={24} rx={4} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 11.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm-4.496 4.193C5 15.772 5 15.822 5 15.922c0 .068 0 .102.002.131a1 1 0 00.94.94c.029.002.063.002.132.002h6.848c.072 0 .109 0 .14-.002a1 1 0 00.936-.936c.002-.03.002-.067.002-.14 0-.105 0-.157-.005-.24-.07-1.259-1.167-2.525-2.402-2.774a.612.612 0 01-.059-.013h-.004a13.558 13.558 0 00-4.054.005l-.007.002a1.249 1.249 0 01-.054.011c-1.248.248-2.344 1.514-2.41 2.785zM19.5 8.5a.5.5 0 01-.5.5h-4.5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5H19a.5.5 0 01.5.5v1zM14 12a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v1z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgSetTagPointFilled;
