import styled from 'styled-components';
import {
  Spacing,
  UnitSize,
  FontSize,
  Color,
  FontWeight,
  ShapeRadius
} from '@pushwoosh/kit-constants';

export const ToolsWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NavButton = styled.button`
  padding: ${Spacing.S0} ${Spacing.S3};
  height: ${UnitSize.ICON_MEDIUM};
  font-size: ${FontSize.REGULAR};
  font-weight: ${FontWeight.MEDIUM};
  color: ${Color.MAIN};
  background: ${Color.SOFT_LIGHT};
  border-radius: ${ShapeRadius.CONTROL};
  border: none;
  outline: transparent;
  cursor: pointer;

  &:hover {
    background: ${Color.BRIGHT_LIGHT};
    color: ${Color.PRIMARY_HOVER};
  }

  &:active {
    color: ${Color.PRIMARY_PRESSED};
  }
`;

export const StyledNavButton = styled(NavButton)`
  margin-right: ${Spacing.S1};
`;

export const Navigation = styled.div`
  display: flex;
  align-items: center;
`;

export const ArrowWrap = styled.span`
  cursor: pointer;
  border-radius: ${ShapeRadius.CONTROL};

  &:not(:last-child) {
    margin-right: ${Spacing.S3};
  }
  & svg > path {
    fill: ${Color.SOFT};
  }

  &:hover {
    background: ${Color.BRIGHT_LIGHT};

    & svg > path {
      fill: ${Color.PRIMARY_HOVER};
    }
  }

  &:active {
    background: ${Color.BRIGHT_LIGHT};

    & svg > path {
      fill: ${Color.PRIMARY_PRESSED};
    }
  }
`;

export const DecadeLabel = styled.div`
  font-weight: ${FontWeight.MEDIUM};
  font-size: ${FontSize.REGULAR};
  color: ${Color.MAIN};
`;
