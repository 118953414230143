import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgTilesMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5.25a.75.75 0 00-.75.75v4c0 .414.336.75.75.75h4a.75.75 0 00.75-.75V6a.75.75 0 00-.75-.75H6zm.75 4v-2.5h2.5v2.5h-2.5zM6 13.25a.75.75 0 00-.75.75v4c0 .414.336.75.75.75h4a.75.75 0 00.75-.75v-4a.75.75 0 00-.75-.75H6zm.75 4v-2.5h2.5v2.5h-2.5zM13.25 6a.75.75 0 01.75-.75h4a.75.75 0 01.75.75v4a.75.75 0 01-.75.75h-4a.75.75 0 01-.75-.75V6zm1.5.75v2.5h2.5v-2.5h-2.5zM14 13.25a.75.75 0 00-.75.75v4c0 .414.336.75.75.75h4a.75.75 0 00.75-.75v-4a.75.75 0 00-.75-.75h-4zm.75 4v-2.5h2.5v2.5h-2.5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgTilesMediumLined;
