import { Color } from '@pushwoosh/kit-constants';

export function getChartFill(isCurrent: boolean, isWinner: boolean, isDisabled: boolean) {
  if (isDisabled) {
    return Color.LOCKED;
  }

  if (isWinner && isCurrent) {
    return '#53B200';
  }

  if (isCurrent) {
    return '#8AB5E5';
  }

  return Color.DIVIDER;
}
