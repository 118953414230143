import * as React from 'react';

// Store
import { useStoreActions, useStoreState } from '@src/store/store';

// Types
import { IUniqueId } from '@src/common/types/entities';
import { IJourney } from '@src/common/types/journey';
import { TRequest } from '@src/store/models/requests/requests.types';

// Types
type TActionAutoSaveById = (args: { id: IUniqueId['id'] }) => Promise<IJourney>;
type TAutoSaveById = (id: IUniqueId['id']) => Promise<void>;

export function useSaveJourney(): boolean {
  const autoSaveById = useStoreActions(
    (actions): TActionAutoSaveById => actions.journeys.autoSaveById
  );

  const handleJourneyAutoSaveById: TAutoSaveById = React.useCallback<TAutoSaveById>(
    async (id): Promise<void> => {
      await autoSaveById({
        id
      });
    },
    [autoSaveById]
  );

  const currentJourneyID: IUniqueId['id'] = useStoreState(
    (state): IUniqueId['id'] => state.journeys.currentJourneyId
  );

  const isAutoSave: TRequest = useStoreState(
    (state): TRequest => state.requests.entities['journeys.autoSaveById']
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (!isAutoSave) {
      handleJourneyAutoSaveById(currentJourneyID);
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return Boolean(isAutoSave);
}
