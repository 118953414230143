import * as React from 'react';

// Analytics
import { sendMixpanelEvent } from '@src/common/analytics';
import { SidebarType } from '@src/common/constants/sidebars';

// Store Hooks
import {
  useCurrentJourneyId,
  useGetJourneyById,
  useGetPointById,
  useOpenNewModal,
  useUpdatePoint,
  useEditJourneyById
} from '@src/store/hooks';
import { useStoreActions, useStoreState } from '@src/store/store';

// UI Hooks
import { useForm, useSaveJourney } from '@src/ui/hooks';

// UI Components
import { Modal } from '@src/ui/kit/Modal';
import { DefaultButtons } from '@src/ui/kit/ModalButtons';
import { Loading } from '@src/ui/kit/Loading';
import { Paragraph } from '@pushwoosh/kit-typography';
import {
  ContainerDynamicValues,
  DynamicValues,
  TypeData
} from '@src/ui/features/Webhook/components/DynamicValues';
import { UrlSettings } from '@src/ui/features/Webhook/components/UrlSettings';

// Types
import { IUniqueId } from '@src/common/types/entities';
import { TWebhookPoint } from '@src/common/types/points';
import { FormInput } from '@src/ui/form';
import { isEqual } from 'lodash';
import { FormBlock } from '@src/ui/form/styled';
import { Section, Button, IconEnum } from '@src/ui/kit';
import { JourneyStatus, ModalType } from '@src/common/constants';
import { IWebhookFormValues } from '../Webhook.types';

// Helpers
import { getHeadersList } from '../helpers/getHeadersList';
import { validate } from '../validation';

// Lazy load components
const JSONEditor = React.lazy(
  () => import('@src/ui/features/Webhook/components/JSONEditor/JSONEditor')
);

interface IProps {
  pointId: IUniqueId['id'];
  closeModal: () => void;
}

const names = {
  url: 'url',
  headers: 'headers',
  dynamicValues: 'dynamicValues',
  requestTemplate: 'requestTemplate',
  title: 'title'
};

export function Webhook(props: IProps): JSX.Element {
  const { pointId, closeModal } = props;
  const getPointById = useGetPointById();
  const updatePoint = useUpdatePoint();
  const point = getPointById(pointId) as TWebhookPoint;
  const isNotSavedJourney = useSaveJourney();
  const showDocumentationMenu = useStoreActions((actions) => actions.documentationMenu.show);
  const currentJourneyId = useCurrentJourneyId();
  const getJourneyById = useGetJourneyById();
  const openNewModal = useOpenNewModal();
  const editPoint = useStoreActions((actions) => actions.points.update);
  const handleEditJourneyById = useEditJourneyById();
  const sidebars = useStoreState((state) => state.sidebars);
  const openNewSidebar = useStoreActions((actions) => actions.sidebars.openNewSidebar);
  const closeAllSidebars = useStoreActions((actions) => actions.sidebars.closeAllSidebars);

  const { status } = getJourneyById(currentJourneyId);

  const headerList = getHeadersList(point?.data?.headers ?? {});

  const initialValues = React.useMemo(
    () => ({
      title: point.title,
      url: point?.data?.url ?? '',
      headers: headerList ?? [],
      dynamicValues: {
        type: TypeData.DEVICE
      },
      requestTemplate: point?.data?.request_template ?? ''
    }),
    [point, headerList]
  );
  const isJourneyActive = status === JourneyStatus.RUNNING;

  const { values, errors, setFieldValue, handleBlur, handleSubmit, setFieldError } =
    useForm<IWebhookFormValues>({
      initialValues,
      validate,
      onSubmit(formValues) {
        const changePoint = {
          id: pointId,
          title: formValues.title,
          data: {
            url: '',
            headers: {},
            method: 'POST',
            request_template: ''
          }
        };

        if (values.url) {
          changePoint.data.url = values.url;
        }

        if (values.headers.length > 0) {
          const headersMap: { [key: string]: string } = {};

          values.headers.forEach((header) => {
            headersMap[header.name] = header.value;
          });

          changePoint.data.headers = headersMap;
        }

        if (values.requestTemplate) {
          changePoint.data.request_template = values.requestTemplate;
        }

        if (!isJourneyActive) {
          updatePoint(changePoint);

          closeModal();
        } else {
          editPoint(changePoint);

          handleEditJourneyById(currentJourneyId);

          sendMixpanelEvent({
            eventName: 'JourneyEditModeChangesApplied',
            eventData: {
              PointType: 'Webhook'
            }
          });

          closeModal();

          openNewModal({
            type: ModalType.DETAIL_POINT,
            data: {
              pointId
            }
          });
        }
      },
      validateOnBlur: true,
      validateOnChange: false,
      enableReinitialize: true
    });

  const handleCancel = React.useCallback(() => {
    if (status === JourneyStatus.RUNNING) {
      sendMixpanelEvent({
        eventName: 'JourneyEditModeChangesCancelled',
        eventData: {
          PointType: 'Webhook'
        }
      });
    } else {
      sendMixpanelEvent({
        eventName: 'ConfigurePointCancelled',
        eventData: {
          PointType: 'Webhook',
          PointChanged: !isEqual(values, initialValues)
        }
      });
    }

    closeModal();
  }, [closeModal, values, initialValues, status]);

  const onShowDocumentation = React.useCallback((): void => {
    if (sidebars.isOpened) {
      closeAllSidebars();
    } else {
      openNewSidebar({
        type: SidebarType.DOCUMENTATION
      });
      showDocumentationMenu();
      sendMixpanelEvent({ eventName: 'ShowDocumentation' });
    }
  }, [showDocumentationMenu, sidebars, closeAllSidebars, openNewSidebar]);

  return (
    <Modal
      title="Webhook"
      isOpen
      footerRight={
        <DefaultButtons
          onClickCancelButton={handleCancel}
          onClickActionButton={handleSubmit}
          actionButtonName={status === JourneyStatus.RUNNING ? 'Save' : 'Apply'}
        />
      }
      footerLeft={
        <Button
          color="primary"
          size="field"
          view="ghost"
          iconPosition="left"
          iconType={IconEnum.DOCS_MEDIUM_LINED}
          onClick={onShowDocumentation}
        >
          How to Use
        </Button>
      }
    >
      <Section direction="column">
        <Paragraph>
          Share data with just about any other service out there. You can configure Webhooks to
          automatically notify external service when a customer has taken a particular action, or to
          send data into your analytics tool and much more. Use Dynamic data and combine Pushwoosh
          data with your request.
        </Paragraph>
        <FormBlock>
          <FormInput
            label="Point Name"
            name="title"
            value={values.title}
            onChange={setFieldValue}
          />
        </FormBlock>
      </Section>

      <Section direction="column">
        <UrlSettings
          values={values}
          errors={errors}
          names={names}
          setFieldValue={setFieldValue}
          onBlurField={handleBlur}
        />
        <React.Suspense fallback={<Loading size="medium" />}>
          <ContainerDynamicValues>
            <JSONEditor
              value={values.requestTemplate}
              name="requestTemplate"
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              pointId={pointId as IUniqueId['id']}
              onSubmit={handleSubmit}
              withFullScreen
            />
            <DynamicValues
              values={values}
              errors={errors}
              names={names}
              isNotSavedJourney={isNotSavedJourney}
              setFieldValue={setFieldValue}
              onBlurField={handleBlur}
            />
          </ContainerDynamicValues>
          <Paragraph>
            If there is no value for the Dynamic Data placeholder when the POST request is sent, the
            null value will be transmitted.
          </Paragraph>
        </React.Suspense>
      </Section>
    </Modal>
  );
}
