import mixpanel from 'mixpanel-browser';

// Enums
import { SubscriptionPlan } from '@src/common/constants/subscriptionPlan';

// Types
import { IUniqueId } from '../types/entities';

const { isProduction } = process.env;

const production = isProduction && window.location.hostname === 'app.pushwoosh.com';

type IdentifyUser = {
  accountId: number;
  userId: IUniqueId['id'];
  plan: SubscriptionPlan;
  email: string;
};

export const initMixpanel = (): void => {
  if (production) {
    mixpanel.init('4169a4e38679ed49785b430a1049238e');
  }
};

export const identifyUser = ({ userId, accountId, plan, email }: IdentifyUser): void => {
  if (production) {
    const username = email?.split('@')?.[0];
    const domain = email?.split('@')?.[1];

    mixpanel.identify(userId);

    if (domain === 'pushwoosh.com') {
      mixpanel.set_group('company', 'pushwoosh');
    }

    mixpanel.people.set({ Restriction: plan });
    mixpanel.people.set({ AccountID: accountId });
    mixpanel.people.set({ $email: email });
    mixpanel.people.set({ $name: username });
    mixpanel.people.set({ $pushwoosh_user_id: userId });
  }
};

type SendEvent = {
  eventName: string;
  eventData?: {
    [key: string]: unknown;
  };
};

export const sendMixpanelEvent = ({ eventName, eventData }: SendEvent): void => {
  if (production) {
    mixpanel.track(eventName, eventData);
  }
};
