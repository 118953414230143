import * as React from 'react';

import { ICommonEvent } from '@src/common/types/event';

type TResult = {
  listEvents: ICommonEvent[];
  updateEvent: (indexElement: number, data: ICommonEvent) => void;
  removeEvent: (id: number) => void;
  addEvent: () => void;
};

export const useEditEvents = (events: ICommonEvent[]): TResult => {
  const [listEvents, updateEvents] = React.useState(events);

  const updateEvent = React.useCallback(
    (indexElement: number, data: ICommonEvent) => {
      const castConditions = data.eventConditions.map((condition) => {
        const newCondition = { ...condition };
        if (typeof condition.value === 'string') {
          newCondition.value = condition.value.trim();
        }

        return newCondition;
      });

      data.eventConditions = castConditions;

      const newListEvents = listEvents.map((item, index) => {
        if (index === indexElement) {
          return {
            ...data
          };
        }
        return item;
      });
      updateEvents([...newListEvents]);
    },
    [listEvents, updateEvents]
  );

  const addEvent = React.useCallback(() => {
    updateEvents([
      ...listEvents,
      {
        name: '',
        isThrowOut: false,
        eventConditions: []
      }
    ]);
  }, [listEvents, updateEvents]);

  const removeEvent = React.useCallback(
    (id: number) => {
      if (listEvents.length > 1) {
        const newStateEvents = listEvents.filter((_, index): boolean => id !== index);
        updateEvents([...newStateEvents]);
      }
      if (listEvents.length === 1) {
        updateEvents([
          {
            name: '',
            isThrowOut: false,
            eventConditions: []
          }
        ]);
      }
    },
    [listEvents]
  );

  React.useEffect(() => {
    if (listEvents.length === 0) {
      addEvent();
    }
  }, [listEvents, addEvent]);

  return {
    listEvents,
    updateEvent,
    removeEvent,
    addEvent
  };
};
