import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgCheckRoundMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.03 10.53a.75.75 0 10-1.06-1.06l-4.47 4.47-1.47-1.47a.75.75 0 00-1.06 1.06l2 2a.75.75 0 001.06 0l5-5z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.473 6.473 2 12 2s10 4.473 10 10-4.473 10-10 10S2 17.527 2 12zm1.563 0A8.433 8.433 0 0012 20.438 8.433 8.433 0 0020.438 12 8.433 8.433 0 0012 3.562 8.433 8.433 0 003.562 12z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgCheckRoundMediumLined;
