import React from 'react';
import autosize from 'autosize';

// Types
import { IAutosizeTextareaProps } from './AutosizeTextarea.types';

// Styled
import { StyledTextarea } from './styled';

export function AutosizeTextarea(props: IAutosizeTextareaProps): JSX.Element {
  const textareaRef = React.useRef<HTMLTextAreaElement>();
  const { value, onChange, placeholder } = props;

  React.useEffect(() => {
    autosize(textareaRef.current);
    textareaRef.current.focus();
  }, []);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target;

      onChange(value);
    },
    [onChange]
  );

  return (
    <StyledTextarea
      ref={textareaRef}
      rows={1}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
    />
  );
}
