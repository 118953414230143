import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgWebhookPoint(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 8.9a4.567 4.567 0 00-2.848 8.137c.038.03.079.057.121.081l.865.487a1 1 0 01.382 1.361l-.245.436-2.953 5.249c-.13.232-.152.506-.13.772a2.136 2.136 0 11-3.078-1.737c.24-.119.466-.28.598-.514l1.877-3.336c.23-.41.138-.922-.18-1.27a7.567 7.567 0 1112.969-3.415c-.122.538-.705.8-1.222.608l-.937-.35c-.518-.192-.77-.769-.694-1.316A4.567 4.567 0 0020 8.9zm2.269 5.493c-.134-.231-.158-.507-.139-.774a2.136 2.136 0 10-3.06 1.767c.24.117.466.275.6.507l2.991 5.171.25.433a1 1 0 001.367.365l.495-.286a4.567 4.567 0 11-.062 8.015c-.482-.27-1.11-.245-1.478.168l-.665.746c-.367.413-.334 1.051.123 1.361a7.567 7.567 0 102.68-13.67c-.462.097-.951-.084-1.188-.493l-1.914-3.31zm4.668 13.343a2.136 2.136 0 10-1.2-3.903c-.221.15-.472.267-.74.267h-6.613a.75.75 0 00-.75.75v.75a4.567 4.567 0 11-6.305-4.225c.51-.21.884-.717.79-1.261l-.169-.986c-.093-.544-.613-.916-1.14-.752a7.569 7.569 0 002.257 14.791 7.57 7.57 0 007.213-5.274c.144-.454.547-.793 1.024-.793h3.694c.268 0 .518.117.74.267.341.233.754.369 1.199.369z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgWebhookPoint;
