import React from 'react';
import { Modal, ModalBody } from '@pushwoosh/kit-modal';
import { RichMediaParcel } from '../RichMediaParcel';

type Props = {
  isOpen: boolean;
  applicationCode: string;
  richMediaCode: string;
  campaignCode: string;
  messageCode: string;
  onClose: () => void;
};

export function RichMediaStatstic(props: Props) {
  const { isOpen, applicationCode, richMediaCode, campaignCode, messageCode, onClose } = props;

  return (
    <Modal size="full" isOpen={isOpen} isSide onClose={onClose}>
      <ModalBody>
        <RichMediaParcel
          applicationCode={applicationCode}
          richMediaCode={richMediaCode}
          campaignCode={campaignCode}
          messageCode={messageCode}
          onClose={onClose}
        />
      </ModalBody>
    </Modal>
  );
}
