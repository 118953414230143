import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgChangeSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.03 1.97a.75.75 0 10-1.06 1.06l.72.72H5.5A2.75 2.75 0 002.75 6.5v1a.75.75 0 001.5 0v-1A1.25 1.25 0 015.5 5.25h5.19l-.72.72a.75.75 0 001.06 1.06l2-2a.748.748 0 000-1.06l-2-2zM6.03 8.97a.75.75 0 010 1.06l-.72.72h5.19a1.25 1.25 0 001.25-1.25v-1a.75.75 0 011.5 0v1a2.75 2.75 0 01-2.75 2.75H5.31l.72.72a.75.75 0 11-1.06 1.06l-2-2a.748.748 0 010-1.06l2-2a.75.75 0 011.06 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgChangeSmallLined;
