import * as React from 'react';

// Components
import { DocElements } from '@src/ui/features/Documentation/components/DocElements';

export function DocumentationPointABSplit(): JSX.Element {
  return (
    <DocElements>
      <DocElements.Paragraph>
        {
          "Test which sequence of messages works best with the A/B/n Split and adjust your communications to your audience's needs and preferences."
        }
      </DocElements.Paragraph>
      <DocElements.Paragraph>
        {
          'Place the A/B/n Split element to the canvas after any other element. Double-click it to set the percentage of users going to each branch. According to the percentage you set, journey travelers who reach this journey step will be split randomly into two branches.'
        }
      </DocElements.Paragraph>
      <DocElements.Paragraph>
        {'By default, the journey travelers are split in half, 50% for each branch.'}
      </DocElements.Paragraph>
      <DocElements.Image
        src="documentation-point-absplit.png"
        label="A/B/n Split"
        alt="A/B/n Split"
      />
    </DocElements>
  );
}
