import * as React from 'react';

// Store
import { useStoreState } from '@src/store/store';

// Types
import { TPoints } from '@src/common/types/points';
import { IUniqueId } from '@src/common/types/entities';

type TGetPointById = (pointId: IUniqueId['id']) => TPoints;
type TResult = TGetPointById;

export function useGetPointById(): TResult {
  const getPointById: TGetPointById = useStoreState<TGetPointById>(
    (state): TGetPointById => state.points.pointById
  );

  const handleGetPointById: TGetPointById = React.useCallback<TGetPointById>(
    (pointId): TPoints => getPointById(pointId),
    [getPointById]
  );

  return handleGetPointById;
}
