import styled from 'styled-components';
import { Color, ShapeRadius, Spacing } from '@pushwoosh/kit-constants';

export const Container = styled.div`
  background-color: ${Color.CLEAR};
  padding: ${Spacing.S0};
  border-radius: ${ShapeRadius.CONTROL};
`;

export const Header = styled.div`
  border-radius: ${ShapeRadius.CONTROL} ${ShapeRadius.CONTROL} 0 0;
  padding: ${Spacing.S2};
  user-select: none;
  border-bottom: 1px solid ${Color.DIVIDER};
`;

export const Tags = styled.div`
  display: flex;
  gap: ${Spacing.S2};
  flex-wrap: wrap;
  align-items: center;
  padding: ${Spacing.S2};

  i {
    color: ${Color.LOCKED};
    font-style: normal;
  }
`;

export const TagName = styled.div``;

export const Tag = styled.div`
  display: inline-flex;
  gap: ${Spacing.S2};
  background-color: ${Color.FROZEN};
  border-radius: ${ShapeRadius.CONTROL};
  padding: ${Spacing.S2};
`;
