import * as React from 'react';
import konva from 'konva';
import { Path } from 'react-konva';
import { addSeconds, intervalToDuration } from 'date-fns';

// Constants
import {
  CHANNEL_GROUP_COLOR,
  PointType,
  COLOR_TRUE,
  COLOR_FALSE
} from '@src/common/constants/point';

// Helpers
import { getTitlePoint } from '@src/canvas/helpers';
import { getOutputs } from '../helpers/getOutputs';

// Components
import { Point, PointContent, PointText, PointIcon, PointOutput, PointShortInfo } from '../Point';

// Types
import { IProps } from './PointSendPush.types';

const KEY_TRUE = 'output1';
const KEY_FALSE = 'default';

export function PointSendPush(props: IProps): JSX.Element {
  const [isShowBadge, setShowBadge] = React.useState([true, true]);
  const {
    data: { behaviorSplitter },
    outputs,
    propsCommonPoint
  } = props;

  const getIsOutput = React.useCallback(
    (key: string): boolean => getOutputs(key, outputs),
    [outputs]
  );

  const getPointInfo = React.useCallback((): string => {
    if (!behaviorSplitter?.interval) return '0 days';

    const startDate = new Date(0);
    const endDate = addSeconds(startDate, behaviorSplitter?.interval);
    const duration = intervalToDuration({ start: startDate, end: endDate });

    if (duration.hours || duration.minutes) {
      return `${duration.days} days...`;
    }

    return `${duration.days} days`;
  }, [behaviorSplitter?.interval]);

  const isLinkedOutputFirst = getIsOutput(KEY_TRUE);
  const isLinkedOutputSecond = getIsOutput(KEY_FALSE);

  const titlePoint = getTitlePoint(PointType.SEND_PUSH, propsCommonPoint.title, props.data);

  return (
    <Point type={PointType.SEND_PUSH} color={CHANNEL_GROUP_COLOR} {...propsCommonPoint}>
      <PointContent type={PointType.SEND_PUSH}>
        <PointIcon type={PointType.SEND_PUSH} fill={CHANNEL_GROUP_COLOR}>
          <Path
            offsetX={-8}
            offsetY={-10}
            fill="white"
            perfectDrawEnabled={false}
            shadowForStrokeEnabled={false}
            data="M0 3.2C0 2.0799 0 1.51984 0.217987 1.09202C0.409734 0.715695 0.715695 0.409734 1.09202 0.217987C1.51984 0 2.0799 0 3.2 0H20.8C21.9201 0 22.4802 0 22.908 0.217987C23.2843 0.409734 23.5903 0.715695 23.782 1.09202C24 1.51984 24 2.0799 24 3.2V13.8C24 14.9201 24 15.4802 23.782 15.908C23.5903 16.2843 23.2843 16.5903 22.908 16.782C22.4802 17 21.9201 17 20.8 17H4.53023C4.27034 17 4.02066 17.1012 3.83408 17.2821L1.35692 19.6842C0.930032 20.0982 0.716589 20.3051 0.53386 20.3175C0.375256 20.3283 0.220985 20.263 0.118302 20.1416C0 20.0018 0 19.7045 0 19.1099V3.2Z"
          />
          <Path
            offsetX={-12}
            offsetY={-14}
            fill={CHANNEL_GROUP_COLOR}
            perfectDrawEnabled={false}
            shadowForStrokeEnabled={false}
            data="M0 2C0 2.55228 0.447715 3 1 3H12C12.5523 3 13 2.55228 13 2V1C13 0.447715 12.5523 0 12 0H1C0.447715 0 0 0.447715 0 1V2ZM0 7C0 7.55228 0.447715 8 1 8H6C6.55228 8 7 7.55228 7 7V6C7 5.44772 6.55228 5 6 5H1C0.447715 5 0 5.44772 0 6V7Z"
          />
        </PointIcon>
        <PointText type={PointType.SEND_PUSH}>{titlePoint}</PointText>
        {behaviorSplitter?.enabled ? (
          <>
            <PointOutput
              y={9}
              pointId={propsCommonPoint.id}
              outputKey={KEY_TRUE}
              type={PointType.SEND_PUSH}
              colorBlock={COLOR_TRUE}
              colorArrow={COLOR_TRUE}
              isInvalid={propsCommonPoint.isInvalid}
              isActive={propsCommonPoint.isActive}
              isLinkedOutput={isLinkedOutputFirst}
              onMouseDown={(event: konva.KonvaEventObject<MouseEvent>): void => {
                setShowBadge([isShowBadge[0], false]);
                propsCommonPoint.onMouseDownOutput(event);
              }}
              textBadge="Opened"
              fillBadge={COLOR_TRUE}
              isShowBadge={isLinkedOutputFirst ? false : isShowBadge[0]}
              arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
            />
            <PointOutput
              y={31}
              pointId={propsCommonPoint.id}
              outputKey={KEY_FALSE}
              type={PointType.SEND_PUSH}
              colorBlock={COLOR_FALSE}
              colorArrow={COLOR_FALSE}
              isInvalid={propsCommonPoint.isInvalid}
              isActive={propsCommonPoint.isActive}
              isLinkedOutput={isLinkedOutputSecond}
              onMouseDown={(event: konva.KonvaEventObject<MouseEvent>): void => {
                setShowBadge([isShowBadge[0], false]);
                propsCommonPoint.onMouseDownOutput(event);
              }}
              textBadge="Not opened"
              fillBadge={COLOR_FALSE}
              isShowBadge={isLinkedOutputSecond ? false : isShowBadge[0]}
              arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
            />
          </>
        ) : (
          <PointOutput
            pointId={propsCommonPoint.id}
            type={PointType.SEND_PUSH}
            colorBlock={CHANNEL_GROUP_COLOR}
            isInvalid={propsCommonPoint.isInvalid}
            isActive={propsCommonPoint.isActive}
            isLinkedOutput={propsCommonPoint.isLinkedOutput}
            onMouseDown={propsCommonPoint.onMouseDownOutput}
            arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
          />
        )}
      </PointContent>
      {behaviorSplitter?.enabled && (
        <PointShortInfo
          type={PointType.SEND_PUSH}
          label={`Wait for ${getPointInfo()}`}
          align="left"
        />
      )}
    </Point>
  );
}
