import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';

export const Container = styled.div`
  margin-top: ${Spacing.S5};
`;

export const DateOptions = styled.div`
  display: grid;
  grid-template-columns: 130px auto;
  grid-column-gap: ${Spacing.S5};
  margin-top: ${Spacing.S5};
`;

export const WaitOptions = styled.div`
  display: grid;
  grid-template-columns: 130px 130px;
  grid-column-gap: ${Spacing.S3};
`;
