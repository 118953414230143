import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgDropdownRight(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.5 8l-4-4v8l4-4z" fill={props.fill} />
    </svg>
  );
}

export default SvgDropdownRight;
