import { useCallback } from 'react';

// Store
import { useStoreActions } from '@src/store/store';

type THandleResetZoom = () => void;

export function useResetZoom(): THandleResetZoom {
  const resetZoom = useStoreActions(
    (actions): typeof actions.canvas.resetZoom => actions.canvas.resetZoom
  );

  return useCallback<THandleResetZoom>((): void => {
    resetZoom();
  }, [resetZoom]);
}
