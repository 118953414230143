import * as React from 'react';

// Types
import { Pagination } from '@src/common/types/pagination';

export const PER_PAGE_DEFAULT = 20;

export const OPTIONS_SHOWN_ON_PAGE = [
  {
    label: '20',
    value: 20
  },
  {
    label: '40',
    value: 40
  }
];

type Result = {
  page: Pagination['page'];
  pages: number[];
  perPage: Pagination['perPage'];
  changePage: (newPage: number) => void;
  changePerPage: (newPerPage: number) => void;
};

type Callback = (args: { page: Result['page']; perPage: Result['perPage'] }) => void;

export const usePagination = (
  newCurrentPage: Pagination['page'],
  pageCount: Pagination['pageCount'],
  callBack: Callback
): Result => {
  const [page, setPage] = React.useState<number>(newCurrentPage);
  const [perPage, setPerPage] = React.useState<number>(PER_PAGE_DEFAULT);

  React.useEffect(() => {
    if (newCurrentPage !== page) {
      setPage(newCurrentPage);
    }
  }, [newCurrentPage, setPage, page]);

  const changePage = React.useCallback(
    (newPage: number) => {
      if (page > pageCount) {
        return;
      }
      setPage(newPage);
      callBack({ page: newPage, perPage });
    },
    [pageCount, page, perPage, callBack]
  );

  const changePerPage = React.useCallback(
    (newPerPage) => {
      setPerPage(newPerPage);
      callBack({ page, perPage: newPerPage });
    },
    [page, callBack]
  );

  const generatePages = React.useMemo(() => {
    const pages = [];

    // start case
    if (page === 1) {
      const countElement = pageCount > 3 ? 3 : pageCount;
      return Array.from({ length: countElement }, (_, i) => i + 1);
    }

    // end case
    if (page === pageCount) {
      if (page - 2 > 0) {
        pages.push(page - 2);
      }
      if (page - 1 > 0) {
        pages.push(page - 1);
      }
      pages.push(page);
      return pages;
    }

    // middle case
    const start = page > 1 ? page - 1 : 1;
    const end = page + 1 < pageCount ? page + 1 : pageCount;
    for (let index = start; index <= end; index++) {
      pages.push(index);
    }
    return pages;
  }, [page, pageCount]);

  return {
    page,
    perPage,
    pages: generatePages,
    changePage,
    changePerPage
  };
};
