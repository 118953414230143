import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgEditSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.12.925l.004-.006c.1-.17.258-.287.434-.338l.018-.006.018-.004a.747.747 0 01.545.076l.006.003 3.464 2a.75.75 0 01.275 1.025L7.077 12h5.173a.75.75 0 010 1.5h-8.5a.748.748 0 01-.299-.062.75.75 0 01-.672-.554l-.733-2.73a.75.75 0 01.075-.569l5-8.66zm3.09 2.65l-.75 1.298-2.165-1.25.75-1.299 2.165 1.25zm-1.5 2.598l-2.165-1.25-2.97 5.143.458 1.706 1.707-.456 2.97-5.143z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgEditSmallLined;
