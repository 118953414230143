import { WaitMode } from '@src/common/constants/wait';

export function getTimeDelayTitle(waitMode: WaitMode) {
  switch (waitMode) {
    case WaitMode.Date: {
      return 'Date';
    }

    case WaitMode.Delay: {
      return 'Fixed duration';
    }

    case WaitMode.Dynamic: {
      return 'Based on user/event data';
    }

    case WaitMode.Wait: {
      return 'Specific time';
    }

    case WaitMode.Week: {
      return 'Date of week';
    }

    default: {
      return 'Fixed duration';
    }
  }
}
