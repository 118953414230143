import styled from 'styled-components';
import { Color, ShapeRadius, Spacing } from '@pushwoosh/kit-constants';
import { Paragraph as ParagraphComponent } from '@pushwoosh/pwkit-typography';

export const Block = styled.div`
  padding: 0;
  background-color: ${Color.SOFT_LIGHT};
  border-radius: ${ShapeRadius.CONTROL};
`;

export const Header = styled.div<{ open: boolean }>`
  background-color: ${Color.SOFT_LIGHT};
  padding: ${Spacing.S4};
  border-radius: ${(p) =>
    p.open ? `${ShapeRadius.CONTROL} ${ShapeRadius.CONTROL} 0 0` : ShapeRadius.CONTROL};
`;

export const BlockContent = styled.div`
  padding: ${Spacing.S4};
`;

export const TimeConfig = styled.div`
  display: flex;
  align-items: flex-start;

  max-width: 640px;

  div:nth-child(1) {
    margin-right: ${Spacing.S7};
  }
`;

export const Paragraph = styled(ParagraphComponent)`
  max-width: 640px;
  margin-top: ${Spacing.S7};
`;

export const CheckboxGroup = styled.div`
  display: flex;
  margin-top: ${Spacing.S1};
  margin-bottom: ${Spacing.S5};
`;

export const CheckboxItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${Spacing.S7};
`;

export const CheckboxLabel = styled.div`
  margin-left: ${Spacing.S2};
`;
