import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgTriggerBasedEntryPoint(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.54 21.936a.8.8 0 01-.683-1.218l7.83-12.759c.445-.724 1.564-.347 1.478.5l-.876 8.684a.8.8 0 00.796.88h4.376a.8.8 0 01.68 1.222l-7.946 12.799c-.447.72-1.56.341-1.476-.502l.88-8.726a.8.8 0 00-.795-.88h-4.265z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgTriggerBasedEntryPoint;
