import { useCallback } from 'react';

// Store
import { useStoreActions } from '@src/store/store';

type THandleChangeZoom = (direction: number, newScale: number) => void;

export function useChangeZoom(): THandleChangeZoom {
  const changeZoom = useStoreActions(
    (actions): typeof actions.canvas.changeZoom => actions.canvas.changeZoom
  );

  return useCallback<THandleChangeZoom>(
    (direction, newScale: number): void => {
      changeZoom({ direction, newScale });
    },
    [changeZoom]
  );
}
