import * as React from 'react';
import { useParams } from 'react-router-dom';

// Analytics
import { sendPostEvent } from '@src/common/analytics';

// Store Hooks
import { useCloseAllModals, useCreateJourney, useUsername } from '@src/store/hooks';

// UI Hooks
import { useForm } from '@src/ui/hooks';
import { useApplicationsWithAutoLoad } from '@src/ui/features/EditEvents/hooks/useApplicationsWithAutoLoad';

// UI Components
import { Section } from '@src/ui/kit';
import { Paragraph } from '@pushwoosh/kit-typography';
import { Modal } from '@src/ui/kit/Modal';
import { DefaultButtons } from '@src/ui/kit/ModalButtons';
import { CreateJourneyForm } from '@src/ui/features/Modals/CreateJourney/components/CreateJourneyForm';

// Helpers
import { useHistory } from '@src/common/contexts/history';
import { makePoint } from '@src/store/helpers';
import { makeApplicationOptions } from '@src/ui/helpers';

// Enums
import { PointType } from '@src/common/constants/point';

// Types
import { TFormErrors } from '@src/ui/hooks/useForm';
import { TPoints } from '@src/common/types/points';
import { ModalCreateJourneyValues } from './ModalCreateJourney.types';

const TITLE_ERROR_TEXT = 'Please enter the name for the journey';
const APP_ERROR_TEXT = 'Please select an application for the journey';

export function ModalCreateJourney(): JSX.Element {
  const closeAllModals = useCloseAllModals();
  const [createJourney] = useCreateJourney();
  const [applications, isLoadingApplications] = useApplicationsWithAutoLoad();
  const applicationSelectOptions = makeApplicationOptions(applications);
  const username = useUsername();
  const history = useHistory();
  const params = useParams<{ applicationCode: string }>();
  const { applicationCode } = params;

  const { values, errors, touched, setFieldValue, setFieldTouched, handleBlur, handleSubmit } =
    useForm<ModalCreateJourneyValues>({
      initialValues: {
        title: '',
        applicationCode: applicationCode || ''
      },
      validate(values): TFormErrors<ModalCreateJourneyValues> {
        const errors: TFormErrors<ModalCreateJourneyValues> = {};

        if (!values.title) {
          errors.title = TITLE_ERROR_TEXT;
        }

        if (!values.applicationCode) {
          errors.applicationCode = APP_ERROR_TEXT;
        }

        return errors;
      },
      onSubmit: async (formValues): Promise<void> => {
        const startingPoint = makePoint({
          type: PointType.EVENT
        }) as TPoints;

        const journey = await createJourney({
          points: [startingPoint],
          title: formValues.title,
          applicationCode: formValues.applicationCode
        });

        sendPostEvent('CustomerJourneyCreate', { userEmail: username });

        closeAllModals();

        const journeyLink = applicationCode
          ? `/journeys/${applicationCode}/${journey.status}/${journey.id}`
          : `/journeys/${journey.status}/${journey.id}`;

        history.push(journeyLink);
      }
    });

  return (
    <Modal
      title="Create journey"
      widthType="short"
      footerRight={
        <DefaultButtons onClickActionButton={handleSubmit} onClickCancelButton={closeAllModals} />
      }
      isOpen
    >
      <Section direction="column">
        <Paragraph>
          Use clear and distinctive names for your journeys to easily manage them further. You can
          select a category to make it easier to sort journeys further.
        </Paragraph>
        <CreateJourneyForm
          values={values}
          touched={touched}
          errors={errors}
          applicationSelectOptions={applicationSelectOptions}
          isLoadingApplications={isLoadingApplications}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
        />
      </Section>
    </Modal>
  );
}
