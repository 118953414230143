import * as React from 'react';
import { Group, Rect, Path } from 'react-konva';

// Hooks
import { useHover } from '@src/canvas/hooks';

// Types
import { Color } from '@pushwoosh/kit-constants';
import { IProps } from './DeleteButton.types';

export function DeleteButton(props: IProps): JSX.Element {
  const { onDelete } = props;
  const { isMouseEnter, handleMouseLeave, handleMouseEnter } = useHover();
  const color = isMouseEnter ? Color.DANGER_HOVER : Color.MAIN;

  return (
    <Group
      x={50}
      y={7}
      height={100}
      width={100}
      onClick={onDelete}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      perfectDrawEnabled={false}
    >
      <Rect x={-14} y={-7} width={8} height={30} fill={isMouseEnter ? '#fff1f4' : '#fff'} />
      <Rect
        x={-10}
        y={-7}
        width={32}
        height={30}
        cornerRadius={4}
        fill={isMouseEnter ? '#fff1f4' : '#fff'}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
      />
      <Path
        x={-8}
        y={-4}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
        data="M17.746 6.93542C17.8976 6.84956 18 6.68674 18 6.5V5.5C18 5.22386 17.7761 5 17.5 5H14.2071C14.0745 5 13.9473 4.94732 13.8536 4.85355L13.1464 4.14645C13.0527 4.05268 12.9255 4 12.7929 4H11.2071C11.0745 4 10.9473 4.05268 10.8536 4.14645L10.1464 4.85355C10.0527 4.94732 9.9255 5 9.79289 5H6.5C6.22386 5 6 5.22386 6 5.5V6.5C6 6.68674 6.10237 6.84956 6.25404 6.93542C6.32667 6.97653 6.41059 7 6.5 7H17.5C17.5894 7 17.6733 6.97653 17.746 6.93542Z"
        fill={color}
      />
      <Path
        x={-8}
        y={-4}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
        data="M7.5 8C7.22386 8 7 8.22386 7 8.5V18C7 19.1046 7.89543 20 9 20H15C16.1046 20 17 19.1046 17 18V8.5C17 8.22386 16.7761 8 16.5 8H7.5Z"
        fill={color}
      />
      <Path
        x={-8}
        y={-4}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
        data="M8.5 9.5V18C8.5 18.2761 8.72386 18.5 9 18.5H15C15.2761 18.5 15.5 18.2761 15.5 18V9.5H8.5Z"
        fill="white"
      />
      <Path
        x={-8}
        y={-4}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
        data="M10.75 11.25C11.1642 11.25 11.5 11.5858 11.5 12V16C11.5 16.4142 11.1642 16.75 10.75 16.75C10.3358 16.75 10 16.4142 10 16V12C10 11.5858 10.3358 11.25 10.75 11.25Z"
        fill={color}
      />
      <Path
        x={-8}
        y={-4}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
        data="M14 12C14 11.5858 13.6642 11.25 13.25 11.25C12.8358 11.25 12.5 11.5858 12.5 12V16C12.5 16.4142 12.8358 16.75 13.25 16.75C13.6642 16.75 14 16.4142 14 16V12Z"
        fill={color}
      />
    </Group>
  );
}
