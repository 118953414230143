import { ChangeTypes } from '@src/common/constants/changeLog';
import { makeUniqueId } from '@src/common/helpers/makeUniqueId';
import { generateTitlePoint, makePoint } from '@src/store/helpers';
import { action, computed, thunkOn } from 'easy-peasy';
import { TPoints } from '@src/common/types/points';
import { ChangeLogModelType } from './changeLog.types';

export const model: ChangeLogModelType = {
  changeLog: [],
  changeCounts: computed(
    [(state) => state?.changeLog ?? []],
    (changeLog) => changeLog?.length ?? 0
  ),
  pushChange: action((state, payload) => {
    const newChangeLog = [...state.changeLog, payload.item];
    state.changeLog = newChangeLog;

    localStorage.setItem(`changeLog_${payload.journeyId}`, JSON.stringify(newChangeLog));
  }),
  setChangeLog: action((state, payload) => {
    state.changeLog = payload;
  }),
  onChange: thunkOn(
    (_, actions): string[] => [
      actions.points.create.type,
      actions.points.delete.type,
      actions.points.update.type,
      actions.points.updateGoals.type,
      actions.comments.updateComment.type,
      actions.comments.remove.type,
      actions.journeys.updateEntiti.type
    ],
    (_, target, helpers): void => {
      const { getState, getStoreState, getStoreActions } = helpers;
      const state = getState();
      const stateStore = getStoreState();
      const storeActions = getStoreActions();
      const { pushChange } = storeActions.changeLog;
      const payload = target.payload as any;

      switch (target.type) {
        case storeActions.points.create.type: {
          const newPoint = makePoint(payload) as TPoints;

          newPoint.title = generateTitlePoint(newPoint, stateStore.points.items);

          pushChange({
            item: {
              id: makeUniqueId(),
              type: ChangeTypes.ADD,
              title: newPoint.title,
              actions: ['Step added'],
              time: new Date(),
              isUndid: false
            },
            journeyId: stateStore.journeys.currentJourneyId
          });

          break;
        }

        case storeActions.points.delete.type: {
          const stackItem =
            stateStore.points.undoRedoStack[stateStore.points.undoRedoStack.length - 1];
          const point = stackItem.points[0];

          pushChange({
            item: {
              id: makeUniqueId(),
              type: ChangeTypes.REMOVE,
              title: point.title,
              actions: ['Step deleted'],
              time: new Date(),
              isUndid: false
            },
            journeyId: stateStore.journeys.currentJourneyId
          });

          break;
        }

        case storeActions.points.updateGoals.type: {
          pushChange({
            item: {
              id: makeUniqueId(),
              type: ChangeTypes.UPDATE,
              title: 'Conversion Goal',
              actions: ['Goals updated'],
              time: new Date(),
              isUndid: false
            },
            journeyId: stateStore.journeys.currentJourneyId
          });

          break;
        }

        case storeActions.points.update.type: {
          pushChange({
            item: {
              id: makeUniqueId(),
              type: ChangeTypes.UPDATE,
              title: payload.title,
              actions: ['Step updated'],
              time: new Date(),
              isUndid: false
            },
            journeyId: stateStore.journeys.currentJourneyId
          });

          break;
        }

        case storeActions.comments.updateComment.type: {
          pushChange({
            item: {
              id: makeUniqueId(),
              type: ChangeTypes.UPDATE,
              title: 'Notes updated',
              actions: [`Message - ${payload.message}`],
              time: new Date(),
              isUndid: false
            },
            journeyId: stateStore.journeys.currentJourneyId
          });

          break;
        }

        case storeActions.comments.remove.type: {
          pushChange({
            item: {
              id: makeUniqueId(),
              type: ChangeTypes.REMOVE,
              title: 'Notes removed',
              actions: [`Message - ${payload.message}`],
              time: new Date(),
              isUndid: false
            },
            journeyId: stateStore.journeys.currentJourneyId
          });

          break;
        }

        case storeActions.journeys.updateEntiti.type: {
          if (state.changeLog.length > 0) {
            pushChange({
              item: {
                id: makeUniqueId(),
                type: ChangeTypes.UPDATE,
                title: 'Journey Params',
                actions: [`Updated - ${payload?.paramType}`],
                time: new Date(),
                isUndid: false
              },
              journeyId: stateStore.journeys.currentJourneyId
            });
          }

          break;
        }

        default: {
          break;
        }
      }
    }
  ),
  onLoad: thunkOn(
    (_, storeActions): string[] => [storeActions.journeys.loadById.successType],
    (_, target): void => {
      const cachedChangeLog = JSON.parse(localStorage.getItem(`changeLog_${target.result.id}`));

      if (cachedChangeLog) {
        _.setChangeLog(cachedChangeLog);
      } else {
        _.setChangeLog([]);
      }
    }
  ),
  onResume: thunkOn(
    (_, storeActions): string[] => [storeActions.journeys.resumeById.successType],
    (_, target): void => {
      localStorage.removeItem(`changeLog_${target.result.id}`);
      _.setChangeLog([]);
    }
  )
};
