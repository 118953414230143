import {
  HttpClient,
  NotFoundError,
  InternalServerError,
  UnauthorizedError,
  defaultResponseMiddleware
} from '@pushwoosh/http-client';
import { History } from 'history';

import { store } from '@src/store/store';

// Enums
import {
  NotificationType,
  NOTIFICATION_TITLE_FAIL,
  NOTIFICATION_MESSAGE_FAIL
} from '@src/common/constants/notification';

const { authClientId, authRedirectUrl, tokenUrl, authCookieDomain, logoutUrl } = process.env;

const { CJ_AUTH_REDIRECT_URL, CJ_AUTH_CLIENT_ID } = window as any;

export const createApi = (history: History<unknown>) => {
  const api = new HttpClient(history, {
    urls: {
      authorize: CJ_AUTH_REDIRECT_URL || authRedirectUrl,
      token: tokenUrl,
      logout: logoutUrl
    },
    clientId: CJ_AUTH_CLIENT_ID || authClientId,
    cookie: {
      domain: authCookieDomain
    },
    basicResponseMiddleware: defaultResponseMiddleware,
    basicErrorMiddleware: async (error: unknown): Promise<never> => {
      const { notifications } = store.getActions();

      if (error instanceof NotFoundError) {
        history.push('/journeys/list');
        notifications.add([
          {
            title: NOTIFICATION_TITLE_FAIL.notFoundJourney,
            type: NotificationType.ERROR
          }
        ]);
      }

      if (error instanceof InternalServerError) {
        notifications.add([
          {
            title: NOTIFICATION_TITLE_FAIL.serverError,
            message: NOTIFICATION_MESSAGE_FAIL.serverError,
            type: NotificationType.ERROR
          }
        ]);
      }

      if (error instanceof UnauthorizedError) {
        await api.logout();
        await api.login(); // это бесконечный промис, дальше этого места мы не пройдем
      }

      throw error;
    }
  });

  return api;
};
