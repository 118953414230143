import * as React from 'react';

// Store hooks
import { useLoadTag, useTag } from '@src/store/hooks';

// Components
import { Select, TSelectOption } from '@src/ui/kit/Select';

// Types
import { TagValuesProps } from './TagValues.types';

export function TagValues(props: TagValuesProps): JSX.Element {
  const { values, names, setFieldValue } = props;
  const { tagValue } = values.dynamicValues;

  const tags = useTag();
  const [loadTags, isLoadTags] = useLoadTag();

  React.useEffect(() => {
    loadTags();
  }, [loadTags]);

  const getOptionsTags = React.useMemo(
    () =>
      tags.map((item) => ({
        label: item.name,
        value: item.name
      })),
    [tags]
  );

  const getCurrentOptionTag = React.useMemo(
    () => getOptionsTags.filter((item) => item.value === tagValue)[0],
    [getOptionsTags, tagValue]
  );

  const handleChangeTagValue = React.useCallback(
    (selectedOption: TSelectOption) => {
      setFieldValue(names.dynamicValues, {
        ...values.dynamicValues,
        tagValue: selectedOption.value
      });
    },
    [names, values.dynamicValues, setFieldValue]
  );

  return (
    <Select
      placeholder="Select tag"
      value={tagValue ? getCurrentOptionTag : null}
      options={getOptionsTags}
      onChange={handleChangeTagValue}
      isLoading={isLoadTags}
    />
  );
}
