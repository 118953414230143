import { css } from 'styled-components';
import { Color } from '@pushwoosh/kit-constants';
import { IButtonProps } from '../Button.types';
import { fieldCSS, compactCSS } from './base';

const primaryShapeCSS = css<IButtonProps>`
  background: ${({ disabled }) => (disabled ? Color.LOCKED : Color.PRIMARY)};
  color: ${Color.CLEAR};
  border: none;

  &:hover {
    background: ${Color.PRIMARY_HOVER};
  }

  &:active {
    background: ${Color.PRIMARY_PRESSED};
  }

  & > div > svg > path {
    fill: ${Color.CLEAR};
  }
`;

const primaryGhostCSS = css<IButtonProps>`
  background: transparent;
  color: ${({ disabled }) => (disabled ? Color.LOCKED : Color.PRIMARY)};
  border: none;

  & > div > svg > path {
    fill: ${({ disabled }) => (disabled ? Color.LOCKED : Color.PRIMARY)};
  }

  &:hover {
    background: ${Color.BRIGHT_LIGHT};
    color: ${Color.PRIMARY_HOVER};

    & > div > svg > path {
      fill: ${Color.PRIMARY_HOVER};
    }
  }

  &:active {
    color: ${Color.PRIMARY_PRESSED};
    background: ${Color.BRIGHT_LIGHT};

    & > div > svg > path {
      fill: ${Color.PRIMARY_PRESSED};
    }
  }
`;

export const primaryShapeFieldCSS = css<IButtonProps>`
  ${fieldCSS}
  ${primaryShapeCSS}
`;

export const primaryShapeCompactCSS = css<IButtonProps>`
  ${compactCSS}
  ${primaryShapeCSS}
`;

export const primaryGhostFieldCSS = css<IButtonProps>`
  ${fieldCSS}
  ${primaryGhostCSS}
`;

export const primaryGhostCompactCSS = css<IButtonProps>`
  ${compactCSS}
  ${primaryGhostCSS}

  &:hover {
    background: transparent;
  }

  &:active {
    background: transparent;
  }
`;
