import styled from 'styled-components';
import { Spacing, FontSize, Color, FontWeight } from '@pushwoosh/kit-constants';

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomTitle = styled.h2`
  margin: 0;
  padding-left: ${Spacing.S1};
  padding-bottom: ${Spacing.S1};
  font-size: ${FontSize.SMALL};
  color: ${Color.LOCKED};
  text-transform: uppercase;
  font-weight: ${FontWeight.MEDIUM};
`;

export const ItemsGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: ${Spacing.S5};
`;
