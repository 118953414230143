import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgVisibilityVisibleMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9.25a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zM10.75 12a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.965 12.41a12.44 12.44 0 01-.491.83c-.34.527-.847 1.232-1.51 1.94-1.313 1.4-3.331 2.903-5.964 2.903-2.633 0-4.65-1.503-5.964-2.903a13.87 13.87 0 01-1.9-2.583 8.357 8.357 0 01-.128-.239L4 12.343l-.003-.005v-.001-.675L4 11.657l.008-.015.027-.053a12.4 12.4 0 01.491-.83c.34-.526.847-1.231 1.51-1.939C7.35 7.42 9.367 5.917 12 5.917c2.633 0 4.65 1.503 5.964 2.903a13.871 13.871 0 011.9 2.583 8.413 8.413 0 01.129.239l.01.02c.123.245.086.514-.038.749zm-14.179.017A11.403 11.403 0 015.524 12 12.37 12.37 0 017.13 9.846C8.318 8.58 9.966 7.416 12 7.416c2.033 0 3.682 1.164 4.869 2.43A12.374 12.374 0 0118.477 12a12.372 12.372 0 01-1.607 2.154c-1.187 1.266-2.836 2.43-4.87 2.43-2.034 0-3.682-1.164-4.87-2.43a12.368 12.368 0 01-1.344-1.727z"
        fill={props.fill}
      />
      <path d="M3.996 12.335v-.67a.751.751 0 000 .67z" fill={props.fill} />
    </svg>
  );
}

export default SvgVisibilityVisibleMediumLined;
