import * as React from 'react';
import Konva from 'konva';
import { Line, Group, Circle } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';

// Components
import { PopUp } from '@src/canvas/components/PopUp/PopUp';
import { ButtonRemove } from '@src/canvas/components/ButtonRemove/ButtonRemove';
import { StatisticsBlock } from '@src/canvas/components/StatisticsBlock';
import { RoundedLine } from '@src/canvas/components/RoundedLine';

// Hooks
import { useHover } from '@src/canvas/hooks';

import { CANVAS_ARROW_COLOR } from '@src/common/constants/canvas';
import { IVector2 } from '@src/common/types/types';

// Helpers
import { calculateCenterArrow } from './helpers/calculateCenterArrow';
import { getArrowColor } from './helpers/getArrowColor';

// Types
import { IArrowProps } from './Arrow.types';

const CURSOR_WIDTH = 10;
const CURSOR_HEIGHT = 12;
const POPUP_SIZE = 16;

function Arrow(props: IArrowProps): JSX.Element {
  const {
    id,
    fromX,
    fromY,
    toX,
    toY,
    fromPointId,
    outputKey,
    outputOrder,
    color = CANVAS_ARROW_COLOR,

    toPointId = '',
    outputStats = 0,

    isActive = false,
    isSelected = false,

    canRemove = false,
    canShowStats = false,

    onRemove,
    setArrowHoveredToDelete
  } = props;
  const groupRef = React.useRef<Konva.Group>(null);

  const { centerX, centerY } = calculateCenterArrow({ fromX, fromY, toX, toY, outputKey });

  const [removeButtonHovered, setRemoveButtonHovered] = React.useState(false);

  const { isMouseEnter, handleMouseLeave, handleMouseEnter } = useHover();

  const handleArrowHoveredToDelete = React.useCallback(
    (isHovered: boolean) => {
      if (isHovered && setArrowHoveredToDelete) {
        setArrowHoveredToDelete(fromPointId, outputKey);
        setRemoveButtonHovered(true);
      } else {
        setArrowHoveredToDelete();
        setRemoveButtonHovered(false);
      }
    },
    [fromPointId, outputKey, setArrowHoveredToDelete]
  );

  const handleArrowRemove = React.useCallback((): void => {
    if (onRemove) {
      handleArrowHoveredToDelete(false);
      onRemove({
        id,
        fromPointId,
        toPointId,
        outputKey
      });
    }
  }, [onRemove, id, fromPointId, toPointId, outputKey, handleArrowHoveredToDelete]);

  const cursorPoints = React.useMemo(
    (): number[] => [
      toX - 1,
      toY,
      toX - CURSOR_WIDTH,
      toY + CURSOR_HEIGHT / 2,
      toX - CURSOR_WIDTH,
      toY - CURSOR_HEIGHT / 2
    ],
    [toX, toY]
  );

  let popUpPosition: IVector2;
  if (canRemove) {
    popUpPosition = {
      x: centerX,
      y: centerY
    };
  }

  const opacity = isActive || isSelected ? 1 : 0.7;

  const onMouseEnter = React.useCallback(
    (evt: KonvaEventObject<MouseEvent>) => {
      const group = groupRef.current;
      const zIndex = group.getZIndex();

      group.setZIndex(zIndex * 10);

      handleMouseEnter(evt);
    },
    [handleMouseEnter]
  );

  const onMouseLeave = React.useCallback(
    (evt: KonvaEventObject<MouseEvent>) => {
      const group = groupRef.current;
      const zIndex = group.getZIndex();

      group.setZIndex(zIndex / 10);

      handleMouseLeave(evt);
    },
    [handleMouseLeave]
  );

  const arrowColor = getArrowColor(color, removeButtonHovered, isMouseEnter);

  return (
    <Group
      name="arrow"
      id={id}
      ref={groupRef}
      outputKey={outputKey}
      outputOrder={outputOrder}
      fromPointId={fromPointId}
      toPointId={toPointId}
      onMouseOver={onMouseEnter}
      onMouseOut={onMouseLeave}
    >
      <Group>
        <Circle
          x={fromX}
          y={fromY}
          radius={4.5}
          fill={arrowColor}
          opacity={opacity}
          perfectDrawEnabled={false}
          shadowForStrokeEnabled={false}
        />

        <RoundedLine
          fromX={fromX}
          fromY={fromY}
          toX={toX}
          toY={toY}
          stroke={arrowColor}
          opacity={opacity}
        />

        <Line
          points={cursorPoints}
          fill={arrowColor}
          opacity={opacity}
          closed
          perfectDrawEnabled={false}
          shadowForStrokeEnabled={false}
        />
      </Group>

      <PopUp
        width={POPUP_SIZE}
        height={POPUP_SIZE}
        position={popUpPosition}
        isVisible={canRemove && isMouseEnter}
      >
        <ButtonRemove
          handleButtonHovered={handleArrowHoveredToDelete}
          onClick={handleArrowRemove}
        />
      </PopUp>
      {canShowStats ? (
        <StatisticsBlock
          fromX={fromX}
          fromY={fromY}
          toX={toX}
          toY={toY}
          countTransitions={outputStats}
          fill={arrowColor}
          outputKey={outputKey}
        />
      ) : null}
    </Group>
  );
}

export const ArrowMemo = React.memo(Arrow);
