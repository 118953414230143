import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgUploadMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 18.5h2.265c.13 0 .235-.105.235-.235V14a.5.5 0 00-.5-.5H8.207a.5.5 0 01-.353-.854l3.792-3.792a.5.5 0 01.708 0l3.792 3.792a.5.5 0 01-.353.854H14.5a.5.5 0 00-.5.5v4a.5.5 0 00.5.5H18a4 4 0 001.704-7.62.58.58 0 01-.319-.394 4.504 4.504 0 00-3.845-3.454.557.557 0 01-.331-.162A6.253 6.253 0 004.875 9.112a.554.554 0 01-.206.266A5 5 0 007.5 18.5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgUploadMediumFilled;
