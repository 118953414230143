// Api
import { GetOptions, PostOptions, HttpClient } from '@pushwoosh/http-client';

// Types
import { getApiOrigin } from '@src/store/helpers';
import {
  TFetchTagResponse,
  TFetchTagAutocompleteResponse,
  TFetchTagAutocompleteBody
} from './types';

export const service = {
  async fetch(api: HttpClient): Promise<TFetchTagResponse> {
    const options: GetOptions<TFetchTagResponse, never, never> = {
      withAuthorization: true
    };

    const url = `${getApiOrigin()}/api/tags`;

    // eslint-disable-next-line camelcase
    return api.get<TFetchTagResponse, never, never>(url, options);
  },
  async fetchTagAutocomplete(
    query: string,
    tag: string,
    type: string,
    api: HttpClient
  ): Promise<TFetchTagAutocompleteResponse> {
    const options: PostOptions<
      TFetchTagAutocompleteResponse,
      never,
      never,
      TFetchTagAutocompleteBody
    > = {
      body: {
        query,
        tag,
        type
      },
      withAuthorization: true
    };

    return api.post<
      TFetchTagAutocompleteResponse,
      never,
      never,
      // eslint-disable-next-line camelcase
      TFetchTagAutocompleteBody
    >('/api/tags/autocomplete', options);
  }
};
