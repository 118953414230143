import * as React from 'react';

// Types
import { ISelectOption } from '@src/common/types/form';

// Api
import { eventService } from '@src/store/api/services';
import { useHttpClient } from '@src/common/contexts/http-client';

// Store hooks
import { useGetEventsByApplicationCode } from '@src/store/hooks';

export function useSelectableSelect(
  applicationCode: string,
  eventCode: string,
  attributeName: string,
  searchValue?: string
) {
  const getEventsByApplicationCode = useGetEventsByApplicationCode();
  const [selectableValues, setSelectableVales] = React.useState<ISelectOption<string>[]>();
  const events = getEventsByApplicationCode(applicationCode);
  const api = useHttpClient();
  const [searchMultiple, setSearch] = React.useState('');

  const handleRequestToApi = React.useCallback(async (): Promise<void> => {
    const currentEvent = events.filter((item): boolean => item.name === eventCode);
    if (currentEvent.length) {
      const response = await eventService.fetchAttributesValuesByEvent(
        applicationCode,
        currentEvent[0].id,
        attributeName,
        searchMultiple || searchValue || '',
        api
      );
      const newSelectableValues = response.payload.values.map((item) => ({
        label: item,
        value: item
      }));
      setSelectableVales(newSelectableValues);
    }
  }, [events, attributeName, eventCode, applicationCode, searchValue, api, searchMultiple]);

  React.useEffect(
    () => {
      handleRequestToApi();
    },
    // eslint-disable-next-line
    [attributeName, searchValue, searchMultiple]
  );

  return {
    selectableValues,
    setSearch
  };
}
