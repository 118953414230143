import React from 'react';
import { Paragraph } from '@pushwoosh/kit-typography';
import { FormSelect } from '@src/ui/form/FormSelect';
import { FormInput } from '@src/ui/form/FormInput';
import { useTagsWithAutoLoad } from '@src/ui/features/SetTags/hooks/useTagsWithAutoLoad';
import { useLoadEvents, useDynamicEvents } from '@src/store/hooks';
import { DelayOptions } from '@src/common/constants/wait';
import { TFormEditingPointWaitProps } from '../WaitForm/WaitForm.types';
import {
  Container,
  DateOption,
  DelayOption,
  DateTimeSettings,
  EventDescription,
  EventSettings
} from './styled';

const fromOptions = [
  { label: 'User profile', value: 'tag' },
  { label: 'Event', value: 'event' }
];

const unitOptions = [
  { label: 'Days', value: 'days' },
  { label: 'Hours', value: 'hours' },
  { label: 'Minutes', value: 'minutes' }
];

const delayOptions = [
  { label: 'Right on Time', value: DelayOptions.RIGHT_ON_TIME },
  { label: 'After', value: DelayOptions.AFTER },
  { label: 'Before', value: DelayOptions.BEFORE }
];

export function DynamicTime(
  props: TFormEditingPointWaitProps & {
    applicationCode: string;
  }
): JSX.Element {
  const { values, names, applicationCode, setFieldValue } = props;
  const [tags] = useTagsWithAutoLoad();
  const [loadEventsByAppCode] = useLoadEvents();
  const { eventNameOptions, getAttributesList, getCurrentEvent, getEventValue } =
    useDynamicEvents(applicationCode);

  React.useEffect(() => {
    loadEventsByAppCode(applicationCode);
  }, [applicationCode, loadEventsByAppCode]);

  const attributesList = getAttributesList(values.eventName, { dateOnly: true });

  const tagNameOptions = React.useMemo(
    () =>
      tags
        .filter((item) => item.type === 'date')
        .map((item) => ({
          label: item.name,
          value: item.name
        })),
    [tags]
  );

  const handleDurationChange = (name: string, value: string) => {
    setFieldValue(names.duration, value.replace(/\D/, ''));
  };

  const currentEvent = getCurrentEvent(values.eventName, values.pointID);

  return (
    <Container>
      {values.dateFrom === 'event' && (
        <EventDescription>
          <Paragraph>
            Specify from which source to take the date for time delay (user profile data or from an
            event).{' '}
          </Paragraph>
        </EventDescription>
      )}
      <DateOption>
        <FormSelect
          name="from"
          label="Get date from"
          options={fromOptions}
          value={values.dateFrom}
          onChange={(name, value) => setFieldValue(names.dateFrom, value)}
        />
        {values.dateFrom === 'tag' && (
          <FormSelect
            name="tag"
            label="Tag"
            options={tagNameOptions}
            value={values.tagName}
            onChange={(name, value) => setFieldValue(names.tagName, value)}
          />
        )}
        {values.dateFrom === 'event' && (
          <EventSettings>
            <FormSelect
              name="event"
              label="Event"
              options={eventNameOptions}
              value={String(currentEvent?.value ?? '')}
              onChange={(name, value) => {
                const { eventName, pointID } = getEventValue(value);

                setFieldValue(names.eventName, eventName);
                setFieldValue(names.pointID, pointID);
              }}
            />
            <FormSelect
              name="eventValue"
              label="Event Value"
              options={attributesList}
              value={values.eventAttribute}
              onChange={(name, value) => setFieldValue(names.eventAttribute, value)}
            />
          </EventSettings>
        )}
      </DateOption>
      <Paragraph>
        Set the delay in relation to the date and time specified in the event attributes or
        corresponding user tags. Delay can be scheduled for a max of 90 days.
      </Paragraph>
      <DelayOption>
        <FormSelect
          name="delayOption"
          label="Delay Option"
          options={delayOptions}
          value={values.delayOptions}
          onChange={(name, value) => setFieldValue(names.delayOptions, value)}
        />
        {(values.delayOptions === 'after' || values.delayOptions === 'before') && (
          <DateTimeSettings>
            <FormInput
              name="duration"
              value={String(values.duration)}
              onChange={handleDurationChange}
            />
            <FormSelect
              name="unit"
              options={unitOptions}
              value={values.unit}
              onChange={(name, value) => setFieldValue(names.unit, value)}
            />
          </DateTimeSettings>
        )}
      </DelayOption>
      {/* <Paragraph>
        Set the delay in relation to the date and time specified in the event attributes or
        corresponding user tags. Delay can be scheduled for a max of 90 days.
      </Paragraph>
      <OptionsContainer>
        <FormSelect
          name="from"
          label="Date from"
          options={fromOptions}
          value={values.dateFrom}
          onChange={(name, value) => setFieldValue(names.dateFrom, value)}
        />
        <FormSelect
          name="delayOption"
          label="Delay Option"
          options={delayOptions}
          value={values.delayOptions}
          onChange={(name, value) => setFieldValue(names.delayOptions, value)}
        />
        {(values.delayOptions === 'after' || values.delayOptions === 'before') && (
          <>
            <FormInput
              name="duration"
              value={String(values.duration)}
              onChange={handleDurationChange}
            />
            <FormSelect
              name="unit"
              options={unitOptions}
              value={values.unit}
              onChange={(name, value) => setFieldValue(names.unit, value)}
            />
          </>
        )}
      </OptionsContainer>
      <FromSettings>
        {values.dateFrom === 'tag' && (
          <FormSelect
            name="tag"
            label="Tag"
            options={tagNameOptions}
            value={values.tagName}
            onChange={(name, value) => setFieldValue(names.tagName, value)}
          />
        )}
        {values.dateFrom === 'event' && (
          <>
            <FormSelect
              name="event"
              label="Event"
              options={eventNameOptions}
              value={String(currentEvent?.value ?? '')}
              onChange={(name, value) => {
                const { eventName, pointID } = getEventValue(value);

                setFieldValue(names.eventName, eventName);
                setFieldValue(names.pointID, pointID);
              }}
            />
            <FormSelect
              name="eventValue"
              label="Event Value"
              options={attributesList}
              value={values.eventAttribute}
              onChange={(name, value) => setFieldValue(names.eventAttribute, value)}
            />
          </>
        )}
      </FromSettings>
      <Description>
        If the date or time has passed already when a user reaches this journey point, this user
        will exit the journey. However, you can set the next step for those users by checking the
        box below.
      </Description>
      <Checkbox
        name="splitBranches"
        value={false}
        isChecked={values.splitBranches}
        onChange={({ isChecked }) => setFieldValue(names.splitBranches, isChecked)}
      >
        Split to branches if the date’s in the past or date is empty
      </Checkbox> */}
    </Container>
  );
}
