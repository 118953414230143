import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgCalendarMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 12.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1zM7.5 15a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1zM11 12.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1zM11.5 15a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1zM15 12.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.25a.75.75 0 01.75.75v1h6.5V3a.75.75 0 011.5 0v1H19a2 2 0 012 2v13a2 2 0 01-2 2H5a2 2 0 01-2-2V6a2 2 0 012-2h2.25V3A.75.75 0 018 2.25zm7.25 3.25v.75a.75.75 0 001.5 0V5.5H19a.5.5 0 01.5.5v2.25h-15V6a.5.5 0 01.5-.5h2.25v.75a.75.75 0 001.5 0V5.5h6.5zm4.25 4.25h-15V19a.5.5 0 00.5.5h14a.5.5 0 00.5-.5V9.75z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgCalendarMediumLined;
