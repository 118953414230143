import * as React from 'react';
import { isEqual } from 'lodash';

// Analytics
import { sendMixpanelEvent } from '@src/common/analytics';
import { SidebarType } from '@src/common/constants/sidebars';

// Store Hooks
import {
  useCurrentJourneyId,
  useEditJourneyById,
  useGetJourneyById,
  useGetPointById,
  useJourneyAppCode,
  useOpenNewModal,
  useUpdatePoint
} from '@src/store/hooks';
import { useStoreActions, useStoreState } from '@src/store/store';

// Containers
import { EditEvents } from '@src/ui/features/EditEvents';

// UI Components
import { Button, IconEnum, Modal } from '@src/ui/kit';
import { Paragraph } from '@pushwoosh/kit-typography';
import { FormBlock } from '@src/ui/form/styled';
import { DefaultButtons } from '@src/ui/kit/ModalButtons';

// Container Hooks
import { useEditEvents } from '@src/containers/hooks/useEditEvents';

// Types
import { TEventPoint } from '@src/common/types/points';
import { IUniqueId } from '@src/common/types/entities';
import { FormInput } from '@src/ui/form';
import { Section } from '@src/ui/kit/Section';
import { useForm } from '@src/ui/hooks';
import { ICommonEvent } from '@src/common/types/event';
import { JourneyStatus, ModalType, PointType } from '@src/common/constants';
import { getPointNameByType } from '@src/ui/helpers';

interface IProps {
  pointId: IUniqueId['id'];
  closeModal: () => void;
}

function FormEditingPointEvent(props: IProps): JSX.Element {
  const [saveDisabled, setSaveDisabled] = React.useState(false);
  const { pointId, closeModal } = props;
  const pointTitleRef = React.useRef(null);

  const journeyId = useCurrentJourneyId();
  const getJourneyById = useGetJourneyById();
  const { status } = getJourneyById(journeyId);

  const applicationCode = useJourneyAppCode(journeyId);

  const getPointById = useGetPointById();
  const updatePoint = useUpdatePoint();

  const showDocumentationMenu = useStoreActions((actions) => actions.documentationMenu.show);
  const openNewSidebar = useStoreActions((actions) => actions.sidebars.openNewSidebar);
  const closeAllSidebars = useStoreActions((actions) => actions.sidebars.closeAllSidebars);
  const editPoint = useStoreActions((actions) => actions.points.update);
  const openNewModal = useOpenNewModal();
  const handleEditJourneyById = useEditJourneyById();
  const sidebars = useStoreState((state) => state.sidebars);

  const point = getPointById(pointId) as TEventPoint;

  const isJourneyActive = status === JourneyStatus.RUNNING;

  const { listEvents, updateEvent, removeEvent } = useEditEvents([{ ...point.data }]);

  const handleEditSettings = React.useCallback(
    async (formValues: { title: string }) => {
      const newSettings = {
        applicationCode,
        name: listEvents[0].name,
        eventConditions: listEvents[0].eventConditions
      };
      const isChanged = !isEqual(point.data, newSettings);

      editPoint({
        ...point,
        title: formValues.title,
        data: {
          applicationCode,
          name: listEvents[0].name,
          eventConditions: listEvents[0].eventConditions
        }
      });

      await handleEditJourneyById(journeyId);

      sendMixpanelEvent({
        eventName: 'JourneyEditModeChangesApplied',
        eventData: {
          PointType: 'StartEvent'
        }
      });

      closeModal();

      openNewModal({
        type: ModalType.DETAIL_POINT,
        data: {
          isChanged,
          pointId
        }
      });
    },
    [
      applicationCode,
      closeModal,
      editPoint,
      handleEditJourneyById,
      journeyId,
      listEvents,
      openNewModal,
      point,
      pointId
    ]
  );

  const handleSaveSettings = React.useCallback(
    (formValues: { title: string }) => {
      if (listEvents[0].name) {
        sendMixpanelEvent({
          eventName: 'StartEventConfigured',
          eventData: {
            EventConditionAdded: listEvents[0]?.eventConditions?.length > 0
          }
        });
      }

      updatePoint({
        ...point,
        title: formValues.title,
        data: {
          applicationCode,
          name: listEvents[0].name,
          eventConditions: listEvents[0].eventConditions
        }
      });

      closeModal();
    },
    [applicationCode, closeModal, listEvents, point, updatePoint]
  );

  const { values, handleSubmit, setFieldValue } = useForm({
    initialValues: {
      title: point.title
    },
    onSubmit: isJourneyActive ? handleEditSettings : handleSaveSettings
  });

  const defaultTitle = getPointNameByType(PointType.EVENT);

  const [nameChanged, setNameChanged] = React.useState(!values.title.includes(defaultTitle));

  const handleChangeTitle = (name: string, value: string) => {
    setNameChanged(true);
    setFieldValue(name, value);
  };

  const handleUpdateEvent = React.useCallback(
    (indexElement: number, data: ICommonEvent) => {
      updateEvent(indexElement, data);

      if (status === JourneyStatus.RUNNING) {
        if (listEvents.length === 1 && data.name === '') {
          setSaveDisabled(true);
        } else {
          setSaveDisabled(false);
        }

        listEvents.forEach((event) =>
          event.eventConditions?.forEach((condition) => {
            if (!condition.value) {
              setSaveDisabled(true);
            }
          })
        );
      }

      if (data.name && data.name !== pointTitleRef.current) {
        if (!nameChanged) {
          setFieldValue('title', data.name);
          // Кэширование название тайтла, так как форма обновляется целиком.
          // Выпилить после рефакторинга формы ивентов
          pointTitleRef.current = data.name;
        }
      }
    },
    [setFieldValue, updateEvent, status, listEvents, nameChanged]
  );

  const handleCancel = React.useCallback(() => {
    const titleChanged = pointTitleRef.current === null || point.title === pointTitleRef.current;
    const eventChanged = point.data.name === listEvents[0].name;
    const eventConditionsChanged = isEqual(
      point.data.eventConditions,
      listEvents[0].eventConditions
    );

    if (status === JourneyStatus.RUNNING) {
      sendMixpanelEvent({
        eventName: 'JourneyEditModeChangesCancelled',
        eventData: {
          PointType: 'StartEvent'
        }
      });
    } else {
      sendMixpanelEvent({
        eventName: 'ConfigurePointCancelled',
        eventData: {
          PointType: 'StartEvent',
          PointChanged: titleChanged || eventChanged || eventConditionsChanged
        }
      });
    }

    closeModal();
  }, [closeModal, listEvents, point, status]);
  const onShowDocumentation = React.useCallback((): void => {
    if (sidebars.isOpened) {
      closeAllSidebars();
    } else {
      openNewSidebar({
        type: SidebarType.DOCUMENTATION
      });
      showDocumentationMenu();
      sendMixpanelEvent({ eventName: 'ShowDocumentation' });
    }
  }, [showDocumentationMenu, closeAllSidebars, sidebars, openNewSidebar]);

  return (
    <Modal
      title="Trigger-based Entry"
      footerRight={
        <DefaultButtons
          isDisabledActionButton={saveDisabled}
          onClickActionButton={handleSubmit}
          onClickCancelButton={handleCancel}
          actionButtonName={status === JourneyStatus.RUNNING ? 'Save' : 'Apply'}
        />
      }
      footerLeft={
        <Button
          color="primary"
          size="field"
          view="ghost"
          iconPosition="left"
          iconType={IconEnum.DOCS_MEDIUM_LINED}
          onClick={onShowDocumentation}
        >
          How to Use
        </Button>
      }
      isOpen
    >
      <Section direction="column">
        <Paragraph>Customers who trigger the Trigger-based Entry will enter the journey.</Paragraph>
        <FormBlock>
          <FormInput
            label="Point Name"
            name="title"
            value={values.title}
            onChange={handleChangeTitle}
          />
        </FormBlock>
      </Section>
      <EditEvents events={listEvents} updateEvent={handleUpdateEvent} removeEvent={removeEvent} />
    </Modal>
  );
}

export default React.memo(FormEditingPointEvent);
