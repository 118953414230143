import * as React from 'react';

// Components
import { LoaderAttributesSelect } from '@src/ui/features/EditEvents/components/LoaderAttributesSelect';
import { FormControl } from '@src/ui/form';

// Constants
import { AttributeTypeEnum } from '@src/common/constants/attribute';

// Types
import { IFilterConditionMultipleChoiceProps } from './FilterConditionMultipleChoice.types';

// Styled
import { OperatorMultipleChoice } from './styled';

export function FilterConditionMultipleChoice(
  props: IFilterConditionMultipleChoiceProps
): JSX.Element {
  const {
    applicationCode,
    eventCode,
    attributeName,
    attributeType,
    name,
    value,
    touched,
    error,
    setFieldValue,
    setFieldTouched
  } = props;

  const handleChangeIntegerType = React.useCallback(
    (_, values: string[]) => {
      const newValues = values.map((item) => {
        const newItem = parseInt(item, 10);
        if (!Number.isNaN(newItem)) {
          return newItem;
        }
        return null;
      });
      const numberValues = newValues.filter((item) => typeof item === 'number');
      setFieldValue(name, numberValues);
    },
    [name, setFieldValue]
  );

  if (!Array.isArray(value)) {
    return null;
  }

  if (attributeType === AttributeTypeEnum.INTEGER) {
    return (
      <OperatorMultipleChoice>
        <FormControl error={touched && error ? error : ''}>
          <LoaderAttributesSelect
            applicationCode={applicationCode}
            eventCode={eventCode}
            attributeName={attributeName}
            name={name}
            values={value as string[]}
            onChange={handleChangeIntegerType}
            onBlur={setFieldTouched}
          />
        </FormControl>
      </OperatorMultipleChoice>
    );
  }

  if (attributeType === AttributeTypeEnum.LIST || attributeType === AttributeTypeEnum.STRING) {
    return (
      <OperatorMultipleChoice>
        <FormControl error={touched && error ? error : ''}>
          <LoaderAttributesSelect
            applicationCode={applicationCode}
            eventCode={eventCode}
            attributeName={attributeName}
            name={name}
            values={value as string[]}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
          />
        </FormControl>
      </OperatorMultipleChoice>
    );
  }

  return null;
}
