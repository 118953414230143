import * as React from 'react';

// Components
import { FormControl } from '@src/ui/form/FormControl';
import { ChoiceGroup } from '@src/ui/kit/ChoiceGroup';

// Styled
import { Container } from './styled';

// Types
import { IProps } from './FormChoiceGroup.types';

export function FormChoiceGroup(props: IProps): JSX.Element {
  const { color, name, label, activeValues, defaultValues, setFieldValue, isDisabled, isMultiple } =
    props;

  const handleChangeWeekly = React.useCallback(
    (values: string[]) => {
      setFieldValue(name, values);
    },
    [name, setFieldValue]
  );

  return (
    <Container isDisabled={isDisabled}>
      <FormControl label={label}>
        <ChoiceGroup
          color={color}
          activeValues={activeValues}
          defaultValues={defaultValues}
          isMultiple={isMultiple}
          onChange={handleChangeWeekly}
        />
      </FormControl>
    </Container>
  );
}
