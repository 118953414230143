import React from 'react';
import { useParams } from 'react-router-dom';

// Store Hooks

// UI Components
import {
  Modal,
  ExtraOneButton,
  Link,
  FormField,
  Button,
  IconEnum,
  Section,
  Tag
} from '@src/ui/kit';
import { PointTitle } from '@src/ui/kit/DetailsPoint/styled';
import { ModalButtonsWrap } from '@src/ui/form/styled';
import { StatItem, StatWrap } from '@src/ui/features/PointStat';

// Helpers
import { decodeDelay } from '@src/common/helpers';

// Types
import { TPointSendSmsStatistics } from '@src/common/types/statistics';
import { TSendSms } from '@src/common/types/points';
import { ModalType } from '@src/common/constants/modals';

// Styled
import { useCloseModalByType } from '@src/store/hooks/modals/useCloseModalByType';
import { DetailBlock, DetailTitle, TagWrap } from '../styled';

interface IProps {
  applicationCode: string;
  content: string;
  campaignCode: string;
  behaviorSplitterEnabled: TSendSms['data']['smsBehaviorSplitter']['enabled'];
  behaviorSplitterInterval: TSendSms['data']['smsBehaviorSplitter']['interval'];
  sent: TPointSendSmsStatistics['pointStat']['sent'];
  delivered: TPointSendSmsStatistics['pointStat']['delivered'];
  pointTitle: string;
  enabledPersonalise: TSendSms['data']['personalise']['enabled'];
  placeholders: TSendSms['data']['personalise']['placeholders'];
}

export function DetailSendSms(props: IProps): JSX.Element {
  const {
    sent,
    delivered,
    applicationCode,
    behaviorSplitterEnabled,
    behaviorSplitterInterval,
    campaignCode,
    content,
    pointTitle,
    enabledPersonalise,
    placeholders
  } = props;
  const params = useParams<{ applicationCode: string }>();

  const closeModalByType = useCloseModalByType();
  const isEnabledBehaviorSplitter =
    behaviorSplitterEnabled &&
    typeof behaviorSplitterInterval === 'number' &&
    behaviorSplitterInterval > 0;
  const time = decodeDelay(behaviorSplitterInterval);

  const statsLink = params.applicationCode
    ? `https://app.pushwoosh.com/applications/${applicationCode}/campaigns/${campaignCode}/statistics`
    : `https://go.pushwoosh.com/cp/applications/${applicationCode}/campaigns/${campaignCode}/statistics`;

  return (
    <Modal
      title={
        <>
          Sms:&nbsp;
          <PointTitle>{pointTitle}</PointTitle>
        </>
      }
      footerRight={
        <ExtraOneButton
          actionButtonName="Close"
          onClickActionButton={() => closeModalByType(ModalType.DETAIL_POINT)}
        />
      }
      footerLeft={
        <ModalButtonsWrap>
          <Link isExternal to={statsLink} target="_blanc">
            <Button
              color="primary"
              size="field"
              view="ghost"
              iconPosition="left"
              iconType={IconEnum.OPEN_MEDIUM_LINED}
            >
              Full Statistics
            </Button>
          </Link>
        </ModalButtonsWrap>
      }
      isOpen
    >
      <Section direction="column">
        <StatWrap>
          <StatItem title="SENT" value={sent} />
          <StatItem title="DELIVERED" value={delivered} />
        </StatWrap>
        <DetailBlock>
          <FormField value={content} label="Content:" direction="col" />
        </DetailBlock>
        {enabledPersonalise && (
          <DetailBlock>
            <DetailTitle>Personalise message with event attributes:</DetailTitle>
            {placeholders.map((placeholder) => (
              <TagWrap>
                <Tag label="Overwrite placeholder" value={placeholder.name} />
                <Tag label="Source event" value={placeholder.eventName} />
                <Tag label="Dynamic attribute" value={placeholder.attribute} />
              </TagWrap>
            ))}
          </DetailBlock>
        )}
        {isEnabledBehaviorSplitter && (
          <DetailBlock>
            <FormField
              value={`${time.dd} days ${time.hh} hours ${time.mm} minutes`}
              label="Wait period to check that the message is opened:"
              direction="col"
            />
          </DetailBlock>
        )}
      </Section>
    </Modal>
  );
}
