import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgNotificationDisabledMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.25 9c0 1.577.212 2.78.492 3.681L15.84 10.78A16.863 16.863 0 0115.75 9a3.75 3.75 0 00-5.163-3.474L9.464 4.403A5.25 5.25 0 0117.25 9z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.088 7.148L5 5.061A.75.75 0 116.06 4L19 16.938A.75.75 0 0117.94 18l-1.5-1.5H5.25a.747.747 0 01-.637-.354c-.113-.179-.138-.336-.108-.48a.75.75 0 01.379-.57l.058-.06.115-.118c.169-.178.41-.477.66-.933.495-.909 1.033-2.47 1.033-4.985 0-.639.117-1.265.338-1.852zM14.939 15H6.862c.057-.094.114-.193.172-.297C7.663 13.549 8.25 11.736 8.25 9c0-.214.018-.427.054-.635L14.94 15z"
        fill={props.fill}
      />
      <path
        d="M11.351 18.374a.75.75 0 00-1.297.752 2.25 2.25 0 003.892 0 .75.75 0 10-1.297-.752.75.75 0 01-1.298 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgNotificationDisabledMediumLined;
