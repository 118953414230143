import { Color } from '@pushwoosh/kit-constants';

// Constants
import { JourneyStatus } from '@src/common/constants/journey';

export const defineColorByStatus = (status: JourneyStatus): Color => {
  switch (status) {
    case JourneyStatus.RUNNING: {
      return Color.SUCCESS;
    }

    case JourneyStatus.PAUSED:
    case JourneyStatus.DRAFT: {
      return Color.SOFT;
    }

    case JourneyStatus.FINISHED:
    case JourneyStatus.ARCHIVED: {
      return Color.LOCKED;
    }

    default: {
      return Color.CLEAR;
    }
  }
};
