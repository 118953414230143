import React from 'react';
import { RewardIcon } from '@pushwoosh/kit-icons';
import { useCommentEvents } from '../../hooks/useCommentEvents';
import { Container, Header, Number, Date as StyledDate, IconWrap, Message } from './styled';

type Props = {
  order: string;
  date: string;
  message: string;
  commentId: string;
  onClick: (commentId: string) => void;
  onRestore: (commentId: string) => void;
};

export function DeletedComment(props: Props): JSX.Element {
  const { onClick, commentId, order, date, onRestore, message } = props;
  const commentEvents = useCommentEvents();

  return (
    <Container
      onClick={() => onClick(commentId)}
      onMouseDown={commentEvents.onMouseDown}
      onMouseUp={commentEvents.onMouseUp}
      isPressed={commentEvents.isPressed}
      isDeleteHovered={commentEvents.isDeleteHovered}
    >
      <Header>
        <Number isDeleteHovered={commentEvents.isDeleteHovered}>{order}</Number>
        <StyledDate>{date}</StyledDate>
        <IconWrap
          onMouseEnter={commentEvents.onMouseEnter}
          onMouseLeave={commentEvents.onMouseLeave}
          onMouseDown={commentEvents.onMouseDown}
          onMouseUp={commentEvents.onMouseUp}
          onClick={() => onRestore(commentId)}
        >
          <RewardIcon size="medium" view="filled" />
        </IconWrap>
      </Header>
      <Message>{message}</Message>
    </Container>
  );
}
