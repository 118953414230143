import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgCommentCanvas(props: IProps): JSX.Element {
  return (
    <svg
      width={41}
      height={40}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.666 5c-7.18 0-13 5.82-13 13 0 8.274 7.439 11.498 13 16 5.562-4.502 13-7.726 13-16 0-7.18-5.82-13-13-13zM14.667 21h7M14.667 16h12"
        stroke={props.fill}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
    </svg>
  );
}

export default SvgCommentCanvas;
