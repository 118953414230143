import React from 'react';
import { Button, Modal } from '@src/ui/kit';
import { useForm } from '@src/ui/hooks';
import { FormInput } from '@src/ui/form';

import { FormTextarea } from '@src/ui/form/FormTextarea';
import { useCloseAllModals, useOpenNewModal } from '@src/store/hooks';
import { useSession } from '@src/common/contexts/session';
import { ModalType } from '@src/common/constants';
import { sendMixpanelEvent } from '@src/common/analytics';
import { registerInZoho } from '@src/ui/features/Modals/ContactSales/api';
import { validationSchema } from './validationSchema';
import { FooterWrapper, FormRow, FormWrapper } from './styled';

export function ContactSales() {
  const closeAllModals = useCloseAllModals();
  const openNewModal = useOpenNewModal();
  const { user, account } = useSession();

  const { values, handleSubmit, setFieldValue, errors } = useForm({
    validationSchema,
    initialValues: {
      email: user.email || '',
      firstName: account.billing.primaryPersonFirstName || '',
      lastName: account.billing.primaryPersonLastName || '',
      company: account.billing.companyName || '',
      message: ''
    },
    onSubmit(): void {
      registerInZoho(values, account).then(() => {
        closeAllModals();
        openNewModal({
          type: ModalType.CONTACT_SALES_SUCCESS
        });
        sendMixpanelEvent({
          eventName: 'CJPaidFeatureFormCompleted'
        });
      });
    }
  });

  const handleClose = () => {
    closeAllModals();
  };

  return (
    <Modal
      isOpen
      widthType="short"
      title="Contact sales"
      footerRight={
        <FooterWrapper>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Send request </Button>
        </FooterWrapper>
      }
    >
      <FormWrapper>
        <FormRow>
          <FormInput
            error={errors.email}
            onChange={setFieldValue}
            name={'email'}
            value={values.email}
            label="EMAIL"
          />
        </FormRow>
        <FormRow>
          <FormInput
            onChange={setFieldValue}
            name={'firstName'}
            value={values.firstName}
            label="FIRST NAME"
            error={errors.firstName}
          />
        </FormRow>
        <FormRow>
          <FormInput
            onChange={setFieldValue}
            name={'lastName'}
            value={values.lastName}
            label="LAST NAME"
            error={errors.lastName}
          />
        </FormRow>
        <FormRow>
          <FormInput
            onChange={setFieldValue}
            name={'company'}
            value={values.company}
            label="COMPANY"
            error={errors.company}
          />
        </FormRow>
        <FormRow>
          <FormTextarea
            onChange={(name, value) => {
              setFieldValue('message', value);
            }}
            placeholder="e.g. I want to request a Custom plan demo"
            name={'message'}
            value={values.message}
            label="HOW ELSE CAN WE HELP?"
          />
        </FormRow>
      </FormWrapper>
    </Modal>
  );
}
