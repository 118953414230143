import { thunk, computed, actionOn, action } from 'easy-peasy';

// Analytics
import { sendEventToGA } from '@src/common/analytics';

// Helpers
import { getShowGettingStorage } from '@src/common/helpers';

// Store helpers
import { convertArrayToDictionary } from '@src/store/helpers';

// Constants
import { Modes, JourneyStatus, PointType } from '@src/common/constants';
import { TimePeriod } from '@src/common/constants/stats';

// Types
import { TPoints } from '@src/common/types/points';
import { IDictionary } from '@src/common/types/types';
import { TJourney } from '@src/common/types/journey';
import { IUniqueId } from '@src/common/types/entities';
import { makeUniqueId } from '@src/common/helpers/makeUniqueId';
import { IJourneysModel } from './journeys.types';

export const model: IJourneysModel = {
  currentJourneyId: '',
  isGettingStarted: false,
  isUnsavedChanges: false,
  isValidated: false,
  pagination: {
    page: 0,
    perPage: 0,
    pageCount: 0,
    totalCount: 0
  },
  entities: {},

  setGettingStarted: action((state, value): void => {
    state.isGettingStarted = value;
  }),

  setUnsavedChanges: action((state, payload): void => {
    const currentStatus = state.entities[state.currentJourneyId]?.status;
    if (currentStatus === JourneyStatus.DRAFT) {
      state.isUnsavedChanges = payload;
    }
  }),

  updatePagination: action((state, payload): void => {
    state.pagination = payload;
  }),

  journeyById: computed((state): ((journeyId: IUniqueId['id']) => TJourney) => (id): TJourney => {
    const journey = state.entities[id];

    return journey;
  }),

  journeyAppCode: computed((state) => (id) => state.entities[id].params.applicationCode),

  items: computed(
    [(state): IDictionary<TJourney> => state.entities],
    (journeysEntities): TJourney[] => Object.values(journeysEntities)
  ),

  setCurrentJourneyId: action((state, payload): void => {
    state.currentJourneyId = payload;
  }),

  load: thunk(async (actions, payload, helpers): Promise<IDictionary<TJourney>> => {
    const {
      injections: { journeysService, api },
      getStoreActions
    } = helpers;

    const { setGettingStarted, updatePagination } = getStoreActions().journeys;
    const response = await journeysService.fetch(payload, api);
    setGettingStarted(false);

    // eslint-disable-next-line
    const metadata = response._metadata;
    updatePagination({
      page: metadata.page,
      perPage: 20,
      pageCount: metadata.page_count,
      totalCount: metadata.total_count
    });

    return convertArrayToDictionary(response.payload.items);
  }),

  create: thunk(async (_, payload, helpers): Promise<TJourney> => {
    const {
      injections: { journeysService, api },
      getStoreState
    } = helpers;
    const points: TPoints[] = payload.points || getStoreState().points.items;
    const { title, applicationCode } = payload;

    if (payload.points && payload.points.length === 1) {
      sendEventToGA({
        eventAction: 'Click',
        eventLabel: 'CreateNewJourney'
      });
    }

    const response = await journeysService.create(
      points,
      title,
      {
        applicationCode
      },
      [],
      api
    );

    return response.payload;
  }),

  loadById: thunk(async (_, payload, helpers): Promise<TJourney> => {
    const {
      injections: { journeysService, api },
      getStoreActions
    } = helpers;

    const { setMode } = getStoreActions().mode;
    const { setGettingStarted } = getStoreActions().journeys;
    const { setPeriodSettings } = getStoreActions().statistics;

    const response = await journeysService.fetchById(payload, api);

    setPeriodSettings({
      period: TimePeriod.ALL_TIME,
      dateFrom: new Date(response.payload.createdAt),
      dateTo: new Date()
    });

    const mode = response?.payload?.status === JourneyStatus.DRAFT ? Modes.EDIT : Modes.READ;
    setMode(mode);

    const isShowedGettingStarted = getShowGettingStorage();
    if (!isShowedGettingStarted && mode === Modes.EDIT) {
      setGettingStarted(true);
    }

    return response.payload;
  }),

  editById: thunk(async (actions, payload, helpers): Promise<TJourney> => {
    const {
      injections: { journeysService, api },
      getStoreState
    } = helpers;

    const points = getStoreState().points.items;

    const { comments, deletedComments } = getStoreState().comments;
    const journey = getStoreState().journeys.journeyById(payload.id);
    const title = payload.title || journey.title;
    const params = { ...journey.params, ...payload.params };
    const response = await journeysService.editJourneyById(
      payload.id,
      points,
      { title, params },
      [...comments, ...deletedComments],
      api
    );

    return response.payload;
  }),

  resumeById: thunk(async (actions, payload, helpers): Promise<TJourney> => {
    const {
      injections: { journeysService, api },
      getStoreActions
    } = helpers;
    const { errorsPoint, sidebars, journeys } = getStoreActions();

    errorsPoint.close();

    const response = await journeysService.resumeById(
      payload.id,
      payload,
      api,
      errorsPoint,
      sidebars
    );

    if (!response.error) {
      journeys.setValidated(true);
      getStoreActions().journeys.updateEntiti({ journey: response.payload, journeyId: payload.id });

      getStoreActions().mode.setMode(Modes.READ);
    } else {
      journeys.setValidated(false);
    }

    return response.payload;
  }),

  updateById: thunk(async (actions, payload, helpers): Promise<TJourney> => {
    const {
      injections: { journeysService, api },
      getStoreState
    } = helpers;

    const points = getStoreState().points.items;
    const { comments, deletedComments } = getStoreState().comments;
    const journey = getStoreState().journeys.journeyById(payload.id);
    const title = payload.title || journey.title;
    const params = { ...journey.params, ...payload.params };
    const response = await journeysService.updateById(
      payload.id,
      points,
      { title, params },
      [...comments, ...deletedComments],
      api
    );
    actions.setUnsavedChanges(false);

    return response.payload;
  }),

  renameById: thunk(async (actions, payload, helpers): Promise<void> => {
    const {
      injections: { journeysService, api },
      getStoreState
    } = helpers;

    const journey = getStoreState().journeys.journeyById(payload.id);
    const title = payload.title || journey.title;
    const response = await journeysService.renameById(payload.id, { title }, api);
    // actions.setUnsavedChanges(false);

    return response.payload;
  }),

  updateABSplitterByJourneyId: thunk(async (actions, payload, helpers): Promise<TJourney> => {
    const {
      injections: { journeysService, api }
    } = helpers;

    const { id, pointId, split } = payload;

    const response = await journeysService.updateABSplitterByJourneyId(id, pointId, split, api);

    return response.payload;
  }),

  autoSaveById: thunk(async (actions, payload, helpers): Promise<TJourney> => {
    const {
      injections: { journeysService, api },
      getStoreState
    } = helpers;

    const points = getStoreState().points.items;
    const { comments, deletedComments } = getStoreState().comments;
    const journey = getStoreState().journeys.journeyById(payload.id);
    const { title, params } = journey;
    const response = await journeysService.updateById(
      payload.id,
      points,
      { title, params },
      [...comments, ...deletedComments],
      api
    );
    actions.setUnsavedChanges(false);

    return response.payload;
  }),

  deleteById: thunk(async (_, payload, helpers): Promise<void> => {
    const {
      injections: { journeysService, api }
    } = helpers;

    await journeysService.deleteById(payload, api);
  }),

  archiveById: thunk(async (_, payload, helpers): Promise<void> => {
    const {
      injections: { journeysService, api }
    } = helpers;

    await journeysService.archiveById(payload, api);
  }),

  cloneById: thunk(async (_, payload, helpers): Promise<TJourney> => {
    const {
      injections: { journeysService, api },
      getState,
      getStoreState
    } = helpers;
    const { id, title } = payload;
    const { currentJourneyId } = getState();

    if (!currentJourneyId) {
      const fetchResponse = await journeysService.fetchById(id, api);
      const journey = fetchResponse.payload;

      const newPoints = journey.points.map((point) => {
        if (
          point.type === PointType.SEND_EMAIL ||
          point.type === PointType.SEND_PUSH ||
          point.type === PointType.SEND_SMS
        ) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { campaignUID, campaignCode, ...otherData } = point.data;

          return {
            ...point,
            originalId: point.id,
            id: makeUniqueId(),
            data: { ...otherData }
          };
        }

        return {
          ...point,
          originalId: point.id,
          id: makeUniqueId()
        };
      }) as TPoints[];

      const newPointsWithArrows = newPoints.map((point) => {
        if (point.outputs.length !== 0) {
          const newPoint = { ...point };

          newPoint.outputs = point.outputs.map((output) => {
            const newId = newPoints.find((point) => point.originalId === output.id).id;
            return {
              ...output,
              id: newId
            };
          });

          delete newPoint.originalId;

          return newPoint;
        }

        const { originalId, ...rest } = point;

        return rest;
      });

      const response = await journeysService.create(
        newPointsWithArrows,
        title,
        journey.params,
        journey.comments,
        api
      );

      return response.payload;
    }

    const getJourneyById = getStoreState().journeys.journeyById;
    const journey = getJourneyById(id);

    const points = getStoreState().points.items;

    const newPoints = points.map((point) => {
      if (
        point.type === PointType.SEND_EMAIL ||
        point.type === PointType.SEND_PUSH ||
        point.type === PointType.SEND_SMS
      ) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { campaignCode, campaignUID, ...otherData } = point.data;

        return {
          ...point,
          id: makeUniqueId(),
          originalId: point.id,
          data: {
            ...otherData,
            personalise: {
              ...point.data.personalise,
              enabled: point.data.personalise?.enabled,
              placeholders: []
            }
          }
        };
      }

      return {
        ...point,
        originalId: point.id,
        id: makeUniqueId()
      };
    }) as unknown as TPoints[];

    const newPointsWithArrows = newPoints.map((point) => {
      if (point.outputs.length !== 0) {
        const newPoint = { ...point };

        newPoint.outputs = point.outputs.map((output) => {
          const newId = newPoints.find((point) => point.originalId === output.id).id;
          return {
            ...output,
            id: newId
          };
        });

        delete newPoint.originalId;

        return newPoint;
      }

      const { originalId, ...rest } = point;

      return rest;
    });

    const response = await journeysService.create(
      newPointsWithArrows,
      title,
      journey.params,
      journey.comments,
      api
    );

    return response.payload;
  }),

  setValidated: action((state, value) => {
    state.isValidated = value;
  }),

  updateEntiti: action((state, payload) => {
    const { journey, journeyId } = payload;

    state.entities[journeyId] = journey;
  }),

  pauseById: thunk(async (actions, payload, helpers): Promise<TJourney> => {
    const {
      injections: { journeysService, api },
      getStoreActions
    } = helpers;

    const { journeyId } = payload;

    const res = await journeysService.pauseById(journeyId, api);

    getStoreActions().journeys.updateEntiti({ journey: res.payload, journeyId });

    getStoreActions().mode.setMode(Modes.EDIT);

    return res.payload;
  }),

  validateById: thunk(async (actions, payload, helpers): Promise<TJourney> => {
    const {
      injections: { journeysService, api },
      getStoreActions,
      getStoreState
    } = helpers;

    const { errorsPoint, sidebars, journeys } = getStoreActions();

    errorsPoint.close();

    const points = getStoreState().points.items;
    const { comments, deletedComments } = getStoreState().comments;
    const journey = getStoreState().journeys.journeyById(payload.journeyId);
    const { title, params } = journey;

    await journeysService.updateById(
      payload.journeyId,
      points,
      { title, params },
      [...comments, ...deletedComments],
      api
    );
    actions.setUnsavedChanges(false);

    const response = await journeysService.validateById(
      payload.journeyId,
      api,
      errorsPoint,
      sidebars
    );

    if (!response.error) {
      journeys.setValidated(true);
    } else {
      journeys.setValidated(false);
    }

    if (payload.onSuccessValidate) {
      payload.onSuccessValidate();
    }

    return response.payload;
  }),

  activateById: thunk(async (_, payload, helpers): Promise<TJourney> => {
    const {
      injections: { journeysService, api },
      getStoreActions
    } = helpers;

    const { notifications, errorsPoint, sidebars } = getStoreActions();

    const response = await journeysService.activateById(
      payload,
      api,
      notifications,
      errorsPoint,
      sidebars
    );
    const { setMode } = getStoreActions().mode;
    setMode(Modes.READ);
    sendEventToGA({
      eventAction: 'Click',
      eventLabel: 'ActivateJourney'
    });

    return response.payload;
  }),

  deactivateById: thunk(async (_, payload, helpers): Promise<TJourney> => {
    const {
      injections: { journeysService, api }
    } = helpers;

    const response = await journeysService.deactivateById(payload, api);

    return response.payload;
  }),

  onLoad: actionOn(
    (state): string => state.load.successType,
    (state, target): void => {
      state.entities = target.result;
    }
  ),

  onDeleteById: actionOn(
    (state): string[] => [state.deleteById.successType],
    (state, target): void => {
      delete state.entities[target.payload];
    }
  ),

  onArchiveById: actionOn(
    (state): string[] => [state.archiveById.successType],
    (state, target): void => {
      delete state.entities[target.payload];
    }
  ),

  onChangeById: actionOn(
    (state): string[] => [
      state.create.successType,
      state.loadById.successType,
      state.updateById.successType,
      state.cloneById.successType,
      state.activateById.successType,
      state.deactivateById.successType
    ],
    (state, target): void => {
      state.entities[target.result.id] = target.result;
    }
  )
};
