// Types
import { UpdateTag } from '@src/common/types/tag';

// Constants
import { TypeTag } from '@src/common/constants/tag';

type SeparateTags = {
  manualTagsList: UpdateTag[];
  dynamicTagsList: UpdateTag[];
};

export const getSeparateTags = (tags: UpdateTag[]): SeparateTags => {
  const manualTagsList: UpdateTag[] = [];
  const dynamicTagsList: UpdateTag[] = [];

  tags.forEach((tag) => {
    if (tag.type === TypeTag.DYNAMIC) {
      dynamicTagsList.push(tag);
    } else {
      manualTagsList.push(tag);
    }
  });

  return {
    manualTagsList,
    dynamicTagsList
  };
};
