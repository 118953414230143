import React from 'react';
import { Icon, IconEnum } from '@src/ui/kit';
import { Color } from '@pushwoosh/kit-constants';

// Enums
import { SubscriptionPlan } from '@src/common/constants/subscriptionPlan';

import { TemplateCategories } from '@src/common/constants/templates';
import { TemplateMenu } from '../TemplateMenu';
import { AccountMenu } from '../AccountMenu';
import { Container, Item, StyledNavLink, StyledLink } from './styled';

interface IProps {
  idCategory: TemplateCategories;
  username: string;
  onLogout: () => void;
  subscriptionPlan: SubscriptionPlan;
}

export function SidebarNav(props: IProps): JSX.Element {
  const { subscriptionPlan } = props;

  return (
    <Container>
      <Item>
        <TemplateMenu idCategory={props.idCategory} />
      </Item>
      <Item>
        <StyledNavLink to="/journeys/list">Journeys list</StyledNavLink>
      </Item>
      <Item>
        <StyledLink
          to="https://go.pushwoosh.com"
          rel="noopener noreferrer"
          target="_blank"
          isExternal
        >
          Pushwoosh Control Panel
          <Icon type={IconEnum.OPEN_MEDIUM_LINED} fill={Color.MAIN} />
        </StyledLink>
      </Item>
      <Item>
        <StyledLink
          to="https://go.pushwoosh.com/cp/applications"
          rel="noopener noreferrer"
          target="_blank"
          isExternal
        >
          Applications
          <Icon type={IconEnum.OPEN_MEDIUM_LINED} fill={Color.MAIN} />
        </StyledLink>
      </Item>
      <Item>
        <StyledLink
          to="https://docs.pushwoosh.com/customer-journey-docs/"
          rel="noopener noreferrer"
          target="_blank"
          isExternal
        >
          Documentation
          <Icon type={IconEnum.OPEN_MEDIUM_LINED} fill={Color.MAIN} />
        </StyledLink>
      </Item>
      <Item height={72} isTopBorder isFixedBottom>
        <AccountMenu
          username={props.username}
          onLogout={props.onLogout}
          subscriptionPlan={subscriptionPlan}
        />
      </Item>
    </Container>
  );
}
