import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgLoginMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 5.25a.75.75 0 000 1.5h2c.69 0 1.25.56 1.25 1.25v8c0 .69-.56 1.25-1.25 1.25h-2a.75.75 0 000 1.5h2A2.75 2.75 0 0018.75 16V8A2.75 2.75 0 0016 5.25h-2z"
        fill={props.fill}
      />
      <path
        d="M9.47 7.47a.75.75 0 011.06 0l4 4a.748.748 0 010 1.06l-4 4a.75.75 0 11-1.06-1.06l2.72-2.72H5a.75.75 0 010-1.5h7.19L9.47 8.53a.75.75 0 010-1.06z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgLoginMediumLined;
