import React from 'react';
import { Button, Icon, IconEnum, Message, Section, Select, Switch } from '@src/ui/kit';
import { TSelectOption } from '@src/ui/kit/Select';
import { Placeholder } from '@src/common/types/placeholder';
import {
  useCloseAllModals,
  useCurrentJourneyId,
  useDynamicEvents,
  useJourneyAppCode,
  useOpenNewModal,
  useRestrictions
} from '@src/store/hooks';
import { StyledPremiumIconWrapper } from '@src/ui/features/StartSegment/components/ScheduledLaunch/styled';
import { Color } from '@pushwoosh/kit-constants';
import { sendMixpanelEvent } from '@src/common/analytics';
import { H3 } from '@pushwoosh/kit-typography';
import { TitleWrap } from '@src/ui/form/styled';
import { ModalType } from '@src/common/constants';
import { PremiumFeatures } from '@src/ui/features/Modals/PremiumModal/types';
import { PlaceholdersForm } from '../components/PlaceholdersForm';
import {
  Block,
  DefaultLink,
  ErrorMessage,
  Flex,
  OverrideWarn,
  Paragraph,
  StyledIcon,
  StyledLink
} from './styled';

type Props = {
  pointId: string;
  enabledPersonalise: boolean;
  enabledLiquid: boolean;
  liquidEvent?: string;
  placeholders: Placeholder[];
  error: string;
  onSwitch: (value: boolean) => void;
  onSelectPlaceholder: (value: boolean, index: number) => void;
  onSelectEvent: (value: string, pointId: string, index: number) => void;
  onSelectAttribute: (value: string, index: number) => void;
  onSubmit: () => void;
  onLiquidEnabledChange: (value: boolean) => void;
  onLiquidEventChange: (value: string) => void;
};

export function PersonaliseMessage(props: Props): JSX.Element {
  const {
    pointId,
    enabledPersonalise,
    enabledLiquid,
    liquidEvent,
    placeholders,
    error,
    onSwitch,
    onSelectPlaceholder,
    onSelectEvent,
    onSelectAttribute,
    onSubmit,
    onLiquidEnabledChange,
    onLiquidEventChange
  } = props;

  const { allowCjContentPersonalization } = useRestrictions();

  const journeyId = useCurrentJourneyId();
  const applicationCode = useJourneyAppCode(journeyId);
  const { eventNameOptions, getCurrentEvent, getEventValue } = useDynamicEvents(applicationCode);
  const selectedLiquidEvent = getCurrentEvent(liquidEvent, pointId);

  const closeAllModals = useCloseAllModals();
  const openNewModal = useOpenNewModal();

  const handleSendMixpanelEvent = () => {
    sendMixpanelEvent({
      eventName: 'JourneyCanvasRestrictionButtonClicked',
      eventData: {
        point: 'Content Personalization'
      }
    });
  };

  const handleContactSalesClick = () => {
    onSubmit();
    closeAllModals();
    openNewModal({
      type: ModalType.PREMIUM,
      data: {
        premiumType: PremiumFeatures.OVERWRITE
      }
    });
    sendMixpanelEvent({
      eventName: 'JourneyCanvasRestrictionButtonClicked',
      eventData: {
        point: 'Content Personalization'
      }
    });
  };

  return (
    <Section direction="column">
      <TitleWrap>
        <H3>Overwrite personalization</H3>

        <Paragraph>
          Configure Tag placeholders personalisation to specific event attributes and map Liquid
          Tags with Events
        </Paragraph>

        {!allowCjContentPersonalization && (
          <Message
            icon={
              <StyledPremiumIconWrapper>
                <Icon type={IconEnum.PREMIUM_MONO} width={16} height={16} />
              </StyledPremiumIconWrapper>
            }
            color={Color.BRIGHT_LIGHT}
            fullWidth
            title="Upgrade your plan to use this feature with a journey "
          />
        )}
      </TitleWrap>

      {/*  Tags personalization */}
      <Block>
        <Flex>
          <Switch
            defaultEnabled={enabledPersonalise}
            onChange={onSwitch}
            isDisabled={!allowCjContentPersonalization}
          >
            Personalise message with event attributes
            {!allowCjContentPersonalization && (
              <Button
                iconType={IconEnum.OPEN_SMALL_LINED}
                onClick={handleContactSalesClick}
                view="ghost"
                size="compact"
              >
                CONTACT SALES
              </Button>
            )}
          </Switch>
          {enabledPersonalise && enabledLiquid && (
            <OverrideWarn>
              <Icon fill={'white'} type={IconEnum.WARNING_ROUND_SMALL_LINED} />
              Override by Liquid
            </OverrideWarn>
          )}
        </Flex>

        <Paragraph>
          Enable this option to use event attributes or Webhook-based entry placeholders instead of
          device tags as value sources in message content and presets.&nbsp;
          <DefaultLink
            to="https://docs.pushwoosh.com/platform-docs/send-messages/customer-journey-builder/journey-elements/dynamic-content-and-liquid-templates-in-journeys"
            isExternal
          >
            Learn more
          </DefaultLink>
        </Paragraph>
        {!allowCjContentPersonalization && (
          <Message
            icon={
              <StyledPremiumIconWrapper>
                <Icon type={IconEnum.PREMIUM_MONO} width={16} height={16} />
              </StyledPremiumIconWrapper>
            }
            color={Color.BRIGHT_LIGHT}
            fullWidth
            title="Upgrade your plan to use this feature with a journey "
          />
        )}
        {enabledPersonalise && placeholders.length > 0 && (
          <>
            <PlaceholdersForm
              placeholders={placeholders}
              onSelectPlaceholder={onSelectPlaceholder}
              onSelectEvent={onSelectEvent}
              onSelectAttribute={onSelectAttribute}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Block>

      {/*  Liquid personalization */}
      <Block>
        <Switch
          defaultEnabled={enabledLiquid}
          onChange={onLiquidEnabledChange}
          isDisabled={!allowCjContentPersonalization}
        >
          Overwrite Liquid placeholders
          {!allowCjContentPersonalization && (
            <StyledLink
              target="_blank"
              isExternal
              onClick={handleSendMixpanelEvent}
              to="https://www.pushwoosh.com/demo/?utm_content=pwproduct&utm_term=journeypoint&utm_campaign=demo"
            >
              <StyledIcon type={IconEnum.OPEN_SMALL_LINED} />
              CONTACT SALES
            </StyledLink>
          )}
        </Switch>
        <Paragraph>
          Attributes from the selected Event will be used in Liquid personalisation and all of the
          Liquid tags matching attributes by name will use values from the event.&nbsp;
          <DefaultLink
            to="https://docs.pushwoosh.com/platform-docs/send-messages/customer-journey-builder/journey-elements/dynamic-content-and-liquid-templates-in-journeys"
            isExternal
          >
            Learn more
          </DefaultLink>
        </Paragraph>

        <br />

        {enabledLiquid && (
          <Select
            value={selectedLiquidEvent}
            options={eventNameOptions}
            onChange={(item) => {
              const event = item as TSelectOption;
              const { eventName } = getEventValue(event.value as unknown as string);

              onLiquidEventChange(eventName);
            }}
          />
        )}
      </Block>
    </Section>
  );
}
