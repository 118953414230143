import { TFormErrors } from '@src/ui/hooks/useForm';
import { FormikTouched } from 'formik';

export const touchErrors = <T>(errors: TFormErrors<T>): FormikTouched<T> => {
  if (!errors) return undefined;

  return Object.entries(errors)
    .map(([key, value]) => {
      if (!value) {
        return [key, false];
      }

      if (Array.isArray(value)) {
        const newArray = value.map(touchErrors);
        return [key, newArray];
      }

      if (typeof value === 'object') {
        return [key, touchErrors(value)];
      }

      return [key, true];
    })
    .reduce((acc, [key, value]) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      acc[key] = value;
      return acc;
    }, {} as FormikTouched<T>);
};

export const treeShakeErrors = <T>(
  errors: TFormErrors<T> | undefined
): TFormErrors<T> | undefined => {
  if (!errors) return undefined;

  const newErrors = Object.entries(errors)
    .map(([key, value]) => {
      if (!value) {
        return [key, undefined];
      }

      if (Array.isArray(value)) {
        const newArray = value.map(treeShakeErrors);

        if (newArray.some(Boolean)) {
          return [key, newArray];
        }

        return [key, undefined];
      }

      if (typeof value === 'object') {
        return [key, treeShakeErrors(value)];
      }

      return [key, value];
    })
    .reduce((acc, [key, value]) => {
      if (value) {
        acc[key as keyof TFormErrors<T>] = value as TFormErrors<T>[keyof TFormErrors<T>];
      }

      return acc;
    }, {} as TFormErrors<T>);

  if (Object.keys(newErrors).length) {
    return newErrors;
  }

  return undefined;
};

export const countErrors = <T>(errors: TFormErrors<T> | undefined): number => {
  if (!errors) return 0;

  let count = 0;

  Object.values(errors).forEach((value) => {
    if (!value) {
      return;
    }

    if (Array.isArray(value)) {
      count += value.map(countErrors).reduce((acc, cur) => acc + cur, 0);
      return;
    }

    if (typeof value === 'object') {
      count += countErrors(value);
      return;
    }

    count += 1;
  });

  return count;
};
