import * as React from 'react';

// Components
import { FormInput } from '@src/ui/form';

// Types
import { EditTitleProps } from './EditTitle.types';

export function EditTitle(props: EditTitleProps): JSX.Element {
  const { names, values, errors, touched, labelInput, setFieldValue, onBlurField, onSubmit } =
    props;

  return (
    <form onSubmit={onSubmit}>
      <FormInput
        label={labelInput}
        error={touched.title && errors.title}
        name={names.title}
        value={values.title}
        onChange={setFieldValue}
        onBlur={onBlurField}
        autoFocus
      />
    </form>
  );
}
