import React from 'react';
import { TComment } from '@src/common/types/comments';
import { TrashIcon } from '@pushwoosh/kit-icons';
import { Button } from '@src/ui/kit';
import { useCommentEvents } from '../../hooks/useCommentEvents';
import { Container, IconWrap, Number, Header, Date, Message, ButtonWrap } from './styled';

type Props = {
  order: string;
  date: string;
  message: string;
  commentId: string;
  createdAt: string;
  position: TComment['position'];
  disableEdit: () => void;
  updateComment: (comment: TComment) => void;
  onRemove: (commentId: string) => void;
};

export function CommentForm(props: Props): JSX.Element {
  const {
    order,
    date,
    message,
    disableEdit,
    commentId,
    createdAt,
    position,
    updateComment,
    onRemove
  } = props;
  const [localMessage, setLocalMessage] = React.useState(message);
  const commentEvents = useCommentEvents();
  const { isDeleteHovered, isPressed, onMouseLeave, onMouseEnter, onMouseDown, onMouseUp } =
    commentEvents;

  const onRemoveHandle = React.useCallback(
    (e: React.SyntheticEvent<HTMLElement, Event>) => {
      e.preventDefault();

      onRemove(commentId);
    },
    [commentId, onRemove]
  );

  const onDoneClick = React.useCallback(
    (e: React.SyntheticEvent<HTMLElement, Event>) => {
      e.preventDefault();

      const comment = {
        id: commentId,
        type: 'comment',
        message: localMessage,
        createdAt,
        deleted: false,
        position,
        title: order
      };

      updateComment(comment);
      disableEdit();
    },
    [commentId, createdAt, localMessage, order, position, updateComment, disableEdit]
  );

  const onBlur = React.useCallback(
    (e: any) => {
      if (e?.relatedTarget?.type === 'button' || e?.relatedTarget?.type === 'submit') {
        return;
      }

      disableEdit();
    },
    [disableEdit]
  );

  const onChange = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLocalMessage(e.currentTarget.value);
  }, []);

  return (
    <Container isDeleteHovered={isDeleteHovered} isPressed={isPressed}>
      <Header>
        <Number isDeleteHovered={isDeleteHovered} isPressed={isPressed}>
          {order}
        </Number>
        <Date>{date}</Date>
        <IconWrap
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onClick={onRemoveHandle}
        >
          <TrashIcon size="medium" view="filled" />
        </IconWrap>
      </Header>
      <Message autoFocus value={localMessage} onBlur={onBlur} onChange={onChange} />
      <ButtonWrap>
        <Button onClick={onDoneClick} color="primary" size="compact" view="ghost">
          Done
        </Button>
      </ButtonWrap>
    </Container>
  );
}
