import React from 'react';
import { FormikValues } from 'formik';
import { TFormFnSetFieldValue } from '@src/common/types/form';
import { ISendEmailFormValues } from '@src/ui/features/SendEmail/components/SendEmailForm';
import { Switch } from '@src/ui/kit';
import { Footnote } from '@pushwoosh/kit-typography';
import { TimeStepper } from '@src/ui/kit/TimeStepper';
import { Label, TimeStepperWrap } from './styled';

type Props = {
  isDisabled: boolean;
  values: ISendEmailFormValues;
  names: Record<keyof ISendEmailFormValues, string>;
  setValues: (values: FormikValues) => void;
  setFieldValue: TFormFnSetFieldValue;
};

const SEVEN_DAYS_IN_SECONDS = 604800;
const ONE_MINUTE_IN_SECONDS = 60;

export function SplitFlow(props: Props): JSX.Element {
  const { isDisabled, values, names, setValues, setFieldValue } = props;

  const handleSwitchBehavioralSplitter = React.useCallback(
    (value: boolean) => {
      setValues({
        ...values,
        [names.enabledBehaviorSplitter]: value,
        [names.intervalBehaviorSplitter]: value ? 86400 : 0
      });
    },
    [setValues, names, values]
  );

  const handleChangeIntervalBehavioralSplitter = React.useCallback(
    (name: string, value: number) => {
      setFieldValue(name, value);
    },
    [setFieldValue]
  );

  return (
    <>
      <Switch
        defaultEnabled={values.enabledBehaviorSplitter}
        onChange={handleSwitchBehavioralSplitter}
        isDisabled={isDisabled}
      >
        Split flow depending on whether this message is opened or ignored (not opened)
      </Switch>
      {values.enabledBehaviorSplitter && (
        <>
          <Label>How long to wait to check message opens</Label>
          <TimeStepperWrap>
            <TimeStepper
              disabled={isDisabled}
              name={names.intervalBehaviorSplitter}
              value={values.intervalBehaviorSplitter}
              onChange={handleChangeIntervalBehavioralSplitter}
              maxInSeconds={SEVEN_DAYS_IN_SECONDS}
              minInSeconds={ONE_MINUTE_IN_SECONDS}
            />
          </TimeStepperWrap>
          <Footnote>
            If the user opens the message within the specified time <br /> period, the “opened”
            branch will be activated.
          </Footnote>
        </>
      )}
    </>
  );
}
