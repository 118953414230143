import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgDownloadMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 15.25a.75.75 0 00-.53 1.28l3 3a.75.75 0 001.06 0l3-3a.75.75 0 00-.53-1.28h-2.25V9a.75.75 0 00-1.5 0v6.25H9zM5.25 6.5A2.25 2.25 0 017.5 4.25h9a2.25 2.25 0 012.25 2.25V11a2.25 2.25 0 01-2.25 2.25h-.75a.75.75 0 110-1.5h.75a.75.75 0 00.75-.75V6.5a.75.75 0 00-.75-.75h-9a.75.75 0 00-.75.75V11c0 .414.336.75.75.75h.75a.75.75 0 010 1.5H7.5A2.25 2.25 0 015.25 11V6.5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgDownloadMediumFilled;
