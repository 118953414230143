import * as React from 'react';

// Components
import { H5 } from '@pushwoosh/kit-typography';

// Constants
import { branchNames } from '@src/common/constants/ab';
import { JourneyStatus } from '@src/common/constants';
import { Button } from '@src/ui/kit';
import { FormControl } from '@src/ui/form';
import { Distribute } from './constants';

// Styled
import { Description, Labels, NumberStepper, Option, Options, ResetButtonWrap } from './styled';

// Types
import { TFormEditABSplitterProps } from './EditABSplitter.types';

export function EditABSplitter(props: TFormEditABSplitterProps): JSX.Element {
  const { names, values, setFieldValue, journeyStatus, setSaveDisabled, resetDistribution } = props;

  const { split } = values;
  const [isError, setError] = React.useState(false);
  const [, setSum] = React.useState(0);

  const handleChangeBranches = React.useCallback(
    (value: number) => {
      if (value === 2) {
        setFieldValue(names.split, Distribute.two);
      }
      if (value === 3) {
        setFieldValue(names.split, Distribute.three);
      }
      if (value === 4) {
        setFieldValue(names.split, Distribute.four);
      }
    },
    [names, setFieldValue]
  );

  const handleChangeSplitItem = React.useCallback(
    (value: number, name: string) => {
      const indexItem = parseInt(name, 10);
      const newSplit = values.split.map((item, index) => {
        if (index === indexItem) {
          return value;
        }
        return item;
      });

      setFieldValue(names.split, newSplit);
    },
    [names, values, setFieldValue]
  );

  React.useEffect(() => {
    const newSum = values.split.reduce((acc, item) => acc + item, 0);
    if (newSum !== 100) {
      if (journeyStatus === JourneyStatus.RUNNING) {
        setSaveDisabled(true);
      } else {
        setSaveDisabled(false);
      }

      setError(true);
    } else {
      setSaveDisabled(false);
      setError(false);
    }
    setSum(newSum);
  }, [values.split, journeyStatus, setSaveDisabled]);

  return (
    <>
      <Labels>
        <H5>BRANCHES:</H5>
        {split.map((_, index) => (
          <H5 key={index}>
            {branchNames[index]}
            {index === 0 && <Description>CONTROL</Description>}
          </H5>
        ))}
      </Labels>
      <Options>
        <Option>
          <NumberStepper
            dimension=""
            name="branches"
            value={split.length}
            max={4}
            step={1}
            min={2}
            onChange={({ value }) => handleChangeBranches(value)}
            isDisabled={journeyStatus === JourneyStatus.RUNNING}
          />
        </Option>
        {split.map((item, index) => (
          <Option key={index}>
            <FormControl error={isError && index === 0 && 'Distribution total must equal 100% '}>
              <NumberStepper
                dimension="%"
                name={`${index}`}
                value={item}
                max={100}
                step={1}
                min={0}
                onChange={({ value }) => handleChangeSplitItem(value, `${index}`)}
                isErrored={isError}
              />
            </FormControl>
          </Option>
        ))}
      </Options>
      <ResetButtonWrap>
        <Button size="compact" color="primary" view="ghost" onClick={resetDistribution}>
          Reset distribution
        </Button>
      </ResetButtonWrap>
    </>
  );
}
