import * as React from 'react';
import konva from 'konva';
import { Path } from 'react-konva';

// Helpers
import { getTitlePoint } from '@src/canvas/helpers';

// Constants
import {
  CONTROL_GROUP_COLOR,
  PointType,
  COLOR_TRUE,
  COLOR_FALSE
} from '@src/common/constants/point';

// Components
import { Point, PointContent, PointOutput, PointShortInfo, PointText } from '../Point';
import { getOutputs } from '../helpers/getOutputs';

// Types
import { IProps } from './PointWait.types';
import { PointIcon } from '../Point/PointIcon';
import { getValuePoint } from './helpers/getValuePoint';

const KEY_TRUE = 'output1';
const KEY_FALSE = 'default';

export function PointWait(props: IProps): JSX.Element {
  const { data, outputs, propsCommonPoint } = props;
  const [isShowBadge, setShowBadge] = React.useState([true, true]);
  const titlePoint = getTitlePoint(PointType.SEGMENT, propsCommonPoint.title, data);

  const getIsOutput = React.useCallback(
    (key: string): boolean => getOutputs(key, outputs),
    [outputs]
  );

  const valuePoint = React.useMemo((): string => getValuePoint(data), [data]);

  const labelAction =
    data.exactTime || data.exactDateTime || data.exactWeekDaysTime ? 'Exact time' : 'Delay';
  const isLinkedOutputFirst = getIsOutput(KEY_TRUE);
  const isLinkedOutputSecond = getIsOutput(KEY_FALSE);

  return (
    <Point type={PointType.WAIT} color={CONTROL_GROUP_COLOR} {...propsCommonPoint}>
      <PointContent type={PointType.WAIT}>
        <PointIcon type={PointType.WAIT} fill={CONTROL_GROUP_COLOR}>
          <Path
            fill="white"
            perfectDrawEnabled={false}
            shadowForStrokeEnabled={false}
            data="M32 20C32 26.6274 26.6274 32 20 32C13.3726 32 8 26.6274 8 20C8 13.3726 13.3726 8 20 8C26.6274 8 32 13.3726 32 20Z"
          />
          <Path
            fill={CONTROL_GROUP_COLOR}
            perfectDrawEnabled={false}
            shadowForStrokeEnabled={false}
            data="M20.5 12.5C21.0523 12.5 21.5 12.9477 21.5 13.5V14V18.5845C21.5 18.9358 21.6843 19.2613 21.9855 19.442L25.7717 21.7138L26.2005 21.971C26.6741 22.2552 26.8276 22.8694 26.5435 23.343L26.029 24.2005C25.7448 24.6741 25.1306 24.8276 24.657 24.5435L24.2283 24.2862L19.2283 21.2862C18.7764 21.0152 18.5 20.5269 18.5 20V14V13.5C18.5 12.9477 18.9477 12.5 19.5 12.5H20.5Z"
          />
        </PointIcon>
        <PointText type={PointType.WAIT}>{titlePoint}</PointText>
        {data.dynamicTime?.splitBranches ? (
          <>
            <PointOutput
              y={9}
              pointId={propsCommonPoint.id}
              outputKey={KEY_TRUE}
              type={PointType.WAIT}
              colorBlock={COLOR_TRUE}
              colorArrow={COLOR_TRUE}
              isInvalid={propsCommonPoint.isInvalid}
              isActive={propsCommonPoint.isActive}
              isLinkedOutput={isLinkedOutputFirst}
              onMouseDown={(event: konva.KonvaEventObject<MouseEvent>): void => {
                setShowBadge([isShowBadge[0], false]);
                propsCommonPoint.onMouseDownOutput(event);
              }}
              textBadge="In the future"
              fillBadge={COLOR_TRUE}
              isShowBadge={isLinkedOutputFirst ? false : isShowBadge[0]}
              arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
            />
            <PointOutput
              y={31}
              pointId={propsCommonPoint.id}
              outputKey={KEY_FALSE}
              type={PointType.SEND_PUSH}
              colorBlock={COLOR_FALSE}
              colorArrow={COLOR_FALSE}
              isInvalid={propsCommonPoint.isInvalid}
              isActive={propsCommonPoint.isActive}
              isLinkedOutput={isLinkedOutputSecond}
              onMouseDown={(event: konva.KonvaEventObject<MouseEvent>): void => {
                setShowBadge([isShowBadge[0], false]);
                propsCommonPoint.onMouseDownOutput(event);
              }}
              textBadge="In the past"
              fillBadge={COLOR_FALSE}
              isShowBadge={isLinkedOutputSecond ? false : isShowBadge[0]}
              arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
            />
          </>
        ) : (
          <PointOutput
            type={PointType.WAIT}
            pointId={propsCommonPoint.id}
            colorBlock={CONTROL_GROUP_COLOR}
            isInvalid={propsCommonPoint.isInvalid}
            isActive={propsCommonPoint.isActive}
            isLinkedOutput={propsCommonPoint.isLinkedOutput}
            onMouseDown={propsCommonPoint.onMouseDownOutput}
            arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
          />
        )}
      </PointContent>
      <PointShortInfo type={PointType.WAIT} label={`${labelAction} ${valuePoint}`} align="left" />
    </Point>
  );
}
