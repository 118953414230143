import { action, thunkOn, computed } from 'easy-peasy';
import { isAfter, isEqual } from 'date-fns';
import { filter } from 'lodash';

// Common helpers
import { pointStorage } from '@src/common/helpers';

// Helpers
import {
  makePoint,
  generateTitlePoint,
  convertArrayToDictionary,
  isOverlapPoints,
  isGoalPoint,
  isOverlapPointsWithComments
} from '@src/store/helpers';

// Constants
import { CANVAS_GRID_CELL_HEIGHT } from '@src/common/constants/canvas';

// Types
import { TPoints, TGoalEventPoint, IPointOutput, TClonedPoints } from '@src/common/types/points';
import { PointType } from '@src/common/constants/point';
import { IDictionary } from '@src/common/types/types';
import { TPointStatistics } from '@src/common/types/statistics';
import { IUniqueId } from '@src/common/types/entities';
import { IPointsModel, TUndoRedo } from './points.types';

// Init
import { AVAILABLE_POINTS } from './points.initial';

function isEmptyArray<T>(arr: T[]): boolean {
  return arr.length === 0;
}

function addPointsToUndoRedoStack<State extends { undoRedoStack: TUndoRedo[] }>(
  state: State,
  operationType: TUndoRedo['operationType'],
  points: TUndoRedo['points']
): void {
  const maxStack = 50;

  if (state.undoRedoStack.length >= maxStack) {
    state.undoRedoStack.shift();
  }

  state.undoRedoStack.push({ operationType, points });
}

export const model: IPointsModel = {
  undoRedoStack: [],

  bufferPoints: [],
  activePointIds: [],
  availablePoints: AVAILABLE_POINTS,
  entities: {},

  isSelectedPoint: computed(
    [(state): string[] => state.activePointIds, (state): typeof state.entities => state.entities],
    (activePointIds, pointEntities): boolean =>
      Boolean(
        activePointIds.length > 0 &&
          filter(pointEntities, (point) => activePointIds.includes(point.id))
      )
  ),

  isBufferPointsNotEmpty: computed(
    [(state): TPoints[] => state.bufferPoints],
    (bufferPoints): boolean => bufferPoints.length > 0
  ),

  pointById: computed(
    (state): ((pointId: IUniqueId['id']) => TPoints) =>
      (id): TPoints =>
        state.entities[id]
  ),

  items: computed([(state): IDictionary<TPoints> => state.entities], (pointsEntities): TPoints[] =>
    Object.values(pointsEntities)
  ),

  filteredItems: computed([(state): TPoints[] => state.items], (points): TPoints[] =>
    points.filter((point): boolean => !isGoalPoint(point))
  ),

  pointOutputs: computed(
    [
      (state): TPoints[] => state.filteredItems,
      (_, rootState): TPointStatistics[] => rootState.statistics.points
    ],
    (points, pointStats): IPointOutput[] =>
      points.reduce<IPointOutput[]>((acc, point): IPointOutput[] => {
        const pointStat = pointStats.find((pointStat): boolean => pointStat.pointId === point.id);

        const outputs = point.outputs.map<IPointOutput>((pointOutput): IPointOutput => {
          let stat = 0;
          if (pointStat) {
            const outputStat = pointStat.pointOutputStat.find(
              (pointOutputStat): boolean =>
                pointOutputStat.nextPointId === pointOutput.id &&
                pointOutputStat.nextPointId === pointOutput.key
            );

            stat = outputStat ? outputStat.count : 0;
          }

          return {
            fromPointId: point.id,
            toPointId: pointOutput.id,
            key: pointOutput.key,
            order: pointOutput.order,
            stat
          };
        });

        return acc.concat(outputs);
      }, [])
  ),

  goalEventPoints: computed(
    [(state): TPoints[] => state.items],
    (points): TGoalEventPoint[] => points.filter(isGoalPoint) as TGoalEventPoint[]
  ),

  isSetUpGoal: computed(
    [(state): TGoalEventPoint[] => state.goalEventPoints],
    (goalEventPoints): boolean => {
      const isSetUpGoalEvents = goalEventPoints.every(
        (goalPoint): boolean =>
          Boolean(goalPoint.data.applicationCode) && Boolean(goalPoint.data.name)
      );

      return !isEmptyArray(goalEventPoints) && isSetUpGoalEvents;
    }
  ),

  addPointsToBuffer: action((state, pointIds): void => {
    state.bufferPoints = pointIds.map((id): TPoints => state.entities[id]);
  }),

  clearBufferPoints: action((state): void => {
    state.bufferPoints = [];
  }),

  clonePointsFromBuffer: action((state): void => {
    // создаем новые поинты на основе буффера
    const newPoints = state.bufferPoints.map(makePoint) as TClonedPoints[];
    // сбрасываем активные поинты
    state.activePointIds = [];

    newPoints.forEach((point) => {
      const { originalOutputs } = point;
      // проверяем стрелки у поинтов-родителей и подменяем старые id на новые у скопированных,
      // чтобы сохранить цепочки в новых поинтах
      if (originalOutputs.length !== 0) {
        point.outputs = originalOutputs.reduce((acc, item) => {
          const chainedPoint = newPoints.find((newPoint) => newPoint.originalId === item.id);

          if (chainedPoint) {
            return [...acc, { ...item, id: chainedPoint.id }];
          }

          return acc;
        }, []);
      }

      // удаляем данные поинтов-родителей
      delete point.originalId;
      delete point.originalOutputs;

      // удаляем campaignCode и campaignUID
      if (
        point.type === PointType.SEND_PUSH ||
        point.type === PointType.SEND_SMS ||
        point.type === PointType.SEND_EMAIL
      ) {
        delete point.data?.campaignUID;
        delete point.data?.campaignCode;
      }

      // добавляем новые поинты в стор и делаем их активными
      state.entities[point.id] = point;
      state.activePointIds.push(point.id);
    });

    addPointsToUndoRedoStack(state, 'create', newPoints);
  }),

  undo: action((state): void => {
    if (state.undoRedoStack.length === 0) {
      return;
    }

    const { operationType, points } = state.undoRedoStack.pop();

    switch (operationType) {
      case 'create': {
        points.forEach((point): void => {
          if (state.activePointIds.includes(point.id)) {
            state.activePointIds = [];
          }

          delete state.entities[point.id];
        });
        break;
      }

      case 'delete': {
        const lastPoint = points[points.length - 1];

        points.forEach((point): void => {
          state.entities[point.id] = point;
        });

        state.activePointIds = [lastPoint.id];
        break;
      }

      case 'move': {
        points.forEach((point): void => {
          state.entities[point.id].position = point.position;
        });

        break;
      }

      case 'connection': {
        points.forEach((point): void => {
          state.entities[point.id].outputs = point.outputs;
        });
        break;
      }

      default: {
        break;
      }
    }
  }),

  create: action((state, point): void => {
    const newPoint = makePoint(point) as TPoints;
    const id = point.id || newPoint.id;

    newPoint.title = generateTitlePoint(newPoint, state.items);

    state.entities[id] = { ...newPoint, id };
    if (newPoint.type !== PointType.GOAL_EVENT) {
      state.activePointIds = [id];
    }

    addPointsToUndoRedoStack(state, 'create', [{ ...newPoint, id }]);
  }),

  delete: action((state, pointId): void => {
    Object.values(state.entities).forEach((point): void => {
      const len = point.outputs.length;
      for (let i = 0; i < len; i += 1) {
        const outputIndex = point.outputs.findIndex((output): boolean => output.id === pointId);

        if (outputIndex !== -1) {
          point.outputs.splice(outputIndex, 1);
        }
      }
    });

    state.activePointIds = [];

    addPointsToUndoRedoStack(state, 'delete', [state.entities[pointId]]);

    delete state.entities[pointId];
  }),

  update: action((state, pointInfo): void => {
    const { campaignCode, campaignUID } = state.entities[pointInfo.id].data as any;

    state.entities[pointInfo.id] = {
      ...state.entities[pointInfo.id],
      ...pointInfo,
      data: {
        ...state.entities[pointInfo.id].data,
        ...pointInfo.data,
        campaignCode,
        campaignUID
      }
    } as TPoints;
  }),

  // то же самое что апдейт, но перезаписывает поинт дату
  edit: action((state, pointInfo): void => {
    const { campaignCode, campaignUID } = state.entities[pointInfo.id].data as any;

    state.entities[pointInfo.id] = {
      ...state.entities[pointInfo.id],
      ...pointInfo,
      data: {
        ...pointInfo.data,
        campaignCode,
        campaignUID
      }
    } as TPoints;
  }),

  updateGoals: action((state, goals): void => {
    const keys = Object.keys(state.entities);
    keys.forEach((key) => {
      if (state.entities[key].type === PointType.GOAL_EVENT) {
        delete state.entities[key];
      }
    });
    goals.forEach((goal) => {
      if (goal.data.applicationCode && goal.data.name !== '') {
        state.entities[goal.id] = {
          ...goal
        };
      }
    });
  }),

  setActive: action((state, pointId): void => {
    state.activePointIds = pointId ? [pointId] : [];
  }),

  setManyActive: action((state, pointIds) => {
    state.activePointIds = pointIds;
  }),

  setMultipleActive: action((state, pointId): void => {
    const { activePointIds } = state;
    if (activePointIds.includes(pointId)) {
      const index = activePointIds.indexOf(pointId);
      activePointIds.splice(index, 1);
    } else {
      activePointIds.push(pointId);
    }
  }),

  selectAllPoints: action((state): void => {
    state.items.forEach((point) => {
      if (point.type !== PointType.GOAL_EVENT) {
        state.activePointIds.push(point.id);
      }
    });
  }),

  checkOverlapPoints: action((state, comments): void => {
    const { activePointIds, entities, filteredItems } = state;
    if (activePointIds.length > 0) {
      const activePoints = activePointIds.map((point) => entities[point]);
      if (activePoints.length > 0) {
        for (;;) {
          // is active point overlap other points
          const overlappedActivePoints = filteredItems.reduce((points, point): TPoints[] => {
            const overlappedPoints = activePoints.filter((activePoint) => {
              const isOverlappedWithPoint =
                point?.id !== activePoint?.id && isOverlapPoints(point, activePoint);
              const isOverlappedWithComments = comments.some((comment) =>
                isOverlapPointsWithComments(activePoint, comment)
              );

              return isOverlappedWithComments || isOverlappedWithPoint;
            });
            return [...points, ...overlappedPoints];
          }, []);

          // if active point overlap others -> move active point lower
          // and check overlapping again
          if (overlappedActivePoints.length > 0) {
            overlappedActivePoints.forEach((point) => {
              point.position.y += CANVAS_GRID_CELL_HEIGHT / 6;
            });
          } else {
            break;
          }
        }
      }
    }
  }),

  addOutputToPoint: action((state, payload): void => {
    const point = state.entities[payload.fromPointId];

    addPointsToUndoRedoStack(state, 'connection', [
      {
        ...point,
        outputs: [...point.outputs]
      }
    ]);

    point.outputs.push({
      id: payload.toPointId,
      key: payload.key,
      order: payload.order
    });
  }),

  removeOutputFromPoint: action((state, payload) => {
    const point = state.entities[payload.fromPointId];

    const outputIndex = point.outputs.findIndex(
      (pointOutput): boolean =>
        pointOutput.key === payload.key && pointOutput.id === payload.toPointId
    );

    addPointsToUndoRedoStack(state, 'connection', [
      {
        ...point,
        outputs: [...point.outputs]
      }
    ]);

    if (outputIndex !== -1) {
      point.outputs.splice(outputIndex, 1);
    }
  }),

  updatePositionAtPoint: action((state, payload) => {
    const { pointId, position } = payload;

    const point = state.entities[pointId];

    addPointsToUndoRedoStack(state, 'move', [
      {
        ...point,
        position: {
          ...point.position
        }
      }
    ]);

    point.position.x = position.x;
    point.position.y = position.y;
  }),

  reset: action((state): void => {
    state.activePointIds = [];
    state.entities = {};
  }),

  onLoad: thunkOn(
    (_, storeActions): string[] => [
      storeActions.journeys.loadById.successType,
      storeActions.journeys.updateById.successType,
      storeActions.journeys.activateById.successType
    ],
    (_, target, helpers): void => {
      const { getState, getStoreState, getStoreActions } = helpers;
      const state = getState();
      const stateStore = getStoreState();
      const storeActions = getStoreActions();

      const pointDataStorage = pointStorage.getPoints(stateStore.journeys.currentJourneyId);

      const dateFromServer = new Date(target.result.updatedAt);
      const dateFromLocal = new Date(
        pointDataStorage && pointDataStorage.updatedAt
          ? pointDataStorage.updatedAt
          : target.result.updatedAt
      );

      const isNewerDataOnServer =
        isAfter(dateFromServer, dateFromLocal) || isEqual(dateFromServer, dateFromLocal);

      // временный фикс для обновления структуры поинтов с новым флагом
      const isUpdatedWithCampaignUID = localStorage.getItem('UPDATED_WITH_CAMPAIGN_UID');

      if (isNewerDataOnServer || isUpdatedWithCampaignUID !== 'yes') {
        state.entities = convertArrayToDictionary<TPoints>(target.result.points);
        pointStorage.removePoints(stateStore.journeys.currentJourneyId);
        localStorage.setItem('UPDATED_WITH_CAMPAIGN_UID', 'yes');
      } else {
        state.entities = convertArrayToDictionary<TPoints>(pointDataStorage.points);
        storeActions.journeys.setUnsavedChanges(true);
      }
    }
  ),

  onChangePointsData: thunkOn(
    (
      actions
    ): [
      typeof actions.update,
      typeof actions.create,
      typeof actions.delete,
      typeof actions.addOutputToPoint,
      typeof actions.removeOutputFromPoint,
      typeof actions.updatePositionAtPoint,
      typeof actions.updateGoals,
      typeof actions.addPointsToBuffer,
      typeof actions.clearBufferPoints,
      typeof actions.clonePointsFromBuffer,
      typeof actions.undo
    ] => [
      actions.update,
      actions.create,
      actions.delete,
      actions.addOutputToPoint,
      actions.removeOutputFromPoint,
      actions.updatePositionAtPoint,
      actions.updateGoals,
      actions.addPointsToBuffer,
      actions.clearBufferPoints,
      actions.clonePointsFromBuffer,
      actions.undo
    ],
    (_, __, helpers): void => {
      const storeActions = helpers.getStoreActions();
      const state = helpers.getStoreState();
      storeActions.journeys.setUnsavedChanges(true);
      pointStorage.setPoints(
        state.journeys.currentJourneyId,
        state.points.items,
        state.comments.comments
      );
    }
  )
};
