import * as React from 'react';
import konva from 'konva';
import { Path } from 'react-konva';

// Constants
import { CHANNEL_GROUP_COLOR, PointType, COLOR_TRUE, COLOR_FALSE } from '@src/common/constants';

// Helpers
import { getTitlePoint } from '@src/canvas/helpers';
import { getOutputs } from '../helpers/getOutputs';

// Components
import { Point, PointContent, PointText, PointIcon, PointOutput } from '../Point';

// Types
import { IProps } from './PointSendEmail.types';

const KEY_TRUE = 'output1';
const KEY_FALSE = 'default';

export function PointSendEmail(props: IProps): JSX.Element {
  const [isShowBadge, setShowBadge] = React.useState([true, true]);
  const { data, propsCommonPoint, outputs } = props;

  const getIsOutput = React.useCallback(
    (key: string): boolean => getOutputs(key, outputs),
    [outputs]
  );

  const titlePoint = getTitlePoint(PointType.SEND_EMAIL, propsCommonPoint.title, data);

  const isLinkedOutputFirst = getIsOutput(KEY_TRUE);
  const isLinkedOutputSecond = getIsOutput(KEY_FALSE);

  return (
    <Point type={PointType.SEND_EMAIL} color={CHANNEL_GROUP_COLOR} {...propsCommonPoint}>
      <PointContent type={PointType.SEND_EMAIL}>
        <PointIcon type={PointType.SEND_EMAIL} fill={CHANNEL_GROUP_COLOR}>
          <Path
            fill="white"
            perfectDrawEnabled={false}
            shadowForStrokeEnabled={false}
            data="M10 11C8.89543 11 8 11.8954 8 13V27C8 28.1046 8.89543 29 10 29H30C31.1046 29 32 28.1046 32 27V13C32 11.8954 31.1046 11 30 11H10ZM10.7393 14.9266C10.4513 15.3979 10.5999 16.0134 11.0711 16.3014L19.2178 21.2799L19.4785 21.4393C19.7987 21.6349 20.2013 21.6349 20.5215 21.4393L20.7822 21.2799L28.9289 16.3014C29.4001 16.0134 29.5487 15.3979 29.2607 14.9266L28.7393 14.0734C28.4513 13.6021 27.8358 13.4535 27.3645 13.7415L20.5215 17.9234C20.2013 18.119 19.7987 18.119 19.4786 17.9234L12.6355 13.7415C12.1642 13.4535 11.5487 13.6021 11.2607 14.0734L10.7393 14.9266Z"
          />
          <Path
            offsetX={-10}
            offsetY={-13}
            fill={CHANNEL_GROUP_COLOR}
            perfectDrawEnabled={false}
            shadowForStrokeEnabled={false}
            data="M9.21782 8.27998L1.0711 3.30143C0.599846 3.01345 0.45128 2.39796 0.739269 1.9267L1.26072 1.07342C1.54871 0.602166 2.16419 0.453599 2.63545 0.741588L9.47854 4.92348C9.79866 5.1191 10.2013 5.1191 10.5214 4.92348L17.3645 0.741589C17.8358 0.4536 18.4513 0.602166 18.7393 1.07342L19.2607 1.9267C19.5487 2.39796 19.4001 3.01345 18.9289 3.30143L10.7822 8.27998L10.5214 8.43932C10.2013 8.63494 9.79866 8.63494 9.47854 8.43932L9.21782 8.27998Z"
          />
        </PointIcon>
        <PointText type={PointType.SEND_EMAIL}>{titlePoint}</PointText>
        {data.emailBehaviorSplitter?.enabled ? (
          <>
            <PointOutput
              y={9}
              pointId={propsCommonPoint.id}
              outputKey={KEY_TRUE}
              type={PointType.SEND_EMAIL}
              colorBlock={COLOR_TRUE}
              colorArrow={COLOR_TRUE}
              isInvalid={propsCommonPoint.isInvalid}
              isActive={propsCommonPoint.isActive}
              isLinkedOutput={isLinkedOutputFirst}
              onMouseDown={(event: konva.KonvaEventObject<MouseEvent>): void => {
                setShowBadge([isShowBadge[0], false]);
                propsCommonPoint.onMouseDownOutput(event);
              }}
              textBadge="Opened"
              fillBadge={COLOR_TRUE}
              isShowBadge={isLinkedOutputFirst ? false : isShowBadge[0]}
              arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
            />
            <PointOutput
              y={31}
              pointId={propsCommonPoint.id}
              outputKey={KEY_FALSE}
              type={PointType.SEND_EMAIL}
              colorBlock={COLOR_FALSE}
              colorArrow={COLOR_FALSE}
              isInvalid={propsCommonPoint.isInvalid}
              isActive={propsCommonPoint.isActive}
              isLinkedOutput={isLinkedOutputSecond}
              onMouseDown={(event: konva.KonvaEventObject<MouseEvent>): void => {
                setShowBadge([isShowBadge[0], false]);
                propsCommonPoint.onMouseDownOutput(event);
              }}
              textBadge="Not opened"
              fillBadge={COLOR_FALSE}
              isShowBadge={isLinkedOutputSecond ? false : isShowBadge[0]}
              arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
            />
          </>
        ) : (
          <PointOutput
            pointId={propsCommonPoint.id}
            type={PointType.SEND_EMAIL}
            colorBlock={CHANNEL_GROUP_COLOR}
            isInvalid={propsCommonPoint.isInvalid}
            isActive={propsCommonPoint.isActive}
            isLinkedOutput={propsCommonPoint.isLinkedOutput}
            onMouseDown={propsCommonPoint.onMouseDownOutput}
            arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
          />
        )}
      </PointContent>
    </Point>
  );
}
