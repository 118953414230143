import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgLogoutSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.25 5c0-.966.784-1.75 1.75-1.75h3.25a.75.75 0 010 1.5H4a.25.25 0 00-.25.25v6c0 .138.112.25.25.25h3.25a.75.75 0 010 1.5H4A1.75 1.75 0 012.25 11V5z"
        fill={props.fill}
      />
      <path
        d="M10.52 4.424a.75.75 0 011.056.096l2.634 3.16a.5.5 0 010 .64l-2.634 3.16a.75.75 0 01-1.152-.96l1.475-1.77H6.75a.75.75 0 010-1.5h5.149l-1.475-1.77a.75.75 0 01.096-1.056z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgLogoutSmallLined;
