import React from 'react';
import { Color } from '@pushwoosh/kit-constants';
import { SidebarType } from '@src/common/constants/sidebars';
import { useStoreActions, useStoreState } from '@src/store/store';
import { useVisibleSidebar, useValidateById } from '@src/store/hooks';
import { WarningRoundIcon } from '@pushwoosh/kit-icons';
import { Button } from '../Button';

export function ValidationButton(): JSX.Element {
  const isVisibleSidebar = useVisibleSidebar();
  const openSidebar = useStoreActions((state) => state.sidebars.openNewSidebar);
  const closeAllSidebars = useStoreActions((state) => state.sidebars.closeAllSidebars);
  const sidebars = useStoreState((state) => state.sidebars);
  const isSelected = sidebars.sidebar?.type === SidebarType.VALIDATION;
  const { isLoading } = useValidateById();

  const openValidationSidebar = React.useCallback(() => {
    if (sidebars.isOpened && sidebars.sidebar.type === SidebarType.VALIDATION) {
      closeAllSidebars();
    } else {
      closeAllSidebars();

      openSidebar({
        type: SidebarType.VALIDATION
      });
    }
  }, [openSidebar, sidebars, closeAllSidebars]);

  return (
    <Button
      isCompact={isVisibleSidebar}
      icon={<WarningRoundIcon size="medium" view="filled" />}
      onClick={openValidationSidebar}
      isSelected={isSelected}
      iconColor={Color.WARNING}
      isDisabled={isLoading}
    >
      Validation
    </Button>
  );
}
