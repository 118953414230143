import React from 'react';
import { StyledNavLink } from './styled';
import { BaseLink } from './BaseLink';
import { ILinkProps } from './Link.types';

export function NavLink(props: ILinkProps): JSX.Element {
  return (
    <StyledNavLink {...props}>
      <BaseLink {...props} />
    </StyledNavLink>
  );
}
