import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgInfoRoundMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12c0-4.974 4.025-9 9-9 4.974 0 9 4.025 9 9 0 4.974-4.025 9-9 9-4.974 0-9-4.025-9-9zm10.25-4a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zm-.75 3a.5.5 0 01.5.5v6a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5h1z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgInfoRoundMediumFilled;
