import styled, { css } from 'styled-components';
import { Color, FontWeight, FontSize, LineHeight, Spacing, Shadow } from '@pushwoosh/kit-constants';

type ButtonProps = {
  isCompact?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  color?: Color;
  isLoading?: boolean;
};

export const ButtonField = styled.p<ButtonProps>`
  font-weight: ${FontWeight.MEDIUM};
  font-size: ${FontSize.REGULAR};
  line-height: ${LineHeight.REGULAR};
  margin-left: ${Spacing.S1};
  color: ${Color.MAIN};
`;

const hoverCSS = css`
  &:hover {
    background: ${Color.BRIGHT_LIGHT};
    color: ${Color.PRIMARY_HOVER};

    ${ButtonField} {
      color: ${Color.PRIMARY_HOVER};
    }
  }

  &:active {
    background: ${Color.BRIGHT_LIGHT};
  }
`;

const loadingCss = css`
  opacity: 0.5;
  pointer-events: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06), 0px 0px 4px rgba(0, 0, 0, 0.06);
`;

export const StyledButton = styled.button<ButtonProps>`
  height: 52px;
  width: ${({ isCompact }) => (isCompact ? '52px' : 'auto')};
  justify-content: center;
  border: transparent;
  outline: transparent;
  display: flex;
  align-items: center;
  padding: ${({ isCompact }) => (isCompact ? '14px' : '16px 20px 16px 12px')};
  cursor: pointer;
  background: ${({ isSelected }) => (isSelected ? Color.MAIN : Color.CLEAR)};
  box-shadow: ${Shadow.REGULAR};
  border-radius: 8px;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  color: ${({ isSelected, color }) => (isSelected ? Color.CLEAR : color)};
  ${({ isDisabled }) => isDisabled && 'opacity: 0.5;'}

  ${({ isSelected }) => !isSelected && hoverCSS}

  ${({ isLoading }) => isLoading && loadingCss}
`;
