import React from 'react';
import { subDays } from 'date-fns';
import { Paragraph } from '@pushwoosh/kit-typography';
import { InputDatepicker } from '@src/ui/kit';
import { NumberStepper } from '@pushwoosh/kit-number-stepper';
import { Field } from '@pushwoosh/pwkit-field';
import { TFormEditingPointWaitProps } from '../WaitForm/WaitForm.types';
import { Container, DateOptions, WaitOptions } from './styled';

type Props = {
  values: TFormEditingPointWaitProps['values'];
  names: TFormEditingPointWaitProps['names'];
  setFieldValue: TFormEditingPointWaitProps['setFieldValue'];
};

export function DateSettings(props: Props): JSX.Element {
  const { values, names, setFieldValue } = props;
  const minDate = subDays(new Date(), 1);

  return (
    <Container>
      <Paragraph>
        Set the specific date and time to continue the journey. If the time has already passed, user
        will wait till the next day.
      </Paragraph>
      <DateOptions>
        <Field title="Date">
          <InputDatepicker
            value={Number.isNaN(values.dateValue.getDay()) ? new Date() : values.dateValue}
            onChange={(value) => setFieldValue(names.dateValue, value)}
            minDate={minDate}
          />
        </Field>
        <Field title="Wait till specific time" helpText="On users timezone">
          <WaitOptions>
            <NumberStepper
              dimension="hour"
              name={names.waitDateHour}
              value={values.waitDateHour}
              max={23}
              onChange={({ value }) => setFieldValue(names.waitDateHour, value)}
            />
            <NumberStepper
              dimension="min"
              name={names.waitDateMinutes}
              value={values.waitDateMinutes}
              max={59}
              onChange={({ value }) => setFieldValue(names.waitDateMinutes, value)}
            />
          </WaitOptions>
        </Field>
      </DateOptions>
    </Container>
  );
}
