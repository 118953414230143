import { TDelay } from '../types/types';

export const formatTime = (delay: TDelay, short = false): string => {
  const days = delay.dd ? `${delay.dd} ${short ? 'd' : 'day'} ` : '';
  const hours = delay.hh ? `${delay.hh} ${short ? 'h' : 'hour'} ` : '';
  const minutes = delay.mm ? `${delay.mm} ${short ? 'm' : 'minute'}` : '';

  return `${days}${hours}${minutes}`;
};

export const formatTimeMaxValue = (delay: TDelay): { value: string; isMultiple: boolean } => {
  const keys = Object.values(delay);

  const countNotZero = keys.reduce(
    (accumulator, currentValue) => (currentValue > 0 ? accumulator + 1 : accumulator),
    0
  );

  if (delay.dd > 0) {
    return {
      value: `${delay.dd} ${delay.dd > 1 ? 'days' : 'day'}`,
      isMultiple: countNotZero > 1
    };
  }

  if (delay.hh > 0) {
    return {
      value: `${delay.hh} hour`,
      isMultiple: countNotZero > 1
    };
  }

  return {
    value: `${delay.mm} minute`,
    isMultiple: countNotZero > 1
  };
};

export const addZero = (n: number): string => (n < 10 ? `0${n}` : n.toString());
