import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPulseSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.911 2.254a.75.75 0 01.653.576l1.84 7.887 1.416-3.034a.75.75 0 01.68-.433H15a.75.75 0 010 1.5h-3.022l-2.132 4.567a.75.75 0 01-1.41-.147l-1.84-7.887L5.18 8.317a.75.75 0 01-.68.433H1a.75.75 0 010-1.5h3.022l2.132-4.567a.75.75 0 01.757-.429z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPulseSmallLined;
