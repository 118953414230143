import * as React from 'react';

// Analytics
import { sendMixpanelEvent } from '@src/common/analytics';
import { SidebarType } from '@src/common/constants/sidebars';

// Store Hooks
import {
  useApplications,
  useCurrentJourneyId,
  useFilter,
  useGetJourneyById,
  useGetPointById,
  useJourneyAppCode,
  useLoadFilter,
  useOpenNewModal,
  useUpdatePoint,
  useEditJourneyById
} from '@src/store/hooks';
import { useStoreActions, useStoreState } from '@src/store/store';

// UI Hooks
import { useForm } from '@src/ui/hooks';

// UI Components
import { Button, IconEnum } from '@src/ui/kit';
import { Modal } from '@src/ui/kit/Modal';
import { DefaultButtons } from '@src/ui/kit/ModalButtons';
import {
  EditPointSegment,
  EditPointSegmentValues
} from '@src/ui/features/StartSegment/components/EditingPointSegment';

// Helpers
import { makeFilterOptions } from '@src/ui/helpers';

// Types
import { TSegmentPoint } from '@src/common/types/points';
import { IUniqueId } from '@src/common/types/entities';

// Constants
import { RepeatType, RepeatFrequency } from '@src/common/constants/repeatSegment';
import { isEqual } from 'lodash';
import { JourneyStatus, ModalType } from '@src/common/constants';

interface IProps {
  pointId: IUniqueId['id'];
  closeModal: () => void;
}

function StartSegmentComponent(props: IProps): JSX.Element {
  const { pointId, closeModal } = props;

  const getPointById = useGetPointById();
  const updatePoint = useUpdatePoint();
  const journeyId = useCurrentJourneyId();
  const applicationCode = useJourneyAppCode(journeyId);
  const showDocumentationMenu = useStoreActions((actions) => actions.documentationMenu.show);
  const getJourneyByUd = useGetJourneyById();
  const openNewModal = useOpenNewModal();
  const editPoint = useStoreActions((actions) => actions.points.update);
  const handleEditJourneyById = useEditJourneyById();
  const sidebars = useStoreState((state) => state.sidebars);
  const openNewSidebar = useStoreActions((actions) => actions.sidebars.openNewSidebar);
  const closeAllSidebars = useStoreActions((actions) => actions.sidebars.closeAllSidebars);

  const { status } = getJourneyByUd(journeyId);

  const applications = useApplications();

  const [onLoadFilter, isLoadingFilter] = useLoadFilter();
  const filters = useFilter();

  const point = getPointById(pointId) as TSegmentPoint;

  const initialValues = React.useMemo(
    () => ({
      title: point.title,
      filterCode: point?.data?.filterCode ?? '',
      entryLimitsEnabled: point?.data?.entryLimitsEnabled ?? false,
      entryLimitsPerSecond: point?.data?.entryLimitsPerSecond ?? 5000,
      repeatEnabled: point?.data?.repeat?.enabled ?? false,
      repeatEvery: point?.data?.repeat?.every ?? 1,
      repeatFrequency: point?.data?.repeat?.frequency ?? RepeatFrequency.DAILY,
      repeatDates: point?.data?.repeat?.dates ?? [],
      repeatType: point?.data?.repeat?.type ?? RepeatType.EVERY_DAY,
      hour: point?.data?.repeat?.time?.hour ?? 10,
      minute: point?.data?.repeat?.time?.minute ?? 0,
      timezone:
        point?.data?.repeat?.time?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
      isDeactivated: point?.data?.IsDeactivated ?? false
    }),
    [point]
  );

  const {
    values,
    setFieldValue,
    setValues,
    setFieldTouched,
    handleSubmit,
    handleChange,
    handleBlur
  } = useForm<EditPointSegmentValues>({
    initialValues,
    onSubmit(formValues): void {
      const {
        filterCode,
        entryLimitsEnabled,
        entryLimitsPerSecond,
        repeatEnabled,
        repeatEvery,
        repeatFrequency,
        repeatDates,
        repeatType,
        title,
        hour,
        minute,
        timezone
      } = formValues;
      let applicationTitle = '';
      let filterTitle = '';
      if (applicationCode) {
        const currentApplication = applications.find((item) => item.code === applicationCode);
        if (currentApplication) {
          applicationTitle = currentApplication.title;
        }
      }

      if (filterCode) {
        const currentFilter = filters.find((item) => item.code === filterCode);
        if (currentFilter) {
          filterTitle = currentFilter.name;
        }
      }

      const repeatOptions = {
        enabled: repeatEnabled,
        frequency: repeatFrequency,
        every: repeatEvery,
        dates: repeatDates,
        type: repeatType,
        time: {
          hour,
          minute,
          timezone
        },
        isDeactivated: formValues.isDeactivated
      };

      const newPoint = {
        id: point.id,
        title,
        data: {
          applicationCode,
          applicationTitle,
          filterCode,
          filterTitle,
          entryLimitsEnabled,
          entryLimitsPerSecond,
          repeat: {
            enabled: repeatEnabled,
            frequency: repeatFrequency,
            every: repeatEvery,
            dates: repeatDates,
            type: repeatType,
            time: {
              hour,
              minute,
              timezone
            }
          },
          IsDeactivated: formValues.isDeactivated
        }
      };

      if (status !== JourneyStatus.RUNNING) {
        updatePoint(newPoint);

        if (filterTitle) {
          sendMixpanelEvent({
            eventName: 'StartSegmentConfigured',
            eventData: {
              ScheduledLaunch: repeatOptions,
              SendRateTurned: entryLimitsEnabled
            }
          });
        }

        closeModal();
      } else {
        editPoint(newPoint);

        handleEditJourneyById(journeyId);

        sendMixpanelEvent({
          eventName: 'JourneyEditModeChangesApplied',
          eventData: {
            PointType: 'StartSegment'
          }
        });

        closeModal();

        openNewModal({
          type: ModalType.DETAIL_POINT,
          data: {
            pointId
          }
        });
      }
    }
  });

  const filtersOptions = makeFilterOptions(filters);

  const handleCancel = React.useCallback(() => {
    if (status === JourneyStatus.RUNNING) {
      sendMixpanelEvent({
        eventName: 'JourneyEditModeChangesCancelled',
        eventData: {
          PointType: 'StartSegment'
        }
      });
    } else {
      sendMixpanelEvent({
        eventName: 'ConfigurePointCancelled',
        eventData: {
          PointType: 'StartSegment',
          PointChanged: !isEqual(values, initialValues)
        }
      });
    }

    closeModal();
  }, [closeModal, values, initialValues, status]);

  const onShowDocumentation = React.useCallback((): void => {
    if (sidebars.isOpened) {
      closeAllSidebars();
    } else {
      openNewSidebar({
        type: SidebarType.DOCUMENTATION
      });
      showDocumentationMenu();
      sendMixpanelEvent({ eventName: 'ShowDocumentation' });
    }
  }, [showDocumentationMenu, sidebars, closeAllSidebars, openNewSidebar]);

  return (
    <Modal
      title="Audience-based Entry"
      footerRight={
        <DefaultButtons
          onClickActionButton={handleSubmit}
          onClickCancelButton={handleCancel}
          actionButtonName={status === JourneyStatus.RUNNING ? 'Save' : 'Apply'}
        />
      }
      footerLeft={
        <Button
          color="primary"
          size="field"
          view="ghost"
          iconPosition="left"
          iconType={IconEnum.DOCS_MEDIUM_LINED}
          onClick={onShowDocumentation}
        >
          How to Use
        </Button>
      }
      isOpen
    >
      <EditPointSegment
        values={values}
        names={{
          title: 'title',
          filterCode: 'filterCode',
          entryLimitsEnabled: 'entryLimitsEnabled',
          entryLimitsPerSecond: 'entryLimitsPerSecond',
          repeatEnabled: 'repeatEnabled',
          repeatFrequency: 'repeatFrequency',
          repeatEvery: 'repeatEvery',
          repeatDates: 'repeatDates',
          repeatType: 'repeatType',
          hour: 'hour',
          minute: 'minute',
          timezone: 'timezone',
          isDeactivated: 'isDeactivated'
        }}
        applicationCode={applicationCode}
        filters={filtersOptions}
        isLoadingFilter={isLoadingFilter}
        onLoadFilter={onLoadFilter}
        setFieldValue={setFieldValue}
        setValues={setValues}
        setFieldTouched={setFieldTouched}
        onBlurField={handleBlur}
        onChangeField={handleChange}
        journeyStatus={status}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
}

export const StartSegment = React.memo(StartSegmentComponent);
