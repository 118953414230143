import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgZoomZoomInMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.25 10.5A.75.75 0 018 9.75h1.75V8a.75.75 0 011.5 0v1.75H13a.75.75 0 010 1.5h-1.75V13a.75.75 0 01-1.5 0v-1.75H8a.75.75 0 01-.75-.75z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.535 15.596a6.5 6.5 0 111.06-1.06l4.082 4.08a.5.5 0 010 .707l-.354.354a.5.5 0 01-.707 0l-4.08-4.081zM15.5 10.5a5 5 0 11-10 0 5 5 0 0110 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgZoomZoomInMediumLined;
