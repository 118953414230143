import * as React from 'react';

// Store
import { useStoreActions } from '@src/store/store';

// Hooks
import { useApplications, useSetMode } from '@src/store/hooks';

// Enums
import { Modes } from '@src/common/constants';

// Types
import { TInfoToCreatePoint } from '@src/common/types/points';
import { PointType } from '@src/common/constants/point';

type TCreatePoint = (point: TInfoToCreatePoint) => void;
type TResult = TCreatePoint;

export function useCreatePoint(): TResult {
  const applications = useApplications();
  const setMode = useSetMode();

  const createPoint: TCreatePoint = useStoreActions<TCreatePoint>(
    (actions): TCreatePoint => actions.points.create
  );

  const handlePointCreate: TCreatePoint = React.useCallback<TCreatePoint>(
    (point): void => {
      if (point.type === PointType.SEND_PUSH && applications.length === 1) {
        createPoint({
          ...point,
          data: {
            ...point.data,
            applicationCode: applications[0].code
          }
        });
      } else {
        createPoint(point);
      }
      setMode(Modes.CHANGED);
    },
    [createPoint, setMode, applications]
  );

  return handlePointCreate;
}
