import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgInfinityMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.75 12.46a2.71 2.71 0 014.628-1.914l.427.427 1.06-1.06-.426-.427a4.21 4.21 0 10-.016 5.963l.007.006 4.212-4.928a2.71 2.71 0 110 3.865l-.496-.495-1.06 1.06.497.498.004.004a4.21 4.21 0 10-.026-5.972l-.021.02-4.137 4.84A2.71 2.71 0 014.75 12.46z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgInfinityMediumLined;
