import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgDocsMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.75 9.5a.75.75 0 01.75-.75H9a.75.75 0 010 1.5H6.5a.75.75 0 01-.75-.75zM6.5 11.75a.75.75 0 000 1.5H9a.75.75 0 000-1.5H6.5zM14.25 9.5a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5H15a.75.75 0 01-.75-.75zM15 11.75a.75.75 0 000 1.5h2.5a.75.75 0 000-1.5H15z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.667 4.75a.75.75 0 00-.75.75V18c0 .414.335.75.75.75H9.5a1.75 1.75 0 011.75 1.75.75.75 0 001.5 0 1.75 1.75 0 011.75-1.75h5.833a.75.75 0 00.75-.75V5.5a.75.75 0 00-.75-.75h-5A4.083 4.083 0 0012 6.475 4.09 4.09 0 008.667 4.75h-5zm7.583 4.083v8.928a3.25 3.25 0 00-1.75-.511H4.417v-11h4.25a2.583 2.583 0 012.583 2.583zm3.25 8.417a3.25 3.25 0 00-1.75.511V8.833a2.584 2.584 0 012.583-2.583h4.25v11H14.5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgDocsMediumLined;
