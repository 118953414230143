// Store
import { useStoreState } from '@src/store/store';

// Types
import { IStatuses } from '@src/common/types/mode';

type TResult = IStatuses;

export function useModeMap(): TResult {
  const modeMap: IStatuses = useStoreState<IStatuses>((state): IStatuses => state.mode.statuses);

  return modeMap;
}
