import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgMenuMedium(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5.5a.5.5 0 01.5-.5h15a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-1zm0 6a.5.5 0 01.5-.5h15a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-1zm.5 5.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h15a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-15z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgMenuMedium;
