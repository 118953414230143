import styled, { css } from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';

const listHorizontalCss = css`
  display: flex;
  align-items: center;
`;

const listVerticalCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type SplittedListWrapProps = {
  direction: 'horizontal' | 'vertical';
};

export const SplittedListWrap = styled.div`
  width: 100%;
  ${({ direction }: SplittedListWrapProps) =>
    direction === 'horizontal' ? listHorizontalCss : listVerticalCss}
`;

export const IconWrap = styled.div`
  margin: 0 ${Spacing.S3};
`;
