import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPulseMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.73 5.803a.6.6 0 01.52.458l2.273 9.545 1.935-4.064A.6.6 0 0116 11.4h4a.6.6 0 110 1.2h-3.621l-2.504 5.258a.6.6 0 01-1.125-.119l-2.273-9.545-1.935 4.064A.6.6 0 018 12.6H4a.6.6 0 010-1.2h3.621l2.504-5.258a.6.6 0 01.605-.339z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPulseMediumLined;
