import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgCheckRoundSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 8a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0zM15 8A7 7 0 111 8a7 7 0 0114 0zm-3.358-1.22a.75.75 0 10-1.061-1.06L6.833 9.467 5.42 8.053a.75.75 0 00-1.06 1.06l1.944 1.945a.75.75 0 001.06 0l4.279-4.278z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgCheckRoundSmallLined;
