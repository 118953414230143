import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgTimeDelayPoint(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 32c6.627 0 12-5.373 12-12S26.627 8 20 8 8 13.373 8 20s5.373 12 12 12zm1.5-18v-.5a1 1 0 00-1-1h-1a1 1 0 00-1 1V20a1.5 1.5 0 00.728 1.286l5 3 .429.258a1 1 0 001.372-.343l.515-.858a1 1 0 00-.343-1.372l-.43-.257-3.786-2.272a1 1 0 01-.485-.858V14z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgTimeDelayPoint;
