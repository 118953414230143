import React from 'react';

export function useCommentEvents() {
  const [isDeleteHovered, setDeleteHovered] = React.useState(false);
  const [isPressed, setPressed] = React.useState(false);

  const onMouseEnter = React.useCallback(() => {
    setDeleteHovered(true);
  }, []);

  const onMouseLeave = React.useCallback(() => {
    setDeleteHovered(false);
  }, []);

  const onMouseDown = React.useCallback(() => {
    setPressed(true);
  }, []);

  const onMouseUp = React.useCallback(() => {
    setPressed(false);
  }, []);

  return {
    isDeleteHovered,
    isPressed,
    onMouseEnter,
    onMouseLeave,
    onMouseDown,
    onMouseUp
  };
}
