import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgArrowLeftMedium(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.53 15.47a.75.75 0 11-1.06 1.06l-4-4a.75.75 0 010-1.06l4-4a.75.75 0 111.06 1.06l-2.72 2.72H18a.75.75 0 010 1.5H8.81l2.72 2.72z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgArrowLeftMedium;
