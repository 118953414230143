import * as React from 'react';
import { useParams } from 'react-router-dom';

// Components
import { FormSelect } from '@src/ui/form';
import { Checkbox, IconEnum, Plate } from '@src/ui/kit';
import {
  FilterConditionList,
  IFilterConditionListProps
} from '@src/ui/features/EditEvents/components/FilterConditionList';
import { useDidUpdate } from '@src/ui/hooks';

// Types
import { IFormEditOneEventProps } from './FormEditOneEvent.types';

// Styled
import { CheckboxContainer, CheckboxLabel, ContainerSelects, RemoveButton } from './styled';

export function FormEditOneEvent(props: IFormEditOneEventProps): JSX.Element {
  const {
    applicationCode,
    attributeSelectOptions,
    eventSelectOptions,
    names,
    values,
    errors,
    touched,
    isLoadingEventOptions = false,
    setFieldValue,
    setFieldTouched,
    isShowThrowOut,
    onChangeField,
    onBlurField,
    onAddCondition,
    onRemoveCondition,
    view = 'dark',
    onRemove
  } = props;

  const params = useParams<{ applicationCode: string }>();

  useDidUpdate((): void => {
    setFieldValue(names.eventCode, '');
    setFieldValue(names.eventConditions, []);
  }, [applicationCode]);

  useDidUpdate((): void => {
    setFieldValue(names.eventConditions, []);
  }, [values.eventCode]);

  const eventLink = params.applicationCode
    ? `https://app.pushwoosh.com/applications/${applicationCode}/events`
    : `https://go.pushwoosh.com/cp/applications/${applicationCode}/events`;

  return (
    <Plate view={view}>
      <ContainerSelects>
        <FormSelect<string>
          label="Event"
          url={eventLink}
          name={names.eventCode}
          value={values.eventCode}
          error={touched.eventCode ? errors.eventCode : ''}
          options={eventSelectOptions}
          placeholder="Type to search…"
          isLoading={isLoadingEventOptions}
          isDisabled={!applicationCode}
          isSearchable
          onChange={setFieldValue}
          onBlur={setFieldTouched}
        />
        {onRemove && (
          <RemoveButton
            onClick={onRemove}
            view="ghost"
            size="compact"
            iconType={IconEnum.TRASH_SMALL_LINED}
            color="secondary"
            type="button"
          >
            REMOVE EVENT
          </RemoveButton>
        )}
      </ContainerSelects>
      {attributeSelectOptions.length > 1 && (
        <FilterConditionList
          attributeSelectOptions={attributeSelectOptions || []}
          formListName={names.eventConditions}
          applicationCode={applicationCode}
          eventCode={values.eventCode}
          names={{
            attributeName: 'attributeName',
            type: 'type',
            operator: 'operator',
            value: 'value'
          }}
          values={values.eventConditions}
          errors={errors.eventConditions as IFilterConditionListProps['errors']}
          touched={touched.eventConditions}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          canMoreAdd={values.eventConditions.length !== attributeSelectOptions.length}
          onChangeField={onChangeField}
          onBlurField={onBlurField}
          onAdd={onAddCondition}
          onRemove={onRemoveCondition}
        />
      )}

      {isShowThrowOut && (
        <CheckboxContainer>
          <Checkbox
            name={names.isThrowOut}
            value={values.isThrowOut}
            onChange={onChangeField}
            onBlur={onBlurField}
          />
          <CheckboxLabel>End journey after this event</CheckboxLabel>
        </CheckboxContainer>
      )}
    </Plate>
  );
}
