import * as React from 'react';

// Store
import { useStoreActions } from '@src/store/store';

// Types
import { IUniqueId } from '@src/common/types/entities';
import { IJourney } from '@src/common/types/journey';

type TActionAutoSaveById = (args: { id: IUniqueId['id'] }) => Promise<IJourney>;
type TAutoSaveById = (id: IUniqueId['id']) => Promise<void>;

export function useAutoSaveById(): TAutoSaveById {
  const autoSaveById = useStoreActions(
    (actions): TActionAutoSaveById => actions.journeys.autoSaveById
  );

  const handleJourneyAutoSaveById: TAutoSaveById = React.useCallback<TAutoSaveById>(
    async (id): Promise<void> => {
      await autoSaveById({
        id
      });
    },
    [autoSaveById]
  );

  return handleJourneyAutoSaveById;
}
