import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgUserSmallFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.7 5.3a3.3 3.3 0 116.6 0 3.3 3.3 0 11-6.6 0zM2.5 12.214C2.5 10.542 5.797 9.7 8 9.7s5.5.842 5.5 2.514v1.336a.55.55 0 01-.55.55h-9.9a.55.55 0 01-.55-.55v-1.336z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgUserSmallFilled;
