export interface WeekDay {
  value: Date;
  key: string;
}

export interface DatepickerProps {
  value?: Date;
  onChange: (value: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  withBorder?: boolean;
  isDisabled?: boolean;
}

export type NavigationType = {
  toNext: () => void;
  toPrev: () => void;
  setToday: () => void;
  setDate: (date: Date) => void;
};

export type DayType = {
  key: string;
  value: Date;
  isCurrentMonth: boolean;
  isCurrentDate: boolean;
  date: number;
};

export type WeekType = {
  key: string;
  value: DayType[];
};

export enum ViewEnum {
  MONTH = 'monthView',
  YEAR = 'yearView',
  DECADE = 'decadeView'
}
