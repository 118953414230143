// Store
import { useStoreState } from '@src/store/store';

// Types
import { IUniqueId } from '@src/common/types/entities';

type TResult = IUniqueId['id'][];

export function useActivePointIds(): TResult {
  const activePointIds: IUniqueId['id'][] = useStoreState<IUniqueId['id'][]>(
    (state): typeof state.points.activePointIds => state.points.activePointIds
  );

  return activePointIds;
}
