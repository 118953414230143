import styled from 'styled-components';
import { H5, H4 } from '@pushwoosh/pwkit-typography';

// Components
import { Icon as IconComponent } from '@src/ui/kit/Icon';

// Constants
import { Spacing, FontSize, FontWeight, Color } from '@pushwoosh/kit-constants';
import { Paragraph } from '@pushwoosh/kit-typography';

export const ContainerDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${Spacing.S3};
`;

export const RowDetails = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const TitleDetails = styled(H4)`
  margin-top: ${Spacing.S5};
  font-weight: ${FontWeight.MEDIUM};
  font-size: ${FontSize.REGULAR};

  :nth-child(1) {
    margin-top: 0;
  }
`;

export const ValueDetails = styled.div`
  font-size: ${FontSize.REGULAR};
  font-weight: ${FontWeight.REGULAR};
`;

export const ValueInlineDetails = styled(ValueDetails)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: ${Spacing.S3};
`;

export const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &:not(:last-child) {
    padding-bottom: ${Spacing.S6};
  }
`;

export const IconDetails = styled(IconComponent)`
  margin-left: ${Spacing.S1};
`;

export const BlockDetails = styled.div`
  border-radius: ${Spacing.S1};
  background-color: ${Color.SOFT_LIGHT};
  padding: ${Spacing.S5};
  margin-top: ${Spacing.S5};
`;

export const ItemDetails = styled.div`
  margin-top: ${Spacing.S3};
`;

export const BlockDetail = styled.div`
  margin-top: ${Spacing.S5};
`;

export const PointTitle = styled.span`
  color: ${Color.PHANTOM};
`;

export const TagTitle = styled(H5)`
  margin-bottom: ${Spacing.S1};
`;

export const TagItem = styled.div`
  margin-right: ${Spacing.S3};
  color: ${Color.LOCKED};
`;

export const ConditionOperator = styled(Paragraph)`
  color: ${Color.LOCKED};
  margin-left: ${Spacing.S3} !important;
  margin-right: ${Spacing.S3} !important;
`;
