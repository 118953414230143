import React from 'react';
import { RouterLink, ExternalLink } from './styled';
import { BaseLink } from './BaseLink';
import { ILinkProps } from './Link.types';

export function Link(props: ILinkProps): JSX.Element {
  const Component: React.ComponentType<ILinkProps> = props.isExternal ? ExternalLink : RouterLink;

  return (
    <Component {...props} href={props.to as string}>
      <BaseLink {...props} />
    </Component>
  );
}
