import * as React from 'react';

// Store
import { useStoreActions } from '@src/store/store';

// Types
import { TTooltip } from '@src/common/types/tooltip';
import { IUniqueId } from '@src/common/types/entities';

type TActionShowTooltip = (tooltip: { id?: IUniqueId['id'] } & Omit<TTooltip, 'id'>) => void;

export function useActionShowTooltip(): TActionShowTooltip {
  const actionShowTooltip = useStoreActions(
    (actions): typeof actions.tooltip.show => actions.tooltip.show
  );

  const handleTooltipShow = React.useCallback<TActionShowTooltip>(
    (tooltip): void => {
      actionShowTooltip({
        ...tooltip
      });
    },
    [actionShowTooltip]
  );

  return handleTooltipShow;
}
