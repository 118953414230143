import * as React from 'react';
import { Group, Path, Rect } from 'react-konva';

// Hooks
import { useHover } from '@src/canvas/hooks';

// Types
import { Color } from '@pushwoosh/kit-constants';
import { IProps } from './CopyButton.types';

const COPY_BUTTON_POSITION_X = 10;
const COPY_BUTTON_POSITION_Y = 7;

// 1 Rect со скруглениями углов для совпадения с контейнером, 2 Rect без скруглений для совпадения с разделителем в середине
const RIGHT_BACKGROUND_X = -10;
const RIGHT_BACKGROUND_Y = -7;
const LEFT_BACKGROUND = -7;

export function CopyButton(props: IProps): JSX.Element {
  const { onClone } = props;
  const { isMouseEnter, handleMouseLeave, handleMouseEnter } = useHover();

  const color = isMouseEnter ? Color.PRIMARY_HOVER : Color.MAIN;

  return (
    <Group
      x={COPY_BUTTON_POSITION_X}
      y={COPY_BUTTON_POSITION_Y}
      height={100}
      width={100}
      onClick={onClone}
      visible
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      perfectDrawEnabled={false}
    >
      <Rect
        x={RIGHT_BACKGROUND_X}
        y={RIGHT_BACKGROUND_Y}
        width={8}
        cornerRadius={4}
        height={30}
        fill={isMouseEnter ? '#f1f7ff' : '#fff'}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
      />
      <Rect
        x={LEFT_BACKGROUND}
        y={LEFT_BACKGROUND}
        width={32}
        height={30}
        fill={isMouseEnter ? '#f1f7ff' : '#fff'}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
      />
      <Path
        x={-5}
        y={-4}
        data="M8.25 7C8.25 5.48122 9.48122 4.25 11 4.25H17C18.5188 4.25 19.75 5.48122 19.75 7V13C19.75 14.5188 18.5188 15.75 17 15.75V14.25C17.6904 14.25 18.25 13.6904 18.25 13V7C18.25 6.30964 17.6904 5.75 17 5.75H11C10.3096 5.75 9.75 6.30964 9.75 7H8.25ZM7 8.25C5.48122 8.25 4.25 9.48122 4.25 11V17C4.25 18.5188 5.48122 19.75 7 19.75H13C14.5188 19.75 15.75 18.5188 15.75 17V11C15.75 9.48122 14.5188 8.25 13 8.25H7ZM5.75 11C5.75 10.3096 6.30964 9.75 7 9.75H13C13.6904 9.75 14.25 10.3096 14.25 11V17C14.25 17.6904 13.6904 18.25 13 18.25H7C6.30964 18.25 5.75 17.6904 5.75 17V11Z"
        fill={color}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
      />
    </Group>
  );
}
