export enum TypeData {
  DEVICE = 'Device',
  TAG = 'Tag',
  EVENT = 'Event'
}

export const TypesData = [
  { label: TypeData.DEVICE, value: `${TypeData.DEVICE}` },
  { label: TypeData.TAG, value: `${TypeData.TAG}` },
  { label: TypeData.EVENT, value: `${TypeData.EVENT}` }
];
