import React from 'react';
import { add, sub, format, isSameMonth } from 'date-fns';

// Components
import { Tools } from '../Tools';

// Types
import { NavigationType, ViewEnum } from '../../Datepicker.types';

// Helpers
import { isWithinInterval } from '../../helpers/isWithinInterval';

// Styled
import { CurrentDateMarker } from '../../styled';
import { Month, MonthsWrap } from './styled';

interface IProps {
  cursorDate: Date;
  minDate?: Date;
  maxDate?: Date;
  value?: Date;
  navigation: NavigationType;
  setView: (view: ViewEnum) => void;
}

const MONTHS_LIST = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

export function YearView(props: IProps): JSX.Element {
  const { cursorDate, value, navigation, minDate, maxDate, setView } = props;
  const currentYear = format(cursorDate, 'y');
  const currentDay = format(cursorDate, 'dd');

  const months = React.useMemo(
    () => MONTHS_LIST.map((month) => new Date(`${month}-${currentDay}-${currentYear}`)),
    [currentDay, currentYear]
  );

  const toNext = React.useCallback(() => {
    const nextDate = add(cursorDate, { years: 1 });

    navigation.setDate(nextDate);
  }, [cursorDate, navigation]);

  const toPrev = React.useCallback(() => {
    const nextDate = sub(cursorDate, { years: 1 });

    navigation.setDate(nextDate);
  }, [cursorDate, navigation]);

  const selectMonth = React.useCallback(
    (date: Date) => {
      navigation.setDate(date);
      setView(ViewEnum.MONTH);
    },
    [navigation, setView]
  );

  return (
    <>
      <Tools currentYear={currentYear} toPrev={toPrev} toNext={toNext} setView={setView} />
      <MonthsWrap>
        {months.map((month) => {
          const monthLabel = format(month, 'LLL');
          const isSelectedMonth = isSameMonth(month, value);
          const isCurrentDate = isSameMonth(month, new Date());
          const isDisabled = isWithinInterval({ value: month, minDate, maxDate });

          return (
            <Month
              key={monthLabel}
              isSameDay={isSelectedMonth}
              isDisabled={isDisabled}
              onClick={() => selectMonth(month)}
            >
              <div>{monthLabel}</div>
              {isCurrentDate ? <CurrentDateMarker isSameDate={isSelectedMonth} /> : null}
            </Month>
          );
        })}
      </MonthsWrap>
    </>
  );
}
