import { SECONDS_IN_DAY, SECONDS_IN_HOUR, SECONDS_IN_MINUTE } from '@src/common/constants';
import { TDelay } from '../types/types';

export function encodeDelay(date: TDelay): number {
  const daysInSeconds = date.dd * SECONDS_IN_DAY;
  const hoursInSeconds = date.hh * SECONDS_IN_HOUR;
  const minutesInSeconds = date.mm * SECONDS_IN_MINUTE;

  return daysInSeconds + hoursInSeconds + minutesInSeconds;
}
