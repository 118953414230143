import React from 'react';
import { Dropdown, useDropdown } from '@src/ui/kit';
import { IconEnum, Icon } from '@src/ui/kit/Icon';

// Constants
import { Color } from '@pushwoosh/kit-constants';

// Enums
import { SubscriptionPlan } from '@src/common/constants/subscriptionPlan';

import { AccountMenuLink } from '../AccountMenuLink';
import { LogoutButton } from '../LogoutButton';
import { UpgradePlan } from '../UpgradePlan';
import {
  AccountContainer,
  AccountText,
  AccountLabel,
  AccountEmail,
  AccountMenuWrap,
  TitleWrap,
  Username,
  UpgradeBadge,
  DropdownContent,
  IconWrap
} from './styled';

interface IProps {
  username: string;
  onLogout: () => void;
  subscriptionPlan: SubscriptionPlan;
}

export function AccountMenu(props: IProps): JSX.Element {
  const [splittedUsername, domen] = props.username.split('@');
  const { subscriptionPlan } = props;
  const isShowUpgradeBadge =
    subscriptionPlan === SubscriptionPlan.DEVELOPER ||
    subscriptionPlan === SubscriptionPlan.MARKETING;
  const isShowUpgradeBlock = subscriptionPlan === SubscriptionPlan.DEVELOPER_FREE;
  const accountMenuRef = React.useRef(null);
  const dropdown = useDropdown({ position: 'right-start', target: accountMenuRef });

  return (
    <>
      <AccountContainer ref={accountMenuRef} onMouseDown={dropdown.toggleDropdown}>
        <IconWrap>
          <Icon type={IconEnum.USER_MEDIUM_FILLED} fill={Color.BRIGHT} />
        </IconWrap>
        <AccountText>
          <AccountLabel>My account</AccountLabel>
          <AccountEmail>{props.username}</AccountEmail>
        </AccountText>
        <Icon type={IconEnum.CHEVRON_RIGHT_SMALL} />
      </AccountContainer>
      <Dropdown dropdown={dropdown} withArrow>
        <DropdownContent>
          <AccountMenuWrap>
            <TitleWrap>
              <Username>{splittedUsername}</Username>
              <AccountEmail>@{domen}</AccountEmail>
            </TitleWrap>
            <AccountMenuLink
              to="https://go.pushwoosh.com/cp/account/edit-profile"
              beforeIcon={IconEnum.USER_SMALL_LINED}
            >
              Profile Detail
            </AccountMenuLink>
            <AccountMenuLink
              to="https://go.pushwoosh.com/cp/products"
              beforeIcon={IconEnum.ARROW_ROUND_UP_SMALL_LINED}
            >
              Plan:&nbsp;
              {subscriptionPlan}
              {isShowUpgradeBadge ? <UpgradeBadge>UPGRADE</UpgradeBadge> : null}
            </AccountMenuLink>
            <AccountMenuLink
              to="https://go.pushwoosh.com/cp/account/info"
              beforeIcon={IconEnum.BOX_SMALL_LINED}
            >
              Workspace detail
            </AccountMenuLink>
            <AccountMenuLink
              to="https://go.pushwoosh.com/cp/account/edit-profile"
              beforeIcon={IconEnum.EDIT_SMALL_LINED}
            >
              Edit Billing info
            </AccountMenuLink>
            <AccountMenuLink
              to="https://go.pushwoosh.com/cp/account/payment-methods"
              beforeIcon={IconEnum.BILLING_SMALL_LINED}
            >
              Payment method
            </AccountMenuLink>
            <AccountMenuLink
              to="https://go.pushwoosh.com/cp/account/payment-history"
              beforeIcon={IconEnum.TIME_SMALL_LINED}
            >
              Payment history
            </AccountMenuLink>
            <LogoutButton onLogout={props.onLogout} />
          </AccountMenuWrap>
          {isShowUpgradeBlock ? <UpgradePlan /> : null}
        </DropdownContent>
      </Dropdown>
    </>
  );
}
