import React from 'react';
import { H3 } from '@pushwoosh/kit-typography';

// Components
import { IconEnum } from '@src/ui/kit';

// Types
import { IRightDrawerSidebarProps } from './RightDrawerSidebar.types';

// Styled
import {
  RightDrawerSidebarContainer,
  RightDrawerSidebarHeader,
  RightDrawerSidebarContent,
  RightDrawerSidebarControlBack,
  RightDrawerSidebarControlClose,
  RightDrawerSidebarFooter
} from './styled';

export function RightDrawerSidebar(props: IRightDrawerSidebarProps): JSX.Element {
  const { sidebarRef, title, children, isOpen, isBack, onClose, onBack, renderFooter } = props;

  if (!isOpen) {
    return null;
  }

  return (
    <RightDrawerSidebarContainer ref={sidebarRef}>
      <RightDrawerSidebarHeader>
        {isBack && (
          <RightDrawerSidebarControlBack
            color="primary"
            view="ghost"
            iconType={IconEnum.ARROW_LEFT_MEDIUM}
            onClick={onBack}
          />
        )}

        <H3>{title}</H3>

        <RightDrawerSidebarControlClose
          color="secondary"
          view="ghost"
          iconType={IconEnum.CLOSE_MEDIUM}
          onClick={onClose}
        />
      </RightDrawerSidebarHeader>

      <RightDrawerSidebarContent>{children}</RightDrawerSidebarContent>
      {renderFooter && <RightDrawerSidebarFooter>{renderFooter()}</RightDrawerSidebarFooter>}
    </RightDrawerSidebarContainer>
  );
}
