import styled, { css } from 'styled-components';
import { FontWeight, FontSize, Color, Spacing, ShapeRadius } from '@pushwoosh/kit-constants';

type DecadeProps = {
  isCurrentDecade?: boolean;
  isSameDate: boolean;
  isDisabled?: boolean;
};

const sameDate = css`
  background: ${Color.BRIGHT};
  color: ${Color.CLEAR};
`;

const notSameDate = css`
  &:hover {
    background: ${Color.BRIGHT_LIGHT};
    color: ${Color.PRIMARY_HOVER};
  }

  &:active {
    background: ${Color.BRIGHT_LIGHT};
    color: ${Color.PRIMARY_PRESSED};
  }
`;

const disabled = css`
  border: 1px solid ${Color.FORM};
  background: linear-gradient(
    to top left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 0.8px),
    ${Color.FORM} 50%,
    rgba(0, 0, 0, 0) calc(50% + 0.8px),
    rgba(0, 0, 0, 0) 100%
  );
  pointer-events: none;
`;

export const Decade = styled.div`
  height: 59px;
  width: 59px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${({ isCurrentDecade }: DecadeProps) =>
    isCurrentDecade ? FontWeight.MEDIUM : FontWeight.REGULAR};
  font-size: ${FontSize.REGULAR};
  color: ${Color.MAIN};
  cursor: pointer;
  opacity: ${({ isCurrentDecade }) => (isCurrentDecade ? 'unset' : '0.8')};
  flex-direction: column;
  border-radius: ${ShapeRadius.CONTROL};

  ${({ isSameDate, isDisabled }) => (isSameDate && !isDisabled ? sameDate : notSameDate)}

  ${({ isDisabled }) => isDisabled && disabled}

  &:not(:nth-child(4n)) {
    margin-right: ${Spacing.S1};
  }
`;

export const DecadeWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${Spacing.S5};
`;
