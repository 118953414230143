import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgLanguageMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.002 20.75a8.75 8.75 0 10.005-17.5 8.75 8.75 0 00-.01 17.5h.005zM10.295 4.952a7.256 7.256 0 00-5.507 6.298h3.298a12.99 12.99 0 012.209-6.298zm3.41 0a12.99 12.99 0 012.209 6.298h3.298a7.256 7.256 0 00-5.507-6.298zm.704 6.298A11.49 11.49 0 0012 5.16a11.49 11.49 0 00-2.409 6.09h4.818zm-4.818 1.5h4.818A11.49 11.49 0 0112 18.84a11.49 11.49 0 01-2.409-6.09zm-1.505 0H4.788a7.256 7.256 0 005.507 6.298 12.99 12.99 0 01-2.209-6.298zm5.62 6.298a12.99 12.99 0 002.208-6.298h3.298a7.256 7.256 0 01-5.507 6.298z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgLanguageMediumLined;
