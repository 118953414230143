import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgCloseSmall(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.702 4.641a.75.75 0 00-1.06 1.061L6.938 8l-2.298 2.298a.75.75 0 101.06 1.06L8 9.062l2.298 2.298a.75.75 0 001.06-1.06L9.062 8l2.298-2.298a.75.75 0 10-1.061-1.06L8 6.938 5.702 4.641z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgCloseSmall;
