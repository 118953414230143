import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgDownloadMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.47 16.47a.75.75 0 011.06 0l1.379 1.378a.2.2 0 00.341-.142V10a.75.75 0 011.5 0v7.706a.2.2 0 00.341.142l1.379-1.378a.75.75 0 011.06 1.06l-3 3a.75.75 0 01-1.06 0l-3-3a.75.75 0 010-1.06zM5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25V12a2.25 2.25 0 01-2.25 2.25h-.75a.75.75 0 010-1.5h.75a.75.75 0 00.75-.75V7.5a.75.75 0 00-.75-.75h-9a.75.75 0 00-.75.75V12c0 .414.336.75.75.75h.75a.75.75 0 010 1.5H7.5A2.25 2.25 0 015.25 12V7.5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgDownloadMediumLined;
