import * as React from 'react';
import { format } from 'date-fns';

// Constants
import {
  AttributeTypeEnum,
  MULTIPLE_CHOICE_OPERATORS,
  ONE_CHOICE_OPERATORS,
  OperatorTypeEnum,
  TWO_CHOICE_OPERATORS
} from '@src/common/constants/attribute';
import { platforms } from '@src/common/constants/platform';

// Hooks
import { useDidUpdate } from '@src/ui/hooks';

// Components
import { FormSelect } from '@src/ui/form';
import { Icon, IconEnum } from '@src/ui/kit';
import {
  FilterConditionTwoChoice,
  TFilterConditionTwoChoiceValue
} from '../FilterConditionTwoChoice';
import {
  FilterConditionOneChoice,
  TFilterConditionOneChoiceValue
} from '../FilterConditionOneChoice';
import {
  FilterConditionMultipleChoice,
  TFilterConditionMultipleChoiceValue
} from '../FilterConditionMultipleChoice';

// Types
import { IFilterConditionProps } from './FilterCondition.types';

// Styled
import { FilterValueWrap, Join, Row } from './styled';

function isMultipleChoiceValue(operatorType: string): boolean {
  return MULTIPLE_CHOICE_OPERATORS.has(operatorType as OperatorTypeEnum);
}

function isTwoChoiceValue(operatorType: string): boolean {
  return TWO_CHOICE_OPERATORS.has(operatorType as OperatorTypeEnum);
}

function isOneChoiceValue(operatorType: string): boolean {
  return ONE_CHOICE_OPERATORS.has(operatorType as OperatorTypeEnum);
}

export function FilterCondition(props: IFilterConditionProps): JSX.Element {
  const {
    applicationCode,
    eventCode,
    attributeSelectOptions,
    operatorSelectOptions,
    names,
    values,
    errors = {},
    touched = {},
    setFieldTouched,
    setFieldValue,
    onChangeField,
    onBlurField,
    isLastElement
  } = props;

  const isAttributeList = values.type === AttributeTypeEnum.LIST;

  const isMultipleChoice = isAttributeList || isMultipleChoiceValue(values.operator);
  const isOneChoice = !isAttributeList && isOneChoiceValue(values.operator);
  const isTwoChoice = isTwoChoiceValue(values.operator);

  useDidUpdate((): void => {
    setFieldValue(names.operator, null);
  }, [values.type]);

  useDidUpdate((): void => {
    const { type } = values;

    if (isMultipleChoice) {
      setFieldValue(names.value, []);
    }

    if (isTwoChoice) {
      if (type === AttributeTypeEnum.INTEGER) {
        setFieldValue(names.value, [0, 0]);
      }

      if (type === AttributeTypeEnum.DATE) {
        setFieldValue(names.value, [
          format(new Date(), 'yyyy-MM-dd'),
          format(new Date(), 'yyyy-MM-dd')
        ]);
      }
    }

    if (!isMultipleChoice && isOneChoice) {
      if (type === AttributeTypeEnum.INTEGER) {
        setFieldValue(names.value, 0);
      }

      if (type === AttributeTypeEnum.DATE) {
        setFieldValue(names.value, format(new Date(), 'yyyy-MM-dd'));
      }

      if (type === AttributeTypeEnum.STRING) {
        setFieldValue(names.value, '');
      }

      if (type === AttributeTypeEnum.BOOLEAN) {
        setFieldValue(names.value, false);
      }
    }
  }, [values.operator]);

  let filterValueRender = null;

  /* eslint-disable @typescript-eslint/no-explicit-any */
  if (values.attributeName === 'PW_platform_id') {
    filterValueRender = (
      <FormSelect
        placeholder="Select platform"
        name={names.value}
        value={values.value}
        valueLabel={platforms[values.value as number]}
        error={touched.value && errors.value}
        options={Object.keys(platforms).map((p) => ({
          value: Number(p),
          label: platforms[p as unknown as number]
        }))}
        onChange={(name, value) => {
          setFieldValue(name, value);
        }}
        onBlur={setFieldTouched}
      />
    );
  } else if (isTwoChoice) {
    filterValueRender = (
      <FilterConditionTwoChoice
        attributeType={values.type}
        name={names.value}
        value={values.value as TFilterConditionTwoChoiceValue}
        error={errors.value as any}
        touched={touched.value as any}
        onChangeField={onChangeField}
        onBlurField={onBlurField}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
      />
    );
  } else if (filterValueRender === null && isMultipleChoice) {
    filterValueRender = (
      <FilterConditionMultipleChoice
        applicationCode={applicationCode}
        eventCode={eventCode}
        attributeName={values.attributeName}
        attributeType={values.type}
        name={names.value}
        value={(values.value as TFilterConditionMultipleChoiceValue) || []}
        error={errors.value}
        touched={touched.value}
        onChangeField={onChangeField}
        onBlurField={onBlurField}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
      />
    );
  } else if (filterValueRender === null && isOneChoice) {
    filterValueRender = (
      <FilterConditionOneChoice
        operatorType={values.operator}
        applicationCode={applicationCode}
        eventCode={eventCode}
        attributeName={values.attributeName}
        attributeType={values.type}
        name={names.value}
        value={values.value as TFilterConditionOneChoiceValue}
        error={errors.value}
        touched={touched.value}
        onChangeField={onChangeField}
        onBlurField={onBlurField}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
      />
    );
  }

  return (
    <Row>
      <FormSelect
        placeholder="Select attribute"
        name={names.type}
        value={values.type}
        valueLabel={values.attributeName}
        error={touched.type && errors.type}
        options={attributeSelectOptions}
        onChange={(_, type, attributeName): void => {
          setFieldValue(names.type, type);
          setFieldValue(names.attributeName, attributeName);
        }}
        onBlur={setFieldTouched}
      />

      <FormSelect
        placeholder="Select operator"
        name={names.operator}
        value={values.operator}
        error={touched.operator && errors.operator}
        options={operatorSelectOptions}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
      />

      <FilterValueWrap>{values.operator ? filterValueRender : null}</FilterValueWrap>

      {isLastElement || (
        <Join>
          <Icon type={IconEnum.PLUS_SMALL} />
        </Join>
      )}
    </Row>
  );
}
