import { useParams } from 'react-router-dom';
// History
import { useHistory } from '@src/common/contexts/history';

// Analytics
import { sendMixpanelEvent } from '@src/common/analytics';

// Constants
import { JourneyStatus } from '@src/common/constants/journey';

type EditJourneyHandler = {
  handleButtonEditClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const useEditJourney = (status: JourneyStatus, id: string): EditJourneyHandler => {
  const history = useHistory();
  const params = useParams<{ applicationCode: string }>();
  const { applicationCode } = params;

  const handleButtonEditClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();

    sendMixpanelEvent({ eventName: 'OpenJourney' });

    const journeyLink = applicationCode
      ? `/journeys/${applicationCode}/${status}/${id}`
      : `/journeys/${status}/${id}`;

    history.push(journeyLink);
  };

  return {
    handleButtonEditClick
  };
};
