import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgDropdownUp(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8 5L4 9h8L8 5z" fill={props.fill} />
    </svg>
  );
}

export default SvgDropdownUp;
