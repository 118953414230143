import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgTrashMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 5.5a.5.5 0 01.5-.5h3.293a.5.5 0 00.353-.146l.708-.708A.5.5 0 0111.207 4h1.586a.5.5 0 01.353.146l.708.708a.5.5 0 00.353.146H17.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-1z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 8.5a.5.5 0 01.5-.5h9a.5.5 0 01.5.5V18a2 2 0 01-2 2H9a2 2 0 01-2-2V8.5zM9.25 10a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h.5a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5h-.5zm2.5 0a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h.5a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5h-.5zm2 .5a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v6a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-6z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgTrashMediumFilled;
