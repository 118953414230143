import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';

export const InputWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const Error = styled.div`
  position: absolute;
  right: ${Spacing.S3};
`;
