import * as React from 'react';
import { Group, Rect } from 'react-konva';

// Types
import { IProps } from './PopUp.types';

export function PopUp(props: IProps): JSX.Element {
  const { position = { x: 0, y: 0 }, width = 40, height = 40, isVisible = true, children } = props;

  return (
    <Group x={position.x} y={position.y} width={width} height={height} visible={isVisible}>
      <Rect
        x={0}
        y={0}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
        width={width}
        height={height}
        shadowColor="#555"
        shadowBlur={10}
        shadowOffset={{
          x: 2,
          y: 2
        }}
        shadowOpacity={0.4}
      />

      {children}
    </Group>
  );
}
