import styled, { css } from 'styled-components';

// Constants
import { Color, ShapeRadius, Spacing, FontSize } from '@pushwoosh/kit-constants';

interface ButtonProps {
  isSelected: boolean;
  isDisabled: boolean;
  color: 'red' | 'blue';
}

const defineColorBackground = css`
  ${(props: ButtonProps): string => {
    if (props.isDisabled && props.isSelected) {
      return Color.LOCKED;
    }
    if (props.isDisabled && !props.isSelected) {
      return Color.SOFT_LIGHT;
    }
    if (props.isSelected) {
      if (props.color === 'red') {
        return Color.DANGER;
      }

      return Color.PRIMARY;
    }

    return Color.BRIGHT_LIGHT;
  }}
`;

const defineColorFont = css`
  ${(props: ButtonProps): string => {
    if (props.isDisabled && props.isSelected) {
      return Color.CLEAR;
    }
    if (props.isDisabled && !props.isSelected) {
      return Color.LOCKED;
    }
    if (props.isSelected) {
      return Color.CLEAR;
    }

    return Color.PRIMARY;
  }}
`;

const defineHover = css`
  ${(props: ButtonProps): string => {
    if (props.isDisabled) {
      return '';
    }
    if (!props.isSelected) {
      return `color: ${Color.PRIMARY_HOVER};`;
    }

    if (props.color === 'red') {
      return `background-color: ${Color.DANGER_HOVER};`;
    }
    return `background-color: ${Color.PRIMARY_HOVER};`;
  }}
`;

const defineActive = css`
  ${(props: ButtonProps): string => {
    if (props.isDisabled) {
      return '';
    }
    if (!props.isSelected) {
      return `color: ${Color.PRIMARY_PRESSED};`;
    }

    if (props.color === 'red') {
      return `background-color: ${Color.DANGER_PRESSED};`;
    }
    return `background-color: ${Color.PRIMARY_PRESSED};`;
  }}
`;

export const Button = styled.button`
  padding: ${Spacing.S0} ${Spacing.S3};
  background-color: ${defineColorBackground};
  color: ${defineColorFont};
  border-radius: ${ShapeRadius.CONTROL};
  border: 0;
  font-size: ${FontSize.REGULAR};
  line-height: 20px;

  &:hover {
    ${defineHover}
  }

  &:active {
    ${defineActive}
  }

  cursor: pointer;
`;
