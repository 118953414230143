import React from 'react';

// Store Hooks
import { useDeleteJourneyById } from '@src/store/hooks';

// Features Hooks
import { useConfirmService } from '@src/ui/features/Modals/Confirm/hooks/useConfirmService';

export const useConfirmDeleteJourney = (
  id: string,
  afterDeleteJourney?: () => void
): (() => void) => {
  const confirm = useConfirmService();
  const [deleteJourneyById, isRequestDeleteJourneyById] = useDeleteJourneyById();

  const confirmDeleteJourneyHandler = React.useCallback((): void => {
    confirm({
      onAfterConfirm(): void {
        deleteJourneyById(id).then(() => {
          if (afterDeleteJourney) {
            afterDeleteJourney();
          }
        });
      },
      title: 'Delete Draft',
      description: 'You won’t be able to restore this journey.',
      level: 'danger',
      confirmLabel: 'Delete',
      isLoadingConfirm: isRequestDeleteJourneyById
    });
  }, [confirm, deleteJourneyById, id, isRequestDeleteJourneyById]);

  return confirmDeleteJourneyHandler;
};
