import * as React from 'react';

// Store Hooks
import {
  useLoadJourneyById,
  useLoadStatistics,
  useModeMap,
  useSetCurrentJourneyId,
  useResetStore,
  useUnsavedChanges,
  useAutoSaveById,
  useGetJourneyById,
  useOpenNewModal,
  useCloseAllModals,
  useLoadRestrictions
} from '@src/store/hooks';
import { useStoreState } from '@src/store/store';
// UI Components
import { IUniqueId } from '@src/common/types/entities';
import { JourneyStatus } from '@src/common/constants/journey';
import { ModalType } from '@src/common/constants/modals';
import { PageJourneyEditor as PageJourneyEditorComponent } from '@src/ui/pages/PageJourneyEditor';

const PAUSE_STATISTICS = 5000;
const PAUSE_AUTO_SAVE = 10000;

interface IProps {
  journeyId: IUniqueId['id'];
}

export function PageJourneyEditor(props: IProps): JSX.Element {
  const { journeyId } = props;

  const modeMap = useModeMap();
  const openNewModal = useOpenNewModal();
  const closeAllModal = useCloseAllModals();
  const loadStatistics = useLoadStatistics();
  const setCurrentJourneyId = useSetCurrentJourneyId();
  const [loadJourneyById, isLoadingJourneyById] = useLoadJourneyById();
  const resetStore = useResetStore();
  const isUnsavedChanges = useUnsavedChanges();
  const autoSaveById = useAutoSaveById();
  const getJourneyById = useGetJourneyById();
  const timerId = React.useRef(null);
  const journey = getJourneyById(journeyId);
  const status = journey?.status;
  const applicationCode = journey?.params.applicationCode;
  const loadRestrictions = useLoadRestrictions();
  const periodSettings = useStoreState((state) => state.statistics.periodSettings);
  const isOpenedModals = useStoreState((state) => state.modals.isOpened);

  React.useEffect(() => {
    if (!isLoadingJourneyById && status === JourneyStatus.DRAFT && !applicationCode) {
      openNewModal({
        type: ModalType.SELECT_APPLICATION,
        data: {
          journeyId
        }
      });
    }

    if (isLoadingJourneyById && !applicationCode) {
      closeAllModal();
    }
  }, [status, applicationCode, journeyId, openNewModal, isLoadingJourneyById, closeAllModal]);

  React.useEffect(
    (): (() => void) => (): void => {
      resetStore();
      setCurrentJourneyId('');
    },
    [resetStore, setCurrentJourneyId]
  );

  React.useEffect((): void => {
    loadRestrictions();
    loadJourneyById(journeyId);
    setCurrentJourneyId(journeyId);
  }, [journeyId, loadJourneyById, setCurrentJourneyId, loadRestrictions]);

  React.useEffect(() => {
    if (isUnsavedChanges) {
      clearTimeout(timerId.current);
      timerId.current = setTimeout(() => {
        autoSaveById(journeyId);
      }, PAUSE_AUTO_SAVE);
    }

    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    };
  }, [timerId, isUnsavedChanges, autoSaveById, journeyId]);

  React.useEffect((): (() => void) => {
    let timerId: ReturnType<typeof setTimeout> = null;

    if (modeMap.isRead && !isOpenedModals) {
      const getStatisticEveryTime = (): void => {
        loadStatistics({
          id: journeyId,
          periodSettings
        });
        timerId = setTimeout(getStatisticEveryTime, PAUSE_STATISTICS);
      };

      getStatisticEveryTime();
    }

    return (): void => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [modeMap, journeyId, loadStatistics, periodSettings, isOpenedModals]);

  return <PageJourneyEditorComponent mode={modeMap} isLoading={isLoadingJourneyById} />;
}
