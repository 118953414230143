// Store
import { useStoreState } from '@src/store/store';

// Types
import { TModal } from '@src/common/types/modal';

type TResult = TModal[];

export function useModals(): TResult {
  const modals: TModal[] = useStoreState<TModal[]>((state): TModal[] => state.modals.items);

  return modals;
}
