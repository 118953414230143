import * as React from 'react';

// Component
import { Choice } from '../Choice';

// Styled
import { Container, Item } from './styled';

// Types
import { ChoiceGroupProps } from './ChoiceGroup.types';

export function ChoiceGroup(props: ChoiceGroupProps): JSX.Element {
  const {
    color,
    defaultValues,
    activeValues: activeValuesProps,
    onChange,
    isMultiple = true
  } = props;
  const [activeValues, setActiveValues] = React.useState(new Set(activeValuesProps));

  const handleChange = React.useCallback(
    (value: string, isSelected: boolean) => {
      const newActiveValues = new Set(activeValues);

      if (!isSelected) {
        newActiveValues.add(value);
      }
      if (isSelected) {
        newActiveValues.delete(value);
      }

      setActiveValues(newActiveValues);

      if (typeof onChange === 'function') {
        onChange(Array.from(newActiveValues));
      }
    },
    [activeValues, setActiveValues, onChange]
  );

  const handleOne = React.useCallback(
    (value: string) => {
      const newActiveValues = new Set(value);

      setActiveValues(newActiveValues);

      if (typeof onChange === 'function') {
        onChange(Array.from(newActiveValues));
      }
    },
    [onChange]
  );

  return (
    <Container>
      {defaultValues.map((item, index) => (
        <Item key={index}>
          <Choice
            color={color}
            isSelected={activeValues.has(item.value)}
            value={item.value}
            onChange={isMultiple ? handleChange : handleOne}
          >
            {item.label}
          </Choice>
        </Item>
      ))}
    </Container>
  );
}
