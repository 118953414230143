import React from 'react';
import { RectangularSkeleton } from '@pushwoosh/kit-skeleton';
import { ItemWrap, FullDocumentationWrap } from './styled';

export function Skeleton(): JSX.Element {
  return (
    <>
      <ItemWrap>
        <RectangularSkeleton width={95} height={16} borderRadius={4} />
        <RectangularSkeleton width={16} height={16} borderRadius={4} />
        <RectangularSkeleton width={119} height={16} borderRadius={4} />
      </ItemWrap>
      <ItemWrap>
        <RectangularSkeleton width={95} height={16} borderRadius={4} />
        <RectangularSkeleton width={16} height={16} borderRadius={4} />
        <RectangularSkeleton width={119} height={16} borderRadius={4} />
      </ItemWrap>
      <ItemWrap>
        <RectangularSkeleton width={95} height={16} borderRadius={4} />
        <RectangularSkeleton width={16} height={16} borderRadius={4} />
        <RectangularSkeleton width={119} height={16} borderRadius={4} />
      </ItemWrap>
      <ItemWrap>
        <RectangularSkeleton width={95} height={16} borderRadius={4} />
        <RectangularSkeleton width={16} height={16} borderRadius={4} />
        <RectangularSkeleton width={119} height={16} borderRadius={4} />
      </ItemWrap>
      <ItemWrap>
        <RectangularSkeleton width={95} height={16} borderRadius={4} />
        <RectangularSkeleton width={16} height={16} borderRadius={4} />
        <RectangularSkeleton width={119} height={16} borderRadius={4} />
      </ItemWrap>
      <FullDocumentationWrap>
        <RectangularSkeleton width={16} height={16} borderRadius={4} />
        <RectangularSkeleton width={95} height={16} borderRadius={4} />
      </FullDocumentationWrap>
    </>
  );
}
