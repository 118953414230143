import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPlusSmall(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.75 4a.75.75 0 00-1.5 0v3.25H4a.75.75 0 000 1.5h3.25V12a.75.75 0 001.5 0V8.75H12a.75.75 0 000-1.5H8.75V4z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPlusSmall;
