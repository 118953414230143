import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgBookmarkMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 14.25l.772.464L15.5 16.35V6.5h-7v9.85l2.728-1.636.772-.463zM12 16l3.486 2.091A1 1 0 0017 17.234V6a1 1 0 00-1-1H8a1 1 0 00-1 1v11.234a1 1 0 001.514.857L12 16z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgBookmarkMediumLined;
