import * as React from 'react';

// Types
import { TComment } from '@src/common/types/comments';

// Store
import { useStoreActions, useStoreState } from '@src/store/store';

type TCheckOverlapPoints = (payload?: TComment[]) => void;
type TResult = TCheckOverlapPoints;

export function useCheckOverlapPoints(): TResult {
  const checkOverlapPoints: TCheckOverlapPoints = useStoreActions<TCheckOverlapPoints>(
    (actions): TCheckOverlapPoints => actions.points.checkOverlapPoints
  );

  const comments = useStoreState((state) => state.comments.comments);

  const handlePointsCheckOverlap: TCheckOverlapPoints =
    React.useCallback<TCheckOverlapPoints>((): void => {
      checkOverlapPoints(comments);
    }, [checkOverlapPoints, comments]);

  return handlePointsCheckOverlap;
}
