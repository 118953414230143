import { Color, FontSize, FontWeight, ShapeRadius, Spacing } from '@pushwoosh/kit-constants';
import { Label as ButtonLabel, StyledButton } from '@src/ui/kit/Button/styled';
import styled from 'styled-components';

export const BorderContainer = styled.div<{ hasErrors?: boolean }>`
  border-radius: ${ShapeRadius.CONTROL};
  width: 100%;
  background-color: ${Color.SOFT_LIGHT};
  border: 1px solid ${({ hasErrors }) => (hasErrors ? Color.DANGER : Color.SOFT_LIGHT)};
`;

export const OperatorWrap = styled.div`
  margin-top: ${Spacing.S3};
  margin-bottom: -${Spacing.S3};

  ${StyledButton} {
    display: block;
    width: 100%;

    ${ButtonLabel} {
      display: block;
      text-align: center;
    }
  }
`;

export const HeaderTitle = styled.div`
  font-weight: ${FontWeight.MEDIUM};
  text-transform: uppercase;
  font-size: ${FontSize.SMALL};
`;

export const HeaderActions = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background-color: ${Color.SOFT_LIGHT};
  border-radius: ${ShapeRadius.CONTROL} ${ShapeRadius.CONTROL} 0 0;
  padding: ${Spacing.S0} ${Spacing.S3} ${Spacing.S0} ${Spacing.S5};
  user-select: none;

  min-height: 32px;

  ${HeaderActions} {
    svg > path {
      fill: ${Color.MAIN};
    }
  }
`;
