import * as React from 'react';

// Components
import { DocElements } from '@src/ui/features/Documentation/components/DocElements';

export function DocumentationPointSegment(): JSX.Element {
  return (
    <DocElements>
      <DocElements.Paragraph>
        <strong>Audience-based Entry </strong>
        {"is a pre-built segment of a particular app's users who will enter into your journey."}
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        {
          'To add a Audience-based Entry, drag-and-drop it to the canvas and double-click. In the pop-up window opened, choose an app and a Filter to apply.'
        }
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-point-segment_gif-1.gif"
        label="Adding Segment"
        alt="Adding Segment"
      />

      <DocElements.Paragraph>
        {
          'All subscribers of the chosen app who comply with Filter conditions (i.e., having corresponding Tag values assigned to their device or user ID) will enter the journey.'
        }
      </DocElements.Paragraph>

      <DocElements.Subtitle>Repeated Audience-based Entry</DocElements.Subtitle>

      <DocElements.Paragraph>
        {
          "By default, users from the segment enter the journey only once at its activation. However, there's an option to repeat the same flow automatically within the journey – a scheduled launch for the Audience-based Entry element. "
        }
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        {
          "When enabled, the Scheduled Launch refreshes the segment prior to every next entry and lets those users into the journey who got into the segment after it started the journey last time. Users who are traveling the journey at the moment of the repeated Audience-based Entry don't enter it again."
        }
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        {
          'You can set the Audience-based Entry to repeat once a day, once a week, once a month, or at any custom period or specific dates. '
        }
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-scheduled-launch.png"
        label="Scheduled Launch"
        alt="Scheduled Launch"
      />

      <DocElements.Paragraph>
        {
          'As a custom period, you can set the Audience-based Entry to repeat once a several weeks or months in the days of the week or the month you specify. For example, every three weeks, three time a week on odd days.'
        }
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-scheduled-launch-2.png"
        label="Scheduled Launch"
        alt="Scheduled Launch"
      />

      <DocElements.Paragraph>
        {
          'You can also set the entry by segment to repeat on the specific dates. It might come in handy when some events in your app occur thus updating the audience segment with new users.'
        }
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-scheduled-launch-3.png"
        label="Scheduled Launch"
        alt="Scheduled Launch"
      />
    </DocElements>
  );
}
