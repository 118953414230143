import * as React from 'react';
import { useMemo } from 'react';

// Containers
import { EditOneEvent } from '@src/ui/features/EditEvents/containers/EditOneEvent';

// UI Components
import { Button, IconEnum, Section, SeparatorOr, SplitedList } from '@src/ui/kit';

import { ICommonEvent } from '@src/common/types/event';
import { FormBlock } from '@src/ui/form/styled';
import { TFormErrors } from '@src/ui/hooks/useForm';
import { IEditOnePointValues } from '@src/ui/features/EditEvents/components/FormEditOneEvent';
import { BorderContainer, Header, HeaderActions, HeaderTitle, OperatorWrap } from './styled';

interface IProps {
  events: ICommonEvent[];
  errors?: (string | TFormErrors<ICommonEvent>)[];
  updateEvent: (indexElement: number, data: ICommonEvent, pointId?: string) => void;
  updateOperator?: (indexElement: number) => void;
  removeEvent: (id: number) => void;
  isShowThrowOut?: boolean;
  removeDisabled?: boolean;
  inBorder?: boolean;
}

const convertErrors = (errors: TFormErrors<ICommonEvent>): TFormErrors<IEditOnePointValues> => {
  if (!errors) return undefined;

  return {
    eventCode: errors.name,
    eventConditions: errors.eventConditions,
    isThrowOut: errors.isThrowOut
  };
};

function useErrors(errors: IProps['errors']) {
  return useMemo(() => {
    const textErrors = errors?.map((e) => (typeof e === 'string' ? e : undefined)) || [];
    const fieldErrors =
      errors?.map((e) => (typeof e === 'object' ? convertErrors(e) : undefined)) || [];

    return {
      textErrors,
      fieldErrors
    };
  }, [errors]);
}

export function EditEvents(props: IProps): JSX.Element {
  const {
    events,
    errors,
    updateEvent,
    updateOperator,
    removeEvent,
    isShowThrowOut,
    removeDisabled = false,
    inBorder = false
  } = props;

  const canRemovedGoals = !removeDisabled;

  const { textErrors, fieldErrors } = useErrors(errors);

  return inBorder ? (
    <>
      {events.map((event, index) => (
        <FormBlock>
          <BorderContainer hasErrors={!!textErrors[index]}>
            <Header>
              <HeaderTitle>Trigger</HeaderTitle>

              <HeaderActions>
                {canRemovedGoals && (
                  <Button
                    iconType={IconEnum.CLOSE_SMALL}
                    iconPosition={'right'}
                    onClick={() => removeEvent(index)}
                    size="compact"
                    color="primary"
                    view="ghost"
                  />
                )}
              </HeaderActions>
            </Header>
            <EditOneEvent
              view="transparent"
              key={`${index}${event.name}`}
              index={index}
              initialValues={{
                eventCode: event.name,
                isThrowOut: event.isThrowOut,
                eventConditions: event.eventConditions
              }}
              errors={fieldErrors[index]}
              onRemoveEvent={(): void => removeEvent(index)}
              onUpdatedForm={({ index, data }): void => updateEvent(index, data, event.id)}
              isShowThrowOut={isShowThrowOut}
            />
          </BorderContainer>
          {!(index === events.length - 1) && (
            <OperatorWrap>
              <Button
                onClick={() => updateOperator(index)}
                color="secondary"
                size="compact"
                view="shape"
              >
                {event?.operation?.type?.toString()}
              </Button>
            </OperatorWrap>
          )}
        </FormBlock>
      ))}
    </>
  ) : (
    <Section direction="column">
      <SplitedList direction="vertical" renderSeparator={SeparatorOr}>
        {events.map((event, index) => (
          <EditOneEvent
            key={`${index}${event.name}`}
            index={index}
            initialValues={{
              eventCode: event.name,
              isThrowOut: event.isThrowOut,
              eventConditions: event.eventConditions
            }}
            errors={fieldErrors[index]}
            onRemoveEvent={canRemovedGoals ? (): void => removeEvent(index) : undefined}
            onUpdatedForm={({ index, data }): void => updateEvent(index, data, event.id)}
            isShowThrowOut={isShowThrowOut}
          />
        ))}
      </SplitedList>
    </Section>
  );
}
