import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgTimeSmallFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 8a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zM8.75 4.4a.75.75 0 00-1.5 0V8c0 .284.16.544.415.67l2.4 1.2a.75.75 0 10.67-1.34L8.75 7.536V4.4z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgTimeSmallFilled;
