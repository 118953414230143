import * as React from 'react';
import { useParams } from 'react-router-dom';

// Analytics
import { sendPostEvent } from '@src/common/analytics';

import { useHistory } from '@src/common/contexts/history';

// Types
import { IUniqueId } from '@src/common/types/entities';

// Store Hooks
import { useCloseAllModals, useUsername, useCloneJourneyById } from '@src/store/hooks';

// UI Hooks
import { useForm, TFormErrors } from '@src/ui/hooks/useForm';

// UI Components
import { Modal } from '@src/ui/kit/Modal';
import { DefaultButtons } from '@src/ui/kit/ModalButtons';
import { EditJourneyTitle, IEditTitleValues } from '../components/EditJourneyTitle';

interface IProps {
  journeyId?: IUniqueId['id'];
}

export function ModalCloningJourney({ journeyId }: IProps): JSX.Element {
  const closeAllModals = useCloseAllModals();
  const username = useUsername();
  const [cloneJourneyById, isCloningJourney] = useCloneJourneyById();
  const history = useHistory();
  const params = useParams<{ applicationCode: string }>();
  const { applicationCode } = params;

  const {
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit
  } = useForm<IEditTitleValues>({
    initialValues: {
      title: ''
    },
    validate(values): TFormErrors<IEditTitleValues> {
      const errors: TFormErrors<IEditTitleValues> = {};

      if (!values.title) {
        errors.title = 'Name is required';
      }

      return errors;
    },
    onSubmit: async (formValues): Promise<void> => {
      const journey = await cloneJourneyById({ id: journeyId, title: formValues.title });

      sendPostEvent('CustomerJourneyClone', { userEmail: username });

      closeAllModals();

      const journeyLink = applicationCode
        ? `/journeys/${applicationCode}/${journey.status}/${journey.id}`
        : `/journeys/${journey.status}/${journey.id}`;

      history.push(journeyLink);
    }
  });

  return (
    <Modal
      title="Cloning journey"
      widthType="short"
      footerRight={
        <DefaultButtons
          isDisabledActionButton={isCloningJourney}
          onClickActionButton={handleSubmit}
          onClickCancelButton={closeAllModals}
        />
      }
      isOpen
    >
      <EditJourneyTitle
        names={{
          title: 'title'
        }}
        values={values}
        touched={touched}
        errors={errors}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        onChangeField={handleChange}
        onBlurField={handleBlur}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
}
