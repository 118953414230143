import * as React from 'react';

// Store
import { useStoreActions } from '@src/store/store';

// Types
import { INotification } from '@src/common/types/notification';

type TActionAdd = (notifications: INotification[]) => void;
type TResult = TActionAdd;

export function useAddNotifications(): TResult {
  const addNotifications: TActionAdd = useStoreActions<TActionAdd>(
    (actions): TActionAdd => actions.notifications.add
  );

  const handleNotificationsAdd = React.useCallback<TActionAdd>(
    (notifications): void => {
      addNotifications(notifications);
    },
    [addNotifications]
  );

  return handleNotificationsAdd;
}
