import * as React from 'react';

// Store
import { useStoreActions, useStoreState } from '@src/store/store';

// Hooks
import { useSetMode } from '@src/store/hooks';

// Helpers
import { getModeByJourneyStatus } from '@src/store/helpers';

// Types
import { TJourney } from '@src/common/types/journey';
import { TRequest } from '@src/store/models/requests/requests.types';
import { IUniqueId } from '@src/common/types/entities';

type TActionLoadById = (id: IUniqueId['id']) => Promise<TJourney>;
type TResult = [TActionLoadById, boolean];

export function useLoadJourneyById(): TResult {
  const setMode = useSetMode();

  const loadById: TActionLoadById = useStoreActions(
    (actions): TActionLoadById => actions.journeys.loadById
  );

  const request: TRequest = useStoreState(
    (state): TRequest => state.requests.entities['journeys.loadById']
  );

  const handleJourneyLoadById = React.useCallback<TActionLoadById>(
    async (journeyId): Promise<TJourney> => {
      const journey: TJourney = await loadById(journeyId);
      const mode = getModeByJourneyStatus(journey.status);
      setMode(mode);

      return journey;
    },
    [loadById, setMode]
  );

  return [handleJourneyLoadById, Boolean(request)];
}
