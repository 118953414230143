// Types
import { Tag, UpdateTag } from '@src/common/types/tag';
import { TypeTag } from '@src/common/constants/tag';

// Helpers
import { getDefaultValueTag } from '@src/common/helpers';

type Args = {
  name: string;
  indexTag: number;
  existTags: Tag[];
  type?: TypeTag;
  updateTags: UpdateTag[];
};
export const newUpdateTagsByName = (args: Args): UpdateTag[] => {
  const { name, indexTag, type, existTags, updateTags } = args;

  const newUpdateTags = updateTags.map((item, index) => {
    if (index === indexTag) {
      const existTag = existTags.filter((item) => item.name === name);

      return {
        ...existTag[0],
        type,
        value: getDefaultValueTag(type || existTag[0].type)
      };
    }
    return item;
  });

  return newUpdateTags;
};
