import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgMoreMedium(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.75 10.25a1.75 1.75 0 110 3.5 1.75 1.75 0 010-3.5zM11.75 10.25a1.75 1.75 0 110 3.5 1.75 1.75 0 010-3.5zM19.5 12a1.75 1.75 0 10-3.5 0 1.75 1.75 0 003.5 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgMoreMedium;
