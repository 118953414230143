import styled from 'styled-components';

export const TextWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
`;

export const EditableTextInput = styled.input`
  width: 150px;
`;
