import { RepeatType, RepeatFrequency } from '@src/common/constants/repeatSegment';

export const getRepeatDescription = (
  repeatType: RepeatType,
  repeatFrequency: RepeatFrequency
): string => {
  let repeatTypeText;

  switch (repeatType) {
    case RepeatType.EVERY_DAY: {
      repeatTypeText = 'every day';
      break;
    }

    case RepeatType.EVERY_WEEK: {
      repeatTypeText = 'every week';
      break;
    }

    case RepeatType.EVERY_MONTH: {
      repeatTypeText = 'every month';
      break;
    }

    case RepeatType.CUSTOM: {
      if (repeatFrequency === RepeatFrequency.MONTHLY) {
        return 'If you schedule the start of the journey to the last day of a month, new users in the selected segment will enter the journey on each specific month’s last day, be it 28th, 29th, 30th, or 31.';
      }
      break;
    }

    default: {
      repeatTypeText = 'every day';
      break;
    }
  }

  return `New users in selected segment will be added to the journey ${repeatTypeText} since the activation day.`;
};
