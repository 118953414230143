import * as React from 'react';

// Styled
import { Container, Box, Circle, Label } from './styled';

// Types
import { SwitchProps } from './Switch.types';

export const Switch = (props: SwitchProps): JSX.Element => {
  const {
    labelPosition = 'right',
    defaultEnabled = false,
    isDisabled = false,
    children,
    onChange
  } = props;
  const [enabled, setEnabled] = React.useState(defaultEnabled);

  const handleClick = React.useCallback(() => {
    if (isDisabled) {
      return;
    }
    setEnabled(!enabled);
    if (typeof onChange === 'function') {
      onChange(!enabled);
    }
  }, [isDisabled, enabled, setEnabled, onChange]);

  return (
    <Container labelPosition={labelPosition}>
      <Box enabled={enabled} isDisabled={isDisabled} onClick={handleClick}>
        <Circle enabled={enabled} isDisabled={isDisabled} />
      </Box>
      {children && <Label labelPosition={labelPosition}>{children}</Label>}
    </Container>
  );
};
