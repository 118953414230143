/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import { Color, ShapeRadius, Spacing, Shadow } from '@pushwoosh/kit-constants';
import { IProps } from './Plate.types';

type ContainerProps = {
  view: IProps['view'];
  withShadow: IProps['withShadow'];
  withBorder: IProps['withBorder'];
  isFlat: IProps['isFlat'];
  onClick: IProps['onClick'];
};

const clickableCss = css`
  cursor: pointer;
  transition: box-shadow 300ms;

  &:hover {
    box-shadow: ${Shadow.LARGE};
  }
`;

export const Container = styled.div`
  box-sizing: border-box;
  background-color: ${({ view }: ContainerProps) =>
    view === 'transparent' ? 'transparent' : view === 'dark' ? Color.SOFT_LIGHT : Color.CLEAR};
  box-shadow: ${({ withShadow }: ContainerProps) => (withShadow ? Shadow.SMALL : 'none')};
  border: ${({ withBorder }: ContainerProps) => (withBorder ? `1px solid ${Color.FORM}` : 'none')};
  border-radius: ${({ isFlat }: ContainerProps) => (isFlat ? 0 : ShapeRadius.CONTROL)};
  padding: ${Spacing.S5};
  width: 100%;

  ${({ onClick }) => Boolean(onClick) && clickableCss};
`;
