import styled from 'styled-components';
import { FontSize, Color, LineHeight } from '@pushwoosh/kit-constants';

export const StyledTextarea = styled.textarea`
  width: 100%;
  border: none;
  outline: transparent;
  font-size: ${FontSize.REGULAR};
  line-height: ${LineHeight.REGULAR};
  color: ${Color.MAIN};
  resize: none;
  background: transparent;
`;
