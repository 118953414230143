import * as React from 'react';
import { sendMixpanelEvent } from '@src/common/analytics';

// Components
import { Icon, IconEnum, Link } from '@src/ui/kit';
import { Popover } from '@src/ui/kit/Popover';

// Helpers
import { getPointIconColorByType, getPointNameByType } from '@src/ui/helpers';

// Enums
import {
  DISABLED_GROUP_COLOR,
  ModalType,
  PointType,
  PREMIUM_POINTS_TYPE
} from '@src/common/constants';

// Types
import { getPremiumFeatureAvailableByType } from '@src/ui/helpers/getPremiumFeatureAvailableByType';
import { useRestrictions } from '@src/store/hooks/restrictions';
import { Color } from '@pushwoosh/kit-constants';
import { useOpenNewModal } from '@src/store/hooks';
import { getPointShortDescriptionByType } from '@src/ui/helpers/getPointShortDescriptionByType';
import { IPointProps } from './Point.types';

// Styled
import { LockIcon, Name, StyledBody, StyledPoint, StyledButton } from './styled';

export function Point(props: IPointProps): JSX.Element {
  const {
    style = {},
    type,
    isRead,
    onMouseDown,
    isDisabled,
    onSetActivePopover,
    activePopoverPointType
  } = props;

  const namePoint = getPointNameByType(type);
  const isPremium = PREMIUM_POINTS_TYPE.has(type);
  const [popoverYCoord, setPopoverCoord] = React.useState(null);

  const restrictions = useRestrictions();
  const openModal = useOpenNewModal();
  const isPremiumAvailable = getPremiumFeatureAvailableByType(type, restrictions);
  const [isHovered, setIsHovered] = React.useState(false);

  const popoverX = -248;
  const popoverY = Math.round(popoverYCoord) - 120;

  const premiumTitle = `${namePoint} is part of Marketing plan`;
  const premiumDescription = getPointShortDescriptionByType(type);

  const handlePopoverClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    onSetActivePopover(null);
    setIsHovered(false);
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    if (isPremium && !isPremiumAvailable) {
      if (type === PointType.SET_TAGS) {
        onSetActivePopover(type);
        setPopoverCoord(event.currentTarget.getBoundingClientRect().y);
      } else {
        openModal({
          type: ModalType.PREMIUM,
          data: {
            premiumType: type
          }
        });
      }
    } else if (!isDisabled) {
      onMouseDown();
    }
  };

  const handleSendMixpanelEvent = (type: PointType) => () => {
    sendMixpanelEvent({
      eventName: 'JourneyPointRestrictionButtonClicked',
      eventData: {
        point: type
      }
    });
  };

  return (
    <>
      <StyledPoint
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onMouseDown={handleMouseDown}
        isDisabled={isDisabled}
        style={style}
        type={type}
      >
        <StyledBody>
          <Icon
            type={type.toString() as IconEnum}
            fill={isRead ? DISABLED_GROUP_COLOR : getPointIconColorByType(type)}
            width={24}
            height={24}
          />
          <Name>{namePoint}</Name>
        </StyledBody>
        <div>
          {isDisabled && (
            <LockIcon>
              <Icon type={IconEnum.LOCK_SMALL_FILLED} fill={Color.LOCKED} />
            </LockIcon>
          )}
          {isPremium && !isPremiumAvailable && (
            <Icon type={isHovered ? IconEnum.PREMIUM_ENABLED : IconEnum.PREMIUM_DISABLED} />
          )}
        </div>
        {isPremium && !isPremiumAvailable && activePopoverPointType === type && (
          <Popover
            onClose={handlePopoverClose}
            title={premiumTitle}
            description={premiumDescription}
            y={popoverY}
            x={popoverX}
          >
            {[PointType.WEBHOOK, PointType.SEND_IN_APP, PointType.API].includes(type) && (
              <Link
                to="https://www.pushwoosh.com/demo/?utm_content=pwproduct&utm_term=journeypoint&utm_campaign=demo"
                isExternal
                target="_blank"
                onClick={handleSendMixpanelEvent(type)}
              >
                <StyledButton type="button">Contact sales</StyledButton>
              </Link>
            )}
            {type === PointType.SET_TAGS && (
              <Link
                to="https://app.pushwoosh.com/products"
                isExternal
                target="_blank"
                onClick={handleSendMixpanelEvent(type)}
              >
                <StyledButton type="button">Upgrade now</StyledButton>
              </Link>
            )}
          </Popover>
        )}
      </StyledPoint>
    </>
  );
}
