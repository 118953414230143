import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgCopyMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 7A2.75 2.75 0 0111 4.25h6A2.75 2.75 0 0119.75 7v6A2.75 2.75 0 0117 15.75v-1.5c.69 0 1.25-.56 1.25-1.25V7c0-.69-.56-1.25-1.25-1.25h-6c-.69 0-1.25.56-1.25 1.25h-1.5zM7 8.25A2.75 2.75 0 004.25 11v6A2.75 2.75 0 007 19.75h6A2.75 2.75 0 0015.75 17v-6A2.75 2.75 0 0013 8.25H7zM5.75 11c0-.69.56-1.25 1.25-1.25h6c.69 0 1.25.56 1.25 1.25v6c0 .69-.56 1.25-1.25 1.25H7c-.69 0-1.25-.56-1.25-1.25v-6z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgCopyMediumLined;
