import * as React from 'react';
import { Color } from '@pushwoosh/kit-constants';
import { Group, Rect } from 'react-konva';

// Helper
import { getPercentFromNumber } from './helper';

// Types
interface IProps {
  x: number;
  y: number;
  width: number;
  progress: number;
}

export function ProgressBar(props: IProps): JSX.Element {
  const { x, y, width, progress } = props;

  const handleCalcWidthProgress = React.useMemo(
    (): number => getPercentFromNumber(width, progress),
    [width, progress]
  );

  return (
    <Group x={x} y={y}>
      <Rect
        x={0}
        y={0}
        width={handleCalcWidthProgress}
        height={12}
        fill={progress === 0 ? Color.PHANTOM : Color.BRIGHT}
        cornerRadius={2}
      />
      <Rect
        x={0}
        y={0}
        width={width}
        height={12}
        fill={progress === 0 ? Color.PHANTOM : Color.BRIGHT}
        opacity={0.1}
        cornerRadius={2}
      />
    </Group>
  );
}
