import React from 'react';

// Components
import { H5, H1 } from '@pushwoosh/kit-typography';

// Helpers
import { formatNumberWithSpace } from '@src/common/helpers/formatNumber';

// Types
import { StatItemProps } from './StatItem.types';

// Styled
import { StatItemWrap, ValueRow, Percent } from './styled';

export function StatItem(props: StatItemProps): JSX.Element {
  const { title, value, percent } = props;

  return (
    <StatItemWrap>
      <H5>{title}</H5>
      <ValueRow>
        <H1>{typeof value === 'number' ? formatNumberWithSpace(value) : value}</H1>
        {percent && <Percent>{percent}</Percent>}
      </ValueRow>
    </StatItemWrap>
  );
}
