import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';

export const Container = styled.div`
  width: 100%;
`;

export const Tag = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${Spacing.S5};
`;

export const TagValue = styled.div`
  margin-right: ${Spacing.S5};
`;
