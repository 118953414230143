import React from 'react';
import { startOfDecade, endOfDecade, add, sub, isSameYear, startOfYear, endOfYear } from 'date-fns';

// Components
import { Tools } from '../Tools';

// Helpers
import { isWithinInterval } from '../../helpers/isWithinInterval';

// Types
import { NavigationType, ViewEnum } from '../../Datepicker.types';

// Styled
import { CurrentDateMarker } from '../../styled';
import { DecadeWrap, Decade } from './styled';

interface IProps {
  cursorDate: Date;
  value?: Date;
  minDate?: Date;
  maxDate?: Date;
  navigation: NavigationType;
  setView: (view: ViewEnum) => void;
}

export function DecadeView(props: IProps): JSX.Element {
  const { cursorDate, value, minDate, maxDate, navigation, setView } = props;

  const decadeLabel = React.useMemo(() => {
    const startYear = startOfDecade(cursorDate).getFullYear();
    const endYear = endOfDecade(cursorDate).getFullYear();

    return `${startYear} - ${endYear}`;
  }, [cursorDate]);

  const years = React.useMemo(() => {
    const yearsList = [];
    const startYear = startOfDecade(cursorDate);

    for (let i = 0; i <= 11; i++) {
      const year = add(startYear, { years: i });

      yearsList.push(year);
    }

    return yearsList;
  }, [cursorDate]);

  const toPrev = React.useCallback(() => {
    const nextDate = sub(cursorDate, { years: 10 });

    navigation.setDate(nextDate);
  }, [cursorDate, navigation]);

  const toNext = React.useCallback(() => {
    const nextDate = add(cursorDate, { years: 10 });

    navigation.setDate(nextDate);
  }, [cursorDate, navigation]);

  const selectYear = React.useCallback(
    (date: Date) => {
      navigation.setDate(date);
      setView(ViewEnum.YEAR);
    },
    [navigation, setView]
  );

  return (
    <>
      <Tools decadeLabel={decadeLabel} toPrev={toPrev} toNext={toNext} />
      <DecadeWrap>
        {years.map((year, index) => {
          const yearLabel = year.getFullYear();
          const isSelectedYear = isSameYear(year, value);
          const isCurrentDate = isSameYear(year, new Date());
          const isDisabled = isWithinInterval({
            value: year,
            minDate: startOfYear(minDate),
            maxDate: endOfYear(maxDate)
          });
          const isCurrentDecade = index < 10;

          return (
            <Decade
              key={yearLabel}
              isSameDate={isSelectedYear}
              isDisabled={isDisabled}
              isCurrentDecade={isCurrentDecade}
              onClick={() => selectYear(year)}
            >
              {yearLabel}
              {isCurrentDate ? <CurrentDateMarker isSameDate={isSelectedYear} /> : null}
            </Decade>
          );
        })}
      </DecadeWrap>
    </>
  );
}
