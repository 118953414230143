import * as React from 'react';
import Konva from 'konva';

export function useAutoMouseFixedAtMouseLeave(refStage: React.RefObject<Konva.Stage>): void {
  const refHandleMouseLeave = React.useRef(null);

  React.useLayoutEffect((): (() => void) => {
    const stage = refStage.current;

    if (stage !== null) {
      const container = stage.container();
      const stag = stage.getStage();

      if (refHandleMouseLeave.current === null) {
        refHandleMouseLeave.current = (event: MouseEvent): void => {
          event.preventDefault();
          stag.setPointersPositions(event);
        };
      }

      container.addEventListener('mouseleave', refHandleMouseLeave.current);
    }

    return (): void => {
      if (stage !== null) {
        stage.container().removeEventListener('mouseleave', refHandleMouseLeave.current);
      }
    };
  }, [refStage]);
}
