import { Color } from '@pushwoosh/kit-constants';
import styled from 'styled-components';

export const OrSeparator = styled.div`
  width: 100%;
  height: 32px;
  font-size: 12px;
  color: ${Color.LOCKED};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
`;
