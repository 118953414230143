import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgAbSplitPoint(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 14a2 2 0 11-4 0 2 2 0 014 0zm3 0a5 5 0 11-10 0 5 5 0 0110 0zm8.59-2.762a1.5 1.5 0 01.632 2.025l-7.787 14.865a1.5 1.5 0 01-2.657-1.392l7.786-14.865a1.5 1.5 0 012.025-.633zM29 28a2 2 0 100-4 2 2 0 000 4zm0 3a5 5 0 100-10 5 5 0 000 10z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgAbSplitPoint;
