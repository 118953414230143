import * as React from 'react';
import Mousetrap from 'mousetrap';

// Store Hooks
import {
  useCloseAllModals,
  usePopupMenuStore,
  useSetActivePoint,
  useDeletePoint,
  useOpenNewModal,
  useActivePointIds,
  useModeMap,
  useErrorsPoint,
  useAddPointIdsToBuffer,
  useClonePointsFromBuffer,
  useUpdateJourneyById,
  useCurrentJourneyId
} from '@src/store/hooks';

// UI Hooks
import { useUIModeFlags } from '@src/store/hooks/ui';

// Enums
import { ModalType } from '@src/common/constants/modals';
import { useStoreActions, useStoreState } from '@src/store/store';

export function useKeyPressService(): void {
  const {
    isOpenedModal,
    isOpenedPopupMenu,
    isSelectedPoint,
    isErrorsPaginate,
    isBufferPointIdsNotEmpty
  } = useUIModeFlags();
  const { isRead } = useModeMap();
  const journeyId = useCurrentJourneyId();
  const isOpenedDocumentation = useStoreState((state) => state.documentationMenu.isVisible);

  const closeAllModals = useCloseAllModals();
  const { close: closePopupMenu } = usePopupMenuStore();
  const setActivePoint = useSetActivePoint();
  const deletePoint = useDeletePoint();
  const openModal = useOpenNewModal();
  const { closePointErrorsPaginate } = useErrorsPoint();
  const addPointIdsToBuffer = useAddPointIdsToBuffer();
  const clonePointsFromBuffer = useClonePointsFromBuffer();
  const [updateJourneysById, isLoadingUpdateJourneyById] = useUpdateJourneyById();
  const closeDocumentationMenu = useStoreActions((actions) => actions.documentationMenu.close);
  const selectAllPoints = useStoreActions((actions) => actions.points.selectAllPoints);

  const undoPoints = useStoreActions((actions) => actions.points.undo);

  const activePointIds = useActivePointIds();

  React.useEffect((): (() => void) => {
    Mousetrap.bind('esc', (): boolean => {
      if (isOpenedDocumentation) {
        closeDocumentationMenu();
        return false;
      }

      if (isOpenedModal) {
        closeAllModals();
        return false;
      }

      if (isRead) {
        return true;
      }

      if (isOpenedPopupMenu) {
        closePopupMenu();
        return false;
      }

      if (isErrorsPaginate) {
        closePointErrorsPaginate();
        return false;
      }

      if (isSelectedPoint) {
        setActivePoint(null);
        return false;
      }

      return true;
    });

    return (): void => {
      Mousetrap.unbind('esc');
    };
  }, [
    isRead,
    isOpenedModal,
    isOpenedPopupMenu,
    isErrorsPaginate,
    isSelectedPoint,
    isOpenedDocumentation,
    closeAllModals,
    closePopupMenu,
    closePointErrorsPaginate,
    closeDocumentationMenu,
    setActivePoint
  ]);

  React.useEffect((): (() => void) => {
    Mousetrap.bind(['del', 'backspace'], (): boolean => {
      if (isRead) {
        return true;
      }

      if (isSelectedPoint) {
        activePointIds.map((pointId) => deletePoint(pointId));
        return false;
      }

      return true;
    });

    return (): void => {
      Mousetrap.unbind(['del', 'backspace']);
    };
  }, [isRead, isSelectedPoint, activePointIds, deletePoint]);

  React.useEffect((): (() => void) => {
    Mousetrap.bind('enter', (): boolean => {
      if (isSelectedPoint && activePointIds.length === 1) {
        openModal({
          type: isRead ? ModalType.DETAIL_POINT : ModalType.EDITING_FORM_POINT,
          data: {
            pointId: activePointIds[0]
          }
        });
        return false;
      }

      return true;
    });

    return (): void => {
      Mousetrap.unbind('enter');
    };
  }, [isRead, isSelectedPoint, activePointIds, openModal]);

  React.useEffect((): (() => void) => {
    Mousetrap.bind(['ctrl+a', 'command+a'], (e): boolean => {
      e.preventDefault();

      if (isRead) {
        return true;
      }

      selectAllPoints();

      return true;
    });
    Mousetrap.bind(['ctrl+c', 'command+c'], (): boolean => {
      if (isRead) {
        return true;
      }

      if (isSelectedPoint) {
        addPointIdsToBuffer(activePointIds);
        return false;
      }

      return true;
    });

    return (): void => {
      Mousetrap.unbind(['ctrl+c', 'command+c']);
    };
  }, [isRead, isSelectedPoint, activePointIds, addPointIdsToBuffer, selectAllPoints]);

  React.useEffect((): (() => void) => {
    Mousetrap.bind(['ctrl+v', 'command+v'], (): boolean => {
      if (isRead) {
        return true;
      }

      if (isBufferPointIdsNotEmpty) {
        clonePointsFromBuffer();
        return false;
      }

      return true;
    });

    return (): void => {
      Mousetrap.unbind(['ctrl+v', 'command+v']);
    };
  }, [isRead, isBufferPointIdsNotEmpty, clonePointsFromBuffer]);

  React.useEffect((): (() => void) => {
    Mousetrap.bind(['ctrl+z', 'command+z'], (): boolean => {
      if (isRead) {
        return true;
      }

      undoPoints();

      return false;
    });

    return (): void => {
      Mousetrap.unbind(['ctrl+z', 'command+z']);
    };
  }, [isRead, undoPoints]);

  React.useEffect((): (() => void) => {
    Mousetrap.bind(['ctrl+d', 'command+d'], (): boolean => {
      if (isRead) {
        return true;
      }

      if (isSelectedPoint) {
        addPointIdsToBuffer(activePointIds);
        clonePointsFromBuffer();
        return false;
      }

      return true;
    });

    return (): void => {
      Mousetrap.unbind(['ctrl+d', 'command+d']);
    };
  }, [isRead, isSelectedPoint, activePointIds, addPointIdsToBuffer, clonePointsFromBuffer]);

  React.useEffect((): (() => void) => {
    Mousetrap.bind(['ctrl+s', 'command+s'], (): boolean => {
      if (isRead) {
        return false;
      }

      if (!isLoadingUpdateJourneyById) {
        updateJourneysById(journeyId);
      }

      return false;
    });

    return (): void => {
      Mousetrap.unbind(['ctrl+s', 'command+s']);
    };
  }, [journeyId, isRead, isSelectedPoint, isLoadingUpdateJourneyById, updateJourneysById]);
}
