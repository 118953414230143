import * as React from 'react';

// Components
import { ManualTagsList } from '@src/ui/features/SetTags/components/ManualTagsList';
import { DynamicTagList } from '@src/ui/features/SetTags/components/DynamicTagList';

// Types
import { Section } from '@src/ui/kit';
import { JourneyStatus } from '@src/common/constants';
import { EditFormProps } from './EditFormSetTags.types';

export function EditFormSetTags(props: EditFormProps): JSX.Element {
  const {
    applicationCode,
    isLoadTags = false,
    existTags,
    names,
    values,
    setFieldValue,
    setFieldTouched,
    setValues,
    onBlurField,
    onChangeField,
    journeyStatus
  } = props;

  const removeDisabled = journeyStatus === JourneyStatus.RUNNING && values.updateTags.length === 1;

  return (
    <>
      <Section>
        {applicationCode && !isLoadTags && (
          <ManualTagsList
            existTags={existTags}
            values={values}
            names={names}
            setFieldValue={setFieldValue}
            setValues={setValues}
            setFieldTouched={setFieldTouched}
            onBlurField={onBlurField}
            onChangeField={onChangeField}
            removeDisabled={removeDisabled}
          />
        )}
      </Section>
      <Section>
        {applicationCode && !isLoadTags && (
          <DynamicTagList
            existTags={existTags}
            applicationCode={applicationCode}
            values={values}
            names={names}
            setFieldValue={setFieldValue}
            setValues={setValues}
            setFieldTouched={setFieldTouched}
            onBlurField={onBlurField}
            onChangeField={onChangeField}
            removeDisabled={removeDisabled}
          />
        )}
      </Section>
    </>
  );
}
