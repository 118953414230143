import React from 'react';
import { H4 } from '@pushwoosh/kit-typography';
import { useJourneyAppCode, useCurrentJourneyId, useLoadEvents } from '@src/store/hooks';
import { Placeholder } from '../Placeholder';
import { PlaceholdersWrap, Header } from './styled';
import { PlaceholdersFormProps } from './PlaceholdersForm.types';

export function PlaceholdersForm(props: PlaceholdersFormProps): JSX.Element {
  const { placeholders, onSelectPlaceholder, onSelectEvent, onSelectAttribute } = props;
  const [loadedAppCodes, setLoadedAppCodes] = React.useState(new Map());
  const [isLoadAppCodes, setIsLoadAppCodes] = React.useState(new Map());
  const [loadEventsByAppCode] = useLoadEvents();
  const journeyId = useCurrentJourneyId();
  const applicationCode = useJourneyAppCode(journeyId);

  const handleLoadAppCode = React.useCallback(
    (appCode: string) => {
      const loadAppCodes = loadedAppCodes.get(appCode);
      if (!loadAppCodes) {
        setIsLoadAppCodes(new Map(isLoadAppCodes.set(appCode, true)));
        loadEventsByAppCode(appCode).then((res) => {
          setLoadedAppCodes(new Map(loadedAppCodes.set(appCode, true)));
          isLoadAppCodes.delete(appCode);
          setIsLoadAppCodes(new Map(isLoadAppCodes));
          return res;
        });
      }
    },
    [isLoadAppCodes, loadedAppCodes, setLoadedAppCodes, setIsLoadAppCodes, loadEventsByAppCode]
  );

  return (
    <PlaceholdersWrap>
      <Header>
        <H4>Overwrite placeholder</H4>
        <H4>Source event</H4>
        <H4>Dynamic attribute</H4>
      </Header>
      {placeholders.map((placeholder, index) => (
        <Placeholder
          key={placeholder.name}
          placeholder={placeholder}
          index={index}
          applicationCode={applicationCode}
          onSelectPlaceholder={onSelectPlaceholder}
          onSelectEvent={onSelectEvent}
          onSelectAttribute={onSelectAttribute}
          handleLoadAppCode={handleLoadAppCode}
        />
      ))}
    </PlaceholdersWrap>
  );
}
