import React from 'react';
import { JourneyStatus } from '@src/common/constants/journey';
import { useCurrentJourneyId, useGetJourneyById } from '@src/store/hooks';
import { useConfirmDeactivateJourney } from '@src/ui/features/Modals/Confirm';
import { useCloneJourney } from '@src/ui/features/JourneyList/components/JorneyControls/hooks/useCloneJourney';
import { useStoreActions, useStoreState } from '@src/store/store';
import { StartButton } from '../components/StartButton';
import { StopButton } from '../components/StopButton';
import { CloneButton } from '../components/CloneButton';
import { ResumeButton } from '../components/ResumeButton';
import { useStartJourney } from '../hooks/useStartJourney';

export function LaunchButton(): JSX.Element {
  const confirmDeactivateJourneyHandler = useConfirmDeactivateJourney();
  const currentJourneyId = useCurrentJourneyId();
  const { startJourney, isLoading, isLoadingConfirm } = useStartJourney();
  const getJourneyById = useGetJourneyById();
  const journey = getJourneyById(currentJourneyId);
  const cloneJourney = useCloneJourney(currentJourneyId);
  const resumeById = useStoreActions((actions) => actions.journeys.resumeById);
  const request = useStoreState((state) => state.requests.entities['journeys.resumeById']);
  const status = journey?.status;

  switch (status) {
    case JourneyStatus.DRAFT: {
      return (
        <StartButton
          isLoadingConfirm={isLoading || isLoadingConfirm}
          activateJourney={startJourney}
        />
      );
    }

    case JourneyStatus.RUNNING: {
      return <StopButton deactivateJourney={confirmDeactivateJourneyHandler} />;
    }

    case JourneyStatus.FINISHED:
    case JourneyStatus.ARCHIVED: {
      return <CloneButton cloneJourney={cloneJourney} />;
    }

    case JourneyStatus.PAUSED: {
      return <ResumeButton resumeJourney={resumeById} isLoadingConfirm={Boolean(request)} />;
    }

    default: {
      return null;
    }
  }
}
