import React from 'react';

// Helpers
import { formatNumber } from '@src/common/helpers';

// Components
import { IconEnum } from '@src/ui/kit';
import {
  useCurrentJourneyId,
  useIsSetUpGoal,
  useModals,
  useOpenNewModal,
  useStoreStatisticsByJourney
} from '@src/store/hooks';
import { useStoreState } from '@src/store/store';
import { ModalType } from '@src/common/constants';
import { TimePeriod } from '@src/common/constants/stats';
import { useCloseModalByType } from '@src/store/hooks/modals/useCloseModalByType';
import { Skeleton } from '../Skeleton';
import { StatBarItem } from '../StatBarItem';
import { PeriodButton } from '../PeriodButton';

// Styled
import { BottomItem, StyledButton, StyledStatBar } from './styled';

export function StatBar(): JSX.Element {
  const openedModals = useModals();
  const [fullstatOpened, setFullstatOpened] = React.useState(false);
  const statistics = useStoreStatisticsByJourney();
  const isSetUpGoal = useIsSetUpGoal();
  const closeModalByType = useCloseModalByType();
  const isLoadingStatistics = useStoreState((state) => state.statistics.isLoading);
  const periodSettings = useStoreState((state) => state.statistics.periodSettings);

  const openNewModal = useOpenNewModal();
  const journeyId = useCurrentJourneyId();

  const { total, achievedGoal, inside, leftByError, avgCTR, uniqueUsersCount } = statistics;

  React.useEffect(() => {
    const isFullStatOpened =
      openedModals.filter((modal) => modal.type === ModalType.FULL_STATISTIC).length > 0;

    setFullstatOpened(isFullStatOpened);
  }, [openedModals]);

  const handleOpenFullStatistic = () => {
    if (fullstatOpened) {
      setFullstatOpened(false);
      closeModalByType(ModalType.FULL_STATISTIC);
    } else {
      setFullstatOpened(true);
      openNewModal({
        type: ModalType.FULL_STATISTIC,
        data: {
          journeyId
        }
      });
    }
  };

  return (
    <StyledStatBar>
      {isLoadingStatistics ? (
        <Skeleton />
      ) : (
        <>
          <PeriodButton />
          <StatBarItem
            value={formatNumber(total)}
            label="Total entries"
            isBorderBottom
            tooltipData="Shows how many entered the journey overall"
          />
          {periodSettings.period === TimePeriod.ALL_TIME && (
            <StatBarItem
              value={formatNumber(uniqueUsersCount)}
              label="Users engaged"
              tooltipData="Shows how many unique users entered the journey. If user entered the journey several times this user still will be counted as one."
              isBorderBottom
            />
          )}
          <StatBarItem value={formatNumber(inside)} label="Inside the journey" isBorderBottom />
          {isSetUpGoal ? (
            <StatBarItem value={formatNumber(achievedGoal)} label="Achieved goals" isBorderBottom />
          ) : null}
          <StatBarItem
            value={`${formatNumber(avgCTR)}%`}
            label="Average CTR"
            tooltipData="Shows average CTR per all messages sent through this particular Journey"
            isBorderBottom
          />
          <StatBarItem
            value={formatNumber(leftByError)}
            label="User drop-offs"
            tooltipData="Shows users and their devices removed from the Journey due to some issues."
            isBorderBottom
          />
          <BottomItem>
            <StyledButton
              view="ghost"
              color="secondary"
              size="field"
              onClick={handleOpenFullStatistic}
              iconType={fullstatOpened ? IconEnum.CHEVRON_DOUBLE_REVERSE : IconEnum.CHEVRON_DOUBLE}
              iconPosition={fullstatOpened ? 'left' : 'right'}
            >
              {fullstatOpened ? 'Hide Details' : 'Show Details'}
            </StyledButton>
          </BottomItem>
        </>
      )}
    </StyledStatBar>
  );
}
