import { defaultResponseMiddleware, BadRequestError } from '@pushwoosh/http-client';
import { sendMixpanelEvent } from '@src/common/analytics';

import { ErrorType } from '@src/common/constants/api';
import { INotification } from '@src/common/types/notification';
import { NotificationType, NOTIFICATION_TITLE_FAIL } from '@src/common/constants/notification';
import { SidebarType } from '@src/common/constants/sidebars';

type JSONResponse<T> = {
  readonly result: T;
  readonly code: number;
  readonly message: string;
};

export async function validateResponse<T>(response: any, errorsPoint: any, sidebars: any) {
  if (response.status === 400) {
    const notificationMessages =
      response?.result?.error?.data
        ?.filter((item: any): boolean => item.type === ErrorType.JOURNEY)
        ?.map(
          (item: any): INotification => ({
            title: NOTIFICATION_TITLE_FAIL.activateJourney || '',
            message: item.message,
            type: NotificationType.ERROR
          })
        ) ?? [];

    if (notificationMessages.length > 0) {
      sendMixpanelEvent({
        eventName: 'ErrorsLogJourney',
        eventData: {
          ErrorMessage: notificationMessages[0].message
        }
      });
    }

    const errorPointsItems = response.result.error.data.filter(
      (item: any): boolean => item.type === ErrorType.POINT
    );

    if (errorPointsItems.length > 0) {
      sendMixpanelEvent({
        eventName: 'ErrorsLogJourney',
        eventData: {
          ErrorMessage: errorPointsItems[0].message
        }
      });

      errorsPoint.add(errorPointsItems);
      errorsPoint.setVisibleErrorsPaginate(true);
      sidebars.openNewSidebar({
        type: SidebarType.VALIDATION
      });
    }

    if (notificationMessages.length > 0) {
      errorsPoint.add(notificationMessages);
      errorsPoint.setVisibleErrorsPaginate(true);
      sidebars.openNewSidebar({
        type: SidebarType.VALIDATION
      });
    }

    const jsonResponse = defaultResponseMiddleware<JSONResponse<T>>(response);

    throw new BadRequestError(jsonResponse.message, response.details);
  }
  return response.result as never;
}
