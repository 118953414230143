import React from 'react';
import { ExtraOneButton, Modal, Section, FormField, ItemDetails } from '@src/ui/kit';
import { ModalType, REACHABILITY_CHECK_TYPES } from '@src/common/constants';
import { useCloseModalByType } from '@src/store/hooks/modals/useCloseModalByType';
import { DropOffStat } from '../../DropOffStat';

interface IProps {
  filterType: string;
  pointId: string;
}

export function DetailReachabilityCheck(props: IProps): JSX.Element {
  const { filterType, pointId } = props;

  const closeModalByType = useCloseModalByType();

  return (
    <Modal
      title={<span>Reachability Check</span>}
      footerRight={
        <ExtraOneButton
          actionButtonName="Close"
          onClickActionButton={() => closeModalByType(ModalType.DETAIL_POINT)}
        />
      }
      isOpen
    >
      <Section direction="column">
        <ItemDetails>
          <DropOffStat pointId={pointId} />
        </ItemDetails>
      </Section>
      <Section direction="column">
        <ItemDetails>
          <FormField
            value={filterType === REACHABILITY_CHECK_TYPES.EMAIL ? 'Email' : 'Push Notifications'}
            label="Reachable by:"
          />
        </ItemDetails>
      </Section>
    </Modal>
  );
}
