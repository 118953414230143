import * as React from 'react';

// Store Hooks
import { useCloseAllModals, useRenameJourneyById, useLoadJourneyById } from '@src/store/hooks';

// UI Components
import { Section } from '@src/ui/kit';
import { Paragraph } from '@pushwoosh/kit-typography';
import { FormBlock } from '@src/ui/form/styled';
import { Modal } from '@src/ui/kit/Modal';
import { DefaultButtons } from '@src/ui/kit/ModalButtons';
import {
  EditTitle,
  EditTitleValues
} from '@src/ui/features/Modals/RenameJourney/components/EditTitle';

// Helpers
import { useForm } from '@src/ui/hooks';
import { TFormErrors } from '@src/ui/hooks/useForm';
import { IUniqueId } from '@src/common/types/entities';

interface IProps {
  journeyId: IUniqueId['id'];
  title: string;
  beforeLoadJourney?: boolean;
  actionButtonName?: string;
}

export function ModalRenameJourney({
  journeyId,
  title,
  beforeLoadJourney,
  actionButtonName
}: IProps): JSX.Element {
  const closeAllModals = useCloseAllModals();
  const [renameJourneysById, isUpdatingJourney] = useRenameJourneyById();
  const [loadJourneyById] = useLoadJourneyById();

  const {
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit
  } = useForm<EditTitleValues>({
    initialValues: {
      title
    },
    validate(values): TFormErrors<EditTitleValues> {
      const errors: TFormErrors<EditTitleValues> = {};

      if (!values.title) {
        errors.title = 'Name is required';
      }

      return errors;
    },
    onSubmit: async (formValues): Promise<void> => {
      if (beforeLoadJourney) {
        await loadJourneyById(journeyId);
      }

      renameJourneysById(journeyId, {
        title: formValues.title
      });

      closeAllModals();
    }
  });

  return (
    <Modal
      title="Rename journey"
      widthType="short"
      footerRight={
        <DefaultButtons
          isDisabledActionButton={isUpdatingJourney}
          actionButtonName={actionButtonName}
          onClickActionButton={handleSubmit}
          onClickCancelButton={closeAllModals}
        />
      }
      isOpen
    >
      <Section direction="column">
        <Paragraph>
          Use clear and distinctive names for your journeys to easily manage them further.
        </Paragraph>
        <FormBlock>
          <EditTitle
            values={values}
            errors={errors}
            touched={touched}
            names={{
              title: 'title'
            }}
            labelInput="New Name"
            onBlurField={handleBlur}
            onChangeField={handleChange}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            onSubmit={handleSubmit}
          />
        </FormBlock>
      </Section>
    </Modal>
  );
}
