import { PointType } from '@src/common/constants';
import { PremiumFeatures, PremiumModalType } from '@src/ui/features/Modals/PremiumModal/types';

export const getModalTitle = (premiumType: PremiumModalType) => {
  switch (premiumType) {
    case PointType.API:
      return 'Upgrade to Custom plan to use Webhook-based entry';
    case PointType.SEND_IN_APP:
      return 'Upgrade to Custom plan to use In-apps';
    case PointType.SEND_SMS:
      return 'Upgrade to Custom plan to use SMS messages';
    case PointType.WEBHOOK:
      return 'Upgrade to Custom plan to use Webhooks';
    case PremiumFeatures.OVERWRITE:
      return 'Upgrade to Custom plan to overwrite message content';
    case PremiumFeatures.SCHEDULED:
      return 'Upgrade to Custom plan to use scheduled launches';
    case PremiumFeatures.DYNAMIC:
      return 'Upgrade to Custom plan to use dynamic time delay';
    default:
      return 'Need more?';
  }
};

export const getModalDescription = (premiumType: PremiumModalType) => {
  switch (premiumType) {
    case PointType.API:
      return 'Launch journeys by external triggers: for example, when a product is back in stock or a new podcast episode is released. A journey is started by sending an API request where you can specify the target audience and overwrite message texts.';
    case PointType.SEND_IN_APP:
      return 'Interact with users more efficiently by showing them relevant and deeply personalized in-app messages. ';
    case PointType.SEND_SMS:
      return 'Use SMS for more efficient communication: contact customers who are not reachable via other channels, send transactional notifications, and make marketing offers.';
    case PointType.WEBHOOK:
      return 'Use webhooks to share data with just about any other service: WhatsApp, Slack, CRM systems, marketing automation platforms, and more.';
    case PremiumFeatures.OVERWRITE:
      return 'Use event attributes instead of device tags to personalize messages for each user. You can select event attributes for personalization both in content and preset settings.';
    case PremiumFeatures.SCHEDULED:
      return 'Save your time and efforts with automated journeys based on your segments. Adjust the frequency of getting users from a segment to a journey.';
    case PremiumFeatures.DYNAMIC:
      return 'Set a time delay dynamically based on what you already know about users or actions they perform in the app while traveling the customer journey.';
    default:
      return 'We have one!';
  }
};
