import * as React from 'react';

// Store Hooks
import { useLoadEvents, useGetEventsByApplicationCode } from '@src/store/hooks';

// Types
import { TEvent } from '@src/common/types/event';

export function useEventsByAppCodeWithAutoLoad(appCode: string): [TEvent[], boolean] {
  const getEventsByApplicationCode = useGetEventsByApplicationCode();
  const events = getEventsByApplicationCode(appCode);
  const [loadEventsByAppCode, isLoadingEvents] = useLoadEvents();

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (appCode && !isLoadingEvents) {
      loadEventsByAppCode(appCode);
    }
  }, [appCode]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return [events, isLoadingEvents];
}
