// Store
import { useStoreState } from '@src/store/store';

type TResult = boolean;

export function useIsSetUpGoal(): TResult {
  const isSetUpGoal: boolean = useStoreState<boolean>((state): boolean => state.points.isSetUpGoal);

  return isSetUpGoal;
}
