import { Color } from '@pushwoosh/kit-constants';

export function getChartStroke(isCurrent: boolean, isWinner: boolean, isDisabled: boolean) {
  if (isDisabled) {
    return Color.LOCKED;
  }

  if (isWinner && isCurrent) {
    return Color.SUCCESS;
  }

  if (isCurrent) {
    return Color.PRIMARY;
  }

  return Color.DIVIDER;
}
