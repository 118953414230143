import * as React from 'react';

// Components
import { DocElements } from '@src/ui/features/Documentation/components/DocElements';

export function DocumentationGoals(): JSX.Element {
  return (
    <DocElements>
      <DocElements.Paragraph>
        <strong>Goals </strong>
        let you measure the effectiveness of your Customer Journey campaign. A
        <strong> Goal </strong>
        is a target event indicating that the customer reaches the marketing objective you set.
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        To define a goal, press the
        <strong> Set Goal </strong>
        button on the top of the page.
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-goals_img-1.png"
        label="Journey activation"
        alt="Journey activation"
      />

      <DocElements.Paragraph>
        To cancel remaining messages on a Goal event trigger, check the
        <strong> End journey after this event </strong>
        {
          "checkbox. Messages that haven't been sent to the moment the Goal event triggers won't be sent to the customer who reached the goal."
        }
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        {
          "For example, if the delay time hasn't expired yet, and no messages are sent, all of them are canceled. If some of the messages have been sent already, the waiting ones are canceled."
        }
      </DocElements.Paragraph>
    </DocElements>
  );
}
