import styled from 'styled-components';
import { FontWeight } from '@pushwoosh/kit-constants';

export const Title = styled.div`
  display: inline-block;
  max-width: calc(100% - 16px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: ${FontWeight.MEDIUM};
  cursor: pointer;
`;

export const TooltipBox = styled.div`
  overflow-wrap: break-word;
`;
