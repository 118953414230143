import { action } from 'easy-peasy';

// Constants
import {
  ZOOM_DEFAULT,
  ZOOM_MAX_RANGE,
  ZOOM_MIN_RANGE,
  ZOOM_STEP
} from '@src/common/constants/canvas';

// Types
import { ICanvasModel } from './canvas.types';

export const model: ICanvasModel = {
  zoom: ZOOM_DEFAULT,
  arrowHoveredToDelete: null,
  isSelectionVisible: false,

  changeZoom: action((state, payload): void => {
    const { direction, newScale } = payload;
    const isIncrement = direction > 0 && state.zoom <= ZOOM_MAX_RANGE;
    const isDecrement = direction < 0 && state.zoom >= ZOOM_MIN_RANGE;

    if (isIncrement) {
      state.zoom = newScale <= ZOOM_MAX_RANGE ? newScale : ZOOM_MAX_RANGE;
    }

    if (isDecrement) {
      state.zoom = newScale >= ZOOM_MIN_RANGE ? newScale : ZOOM_MIN_RANGE;
    }
  }),

  resetZoom: action((state): void => {
    state.zoom = ZOOM_DEFAULT;
  }),

  incrementZoom: action((state): void => {
    const newZoom = state.zoom + ZOOM_STEP;

    state.zoom = newZoom <= ZOOM_MAX_RANGE ? newZoom : ZOOM_MAX_RANGE;
  }),

  decrementZoom: action((state): void => {
    const newZoom = state.zoom - ZOOM_STEP;

    state.zoom = newZoom >= ZOOM_MIN_RANGE ? newZoom : ZOOM_MIN_RANGE;
  }),

  setArrowHoveredToDelete: action((state, payload): void => {
    state.arrowHoveredToDelete = payload;
  }),

  setSelectionVisible: action((state, payload) => {
    state.isSelectionVisible = payload;
  })
};
