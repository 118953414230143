import * as React from 'react';

// Components
import { Cell } from '@src/ui/features/JourneyList/components/Table/styled';

// Types
import { ColumnConstant } from '@src/ui/features/JourneyList/components/Table/Table.types';

// Styled
import { Skeleton } from './styled';

const LOADER_SKELETON_CELL = Array.from({ length: 10 }, (_, index) => index + 1);

export const LoaderSkeleton = (): JSX.Element => (
  <>
    {LOADER_SKELETON_CELL.map((_, index) => (
      <React.Fragment key={index}>
        <Cell>
          <Skeleton size={ColumnConstant.TITLE} even={index % 2 === 0} />
        </Cell>
        <Cell>
          <Skeleton size={ColumnConstant.STATUS} even={index % 2 === 0} />
        </Cell>
        <Cell>
          <Skeleton size={ColumnConstant.DATA_MODIFIED} even={index % 2 === 0} />
        </Cell>
        <Cell>
          <Skeleton size={ColumnConstant.CATEGORY} even={index % 2 === 0} />
        </Cell>
        <Cell>
          <Skeleton size={ColumnConstant.TOTAL_ENTRIES} even={index % 2 === 0} />
        </Cell>
        <Cell>
          <Skeleton size={ColumnConstant.AVR_CTR} even={index % 2 === 0} />
        </Cell>
        <Cell>
          <Skeleton size={ColumnConstant.GOAL_REACHED} even={index % 2 === 0} />
        </Cell>
        <Cell>&nbsp;</Cell>
      </React.Fragment>
    ))}
  </>
);
