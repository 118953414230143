// Store
import { useStoreState } from '@src/store/store';

// Types
import { Pagination } from '@src/common/types/pagination';

export function usePaginationJourneys(): Pagination {
  const pagination: Pagination = useStoreState((state): Pagination => state.journeys.pagination);

  return pagination;
}
