import * as React from 'react';

// Types
import { TSelectedProps } from '@src/ui/kit/Select';
import { Props } from '@src/ui/kit/AutoComplete/AutoComplete.types';

const KEY_ENTER = 13;

type Result = {
  selectInputValue: string;
  setSelectInputValue: (value: string) => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

export const useAddCustomValue = (
  value: TSelectedProps['value'],
  onChange: Props['onChange'],
  isMulti: Props['isMulti']
): Result => {
  const [selectInputValue, setSelectInputValue] = React.useState<string>('');

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (!isMulti || event.keyCode !== KEY_ENTER || !Array.isArray(value)) {
        return;
      }
      const newValue = (event.target as HTMLInputElement).value;
      const isDuplicateValue = value.some((item): boolean => item.value === newValue);

      if (!isDuplicateValue && newValue !== '') {
        const newValues = [...value, { label: newValue, value: newValue }];
        onChange(newValues);
        setSelectInputValue('');
      }
    },
    [isMulti, value, onChange]
  );

  return { handleKeyDown, selectInputValue, setSelectInputValue };
};
