import React from 'react';
import { Group, Shape } from 'react-konva';

const DOT_SIZE = 1;

type Props = {
  x: number;
  y: number;
  width: number;
  height: number;
  zoom: number;
};

export function Cell(props: Props): JSX.Element {
  const { x, y, width, height, zoom } = props;
  const dotSize = zoom <= 1 ? DOT_SIZE / zoom : DOT_SIZE;

  return (
    <Group x={x} y={y}>
      <Shape
        x={0}
        y={0}
        width={width}
        height={height}
        perfectDrawEnabled={false}
        fill="#C5D7EB"
        shadowForStrokeEnabled={false}
        sceneFunc={(context, shape) => {
          context.beginPath();

          for (let x = 0; x < width; x += width / 10) {
            for (let y = 0; y < height; y += height / 10) {
              context.moveTo(x, y);
              context.arc(x, y, dotSize, 0, Math.PI * 2, true);
            }
          }

          context.fillStrokeShape(shape);
        }}
      />
    </Group>
  );
}
