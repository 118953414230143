import React from 'react';
import { useParams } from 'react-router-dom';

// Components
import { FormSelect, FormInput } from '@src/ui/form';
import { FormBlock } from '@src/ui/form/styled';

// Types
import { ICreateJourneyFormProps } from './CreateJourneyForm.types';

// Styled
import { Container } from './styled';

export function CreateJourneyForm(props: ICreateJourneyFormProps): JSX.Element {
  const {
    values,
    touched,
    errors,
    applicationSelectOptions,
    isLoadingApplications,
    setFieldValue,
    handleBlur,
    setFieldTouched
  } = props;
  const params = useParams<{ applicationCode: string }>();
  const appLink = params.applicationCode
    ? 'https://app.pushwoosh.com/applications'
    : 'https://go.pushwoosh.com/cp/applications';

  return (
    <Container>
      <FormInput
        label="Journey Name"
        name="title"
        value={values.title}
        error={touched.title ? errors.title : ''}
        placeholder=""
        onChange={setFieldValue}
        onBlur={handleBlur}
      />
      {!params.applicationCode && (
        <FormBlock>
          <FormSelect
            label="Application"
            name="applicationCode"
            url={appLink}
            value={values.applicationCode}
            error={touched.applicationCode ? errors.applicationCode : ''}
            options={applicationSelectOptions}
            placeholder="Select application"
            isLoading={isLoadingApplications}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
          />
        </FormBlock>
      )}
    </Container>
  );
}
