export enum PointType {
  EXIT = 'exit',
  WAIT = 'wait',
  EVENT = 'event',
  API = 'start-by-api',
  SEGMENT = 'start-by-segment',
  SEND_PUSH = 'send-push',
  SEND_SMS = 'send-sms',
  SEND_EMAIL = 'send-email',
  SEND_IN_APP = 'inapp',
  BOOLEAN_SPLITTER = 'boolean-splitter',
  WAIT_EVENT = 'wait-event',
  AB_SPLITTER = 'ab-splitter',
  SET_TAGS = 'set-tags',

  GOAL_EVENT = 'goal-event',
  GOAL_TAG = 'goal-tag',
  WEBHOOK = 'webhook',
  REACHABILITY_CHECK = 'filter'
}

export const ENTRY_GROUP = new Set([PointType.EVENT, PointType.SEGMENT, PointType.API]);

export const CHANNEL_GROUP = new Set([
  PointType.SEND_PUSH,
  PointType.SEND_EMAIL,
  PointType.SEND_IN_APP,
  PointType.SEND_SMS,
  PointType.WEBHOOK
]);

export const FLOW_GROUP = new Set([
  PointType.EXIT,
  PointType.REACHABILITY_CHECK,
  PointType.WAIT_EVENT,
  PointType.WAIT,
  PointType.BOOLEAN_SPLITTER,
  PointType.AB_SPLITTER,
  PointType.SET_TAGS
]);

export const GOAL_GROUP = new Set([PointType.GOAL_EVENT, PointType.GOAL_TAG]);

export const POINTS_WITH_INPUTS = new Set([
  PointType.SEND_PUSH,
  PointType.SEND_EMAIL,
  PointType.SEND_SMS,
  PointType.SEND_IN_APP,
  PointType.BOOLEAN_SPLITTER,
  PointType.WAIT_EVENT,
  PointType.WAIT,
  PointType.AB_SPLITTER,
  PointType.SET_TAGS,
  PointType.EXIT,
  PointType.WEBHOOK,
  PointType.REACHABILITY_CHECK
]);

export const EDITABLE_POINTS_TYPE = new Set([
  PointType.EVENT,
  PointType.SEGMENT,
  PointType.API,
  PointType.SEND_PUSH,
  PointType.SEND_EMAIL,
  PointType.SEND_SMS,
  PointType.SEND_IN_APP,
  PointType.BOOLEAN_SPLITTER,
  PointType.WAIT_EVENT,
  PointType.SET_TAGS,
  PointType.WAIT,
  PointType.AB_SPLITTER,
  PointType.WEBHOOK,
  PointType.REACHABILITY_CHECK
]);

export const PREMIUM_POINTS_TYPE = new Set([
  PointType.WEBHOOK,
  PointType.SEND_IN_APP,
  PointType.SEND_SMS,
  PointType.SET_TAGS,
  PointType.API
]);

export const REACHABILITY_CHECK_TYPES = {
  EMAIL: 'EMAIL_EXISTS',
  PUSH: 'PUSH_TOKEN_EXISTS'
};

export const CONTROL_GROUP_COLOR = '#00A2FF';
export const ENTRY_GROUP_COLOR = '#479900';
export const CHANNEL_GROUP_COLOR = '#B255E0';
export const DISABLED_GROUP_COLOR = '#9e9e9e';
export const INVALID_COLOR = '#E52E59';

export const COLOR_TRUE = '#479900';
export const COLOR_TRUE_LIGHT = 'rgba(49, 153, 0, 0.10)';

export const COLOR_FALSE = '#F08000';
export const COLOR_FALSE_LIGHT = 'rgba(255, 178, 0, 0.10)';

export const POINT_MENU_DEFAULT = '#89A4BF';

// Sizes of point
export enum GroupSizeFirst {
  'WIDTH' = 160,
  'HEIGHT' = 56
}

export enum GroupSizeSecond {
  'WIDTH' = 56,
  'HEIGHT' = 56
}

// Custom height
export enum GroupSizeThird {
  'WIDTH' = 160,
  'HEIGHT' = 0
}

// Size content of point
export enum GroupSizeContent {
  'WIDTH' = 160,
  'HEIGHT' = 22
}

// Size icon
export enum IconPointDefault {
  'WIDTH' = 40,
  'HEIGHT' = 40
}

export enum IconPointSmall {
  'WIDTH' = 16,
  'HEIGHT' = 22
}

export const DEFAULT_POINT_BLOCK_OFFSET = 8;
export const WIDTH_MENU_POINT = 72;

export const DEFAULT_JSON_VALUE = `{
  "hwid": "{{device:hwid}}"
}`;
