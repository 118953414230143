import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgListSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 5a1 1 0 11-2 0 1 1 0 012 0zM6 8a1 1 0 11-2 0 1 1 0 012 0zM5 12a1 1 0 100-2 1 1 0 000 2zM8 4.25a.75.75 0 000 1.5h4a.75.75 0 000-1.5H8zM7.25 8A.75.75 0 018 7.25h4a.75.75 0 010 1.5H8A.75.75 0 017.25 8zM8 10.25a.75.75 0 000 1.5h4a.75.75 0 000-1.5H8z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgListSmallLined;
