import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';
import { Paragraph } from '@pushwoosh/kit-typography';
import { Button } from '@src/ui/kit';

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  margin-top: ${Spacing.S3};
`;

export const CheckboxLabel = styled(Paragraph)`
  margin-left: ${Spacing.S4};
`;

export const ContainerSelects = styled.div`
  display: flex;
  flex-wrap: nowrap;

  & > div {
    width: 50%;
  }
`;

export const RemoveButton = styled(Button)`
  margin-top: 28px;
  margin-left: 8px;
`;
