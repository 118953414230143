import styled, { css } from 'styled-components';
import { darken } from 'polished';

import {
  Spacing,
  FontFamily,
  FontWeight,
  FontSize,
  LineHeight,
  Color,
  ShapeRadius
} from '@pushwoosh/kit-constants';

const CssHover = css`
  border: 1px solid ${darken(0.2, Color.FORM)};
`;

interface TextInputProps {
  isError: boolean;
}

export const TextInputContainer = styled.input.attrs((props) => ({
  disabled: Boolean(props.disabled)
}))`
  width: 100%;

  box-sizing: border-box;

  height: 38px;

  padding: 0px ${Spacing.S4};

  color: ${Color.MAIN};
  font-weight: ${FontWeight.REGULAR};
  font-size: ${FontSize.REGULAR};
  font-family: ${FontFamily.MAIN};
  line-height: ${LineHeight.REGULAR};

  background: ${Color.CLEAR};
  border: 1px solid ${(props: TextInputProps) => (props.isError ? Color.WARNING : Color.FORM)};
  border-radius: ${ShapeRadius.CONTROL};

  outline: none;

  ::placeholder {
    color: ${Color.PHANTOM};
  }

  &:hover {
    ${(props: TextInputProps) => (!props.isError ? CssHover : '')}
  }

  &:focus {
    border: 1px solid ${Color.BRIGHT};
    caret-color: ${Color.BRIGHT};
  }

  &:disabled {
    color: ${Color.LOCKED};

    background: ${Color.FROZEN};

    border: 1px solid ${Color.FORM};

    cursor: not-allowed;
  }
`;
