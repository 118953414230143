import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgTrashSmallFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 3.167a.5.5 0 00-.5.5V4a.5.5 0 00.5.5h9A.5.5 0 0013 4v-.333a.5.5 0 00-.5-.5H9.842a.5.5 0 01-.312-.11l-.56-.447a.5.5 0 00-.312-.11H7.342a.5.5 0 00-.312.11l-.56.447a.5.5 0 01-.312.11H3.5zm1 2.333A.5.5 0 004 6v5.5a2 2 0 002 2h4a2 2 0 002-2V6a.5.5 0 00-.5-.5h-7z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgTrashSmallFilled;
