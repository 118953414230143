import * as React from 'react';
import { useParams } from 'react-router-dom';

// UI Components
import { Section } from '@src/ui/kit';
import { FormSelect } from '@src/ui/form/FormSelect';
import { RichMediaPreviewMemo } from '@src/ui/features/SendInApp/components/RichMediaPreview';

// Types
import { FormNumberStepper } from '@src/ui/form';
import { Header as FormHeader } from '@src/ui/form/FormControl/styled';
import { Paragraph } from '@pushwoosh/kit-typography';
import { ISendInAppFormProps } from './SendInAppForm.types';
import { ExpirationHint, ExpirationSelectContainer, FieldsContainer, Wrapper } from './styled';

export function SendInAppForm(props: ISendInAppFormProps): JSX.Element {
  const {
    applicationCode,
    richMediaSelectOptions,
    names,
    values,
    errors,
    touched,
    isLoadingRichMediaOptions = false,
    setFieldValue,
    setFieldTouched,
    richMedia
  } = props;
  const params = useParams<{ applicationCode: string }>();
  const richMediaUrl = params.applicationCode
    ? `https://app.pushwoosh.com/applications/${applicationCode}/richMedia`
    : `https://go.pushwoosh.com/cp/applications/${applicationCode}/richMedia`;
  const isSelectedApplication = applicationCode;

  return (
    <>
      <Section>
        <Wrapper>
          <FieldsContainer>
            <div>
              <FormSelect
                label="Media Source"
                url={richMediaUrl}
                name={names.richMediaCode}
                value={values.richMediaCode}
                error={touched.richMediaCode && errors.richMediaCode}
                options={richMediaSelectOptions}
                placeholder="Select Rich media"
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                isDisabled={!isSelectedApplication}
                isLoading={isLoadingRichMediaOptions}
                isSearchable
              />
            </div>

            <div>
              <FormHeader>
                <Paragraph>
                  <b>In-app expiration</b>
                </Paragraph>
              </FormHeader>
              <ExpirationSelectContainer>
                <b>after</b>
                <FormNumberStepper
                  name={names.expireInDays}
                  value={typeof values.expireInDays !== 'number' ? 7 : values.expireInDays}
                  min={1}
                  max={999}
                  step={1}
                  dimension="days"
                  onChange={(x) => setFieldValue(names.expireInDays, x)}
                />
              </ExpirationSelectContainer>
              <ExpirationHint>
                Set a time limit for how long an in-app notification is available to the user,
                specifying the number of days it remains active. Once this period expires, if the
                user has not yet viewed the notification, it automatically becomes inaccessible.
              </ExpirationHint>
            </div>
          </FieldsContainer>

          <RichMediaPreviewMemo
            richMediaCode={values.richMediaCode}
            zipContent={richMedia ? richMedia.zipcontent : ''}
            params={richMedia ? richMedia.params : ''}
            language={richMedia ? richMedia.defaultLanguage : ''}
          />
        </Wrapper>
      </Section>
    </>
  );
}
