import React from 'react';

// UI Components
import { Badge, ExtraOneButton, Modal, Section, FormField } from '@src/ui/kit';
import {
  DetailsRow,
  PointTitle,
  TitleDetails,
  ValueInlineDetails,
  ItemDetails
} from '@src/ui/kit/DetailsPoint';

// Helpers
import { defineRepeatValue } from '@src/ui/features/StartSegment/helepers/defineRepeatValue';

// Constants
import { Color } from '@pushwoosh/kit-constants';

// Types
import { TFilter } from '@src/common/types/filter';
import { TSegmentPoint } from '@src/common/types/points';
import { ModalType } from '@src/common/constants/modals';

// Styled
import { addZero } from '@src/common/helpers/formatTime';
import { useCloseModalByType } from '@src/store/hooks/modals/useCloseModalByType';
import { BadgeFrequency, BadgeValue, Gray, TextWithOffset } from '../styled';

interface IProps {
  filterTitle: TFilter['name'];
  filterCode: TFilter['code'];
  entryLimitsEnabled: TSegmentPoint['data']['entryLimitsEnabled'];
  entryLimitsPerSecond: TSegmentPoint['data']['entryLimitsPerSecond'];
  enabledRepeat: TSegmentPoint['data']['repeat']['enabled'];
  frequencyRepeat: TSegmentPoint['data']['repeat']['frequency'];
  everyRepeat: TSegmentPoint['data']['repeat']['every'];
  datesRepeat?: TSegmentPoint['data']['repeat']['dates'];
  timeRepeat?: TSegmentPoint['data']['repeat']['time'];
  activatedAt: string;
  pointTitle: string;
  applicationId: string;
}

export function DetailStartSegment(props: IProps): JSX.Element {
  const {
    filterTitle,
    filterCode,
    entryLimitsEnabled,
    entryLimitsPerSecond,
    enabledRepeat,
    frequencyRepeat,
    everyRepeat,
    datesRepeat,
    activatedAt,
    pointTitle,
    timeRepeat,
    applicationId
  } = props;

  const { label, union, value } = defineRepeatValue({
    enabledRepeat,
    frequencyRepeat,
    everyRepeat,
    datesRepeat
  });
  const closeModalByType = useCloseModalByType();

  return (
    <Modal
      title={
        <>
          Audience-based Entry:&nbsp;
          <PointTitle>{pointTitle}</PointTitle>
        </>
      }
      footerRight={
        <ExtraOneButton
          actionButtonName="Close"
          onClickActionButton={() => closeModalByType(ModalType.DETAIL_POINT)}
        />
      }
      isOpen
    >
      <Section direction="column">
        <ItemDetails>
          <FormField
            label="Audience Source:"
            value={filterTitle}
            url={`https://app.pushwoosh.com/applications/${applicationId}/segments/${filterCode}/detail`}
          />
        </ItemDetails>
      </Section>
      {enabledRepeat && (
        <Section direction="column">
          <DetailsRow>
            <TitleDetails>Scheduled Launch:</TitleDetails>

            {timeRepeat ? (
              <ValueInlineDetails>
                <BadgeFrequency color={Color.MAIN}>
                  {addZero(timeRepeat.hour)}:{addZero(timeRepeat.minute)}
                </BadgeFrequency>

                <TextWithOffset>
                  <Gray>{`by ${timeRepeat.timezone} timezone`}</Gray>
                </TextWithOffset>
              </ValueInlineDetails>
            ) : (
              <ValueInlineDetails>
                <Gray>{activatedAt}</Gray>
              </ValueInlineDetails>
            )}
          </DetailsRow>
          <DetailsRow>
            <TitleDetails>Launch Period:</TitleDetails>
            <ValueInlineDetails>
              <BadgeFrequency color={Color.SUCCESS} isBoldText>
                {label}
              </BadgeFrequency>{' '}
              {union && <TextWithOffset>{union}</TextWithOffset>}{' '}
              {value.map((item, index) => (
                <BadgeValue key={index} color={Color.SOFT} isBoldText>
                  {item}
                </BadgeValue>
              ))}
            </ValueInlineDetails>
          </DetailsRow>
        </Section>
      )}
      {entryLimitsEnabled && (
        <Section>
          <TitleDetails>Rate Limits:</TitleDetails>
          <ValueInlineDetails>
            <Badge color={Color.MAIN}>{entryLimitsPerSecond} devices/sec</Badge>
          </ValueInlineDetails>
        </Section>
      )}
    </Modal>
  );
}
