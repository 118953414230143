// Store
import { useStoreState, useStoreActions } from '@src/store/store';

type TResult = {
  isGettingStarted: boolean;
  setGettingStarted: (value: boolean) => void;
};

export function useGettingStarted(): TResult {
  const isGettingStarted = useStoreState((state): boolean => state.journeys.isGettingStarted);
  const setGettingStarted = useStoreActions(
    (actions): typeof actions.journeys.setGettingStarted => actions.journeys.setGettingStarted
  );

  return { isGettingStarted, setGettingStarted };
}
