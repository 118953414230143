import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPlusRoundMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12c0-4.974 4.025-9 9-9 4.974 0 9 4.025 9 9 0 4.974-4.025 9-9 9-4.974 0-9-4.025-9-9zm9 5a1 1 0 01-1-1v-3H8a1 1 0 110-2h3V8a1 1 0 112 0v3h3a1 1 0 110 2h-3v3a1 1 0 01-1 1z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPlusRoundMediumFilled;
