import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPlaceMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.584 21.735L12 21.11l.416.624a.75.75 0 01-.832 0z" fill={props.fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.917a3.083 3.083 0 100 6.166 3.083 3.083 0 000-6.166zM10.417 11a1.583 1.583 0 113.166 0 1.583 1.583 0 01-3.166 0z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.584 21.735L12 21.11c.416.624.417.623.417.623h.002l.005-.004.018-.012a8.911 8.911 0 00.298-.21 24.416 24.416 0 003.305-2.895c1.78-1.879 3.705-4.59 3.705-7.613a7.75 7.75 0 10-15.5 0c0 3.024 1.926 5.734 3.706 7.613a24.41 24.41 0 003.538 3.06l.065.045.017.012.005.003.003.002zM7.58 6.58A6.25 6.25 0 0118.25 11c0 2.42-1.574 4.766-3.294 6.581A22.914 22.914 0 0112 20.191a22.92 22.92 0 01-2.956-2.61C7.324 15.766 5.75 13.421 5.75 11a6.25 6.25 0 011.83-4.42z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPlaceMediumLined;
