// Store
import { useStoreState, useStoreActions } from '@src/store/store';

// Types
import { IErrorResponse } from '@src/common/types/api';

type TAction = (payload: number) => void;
type TCloseEditMenu = (value?: void) => void;

interface IMapState {
  isVisibleEditMenu: boolean;
  isVisibleErrorsPaginate: boolean;
  errorsPoint: IErrorResponse['data'];
  currentError: number;
}

interface IResult {
  isVisibleErrorsPaginate: boolean;
  errorsPoint: IErrorResponse['data'];
  currentError: number;
  prevError: () => void;
  nextError: () => void;
  setCurrentError: (value: number) => void;
  closePointErrorsPaginate: (payload?: void) => void;
}

export function useErrorsPoint(): IResult {
  const { isVisibleEditMenu, isVisibleErrorsPaginate, errorsPoint, currentError } = useStoreState(
    (state): IMapState => ({
      isVisibleEditMenu: state.pointEditMenu.isVisible,
      isVisibleErrorsPaginate: state.errorsPoint.isVisibleErrorsPaginate,
      errorsPoint: state.errorsPoint.items,
      currentError: state.errorsPoint.currentError
    })
  );

  const closePointErrorsPaginate = useStoreActions(
    (actions): typeof actions.errorsPoint.close => actions.errorsPoint.close
  );

  const setCurrentError = useStoreActions(
    (actions): TAction => actions.errorsPoint.setCurrentError
  );

  const closeEditMenu: TCloseEditMenu = useStoreActions(
    (actions): TCloseEditMenu => actions.pointEditMenu.close
  );

  const prevError = (): void => {
    if (currentError !== 0) {
      setCurrentError(currentError - 1);
    }

    if (isVisibleEditMenu) {
      closeEditMenu();
    }
  };

  const nextError = (): void => {
    if (currentError + 1 !== errorsPoint.length) {
      setCurrentError(currentError + 1);
    }

    if (isVisibleEditMenu) {
      closeEditMenu();
    }
  };

  return {
    isVisibleErrorsPaginate,
    errorsPoint,
    currentError,
    prevError,
    nextError,
    setCurrentError,
    closePointErrorsPaginate
  };
}
