import * as React from 'react';

// Styled
import { Container } from './styled';

// Types
import { TextareaProps } from './Textarea.types';

export const Textarea = (props: TextareaProps): JSX.Element => {
  const {
    className,
    name,
    value,
    placeholder,
    isError,
    minHeight,
    disabled,
    readonly,
    onChange,
    onValidation,
    onFocus,
    onBlur,
    onMouseEnter,
    onMouseLeave
  } = props;

  const [errorValidation, setErrorValidation] = React.useState(false);

  const handleValidation = React.useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement>): void => {
      if (typeof onBlur === 'function') {
        onBlur(event);
      }

      const { value: currentValue } = event.currentTarget;
      if (!currentValue) {
        setErrorValidation(false);
        return;
      }
      if (typeof onValidation === 'function') {
        const resultValidation = onValidation(currentValue);
        setErrorValidation(!resultValidation);
      }
    },
    [setErrorValidation, onBlur, onValidation]
  );

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
      const { value } = event.currentTarget;
      if (typeof onChange === 'function') {
        onChange(name, value);
      }
    },
    [onChange, name]
  );

  return (
    <Container
      className={className}
      name={name}
      value={value}
      isError={isError || errorValidation}
      placeholder={placeholder}
      minHeight={minHeight}
      disabled={disabled}
      readOnly={readonly}
      onChange={handleChange}
      onBlur={handleValidation}
      onFocus={onFocus}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      rows={1}
    />
  );
};
