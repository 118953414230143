import styled from 'styled-components';
import { H1 } from '@pushwoosh/kit-typography';
import { Color, Spacing } from '@pushwoosh/kit-constants';
import { Link } from '@src/ui/kit';

export const StyledH1 = styled(H1)``;

export const Wrapper = styled.div`
  border: 1px solid ${Color.DIVIDER};
  border-radius: 4px;
  margin-top: ${Spacing.S7};
`;

export const UpgradePlanLink = styled(Link)`
  margin-left: auto;
`;
