import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgDelay(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 6a1 1 0 100 2h6a1 1 0 100-2h-6zm-6 15a9 9 0 1118 0 9 9 0 01-18 0zm9-11c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm9.016-.323a1 1 0 011.414 0l1.768 1.768a1 1 0 01-1.415 1.414l-1.767-1.767a1 1 0 010-1.415zM21 15a1 1 0 10-2 0v6a1 1 0 001 1h5a1 1 0 100-2h-4v-5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgDelay;
