import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgChevronDoubleReverse(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.424 4.52a.75.75 0 011.152.96L9.476 8l2.1 2.52a.75.75 0 01-1.152.96l-2.5-3a.75.75 0 010-.96l2.5-3zm-4 0a.75.75 0 011.152.96L5.476 8l2.1 2.52a.75.75 0 01-1.152.96l-2.5-3a.75.75 0 010-.96l2.5-3z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgChevronDoubleReverse;
