import * as React from 'react';

// Types
import { PropsOverlay } from './Overlay.types';

// Styled
import { Layout } from './styled';

export function Overlay(props: PropsOverlay): JSX.Element {
  const { isActive, children, className, onClick } = props;

  if (!isActive) {
    return null;
  }

  return (
    <Layout className={className} onClick={onClick}>
      {children}
    </Layout>
  );
}
