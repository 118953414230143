import React from 'react';

// Components
import { Icon, IconEnum } from '../../../Icon';

// Types
import { ViewEnum } from '../../Datepicker.types';

// Styled
import { ToolsWrap, StyledNavButton, Navigation, ArrowWrap, DecadeLabel } from './styled';

interface IProps {
  currentMonth?: string;
  currentYear?: string;
  decadeLabel?: string;
  setView?: (view: ViewEnum) => void;
  toPrev: () => void;
  toNext: () => void;
}

export function Tools(props: IProps): JSX.Element {
  const { currentMonth, currentYear, decadeLabel, setView, toPrev, toNext } = props;

  return (
    <ToolsWrap>
      <div>
        {currentMonth && (
          <StyledNavButton onClick={() => setView(ViewEnum.YEAR)} type="button">
            {currentMonth}
          </StyledNavButton>
        )}
        {currentYear && (
          <StyledNavButton onClick={() => setView(ViewEnum.DECADE)} type="button">
            {currentYear}
          </StyledNavButton>
        )}
        {decadeLabel && <DecadeLabel>{decadeLabel}</DecadeLabel>}
      </div>
      <Navigation>
        <ArrowWrap onClick={toPrev}>
          <Icon type={IconEnum.ARROW_LEFT_SMALL} />
        </ArrowWrap>
        <ArrowWrap onClick={toNext}>
          <Icon type={IconEnum.ARROW_RIGHT_SMALL} />
        </ArrowWrap>
      </Navigation>
    </ToolsWrap>
  );
}
