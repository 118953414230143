import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgTagSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6.208 5.458a.75.75 0 000 1.5h.006a.75.75 0 100-1.5h-.006z" fill={props.fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.917 3.167a.75.75 0 00-.75.75V8.5c0 .2.079.39.22.53l3.936 3.933a1.667 1.667 0 002.358 0l3.286-3.286-.53-.53.532.528a1.666 1.666 0 000-2.35L9.03 3.386a.75.75 0 00-.53-.22H3.917zM11.954 8.5a.166.166 0 01-.048.117L8.62 11.902a.167.167 0 01-.236 0L4.667 8.189V4.667h3.522l3.717 3.716c.03.031.048.073.048.117z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgTagSmallLined;
