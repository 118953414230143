export enum TypeTag {
  INTEGER = 'integer',
  STRING = 'string',
  LIST = 'list',
  LIST_APPEND = 'list_append',
  DATE = 'date',
  BOOLEAN = 'boolean',
  PRICE = 'price',
  VERSION = 'version',
  DYNAMIC = 'dynamic'
}
