import styled from 'styled-components';

// Constants
import { Spacing } from '@pushwoosh/kit-constants';

export const Container = styled.div`
  display: flex;
`;

export const Item = styled.div`
  margin-right: ${Spacing.S1};
`;
