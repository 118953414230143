import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgHelpRoundSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.183 5.28a1.4 1.4 0 00-1.557.915l-.2.566-1.131-.398.199-.566a2.6 2.6 0 015.053.866c0 .985-.731 1.642-1.267 1.999-.267.178-.53.312-.733.404V9.8h-1.2V8.663a.6.6 0 01.409-.569h.001l.19.569-.19-.57.011-.003.051-.02a4.1 4.1 0 00.795-.407c.464-.309.733-.652.733-1A1.402 1.402 0 008.183 5.28zM8 12.496a1 1 0 100-2 1 1 0 000 2z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15A7 7 0 108 1a7 7 0 000 14zm0-1.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgHelpRoundSmallLined;
