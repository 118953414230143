import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';

export const Container = styled.div`
  width: 100%;
`;

export const TagsList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Tag = styled.div`
  margin-top: ${Spacing.S5};
  width: 50%;
`;
