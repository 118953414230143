import styled from 'styled-components';
import { Spacing, Color, ShapeRadius } from '@pushwoosh/kit-constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Color.CLEAR};
  min-height: 100vh;
  height: 100%;
`;

export const Content = styled.div`
  padding: ${Spacing.S11};
  border-radius: ${ShapeRadius.SECTION};
`;
