import * as React from 'react';
import _startsWith from 'lodash/startsWith';

// Analytics
import { sendMixpanelEvent } from '@src/common/analytics';
import { SidebarType } from '@src/common/constants/sidebars';

// Store Hooks
import {
  useCurrentJourneyId,
  useEditJourneyById,
  useGetEmailsByApplicationCode,
  useGetJourneyById,
  useGetPointById,
  useJourneyAppCode,
  useOpenNewModal,
  useUpdatePoint
} from '@src/store/hooks';
import { useStoreActions, useStoreState } from '@src/store/store';

// UI Hooks
import { useForm } from '@src/ui/hooks';

// Container Hooks
import { useApplicationsWithAutoLoad } from '@src/ui/hooks/useApplicationsWithAutoLoad';
import { useEmailsByAppCodeWithAutoLoad } from '@src/ui/features/SendEmail/hooks/useEmailsByAppCodeWithAutoLoad';

// UI Components
import { Button, IconEnum, Section } from '@src/ui/kit';
import { Modal } from '@src/ui/kit/Modal';
import { DefaultButtons } from '@src/ui/kit/ModalButtons';
import {
  ISendEmailFormValues,
  SendEmailForm
} from '@src/ui/features/SendEmail/components/SendEmailForm';
import { SplitFlow } from '@src/ui/features/SendEmail/components/SplitFlow';

// Helpers
import { getPointNameByType, makeEmailOptions } from '@src/ui/helpers';
import { getPlaceholderFromPreset } from '@src/common/helpers/getPlaceholdersFromPreset';

// Types
import { TSendEmailPoint } from '@src/common/types/points';
import { TFormErrors } from '@src/ui/hooks/useForm';
import { IUniqueId } from '@src/common/types/entities';
import { JourneyStatus, ModalType, PointType } from '@src/common/constants';

// Styled
import { Description, StyledInput } from '../styled';

const EMAIL_PLATFORM_ERROR_TEXT = 'Email platform is not configured for this application';

interface IProps {
  pointId: IUniqueId['id'];
  closeModal: () => void;
}

export function SendEmail(props: IProps): JSX.Element {
  const { pointId, closeModal } = props;

  const journeyId = useCurrentJourneyId();
  const applicationCode = useJourneyAppCode(journeyId);
  const showDocumentationMenu = useStoreActions((actions) => actions.documentationMenu.show);
  const [emails, isLoadingEmails] = useEmailsByAppCodeWithAutoLoad(applicationCode);

  const getPointById = useGetPointById();
  const getEmailsByApplicationCode = useGetEmailsByApplicationCode();
  const updatePoint = useUpdatePoint();
  const openNewModal = useOpenNewModal();
  const getJourneyById = useGetJourneyById();
  const editPoint = useStoreActions((actions) => actions.points.update);
  const handleEditJourneyById = useEditJourneyById();
  const loadPlaceholders = useStoreActions((actions) => actions.emails.loadPlaceholders);
  const sidebars = useStoreState((state) => state.sidebars);
  const openNewSidebar = useStoreActions((actions) => actions.sidebars.openNewSidebar);
  const closeAllSidebars = useStoreActions((actions) => actions.sidebars.closeAllSidebars);

  const { status } = getJourneyById(journeyId);

  const point = getPointById(pointId) as TSendEmailPoint;

  const [applications] = useApplicationsWithAutoLoad();

  const isJourneyActive = status === JourneyStatus.RUNNING;
  const emailOptions = makeEmailOptions(emails);
  const emailData = emailOptions.find((email): boolean => email.value === point.data?.presetCode);

  const preset = React.useMemo(
    () => ({
      code: point.data?.presetCode ?? '',
      title: emailData?.label ?? '',
      name: emailData?.message ?? '',
      content: ''
    }),
    [point.data, emailData]
  );

  const {
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit
  } = useForm<ISendEmailFormValues>({
    initialValues: {
      title: point.title,
      presetCode: point.data.presetCode,
      enabledPersonalise: point.data?.personalise?.enabled,
      enabledBehaviorSplitter: point.data?.emailBehaviorSplitter?.enabled || false,
      intervalBehaviorSplitter: point.data?.emailBehaviorSplitter?.interval || 0,
      sendOnlyToSubscribed:
        point.data?.sendOnlyToSubscribed === undefined ? true : point.data?.sendOnlyToSubscribed,
      placeholders: getPlaceholderFromPreset(preset).map((placeholder) => {
        const selectedPlaceholder = point.data.personalise.placeholders.find(
          (item) => item.name === placeholder.name
        );

        return selectedPlaceholder ? { ...selectedPlaceholder, isSelected: true } : placeholder;
      }),
      enabledLiquid: !!point.data?.liquidTemplateParams?.enabled,
      liquidEventName: point.data?.liquidTemplateParams?.eventName
    },
    validate: (): TFormErrors<ISendEmailFormValues> => {
      const errors: TFormErrors<ISendEmailFormValues> = {};

      if (applicationCode) {
        const { platforms } = applications.find(
          (application): boolean => application.code === applicationCode
        );

        const isConfiguredEmailPlatform =
          platforms.length >= 1 &&
          platforms.some((platform): boolean => platform.isConfigured && platform.type === 'email');

        if (!isConfiguredEmailPlatform) {
          errors.presetCode = EMAIL_PLATFORM_ERROR_TEXT;
        }
      }

      const isPlaceholderError = values.placeholders.some(
        (placeholder) =>
          placeholder.isSelected && (!placeholder.eventName || !placeholder.attribute)
      );

      if (isPlaceholderError) {
        errors.placeholders = 'Please enter a correct placehollder settings';
      }

      return errors;
    },
    onSubmit(formValues): void {
      const emails = getEmailsByApplicationCode(applicationCode);
      const emailData = emails.find((email): boolean => email.code === formValues.presetCode);
      const data: TSendEmailPoint['data'] = {
        applicationCode,
        presetCode: formValues.presetCode,
        name: emailData ? emailData.name : '',
        isPresetAvailable: Boolean(emailData),
        sendOnlyToSubscribed: !!formValues.sendOnlyToSubscribed,
        liquidTemplateParams: {
          enabled: formValues.enabledLiquid,
          eventName: formValues.liquidEventName
        }
      };
      const newPoint = {
        id: pointId,
        title: formValues.title,
        data
      };

      if (formValues.enabledPersonalise) {
        newPoint.data = {
          ...newPoint.data,
          personalise: {
            enabled: formValues.enabledPersonalise,
            placeholders: formValues.placeholders
              .filter((placeholder) => placeholder.isSelected)
              .map((placeholder) => ({
                name: placeholder.name,
                pointID: placeholder.pointID,
                eventName: placeholder.eventName,
                attribute: placeholder.attribute
              }))
          }
        };
      }

      if (formValues.enabledBehaviorSplitter) {
        newPoint.data.emailBehaviorSplitter = {
          enabled: formValues.enabledBehaviorSplitter,
          interval: formValues.intervalBehaviorSplitter
        };
      }

      if (!isJourneyActive) {
        updatePoint(newPoint);

        closeModal();
      } else {
        editPoint(newPoint);

        handleEditJourneyById(journeyId);

        sendMixpanelEvent({
          eventName: 'JourneyEditModeChangesApplied',
          eventData: {
            PointType: 'SendEmail'
          }
        });

        closeModal();

        openNewModal({
          type: ModalType.DETAIL_POINT,
          data: {
            pointId
          }
        });
      }
    }
  });

  const defaultTitle = getPointNameByType(PointType.SEND_EMAIL);
  const [nameChanged, setNameChanged] = React.useState(!_startsWith(values.title, defaultTitle));

  const handleChangeName = (name: string, value: string) => {
    setFieldValue(name, value);
    setNameChanged(true);
  };

  React.useEffect(() => {
    if (emailData?.template) {
      loadPlaceholders(emailData?.template ?? '').then((response) => {
        const { items } = response;
        const placeholders = items.map((item) => {
          const selectedPlaceholder = point.data?.personalise?.placeholders?.find(
            (placeholder) => item === placeholder.name
          );

          return {
            name: item,
            pointID: selectedPlaceholder?.pointID ?? '',
            eventName: selectedPlaceholder?.eventName ?? '',
            attribute: selectedPlaceholder?.attribute ?? '',
            isSelected: !!selectedPlaceholder
          };
        });

        setFieldValue('placeholders', placeholders);
      });
    }
  }, [emailData?.template, loadPlaceholders, point.data?.personalise?.placeholders, setFieldValue]);

  const handleCancel = React.useCallback(() => {
    const { title, presetCode } = values;

    const eventData = {
      PointType: 'SendEmail',
      PointChanged: point.title !== title || point.data.presetCode !== presetCode
    };

    if (status === JourneyStatus.RUNNING) {
      sendMixpanelEvent({
        eventName: 'JourneyEditModeChangesCancelled',
        eventData: {
          PointType: 'SendEmail'
        }
      });
    } else {
      sendMixpanelEvent({
        eventName: 'ConfigurePointCancelled',
        eventData
      });
    }

    closeModal();
  }, [closeModal, values, point, status]);

  /* eslint-disable */
  const {
    placeholders,
    enabledPersonalise,
    enabledLiquid,
    liquidEventName,
    enabledBehaviorSplitter,
    intervalBehaviorSplitter,
    sendOnlyToSubscribed,
    ...requiredValues
  } = values;
  /* eslint-enable */

  let isDisabledSubmit = !Object.values(requiredValues).every(Boolean);

  if (enabledLiquid) {
    isDisabledSubmit = isDisabledSubmit || !liquidEventName;
  }

  const onShowDocumentation = React.useCallback((): void => {
    if (sidebars.isOpened) {
      closeAllSidebars();
    } else {
      openNewSidebar({
        type: SidebarType.DOCUMENTATION
      });
      showDocumentationMenu();
      sendMixpanelEvent({ eventName: 'ShowDocumentation' });
    }
  }, [showDocumentationMenu, sidebars, closeAllSidebars, openNewSidebar]);

  const handleSetValues = React.useCallback(
    (newValues: ISendEmailFormValues) => {
      const values2 = {
        ...newValues,
        title: !nameChanged ? newValues.title : values.title
      };

      setValues(values2);
    },
    [nameChanged, setValues, values.title]
  );

  return (
    <Modal
      title="Email"
      footerRight={
        <DefaultButtons
          onClickActionButton={handleSubmit}
          onClickCancelButton={handleCancel}
          isDisabledActionButton={isDisabledSubmit}
          actionButtonName={status === JourneyStatus.RUNNING ? 'Save' : 'Apply'}
        />
      }
      footerLeft={
        <Button
          color="primary"
          size="field"
          view="ghost"
          iconPosition="left"
          iconType={IconEnum.DOCS_MEDIUM_LINED}
          onClick={onShowDocumentation}
        >
          How to Use
        </Button>
      }
      isOpen
    >
      <Section direction="column">
        <Description>Use email templates from your Pushwoosh Account.</Description>
        <StyledInput
          label="Point Name"
          name="title"
          value={values.title}
          onChange={handleChangeName}
        />
      </Section>
      <SendEmailForm
        applicationCode={applicationCode}
        pointId={pointId}
        emailOptions={emailOptions}
        values={values}
        errors={errors}
        names={{
          title: 'title',
          presetCode: 'presetCode',
          placeholders: 'placeholders',
          enabledPersonalise: 'enabledPersonalise',
          enabledBehaviorSplitter: 'enabledBehaviorSplitter',
          intervalBehaviorSplitter: 'intervalBehaviorSplitter',
          sendOnlyToSubscribed: 'sendOnlyToSubscribed',
          enabledLiquid: 'enabledLiquid',
          liquidEventName: 'liquidEventName'
        }}
        touched={touched}
        isLoadingEmailOptions={isLoadingEmails}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        onChangeField={handleChange}
        onBlurField={handleBlur}
        nameChanged={nameChanged}
        onSubmit={handleSubmit}
      />
      <Section direction="column">
        <SplitFlow
          isDisabled={status !== JourneyStatus.DRAFT}
          values={values}
          names={{
            title: 'title',
            presetCode: 'presetCode',
            placeholders: 'placeholders',
            enabledPersonalise: 'enabledPersonalise',
            enabledBehaviorSplitter: 'enabledBehaviorSplitter',
            intervalBehaviorSplitter: 'intervalBehaviorSplitter',
            sendOnlyToSubscribed: 'sendOnlyToSubscribed',
            enabledLiquid: 'enabledLiquid',
            liquidEventName: 'liquidEventName'
          }}
          setValues={handleSetValues}
          setFieldValue={setFieldValue}
        />
      </Section>
    </Modal>
  );
}
