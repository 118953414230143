import styled from 'styled-components';
import { H4 } from '@pushwoosh/kit-typography';
import { Color, Spacing } from '@pushwoosh/kit-constants';

type ContainerProps = {
  isOpen: boolean;
};

export const Header = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  padding: ${Spacing.S5};
  display: flex;
  align-items: center;
  height: 52px;
  background-color: ${Color.MAIN};
  color: ${Color.CLEAR};
`;

export const Title = styled(H4)`
  flex: 1;
  margin: 0 ${Spacing.S2};
`;

export const Container = styled.div<ContainerProps>`
  ${({ isOpen }) => !isOpen && 'min-height: 52px;'}
  height: ${({ isOpen }) => (isOpen ? '100%' : '52px')};
  position: ${({ isOpen }) => (isOpen ? 'absolute' : 'relative')};
  width: 100%;
  background: ${Color.CLEAR};
  z-index: 1;
  overflow: hidden;
  border-bottom: 1px solid ${Color.DIVIDER};

  > svg {
    height: 14px;
    width: 14px;
  }
`;

export const PeriodWrap = styled.div`
  padding: ${Spacing.S5};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: 1px solid ${Color.DIVIDER};

  &:hover {
    background-color: ${Color.BRIGHT_LIGHT};
    color: ${Color.PRIMARY_HOVER};
  }

  &:active {
    opacity: 0.5;
    background-color: ${Color.BRIGHT_LIGHT};
    color: ${Color.PRIMARY_HOVER};
  }
`;

export const PeriodValue = styled(H4)`
  margin: 0 ${Spacing.S2};
  flex: 1;
  font-weight: 500;
`;
