import * as React from 'react';
import { H1, Paragraph } from '@pushwoosh/pwkit-typography';

import { useOpenNewModal, useUsername } from '@src/store/hooks';
import { sendToEmailSubscription, sendMixpanelEvent } from '@src/common/analytics';
import { ModalType } from '@src/common/constants';
import { Accordion } from '@src/ui/kit/Accordion';

// Styled
import { Link } from '@src/ui/kit';
import { Container, Left, Right, Button, Header, StyledImg } from './styled';

interface IEmptyListJourneyProps {
  isJourneyAllowed: boolean;
}

const accordionData = [
  {
    key: 1,
    title: 'Communicate',
    description:
      'Reach out to your customers via mobile and web push notifications, In-Apps, and emails with personalized content to reach your campaign objectives.',
    // eslint-disable-next-line camelcase
    content: <StyledImg src={`${__webpack_public_path__}communicate.png`} />
  },
  {
    key: 2,
    title: 'Engage',
    description:
      'Build your campaigns based on user behaviour and attributes. Decide who gets into the campaign — match each user with the right message.',
    // eslint-disable-next-line camelcase
    content: <StyledImg src={`${__webpack_public_path__}engage.png`} />
  },
  {
    key: 3,
    title: 'Experiment',
    description: 'Run A/B/n tests to determine the best message and the best flow.',
    // eslint-disable-next-line camelcase
    content: <StyledImg src={`${__webpack_public_path__}experiment.png`} />
  },
  {
    key: 4,
    title: 'Measure',
    description:
      'Build your campaigns based on user behaviour and attributes. Measure your campaigns effectiveness and update your user base using Set Tags. ',
    // eslint-disable-next-line camelcase
    content: <StyledImg src={`${__webpack_public_path__}measure.png`} />
  }
];

export function EmptyListJourney({ isJourneyAllowed }: IEmptyListJourneyProps): JSX.Element {
  const openNewModal = useOpenNewModal();
  const username = useUsername();
  const handleClickNewJourney = React.useCallback((): void => {
    sendToEmailSubscription(
      'OnboardingProductUsage',
      { ActionName: 'ClickCreateJourney' },
      username
    );
    sendMixpanelEvent({
      eventName: 'JourneyListWelcomeScreenCreateClicked'
    });

    openNewModal({ type: ModalType.CREATE_JOURNEY });
  }, [openNewModal, username]);

  React.useEffect(() => {
    sendMixpanelEvent({
      eventName: 'JourneyListWelcomeScreenVisited'
    });
  }, []);

  const handleAccordionItemClicked = () => {
    sendMixpanelEvent({
      eventName: 'JourneyListWelcomeScreenInfoTabChanged'
    });
  };

  return (
    <Container>
      <Header>
        <Left>
          <H1>Customer Journey Builder</H1>
          <Paragraph>
            Use campaigns to reach the audience through a relevant channel — mobile or web push
            notifications, in-apps and emails — based on segmentation and user behavior.
          </Paragraph>
        </Left>
        <Right>
          {isJourneyAllowed ? (
            <Button onClick={handleClickNewJourney}>Create Campaign</Button>
          ) : (
            <Link isExternal target="_blank" to="https://app.pushwoosh.com/products">
              <Button>Upgrade plan</Button>
            </Link>
          )}
        </Right>
      </Header>
      <Accordion items={accordionData} handleItemClick={handleAccordionItemClicked} />
    </Container>
  );
}
