import React from 'react';

// Enums
import { Color } from '@pushwoosh/kit-constants';

// Components
import { Icon, IconEnum } from '../Icon';

// Styled
import { SpinnerWrap } from './styled';

// Types
import { ISpinnerProps } from './Spinner.types';

export function Spinner(props: ISpinnerProps): JSX.Element {
  const { color = Color.CLEAR } = props;

  return (
    <SpinnerWrap color={color}>
      <Icon fill="transparent" type={IconEnum.SPINNER} />
    </SpinnerWrap>
  );
}
