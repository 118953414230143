import * as React from 'react';

export function useAutoCleanDragAndDropAtDragEnd(
  isDragging: boolean,
  dragEnd: (payload?: void) => void
): void {
  const refHandle = React.useRef<() => void>(null);

  const handleDragEnd = React.useCallback((): void => {
    if (isDragging) {
      dragEnd();
    }
  }, [isDragging, dragEnd]);

  React.useEffect((): (() => void) => {
    if (isDragging) {
      refHandle.current = handleDragEnd;

      document.body.addEventListener('mouseup', refHandle.current);
      document.body.addEventListener('mouseleave', refHandle.current);
    }

    return (): void => {
      if (refHandle.current !== null) {
        document.body.removeEventListener('mouseup', refHandle.current);
        document.body.removeEventListener('mouseleave', refHandle.current);

        refHandle.current = null;
      }
    };
  }, [isDragging, handleDragEnd]);
}
