import * as React from 'react';

// Store
import { useStoreActions } from '@src/store/store';

// Constants
import { Modes } from '@src/common/constants';

type TSetMode = (status: Modes) => void;
type TResult = TSetMode;

export function useSetMode(): TResult {
  const setMode: TSetMode = useStoreActions<TSetMode>((actions): TSetMode => actions.mode.setMode);

  const handleModeSet: TSetMode = React.useCallback<TSetMode>(
    (mode): void => {
      setMode(mode);
    },
    [setMode]
  );

  return handleModeSet;
}
