import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgBillingSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 3.25A1.75 1.75 0 001.75 5v6c0 .966.784 1.75 1.75 1.75h9A1.75 1.75 0 0014.25 11V5a1.75 1.75 0 00-1.75-1.75h-9zM3.25 5a.25.25 0 01.25-.25h9a.25.25 0 01.25.25v1h-9.5V5zm0 3v3c0 .138.112.25.25.25h9a.25.25 0 00.25-.25V8h-9.5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgBillingSmallLined;
