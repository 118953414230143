import * as React from 'react';

// Components
import { SelectAsync, TSelectedProps } from '@src/ui/kit/Select';

// Hooks
import { usePreviousValue } from './hooks/usePreviousValue';
import { useAddCustomValue } from './hooks/useAddCustomValue';

// Types
import { Props } from './AutoComplete.types';

export function AutoComplete(props: Props): JSX.Element {
  const { value, placeholder, isMulti = false, loadOptions, onChange, onFocus, onBlur } = props;

  const { selectInputValue, setSelectInputValue, handleKeyDown } = useAddCustomValue(
    value,
    onChange,
    isMulti
  );

  const [previousValueFocus, previousValueBlur] = usePreviousValue(
    value,
    selectInputValue,
    onChange,
    isMulti
  );

  const handleFocus = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      previousValueFocus();
      if (typeof onFocus === 'function') {
        onFocus(event);
      }
    },
    [previousValueFocus, onFocus]
  );

  const handleBlur = React.useCallback(
    (event: React.FocusEvent<HTMLElement>) => {
      previousValueBlur();
      if (typeof onBlur === 'function') {
        onBlur(event);
      }
    },
    [previousValueBlur, onBlur]
  );

  const handleChange = React.useCallback<TSelectedProps['onChange']>(
    (selectedOption) => {
      if (Array.isArray(selectedOption)) {
        onChange([...selectedOption]);
      } else {
        onChange(selectedOption);
      }
    },
    [onChange]
  );

  return (
    <SelectAsync
      value={value}
      inputValue={selectInputValue}
      placeholder={placeholder}
      components={{
        DropdownIndicator: (): null => null
      }}
      noOptionsMessage={(props): string => {
        if (!isMulti) {
          return null;
        }
        return props.inputValue !== ''
          ? `Add new: ${props.inputValue}`
          : 'To write searchable value';
      }}
      onChange={handleChange}
      onInputChange={setSelectInputValue}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      loadOptions={loadOptions}
      isMulti={isMulti}
      defaultOptions
      isSearchable
      backspaceRemovesValue
    />
  );
}
