import * as React from 'react';

// Analytics
import { sendMixpanelEvent } from '@src/common/analytics';

// Constants
import {
  ColumnConstant,
  SortType
} from '@src/ui/features/JourneyList/components/Table/Table.types';

type Result = {
  columnSort: ColumnConstant;
  sortType: SortType;
  changeSort: (column: ColumnConstant) => void;
};

type Callback = (args: { sortColumn: Result['columnSort']; sortType: Result['sortType'] }) => void;

export const useSort = (callback: Callback): Result => {
  const [columnSort, setSortColumn] = React.useState<ColumnConstant>(null);
  const [sortType, setSortType] = React.useState<SortType>(SortType.ASC);

  const changeSort = React.useCallback(
    (column: ColumnConstant) => {
      sendMixpanelEvent({ eventName: 'SortJourneys' });
      // Click new column
      if (column !== columnSort) {
        setSortColumn(column);
        setSortType(SortType.ASC);
        callback({ sortColumn: column, sortType: SortType.ASC });
        return;
      }
      if (sortType === SortType.ASC) {
        setSortType(SortType.DESC);
        callback({ sortColumn: column, sortType: SortType.DESC });
        return;
      }
      // 3-е состояние сортировки – сброс сортировки по колонке
      if (sortType === SortType.DESC) {
        setSortType(null);
        setSortColumn(null);
        callback({ sortColumn: null, sortType: null });
      }
    },
    [columnSort, sortType, callback]
  );

  return {
    columnSort,
    sortType,
    changeSort
  };
};
