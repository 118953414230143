import * as React from 'react';
import { Group, Rect } from 'react-konva';

// Constants
import { WIDTH_MENU_POINT } from '@src/common/constants';

// Components
import { CopyButton } from '@src/canvas/components/Points/Point/CopyButton';
import { DeleteButton } from '@src/canvas/components/Points/Point/DeleteButton';
import { Separator } from '@src/canvas/components/Points/Point/Separator';

// Helpers
import { calcPointMenuPosition } from '@src/canvas/helpers';
import { getSizePoint } from '@src/common/helpers';

// Types
import { IProps } from './PointMenu.types';

export function PointMenu(props: IProps): JSX.Element {
  const { type, isActive, onDelete, onClone } = props;

  const { widthPoint: width } = getSizePoint(type);
  return (
    <Group
      x={calcPointMenuPosition(width)}
      y={-42}
      height={36}
      width={WIDTH_MENU_POINT}
      visible={isActive && Boolean(onDelete)}
      perfectDrawEnabled={false}
    >
      <Rect
        height={30}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
        width={WIDTH_MENU_POINT}
        fill="white"
        cornerRadius={4}
        shadowOffsetX={0}
        shadowOffsetY={6}
        shadowBlur={6}
        shadowColor="#000000"
        shadowOpacity={0.1}
      />

      <CopyButton onClone={onClone} />
      <Separator />
      <DeleteButton onDelete={onDelete} />
    </Group>
  );
}
