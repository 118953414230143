import styled from 'styled-components';
import { Color, Spacing } from '@pushwoosh/kit-constants';

export const StyledWrapper = styled.div`
  display: flex;
  padding: ${Spacing.S0} ${Spacing.S3};
  background-color: ${Color.SOFT_LIGHT};
  border-radius: ${Spacing.S1};
  color: ${Color.MAIN};
  margin-left: ${Spacing.S3};
`;

export const StyledOperator = styled.div`
  color: ${Color.LOCKED};
  margin-left: ${Spacing.S3};
  margin-right: ${Spacing.S3};
`;
