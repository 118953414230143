import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgUserMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-7 1.34-7 4v1a1 1 0 001 1h12a1 1 0 001-1v-1c0-2.66-4.33-4-7-4z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgUserMediumFilled;
