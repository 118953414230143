import styled from 'styled-components';
import { Link } from '@src/ui/kit/Link';
import { Spacing, FontSize, Color, UnitSize } from '@pushwoosh/kit-constants';

export const StyledLink = styled(Link)`
  color: ${Color.MAIN};
  fill: ${Color.MAIN};
  font-size: ${FontSize.REGULAR};
  margin-bottom: ${Spacing.S7};
  height: ${UnitSize.ICON_SMALL};
  cursor: pointer;

  &:hover {
    color: ${Color.PRIMARY};
    fill: ${Color.PRIMARY};
  }
`;
