import * as React from 'react';

// Hooks Store
import { useCloseAllModals, useStoreStatisticsByJourney } from '@src/store/hooks';

// Helpers
import { getPercentageOfTotal } from '@src/ui/helpers';

// Container Components
import { DetailPointGoalEventList } from '@src/ui/features/ConversionGoals/components/DetailPointGoalEventList';

// UI Components
import { Message, Modal, Section } from '@src/ui/kit';
import { Paragraph } from '@pushwoosh/kit-typography';
import { ExtraOneButton } from '@src/ui/kit/ModalButtons';
import { Color } from '@pushwoosh/kit-constants';

interface IProps {
  isChanged?: boolean;
}

export function ModalDetailPointGoalEventList(props: IProps): JSX.Element {
  const { isChanged } = props;

  const [isChangedLocal, setChanged] = React.useState(isChanged);
  const closeAllModals = useCloseAllModals();
  const statisticsByJourney = useStoreStatisticsByJourney();

  return (
    <Modal
      title="Conversion Goals"
      footerRight={<ExtraOneButton actionButtonName="Close" onClickActionButton={closeAllModals} />}
      isOpen
    >
      <Section direction="column">
        <Paragraph>
          <b>
            Total of goals achieved:&nbsp;
            {getPercentageOfTotal(statisticsByJourney.total, statisticsByJourney.achievedGoal)}
          </b>
          ({statisticsByJourney.achievedGoal}
          hits). Percent is calculated relative to total amount of entries in journey. Total
          conversion goals achieved in this particular journey.
        </Paragraph>
        <DetailPointGoalEventList />
        {isChangedLocal && (
          <Message
            color={Color.WARNING_LIGHT}
            withClosing
            onClose={() => setChanged(false)}
            title="These changes will affect other journey points"
            message="If you set another event as a Conversion Goal or change some attributes of the current one, make sure to change the settings of linked Update User Profile and Webhook elements. Otherwise, some of these elements might perform incorrectly."
          />
        )}
      </Section>
    </Modal>
  );
}
