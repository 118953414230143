// Types
import { IOption } from '@src/common/types/select';
import { TApplication } from '@src/common/types/application';

export function makeApplicationOptions(applications: TApplication[]): IOption<string>[] {
  return applications.map(
    (application): IOption<string> => ({
      label: application.title,
      value: application.code
    })
  );
}
