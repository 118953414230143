import { computed, thunk, actionOn } from 'easy-peasy';

// Types
import { TPreset } from '@src/common/types/preset';
import { IPresetModel, TPresetsByAppCode, TPresetByAppCodeAndPresetCode } from './preset.types';

export const model: IPresetModel = {
  entities: {},

  presetsByAppCode: computed(
    (state): TPresetsByAppCode =>
      (appCode): TPreset[] => {
        const presets = state.entities[appCode];

        return presets !== undefined ? presets : [];
      }
  ),

  presetByAppCodeAndPresetCode: computed(
    (state): TPresetByAppCodeAndPresetCode =>
      (appCode, presetCode): TPreset | null => {
        const presets = state.presetsByAppCode(appCode);
        const preset = presets.find((preset): boolean => preset.code === presetCode);

        return preset !== undefined ? preset : null;
      }
  ),

  loadByAppCode: thunk(
    async (_, payload, helpers): Promise<{ code: string; presets: TPreset[] }> => {
      const {
        injections: { presetService, api }
      } = helpers;

      const response = await presetService.fetchByAppCode(payload, api);

      return {
        code: payload,
        presets: response.payload?.items
      };
    }
  ),

  onLoad: actionOn(
    (state): string => state.loadByAppCode.successType,
    (state, target): void => {
      state.entities[target.result.code] = target.result.presets;
    }
  )
};
