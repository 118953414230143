import React from 'react';
import { TWaitEvent } from '@src/common/types/points';
import { DetailedEvent } from '@src/common/components/DetailedEvent';
import { IconEnum } from '@src/ui/kit';
import { ICommonEvent } from '@src/common/types/event';
import {
  CompactEventBadge,
  CompactEventsView,
  CompactOperator
} from '@src/ui/features/WaitEvent/components/EventBranches/components/Branch/styled';
import {
  Container,
  Content,
  Header,
  HeaderBranchKey,
  HeaderBranchName,
  HeaderTitle,
  OperatorWrap,
  StatusIcon
} from './styled';

type Props = {
  index: number;
  title: string;
  open: boolean;
  toggleOpen: (index: number) => void;
  waitEvents: (ICommonEvent & TWaitEvent & { applicationCode: string; id: string })[];
};

export function DetailedBranch(props: Props): JSX.Element {
  const { index, title, waitEvents, open, toggleOpen } = props;

  return (
    <Container>
      <Header kind="secondary" onClick={() => toggleOpen(index)}>
        <HeaderTitle>
          <HeaderBranchKey>{`Branch ${index + 1}`}</HeaderBranchKey>
          <StatusIcon type={IconEnum.CHEVRON_DOWN_SMALL} open={open} />
          <HeaderBranchName>{title}</HeaderBranchName>
        </HeaderTitle>
      </Header>
      <Content>
        {open ? (
          waitEvents.map((event, index) => (
            <React.Fragment key={index}>
              <DetailedEvent
                eventName={event.name}
                eventConditions={event.eventConditions}
                eventUrl={`https://app.pushwoosh.com/applications/${event.applicationCode}/events/${event.name}/statistics`}
              />

              {index !== waitEvents.length - 1 && (
                <OperatorWrap>
                  <CompactOperator>{event?.operation?.type?.toString()}</CompactOperator>
                </OperatorWrap>
              )}
            </React.Fragment>
          ))
        ) : (
          <CompactEventsView>
            {waitEvents.map((event, index) => (
              <React.Fragment key={index}>
                <CompactEventBadge>{event.name || '(not selected)'}</CompactEventBadge>

                {index === waitEvents.length - 1 || (
                  <CompactOperator>{event?.operation?.type?.toString()}</CompactOperator>
                )}
              </React.Fragment>
            ))}
          </CompactEventsView>
        )}
      </Content>
    </Container>
  );
}
