// Store
import { useStoreState } from '@src/store/store';

// Types
import { TRichMedia } from '@src/common/types/richMedia';

type TResult = (applicationCode: string) => TRichMedia[];

export function useGetRichMediasByApplicationCode(): TResult {
  const getRichMediasByApplicationCode = useStoreState(
    (state): TResult => state.richMedias.richMediasByAppCode
  );

  return getRichMediasByApplicationCode;
}
