import * as React from 'react';

// Analytics
import { sendMixpanelEvent } from '@src/common/analytics';
import { SidebarType } from '@src/common/constants/sidebars';

// Store Hooks
import {
  useApplications,
  useCurrentJourneyId,
  useEditJourneyById,
  useFilter,
  useGetJourneyById,
  useGetPointById,
  useJourneyAppCode,
  useLoadFilter,
  useOpenNewModal,
  useUpdatePoint
} from '@src/store/hooks';
import { useStoreActions, useStoreState } from '@src/store/store';

// UI Hooks
import { useForm } from '@src/ui/hooks';

// UI Components
import { Button, IconEnum } from '@src/ui/kit';
import { Modal } from '@src/ui/kit/Modal';
import { DefaultButtons } from '@src/ui/kit/ModalButtons';

// Helpers
import { makeFilterOptions } from '@src/ui/helpers';

// Types
import { TApiPoint } from '@src/common/types/points';
import { IUniqueId } from '@src/common/types/entities';

// Constants
import { isEqual } from 'lodash';
import { JourneyStatus, ModalType } from '@src/common/constants';
import {
  EditApiEntriesValues,
  EditApiEntry
} from '@src/ui/features/ApiEntry/components/EditApiEntry';

interface IProps {
  pointId: IUniqueId['id'];
  closeModal: () => void;
}

function ApiEntryComponent(props: IProps): JSX.Element {
  const { pointId, closeModal } = props;

  const getPointById = useGetPointById();
  const updatePoint = useUpdatePoint();
  const journeyId = useCurrentJourneyId();
  const applicationCode = useJourneyAppCode(journeyId);
  const showDocumentationMenu = useStoreActions((actions) => actions.documentationMenu.show);
  const getJourneyByUd = useGetJourneyById();
  const openNewModal = useOpenNewModal();
  const editPoint = useStoreActions((actions) => actions.points.update);
  const handleEditJourneyById = useEditJourneyById();
  const sidebars = useStoreState((state) => state.sidebars);
  const openNewSidebar = useStoreActions((actions) => actions.sidebars.openNewSidebar);
  const closeAllSidebars = useStoreActions((actions) => actions.sidebars.closeAllSidebars);

  const { status } = getJourneyByUd(journeyId);

  const applications = useApplications();

  const [onLoadFilter, isLoadingFilter] = useLoadFilter();
  const filters = useFilter();

  const point = getPointById(pointId) as TApiPoint;

  const initialValues = React.useMemo(
    () => ({
      title: point.title,
      entryLimitsEnabled: point?.data?.entryLimitsEnabled ?? false,
      entryLimitsPerSecond: point?.data?.entryLimitsPerSecond ?? 5000,
      IsDeactivated: point?.data?.IsDeactivated ?? false,
      attributes: point?.data?.attributes ?? []
    }),
    [point]
  );

  const {
    values,
    setFieldValue,
    setValues,
    setFieldTouched,
    handleSubmit,
    handleChange,
    handleBlur
  } = useForm<EditApiEntriesValues>({
    initialValues,
    onSubmit(formValues): void {
      const { title, entryLimitsEnabled, entryLimitsPerSecond, IsDeactivated, attributes } =
        formValues;
      let applicationTitle = '';
      if (applicationCode) {
        const currentApplication = applications.find((item) => item.code === applicationCode);
        if (currentApplication) {
          applicationTitle = currentApplication.title;
        }
      }

      const newPoint = {
        id: point.id,
        title,
        data: {
          applicationCode,
          applicationTitle,
          entryLimitsEnabled,
          entryLimitsPerSecond,
          attributes,
          IsDeactivated
        }
      };

      if (status !== JourneyStatus.RUNNING) {
        updatePoint(newPoint);

        closeModal();
      } else {
        editPoint(newPoint);

        handleEditJourneyById(journeyId);

        sendMixpanelEvent({
          eventName: 'JourneyEditModeChangesApplied',
          eventData: {
            PointType: 'ApiEntry'
          }
        });

        closeModal();

        openNewModal({
          type: ModalType.DETAIL_POINT,
          data: {
            pointId
          }
        });
      }
    }
  });

  const filtersOptions = makeFilterOptions(filters);

  const handleCancel = React.useCallback(() => {
    if (status === JourneyStatus.RUNNING) {
      sendMixpanelEvent({
        eventName: 'JourneyEditModeChangesCancelled',
        eventData: {
          PointType: 'ApiEntry'
        }
      });
    } else {
      sendMixpanelEvent({
        eventName: 'ConfigurePointCancelled',
        eventData: {
          PointType: 'ApiEntry',
          PointChanged: !isEqual(values, initialValues)
        }
      });
    }

    closeModal();
  }, [closeModal, values, initialValues, status]);

  const onShowDocumentation = React.useCallback((): void => {
    if (sidebars.isOpened) {
      closeAllSidebars();
    } else {
      openNewSidebar({
        type: SidebarType.DOCUMENTATION
      });
      showDocumentationMenu();
      sendMixpanelEvent({ eventName: 'ShowDocumentation' });
    }
  }, [showDocumentationMenu, sidebars, closeAllSidebars, openNewSidebar]);

  return (
    <Modal
      title="Webhook-based Entry"
      footerRight={
        <DefaultButtons
          onClickActionButton={handleSubmit}
          onClickCancelButton={handleCancel}
          actionButtonName={status === JourneyStatus.RUNNING ? 'Save' : 'Apply'}
        />
      }
      footerLeft={
        <Button
          color="primary"
          size="field"
          view="ghost"
          iconPosition="left"
          iconType={IconEnum.DOCS_MEDIUM_LINED}
          onClick={onShowDocumentation}
        >
          How to Use
        </Button>
      }
      isOpen
    >
      <EditApiEntry
        values={values}
        names={{
          title: 'title',
          entryLimitsEnabled: 'entryLimitsEnabled',
          entryLimitsPerSecond: 'entryLimitsPerSecond',
          IsDeactivated: 'IsDeactivated',
          attributes: 'attributes'
        }}
        applicationCode={applicationCode}
        journeyId={journeyId}
        filters={filtersOptions}
        isLoadingFilter={isLoadingFilter}
        onLoadFilter={onLoadFilter}
        setFieldValue={setFieldValue}
        setValues={setValues}
        setFieldTouched={setFieldTouched}
        onBlurField={handleBlur}
        onChangeField={handleChange}
        journeyStatus={status}
      />
    </Modal>
  );
}

export const ApiEntry = React.memo(ApiEntryComponent);
