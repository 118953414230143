import React from 'react';

// Types
import { DaypickerProps } from './Daypicker.types';

// Styled
import { DaypickerWrap, StyledDay } from './styled';

// Constants
import { DAYS_LIST } from './constants';

export function Daypicker(props: DaypickerProps): JSX.Element {
  const { values, onChange, isDisabled } = props;

  const selectHandler = React.useCallback(
    (value: string) => {
      values.add(value);

      onChange(new Set(values));
    },
    [values, onChange]
  );

  const unselectHandler = React.useCallback(
    (value: string) => {
      values.delete(value);

      onChange(new Set(values));
    },
    [values, onChange]
  );

  return (
    <DaypickerWrap isDisabled={isDisabled}>
      {DAYS_LIST.map((day) => {
        const isSelected = values.has(day);

        return (
          <StyledDay
            key={day}
            isSameDay={isSelected}
            onClick={() => (isSelected ? unselectHandler(day) : selectHandler(day))}
          >
            {day}
          </StyledDay>
        );
      })}
    </DaypickerWrap>
  );
}
