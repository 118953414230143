import * as React from 'react';

// Components
import { Icon, IconEnum } from '@src/ui/kit/Icon';

// Constants
import { Color } from '@pushwoosh/kit-constants';

// Types
import { SortType } from '@src/ui/features/JourneyList/components/Table/Table.types';
import { IconSortProps } from './IconSort.types';

// Styled
import { IconHeaderContainer } from './styled';

export const IconSort = (props: IconSortProps): JSX.Element => {
  const { currentColumn, columnSort, sortType } = props;
  return (
    <>
      {columnSort !== currentColumn && (
        <IconHeaderContainer>
          <Icon type={IconEnum.SORT_NOT_SORTED_SMALL} fill={Color.PHANTOM} />
        </IconHeaderContainer>
      )}
      {columnSort === currentColumn && sortType === SortType.ASC && (
        <IconHeaderContainer>
          <Icon type={IconEnum.SORT_ASC_SMALL} fill={Color.MAIN} />
        </IconHeaderContainer>
      )}
      {columnSort === currentColumn && sortType === SortType.DESC && (
        <IconHeaderContainer>
          <Icon type={IconEnum.SORT_DESC_SMALL} fill={Color.MAIN} />
        </IconHeaderContainer>
      )}
    </>
  );
};
