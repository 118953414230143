import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPauseRoundMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 8.25a.75.75 0 01.75.75v6a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zM14.75 9a.75.75 0 00-1.5 0v6a.75.75 0 001.5 0V9z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-10 8.5a8.5 8.5 0 100-17 8.5 8.5 0 000 17z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPauseRoundMediumLined;
