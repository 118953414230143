import {
  Observable,
  fromEvent,
  merge,
  distinctUntilChanged,
  share,
  filter,
  combineLatest
} from 'rxjs';
import { KeyCode } from '@src/common/constants/keykodes';

export const shortcut = (shortcut: KeyCode[]): Observable<KeyboardEvent[]> => {
  const keyDown$ = fromEvent<KeyboardEvent>(document, 'keydown');
  const keyUp$ = fromEvent<KeyboardEvent>(document, 'keyup');

  const keyEvents$ = merge(keyDown$, keyUp$).pipe(
    distinctUntilChanged((a, b) => a.code === b.code && a.type === b.type),
    share()
  );

  const createKeyPressStream = (charCode: KeyCode) =>
    keyEvents$.pipe(filter((event) => event.code === charCode.valueOf()));

  const keyCodeEvents$ = shortcut.map((s) => createKeyPressStream(s));

  return combineLatest(keyCodeEvents$);
};
