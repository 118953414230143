import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';

export const OperatorMultipleChoice = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  align-items: center;
  position: relative;
  grid-gap: ${Spacing.S4};
`;

export const Operator = styled.div``;
