import { useCallback } from 'react';

// Store
import { useStoreActions } from '@src/store/store';

// Enums
import { IVector2 } from '@src/common/types/types';
import { IUniqueId } from '@src/common/types/entities';

type TUpdateCommentPosition = (commentId: IUniqueId['id'], position: IVector2) => void;

export function useUpdateCommentPosition(): TUpdateCommentPosition {
  const actionUpdateCommentPosition = useStoreActions(
    (actions): typeof actions.comments.updateCommentPosition =>
      actions.comments.updateCommentPosition
  );

  const updateCommentPosition = useCallback<TUpdateCommentPosition>(
    (commentId, position): void => {
      actionUpdateCommentPosition({
        commentId,
        position
      });
    },
    [actionUpdateCommentPosition]
  );

  return updateCommentPosition;
}
