import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgLocked(props: IProps): JSX.Element {
  return (
    <svg
      width={10}
      height={12}
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 .25A3.25 3.25 0 001.75 3.5v1.54c-.266.031-.474.084-.658.178a2 2 0 00-.874.874C0 6.52 0 7.08 0 8.2v.6c0 1.12 0 1.68.218 2.108a2 2 0 00.874.874C1.52 12 2.08 12 3.2 12h3.6c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874C10 10.48 10 9.92 10 8.8v-.6c0-1.12 0-1.68-.218-2.108a2 2 0 00-.874-.874 1.884 1.884 0 00-.658-.177V3.5A3.25 3.25 0 005 .25zM6.75 5V3.5a1.75 1.75 0 10-3.5 0V5h3.5zm-1 3.661a1 1 0 10-1.5 0V9.5a.75.75 0 001.5 0v-.839z"
        fill="#C4C8CC"
      />
    </svg>
  );
}

export default SvgLocked;
