import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPlusRoundSmallFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 8A7 7 0 111 8a7 7 0 0114 0zM8 4.25a.75.75 0 01.75.75v2.25H11a.75.75 0 010 1.5H8.75V11a.75.75 0 01-1.5 0V8.75H5a.75.75 0 010-1.5h2.25V5A.75.75 0 018 4.25z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPlusRoundSmallFilled;
