import * as React from 'react';

// Store
import { useStoreState } from '@src/store/store';

// Types
import { TJourney } from '@src/common/types/journey';
import { IUniqueId } from '@src/common/types/entities';

type TGetJourneyById = (journeyId: IUniqueId['id']) => TJourney;
type TResult = TGetJourneyById;

export function useGetJourneyById(): TResult {
  const getJourneyById: TGetJourneyById = useStoreState(
    (state): TGetJourneyById => state.journeys.journeyById
  );

  const handleGetJourneyById: TGetJourneyById = React.useCallback<TGetJourneyById>(
    (journeyId): TJourney => getJourneyById(journeyId),
    [getJourneyById]
  );

  return handleGetJourneyById;
}
