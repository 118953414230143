import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgApiEntryFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#ApiEntryFilled_svg__clip0_512_69915)">
        <rect width={24} height={24} rx={4} fill="#479900" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 10.655a2.655 2.655 0 115.31 0v4.104a.965.965 0 11-1.93 0v-.949a.5.5 0 00-.5-.5H7.43a.5.5 0 00-.5.5v.949a.965.965 0 11-1.931 0v-4.104zm1.931 0a.724.724 0 111.448 0v.707a.5.5 0 01-.5.5h-.448a.5.5 0 01-.5-.5v-.707zm4.345-1.69a.965.965 0 011.116-.953.877.877 0 00.26.005c.076-.011.154-.017.233-.017h.563a2.655 2.655 0 010 5.31.241.241 0 00-.241.242v1.207a.965.965 0 11-1.931 0V8.966zm2.38.483c-.248 0-.45.201-.45.449v1.517c0 .247.202.448.45.448a1 1 0 001-1v-.414a1 1 0 00-1-1zm3.413-.482a.965.965 0 111.931 0v5.793a.965.965 0 11-1.931 0V8.966z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="ApiEntryFilled_svg__clip0_512_69915">
          <rect width={24} height={24} rx={4} fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgApiEntryFilled;
