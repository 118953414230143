/* TODO: Какая та непонятная фигня, нужно будет вынести этот функционал в отдельную либу. */
import JSZip from 'jszip';
import { TRichMedia } from '@src/common/types/richMedia';

const reStr = '\\{\\{([^}]+)\\}\\}';

const reGetName = /{{([^|]+)|/; // regex for get param name

async function parseZipContent(zipContent: string): Promise<string> {
  const responseZipContent = await fetch(`data:application/zip;base64,${zipContent}`);
  const blob = await responseZipContent.blob();

  const file = new File([blob], 'File name', { type: 'application/zip' });
  const zip = new JSZip();

  const archive = await zip.loadAsync(file);
  const htmlContent = await archive.file('index.html').async('string');

  return htmlContent;
}

function getName(placeholder: string): string {
  const [, placeholderName] = placeholder.match(reGetName);
  return placeholderName;
}

function getVarPlaceholder(varName: string): string {
  return `<|<${varName}>|>`;
}

type TRichMediaTemplateVariable = {
  className: string;
  name: string;
  type: string;
  title: string;
  defaults: string;
};

type TRichMediaTemplateDictVariables = Record<string, TRichMediaTemplateVariable>;
type TRichMediaTemplateDictClasses = Record<string, string>;
type TRichMediaTemplateDictParams = Record<string, string>;

export class RichMediaTemplate {
  protected params: string;

  protected baseURL: string;

  protected language: string;

  protected dictParams: TRichMediaTemplateDictParams;

  protected dictVariables: TRichMediaTemplateDictVariables;

  protected dictClasses: TRichMediaTemplateDictClasses;

  protected activeVariable: string;

  protected zipContent: string;

  protected replacers: {
    variableName: string;
    placeholder: string;
    replacer: () => any;
  }[];

  constructor(
    code: string,
    zipContent: string,
    params: TRichMedia['params'] | null,
    language = 'en'
  ) {
    this.zipContent = zipContent;
    this.params = params;
    this.language = language;
    this.replacers = [];

    this.setBaseURL(code);
  }

  private setBaseURL(code: TRichMedia['code']): void {
    const firstCodeSymbol = code[0];
    const secondCodeSymbol = code[1];

    this.baseURL = `https://go.pushwoosh.com/richmedia/${firstCodeSymbol}/${secondCodeSymbol}/${code}/`;
  }

  private getDictParamsByLanguage(
    params: TRichMedia['params'] | null,
    language: string
  ): TRichMediaTemplateDictParams | null {
    try {
      return JSON.parse(params)[language];
    } catch (_) {
      return null;
    }
  }

  private getDictVariablesFromTemplate(template: string): TRichMediaTemplateDictVariables {
    const re = new RegExp(reStr, 'g');
    const dictVariables: TRichMediaTemplateDictVariables = {};

    let founded = re.exec(template);
    for (let i = 0; founded; i += 1) {
      const [title, type, defaultsRaw] = founded[1].split('|');

      let defaults = defaultsRaw;
      if (!defaults) {
        defaults = type === 'color' ? 'F00' : title;
      }

      dictVariables[title] = {
        name: title,
        type,
        title,
        defaults,
        className: `var-${i}`
      };

      founded = re.exec(template);
    }

    return dictVariables;
  }

  private getDictClassesFromTemplate(template: string): TRichMediaTemplateDictClasses {
    const dictVariables = this.getDictVariablesFromTemplate(template);
    const variables = Object.keys(dictVariables);

    return variables.reduce(
      (acc, key) => ({
        ...acc,
        [key]: `pw-re-class-${dictVariables[key].className}`
      }),
      {}
    );
  }

  private getTemplateWithInsertBaseURL(template: string): string {
    const headContentRegExp = /<head[^>]*>([\s\S]*)<\/head[^>]*>/im;
    const openingTagHeadRegExp = /<head[^>]*>/i;

    return template.replace(headContentRegExp, (headContent: string): string => {
      const text = this.getTextWithReplacePlaceholders(headContent);

      return text.replace(
        openingTagHeadRegExp,
        (founded: string): string => `${founded}<base href="${this.baseURL}" />`
      );
    });
  }

  private getParamValue(variableName: string): string {
    if (this.dictParams && Object.prototype.hasOwnProperty.call(this.dictParams, variableName)) {
      return this.dictParams[variableName];
    }

    return this.dictVariables[variableName].defaults;
  }

  private getTextWithReplacePlaceholders(
    text: string,
    callback?: (data: { variableName: string; placeholder: string }) => void,
    getReplacer?: (...paras: any[]) => () => string
  ): string {
    const regExp = new RegExp(reStr, 'g');

    return text.replace(regExp, (fullPlaceholderText) => {
      const variableName = getName(fullPlaceholderText);
      const placeholder = getVarPlaceholder(variableName);

      const replacer =
        typeof getReplacer === 'function'
          ? getReplacer(variableName)
          : (): string => this.getParamValue(variableName);

      if (typeof callback === 'function') {
        callback({
          variableName,
          placeholder
        });
      }

      this.replacers.push({
        variableName,
        placeholder,
        replacer
      });

      return placeholder;
    });
  }

  private getClassName(variableName: string): string {
    return this.dictClasses[variableName];
  }

  private getTemplateWithInsertClasses(template: string): string {
    const regExp = new RegExp(`<[^<>]*${reStr}[^<>]*>`, 'g');

    return template.replace(regExp, (full) => {
      const classes: string[] = [];

      let modifiedFull = this.getTextWithReplacePlaceholders(full, (data): void => {
        classes.push(this.getClassName(data.variableName));
      });

      const matchedClassName = modifiedFull.match(/class=["']([^"']+)["']/i);
      if (matchedClassName) {
        modifiedFull = modifiedFull.replace(
          matchedClassName[0],
          `class="${matchedClassName[1]} ${classes.join(' ')}"`
        );
      } else {
        modifiedFull = modifiedFull.replace(
          /(<\w+)\W/,
          (_, tagBegin) => `${tagBegin} class="${classes.join(' ')}" `
        );
      }

      return modifiedFull;
    });
  }

  private getTemplateWithInsertVariables(template: string): string {
    return this.getTextWithReplacePlaceholders(
      template,
      null,
      (variableName: string): (() => string) =>
        (): string => {
          const value = this.getParamValue(variableName);

          if (this.activeVariable === variableName) {
            return `<span class="${this.getClassName(variableName)}">${value}</span>`;
          }

          return value;
        }
    );
  }

  public async buildHTML(activeVariable?: string): Promise<string> {
    const baseTemplate = await parseZipContent(this.zipContent);

    this.dictVariables = this.getDictVariablesFromTemplate(baseTemplate);
    this.dictClasses = this.getDictClassesFromTemplate(baseTemplate);
    this.dictParams = this.getDictParamsByLanguage(this.params, this.language);
    this.activeVariable = activeVariable;

    const template = this.getTemplateWithInsertVariables(
      this.getTemplateWithInsertClasses(this.getTemplateWithInsertBaseURL(baseTemplate))
    );

    const html = this.replacers
      .reduce((src, rep) => src.replace(rep.placeholder, rep.replacer), template)
      .replace(/<\/head[^>]*>/, (str) => {
        let style = '';

        if (this.activeVariable) {
          style = `.${this.getClassName(this.activeVariable)} {outline: red dashed 3px !important}`;
        }

        const styleFull = `<style type="text/css">\n${style}\n</style>`;

        return `${styleFull}${str}`;
      });

    return html;
  }
}
