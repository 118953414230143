import * as React from 'react';

// Store Hooks
import {
  useComputedGetTooltipById,
  useComputedGetPointStatByPointId,
  useActionShowTooltip,
  useActionHideTooltip
} from '@src/store/hooks';
import { useStoreState } from '@src/store/store';

// UI Components
import {
  TooltipPointSendEmailStats as TooltipPointSendEmailStatsComponent,
  ITooltipPointSendEmailStatsProps
} from '@src/ui/molecules/TooltipPointSendEmailStats';

// Types
import { TPointSendEmailStatistics } from '@src/common/types/statistics';
import { IUniqueId } from '@src/common/types/entities';
import _reduce from 'lodash/reduce';
import { usePointsToGoalsStat } from '@src/store/hooks/statistics/useGoalsStatistic';

interface ITooltipPointSendEmailStatsContainerProps {
  tooltipId: IUniqueId['id'];
}

function TooltipPointSendEmailStats(props: ITooltipPointSendEmailStatsContainerProps): JSX.Element {
  const getTooltipById = useComputedGetTooltipById();
  const getPointStatByPointId = useComputedGetPointStatByPointId();
  const getGoalStatByPointId = usePointsToGoalsStat();
  const getPointDropOffs = useStoreState((state) => state.statistics.getPointDropOffs);

  const showTooltip = useActionShowTooltip();
  const hideTooltip = useActionHideTooltip();

  const tooltip = getTooltipById(props.tooltipId);
  const point = getPointStatByPointId(tooltip.data.pointId) as TPointSendEmailStatistics;
  const dropOffs = getPointDropOffs(point?.pointId);

  const goalsStat = getGoalStatByPointId(tooltip.data.pointId);
  const goalsReached =
    goalsStat?.length > 0 ? _reduce(goalsStat, (sum, goal) => sum + goal.Count, 0) : 0;
  const allDropOffs = dropOffs.reduce((prevValue, dropOff) => prevValue + dropOff.value, 0);

  const handleTooltipMouseEnter = React.useCallback<
    ITooltipPointSendEmailStatsProps['onMouseEnter']
  >((): void => showTooltip(tooltip), [tooltip, showTooltip]);

  const handleTooltipMouseLeave = React.useCallback<
    ITooltipPointSendEmailStatsProps['onMouseLeave']
  >((): void => hideTooltip({ id: props.tooltipId }), [props.tooltipId, hideTooltip]);

  if (!point) {
    return null;
  }

  return (
    <TooltipPointSendEmailStatsComponent
      id={props.tooltipId}
      x={tooltip.position.x}
      y={tooltip.position.y}
      error={allDropOffs}
      clicked={point.pointStat.clicked}
      sent={point.pointStat.sent}
      opened={point.pointStat.opened}
      unsubscribed={point.pointStat.unsubscribed}
      onMouseEnter={handleTooltipMouseEnter}
      onMouseLeave={handleTooltipMouseLeave}
      goalsReached={goalsReached}
    />
  );
}

export default React.memo(TooltipPointSendEmailStats);
