import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPushMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.25 9.25A.75.75 0 018 8.5h8a.75.75 0 010 1.5H8a.75.75 0 01-.75-.75zM8 11.5A.75.75 0 008 13h4a.75.75 0 000-1.5H8z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.95 4.25h10.1c.664 0 1.237 0 1.696.062.492.066.963.215 1.345.597s.531.854.597 1.345c.062.459.062 1.032.062 1.697v6.098c0 .665 0 1.238-.062 1.697-.066.492-.215.963-.597 1.345s-.854.531-1.345.597c-.459.062-1.032.062-1.697.062H11.25l-3.8 2.85a.75.75 0 01-1.2-.6v-2.253a8.608 8.608 0 01-.996-.059c-.491-.066-.963-.215-1.345-.597s-.531-.854-.597-1.345c-.062-.459-.062-1.032-.062-1.697V7.951c0-.665 0-1.238.062-1.697.066-.491.215-.963.597-1.345s.854-.531 1.345-.597c.459-.062 1.032-.062 1.697-.062zM5.455 5.798c-.325.044-.427.115-.484.172-.057.057-.128.159-.172.484-.046.347-.048.818-.048 1.546v6c0 .728.002 1.2.048 1.546.044.325.115.427.172.484.057.057.159.128.484.172.347.046.818.048 1.546.048a.75.75 0 01.75.75v1.5l2.8-2.1a.75.75 0 01.45-.15h6c.728 0 1.2-.002 1.546-.048.325-.044.427-.115.484-.172.057-.057.128-.159.172-.484.046-.347.048-.818.048-1.546V8c0-.728-.002-1.2-.048-1.546-.044-.325-.115-.427-.172-.484-.057-.057-.159-.128-.484-.172-.347-.046-.818-.048-1.546-.048H7c-.728 0-1.2.002-1.546.048z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPushMediumLined;
