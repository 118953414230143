import React from 'react';

// Types
import { DropdownOptions, UseDropdownResult } from './Dropdown.types';

export const useDropdown = (dropdownOptions: DropdownOptions): UseDropdownResult => {
  const { position, target } = dropdownOptions;

  const [showDropdown, setShowDropdown] = React.useState(false);

  const toggleDropdown = React.useCallback(
    (event?: React.MouseEvent) => {
      if (event) {
        event.stopPropagation();
      }

      setShowDropdown(!showDropdown);
    },
    [showDropdown, setShowDropdown]
  );

  return {
    position,
    showDropdown,
    target,
    toggleDropdown,
    setShowDropdown
  };
};
