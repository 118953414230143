// Store
import { useStoreState, useStoreActions } from '@src/store/store';

// Types
import { TGoalEventPoint } from '@src/common/types/points';

type TResult = {
  goalEventPoints: TGoalEventPoint[];
  updateGoalEvents: (goals: TGoalEventPoint[]) => void;
};

export function useGoalEventPoints(): TResult {
  const goalEventPoints: TGoalEventPoint[] = useStoreState<TGoalEventPoint[]>(
    (state): TGoalEventPoint[] => state.points.goalEventPoints
  );

  const updateGoalEvents = useStoreActions(
    (state): typeof state.points.updateGoals => state.points.updateGoals
  );

  return { goalEventPoints, updateGoalEvents };
}
