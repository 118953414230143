import * as React from 'react';

import { makeComment } from '@src/store/helpers';
import { Comment } from '@src/ui/features/JourneySidebar/components/Comment';

import { Items } from '../Items';

// Types
import { ISidebarProps } from './Sidebar.types';

import { SidebarContainer, SidebarContent, SidebarFooter } from './styled';

export function Sidebar(props: ISidebarProps): JSX.Element {
  const { pointList, draggablePoint, isDragging, onDragStart, modes } = props;
  const newComment = makeComment();

  return (
    <SidebarContainer>
      <SidebarContent>
        <Items
          points={pointList}
          draggablePoint={draggablePoint}
          isDragging={isDragging}
          onDragStart={onDragStart}
          isRead={modes.isRead}
        />
      </SidebarContent>

      <SidebarFooter>
        <Comment onMouseDown={() => onDragStart(newComment)} />
      </SidebarFooter>
    </SidebarContainer>
  );
}
