import styled from 'styled-components';
import { Spacing, Color, FontWeight, FontSize, LineHeight } from '@pushwoosh/kit-constants';
import { Button } from '@src/ui/kit';

export const Wrap = styled.div`
  margin: -${Spacing.S5} -${Spacing.S5} -${Spacing.S5} ${Spacing.S5};
  padding: ${Spacing.S9};
  background-color: ${Color.FROZEN};
  width: 292px;
`;

export const StyledImg = styled.img`
  margin: 0 auto;
  display: block;
  margin-bottom: ${Spacing.S9};
`;

export const Title = styled.h2`
  font-weight: ${FontWeight.BOLD};
  font-size: ${FontSize.HEADING2};
  color: ${Color.MAIN};
  margin: 0 0 ${Spacing.S3};
`;

export const Description = styled.p`
  color: ${Color.MAIN};
  line-height: ${LineHeight.REGULAR};
  margin: 0 0 ${Spacing.S5};
`;

export const DescriptionList = styled.ul`
  margin: 0;
  padding: 0 0 0 ${Spacing.S5};
  margin-bottom: ${Spacing.S9};
`;

export const ListItem = styled.li`
  color: ${Color.MAIN};
  line-height: ${LineHeight.REGULAR};
  margin-bottom: ${Spacing.S3};

  &::marker {
    width: 6px;
    height: 6px;
    color: ${Color.LOCKED};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const UpgradeButton = styled(Button)`
  width: 100%;
  justify-content: center;
`;
