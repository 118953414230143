import styled from 'styled-components';

// Constants
import { Spacing, FontSize, FontWeight, LineHeight } from '@pushwoosh/kit-constants';

export const Label = styled.p`
  margin-bottom: ${Spacing.S3};
  font-weight: ${FontWeight.MEDIUM};
  font-size: ${FontSize.REGULAR};
  line-height: ${LineHeight.REGULAR};
`;
