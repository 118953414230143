import React from 'react';

// Store Hooks
import { usePauseById } from '@src/store/hooks';

// Features Hooks
import { useConfirmService } from '@src/ui/features/Modals/Confirm/hooks/useConfirmService';

// Неизвестно для чего тут title
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useConfirmPauseJourney = (id: string): (() => void) => {
  const confirm = useConfirmService();
  const { pauseJourneyById, isLoading } = usePauseById();
  const breakChar = '\n\n';
  const confirmPauseJourneyHandler = React.useCallback((): void => {
    confirm({
      description: `Please be careful when editing a journey: some users who are currently inside the journey may be lost. We’ll make sure to notify you of which point deletion or modification may result in user loss. ${breakChar} During editing, the journey will be paused. All processes will resume once the journey is re-launched.`,
      onAfterConfirm(): void {
        pauseJourneyById({ journeyId: id });
      },
      title: 'Pause and edit your campaign',
      confirmLabel: 'Pause and edit',
      level: 'info',
      isLoadingConfirm: isLoading
    });
  }, [confirm, pauseJourneyById, id, isLoading]);

  return confirmPauseJourneyHandler;
};
