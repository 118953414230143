import styled from 'styled-components';

// Components
import { H3 as H3Component } from '@pushwoosh/kit-typography';

// Constants
import { Spacing, FontWeight, FontSize } from '@pushwoosh/kit-constants';

export const Container = styled.div`
  width: 100%;
`;

export const H3 = styled(H3Component)`
  margin-bottom: ${Spacing.S3};
`;

export const Labels = styled.div`
  display: flex;
  margin-top: ${Spacing.S5};
  margin-bottom: ${Spacing.S1};
`;

const Label = styled.div`
  font-size: ${FontSize.REGULAR};
  font-weight: ${FontWeight.MEDIUM};
`;

export const TagLabel = styled(Label)`
  flex-basis: 308px;
  margin-right: ${Spacing.S3};
`;

export const EventLabel = styled(Label)`
  flex-basis: 282px;
  margin-right: ${Spacing.S3};
`;

export const ValueLabel = styled(Label)`
  flex: 1;
`;

export const Controls = styled.div`
  margin-top: ${Spacing.S5};
  margin-left: -${Spacing.S4};
`;
