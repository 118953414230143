import { Spacing, Color, FontSize, FontWeight } from '@pushwoosh/kit-constants';
import styled from 'styled-components';

export const ButtonsWrap = styled.div`
  display: grid;
  grid-template: 'auto auto auto';
  gap: ${Spacing.S3};
`;

export const RightFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${Spacing.S5};
`;

export const ErrorsCounter = styled.span`
  color: ${Color.DANGER};
  font-size: ${FontSize.SMALL};
  font-weight: ${FontWeight.MEDIUM};
`;
