import React from 'react';
import _isEmpty from 'lodash/isEmpty';

import { Cell, Row, Table, TableHeader } from '@src/ui/kit/Table';
import { useOpenNewModal, usePoints, usePointsStats } from '@src/store/hooks';
import { ModalType, PointType } from '@src/common/constants';
import { filterPointsByGroup, getPointIconColorByType } from '@src/ui/helpers';
import { TPointSendEmailStatistics, TPointSendPushStatistics } from '@src/common/types/statistics';
import { IconEnum } from '@src/ui/kit';

import { StyledIcon } from '@src/ui/features/JourneyStatistics/components/CommunicationsStat/styled';
import { formatNumber } from '@src/common/helpers';
import { DropdownMenu, DropdownMenuWindow, DropdownMenuItem } from '@pushwoosh/kit-dropdown-menu';
import { Tag } from '@pushwoosh/kit-constants';
import { KebabIcon } from '@pushwoosh/kit-icons';
import { IUniqueId } from '@src/common/types/entities';

const COMMUNICATION_GROUP = new Set([PointType.SEND_EMAIL, PointType.SEND_PUSH]);

export function CommunicationsStat(): JSX.Element {
  const pointsStats = usePointsStats();
  const openNewModal = useOpenNewModal();

  const points = usePoints();

  const communicationPoints = filterPointsByGroup(points, COMMUNICATION_GROUP);

  const handleOpenPoint = (id: IUniqueId['id']) => {
    openNewModal({
      type: ModalType.DETAIL_POINT,
      data: {
        pointId: id
      }
    });
  };

  return (
    !_isEmpty(communicationPoints) && (
      <Table>
        <Row>
          <TableHeader>Communication</TableHeader>
          <TableHeader>Sent</TableHeader>
          <TableHeader>Opened</TableHeader>
          <TableHeader>CTR</TableHeader>
          <TableHeader />
        </Row>
        <>
          {communicationPoints.map((point) => {
            const pointStats = pointsStats.find((ps) => ps.pointId === point.id) as
              | TPointSendPushStatistics
              | TPointSendEmailStatistics;

            const iconType =
              point.type === PointType.SEND_EMAIL
                ? IconEnum.EMAIL_MEDIUM_LINED
                : IconEnum.PUSH_MEDIUM_LINED;

            let ctrStat = 0;

            if (pointStats) {
              if (point.type === PointType.SEND_EMAIL) {
                const { pointStat } = pointStats as TPointSendEmailStatistics;

                ctrStat = (pointStat.clicked / pointStat.sent) * 100;
              } else {
                const { pointStat } = pointStats as TPointSendPushStatistics;

                ctrStat = pointStat.conversion;
              }
            }

            return (
              <Row key={point.id}>
                <Cell>
                  <StyledIcon type={iconType} fill={getPointIconColorByType(point.type)} />
                  {point.type === PointType.SEND_PUSH
                    ? point.title || point.data?.name
                    : point.title}
                </Cell>
                <Cell>{pointStats?.pointStat?.sent || 0}</Cell>
                <Cell>{pointStats?.pointStat?.opened || 0}</Cell>
                <Cell>{formatNumber(ctrStat)}%</Cell>
                <Cell>
                  <DropdownMenu
                    color="secondary"
                    windowPlacement="bottom-end"
                    icon={<KebabIcon size="medium" />}
                    view="ghost"
                  >
                    <DropdownMenuWindow>
                      <DropdownMenuItem
                        color="main"
                        as={Tag.BUTTON}
                        type="button"
                        onClick={() => handleOpenPoint(point.id)}
                      >
                        Open Message
                      </DropdownMenuItem>
                    </DropdownMenuWindow>
                  </DropdownMenu>
                </Cell>
              </Row>
            );
          })}
        </>
      </Table>
    )
  );
}
