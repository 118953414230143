// Store
import { useStoreActions, useStoreState } from '@src/store/store';

// Types
import { TRequest } from '@src/store/models/requests/requests.types';
import { TRichMedia } from '@src/common/types/richMedia';

type TActionLoad = (payload: string) => Promise<{ code: string; richMedias: TRichMedia[] }>;
type TResult = [TActionLoad, boolean];

export function useLoadRichMedias(): TResult {
  const load: TActionLoad = useStoreActions(
    (actions): TActionLoad => actions.richMedias.loadByAppCode
  );

  const request: TRequest = useStoreState(
    (state): TRequest => state.requests.entities['richMedias.loadByAppCode']
  );

  return [load, Boolean(request)];
}
