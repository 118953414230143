import { Color, ShapeRadius, Spacing } from '@pushwoosh/kit-constants';
import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid ${Color.DIVIDER};
  border-radius: ${ShapeRadius.CONTROL};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing.S3} ${Spacing.S5};
`;

export const WinnerWrapper = styled.div`
  background-color: ${Color.BRIGHT_LIGHT};
  padding: ${Spacing.S5};
  border-radius: 4px;
`;

export const WinnerFooter = styled.div`
  display: flex;
  align-items: center;
`;

export const Error = styled.p`
  font-size: 12px;
  color: ${Color.DANGER};
  margin-left: ${Spacing.S4};
`;
