import * as React from 'react';
import { Field } from '@pushwoosh/pwkit-field';

import { TimeStepper } from '@src/ui/kit/TimeStepper';
import { SECONDS_IN_DAY, SECONDS_IN_MINUTE } from '@src/common/constants';
import { Section, Switch } from '@src/ui/kit';
import { sendMixpanelEvent } from '@src/common/analytics';
import { StyledDescription, StyledSwitchWrapper } from './styled';
import { WaitingPeriodTypeProps } from './WaitPeriodTime.types';

const MIN_DELAY = SECONDS_IN_MINUTE * 1;
const MAX_DELAY = SECONDS_IN_DAY * 90;

export function WaitPeriodTime(props: WaitingPeriodTypeProps): JSX.Element {
  const { names, values, setFieldValue } = props;

  const handleFixedDelayChange = (value: boolean) => {
    sendMixpanelEvent({
      eventName: value ? 'WaitEventFixedPeriodEnabled' : 'WaitEventFixedPeriodDisabled'
    });

    setFieldValue(names.isFixedDelay, value);
  };

  return (
    <Section direction="column">
      <Field title="Waiting period" helpText="The maximum time period to wait is 90 days.">
        <>
          <TimeStepper
            name={names.delay}
            value={values.delay}
            onChange={setFieldValue}
            maxInSeconds={MAX_DELAY}
            minInSeconds={MIN_DELAY}
            maxDayValue={90}
          />
        </>
      </Field>
      <StyledSwitchWrapper>
        <Switch defaultEnabled={values.isFixedDelay} onChange={handleFixedDelayChange}>
          Fixed waiting period
        </Switch>
        <StyledDescription>
          This option would allow the user to wait for the entire specified time period, even if the
          chosen event(s) occur earlier.
        </StyledDescription>
      </StyledSwitchWrapper>
    </Section>
  );
}
