import styled from 'styled-components';

import { Spacing } from '@pushwoosh/kit-constants';

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  user-select: none;
  border-radius: 8px;
`;

export const SidebarContent = styled.div`
  padding: ${Spacing.S5} ${Spacing.S3};
  overflow: auto;
  user-select: none;
`;

export const SidebarFooter = styled.div`
  border-top: 1px solid #e6e6e6;
  margin-top: auto;
  padding: ${Spacing.S3};
`;
