import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgInAppLarge(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 13a2 2 0 012-2h20a2 2 0 012 2v3.586A2 2 0 0131.414 18l-1.293 1.293a1 1 0 000 1.414L31.414 22A2 2 0 0132 23.414V27a2 2 0 01-2 2H10a2 2 0 01-2-2v-3.586A2 2 0 018.586 22l1.293-1.293a1 1 0 000-1.414L8.586 18A2 2 0 018 16.586V13zm22 .5a.5.5 0 00-.5-.5h-19a.5.5 0 00-.5.5v2.879a.5.5 0 00.146.353l1.147 1.147a3 3 0 010 4.242l-1.147 1.147a.5.5 0 00-.146.353V26.5a.5.5 0 00.5.5h19a.5.5 0 00.5-.5v-2.879a.5.5 0 00-.146-.353l-1.147-1.147a3 3 0 010-4.242l1.147-1.147A.5.5 0 0030 16.38V13.5zM10 32a1 1 0 011-1h18a1 1 0 110 2H11a1 1 0 01-1-1zm10.465-16.641a.5.5 0 00-.944 0l-.843 2.425a.5.5 0 01-.463.336l-2.567.052a.5.5 0 00-.292.899l2.047 1.551a.5.5 0 01.176.543l-.744 2.458a.5.5 0 00.765.555l2.107-1.466a.5.5 0 01.572 0l2.107 1.466a.5.5 0 00.765-.555l-.744-2.458a.5.5 0 01.177-.543l2.046-1.551a.5.5 0 00-.292-.899l-2.568-.052a.5.5 0 01-.462-.336l-.843-2.425z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgInAppLarge;
