import * as React from 'react';
import { Path } from 'react-konva';

// Constants
import { CONTROL_GROUP_COLOR, PointType } from '@src/common/constants';

// Components
import { Point, PointContent, PointShortInfo } from '../Point';

// Types
import { IProps } from './PointExit.types';
import { PointIcon } from '../Point/PointIcon';

export function PointExit(props: IProps): JSX.Element {
  const { propsCommonPoint } = props;

  return (
    <Point type={PointType.EXIT} color={CONTROL_GROUP_COLOR} {...propsCommonPoint}>
      <PointContent type={PointType.EXIT}>
        <PointIcon type={PointType.EXIT} fill={CONTROL_GROUP_COLOR}>
          <Path
            fill="white"
            perfectDrawEnabled={false}
            shadowForStrokeEnabled={false}
            data="M11.5 8.5C12.1565 8.5 12.7145 8.92173 12.9178 9.50905C13.0082 9.77 13.2239 10 13.5 10H28.5C29.3284 10 30 10.6716 30 11.5V21.5C30 22.3284 29.3284 23 28.5 23H13.5C13.2239 23 13 23.2239 13 23.5V27.5C13 27.7761 13.224 27.9992 13.5 28.0073C14.471 28.0357 15.3665 28.1466 16.071 28.3228C16.4753 28.4238 16.8734 28.5601 17.1945 28.7499C17.4487 28.9001 18 29.2901 18 30C18 30.7099 17.4487 31.0999 17.1945 31.2501C16.8734 31.4399 16.4753 31.5762 16.071 31.6772C15.247 31.8832 14.1617 32 13 32C11.8383 32 10.753 31.8832 9.92904 31.6772C9.52475 31.5762 9.12664 31.4399 8.80551 31.2501C8.55133 31.0999 8 30.7099 8 30C8 29.2901 8.55133 28.9001 8.80551 28.7499C9.12664 28.5601 9.52475 28.4238 9.92904 28.3228C9.9706 28.3124 10 28.2752 10 28.2323V10C10 9.17157 10.6716 8.5 11.5 8.5ZM9.78364 30.4984C9.78271 30.4983 9.78908 30.4929 9.80549 30.4824C9.79277 30.4933 9.78456 30.4985 9.78364 30.4984ZM16.2164 30.4984C16.2154 30.4985 16.2072 30.4933 16.1945 30.4824C16.2109 30.4929 16.2173 30.4983 16.2164 30.4984ZM9.80549 29.5176C9.78908 29.5071 9.78271 29.5017 9.78364 29.5016C9.78456 29.5015 9.79277 29.5067 9.80549 29.5176ZM16.1945 29.5176C16.2072 29.5067 16.2154 29.5015 16.2164 29.5016C16.2173 29.5017 16.2109 29.5071 16.1945 29.5176Z"
          />
        </PointIcon>
      </PointContent>
      <PointShortInfo type={PointType.EXIT} show={true} label="Exit" align="center" />
    </Point>
  );
}
