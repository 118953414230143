import { TArrowEntity } from '@src/common/types/arrow';
import { IPointOutputStatistics } from '@src/common/types/statistics';

export function mapArrowWithStats(
  arrows: TArrowEntity[],
  pointOutputsStats: IPointOutputStatistics[]
): TArrowEntity[] {
  return arrows.map<TArrowEntity>((arrow): TArrowEntity => {
    const stats = pointOutputsStats.find(
      (outputStat): boolean =>
        outputStat.fromPointId === arrow.fromPointId && outputStat.key === arrow.outputKey
    );

    return {
      ...arrow,
      outputStats: stats && stats.count
    };
  });
}
