import * as React from 'react';

// UI components
import { TimeStepperPeriod } from '@src/ui/features/SilentHours/components/TimeStepperPeriod';
import { Select, Switch } from '@src/ui/kit';

// Styled
import { FormChoiceGroup, FormControl } from '@src/ui/form';
import { WeeklyValues } from '@src/common/constants/datetime';
import { SilentHoursBehavior } from '@src/common/types/silentHours';
import { formatWeekDaysValues } from '../../helpers/formatWeekDaysValues';
import { Block, BlockContent, Header, Paragraph, TimeConfig } from './styled';

// Types
import { TFormEditChannelHoursProps } from './EditChannelHours.types';

type BehaviorOption = { label: string; value: SilentHoursBehavior; help?: string };

const BEHAVIOR_OPTIONS: BehaviorOption[] = [
  {
    label: 'Send when Silent Hours End',
    value: 'waitAndSend',
    help:
      'This option schedules the message to be automatically sent as soon as the Silent Hours period is over, ' +
      'ensuring no disruption to the recipient during designated Silent Hours.'
  },
  {
    label: 'Cancel Message and Move Forward',
    value: 'dropAndGo',
    help:
      'This option immediately cancels the message and progresses the user ' +
      'to the next step in the campaign without delay.'
  },
  {
    label: 'Cancel Message, Then Forward Post-Silent Hours',
    value: 'waitAndDrop',
    help:
      "This option cancels the message, but delays the user's progression " +
      'to the next step in the campaign until the Silent Hours period has concluded.'
  }
];

export const EditChannelHours = (props: TFormEditChannelHoursProps): JSX.Element => {
  const { header, names, values, setFieldValue } = props;

  const disabledTimeConfig = !values.enabled;

  const handleChangeCheckbox = (enabled: boolean) => setFieldValue(names.enabled, enabled);

  const handleSelectWeekDays = (name: string, value: string[]) => {
    const values = [false, false, false, false, false, false, false];

    // eslint-disable-next-line no-return-assign
    value.map((item: string) => (values[Number(item)] = true));

    setFieldValue(name, values);
  };

  const selectedBehaviorOption = React.useMemo(
    () => BEHAVIOR_OPTIONS.find((item) => item.value === values.behavior),
    [values.behavior]
  );

  const handleBehaviorChange = (opt: BehaviorOption | BehaviorOption[]) => {
    if (Array.isArray(opt)) {
      return;
    }
    setFieldValue(names.behavior, opt.value);
  };

  return (
    <Block>
      <Header open={values.enabled}>
        <Switch defaultEnabled={values.enabled} onChange={handleChangeCheckbox}>
          {header}
        </Switch>
      </Header>

      {values.enabled && (
        <BlockContent>
          <TimeConfig>
            <TimeStepperPeriod
              label="Start"
              helpText="On subscriber’s device timezone"
              name={'fromTime'}
              values={values.fromTime}
              disabled={disabledTimeConfig}
              onChange={setFieldValue}
            />
            <TimeStepperPeriod
              label="End"
              name={'toTime'}
              values={values.toTime}
              disabled={disabledTimeConfig}
              onChange={setFieldValue}
            />
          </TimeConfig>

          <FormChoiceGroup
            name={names.weekDays}
            label="Set specific weekdays for pausing message delivery"
            activeValues={formatWeekDaysValues(values.weekDays)}
            defaultValues={WeeklyValues}
            setFieldValue={handleSelectWeekDays}
            color="red"
          />

          <FormControl label="Message behavior">
            <Select
              value={selectedBehaviorOption}
              options={BEHAVIOR_OPTIONS}
              onChange={handleBehaviorChange}
            />

            {selectedBehaviorOption.help && <Paragraph>{selectedBehaviorOption.help}</Paragraph>}
          </FormControl>
        </BlockContent>
      )}
    </Block>
  );
};
