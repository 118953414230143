import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgArrowRoundLeftSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.141 7.465L7.47 5.136a.75.75 0 011.06 1.061L7.477 7.25h2.856a.75.75 0 010 1.5H7.477L8.53 9.803a.75.75 0 11-1.06 1.06L5.136 8.53a.747.747 0 01.005-1.065z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14.583A6.583 6.583 0 118 1.417a6.583 6.583 0 010 13.166zM2.917 8a5.083 5.083 0 1010.166 0A5.083 5.083 0 002.917 8z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgArrowRoundLeftSmallLined;
