import * as React from 'react';
import { Color } from '@pushwoosh/kit-constants';
import { Section } from '@src/ui/kit';

// Constants
import { OPTIONS_FREQUENCY_CAPPING } from '@src/common/constants/frequencyCapping';

// Styled
import { TextRule, BadgeWithOffset } from './styled';

// Types
import { PropsDetailSilentHours } from './DetailFrequencyCapping.types';

export const DetailFrequencyCapping = (props: PropsDetailSilentHours): JSX.Element => {
  const { isEnabled, period } = props;

  const labelPeriod = !isEnabled
    ? 'No Limit'
    : `Once time in ${OPTIONS_FREQUENCY_CAPPING.find((item) => item.value === period).label}`;

  return (
    <Section>
      <TextRule>Frequency capping rules: </TextRule>
      <BadgeWithOffset color={Color.WARNING} isBoldText>
        {labelPeriod}
      </BadgeWithOffset>
    </Section>
  );
};
