import React from 'react';
import Konva from 'konva';
import { TSetActivePoint } from '@src/store/hooks/points/useSetActivePoint';
import { getCorrectPointPosition } from '../../helpers/getCorrectPointPosition';

type Options = {
  zoom: number;
  setActivePoint: TSetActivePoint;
};

export function useSelection(options: Options) {
  const { zoom, setActivePoint } = options;
  const refSelection = React.useRef<Konva.Rect>(null);
  const refSelectionCoords = React.useRef({
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0
  });
  const [selectionOptions, setSelectionOptions] = React.useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    visible: false
  });

  const handleSelection = React.useCallback(
    (event: Konva.KonvaEventObject<MouseEvent>) => {
      event.evt.preventDefault();

      const stage = event.currentTarget.getStage();

      if (!selectionOptions.visible) return;

      if (event.evt.shiftKey || event.evt.ctrlKey || event.evt.metaKey) {
        const oldScale = { x: zoom, y: zoom };
        const pointPosition = getCorrectPointPosition(stage, stage.getPointerPosition(), oldScale);
        const x2 = pointPosition.x;
        const y2 = pointPosition.y;

        setSelectionOptions({
          x: Math.min(refSelectionCoords.current.x1, x2),
          y: Math.min(refSelectionCoords.current.y1, y2),
          width: Math.abs(x2 - refSelectionCoords.current.x1),
          height: Math.abs(y2 - refSelectionCoords.current.y1),
          visible: true
        });
      }
    },
    [selectionOptions.visible, zoom]
  );

  const handleStartSelection = React.useCallback(
    (event: Konva.KonvaEventObject<MouseEvent>) => {
      event.evt.preventDefault();

      const stage = event.currentTarget.getStage();

      if (event.target !== stage) return;

      if (event.evt.shiftKey || event.evt.ctrlKey || event.evt.metaKey) {
        setActivePoint(null);

        const oldScale = { x: zoom, y: zoom };
        const pointPosition = getCorrectPointPosition(stage, stage.getPointerPosition(), oldScale);

        refSelectionCoords.current = {
          x1: pointPosition.x,
          y1: pointPosition.y,
          x2: pointPosition.x,
          y2: pointPosition.y
        };

        setSelectionOptions({
          x: pointPosition.x,
          y: pointPosition.y,
          visible: true,
          width: 0,
          height: 0
        });
      }
    },
    [zoom, setActivePoint]
  );

  const handleEndSelection = React.useCallback(
    (event: Konva.KonvaEventObject<MouseEvent>) => {
      event.evt.preventDefault();

      const stage = event.currentTarget.getStage();
      const points = stage.find('.point');
      const selectionBox = refSelection.current.getClientRect();

      Array.from(points)
        .filter((point) => Konva.Util.haveIntersection(selectionBox, point.getClientRect()))
        .forEach((point) => {
          setActivePoint(point.attrs.pointId, true);
        });

      setSelectionOptions({
        ...selectionOptions,
        visible: false
      });
    },
    [setActivePoint, selectionOptions]
  );

  return {
    refSelection,
    selectionOptions,
    handleSelection,
    handleStartSelection,
    handleEndSelection
  };
}
