// Api
import { GetOptions, HttpClient } from '@pushwoosh/http-client';

// Helpers
import { getApiOrigin } from '@src/store/helpers';

// Types
import { TFetchByUidResponse, TFetchByAppCodeResponse } from './types';

export const service = {
  async fetchByAppCode(applicationCode: string, api: HttpClient): Promise<TFetchByAppCodeResponse> {
    const options: GetOptions<TFetchByAppCodeResponse, { code: string }, any> = {
      params: {
        code: applicationCode
      },
      query: {
        no_content: 'yes'
      },
      withAuthorization: true
    };

    // eslint-disable-next-line camelcase
    return api.get<TFetchByAppCodeResponse, { code: string }, never>(
      `${getApiOrigin()}/api/application/:code/richmedia`,
      options
    );
  },

  async fetchByRichMediaCode(
    appCode: string,
    richMediaCode: string,
    api: HttpClient
  ): Promise<TFetchByUidResponse> {
    const options: GetOptions<TFetchByUidResponse, { code: string; richMediaCode: string }, never> =
      {
        params: {
          code: appCode,
          richMediaCode
        },
        withAuthorization: true
      };

    return api.get<TFetchByUidResponse, { code: string; richMediaCode: string }, never>(
      `${getApiOrigin()}/api/application/:code/richmedia/:richMediaCode`,
      options
    );
  }
};
