import React from 'react';
import { Color } from '@pushwoosh/kit-constants';
import { Spinner } from '@src/ui/kit';
import { StyledButton, ButtonField } from './styled';

type Props = {
  icon?: JSX.Element;
  children: string;
  onClick?: () => void;
  isCompact?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  iconColor: Color;
  isLoading?: boolean;
};

export function Button(props: Props): JSX.Element {
  const {
    icon: Icon,
    children,
    onClick,
    isCompact,
    isDisabled,
    isSelected,
    iconColor,
    isLoading
  } = props;

  return (
    <StyledButton
      onClick={onClick}
      isCompact={isCompact}
      isDisabled={isDisabled}
      isSelected={isSelected}
      color={iconColor}
      isLoading={isLoading}
    >
      {isLoading ? <Spinner color={Color.MAIN} /> : <>{Icon}</>}
      {!isCompact && <ButtonField isDisabled={isDisabled}>{children}</ButtonField>}
    </StyledButton>
  );
}
