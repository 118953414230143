import styled from 'styled-components';
import { Color, Spacing } from '@pushwoosh/kit-constants';

export const Container = styled.div`
  background-color: ${Color.CLEAR};
  border-radius: 8px;
`;

export const FooterWrap = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 ${Spacing.S5};

  & > button {
    width: 100%;
    justify-content: center;
  }
`;

export const SidebarContent = styled.div`
  padding: ${Spacing.S7};
`;

export const ValidationContent = styled.div`
  padding: ${Spacing.S3} 0;
`;
