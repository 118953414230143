import * as React from 'react';
import { Group, Ring, Line, Path } from 'react-konva';

// Constants
import { INVALID_COLOR, GroupSizeContent, DEFAULT_POINT_BLOCK_OFFSET } from '@src/common/constants';
import { CANVAS_ARROW_COLOR } from '@src/common/constants/canvas';

// Helpers
import { getSizePoint } from '@src/common/helpers';

// Hooks
import { useHover } from '@src/canvas/hooks';

// Types
import { Color } from '@pushwoosh/kit-constants';
import { IProps } from './PointOutput.types';

// Components
import { PointBadge } from '../PointBadge';

const RADIUS_OUTER_RING = 4;
const RADIUS_INNER_RING = 2;
const SCALE_FACTOR = 2;

export function PointOutput(props: IProps): JSX.Element {
  const {
    pointId,
    outputKey = 'default',
    x,
    y,
    order,
    type,
    colorBlock,
    colorOutput,
    colorArrow,
    textBadge,
    fillBadge,
    isInvalid,
    isActive = false,
    isShowBadge,
    isLinkedOutput = false,
    onMouseDown,
    arrowHoveredToDelete
  } = props;

  const { isMouseEnter, handleMouseEnter, handleMouseLeave } = useHover();

  const { widthPoint, heightPoint } = getSizePoint(type);

  const xCord = x || widthPoint - DEFAULT_POINT_BLOCK_OFFSET;
  let yCord = y || heightPoint / 2 - DEFAULT_POINT_BLOCK_OFFSET;
  if (typeof order === 'number') {
    yCord = GroupSizeContent.HEIGHT / 2;
  }

  // reset hover after create new point
  React.useEffect(() => {
    if (isLinkedOutput && isMouseEnter) {
      handleMouseLeave();
    }
  }, [isLinkedOutput, isMouseEnter, handleMouseLeave]);

  const isArrowHoveredToDelete =
    arrowHoveredToDelete !== null &&
    arrowHoveredToDelete.fromPointId === pointId &&
    arrowHoveredToDelete.key === outputKey;

  let colorOutputRing = colorArrow || CANVAS_ARROW_COLOR;

  if (isArrowHoveredToDelete) {
    colorOutputRing = Color.DANGER;
  }

  const colorOutputFill = isInvalid ? INVALID_COLOR : colorOutput || colorBlock;
  const increaseLine = isMouseEnter ? SCALE_FACTOR : 0;

  let innerRadius = isMouseEnter ? RADIUS_INNER_RING + 1 : RADIUS_INNER_RING;

  if (isLinkedOutput) {
    innerRadius = 0;
  }

  return (
    <Group
      name="point-output"
      fromPointId={pointId}
      outputKey={outputKey}
      colorArrow={colorArrow}
      outputOrder={order}
      x={xCord}
      y={yCord}
      onMouseDown={!isLinkedOutput && onMouseDown}
      onMouseEnter={!isLinkedOutput && handleMouseEnter}
      onMouseLeave={!isLinkedOutput && handleMouseLeave}
      perfectDrawEnabled={false}
    >
      <>
        <Path
          offsetY={11}
          offsetX={8}
          fill="#fff"
          perfectDrawEnabled={false}
          shadowForStrokeEnabled={false}
          data="M3.5 -5C1.567 -5 0 -3.433 0 -1.5V11V23.5C0 25.433 1.567 27 3.5 27C5.433 27 7 25.433 7 23.5V22C7 19.7909 8.92904 18.0852 10.7861 16.8887C12.7196 15.643 14 13.471 14 11C14 8.52896 12.7196 6.35697 10.7861 5.11127C8.92904 3.9148 7 2.20914 7 0V-1.5C7 -3.433 5.433 -5 3.5 -5Z"
        />
        <Line
          points={[0, -5.9 - increaseLine, 0, 5.9 + increaseLine]}
          lineJoin="round"
          stroke="#ffffff"
          strokeWidth={2.2}
          perfectDrawEnabled={false}
          shadowForStrokeEnabled={false}
        />
        <Ring
          x={0}
          y={0}
          outerRadius={isMouseEnter ? RADIUS_OUTER_RING + 1 : RADIUS_OUTER_RING}
          innerRadius={innerRadius}
          fill={isLinkedOutput ? colorOutputRing : colorOutputFill}
          opacity={isActive ? 1 : 0.7}
          perfectDrawEnabled={false}
          shadowForStrokeEnabled={false}
        />
        {isShowBadge && <PointBadge x={15} y={-10} text={textBadge} fill={fillBadge} />}
      </>
    </Group>
  );
}
