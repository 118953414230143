import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgCheckRoundMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12c0-4.974 4.025-9 9-9 4.974 0 9 4.025 9 9 0 4.974-4.025 9-9 9-4.974 0-9-4.025-9-9zm14.207-1.793a1 1 0 00-1.414-1.414L11 13.586l-1.793-1.793a1 1 0 00-1.414 1.414l2.5 2.5a1 1 0 001.414 0l5.5-5.5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgCheckRoundMediumFilled;
