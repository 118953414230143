// Store
import { useStoreActions, useStoreState } from '@src/store/store';

// Types
import { TRequest } from '@src/store/models/requests/requests.types';
import { TPreset } from '@src/common/types/preset';

type TActionLoad = (payload: string) => Promise<{ code: string; presets: TPreset[] }>;
type TResult = [TActionLoad, boolean];

export function useLoadPresets(): TResult {
  const load: TActionLoad = useStoreActions((actions): TActionLoad => actions.preset.loadByAppCode);

  const request: TRequest = useStoreState(
    (state): TRequest => state.requests.entities['preset.loadByAppCode']
  );

  return [load, Boolean(request)];
}
