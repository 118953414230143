import * as React from 'react';
import { cloneDeep } from 'lodash';
import { Group } from 'react-konva';
import { useBus } from '@src/common/contexts/bus';
import { DrawArrowMemo } from '@src/canvas/components/DrawArrow';
import { DrawPoint } from '@src/canvas/components/DrawPoint';
import { Comment } from '@src/canvas/components/Comment';
import { DeletedComment } from '@src/canvas/components/DeletedComment';
import {
  useActivePointIds,
  useModeMap,
  usePointOutputsStats,
  usePoints,
  useComments,
  useStoreTooltips,
  useActionHideTooltip,
  useActionUpdatePositionAtPoint,
  useCheckOverlapPoints,
  useSetActivePoint
} from '@src/store/hooks';
import { useStoreState, useStoreActions } from '@src/store/store';
import { useDrawingArrow } from '@src/canvas/hooks';
import { mapArrowWithStats } from '../../helpers/mapArrowWithStats';

function DrawItems(): JSX.Element {
  const bus = useBus();
  const activePointIds = useActivePointIds();
  const modeMap = useModeMap();
  const arrows = useStoreState((store) => store.arrow.arrows);
  const pointOutputsStatistics = usePointOutputsStats();
  const actionUpdatePositionAtPoint = useActionUpdatePositionAtPoint();
  const setActivePoint = useSetActivePoint();
  const points = usePoints();
  const comments = useComments();
  const tooltips = useStoreTooltips();
  const hideTooltip = useActionHideTooltip();
  const handlePointsCheckOverlap = useCheckOverlapPoints();
  const deletedComments = useStoreState((state) => state.comments.deletedComments);
  const isVisibleDeletedComments = useStoreState(
    (state) => state.comments.isVisibleDeletedComments
  );
  const setManyActive = useStoreActions((state) => state.points.setManyActive);
  const { endDrawArrow, startDrawArrow } = useDrawingArrow();

  const arrowsPointToPoint = modeMap.isRead
    ? mapArrowWithStats(arrows, pointOutputsStatistics)
    : arrows;

  const onGroupDragMove = React.useCallback(
    (konvaEvent: any) => {
      konvaEvent.evt.preventDefault();
      const group = konvaEvent.currentTarget;
      const { children } = group;

      if (tooltips.length > 0) {
        activePointIds.forEach((point) => {
          hideTooltip({ id: point, force: true });
        });
      }
      bus.emit({
        type: 'reCalcArrowPosition$',
        withCopmplete: false,
        payload: children
      });
    },
    [activePointIds, hideTooltip, tooltips.length, bus]
  );

  const onGroupDragEnd = React.useCallback(
    (konvaEvent: any) => {
      konvaEvent.evt.preventDefault();
      const selectedPoints = cloneDeep(activePointIds);
      const group = konvaEvent.currentTarget;
      const { children } = group;

      children.forEach((point: any) => {
        const { pointId } = point.attrs;

        actionUpdatePositionAtPoint(pointId, {
          x: point.x() + group.x(),
          y: point.y() + group.y()
        });

        setActivePoint('');
        setManyActive(selectedPoints);
      });

      handlePointsCheckOverlap();

      bus.emit({
        type: 'reCalcArrowPosition$',
        withCopmplete: false,
        payload: children
      });
    },
    [
      actionUpdatePositionAtPoint,
      bus,
      handlePointsCheckOverlap,
      activePointIds,
      setActivePoint,
      setManyActive
    ]
  );

  const selectedPoints = React.useMemo(
    () => points.filter((point) => activePointIds.length > 1 && activePointIds.includes(point.id)),
    [activePointIds, points]
  );

  const unselectedPoints = React.useMemo(
    () =>
      points.filter((point) => activePointIds.length <= 1 || !activePointIds.includes(point.id)),
    [activePointIds, points]
  );

  return (
    <>
      {arrowsPointToPoint.map((arrow) => {
        const isActiveFromPoint = activePointIds.includes(arrow.fromPointId);
        const isActiveToPoint = activePointIds.includes(arrow.toPointId);

        return (
          <DrawArrowMemo
            key={arrow.id}
            arrow={arrow}
            isActive={isActiveFromPoint || isActiveToPoint}
          />
        );
      })}
      {unselectedPoints.map((item) => (
        <DrawPoint
          key={item.id}
          point={item}
          onMouseUp={endDrawArrow}
          onMouseDownOutput={startDrawArrow}
        />
      ))}
      {selectedPoints.length > 1 && (
        <Group draggable onDragMove={onGroupDragMove} onDragEnd={onGroupDragEnd}>
          {selectedPoints.map((item) => (
            <DrawPoint
              key={`${item.id}-select`}
              point={item}
              onMouseUp={endDrawArrow}
              onMouseDownOutput={startDrawArrow}
            />
          ))}
        </Group>
      )}
      {comments.map((comment) => (
        <Comment
          key={comment.id}
          position={comment.position}
          createdAt={comment.createdAt}
          commentId={comment.id}
          text={comment.title}
          message={comment.message}
        />
      ))}
      {isVisibleDeletedComments &&
        deletedComments.map((comment) => (
          <DeletedComment
            key={comment.id}
            position={comment.position}
            commentId={comment.id}
            text={comment.title}
          />
        ))}
    </>
  );
}

export const DrawItemsMemo = DrawItems;
