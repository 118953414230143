import styled, { FlattenInterpolation, ThemedStyledProps, css } from 'styled-components';
import { Spacing, ShapeRadius } from '@pushwoosh/kit-constants';
import { IButtonProps } from '../Button.types';
import {
  primaryShapeCompactCSS,
  primaryShapeFieldCSS,
  primaryGhostCompactCSS,
  primaryGhostFieldCSS
} from './primary';
import {
  secondaryShapeCompactCSS,
  secondaryShapeFieldCSS,
  secondaryGhostCompactCSS,
  secondaryGhostFieldCSS
} from './secondary';
import {
  dangerGhostCompactCSS,
  dangerGhostFieldCSS,
  dangerShapeCompactCSS,
  dangerShapeFieldCSS
} from './danger';

type CssType = FlattenInterpolation<ThemedStyledProps<IButtonProps, any>>;

type ButtonCssMapType = {
  [color in IButtonProps['color']]: {
    [view in IButtonProps['view']]: {
      [size in IButtonProps['size']]: CssType;
    };
  };
};

const buttonCssMap: ButtonCssMapType = {
  primary: {
    shape: {
      field: primaryShapeFieldCSS,
      compact: primaryShapeCompactCSS
    },
    ghost: {
      field: primaryGhostFieldCSS,
      compact: primaryGhostCompactCSS
    }
  },
  secondary: {
    shape: {
      field: secondaryShapeFieldCSS,
      compact: secondaryShapeCompactCSS
    },
    ghost: {
      field: secondaryGhostFieldCSS,
      compact: secondaryGhostCompactCSS
    }
  },
  danger: {
    shape: {
      field: dangerShapeFieldCSS,
      compact: dangerShapeCompactCSS
    },
    ghost: {
      field: dangerGhostFieldCSS,
      compact: dangerGhostCompactCSS
    }
  }
};

const getButtonStyle = (props: IButtonProps) => {
  const { color, view, size } = props;

  return buttonCssMap[color][view][size];
};

const leftIconCss = css<IButtonProps>`
  margin-right: ${Spacing.S1};
  margin-left: -${Spacing.S2};
`;

const rightIconCss = css<IButtonProps>`
  margin-right: -${Spacing.S2};
  margin-left: ${Spacing.S1};
`;

const iconOnlyCss = css<IButtonProps>`
  margin-right: -${Spacing.S2};
  margin-left: -${Spacing.S2};
`;

const getIconStyle = (isIconOnly: boolean, iconPosition: IButtonProps['iconPosition']) => {
  if (isIconOnly) return iconOnlyCss;

  if (iconPosition === 'left') return leftIconCss;

  return rightIconCss;
};

export const StyledButton = styled.button<IButtonProps & { isIconOnly?: boolean }>`
  display: flex;
  padding: 0 ${Spacing.S4};
  align-items: center;
  border-radius: ${ShapeRadius.CONTROL};
  outline: transparent;
  cursor: pointer;
  pointer-events: ${({ isLoading, disabled }) => (isLoading || disabled ? 'none' : 'auto')};

  ${getButtonStyle}

  & > svg,
  & > div > svg {
    ${({ isIconOnly, iconPosition }) => getIconStyle(isIconOnly, iconPosition)}
  }
`;

export const Label = styled.div<IButtonProps>`
  opacity: ${({ isLoading }) => (isLoading ? '0.5' : '1')};
  display: flex;
  align-items: center;
  line-height: 100%;
`;
