import * as React from 'react';

// Styled
import {
  DocumentationContainer,
  DocumentationImageContainerZoom,
  DocumentationParagraph,
  DocumentationList,
  DocumentationImageContainer,
  DocumentationImageLabel,
  DocumentationSubtitle,
  DocumentationNumberedList
} from './styled';

function DocumentationListItem(props: React.LiHTMLAttributes<HTMLElement>): JSX.Element {
  return <DocumentationParagraph as="li" {...props} />;
}

function DocumentationImage(
  props: React.ImgHTMLAttributes<HTMLImageElement> & { label?: string }
): JSX.Element {
  const { alt, label, src, ...otherProps } = props;

  const [isZooming, setIsZooming] = React.useState(false);

  const onZoomIn = React.useCallback((): void => setIsZooming(true), [setIsZooming]);
  const onZoomOut = React.useCallback((): void => setIsZooming(false), [setIsZooming]);
  // eslint-disable-next-line camelcase
  const imageUrl = `${__webpack_public_path__}${src}`;

  return (
    <>
      <DocumentationImageContainer onClick={onZoomIn}>
        <img alt={alt} src={imageUrl} {...otherProps} crossOrigin="anonymous" />
        {label ? <DocumentationImageLabel as="figcaption">{label}</DocumentationImageLabel> : null}
      </DocumentationImageContainer>

      {isZooming ? (
        <DocumentationImageContainerZoom onClick={onZoomOut}>
          <img alt={alt} src={imageUrl} {...otherProps} crossOrigin="anonymous" />
        </DocumentationImageContainerZoom>
      ) : null}
    </>
  );
}

export function DocElements(props: React.HTMLAttributes<HTMLElement>): JSX.Element {
  return <DocumentationContainer {...props} />;
}

DocElements.Paragraph = DocumentationParagraph;
DocElements.List = DocumentationList;
DocElements.ListItem = DocumentationListItem;
DocElements.Image = DocumentationImage;
DocElements.Subtitle = DocumentationSubtitle;
DocElements.NumberedList = DocumentationNumberedList;
