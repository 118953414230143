import * as React from 'react';

export function RichMediaEmptyPreviewSVG(): JSX.Element {
  return (
    <svg
      width="164"
      height="150"
      viewBox="0 0 164 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5" filter="url(#filter0_dd)">
        <rect x="16" y="6" width="132" height="118" rx="4" fill="white" />
        <path
          d="M34.0996 25.4951V28H32.6348V20.8906H35.4082C35.9421 20.8906 36.4108 20.9883 36.8145 21.1836C37.2214 21.3789 37.5339 21.6572 37.752 22.0186C37.9701 22.3766 38.0791 22.7852 38.0791 23.2441C38.0791 23.9408 37.8398 24.4909 37.3613 24.8945C36.8861 25.2949 36.2269 25.4951 35.3838 25.4951H34.0996ZM34.0996 24.3086H35.4082C35.7956 24.3086 36.0902 24.2174 36.292 24.0352C36.4971 23.8529 36.5996 23.5924 36.5996 23.2539C36.5996 22.9056 36.4971 22.624 36.292 22.4092C36.0869 22.1943 35.8037 22.0837 35.4424 22.0771H34.0996V24.3086ZM41.7217 25.3975H40.5547V28H39.0898V20.8906H41.7314C42.5713 20.8906 43.2191 21.0778 43.6748 21.4521C44.1305 21.8265 44.3584 22.3555 44.3584 23.0391C44.3584 23.5241 44.2526 23.9294 44.041 24.2549C43.8327 24.5771 43.5153 24.8343 43.0889 25.0264L44.627 27.9316V28H43.0547L41.7217 25.3975ZM40.5547 24.2109H41.7363C42.1042 24.2109 42.389 24.1182 42.5908 23.9326C42.7926 23.7438 42.8936 23.485 42.8936 23.1562C42.8936 22.821 42.7975 22.5573 42.6055 22.3652C42.4167 22.1732 42.1253 22.0771 41.7314 22.0771H40.5547V24.2109ZM49.7539 24.9189H46.9414V26.8232H50.2422V28H45.4766V20.8906H50.2324V22.0771H46.9414V23.7715H49.7539V24.9189ZM53.7285 26.2373L55.3398 20.8906H56.9707L54.4951 28H52.9668L50.501 20.8906H52.127L53.7285 26.2373ZM59.2021 28H57.7373V20.8906H59.2021V28ZM64.8418 24.9189H62.0293V26.8232H65.3301V28H60.5645V20.8906H65.3203V22.0771H62.0293V23.7715H64.8418V24.9189ZM71.7266 25.9492L72.6836 20.8906H74.1436L72.5664 28H71.0918L69.9346 23.2441L68.7773 28H67.3027L65.7256 20.8906H67.1855L68.1475 25.9395L69.3193 20.8906H70.5596L71.7266 25.9492Z"
          fill="#89A4BF"
        />
        <rect opacity="0.2" x="32" y="42" width="100" height="8" rx="2" fill="#89A4BF" />
        <rect opacity="0.2" x="32" y="58" width="86" height="8" rx="2" fill="#89A4BF" />
        <rect opacity="0.2" x="32" y="74" width="100" height="8" rx="2" fill="#89A4BF" />
        <g opacity="0.2">
          <rect x="32" y="94" width="34" height="18" rx="2" fill="#89A4BF" />
        </g>
        <g opacity="0.2">
          <rect x="70" y="94" width="47" height="18" rx="2" fill="#89A4BF" />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dd"
          x="0"
          y="0"
          width="164"
          height="150"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="8" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
          <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
