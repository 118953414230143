import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgTimeSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.75 4.4a.75.75 0 00-1.5 0V8c0 .284.16.544.415.67l2.4 1.2a.75.75 0 10.67-1.34L8.75 7.536V4.4z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.25a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.75 8a5.25 5.25 0 1110.5 0 5.25 5.25 0 01-10.5 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgTimeSmallLined;
