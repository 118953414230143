import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgTutorialMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.73 16.675a.748.748 0 00.02-.198v-4.513l3.586-1.793a.75.75 0 00-.016-1.35L12.336 4.33a.75.75 0 00-.671 0L2.677 8.823a.75.75 0 00-.427.7V16.5a.75.75 0 001.5 0v-5.787l2.5 1.25V16.5a.747.747 0 00.433.68l4.982 2.49a.75.75 0 00.67 0l4.989-2.493a.744.744 0 00.353-.354.747.747 0 00.053-.148zM12 5.84L19.323 9.5l-2.641 1.32a.758.758 0 00-.033.017L12 13.161 4.678 9.5 12 5.839zm4.25 6.875l-3.914 1.957a.75.75 0 01-.67 0L7.75 12.713v3.323L12 18.161l4.25-2.125v-3.322z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgTutorialMediumLined;
