import { format } from 'date-fns';

// Types
import { IUniqueId } from '@src/common/types/entities';
import { TPoints } from '@src/common/types/points';
import { TComment } from '@src/common/types/comments';

interface IItem {
  updatedAt: string;
  points: TPoints[];
  comments: TComment[];
}
type TItem = IItem | null;

interface IStorage {
  [key: string]: TItem;
}

const KEY = 'pointStorage';

const getDataStorage = (key: IUniqueId['id']): IStorage => {
  const data = JSON.parse(window.localStorage.getItem(key));
  if (!data) {
    return {};
  }
  return data as IStorage;
};

const setDataStorage = (key: IUniqueId['id'], data: IStorage): void =>
  window.localStorage.setItem(key, JSON.stringify(data));

const setPoints = (journeyId: IUniqueId['id'], points: TPoints[], comments: TComment[]): void => {
  const storage = getDataStorage(KEY);
  const date = new Date();
  const updatedAt = format(date, "yyyy-MM-dd'T'HH:mm:ss");

  storage[journeyId] = {
    ...storage[journeyId],
    points,
    comments,
    updatedAt
  };
  setDataStorage(KEY, storage);
};

const getPoints = (journeyId: IUniqueId['id']): TItem => {
  const storage = getDataStorage(KEY);
  if (storage[journeyId]) {
    return storage[journeyId];
  }

  return null;
};

const removePoints = (journeyId: IUniqueId['id']): void => {
  const storage = getDataStorage(KEY);
  delete storage[journeyId];
  setDataStorage(KEY, storage);
};

export const pointStorage = {
  setPoints,
  getPoints,
  removePoints
};
