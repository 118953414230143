import styled, { css } from 'styled-components';
import { Spacing, Color, ShapeRadius } from '@pushwoosh/kit-constants';
import { Placement } from '@popperjs/core';
// Components
import { Plate as PlateComponent } from '../Plate';

type StyledProps = {
  view: 'light' | 'dark';
  position?: Placement;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styles?: any;
};

export const ChildrenContainer = styled(PlateComponent)<StyledProps>`
  left: 0;
  top: 0;
  position: absolute;
  width: auto;
  z-index: 999;
  background-color: ${({ view }) => (view === 'light' ? Color.CLEAR : Color.MAIN)};
  border-color: ${({ view }) => (view === 'light' ? Color.FORM : Color.MAIN)};
  color: ${({ view }) => (view === 'light' ? Color.MAIN : Color.CLEAR)};
  ${({ styles }) => styles};
`;

const cssArrow = css`
  position: absolute;

  width: ${Spacing.S4};
  height: ${Spacing.S4};
  box-sizing: border-box;

  background-color: ${({ view }: StyledProps) => (view === 'light' ? Color.CLEAR : Color.MAIN)};
  border-left: 1px solid ${({ view }) => (view === 'light' ? Color.CLEAR : Color.MAIN)};
  border-top: 1px solid ${({ view }) => (view === 'light' ? Color.CLEAR : Color.MAIN)};
  border-radius: ${ShapeRadius.SECTION} 0;
`;

export const ArrowBottom = styled.div`
  top: 0;
  left: 50%;
  ${cssArrow}
  transform: translate3d(-50%, -50%, 0)  rotate(45deg);
`;

export const ArrowTop = styled.div`
  bottom: -12px;
  left: ${({ position }: StyledProps) => (position === 'top-end' ? '85%' : '50%')};
  ${cssArrow}
  transform: translate3d(-50%, -50%, 0)  rotate(-45deg);
`;

export const ArrowRight = styled.div`
  bottom: ${Spacing.S3};
  left: 0%;
  ${cssArrow}
  transform: translate3d(-50%, -50%, 0)  rotate(-45deg);
`;

export const ArrowRightStart = styled.div`
  top: ${Spacing.S5};
  left: 0%;
  ${cssArrow}
  transform: translate3d(-50%, -50%, 0)  rotate(-45deg);
`;
