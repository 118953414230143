import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPlatformLogoAmazonMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.535 16.875a.513.513 0 00.58-.059l.007.007c.49-.435 1.38-1.211 1.881-1.629.2-.165.166-.431.007-.652-.048-.067-.097-.132-.145-.197-.4-.54-.78-1.052-.78-2.078V8.443c0-.13 0-.26.002-.39.008-1.466.015-2.806-1.082-3.831C15.062 3.318 13.502 3 12.308 3c-2.333 0-4.939.87-5.488 3.755-.055.307.166.47.366.515l2.382.255c.22-.01.383-.228.424-.449.204-.994 1.04-1.474 1.974-1.474.504 0 1.077.187 1.378.639.306.452.302 1.055.297 1.595v.5c-.23.026-.471.05-.72.076-1.29.132-2.778.285-3.895.777-1.536.662-2.617 2.019-2.617 4.01 0 2.55 1.609 3.824 3.673 3.824 1.746 0 2.699-.41 4.045-1.784.071.102.134.196.194.284.317.47.529.783 1.214 1.352zm-1.894-5.91v-.662c-1.775 0-3.649.38-3.649 2.472 0 1.06.549 1.777 1.491 1.777.69 0 1.308-.424 1.698-1.115.462-.81.46-1.573.46-2.472zm2.474 5.851z"
        fill={props.fill}
      />
      <path
        d="M3.504 17.115c3.048 1.858 7.766 4.734 15.284 1.156.325-.135.551.09.23.5-.323.413-2.903 2.78-7.24 2.78-4.335 0-7.656-2.962-8.67-4.19-.277-.32.043-.463.231-.347l.165.101z"
        fill={props.fill}
      />
      <path
        d="M19.031 16.846c.658-.08 1.702-.03 1.908.237.156.204-.006 1.12-.27 1.777-.267.654-.664 1.115-.884 1.293-.222.179-.386.11-.267-.158.122-.268.794-1.928.528-2.276-.247-.323-1.365-.206-1.884-.152l-.105.011c-.11.01-.198.02-.27.028-.223.025-.283.031-.317-.037-.085-.242.903-.644 1.561-.723z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPlatformLogoAmazonMediumFilled;
