import * as React from 'react';
import { cloneDeep } from 'lodash';
import { PersonaliseMessage } from '@src/ui/features/PersonaliseMessage';
import { Section } from '@src/ui/kit';
import { H3 } from '@pushwoosh/kit-typography';
import { OptionsWrap, TitleWrap } from '@src/ui/form/styled';
import { ContentSettings } from '../ContentSettings';
import { SplitFlow } from '../SplitFlow';
import { SendByUserId } from '../SendByUserId';
import { OptimalTime } from '../OptimalTime';
import { IEditPointSendPushProps } from './EditPointSendPush.types';

export function EditPointSendPush(props: IEditPointSendPushProps): JSX.Element {
  const {
    applicationCode,
    presetSelectOptions,
    presets,
    names,
    errors,
    values,
    isLoadingPresetOptions = false,
    setFieldValue,
    setValues,
    setFieldTouched,
    journeyStatus,
    pointId,
    onSubmit
  } = props;

  const handleSwitchPersonalise = React.useCallback(
    (value: boolean) => {
      setValues({
        ...values,
        [names.enabledPersonalise]: value
      });
    },
    [names.enabledPersonalise, values, setValues]
  );

  const handleSwitchLiquid = React.useCallback(
    (value: boolean) => {
      setFieldValue(names.enabledLiquid, value);
    },
    [names.enabledLiquid, setFieldValue]
  );

  const handleLiquidEventChange = React.useCallback(
    (value: string) => {
      setFieldValue(names.liquidEventName, value);
    },
    [names.liquidEventName, setFieldValue]
  );

  const togglePlaceholder = React.useCallback(
    (value: boolean, index: number) => {
      const newValues = cloneDeep(values);

      newValues.placeholders[index].isSelected = value;

      setValues(newValues);
    },
    [setValues, values]
  );

  const selectEvent = React.useCallback(
    (value: string, pointId: string, index: number) => {
      const newValues = cloneDeep(values);

      newValues.placeholders[index].eventName = value;
      newValues.placeholders[index].pointID = pointId;

      setValues(newValues);
    },
    [setValues, values]
  );

  const selectAttr = React.useCallback(
    (value: string, index: number) => {
      const newValues = cloneDeep(values);

      newValues.placeholders[index].attribute = value;

      setValues(newValues);
    },
    [setValues, values]
  );

  return (
    <>
      <ContentSettings
        applicationCode={applicationCode}
        values={values}
        names={names}
        presetSelectOptions={presetSelectOptions}
        isLoadingPresetOptions={isLoadingPresetOptions}
        presets={presets}
        setValues={setValues}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        pointId={pointId}
      />
      {values.isPresetAvailable && values.presetCode && (
        <PersonaliseMessage
          pointId={pointId}
          enabledPersonalise={values.enabledPersonalise}
          enabledLiquid={values.enabledLiquid}
          liquidEvent={values.liquidEventName}
          placeholders={values.placeholders}
          onSwitch={handleSwitchPersonalise}
          onSelectPlaceholder={togglePlaceholder}
          onSelectEvent={selectEvent}
          onSelectAttribute={selectAttr}
          onLiquidEnabledChange={handleSwitchLiquid}
          onLiquidEventChange={handleLiquidEventChange}
          error={errors.placeholders as string}
          onSubmit={onSubmit}
        />
      )}
      <Section direction="column">
        <TitleWrap>
          <H3>Options</H3>
        </TitleWrap>
        <OptionsWrap>
          <OptimalTime values={values} names={names} setFieldValue={setFieldValue} />
        </OptionsWrap>
        <OptionsWrap>
          <SendByUserId
            journeyStatus={journeyStatus}
            values={values}
            names={names}
            setFieldValue={setFieldValue}
          />
        </OptionsWrap>
        <OptionsWrap>
          <SplitFlow
            journeyStatus={journeyStatus}
            values={values}
            names={names}
            setValues={setValues}
            setFieldValue={setFieldValue}
          />
        </OptionsWrap>
      </Section>
    </>
  );
}
