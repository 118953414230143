import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgTrophyMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.476 5.845a.5.5 0 00-.952 0l-.56 1.728H9.146a.5.5 0 00-.294.904l1.47 1.068-.562 1.728a.5.5 0 00.77.559L12 10.764l1.469 1.068a.5.5 0 00.769-.56l-.561-1.727 1.47-1.068a.5.5 0 00-.295-.904h-1.816l-.561-1.728zm-.674 2.382L12 7.618l.198.61a.5.5 0 00.476.345h.64l-.518.377a.5.5 0 00-.182.559l.198.609-.518-.377a.5.5 0 00-.588 0l-.518.377.198-.61a.5.5 0 00-.182-.558l-.518-.377h.64a.5.5 0 00.476-.346z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 5c0-.966.784-1.75 1.75-1.75h10c.966 0 1.75.784 1.75 1.75v.25H20c.966 0 1.75.784 1.75 1.75v2a3.75 3.75 0 01-3.582 3.746 6.756 6.756 0 01-5.418 3.963v1.041H15c.966 0 1.75.784 1.75 1.75v.5A1.75 1.75 0 0115 21.75H9A1.75 1.75 0 017.25 20v-.5c0-.966.784-1.75 1.75-1.75h2.25v-1.041a6.756 6.756 0 01-5.418-3.963A3.75 3.75 0 012.25 9V7c0-.966.784-1.75 1.75-1.75h1.25V5zm12 5V5a.25.25 0 00-.25-.25H7a.25.25 0 00-.25.25v5a5.25 5.25 0 1010.5 0zm1.5-3.25v4.372A2.251 2.251 0 0020.25 9V7a.25.25 0 00-.25-.25h-1.25zM4 6.75h1.25v4.372A2.251 2.251 0 013.75 9V7A.25.25 0 014 6.75zm5 12.5a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25h6a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25H9z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgTrophyMediumLined;
