import React from 'react';
import { StyledButton, ButtonField, ButtonWrap } from './styled';

type Props = {
  cloneJourney: () => void;
};

export function CloneButton(props: Props): JSX.Element {
  const { cloneJourney } = props;

  return (
    <ButtonWrap>
      <StyledButton onClick={cloneJourney}>
        <ButtonField>Clone campaign</ButtonField>
      </StyledButton>
    </ButtonWrap>
  );
}
