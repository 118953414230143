import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgDangerTriangleMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.055 18.76L12.438 4.91a.5.5 0 00-.876 0L3.944 18.76a.5.5 0 00.438.74h15.235a.5.5 0 00.438-.74zM13.752 4.185c-.76-1.381-2.745-1.381-3.505 0L2.63 18.036C1.897 19.37 2.861 21 4.382 21h15.235c1.522 0 2.486-1.63 1.753-2.964l-7.618-13.85zM11 9.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-5zm.5 6.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgDangerTriangleMediumLined;
