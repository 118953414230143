import * as React from 'react';

// Analytics
import { sendMixpanelEvent } from '@src/common/analytics';
import { SidebarType } from '@src/common/constants/sidebars';

// Store Hooks
import {
  useCurrentJourneyId,
  useGetJourneyById,
  useGetPointById,
  useOpenNewModal,
  useUpdatePoint,
  useEditJourneyById,
  useGoalEventPoints
} from '@src/store/hooks';
import { useStoreActions, useStoreState } from '@src/store/store';

// UI Hooks
import { useForm } from '@src/ui/hooks';

// Constants
import { Distribute } from '@src/ui/features/ABSplitter/components/EditABSplitter/constants';

// UI Components
import { Modal } from '@src/ui/kit/Modal';
import { Section, IconEnum, Message } from '@src/ui/kit';
import { Paragraph } from '@pushwoosh/kit-typography';
import { InfoRoundIcon } from '@pushwoosh/kit-icons';
import { DefaultButtons } from '@src/ui/kit/ModalButtons';
import { Button } from '@src/ui/kit/Button';
import {
  EditABSplitter,
  IFormEditABSplitterValues
} from '@src/ui/features/ABSplitter/components/EditABSplitter';

// Types
import { TABSplitterPoint } from '@src/common/types/points';
import { IUniqueId } from '@src/common/types/entities';
import { isEqual } from 'lodash';
import { JourneyStatus, ModalType } from '@src/common/constants';
import { Color } from '@pushwoosh/kit-constants';
import { FormBlock } from '@src/ui/form/styled';

// Styled
import { ButtonsWrap, StyledMessageIconWrapper } from '../styled';

interface IProps {
  pointId: IUniqueId['id'];
  closeModal: () => void;
}

function ABSlitter(props: IProps): JSX.Element {
  const { pointId, closeModal } = props;

  const [saveDisabled, setSaveDisabled] = React.useState(false);
  const getPointById = useGetPointById();
  const updatePoint = useUpdatePoint();
  const showDocumentationMenu = useStoreActions((actions) => actions.documentationMenu.show);
  const journeyId = useCurrentJourneyId();
  const getJourneyById = useGetJourneyById();
  const openNewModal = useOpenNewModal();
  const editPoint = useStoreActions((actions) => actions.points.update);
  const handleEditJourneyById = useEditJourneyById();
  const sidebars = useStoreState((state) => state.sidebars);
  const openNewSidebar = useStoreActions((actions) => actions.sidebars.openNewSidebar);
  const closeAllSidebars = useStoreActions((actions) => actions.sidebars.closeAllSidebars);
  const { goalEventPoints } = useGoalEventPoints();

  const { status } = getJourneyById(journeyId);

  const point = getPointById(pointId) as TABSplitterPoint;

  const isJourneyActive = status === JourneyStatus.RUNNING;

  const {
    values,
    setFieldValue,
    setValues,
    setFieldTouched,
    handleSubmit,
    handleChange,
    handleBlur
  } = useForm<IFormEditABSplitterValues>({
    initialValues: {
      split: point.data.split || [50, 50]
    },
    onSubmit(formValues): void {
      const newPoint = {
        id: point.id,
        data: {
          split: [...formValues.split]
        }
      };

      if (!isJourneyActive) {
        updatePoint(newPoint);

        sendMixpanelEvent({
          eventName: 'ABSplitterConfigured',
          eventData: {
            BranchCount: formValues.split.length
          }
        });

        closeModal();
      } else {
        editPoint(newPoint);

        handleEditJourneyById(journeyId);

        sendMixpanelEvent({
          eventName: 'JourneyEditModeChangesApplied',
          eventData: {
            PointType: 'ABSplitter'
          }
        });

        closeModal();

        openNewModal({
          type: ModalType.DETAIL_POINT,
          data: {
            pointId
          }
        });
      }
    }
  });

  const names = React.useMemo(
    () => ({
      split: 'split'
    }),
    []
  );

  const handleResetDistribution = React.useCallback(() => {
    if (values.split.length === 2) {
      setFieldValue(names.split, Distribute.two);
    }
    if (values.split.length === 3) {
      setFieldValue(names.split, Distribute.three);
    }
    if (values.split.length === 4) {
      setFieldValue(names.split, Distribute.four);
    }
  }, [values, names, setFieldValue]);

  const handleCancel = React.useCallback(() => {
    if (status === JourneyStatus.RUNNING) {
      sendMixpanelEvent({
        eventName: 'JourneyEditModeChangesCancelled',
        eventData: {
          PointType: 'ABSplitter'
        }
      });
    } else {
      sendMixpanelEvent({
        eventName: 'ConfigurePointCancelled',
        eventData: {
          PointType: 'ABSplitter',
          PointChanged: !isEqual(values.split, point.data.split)
        }
      });
    }

    closeModal();
  }, [closeModal, values, point, status]);

  const onShowDocumentation = React.useCallback((): void => {
    if (sidebars.isOpened) {
      closeAllSidebars();
    } else {
      openNewSidebar({
        type: SidebarType.DOCUMENTATION
      });
      showDocumentationMenu();
      sendMixpanelEvent({ eventName: 'ShowDocumentation' });
    }
  }, [showDocumentationMenu, sidebars, closeAllSidebars, openNewSidebar]);

  return (
    <Modal
      title="A/B/n Split"
      footerRight={
        <DefaultButtons
          isDisabledActionButton={saveDisabled}
          onClickActionButton={handleSubmit}
          onClickCancelButton={handleCancel}
          actionButtonName={status === JourneyStatus.RUNNING ? 'Save' : 'Apply'}
        />
      }
      footerLeft={
        <ButtonsWrap>
          <Button
            color="primary"
            size="field"
            view="ghost"
            iconPosition="left"
            iconType={IconEnum.DOCS_MEDIUM_LINED}
            onClick={onShowDocumentation}
          >
            How to Use
          </Button>
        </ButtonsWrap>
      }
      isOpen
    >
      <Section direction="column">
        <Paragraph>
          Improve your campaigns by testing different messaging and interaction options through
          audience splitting. Monitor real-time goal conversion rates and deactivate less effective
          branches to increase the overall efficiency of your campaign.
        </Paragraph>
      </Section>
      <Section direction="column">
        <EditABSplitter
          values={values}
          names={names}
          setFieldValue={setFieldValue}
          setValues={setValues}
          setFieldTouched={setFieldTouched}
          onBlurField={handleBlur}
          onChangeField={handleChange}
          journeyStatus={status}
          setSaveDisabled={setSaveDisabled}
          resetDistribution={handleResetDistribution}
        />
        <Message
          message="For accurate statistical comparison, ensure that Branch A sends any message intended&nbsp;to drive conversions or includes sufficient delays to allow for goal completion.&nbsp;Additionally, it is important to evenly distribute traffic across all branches to determine statistical significance correctly."
          title="Branch A — Control group"
          color={Color.BRIGHT_LIGHT}
          icon={
            <StyledMessageIconWrapper color={Color.BRIGHT_LIGHT}>
              <InfoRoundIcon size="small" view="filled" color={Color.BRIGHT} />
            </StyledMessageIconWrapper>
          }
        />
        {goalEventPoints.length === 0 && (
          <FormBlock>
            <Message
              title="You should add goals to measure branch conversion"
              color={Color.WARNING_LIGHT}
              icon={
                <StyledMessageIconWrapper color={Color.WARNING_LIGHT}>
                  <InfoRoundIcon size="small" view="filled" color={Color.WARNING} />
                </StyledMessageIconWrapper>
              }
            />
          </FormBlock>
        )}
      </Section>
    </Modal>
  );
}

export default React.memo(ABSlitter);
