import { thunk, actionOn, computed } from 'easy-peasy';

// Types
import { TEmail } from '@src/common/types/email';
import { IEmailsModel, TEmailsByAppCode, TEmailByAppCodeAndEmailCode } from './emails.types';

export const model: IEmailsModel = {
  entities: {},

  emailsByAppCode: computed(
    (state): TEmailsByAppCode =>
      (appCode): TEmail[] => {
        const emails = state.entities[appCode];

        return emails !== undefined ? emails : [];
      }
  ),

  emailByAppCodeAndEmailCode: computed(
    (state): TEmailByAppCodeAndEmailCode =>
      (appCode, emailCode): TEmail | null => {
        const emails = state.emailsByAppCode(appCode);
        const email = emails.find((email): boolean => email.code === emailCode);

        return email !== undefined ? email : null;
      }
  ),

  loadByAppCode: thunk(async (_, payload, helpers): Promise<{ code: string; emails: TEmail[] }> => {
    const {
      injections: { emailService, api }
    } = helpers;

    const response = await emailService.fetchByAppCode(payload, api);

    return {
      code: payload,
      emails: response.payload.items
    };
  }),

  loadPlaceholders: thunk(async (_, payload, helpers): Promise<{ items: string[] }> => {
    const {
      injections: { emailService, api }
    } = helpers;

    const response = await emailService.fetchPlaceholders(payload, api);

    return {
      items: response.payload.items
    };
  }),

  onLoad: actionOn(
    (state): string => state.loadByAppCode.successType,
    (state, target): void => {
      state.entities[target.result.code] = target.result.emails;
    }
  )
};
