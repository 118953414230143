import { createContext, useContext } from 'react';

import { History } from 'history';

const HistoryContext = createContext<History | null>(null);
export const HistoryProvider = HistoryContext.Provider;

export function useHistory(): History {
  const history = useContext(HistoryContext);
  if (!history) {
    throw new Error(
      'HistoryContext is not defined! Please check what are you using useHistory in a child of HistoryProvider!'
    );
  }

  return history;
}
