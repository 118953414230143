// Types
import { IJourney } from '@src/common/types/journey';
import { IChannelSilentHours, IJourneySilentHours } from '@src/common/types/silentHours';

const defineChannelSilentHours = (csh?: IChannelSilentHours): IChannelSilentHours => ({
  enabled: csh?.enabled || false,
  fromTime: {
    hour: csh?.fromTime?.hour || 22,
    minute: csh?.fromTime?.minute || 0
  },
  toTime: {
    hour: csh?.toTime?.hour || 7,
    minute: csh?.toTime?.minute || 0
  },
  weekDays: csh?.weekDays || [false, false, false, false, false, false, false],
  behavior: csh?.behavior || 'waitAndSend'
});

export const defineSilentHours = (journey: IJourney): IJourneySilentHours => ({
  inAppParams: defineChannelSilentHours(journey?.params?.silentHours?.inAppParams),
  pushParams: defineChannelSilentHours(journey?.params?.silentHours?.pushParams),
  emailParams: defineChannelSilentHours(journey?.params?.silentHours?.emailParams),
  smsParams: defineChannelSilentHours(journey?.params?.silentHours?.smsParams)
});
