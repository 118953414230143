import styled, { css, keyframes } from 'styled-components';

// Constants
import { Spacing } from '@pushwoosh/kit-constants';

// Types
import { Props } from './Loading.types';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

interface CircleProps {
  size: Props['size'];
}

const rotate = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
`;

const sizeCss = css`
  ${(props: CircleProps) =>
    props.size === 'small' &&
    `
    width: ${Spacing.S1};
    height: ${Spacing.S1};    
  `}
  ${(props) =>
    props.size === 'medium' &&
    `
    width: ${Spacing.S4};
    height:${Spacing.S4};    
  `}
  ${(props) =>
    props.size === 'large' &&
    `
    width: ${Spacing.S7};
    height:${Spacing.S7};    
  `}
  margin: ${Spacing.S5};
  border-radius: 100%;
  background: #b3e5fc;
  animation: ${rotate} 1s linear infinite;
`;

export const FirstCircle = styled.div`
  ${sizeCss}
`;

export const SecondCircle = styled.div`
  ${sizeCss}
  animation-delay: 0.2s;
`;

export const ThirdCircle = styled.div`
  ${sizeCss}
  animation-delay: 0.4s;
`;
