import { FormikErrors } from 'formik';
import { IEditPointSendPushValues } from '@src/ui/features/SendPush/components/EditPointSendPush';

export function validate(values: IEditPointSendPushValues): FormikErrors<IEditPointSendPushValues> {
  const errors: FormikErrors<IEditPointSendPushValues> = {};

  const isPlaceholderError = values.placeholders.some(
    (placeholder) => placeholder.isSelected && (!placeholder.eventName || !placeholder.attribute)
  );

  if (isPlaceholderError) {
    errors.placeholders = 'Please enter a correct placeholder settings';
  }

  return errors;
}
