import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgLinkSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5 2.283a3.25 3.25 0 00-2.286.913l-.868.862a.75.75 0 001.058 1.064l.856-.851a1.75 1.75 0 012.475 2.474L10.24 8.24a1.75 1.75 0 01-2.64-.19.75.75 0 10-1.2.9 3.25 3.25 0 004.9.35l1.5-1.5.01-.009a3.25 3.25 0 00-2.31-5.508z"
        fill={props.fill}
      />
      <path
        d="M7.23 5.756A3.25 3.25 0 004.7 6.7L3.2 8.2l-.01.009a3.25 3.25 0 004.596 4.596l.864-.865a.75.75 0 00-1.06-1.06l-.85.85a1.75 1.75 0 01-2.475-2.475L5.76 7.76a1.75 1.75 0 012.64.19.75.75 0 101.2-.9 3.25 3.25 0 00-2.37-1.294z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgLinkSmallLined;
