import React from 'react';

import { IconEnum } from '@src/ui/kit';

// Styled
import {
  AccordionItemDescription,
  AccordionItemTitle,
  AccordionItemWrapper,
  AccordionItemIcon,
  AccordionItemIconWrapper
} from './styled';

type AccordionItemProps = {
  id: number;
  title: string;
  description: string;
  isActive: boolean;
  handleSetActive: (key: number) => void;
};

export function AccordionItem(props: AccordionItemProps): JSX.Element {
  const { title, description, isActive, id, handleSetActive } = props;
  return (
    <AccordionItemWrapper $isActive={isActive} onClick={() => handleSetActive(id)}>
      <div>
        <AccordionItemTitle>{title}</AccordionItemTitle>
        <AccordionItemDescription>{description}</AccordionItemDescription>
      </div>
      <AccordionItemIconWrapper>
        <AccordionItemIcon type={IconEnum.CHEVRO_RIGHT_MEDIUM} $isActive={isActive} />
      </AccordionItemIconWrapper>
    </AccordionItemWrapper>
  );
}
