import styled from 'styled-components';

// Constants
import { Shadow, Color } from '@pushwoosh/kit-constants';

export const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-shadow: ${Shadow.REGULAR};
  background-color: ${Color.CLEAR};
`;
