// Store
import { useStoreState } from '@src/store/store';

// Types
import { TJourney } from '@src/common/types/journey';

type TResult = TJourney[];

export function useJourneys(): TResult {
  const journeys: TJourney[] = useStoreState((state): TJourney[] => state.journeys.items);

  return journeys;
}
