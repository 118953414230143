// Store
import { useStoreActions, useStoreState } from '@src/store/store';

// Types
import { TRequest } from '@src/store/models/requests/requests.types';
import { TEventsByMultipleAppCode } from '@src/common/types/event';

type TActionLoad = (payload: string[]) => Promise<TEventsByMultipleAppCode>;
type TResult = [TActionLoad, boolean];

export function useLoadEventsByMultipleAppCodes(): TResult {
  const load: TActionLoad = useStoreActions((actions): TActionLoad => actions.event.loadByAppCodes);

  const request: TRequest = useStoreState(
    (state): TRequest => state.requests.entities['event.loadByAppCodes']
  );

  return [load, Boolean(request)];
}
