import * as React from 'react';

// Analytics
import { sendMixpanelEvent } from '@src/common/analytics';
import { SidebarType } from '@src/common/constants/sidebars';

// Store Hooks
import { useZoom, useIncrementZoom, useDecrementZoom, useResetZoom } from '@src/store/hooks';
import { useStoreActions, useStoreState } from '@src/store/store';

// Constants
import { ZOOM_MAX_RANGE, ZOOM_MIN_RANGE } from '@src/common/constants/canvas';

// UI Components
import { Controls } from '@src/ui/features/ControlsPanel/components/Controls';

// Helpers
import { sendYandexMetrika } from '@src/common/analytics/analytics';

function ControlsPanel(): JSX.Element {
  const zoom = useZoom();

  const resetZoom = useResetZoom();
  const decrementZoom = useDecrementZoom();
  const incrementZoom = useIncrementZoom();
  const closeAllSidebars = useStoreActions((actions) => actions.sidebars.closeAllSidebars);
  const sidebars = useStoreState((state) => state.sidebars);
  const openSidebar = useStoreActions((actions) => actions.sidebars.openNewSidebar);

  const showDocumentationMenu = React.useCallback(() => {
    openSidebar({
      type: SidebarType.DOCUMENTATION
    });
  }, [openSidebar]);

  const showCommentsPanel = React.useCallback(() => {
    if (sidebars.isOpened && sidebars.sidebar.type === SidebarType.COMMENTS) {
      closeAllSidebars();
    } else {
      sendMixpanelEvent({ eventName: 'Canvas:ShowDrawer', eventData: { DrawerName: 'Notes' } });
      closeAllSidebars();
      openSidebar({
        type: SidebarType.COMMENTS
      });
    }
  }, [openSidebar, sidebars, closeAllSidebars]);

  const showChangeLog = React.useCallback(() => {
    if (sidebars.isOpened && sidebars.sidebar.type === SidebarType.HISTORY) {
      closeAllSidebars();
    } else {
      sendMixpanelEvent({
        eventName: 'Canvas:ShowDrawer',
        eventData: { DrawerName: 'Change Log' }
      });
      closeAllSidebars();
      openSidebar({
        type: SidebarType.HISTORY
      });
    }
  }, [openSidebar, sidebars, closeAllSidebars]);

  const isDisabledDecrement = zoom === ZOOM_MIN_RANGE;
  const isDisabledIncrement = zoom === ZOOM_MAX_RANGE;

  const onShowDocumentation = React.useCallback((): void => {
    if (sidebars.isOpened && sidebars.sidebar.type === SidebarType.DOCUMENTATION) {
      closeAllSidebars();
    } else {
      closeAllSidebars();
      showDocumentationMenu();
      sendMixpanelEvent({ eventName: 'Canvas:ShowDrawer', eventData: { DrawerName: 'Tutorial' } });
      sendYandexMetrika('cj-widjet-helper');
    }
  }, [showDocumentationMenu, sidebars, closeAllSidebars]);

  return (
    <Controls
      zoom={zoom}
      isDisabledDecrement={isDisabledDecrement}
      isDisabledIncrement={isDisabledIncrement}
      onDecrementZoom={decrementZoom}
      onIncrementZoom={incrementZoom}
      onResetZoom={resetZoom}
      onShowDocumentation={onShowDocumentation}
      onShowComments={showCommentsPanel as unknown as () => void}
      onShowChangeLog={showChangeLog}
    />
  );
}

export default React.memo(ControlsPanel);
