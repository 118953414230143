import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgMinusMedium(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 12a.75.75 0 01.75-.75h10a.75.75 0 010 1.5H7a.75.75 0 01-.75-.75z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgMinusMedium;
