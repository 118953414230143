import * as React from 'react';

// Components
import { DocElements } from '@src/ui/features/Documentation/components/DocElements';

export function DocumentationCanvas(): JSX.Element {
  return (
    <DocElements>
      <DocElements.Paragraph>
        {
          'Customer Journey is a single-interface marketer tool to create a cross-channel customer experience. Customer Journey allows you to keep track of user behavior and send relevant behavior-based messages to customers on every stage of their lifecycle.'
        }
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        Get benefits from Customer Journey campaigns mapping:
      </DocElements.Paragraph>

      <DocElements.List>
        <DocElements.ListItem>
          <strong>Set up your campaigns </strong>
          faster Create chains of push notifications & emails launched by a user’s actions
        </DocElements.ListItem>

        <DocElements.ListItem>
          <strong>Keep your campaigns organized </strong>
          {
            'Cross-channel messaging displayed in one place. See the whole picture and coordinate your messages with Customer Journey mapping.'
          }
        </DocElements.ListItem>

        <DocElements.ListItem>
          <strong>Optimize the performance</strong>
          {
            'Track the results of your campaigns and fine-tune your messages to improve their efficiency, increase customer retention and reach your goals faster.'
          }
        </DocElements.ListItem>
      </DocElements.List>

      <DocElements.Paragraph>
        {
          'Visual campaign builder makes it easier to plan your communications. Customer Journey dashboard consists of journey elements and the canvas where you visualize your campaigns.'
        }
      </DocElements.Paragraph>

      <DocElements.Image src="documentation-canvas_gif-1.gif" alt="doc-canvas-gif-1" />

      <DocElements.Paragraph>
        {
          'Communicating with customers throughout their journey — from prospects to brand advocates — you improve your business results and achieve your KPIs efficiently.'
        }
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        {
          'With the Customer Journey, building your marketing campaigns is simple, visualized, and providing all the significant touchpoints are covered.'
        }
      </DocElements.Paragraph>

      <DocElements.Image src="documentation-canvas_img-1.png" alt="doc-canvas-img-1" />
    </DocElements>
  );
}
