import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgBulletCircleFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11 8a3 3 0 11-6 0 3 3 0 016 0z" fill={props.fill} />
    </svg>
  );
}

export default SvgBulletCircleFilled;
