import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgFinishSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 3a.75.75 0 01.75-.75h8a.75.75 0 01.624 1.166L11.401 6l1.723 2.584A.75.75 0 0112.5 9.75H5.25v2.004c.502.014.97.071 1.346.165.213.053.438.128.628.24.14.083.526.343.526.841 0 .498-.386.758-.526.84-.19.113-.415.188-.628.241-.437.11-1 .169-1.596.169-.595 0-1.16-.06-1.596-.169a2.395 2.395 0 01-.628-.24c-.14-.083-.526-.343-.526-.841 0-.498.386-.758.526-.84.19-.113.415-.188.628-.241.108-.027.224-.051.346-.072V3zm1.5 5.25v-4.5h5.849L9.876 5.584a.75.75 0 000 .832l1.223 1.834H5.25z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgFinishSmallLined;
