import styled from 'styled-components';
import { FormInput } from '@src/ui/form/FormInput';

// Constants
import { Color, Spacing } from '@pushwoosh/kit-constants';

export const DetailBlock = styled.div`
  margin-bottom: ${Spacing.S5};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const TagWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: ${Spacing.S1};
  margin-bottom: ${Spacing.S3};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledInput = styled(FormInput)`
  padding-bottom: 0;
  margin-top: ${Spacing.S5};
  width: 100%;
`;

export const ContentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContentText = styled.div`
  margin-top: ${Spacing.S1};
`;

export const GrayText = styled.span`
  color: ${Color.LOCKED};
  margin-left: ${Spacing.S3};
  margin-right: ${Spacing.S3};
`;

export const OptionsRow = styled.div`
  display: flex;
  align-items: center;
`;

export const OptionsText = styled.span`
  margin-left: ${Spacing.S3};
`;
