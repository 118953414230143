import * as React from 'react';
import konva from 'konva';
import { Path } from 'react-konva';

// Constants
import { POINT_MENU_DEFAULT, ENTRY_GROUP_COLOR, PointType } from '@src/common/constants/point';

// Hooks
import { useShowOneBadge } from '@src/canvas/hooks';

// Helpers
import { getTitlePoint } from '@src/canvas/helpers';

// Components
import { Point, PointContent, PointText, PointIcon, PointOutput, PointShortInfo } from '../Point';

// Types
import { IProps } from './PointSegment.types';

export function PointSegment(props: IProps): JSX.Element {
  const { data, propsCommonPoint } = props;

  const [isShowBadge, setShowBadge] = useShowOneBadge(propsCommonPoint.isDrawingArrow);

  const titlePoint = getTitlePoint(PointType.SEGMENT, propsCommonPoint.title, data);

  return (
    <Point type={PointType.SEGMENT} color={ENTRY_GROUP_COLOR} {...propsCommonPoint}>
      <PointContent type={PointType.SEGMENT}>
        <PointIcon type={PointType.SEGMENT} fill={ENTRY_GROUP_COLOR}>
          <Path
            fill="white"
            perfectDrawEnabled={false}
            shadowForStrokeEnabled={false}
            data="M12 15C12 12.2375 14.2375 10 17 10C19.7625 10 22 12.2375 22 15C22 17.7625 19.7625 20 17 20C14.2375 20 12 17.7625 12 15ZM8 27.9329C8 27.9108 8 27.8998 8.00173 27.8278C8.06642 25.1355 10.3749 22.4845 13.0327 22.0504C13.0836 22.0421 12.8765 22.0705 12.8178 22.0785C12.8095 22.0796 12.8095 22.0796 12.8178 22.0785L12.8439 22.0748C15.76 21.6702 18.2855 21.6648 21.1666 22.0632C21.1965 22.0673 21.2086 22.069 21.2086 22.069C21.2086 22.069 21.2029 22.0682 21.1932 22.0669C21.1337 22.0588 20.925 22.0304 20.9821 22.0399C23.6149 22.4767 25.9257 25.1291 25.9978 27.7969C26 27.8779 26 27.8935 26 27.9245C26 27.9945 26 28.0295 25.9991 28.0591C25.9678 29.1173 25.1173 29.9677 24.0592 29.9991C24.0296 29.9999 23.9946 29.9999 23.9246 29.9999H10.0671C10.0048 29.9999 9.97366 29.9999 9.94733 29.9992C8.88405 29.9712 8.0287 29.1159 8.00069 28.0526C8 28.0263 8 27.9951 8 27.9329ZM25 15C25 16.6981 24.1546 18.1978 22.8615 19.1014C22.4779 19.3694 22.532 20 23 20C25.7625 20 28 17.7625 28 15C28 12.2375 25.7625 10 23 10C22.532 10 22.4779 10.6306 22.8615 10.8986C24.1546 11.8022 25 13.3019 25 15ZM29.9246 29.9999H26.7303C27.0737 29.9999 27.2455 29.9999 27.3894 29.9789C28.2641 29.851 28.9511 29.164 29.079 28.2893C29.1 28.1454 29.1 27.9736 29.1 27.6302C29.1 26.9167 29.1 26.56 29.0459 26.16C28.8644 24.8173 28.0672 23.4708 26.9854 22.493C26.8176 22.3413 26.9426 22.0322 27.1666 22.0632L27.2024 22.0681L27.1932 22.0669C27.1337 22.0588 26.925 22.0304 26.9821 22.0399C29.6149 22.4767 31.9257 25.1291 31.9978 27.7969C32 27.8779 32 27.8935 32 27.9245C32 27.9945 32 28.0295 31.9991 28.0591C31.9678 29.1173 31.1173 29.9677 30.0592 29.9991C30.0296 29.9999 29.9946 29.9999 29.9246 29.9999ZM27.2024 22.0681C27.203 22.0682 27.2036 22.0683 27.2041 22.0684C27.2071 22.0688 27.2071 22.0688 27.2041 22.0684L27.2024 22.0681Z"
          />
        </PointIcon>
        <PointText type={PointType.SEGMENT}>{titlePoint}</PointText>
        <PointOutput
          pointId={propsCommonPoint.id}
          type={PointType.SEGMENT}
          colorBlock={ENTRY_GROUP_COLOR}
          isInvalid={propsCommonPoint.isInvalid}
          isActive={propsCommonPoint.isActive}
          isLinkedOutput={propsCommonPoint.isLinkedOutput}
          onMouseDown={(event: konva.KonvaEventObject<MouseEvent>): void => {
            setShowBadge(!isShowBadge);
            propsCommonPoint.onMouseDownOutput(event);
          }}
          textBadge="Connect this"
          fillBadge={POINT_MENU_DEFAULT}
          isShowBadge={propsCommonPoint.isLinkedOutput ? false : isShowBadge}
          arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
        />
      </PointContent>
      <PointShortInfo type={PointType.SEGMENT} label="Entry" align="left" />
    </Point>
  );
}
