import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgArrowDownMedium(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.75 7a.75.75 0 00-1.5 0v9.19l-2.72-2.72a.75.75 0 00-1.06 1.06l4 4a.75.75 0 001.06 0l4-4a.75.75 0 10-1.06-1.06l-2.72 2.72V7z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgArrowDownMedium;
