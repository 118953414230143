import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { Session, SessionStore } from '@pushwoosh/session-store';
import { SessionProvider } from '@src/common/contexts/session';

// Store Hooks
import { useAuth, useNotifications } from '@src/store/hooks';

// UI Hooks
import { useDidMount } from '@src/ui/hooks';

// Container Hooks
import { useKeyPressService } from '@src/containers/hooks/useKeyPressService';

// UI Components
import { LoadingOverlay } from '@pushwoosh/ui/lib/molecules/LoadingOverlay';
import { Notification } from '@src/ui/molecules';

interface IProps {
  children: React.ReactNode;
  sessionStore: SessionStore;
}

export function App(props: IProps): JSX.Element {
  const [session, setSession] = React.useState(null);

  const { checkAuth, isAuthorized } = useAuth();
  const notifications = useNotifications();

  const refToast = React.useRef<ReturnType<typeof Notification>>(null);
  const { pathname } = useLocation();
  const { sessionStore } = props;

  useKeyPressService();

  useDidMount((): void => {
    checkAuth();
  });

  React.useEffect((): void => {
    if (refToast.current && pathname === '/') {
      refToast.current.dismiss();
    }
  }, [refToast, pathname]);

  React.useEffect((): void => {
    refToast.current = Notification({ notifications });
  }, [notifications]);

  React.useEffect(() => {
    let subscriber: any = null;
    const getSession = async () => {
      await sessionStore.update();
    };

    if (isAuthorized) {
      getSession().then(() => {
        subscriber = sessionStore.subscribe((session: Session) => {
          setSession(session);
        });
      });
    }

    return () => {
      if (subscriber !== null) {
        subscriber.unsubscribe();
      }
    };
  }, [sessionStore, isAuthorized]);

  if (!isAuthorized || session === null) {
    return (
      <div>
        <LoadingOverlay isActive={true} size="l" />
      </div>
    );
  }

  return (
    <SessionProvider value={session}>
      {props.children}
      <div id="editor-page-modal" />
    </SessionProvider>
  );
}
