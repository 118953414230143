import * as React from 'react';

// Store
import { useStoreActions, useStoreState } from '@src/store/store';

// Hooks
import { useAddNotifications, useCurrentJourneyId, useGetJourneyById } from '@src/store/hooks';

// Enums
import { NOTIFICATION_TITLE_SUCCESS, NotificationType } from '@src/common/constants/notification';

// Types
import { TJourney } from '@src/common/types/journey';
import { TRequest } from '@src/store/models/requests/requests.types';
import { IUniqueId } from '@src/common/types/entities';

type TRenameData = {
  title: TJourney['title'];
};
type TActionRenameById = (data: { id: IUniqueId['id']; title?: string }) => Promise<void>;
type TRenameById = (id: IUniqueId['id'], renameData?: TRenameData) => Promise<void> | void;
type TResult = [TRenameById, boolean];

export function useRenameJourneyById(): TResult {
  const journeyId = useCurrentJourneyId();
  const getJourneyById = useGetJourneyById();
  const currentJourney = getJourneyById(journeyId);
  const addNotifications = useAddNotifications();

  const renameById = useStoreActions((actions): TActionRenameById => actions.journeys.renameById);
  const updateEntiti = useStoreActions((actions) => actions.journeys.updateEntiti);

  const request: TRequest = useStoreState<TRequest>(
    (state): TRequest => state.requests.entities['journeys.updateById']
  );

  const handleJourneyRenameById: TRenameById = React.useCallback<TRenameById>(
    async (id, renameData): Promise<void> => {
      await renameById({
        id,
        ...renameData
      });

      const newJourney: TJourney = {
        ...currentJourney,
        title: renameData.title
      };

      updateEntiti({ journey: newJourney, journeyId: id, paramType: undefined });

      addNotifications([
        {
          title: NOTIFICATION_TITLE_SUCCESS.updateJourney,
          type: NotificationType.SUCCESS
        }
      ]);
    },
    [renameById, addNotifications, currentJourney, updateEntiti]
  );

  return [handleJourneyRenameById, Boolean(request)];
}
