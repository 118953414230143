import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgCopySmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 3.5h5v6h-.5V11h.5a1.5 1.5 0 001.5-1.5v-6A1.5 1.5 0 0012 2H7a1.5 1.5 0 00-1.5 1.5V4H7v-.5z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 6.5A1.5 1.5 0 009 5H4a1.5 1.5 0 00-1.5 1.5v6A1.5 1.5 0 004 14h5a1.5 1.5 0 001.5-1.5v-6zM9 6.5H4v6h5v-6z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgCopySmallLined;
