// Store
import { useStoreActions, useStoreState } from '@src/store/store';

// Types
import { TJourney } from '@src/common/types/journey';
import { TRequest } from '@src/store/models/requests/requests.types';
import { TPayloadCloneById } from '@src/store/models/journeys/journeys.types';

type TActionCloneById = (payload: TPayloadCloneById) => Promise<TJourney>;
type TResult = [TActionCloneById, boolean];

export function useCloneJourneyById(): TResult {
  const cloneById: TActionCloneById = useStoreActions(
    (actions): TActionCloneById => actions.journeys.cloneById
  );

  const request: TRequest = useStoreState(
    (state): TRequest => state.requests.entities['journeys.cloneById']
  );

  return [cloneById, Boolean(request)];
}
