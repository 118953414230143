import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgCopyMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 7A2.75 2.75 0 0111 4.25h6A2.75 2.75 0 0119.75 7v6A2.75 2.75 0 0117 15.75h-1.25V17A2.75 2.75 0 0113 19.75H7A2.75 2.75 0 014.25 17v-6A2.75 2.75 0 017 8.25h1.25V7zM17 14.25h-1.25V11A2.75 2.75 0 0013 8.25H9.75V7c0-.69.56-1.25 1.25-1.25h6c.69 0 1.25.56 1.25 1.25v6c0 .69-.56 1.25-1.25 1.25z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgCopyMediumFilled;
