import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSendPushPointFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={24} height={24} rx={4} fill="#B255E0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 9.4c0-.84 0-1.26.163-1.581a1.5 1.5 0 01.656-.656C7.139 7 7.559 7 8.4 7h7.2c.84 0 1.26 0 1.581.163a1.5 1.5 0 01.655.656c.164.32.164.74.164 1.581v4.2c0 .84 0 1.26-.163 1.581a1.5 1.5 0 01-.656.655c-.32.164-.74.164-1.581.164H9.207a.5.5 0 00-.353.146l-2 2A.5.5 0 016 17.793V9.4zm9.5 1.1a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5V10a.5.5 0 01.5-.5h6a.5.5 0 01.5.5v.5zm-7 2.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5H9a.5.5 0 00-.5.5v.5z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgSendPushPointFilled;
