import styled from 'styled-components';
import { Color, Spacing } from '@pushwoosh/kit-constants';

// Types
import { ColumnConstant } from '@src/ui/features/JourneyList/components/Table/Table.types';

interface PropsSkeleton {
  size: string;
  even: boolean;
}

const WIDTH_SKELETON = {
  [ColumnConstant.TITLE]: 284,
  [ColumnConstant.STATUS]: 68,
  [ColumnConstant.DATA_MODIFIED]: 124,
  [ColumnConstant.CATEGORY]: 116,
  [ColumnConstant.TOTAL_ENTRIES]: 84,
  [ColumnConstant.AVR_CTR]: 84,
  [ColumnConstant.GOAL_REACHED]: 84
};

const DECREASE_WIDTH = 24;

export const Skeleton = styled.div.attrs<PropsSkeleton>(({ size, even }) => ({
  'data-size': size,
  'data-even': even
}))<PropsSkeleton>`
  height: ${Spacing.S5};
  background-color: ${Color.LOCKED};
  opacity: 0.2;

  &[data-size='${ColumnConstant.TITLE}'] {
    width: ${WIDTH_SKELETON[ColumnConstant.TITLE]}px;
    &[data-even='false'] {
      width: ${WIDTH_SKELETON[ColumnConstant.TITLE] - DECREASE_WIDTH}px;
    }
  }
  &[data-size='${ColumnConstant.STATUS}'] {
    width: ${WIDTH_SKELETON[ColumnConstant.STATUS]}px;
    &[data-even='false'] {
      width: ${WIDTH_SKELETON[ColumnConstant.STATUS] - DECREASE_WIDTH}px;
    }
  }
  &[data-size='${ColumnConstant.DATA_MODIFIED}'] {
    width: ${WIDTH_SKELETON[ColumnConstant.DATA_MODIFIED]}px;
    &[data-even='false'] {
      width: ${WIDTH_SKELETON[ColumnConstant.DATA_MODIFIED] - DECREASE_WIDTH}px;
    }
  }
  &[data-size='${ColumnConstant.CATEGORY}'] {
    width: ${WIDTH_SKELETON[ColumnConstant.CATEGORY]}px;
    &[data-even='false'] {
      width: ${WIDTH_SKELETON[ColumnConstant.CATEGORY] - DECREASE_WIDTH}px;
    }
  }
  &[data-size='${ColumnConstant.TOTAL_ENTRIES}'] {
    width: ${WIDTH_SKELETON[ColumnConstant.TOTAL_ENTRIES]}px;
    &[data-even='false'] {
      width: ${WIDTH_SKELETON[ColumnConstant.TOTAL_ENTRIES] - DECREASE_WIDTH}px;
    }
  }
  &[data-size='${ColumnConstant.GOAL_REACHED}'] {
    width: ${WIDTH_SKELETON[ColumnConstant.GOAL_REACHED]}px;
    &[data-even='false'] {
      width: ${WIDTH_SKELETON[ColumnConstant.GOAL_REACHED] - DECREASE_WIDTH}px;
    }
  }
`;
