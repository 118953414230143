import { isBefore, isAfter } from 'date-fns';

type Options = {
  value: Date;
  minDate?: Date;
  maxDate?: Date;
};

export const isWithinInterval = (options: Options): boolean => {
  const { value, minDate, maxDate } = options;
  let result = false;

  if (minDate) {
    if (isBefore(value, minDate)) {
      result = true;
    }
  }

  if (maxDate) {
    if (isAfter(value, maxDate)) {
      result = true;
    }
  }

  return result;
};
