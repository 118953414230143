import * as React from 'react';

// Types
import { TSelectedProps } from './Select.types';

// Styled
import { PREFIX, SelectAsyncComponent } from './styled';

export function SelectAsync<OptionType>(props: TSelectedProps<OptionType>): JSX.Element {
  return (
    <SelectAsyncComponent
      classNamePrefix={PREFIX}
      menuPortalTarget={document.body}
      menuPosition="absolute"
      {...props}
    />
  );
}
