import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSortNotSortedMedium(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 17.75a.75.75 0 00.75-.75V8.81l1.72 1.72a.75.75 0 101.06-1.06l-3-3a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.72-1.72V17c0 .414.336.75.75.75zM15.25 7a.75.75 0 011.5 0v8.19l1.72-1.72a.75.75 0 111.06 1.06l-3 3a.75.75 0 01-1.06 0l-3-3a.75.75 0 111.06-1.06l1.72 1.72V7z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgSortNotSortedMedium;
