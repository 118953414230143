import * as React from 'react';
import Konva from 'konva';

export function useAutoResizeCanvasAtChangeWindow(
  refStage: React.RefObject<Konva.Stage>
): () => void {
  const refHandleResizeWindow = React.useRef(null);

  React.useEffect((): (() => void) => {
    if (refStage.current !== null) {
      const container: HTMLDivElement = refStage.current.container();
      const stage: Konva.Stage = refStage.current.getStage();

      refHandleResizeWindow.current = (): void => {
        const containerWidth = container.offsetWidth;
        const containerHeight = container.offsetHeight;

        stage.width(containerWidth).height(containerHeight).draw();
      };

      refHandleResizeWindow.current();

      window.addEventListener('resize', refHandleResizeWindow.current);
    }

    return (): void => {
      window.removeEventListener('resize', refHandleResizeWindow.current);
    };
  }, [refStage]);

  return refHandleResizeWindow.current;
}
