import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSocialLogoGoogleMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.106 8.596c-.552-.518-1.521-1.139-2.957-1.139-2.023 0-3.74 1.335-4.36 3.179a4.948 4.948 0 00-.247 1.513c0 .527.094 1.036.255 1.512.612 1.845 2.329 3.179 4.352 3.179 1.138 0 2.005-.315 2.651-.765 1.02-.714 1.479-1.776 1.564-2.482h-4.215v-2.838h7.18c.111.476.162.935.162 1.564 0 2.328-.833 4.291-2.277 5.626-1.267 1.172-3 1.852-5.065 1.852A7.656 7.656 0 014.5 12.148 7.656 7.656 0 0112.149 4.5c2.065 0 3.79.756 5.116 1.989l-2.159 2.107z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgSocialLogoGoogleMediumFilled;
