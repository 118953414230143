import * as React from 'react';

// Store
import { useStoreActions } from '@src/store/store';

type TActionResetPoints = () => void;

export function useResetStore(): TActionResetPoints {
  const actionResetPoints = useStoreActions(
    (actions): typeof actions.points.reset => actions.points.reset
  );

  const actionResetUnsavedChanges = useStoreActions(
    (actions): typeof actions.journeys.setUnsavedChanges => actions.journeys.setUnsavedChanges
  );

  const actionResetErrorPoints = useStoreActions(
    (actions): typeof actions.errorsPoint.close => actions.errorsPoint.close
  );
  const actionResetModals = useStoreActions(
    (actions): typeof actions.modals.closeAllModal => actions.modals.closeAllModal
  );

  const resetStore = React.useCallback((): void => {
    actionResetPoints();
    actionResetErrorPoints();
    actionResetModals();
    actionResetUnsavedChanges(false);
  }, [actionResetPoints, actionResetErrorPoints, actionResetModals, actionResetUnsavedChanges]);

  return resetStore;
}
