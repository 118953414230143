import React from 'react';
import { Layer } from 'react-konva';
import { Cell } from './components/Cell';

const WIDTH = 210;
const HEIGHT = 210;

type Props = {
  coordsCenteredPoint: {
    x: number;
    y: number;
  };
  zoom: number;
};

export function Grid(props: Props): JSX.Element {
  const { coordsCenteredPoint, zoom } = props;

  const width = React.useMemo(
    () => (zoom <= 1 ? window.innerWidth / zoom : window.innerWidth),
    [zoom]
  );
  const height = React.useMemo(
    () => (zoom <= 1 ? window.innerHeight / zoom : window.innerHeight),
    [zoom]
  );

  const cellWidth = zoom <= 1 ? WIDTH / zoom : WIDTH;
  const cellHeight = zoom <= 1 ? HEIGHT / zoom : HEIGHT;

  const startX = Math.floor((-coordsCenteredPoint.x - width) / cellWidth) * cellWidth;
  const endX = Math.floor((-coordsCenteredPoint.x + width * 2) / cellWidth) * cellWidth;

  const startY = Math.floor((-coordsCenteredPoint.y - height) / cellHeight) * cellHeight;
  const endY = Math.floor((-coordsCenteredPoint.y + height * 2) / cellHeight) * cellHeight;

  const gridComponents = React.useMemo(() => {
    const result = [];
    for (let x = startX; x < endX; x += cellWidth) {
      for (let y = startY; y < endY; y += cellHeight) {
        result.push(
          <Cell key={`${x}${y}`} zoom={zoom} x={x} y={y} width={cellWidth} height={cellHeight} />
        );
      }
    }

    return result;
  }, [cellWidth, cellHeight, startX, endX, startY, endY, zoom]);

  return <Layer>{gridComponents}</Layer>;
}
