// Store
import { useStoreActions } from '@src/store/store';
import { IUniqueId } from '@src/common/types/entities';

type TActionSetCurrentJourneyId = (journeyId: IUniqueId['id']) => void;
type TResult = TActionSetCurrentJourneyId;

export function useSetCurrentJourneyId(): TResult {
  const setCurrentJourneyId: TActionSetCurrentJourneyId = useStoreActions(
    (actions): TActionSetCurrentJourneyId => actions.journeys.setCurrentJourneyId
  );

  return setCurrentJourneyId;
}
