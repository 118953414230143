import { Color, ShapeRadius, Spacing } from '@pushwoosh/kit-constants';
import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid ${Color.DIVIDER};
  border-radius: ${ShapeRadius.CONTROL};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing.S3} ${Spacing.S5};
  border-bottom: 1px solid ${Color.DIVIDER};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing.S5};
  border-bottom: 1px solid ${Color.DIVIDER};
`;

export const Item = styled.div`
  margin-right: ${Spacing.S1};
  display: flex;
  align-items: center;
`;

export const Status = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${Color.BRIGHT};
`;

export const BranchTitle = styled.div`
  display: flex;
  align-items: center;
`;
