import * as React from 'react';

// UI Hooks
import { useDidMount } from './useDidMount';

export function useDidUpdate(callback: () => void, conditions: unknown[]): void {
  const [hasMounted, setHasMounted] = React.useState(false);

  useDidMount((): void => {
    setHasMounted(true);
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect((): void => {
    if (hasMounted) {
      callback();
    }
  }, conditions);
  /* eslint-enable react-hooks/exhaustive-deps */
}
