import styled from 'styled-components';
import { Link } from '@src/ui/kit';
import { H4 } from '@pushwoosh/kit-typography';
import { Color, FontSize, Shadow, Spacing } from '@pushwoosh/kit-constants';
import { DropdownMenuWindow } from '@pushwoosh/kit-dropdown-menu';

const HEADER_HEIGHT = '52px';

export const Wrapper = styled.div`
  position: relative;
  height: ${HEADER_HEIGHT};
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 280px;
  width: 100%;
  background-color: ${Color.CLEAR};
  box-shadow: ${Shadow.REGULAR};
  border-radius: 8px;
  padding: ${Spacing.S3};
`;

export const BackwardLink = styled(Link)`
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled(H4)`
  overflow: hidden;
  max-height: 36px;
  max-width: 176px;
  margin-left: ${Spacing.S3};
  line-height: 16px;
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    color: ${Color.PRIMARY_HOVER};
    cursor: pointer;
  }

  &:active {
    color: ${Color.PRIMARY_HOVER};
    opacity: 0.5;
    cursor: pointer;
  }
`;

export const Subtitle = styled.span`
  color: ${Color.LOCKED};
  font-size: ${FontSize.SMALL};
  line-height: 1.3;
  margin-left: ${Spacing.S3};
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;
  max-width: 176px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
`;

export const StyledDropdownMenuWindow = styled(DropdownMenuWindow)`
  background-color: ${Color.CLEAR};

  & :first-child {
    padding-top: ${Spacing.S3};
  }
`;

export const DeleteJourneyButton = styled.div`
  border-top: 1px solid ${Color.DIVIDER};
  color: ${Color.DANGER};
  padding: ${Spacing.S5} ${Spacing.S4};

  &:hover {
    background-color: ${Color.SOFT_LIGHT};
    cursor: pointer;
  }
`;

export const StyledSavedButton = styled.div`
  color: ${Color.LOCKED};
  padding: ${Spacing.S3} ${Spacing.S4};
`;
