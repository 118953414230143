// Store
import { useStoreState } from '@src/store/store';

// Types
import { INotification } from '@src/common/types/notification';

export function useNotifications(): INotification[] {
  const notifications = useStoreState((state): INotification[] => state.notifications.items);

  return notifications;
}
