// Store
import { useStoreActions, useStoreState } from '@src/store/store';

// Types
import { Tag } from '@src/common/types/tag';
import { TRequest } from '@src/store/models/requests/requests.types';

type TActionLoad = (payload?: void) => Promise<Tag[]>;
type TResult = [TActionLoad, boolean];

export function useLoadTag(): TResult {
  const load: TActionLoad = useStoreActions((actions): TActionLoad => actions.tag.load);

  const request: TRequest = useStoreState((state): TRequest => state.requests.entities['tag.load']);

  return [load, Boolean(request)];
}
