import styled from 'styled-components';

// Components
import { H4 } from '@pushwoosh/kit-typography';
import { FormSelect } from '@src/ui/form/FormSelect';

// Constants
import { Spacing, Color } from '@pushwoosh/kit-constants';

export const Title = styled(H4)`
  margin-bottom: ${Spacing.S1};
`;

export const Attention = styled.div`
  margin-top: ${Spacing.S3};
  background: ${Color.WARNING_LIGHT};
  padding: ${Spacing.S3} ${Spacing.S4};
  margin-bottom: ${Spacing.S5};
`;

export const AttributeFormSelect = styled(FormSelect)`
  margin-top: ${Spacing.S5};
`;
