import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSendEmailPointFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={24} height={24} rx={4} fill="#B255E0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.163 7.819C6 8.139 6 8.559 6 9.4v5.2c0 .84 0 1.26.163 1.581a1.5 1.5 0 00.656.655c.32.164.74.164 1.581.164h7.2c.84 0 1.26 0 1.581-.163a1.5 1.5 0 00.655-.656c.164-.32.164-.74.164-1.581V9.4c0-.84 0-1.26-.163-1.581a1.5 1.5 0 00-.656-.656C16.861 7 16.441 7 15.6 7H8.4c-.84 0-1.26 0-1.581.163a1.5 1.5 0 00-.656.656zm1.216 1.65a.5.5 0 00.194.68l4.063 2.257a.75.75 0 00.728 0l4.063-2.258a.5.5 0 00.194-.68l-.242-.437a.5.5 0 00-.68-.194l-3.456 1.92a.5.5 0 01-.486 0l-3.456-1.92a.5.5 0 00-.68.194l-.242.438z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgSendEmailPointFilled;
