import { Color } from '@pushwoosh/kit-constants';
import { IButtonProps } from '../Button.types';

export const getLoaderColors = (
  color: IButtonProps['color'],
  view: IButtonProps['view']
): Color => {
  if (color === 'primary') {
    if (view === 'shape') {
      return Color.CLEAR;
    }

    return Color.PRIMARY;
  }

  if (color === 'secondary') {
    if (view === 'shape') {
      return Color.MAIN;
    }

    return Color.SOFT;
  }

  if (color === 'danger' && view === 'ghost') {
    return Color.WARNING;
  }

  return Color.CLEAR;
};
