// Types
import { TAttributeSelectOption } from '@src/common/types/form';
import { TEventAttribute } from '@src/common/types/event';

export function makeAttributeOptions(eventAttributes: TEventAttribute[]): TAttributeSelectOption[] {
  return eventAttributes.map(
    (eventAttribute): TAttributeSelectOption => ({
      label: eventAttribute.name,
      value: eventAttribute.type
    })
  );
}
