import styled, { keyframes } from 'styled-components';
import { ISpinnerProps } from './Spinner.types';

const rotate = keyframes`
  ${100}% {
    transform: rotate(360deg);
  }
`;

export const SpinnerWrap = styled.div<ISpinnerProps>`
  & > div > svg {
    animation: ${rotate} 0.8s linear infinite;
  }

  & > div > svg > path {
    stroke: ${({ color }) => color};
  }

  & > svg > circle {
    stroke: ${({ color }) => color};
  }
`;
