import * as React from 'react';

// Store
import { useStoreActions } from '@src/store/store';

// Hooks
import { useAddNotifications } from '@src/store/hooks';

// Enums
import { NotificationType, NOTIFICATION_TITLE_SUCCESS } from '@src/common/constants/notification';

// Types
import { TJourney } from '@src/common/types/journey';
import { IUniqueId } from '@src/common/types/entities';

type TEditData = {
  title?: TJourney['title'];
  params?: TJourney['params'];
};
type TActionEditById = (data: { id: IUniqueId['id']; title?: string }) => Promise<TJourney>;
type TEditById = (id: IUniqueId['id'], updateData?: TEditData) => Promise<TJourney>;

export function useEditJourneyById(): TEditById {
  const addNotifications = useAddNotifications();

  const editById = useStoreActions((actions): TActionEditById => actions.journeys.editById);

  const handleJourneyEditById: TEditById = React.useCallback<TEditById>(
    async (id, updateData): Promise<TJourney> => {
      const journey: TJourney = await editById({
        id,
        ...updateData
      });

      addNotifications([
        {
          title: NOTIFICATION_TITLE_SUCCESS.updateJourney,
          type: NotificationType.SUCCESS
        }
      ]);

      return journey;
    },
    [editById, addNotifications]
  );

  return handleJourneyEditById;
}
