import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';
import { Paragraph } from '@pushwoosh/kit-typography';

export const StyledNumberWrap = styled.div`
  width: 180px;
  margin: ${Spacing.S5} 0;
`;

export const LimitsDescription = styled(Paragraph)`
  margin-top: ${Spacing.S4};
`;
