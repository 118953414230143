import * as React from 'react';

// Components
import { DocElements } from '@src/ui/features/Documentation/components/DocElements';

export function DocumentationPointStartEvent(): JSX.Element {
  return (
    <DocElements>
      <DocElements.Paragraph>
        <strong>Trigger-based Entry </strong>
        {'is a specific action user performs starting the journey.'}
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        {
          'To add the Entry Event, drag-and-drop it from the left pane to the canvas. To specify the Event, double-click the Entry Event element,'
        }
        <strong> select the app</strong>
        {', and'}
        <strong> choose the Event </strong>
        {'from the drop-down menu on the pop-up appeared. Then, press'}
        <strong> Apply </strong>
        {'to save changes.'}
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-point-start-event_gif-1.gif"
        label="Adding Entry Event"
        alt="Adding Entry Event"
      />

      <DocElements.Paragraph>
        {'If needed, you can'}
        <strong> specify attributes </strong>
        {
          "of the Entry Event so that it'll start the journey only if the event is triggered with those specific attributes. To do so, press the"
        }
        <strong> Add condition </strong>
        {
          'button when editing the element. Select an attribute from the drop-down and set its operator and values in the corresponding fields.'
        }
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-point-start-event_img-1.png"
        label="Entry Event with attributes"
        alt="Entry Event with attributes"
      />

      <DocElements.Paragraph>
        {'You can add'}
        <strong> multiple Trigger-based entries </strong>
        {
          '— in that case, any of them will start the journey. Each Entry Event could start their own journey but all of them should end at the same Exit point.'
        }
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-point-start-event_img-2.png"
        label="Several Entry Events starting a single journey"
        alt="Several Entry Events starting a single journey"
      />
    </DocElements>
  );
}
