import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgTrophySmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.476 4.345a.5.5 0 00-.951 0l-.337 1.037h-1.09a.5.5 0 00-.294.904l.882.641-.337 1.037a.5.5 0 00.77.559L8 7.882l.882.64a.5.5 0 00.77-.558l-.338-1.037.882-.64a.5.5 0 00-.294-.905h-1.09l-.336-1.037zM7.62 6.377A.5.5 0 008 6.102a.5.5 0 00.379.275.5.5 0 00-.145.445.5.5 0 00-.468 0 .5.5 0 00-.145-.445z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 14a1 1 0 00-1-1H8.75v-1.056a5.005 5.005 0 003.732-2.725A3.75 3.75 0 0015.75 5.5v-1A1.75 1.75 0 0014 2.75h-1v-.632a.618.618 0 00-.618-.618H3.618A.618.618 0 003 2.118v.632H2A1.75 1.75 0 00.25 4.5v1a3.75 3.75 0 003.268 3.72 5.005 5.005 0 003.732 2.724V13H6a1 1 0 100 2h4a1 1 0 001-1zM4.5 3v4a3.5 3.5 0 107 0V3h-7zM1.75 5.5a2.25 2.25 0 001.278 2.03A5.059 5.059 0 013 7V4.25H2a.25.25 0 00-.25.25v1zM13 7V4.25h1a.25.25 0 01.25.25v1a2.25 2.25 0 01-1.278 2.03c.019-.174.028-.351.028-.53z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgTrophySmallLined;
