import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgListMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 6.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM8 12a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 19a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM10.5 5.75a.75.75 0 000 1.5H18a.75.75 0 000-1.5h-7.5zM9.75 12a.75.75 0 01.75-.75H18a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM10.5 16.75a.75.75 0 000 1.5H18a.75.75 0 000-1.5h-7.5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgListMediumLined;
