import styled from 'styled-components';
import { Spacing, Color, ShapeRadius, FontSize, Shadow } from '@pushwoosh/kit-constants';

export const StyledInput = styled.input`
  padding: 10px ${Spacing.S4};
  border: 1px solid ${Color.FORM};
  border-radius: ${ShapeRadius.CONTROL};
  outline: transparent;
  width: 100%;
  color: ${Color.MAIN};
  font-size: ${FontSize.REGULAR};
  user-select: none;
  cursor: pointer;
  height: 38px;

  &:focus {
    border: 1px solid ${Color.BRIGHT};
  }

  &::placeholder {
    color: ${Color.PHANTOM};
    font-size: ${FontSize.REGULAR};
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type InputWrapProps = {
  isDisabled: boolean;
};

export const InputWrap = styled.div<InputWrapProps>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
`;

export const IconWrap = styled.div`
  position: absolute;
  right: ${Spacing.S2};
`;

export const DatepickerWrap = styled.div`
  margin: -${Spacing.S5};
  box-shadow: ${Shadow.REGULAR};
`;
