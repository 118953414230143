import * as React from 'react';

// Api
import { tagService } from '@src/store/api/services';
import { useHttpClient } from '@src/common/contexts/http-client';

// Types
import { TSelectOption } from '@src/ui/kit/Select';

type Result = [(query: string, tag: string, type: string) => Promise<TSelectOption[]>];

export const useGetTagAutocompleteValues = (): Result => {
  const api = useHttpClient();
  const handleRequest = React.useCallback(
    async (query: string, tag: string, type: string): Promise<TSelectOption[]> => {
      const res = await tagService.fetchTagAutocomplete(query, tag, type, api);
      return (
        res?.payload?.values?.map((item) => ({
          label: item,
          value: item
        })) || []
      );
    },
    [api]
  );

  return [handleRequest];
};
