// Types
import { TEmail } from '@src/common/types/email';
import { TEmailSelectOption } from '@src/common/types/form';

export function makeEmailOptions(emails: TEmail[]): TEmailSelectOption[] {
  return emails.map(
    (email): TEmailSelectOption => ({
      label: email.name,
      value: email.code,
      message: email.subjects.en,
      template: email.template
    })
  );
}
