import React from 'react';

// UI Components
import { ExtraOneButton, Message, Modal, Section } from '@src/ui/kit';
import { FormBlock } from '@src/ui/form/styled';
import { Color } from '@pushwoosh/kit-constants';
import { PointTitle, ItemDetails } from '@src/ui/kit/DetailsPoint/styled';
import { useLoadEvents, useGetEventsByApplicationCode } from '@src/store/hooks';

// Types
import { IEventPointCondition } from '@src/common/types/points';
import { ModalType } from '@src/common/constants/modals';
import { useCloseModalByType } from '@src/store/hooks/modals/useCloseModalByType';
import { DetailedEvent } from '@src/common/components/DetailedEvent';

interface IProps {
  applicationCode: string;
  eventName: string;
  eventConditions: IEventPointCondition[];
  pointTitle: string;
  isChanged?: boolean;
}

export function DetailStartEvent(props: IProps): JSX.Element {
  const { eventName, eventConditions, applicationCode, pointTitle, isChanged } = props;
  const [loadEventsByAppCode] = useLoadEvents();
  const getEventsByApplicationCode = useGetEventsByApplicationCode();
  const events = getEventsByApplicationCode(applicationCode);
  const selectedEvent = React.useMemo(
    () => events.find((item) => item.name === eventName),
    [events, eventName]
  );

  React.useEffect(() => {
    if (events.length === 0 && applicationCode) {
      loadEventsByAppCode(applicationCode);
    }
  }, [applicationCode, events.length, loadEventsByAppCode]);

  const [isChangedLocal, setChanged] = React.useState(isChanged);
  const statUrl = React.useMemo(
    () =>
      `https://app.pushwoosh.com/applications/${applicationCode}/events/${selectedEvent?.name}/statistics`,
    [applicationCode, selectedEvent?.name]
  );
  const closeModalByType = useCloseModalByType();

  return (
    <Modal
      title={
        <>
          Trigger-based Entry:&nbsp;
          <PointTitle>{pointTitle}</PointTitle>
        </>
      }
      footerRight={
        <ExtraOneButton
          actionButtonName="Close"
          onClickActionButton={() => closeModalByType(ModalType.DETAIL_POINT)}
        />
      }
      isOpen
    >
      <Section direction="column">
        <ItemDetails>
          <DetailedEvent
            eventName={eventName}
            eventUrl={selectedEvent?.name && statUrl}
            eventConditions={eventConditions}
          />
        </ItemDetails>
        {isChangedLocal && (
          <FormBlock>
            <Message
              color={Color.WARNING_LIGHT}
              withClosing
              onClose={() => setChanged(false)}
              title="These changes will affect other journey points"
              message="If you set another event as a Event or change some attributes, make sure to change settings of linked Update User Profile and Webhook elements. Otherwise, some of these elements might perform incorrectly."
            />
          </FormBlock>
        )}
      </Section>
    </Modal>
  );
}
