import React from 'react';

// Styled
import { TabNames } from '@src/ui/features/JourneyStatistics/components/FullStatistic/types';
import { TabItemContainer } from './styled';

type IProps = {
  activeTab: string;
  name: TabNames;
  setActiveTab: (name: TabNames) => void;
};

export function TabItem(props: IProps) {
  const { name, activeTab, setActiveTab } = props;

  const handleSetActive = () => setActiveTab(name);

  return (
    <TabItemContainer isActive={activeTab === name} onClick={handleSetActive}>
      {name}
    </TabItemContainer>
  );
}
