import styled from 'styled-components';
import { Color, FontSize, FontWeight, Shadow, Spacing, UnitSize } from '@pushwoosh/kit-constants';
import { Icon } from '@src/ui/kit';

export const Container = styled.div`
  background: ${Color.CLEAR};
  box-shadow: ${Shadow.REGULAR};
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
`;

export const StyledPremiumIcon = styled(Icon)`
  position: absolute;
  top: 6px;
  left: 6px;
  background-color: ${Color.CLEAR};
  border-radius: 4px;
`;

export const StyledButton = styled.div`
  display: flex;
  padding: 0 ${Spacing.S4};
  align-items: center;
  border-radius: 4px;
  outline: transparent;
  height: ${UnitSize.FIELD_HEIGHT};
  font-weight: ${FontWeight.MEDIUM};
  font-size: ${FontSize.REGULAR};
  cursor: pointer;
  width: 100%;
  justify-content: center;
  background-color: ${Color.PRIMARY};
  color: ${Color.CLEAR};

  &:hover {
    background: ${Color.PRIMARY_HOVER};
  }

  &:active {
    background: ${Color.PRIMARY_PRESSED};
  }
`;
