import styled, { css } from 'styled-components';

// Components
import { Overlay as OverlayComponent } from '@src/ui/kit/Overlay';
import { H2 } from '@pushwoosh/kit-typography';

// Constants
import { Color, ShapeRadius, Shadow, Spacing } from '@pushwoosh/kit-constants';

// Types
import { IProps } from './Modal.types';

const getModalWidth = (widthType: IProps['widthType']): string => {
  if (widthType === 'short') return '380px';

  if (widthType === 'widest') return '960px';

  return '640px';
};

export const Overlay = styled(OverlayComponent)`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
`;

interface ContainerProps {
  widthType: IProps['widthType'];
  isFullScreen: boolean;
}

const fullScreenCss = css`
  width: 95vw;
  height: 95vh;
  max-height: 95vh;
`;

export const Container = styled.div`
  width: ${({ widthType }: ContainerProps) => getModalWidth(widthType)};
  max-height: calc(100vh - ${Spacing.S13} - ${Spacing.S13});
  border-radius: ${ShapeRadius.CONTROL};
  ${({ isFullScreen }) => isFullScreen && fullScreenCss}
`;

export const Body = styled.div<{ isFullScreen: boolean }>`
  width: 100%;
  background: ${Color.CLEAR};
  border-radius: ${ShapeRadius.DIALOG} ${ShapeRadius.DIALOG} 0 0;
  box-shadow: ${Shadow.LARGE};

  height: ${({ isFullScreen }) => (isFullScreen ? 'calc(100% - 84px)' : 'auto')};

  &:last-child {
    border-radius: 4px;
  }
`;

export const Content = styled.div<{ isFullScreen: boolean }>`
  overflow-y: auto;
  max-height: ${({ isFullScreen }) => (isFullScreen ? '100%' : '60vh')};
  height: ${({ isFullScreen }) => (isFullScreen ? '100%' : 'auto')};
`;

export const Title = styled(H2)`
  padding-top: ${Spacing.S7};
  padding-left: ${Spacing.S7};
  padding-right: ${Spacing.S7};
  margin-bottom: ${Spacing.S3};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 84px;
  width: 100%;
  padding: ${Spacing.S7};
  background: ${Color.CLEAR};
  border-radius: 0 0 ${ShapeRadius.CONTROL} ${ShapeRadius.CONTROL};
`;

export const Left = styled.div``;
export const Right = styled.div``;
