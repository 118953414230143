import React from 'react';
import { Paragraph } from '@pushwoosh/kit-typography';
import { Title, Warn, Container, Success } from './styled';

type Props = {
  isCheckCompleted: boolean;
};

export function SidebarDescription(props: Props): JSX.Element {
  const { isCheckCompleted } = props;

  return (
    <Container>
      <Title>
        Campaign is {isCheckCompleted ? <Success>ready</Success> : <Warn>not ready</Warn>} to launch
      </Title>
      <Paragraph>To launch a campaign, you need to complete these steps</Paragraph>
    </Container>
  );
}
