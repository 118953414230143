import React from 'react';

// Components
import { FormField } from '@src/ui/kit';
import { H3 } from '@pushwoosh/kit-typography';

// Types
import { UpdateTag, DynamicTagValue } from '@src/common/types/tag';

// Styled
import { Container, Tag, TagValue } from './styled';

interface IProps {
  tags: UpdateTag[];
}

export function DetailDynamicTags(props: IProps): JSX.Element {
  const { tags } = props;

  return (
    <Container>
      <H3>Dynamic Tag Value</H3>
      {tags.map((tag) => {
        const value = tag.value as DynamicTagValue;

        return (
          <Tag>
            <TagValue>
              <FormField label="Tag:" value={tag.name} />
            </TagValue>
            <TagValue>
              <FormField label="Event:" value={value.eventName} />
            </TagValue>
            <FormField label="Dynamic Value:" value={value.attribute} />
          </Tag>
        );
      })}
    </Container>
  );
}
