import styled, { css } from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';
import { Paragraph } from '@pushwoosh/kit-typography';

const fieldRowCss = css`
  display: flex;
  align-items: center;
`;

const fieldColCss = css`
  display: flex;
  flex-direction: column;
`;

const fieldValueRowCss = css`
  margin-left: ${Spacing.S1};
`;

const fieldValueColCss = css`
  margin-top: ${Spacing.S1};
`;

type FieldProps = {
  direction: 'row' | 'col';
};

export const FieldWrap = styled.div`
  ${({ direction }: FieldProps) => (direction === 'row' ? fieldRowCss : fieldColCss)}
`;

export const FieldValue = styled(Paragraph)`
  margin-right: ${Spacing.S1};
  display: flex;
  align-items: center;

  ${({ color }) => color || 'auto'};

  > p {
    margin: 0 !important;
  }
`;

export const ValueWrap = styled.div`
  display: flex;
  align-items: center;
  ${({ direction }: FieldProps) => (direction === 'row' ? fieldValueRowCss : fieldValueColCss)}
`;
