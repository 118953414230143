import React from 'react';

// Styled
import { TabsContainer, TabsEmptyFiller } from './styled';

type IProps = {
  children: JSX.Element[];
};

export function Tabs(props: IProps) {
  const { children } = props;

  return (
    <TabsContainer>
      {children}
      <TabsEmptyFiller />
    </TabsContainer>
  );
}
