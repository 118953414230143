// Helpers
import { getPointNameByType } from '@src/ui/helpers';

// Types
import { TPoints } from '@src/common/types/points';

export function generateTitlePoint(currentPoint: TPoints, allPoints: TPoints[]): string {
  let numbersName: number[] = [];

  for (let index = 0; index < allPoints.length; index++) {
    if (currentPoint.type === allPoints[index].type) {
      const res = allPoints[index].title.match(/^.*(\d+)$/);
      const number = res && res[1] ? parseInt(res[1], 10) : 0;
      numbersName = [...numbersName, number];
    }
  }

  const maxNumber = numbersName.length ? Math.max(...numbersName) : 0;
  const newTitle = `${getPointNameByType(currentPoint.type)} ${maxNumber + 1}`;
  return newTitle;
}
