import * as React from 'react';
import styled from 'styled-components';

import { Spacing } from '@pushwoosh/kit-constants';

// Components
import { ButtonCard } from '@src/ui/features/Documentation/components/ButtonCard';

// Styled
import { StyledSubtitle } from '@src/ui/features/Documentation/components/DocumentationButtonCards/styled';

// Types
import { IDocumentationButtonCardsProps } from './DocumentationButtonCards.types';

const DocumentationButtonCardsContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: ${Spacing.S3} 0;
`;

export function DocumentationButtonCards<Path extends string>(
  props: IDocumentationButtonCardsProps<Path>
): JSX.Element {
  const { links, onClick } = props;

  return (
    <DocumentationButtonCardsContainer>
      <StyledSubtitle>
        Check out our quick tips on how to use the Customer Journey Builder tools.
      </StyledSubtitle>
      {links.map(
        (link): JSX.Element => (
          <ButtonCard
            key={link.path}
            icon={link.icon}
            label={link.label}
            backgroundColor={link.backgroundColor}
            onClick={(): void => onClick(link.path)}
          />
        )
      )}
    </DocumentationButtonCardsContainer>
  );
}
