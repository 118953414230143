import * as React from 'react';

// Components
import {
  Point,
  PointContent,
  PointIcon,
  PointOutput,
  PointText
} from '@src/canvas/components/Points/Point';

// Constants
import { COLOR_FALSE, COLOR_TRUE, CONTROL_GROUP_COLOR, PointType } from '@src/common/constants';

// Types
import { getTitlePoint } from '@src/canvas/helpers';
import { getOutputs } from '@src/canvas/components/Points/helpers/getOutputs';
import { Path } from 'react-konva';
import { IProps } from './PointReachabilityCheck.types';

const KEY_TRUE = 'output1';
const KEY_FALSE = 'default';

export function PointReachabilityCheck(props: IProps): JSX.Element {
  const { propsCommonPoint, outputs, data } = props;
  const { isDrawingArrow, onMouseDownOutput } = propsCommonPoint;

  const titlePoint = getTitlePoint(PointType.REACHABILITY_CHECK, '', data);

  const getIsOutput = React.useCallback(
    (key: string): boolean => getOutputs(key, outputs),
    [outputs]
  );

  const [isShowBadge, setShowBadge] = React.useState([true, true]);

  const isLinkedOutputFirst = getIsOutput(KEY_TRUE);
  const isLinkedOutputSecond = getIsOutput(KEY_FALSE);

  React.useEffect(() => {
    if (!isDrawingArrow && !isShowBadge[0]) {
      setShowBadge([true, isShowBadge[1]]);
    }

    if (!isDrawingArrow && !isShowBadge[1]) {
      setShowBadge([isShowBadge[0], true]);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isDrawingArrow]);

  return (
    <Point
      color={CONTROL_GROUP_COLOR}
      type={PointType.REACHABILITY_CHECK}
      isOutputs
      {...propsCommonPoint}
    >
      <PointContent type={PointType.REACHABILITY_CHECK}>
        <PointIcon type={PointType.REACHABILITY_CHECK} fill={CONTROL_GROUP_COLOR}>
          <Path
            data="M12.2321 15.4952C12.3815 11.3131 15.815 8 19.9998 8C24.1845 8 27.6181 11.3131 27.7675 15.4952L27.9998 21.9999C27.9998 21.9999 27.9999 22 27.9999 22C28 22 28.0001 22.0001 28.0001 22.0001C28.0002 23.4475 29.5714 24.8949 30.4395 25.5842C30.7813 25.8556 30.9995 26.2623 30.9989 26.6987C30.9978 27.4177 30.4146 28 29.6956 28H19.9999H15.9999H10.3042C9.58524 28 9.00208 27.4177 9.00098 26.6987C9.00032 26.2623 9.21851 25.8556 9.56032 25.5842C10.4285 24.8949 11.9998 23.4474 11.9998 22L12.2321 15.4952ZM17.7999 30C17.3581 30 16.989 30.3646 17.1052 30.7908C17.4522 32.064 18.6168 33 19.9999 33C21.383 33 22.5475 32.064 22.8946 30.7908C23.0108 30.3646 22.6417 30 22.1999 30H17.7999ZM20.0606 22.5607L25.4948 17.1263C25.8073 16.8139 25.8073 16.3074 25.4948 15.995L24.5049 15.005C24.1925 14.6926 23.6859 14.6926 23.3735 15.005L19.5656 18.813C19.2532 19.1254 18.7466 19.1254 18.4342 18.813L17.1262 17.505C16.8138 17.1926 16.3073 17.1926 15.9948 17.505L15.0049 18.495C14.6925 18.8074 14.6925 19.3139 15.0049 19.6263L17.9392 22.5607L18.4342 23.0556C18.7466 23.3681 19.2532 23.3681 19.5656 23.0556L20.0606 22.5607Z"
            fill="white"
            perfectDrawEnabled={false}
            shadowForStrokeEnabled={false}
          />
        </PointIcon>
        <PointText type={PointType.REACHABILITY_CHECK}>{titlePoint}</PointText>
        <PointOutput
          y={9}
          type={PointType.REACHABILITY_CHECK}
          pointId={propsCommonPoint.id}
          outputKey={KEY_TRUE}
          colorBlock={COLOR_TRUE}
          colorArrow={COLOR_TRUE}
          isInvalid={propsCommonPoint.isInvalid}
          isActive={propsCommonPoint.isActive}
          isLinkedOutput={isLinkedOutputFirst}
          textBadge="Reachable"
          fillBadge={COLOR_TRUE}
          isShowBadge={isLinkedOutputFirst ? false : isShowBadge[0]}
          onMouseDown={(event) => {
            setShowBadge([false, isShowBadge[1]]);
            onMouseDownOutput(event);
          }}
          arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
        />
        <PointOutput
          y={31}
          type={PointType.REACHABILITY_CHECK}
          pointId={propsCommonPoint.id}
          colorBlock={COLOR_FALSE}
          colorArrow={COLOR_FALSE}
          isInvalid={propsCommonPoint.isInvalid}
          isActive={propsCommonPoint.isActive}
          isLinkedOutput={isLinkedOutputSecond}
          textBadge="Not reachable"
          fillBadge={COLOR_FALSE}
          isShowBadge={isLinkedOutputSecond ? false : isShowBadge[1]}
          onMouseDown={(event) => {
            setShowBadge([isShowBadge[1], false]);
            onMouseDownOutput(event);
          }}
          arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
        />
      </PointContent>
    </Point>
  );
}
