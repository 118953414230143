import styled from 'styled-components';
import { Spacing, Color } from '@pushwoosh/kit-constants';
import { Paragraph } from '@pushwoosh/kit-typography';

export const CommentInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${Spacing.S5};
  justify-content: space-between;
`;

export const CommentUpdateDate = styled(Paragraph)`
  color: ${Color.LOCKED};
`;

export const CommentText = styled(Paragraph)`
  cursor: pointer;
`;

export const RemoveIcon = styled.div`
  cursor: pointer;
`;
