// Constants
import { PointType, REACHABILITY_CHECK_TYPES } from '@src/common/constants/point';

// Types
import {
  TBooleanSplitterPoint,
  TEventPoint,
  TPoints,
  TReachabilityCheckPoint,
  TSegmentPoint,
  TSendEmailPoint,
  TSendPush,
  TSendSms,
  TSetTagsPoint,
  TWaitEventPoint
} from '@src/common/types/points';
import { UpdateTag } from '@src/common/types/tag';

const getFirstTag = (tag: UpdateTag): string => {
  const { name, value } = tag;
  const currentValue = Array.isArray(value) ? value.join(', ') : `${value}`;
  return `${name}: ${currentValue}`;
};

export function getTitlePoint(type: PointType, title: string, data: TPoints['data']): string {
  if (title !== '') {
    return title;
  }

  switch (type) {
    case PointType.SEGMENT: {
      return (data as TSegmentPoint['data']).filterTitle || 'Audience-based Entry';
    }
    case PointType.EVENT: {
      return (data as TEventPoint['data']).name
        ? (data as TEventPoint['data']).name
        : 'Trigger-based Entry';
    }
    case PointType.API: {
      return 'Webhook-based entry';
    }
    case PointType.SEND_PUSH: {
      const dataPush = data as TSendPush['data'];
      const label =
        dataPush.isPresetAvailable && dataPush.presetCode && dataPush.name
          ? dataPush.name
          : dataPush.title;
      return !dataPush.name && !dataPush.title ? 'Push' : label;
    }
    case PointType.SEND_EMAIL: {
      const dataEmail = data as TSendEmailPoint['data'];
      return dataEmail.isPresetAvailable && dataEmail.name ? dataEmail.name : 'Email';
    }
    case PointType.SEND_IN_APP: {
      return 'In-App';
    }
    case PointType.SEND_SMS: {
      const dataSms = data as TSendSms['data'];
      return !dataSms.name ? 'SMS' : dataSms.name;
    }
    case PointType.BOOLEAN_SPLITTER: {
      const dataSplitter = data as TBooleanSplitterPoint['data'];
      return dataSplitter.name ? dataSplitter.name : 'Select filter';
    }
    case PointType.WAIT_EVENT: {
      const dataEventWait = data as TWaitEventPoint['data'];
      return dataEventWait.waitEvents &&
        dataEventWait.waitEvents[0] &&
        dataEventWait.waitEvents[0].name
        ? dataEventWait.waitEvents[0].name
        : 'Wait for Trigger';
    }
    case PointType.SET_TAGS: {
      const dataTags = data as TSetTagsPoint['data'];
      return dataTags.tags.length === 0 || !dataTags.tags[0].name
        ? 'Update User Profile'
        : getFirstTag(dataTags.tags[0]);
    }
    case PointType.REACHABILITY_CHECK: {
      const dataProps = data as TReachabilityCheckPoint['data'];
      return dataProps.filterType === REACHABILITY_CHECK_TYPES.EMAIL
        ? 'Reachable by Email'
        : 'Reachable by Push';
    }
    default: {
      return 'unknown';
    }
  }
}
