import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgBulletTriangleLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.585 9.5h2.83L8 7.023 6.585 9.5zm-1.73.004A1 1 0 005.723 11h4.554a1 1 0 00.868-1.496L8.868 5.519a1 1 0 00-1.736 0L4.855 9.504z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgBulletTriangleLined;
