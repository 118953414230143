/* eslint-disable no-nested-ternary */
import * as React from 'react';

// Analytics
import { sendMixpanelEvent } from '@src/common/analytics';
import { SidebarType } from '@src/common/constants/sidebars';

// Store Hooks
import {
  useCurrentJourneyId,
  useGetJourneyById,
  useGetPointById,
  useOpenNewModal,
  useEditJourneyById,
  useEditPoint
} from '@src/store/hooks';
import { useStoreActions, useStoreState } from '@src/store/store';

// UI Hooks
import { useForm } from '@src/ui/hooks';

// UI Components
import { Paragraph } from '@pushwoosh/kit-typography';
import { Section, Button, IconEnum, BlockDetail } from '@src/ui/kit';
import {
  WaitForm,
  IFormEditingPointWaitValues
} from '@src/ui/features/WaitUntil/components/WaitForm';
import { Modal } from '@src/ui/kit/Modal';
import { DefaultButtons } from '@src/ui/kit/ModalButtons';
import { Switch } from '@src/ui/kit/Switch';
import { FormBlock } from '@src/ui/form/styled';
import { FormInput } from '@src/ui/form';

// Constants
import { WaitMode, DelayOptions } from '@src/common/constants/wait';

// Types
import { TWaitPoint } from '@src/common/types/points';
import { IUniqueId } from '@src/common/types/entities';
import { JourneyStatus, ModalType } from '@src/common/constants';
import { useTimeDelay } from '../hooks/useTimeDelay';

const MIN_DELAY = 60;
const DEFAULT_DELAY = 86400;
const DEFAULT_DATE_FROM = 'tag';
const DEFAULT_DELAY_OPTIONS = DelayOptions.RIGHT_ON_TIME;

interface IFormEditingPointWaitProps {
  pointId: IUniqueId['id'];
  closeModal: () => void;
}

export function WaitUntil(props: IFormEditingPointWaitProps): JSX.Element {
  const { pointId, closeModal } = props;

  const getPointById = useGetPointById();
  const editPoint = useEditPoint();
  const showDocumentationMenu = useStoreActions((actions) => actions.documentationMenu.show);
  const journeyId = useCurrentJourneyId();
  const getJourneyById = useGetJourneyById();
  const openNewModal = useOpenNewModal();
  const updatePoint = useStoreActions((actions) => actions.points.update);
  const handleEditJourneyById = useEditJourneyById();
  const sidebars = useStoreState((state) => state.sidebars);
  const openNewSidebar = useStoreActions((actions) => actions.sidebars.openNewSidebar);
  const closeAllSidebars = useStoreActions((actions) => actions.sidebars.closeAllSidebars);

  const { status, params } = getJourneyById(journeyId);
  const { applicationCode } = params;

  const point = getPointById(pointId) as TWaitPoint;
  const { exactDateTime } = point.data;
  const exactDate = `${exactDateTime?.year}-${exactDateTime?.month}-${exactDateTime?.day} ${exactDateTime?.hour}:${exactDateTime?.minute}`;
  const { waitMode } = useTimeDelay(point);
  const isJourneyActive = status === JourneyStatus.RUNNING;

  const { values, setFieldValue, setFieldTouched, handleSubmit, handleChange, handleBlur } =
    useForm<IFormEditingPointWaitValues>({
      initialValues: {
        title: point.title,
        delay: point.data.delay || DEFAULT_DELAY,
        exactTimeHour: point.data.exactTime ? point.data.exactTime.hour : 10,
        exactTimeMinute: point.data.exactTime ? point.data.exactTime.minute : 0,
        waitMode,
        dateFrom: point.data.dynamicTime?.from ?? DEFAULT_DATE_FROM,
        delayOptions: point.data.dynamicTime?.direction ?? DEFAULT_DELAY_OPTIONS,
        splitBranches: point.data.dynamicTime?.splitBranches ?? false,
        duration: point.data.dynamicTime?.duration ?? 1,
        unit: point.data.dynamicTime?.unit ?? 'days',
        tagName: point.data.dynamicTime?.tagName ?? '',
        eventName: point.data.dynamicTime?.eventName ?? '',
        eventAttribute: point.data.dynamicTime?.eventAttribute ?? '',
        pointID: point.data.dynamicTime?.pointID || point.data.dynamicTime?.pointId || '',
        dateValue: new Date(exactDate),
        waitDateHour: new Date(exactDate).getHours() || 10,
        waitDateMinutes: new Date(exactDate).getMinutes() || 0,
        weekValue: Object.keys(point.data?.exactWeekDaysTime ?? {})?.[0],
        weekHour: Object.values(point.data?.exactWeekDaysTime ?? {})?.[0]?.hour || 10,
        weekMinute: Object.values(point.data?.exactWeekDaysTime ?? {})?.[0]?.minute || 0
      },
      onSubmit(formValues): void {
        const delayPointData: TWaitPoint['data'] = {
          delay: formValues.delay > MIN_DELAY ? formValues.delay : MIN_DELAY
        };

        const waitPointData = {
          exactTime: {
            hour: formValues.exactTimeHour,
            minute: formValues.exactTimeMinute
          }
        };

        const exactDateTimeData = {
          exactDateTime: {
            year: formValues.dateValue.getFullYear(),
            month: formValues.dateValue.getMonth() + 1,
            day: formValues.dateValue.getDate(),
            hour: formValues.waitDateHour,
            minute: formValues.waitDateMinutes
          }
        };

        const exactWeekDaysTimeData = {
          exactWeekDaysTime: {
            [formValues.weekValue]: {
              hour: formValues.weekHour,
              minute: formValues.weekMinute
            }
          }
        };

        const dynamicPointData: any = {
          dynamicTime: {
            from: formValues.dateFrom,
            direction: formValues.delayOptions,
            splitBranches: formValues.splitBranches
          }
        };

        if (formValues.delayOptions !== DelayOptions.RIGHT_ON_TIME) {
          dynamicPointData.dynamicTime.duration = Number(formValues.duration);
          dynamicPointData.dynamicTime.unit = formValues.unit;
        }

        if (formValues.dateFrom === 'tag') {
          sendMixpanelEvent({
            eventName: 'TimeDelayTagEventBased',
            eventData: {
              DateFrom: 'tag',
              DelayOptions: formValues.delayOptions
            }
          });

          dynamicPointData.dynamicTime.tagName = formValues.tagName;
        }

        if (formValues.dateFrom === 'event') {
          sendMixpanelEvent({
            eventName: 'TimeDelayTagEventBased',
            eventData: {
              DateFrom: 'event',
              DelayOptions: formValues.delayOptions
            }
          });

          dynamicPointData.dynamicTime.eventName = formValues.eventName;
          dynamicPointData.dynamicTime.eventAttribute = formValues.eventAttribute;
          dynamicPointData.dynamicTime.pointID = formValues.pointID;
        }

        let data;

        if (formValues.waitMode === WaitMode.Delay) {
          data = delayPointData;
        } else if (formValues.waitMode === WaitMode.Wait) {
          data = waitPointData;
        } else if (formValues.waitMode === WaitMode.Dynamic) {
          data = dynamicPointData;
        } else if (formValues.waitMode === WaitMode.Date) {
          data = exactDateTimeData;
        } else if (formValues.waitMode === WaitMode.Week) {
          data = exactWeekDaysTimeData;
        }

        const newPoint = {
          id: point.id,
          title: formValues.title,
          // eslint-disable-next-line no-nested-ternary
          data
        };

        if (!isJourneyActive) {
          editPoint(newPoint);

          closeModal();
        } else {
          updatePoint(newPoint);

          handleEditJourneyById(journeyId);

          sendMixpanelEvent({
            eventName: 'JourneyEditModeChangesApplied',
            eventData: {
              PointType: 'WaitUntil'
            }
          });

          closeModal();

          openNewModal({
            type: ModalType.DETAIL_POINT,
            data: {
              pointId
            }
          });
        }
      }
    });

  const handleCancel = React.useCallback(() => {
    let PointChanged;

    if (point.data.exactTime) {
      if (values.waitMode === WaitMode.Wait) {
        PointChanged =
          point.data.exactTime.hour !== values.exactTimeHour ||
          point.data.exactTime.minute !== values.exactTimeMinute;
      } else {
        PointChanged = true;
      }
    } else {
      PointChanged = values.waitMode !== WaitMode.Delay || point.data.delay !== values.delay;
    }

    if (status === JourneyStatus.RUNNING) {
      sendMixpanelEvent({
        eventName: 'JourneyEditModeChangesCancelled',
        eventData: {
          PointType: 'ConversionGoals'
        }
      });
    } else {
      sendMixpanelEvent({
        eventName: 'ConfigurePointCancelled',
        eventData: {
          PointType: 'WaitUntil',
          PointChanged
        }
      });
    }

    closeModal();
  }, [closeModal, values, point.data.delay, point.data.exactTime, status]);

  const onShowDocumentation = React.useCallback((): void => {
    if (sidebars.isOpened) {
      closeAllSidebars();
    } else {
      openNewSidebar({
        type: SidebarType.DOCUMENTATION
      });
      showDocumentationMenu();
      sendMixpanelEvent({ eventName: 'ShowDocumentation' });
    }
  }, [showDocumentationMenu, sidebars, closeAllSidebars, openNewSidebar]);

  return (
    <Modal
      title="Time Delay"
      footerRight={
        <DefaultButtons
          onClickActionButton={handleSubmit}
          onClickCancelButton={handleCancel}
          actionButtonName={status === JourneyStatus.RUNNING ? 'Save' : 'Apply'}
        />
      }
      footerLeft={
        <Button
          color="primary"
          size="field"
          view="ghost"
          iconPosition="left"
          iconType={IconEnum.DOCS_MEDIUM_LINED}
          onClick={onShowDocumentation}
        >
          How to Use
        </Button>
      }
      isOpen
    >
      <Section direction="column">
        <Paragraph>
          Select a time delay option to control when contacts proceed in the campaign.
        </Paragraph>
        <FormBlock>
          <FormInput
            label="Point Name"
            name="title"
            value={values.title}
            onChange={setFieldValue}
          />
        </FormBlock>
      </Section>
      <Section>
        <WaitForm
          names={{
            delay: 'delay',
            exactTimeHour: 'exactTimeHour',
            exactTimeMinute: 'exactTimeMinute',
            waitMode: 'waitMode',
            dateFrom: 'dateFrom',
            delayOptions: 'delayOptions',
            splitBranches: 'splitBranches',
            duration: 'duration',
            unit: 'unit',
            tagName: 'tagName',
            eventName: 'eventName',
            eventAttribute: 'eventAttribute',
            pointID: 'pointID',
            title: 'title',
            dateValue: 'dateValue',
            waitDateHour: 'waitDateHour',
            waitDateMinutes: 'waitDateMinutes',
            weekValue: 'weekValue',
            weekHour: 'weekHour',
            weekMinute: 'weekMinute'
          }}
          values={values}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          onBlurField={handleBlur}
          onChangeField={handleChange}
          applicationCode={applicationCode}
          onSubmit={handleSubmit}
        />
      </Section>
      {values.waitMode === WaitMode.Dynamic && (
        <Section direction="column">
          <Switch
            defaultEnabled={values.splitBranches}
            onChange={(value) => setFieldValue('splitBranches', value)}
          >
            Split to branches if the date’s in the past or date is empty
          </Switch>
          <BlockDetail>
            <Paragraph>
              When toggle is turned off, if the date or time has already passed (or the date is
              empty), when the user reaches this step, this user will be removed from the campaign.
              When toggle is turned on, the user will be moved to a second branch where you can
              continue communicating with them in another way.
            </Paragraph>
          </BlockDetail>
        </Section>
      )}
    </Modal>
  );
}
