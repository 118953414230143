import styled from 'styled-components';

import { Color, FontSize, FontWeight, Spacing } from '@pushwoosh/kit-constants';

type TabItemContainerProps = {
  isActive: boolean;
};

export const TabsContainer = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: ${Spacing.S9};
`;

export const TabsEmptyFiller = styled.div`
  width: 100%;
  border-bottom: 1px solid ${Color.DIVIDER};
  flex: 1;
`;

export const TabItemContainer = styled.div`
  max-width: 155px;
  box-sizing: border-box;
  display: flex;
  padding-top: ${Spacing.S4};
  padding-bottom: ${Spacing.S4};
  padding-left: ${Spacing.S7};
  padding-right: ${Spacing.S7};
  font-size: ${FontSize.HEADING4};
  font-weight: ${FontWeight.MEDIUM};
  line-height: 1.4;
  cursor: pointer;
  ${({ isActive }: TabItemContainerProps) =>
    isActive
      ? `
    border-bottom: 1px solid ${Color.CLEAR};
    border-top: 3px solid ${Color.BRIGHT};
    border-left: 1px solid ${Color.DIVIDER};
    border-right: 1px solid ${Color.DIVIDER}; 
  `
      : `
    border-bottom: 1px solid ${Color.DIVIDER};
    border-top: 3px solid ${Color.CLEAR};
    border-left: 1px solid ${Color.CLEAR};
    border-right: 1px solid ${Color.CLEAR};
  `}
`;
