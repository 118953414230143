import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgArrowRoundDownMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3a9 9 0 110 18 9 9 0 010-18zm-4.707 8.293a1 1 0 011.414 0L11 13.586V8a1 1 0 112 0v5.586l2.293-2.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgArrowRoundDownMediumFilled;
