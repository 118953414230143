import { WaitMode } from '@src/common/constants/wait';

export function getDeayOption() {
  return [
    { label: 'Fixed duration', value: WaitMode.Delay },
    { label: 'Specific time', value: WaitMode.Wait },
    { label: 'Date', value: WaitMode.Date },
    { label: 'Day of week', value: WaitMode.Week },
    { label: 'Based on user/event data', value: WaitMode.Dynamic }
  ];
}
