import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgChevronDouble(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.576 11.48a.75.75 0 11-1.152-.96l2.1-2.52-2.1-2.52a.75.75 0 011.152-.96l2.5 3a.75.75 0 010 .96l-2.5 3zm-4 0a.75.75 0 11-1.152-.96L6.524 8l-2.1-2.52a.75.75 0 011.152-.96l2.5 3a.75.75 0 010 .96l-2.5 3z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgChevronDouble;
