import * as React from 'react';

// Store hooks
import { useLoadEventsByMultipleAppCodes, useGetEventsByApplicationCodes } from '@src/store/hooks';

// Hooks
import { useAppCodePointsWithEvent } from '@src/ui/features/Webhook/hooks/useAppCodePointsWithEvent';
import { usePointsWithEvent } from '@src/ui/hooks/usePointsWithEvent';

// Components
import { Select, TSelectOption } from '@src/ui/kit/Select';
import { Paragraph } from '@pushwoosh/kit-typography';
import { ISelectOption } from '@src/common/types/form';

// Types
import { EventValuesProps } from './EventValues.types';

// Styled
import { Title, Attention, AttributeFormSelect } from './styled';

export function EventValues(props: EventValuesProps): JSX.Element {
  const { values, names, setFieldValue } = props;
  const { eventValue, attributeValue } = values.dynamicValues;

  const appCodes = useAppCodePointsWithEvent();
  const [loadEventByMultipleAppCodes, isLoadEvents] = useLoadEventsByMultipleAppCodes();
  const getEventsByAppCodes = useGetEventsByApplicationCodes();

  React.useEffect(
    () => {
      loadEventByMultipleAppCodes(appCodes);
    },
    // eslint-disable-next-line
    []
  );

  const eventsByAppCodes = React.useMemo(
    () =>
      getEventsByAppCodes(appCodes).map((item) => ({
        label: item.name,
        value: item.name
      })),
    [appCodes, getEventsByAppCodes]
  );

  const eventsOptions = usePointsWithEvent();

  const getCurrentOptionEvent = React.useMemo(
    () => eventsByAppCodes.filter((item) => item.value === eventValue)[0],
    [eventsByAppCodes, eventValue]
  );

  const handleChangeEvent = React.useCallback(
    (selectedOption: TSelectOption) => {
      const selectedValue = selectedOption.value as [string, string];

      setFieldValue(names.dynamicValues, {
        ...values.dynamicValues,
        eventValue: selectedValue[1],
        pointId: selectedValue[0]
      });
    },
    [names, values.dynamicValues, setFieldValue]
  );

  const getOptionAttributes = React.useMemo((): ISelectOption<string>[] => {
    if (!eventValue) {
      return [];
    }
    const events = getEventsByAppCodes(appCodes);
    const attributes: ISelectOption<string>[] = [];
    events.forEach((item) => {
      if (item.name === eventValue && item?.attributes.length > 0) {
        item.attributes.forEach((attr) => {
          attributes.push({
            label: attr.name,
            value: attr.name
          });
        });
      }
    });
    return attributes;
  }, [appCodes, getEventsByAppCodes, eventValue]);

  const handleChangeAttribute = React.useCallback(
    (_, value: string) => {
      setFieldValue(names.dynamicValues, {
        ...values.dynamicValues,
        attributeValue: value
      });
    },
    [names, values.dynamicValues, setFieldValue]
  );

  const getCurrentOptionAttribute = React.useMemo(() => {
    if (!attributeValue) {
      return null;
    }
    return getOptionAttributes.filter((item) => item.value === attributeValue)[0]?.value;
  }, [getOptionAttributes, attributeValue]);

  return (
    <>
      {appCodes.length === 0 && (
        <Attention>
          <Title>No events in Journey </Title>
          <Paragraph>
            Add a Trigger-based Entry, Wait for Trigger, or Conversion Goal to your Journey to track
            users who trigger those events.
          </Paragraph>
        </Attention>
      )}
      <Select
        placeholder="Select event"
        value={getCurrentOptionEvent}
        options={eventsOptions}
        onChange={handleChangeEvent}
        isLoading={isLoadEvents}
      />
      <AttributeFormSelect
        name="attribute"
        label="Attribute"
        value={getCurrentOptionAttribute}
        options={getOptionAttributes}
        onChange={handleChangeAttribute}
      />
    </>
  );
}
