import * as React from 'react';
import { Path } from 'react-konva';

// Constants
import { CHANNEL_GROUP_COLOR, PointType } from '@src/common/constants/point';

// Helpers
import { getTitlePoint } from '@src/canvas/helpers';

// Components
import { Point, PointContent, PointText, PointIcon, PointOutput } from '../Point';

// Types
import { IProps } from './PointSendInApp.types';

export function PointSendInApp(props: IProps): JSX.Element {
  const { data, propsCommonPoint } = props;

  const titlePoint = getTitlePoint(PointType.SEND_IN_APP, propsCommonPoint.title, data);

  return (
    <Point type={PointType.SEND_IN_APP} color={CHANNEL_GROUP_COLOR} {...propsCommonPoint}>
      <PointContent type={PointType.SEND_IN_APP}>
        <PointIcon type={PointType.SEND_IN_APP} fill={CHANNEL_GROUP_COLOR}>
          <Path
            fill="white"
            perfectDrawEnabled={false}
            shadowForStrokeEnabled={false}
            data="M32 11C32 11.5523 31.5523 12 31 12H30C29.4477 12 29 11.5523 29 11V10C29 9.44772 29.4477 9 30 9H31C31.5523 9 32 9.44772 32 10V11ZM8 17.2C8 16.0799 8 15.5198 8.21799 15.092C8.40973 14.7157 8.71569 14.4097 9.09202 14.218C9.51984 14 10.0799 14 11.2 14H28.8C29.9201 14 30.4802 14 30.908 14.218C31.2843 14.4097 31.5903 14.7157 31.782 15.092C32 15.5198 32 16.0799 32 17.2V26.8C32 27.9201 32 28.4802 31.782 28.908C31.5903 29.2843 31.2843 29.5903 30.908 29.782C30.4802 30 29.9201 30 28.8 30H11.2C10.0799 30 9.51984 30 9.09202 29.782C8.71569 29.5903 8.40973 29.2843 8.21799 28.908C8 28.4802 8 27.9201 8 26.8V17.2ZM26 20C26 20.5523 25.5523 21 25 21H13C12.4477 21 12 20.5523 12 20V19C12 18.4477 12.4477 18 13 18H25C25.5523 18 26 18.4477 26 19V20ZM12 25C12 25.5523 12.4477 26 13 26H18C18.5523 26 19 25.5523 19 25V24C19 23.4477 18.5523 23 18 23H13C12.4477 23 12 23.4477 12 24V25ZM8 11C8 11.5523 8.44771 12 9 12H26C26.5523 12 27 11.5523 27 11V10C27 9.44772 26.5523 9 26 9H9C8.44772 9 8 9.44772 8 10V11Z"
          />
          <Path
            offsetX={-12}
            offsetY={-18}
            fill={CHANNEL_GROUP_COLOR}
            perfectDrawEnabled={false}
            shadowForStrokeEnabled={false}
            data="M14 2C14 2.55228 13.5523 3 13 3H1C0.447715 3 0 2.55228 0 2V1C0 0.447715 0.447716 0 1 0H13C13.5523 0 14 0.447715 14 1V2Z"
          />
        </PointIcon>
        <PointText type={PointType.SEND_IN_APP}>{titlePoint}</PointText>
        <PointOutput
          pointId={propsCommonPoint.id}
          type={PointType.SEND_IN_APP}
          colorBlock={CHANNEL_GROUP_COLOR}
          isInvalid={propsCommonPoint.isInvalid}
          isActive={propsCommonPoint.isActive}
          isLinkedOutput={propsCommonPoint.isLinkedOutput}
          onMouseDown={propsCommonPoint.onMouseDownOutput}
          arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
        />
      </PointContent>
    </Point>
  );
}
