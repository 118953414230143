import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSegmentSplitPointFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={24} height={24} rx={4} fill="#00A2FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.796 18.417a6.998 6.998 0 002.107-1.42c.394-.388.306-1.021-.134-1.357l-1.192-.91c-.439-.335-1.06-.235-1.506.092a3.503 3.503 0 01-4.062.057 3.5 3.5 0 011.015-6.24c.53-.154.988-.587.99-1.139l.004-1.5c.002-.552-.447-1.009-.994-.932a7 7 0 103.772 13.35zm1.759-11.732a7.003 7.003 0 00-1.553-1.009c-.499-.236-1.064.065-1.227.593l-.444 1.433c-.163.527.145 1.077.605 1.382a3.493 3.493 0 011.56 2.755c.007.143.005.286-.006.428-.042.55.202 1.131.718 1.327l1.403.531c.517.196 1.101-.064 1.221-.603a7 7 0 00-2.277-6.837z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgSegmentSplitPointFilled;
