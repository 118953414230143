import styled from 'styled-components';
import { Color, FontSize, FontWeight, Spacing } from '@pushwoosh/kit-constants';

interface IStyledWrapperProps {
  x: number;
  y: number;
}

export const StyledPopoverWrapper = styled.div`
  z-index: 100;
  position: absolute;
  top: ${({ y }: IStyledWrapperProps) => `${y}px`};
  right: ${({ x }: IStyledWrapperProps) => `${x}px`};
  width: 240px;
  min-height: 162px;
  display: flex;
  flex-direction: column;
  padding: ${Spacing.S5};
  background-color: ${Color.CLEAR};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06), 0px 10px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

export const StyledPopoverTitle = styled.div`
  display: flex;
  color: ${Color.MAIN};
  font-weight: ${FontWeight.BOLD};
  font-size: ${FontSize.HEADING3};
  line-height: 1.2;
  width: 200px;
`;

export const StyledPopoverDescription = styled.div`
  display: flex;
  margin-top: ${Spacing.S1};
  margin-bottom: ${Spacing.S4};
  line-height: 1.4;
  color: ${Color.MAIN};
  text-align: start;
  font-weight: ${FontWeight.REGULAR};
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCloseButton = styled.div`
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;

  & svg {
    fill: ${Color.MAIN};
  }

  &:hover {
    & svg {
      fill: ${Color.PRIMARY_HOVER};
    }
  }

  &:active {
    & svg {
      fill: ${Color.PRIMARY_PRESSED};
    }
  }
`;
