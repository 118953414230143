import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgWebhookPointFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={24} height={24} rx={4} fill="#B255E0" />
      <g clipPath="url(#WebhookPointFilled_svg__clip0_965_27577)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.718 8.696c0 .244-.05.475-.142.685-.076.176-.09.38.007.546l.587 1.01c.16.274.532.333.73.086a3.718 3.718 0 10-4.863.832l.265.143a.5.5 0 01.2.678l-.735 1.357c-.091.168-.073.37.008.544a1.718 1.718 0 11-1.557-.99.14.14 0 00.123-.072l.668-1.23c.15-.278.007-.627-.307-.668a3.718 3.718 0 103.231 3.829v-.39a.5.5 0 01.5-.5h1.489c.191 0 .361-.114.475-.269a1.718 1.718 0 11.001 2.035c-.114-.154-.283-.267-.475-.267h-1.17c-.317 0-.555.292-.441.588a3.72 3.72 0 007.188-1.339 3.718 3.718 0 00-5.37-3.332l-.385.224a.5.5 0 01-.684-.181l-.868-1.494a.222.222 0 00-.193-.107 1.718 1.718 0 111.718-1.718zM12 9.45a.75.75 0 100-1.5.75.75 0 000 1.5zm4.55 5.85a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm-8.35.75a.75.75 0 100-1.5.75.75 0 000 1.5z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="WebhookPointFilled_svg__clip0_965_27577">
          <path fill="#fff" transform="translate(4 4)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgWebhookPointFilled;
