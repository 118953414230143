import * as React from 'react';
import { Path } from 'react-konva';

// Constants
import {
  CONTROL_GROUP_COLOR,
  COLOR_TRUE,
  COLOR_TRUE_LIGHT,
  COLOR_FALSE,
  COLOR_FALSE_LIGHT,
  PointType
} from '@src/common/constants';
import { COLOR_GREEN, COLOR_ORANGE } from '@src/common/constants/canvas';

// Helpers
import { getTitlePoint } from '@src/canvas/helpers';
import { getOutputs } from '../helpers/getOutputs';

// Components
import { Point, PointContent, PointText, PointIcon, PointOutput } from '../Point';

// Types
import { IProps } from './PointBooleanSplitter.types';

const KEY_TRUE = 'output1';
const KEY_FALSE = 'default';

export function PointBooleanSplitter(props: IProps): JSX.Element {
  const { countContent, outputs, data, propsCommonPoint } = props;

  const getIsOutput = React.useCallback(
    (key: string): boolean => getOutputs(key, outputs),
    [outputs]
  );

  const titlePoint = getTitlePoint(PointType.BOOLEAN_SPLITTER, propsCommonPoint.title, data);

  return (
    <Point
      color={CONTROL_GROUP_COLOR}
      type={PointType.BOOLEAN_SPLITTER}
      countContent={countContent}
      isOutputs
      {...propsCommonPoint}
    >
      <PointContent type={PointType.BOOLEAN_SPLITTER} fill={COLOR_TRUE_LIGHT} order={1}>
        <PointIcon type={PointType.BOOLEAN_SPLITTER} fill={COLOR_TRUE}>
          <Path
            fill="white"
            data="M12.697 8.21967C12.9899 8.51256 12.9899 8.98744 12.697 9.28033L7.65534 14.322C7.36245 14.6149 6.88757 14.6149 6.59468 14.322L4.30301 12.0303C4.01012 11.7374 4.01012 11.2626 4.30301 10.9697C4.59591 10.6768 5.07078 10.6768 5.36367 10.9697L7.12501 12.731L11.6363 8.21967C11.9292 7.92678 12.4041 7.92678 12.697 8.21967Z"
          />
        </PointIcon>
        <PointText type={PointType.BOOLEAN_SPLITTER} order={0}>
          {titlePoint}
        </PointText>
        <PointOutput
          type={PointType.BOOLEAN_SPLITTER}
          outputKey={KEY_TRUE}
          order={0}
          pointId={propsCommonPoint.id}
          colorArrow={COLOR_GREEN}
          colorBlock={CONTROL_GROUP_COLOR}
          colorOutput={COLOR_TRUE}
          isInvalid={propsCommonPoint.isInvalid}
          isActive={propsCommonPoint.isActive}
          isLinkedOutput={getIsOutput(KEY_TRUE)}
          onMouseDown={propsCommonPoint.onMouseDownOutput}
          arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
        />
      </PointContent>
      <PointContent type={PointType.BOOLEAN_SPLITTER} fill={COLOR_FALSE_LIGHT} order={2}>
        <PointIcon type={PointType.BOOLEAN_SPLITTER} fill={COLOR_FALSE}>
          <Path
            fill="white"
            data="M5.7019 7.64124C5.40901 7.34835 4.93413 7.34835 4.64124 7.64124C4.34835 7.93413 4.34835 8.40901 4.64124 8.7019L6.93935 11L4.64125 13.2981C4.34836 13.591 4.34836 14.0659 4.64125 14.3588C4.93414 14.6517 5.40902 14.6517 5.70191 14.3588L8.00001 12.0607L10.2981 14.3588C10.591 14.6516 11.0659 14.6516 11.3588 14.3588C11.6516 14.0659 11.6516 13.591 11.3588 13.2981L9.06067 11L11.3588 8.70192C11.6517 8.40902 11.6517 7.93415 11.3588 7.64125C11.0659 7.34836 10.591 7.34836 10.2981 7.64125L8.00001 9.93935L5.7019 7.64124Z"
          />
        </PointIcon>
        <PointText type={PointType.BOOLEAN_SPLITTER} order={1} fill={COLOR_FALSE}>
          Else
        </PointText>
        <PointOutput
          type={PointType.BOOLEAN_SPLITTER}
          outputKey={KEY_FALSE}
          order={1}
          pointId={propsCommonPoint.id}
          colorBlock={CONTROL_GROUP_COLOR}
          colorArrow={COLOR_ORANGE}
          colorOutput={COLOR_FALSE}
          isInvalid={propsCommonPoint.isInvalid}
          isActive={propsCommonPoint.isActive}
          isLinkedOutput={getIsOutput(KEY_FALSE)}
          onMouseDown={propsCommonPoint.onMouseDownOutput}
          arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
        />
      </PointContent>
    </Point>
  );
}
