import { useCallback } from 'react';

// Store
import { useStoreActions } from '@src/store/store';

type THandleIncrementZoom = () => void;

export function useIncrementZoom(): THandleIncrementZoom {
  const incrementZoom = useStoreActions(
    (actions): typeof actions.canvas.incrementZoom => actions.canvas.incrementZoom
  );

  return useCallback<THandleIncrementZoom>((): void => {
    incrementZoom();
  }, [incrementZoom]);
}
