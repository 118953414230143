import React from 'react';

// Hooks
import { useSetMode } from '@src/store/hooks';

// Store
import { useStoreActions } from '@src/store/store';

// Types
import { TComment } from '@src/common/types/comments';

// Enums
import { Modes } from '@src/common/constants';

type TRemoveComment = (commentId: TComment['id']) => void;

export function useRemoveComment(): TRemoveComment {
  const setMode = useSetMode();

  const removeComment: TRemoveComment = useStoreActions<TRemoveComment>(
    (actions): TRemoveComment => actions.comments.remove
  );

  const handleCommentRemove: TRemoveComment = React.useCallback<TRemoveComment>(
    (commentId): void => {
      removeComment(commentId);
      setMode(Modes.CHANGED);
    },
    [removeComment, setMode]
  );

  return handleCommentRemove;
}
