import { Action, Computed } from 'easy-peasy';

// Constants
import { IUniqueId } from '@src/common/types/entities';
import { IDictionary } from '@src/common/types/types';
import { TArrow } from '@src/common/types/arrow';

export enum DrawingArrowStatus {
  IDLE = 'idle',
  DRAWING = 'drawing',
  DRAWING_PAUSE_ON_POINT = 'drawing-pause-on-point',
  DRAWING_PAUSE_ON_CANVAS = 'drawing-pause-on-canvas'
}

type Arrow = TArrow & {
  id: IUniqueId['id'];
};

export type UpdateArrow = (Partial<TArrow> & IUniqueId) | Array<Partial<TArrow> & IUniqueId>;

export interface IArrowModel {
  drawingData: {
    fromPointId: IUniqueId['id'];
    toPointId: IUniqueId['id'];
    outputKey: string;
    outputOrder: number;
    outputStats: number;
    color?: string;
    fromX?: number;
    fromY?: number;
    toX?: number;
    toY?: number;
    pageX: number;
    pageY: number;
    status: DrawingArrowStatus;
  };
  entities: IDictionary<TArrow & IUniqueId>;
  arrows: Computed<IArrowModel, Arrow[]>;
  isDrawingArrow: Computed<IArrowModel, boolean>;
  setArrowState: Action<
    IArrowModel,
    {
      status?: DrawingArrowStatus;
      fromX?: number;
      fromY?: number;
      toX?: number;
      toY?: number;
      pageX?: number;
      pageY?: number;
      fromPointId?: IUniqueId['id'];
      toPointId?: IUniqueId['id'];
      outputKey?: IUniqueId['id'];
      outputOrder?: number;
      color?: string;
    }
  >;

  resetArrowState: Action<IArrowModel, void>;
  addArrow: Action<IArrowModel, TArrow & IUniqueId>;
  removeArrow: Action<IArrowModel, string>;
  updateArrow: Action<IArrowModel, UpdateArrow>;
  setArrows: Action<IArrowModel, TArrow[]>;
}
