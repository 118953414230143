import styled from 'styled-components';
import { Button } from '@src/ui/kit';
import { Spacing } from '@pushwoosh/kit-constants';

export const Tools = styled.div`
  padding: ${Spacing.S7};
  display: flex;
  align-items: center;
`;

export const ToolItem = styled.div`
  margin-right: 8px;
`;

export const CreateJourneyButton = styled(Button)`
  margin-left: auto;
`;
