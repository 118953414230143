import * as React from 'react';

// Store Hooks
import {
  useComputedGetTooltipById,
  useComputedGetPointStatByPointId,
  useActionShowTooltip,
  useActionHideTooltip,
  useGetPointById
} from '@src/store/hooks';
import { useStoreState } from '@src/store/store';

// UI Components
import {
  TooltipPointSendPushStats as TooltipPointSendPushStatsComponent,
  ITooltipPointSendPushStatsProps
} from '@src/ui/molecules/TooltipPointSendPushStats';

// Types
import { TPointSendPushStatistics } from '@src/common/types/statistics';
import { TSendPush } from '@src/common/types/points';
import { IUniqueId } from '@src/common/types/entities';
import _reduce from 'lodash/reduce';
import { usePointsToGoalsStat } from '@src/store/hooks/statistics/useGoalsStatistic';

interface ITooltipPointSendPushStatsContainerProps {
  tooltipId: IUniqueId['id'];
}

function TooltipPointSendPushStats(props: ITooltipPointSendPushStatsContainerProps): JSX.Element {
  const getTooltipById = useComputedGetTooltipById();
  const getPointStatByPointId = useComputedGetPointStatByPointId();
  const getPointById = useGetPointById();
  const getGoalStatByPointId = usePointsToGoalsStat();
  const getPointDropOffs = useStoreState((state) => state.statistics.getPointDropOffs);

  const showTooltip = useActionShowTooltip();
  const hideTooltip = useActionHideTooltip();

  const tooltip = getTooltipById(props.tooltipId);
  const point = getPointStatByPointId(tooltip.data.pointId) as TPointSendPushStatistics;
  const pointData = getPointById(point?.pointId) as TSendPush;
  const dropOffs = getPointDropOffs(point?.pointId);

  const error = point?.pointOutputStat
    ? point.pointOutputStat.reduce((acc, outputStat) => {
        if (outputStat.errors.error) {
          return acc + outputStat.errors.error;
        }

        return acc;
      }, 0)
    : 0;

  const goalsStat = getGoalStatByPointId(tooltip.data.pointId);
  const goalsReached =
    goalsStat?.length > 0 ? _reduce(goalsStat, (sum, goal) => sum + goal.Count, 0) : 0;
  const allDropOffs = dropOffs.reduce((prevValue, dropOff) => prevValue + dropOff.value, 0);

  const usersEngaged =
    point?.pointOutputStat?.[0]?.count - error >= 0 ? point.pointOutputStat[0].count - error : 0;
  const isShowUsersEngaged = pointData?.data?.sendByUserID ?? false;

  const handleTooltipMouseEnter = React.useCallback<
    ITooltipPointSendPushStatsProps['onMouseEnter']
  >((): void => showTooltip(tooltip), [tooltip, showTooltip]);

  const handleTooltipMouseLeave = React.useCallback<
    ITooltipPointSendPushStatsProps['onMouseLeave']
  >((): void => hideTooltip({ id: props.tooltipId }), [props.tooltipId, hideTooltip]);

  if (!point) {
    return null;
  }

  return (
    <TooltipPointSendPushStatsComponent
      id={props.tooltipId}
      x={tooltip.position.x}
      y={tooltip.position.y}
      opened={point.pointStat.opened}
      sent={point.pointStat.sent}
      conversion={point.pointStat.conversion}
      error={allDropOffs}
      goalsReached={goalsReached}
      onMouseEnter={handleTooltipMouseEnter}
      onMouseLeave={handleTooltipMouseLeave}
      usersEngaged={usersEngaged}
      isShowUsersEngaged={isShowUsersEngaged}
    />
  );
}

export default React.memo(TooltipPointSendPushStats);
