import React from 'react';

// Analytics
import { sendMixpanelEvent } from '@src/common/analytics';

// Types
import { IUniqueId } from '@src/common/types/entities';
import { ModalType } from '@src/common/constants/modals';

// Store Hooks
import { useOpenNewModal } from '@src/store/hooks';

export const useCloneJourney = (journeyId: IUniqueId['id']): (() => void) => {
  const openNewModal = useOpenNewModal();
  const cloneJourneyHandle = React.useCallback((): void => {
    sendMixpanelEvent({ eventName: 'CloneJourney' });
    openNewModal({
      type: ModalType.CLONE_JOURNEY,
      data: { journeyId }
    });
  }, [openNewModal, journeyId]);

  return cloneJourneyHandle;
};
