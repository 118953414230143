// Types
import { TComment } from '@src/common/types/comments';

export function makeComment(): TComment {
  return {
    id: '1',
    message: '',
    type: 'comment',
    createdAt: '',
    deleted: false,
    position: {
      x: 200,
      y: 200
    }
  };
}
