import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgArrowRoundUpMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21a9 9 0 110-18 9 9 0 010 18zm4.707-8.293a1 1 0 01-1.414 0L13 10.414V16a1 1 0 11-2 0v-5.586l-2.293 2.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgArrowRoundUpMediumFilled;
