import * as React from 'react';

// Store
import { useStoreState, useStoreActions } from '@src/store/store';

// Types
import { IUniqueId } from '@src/common/types/entities';

type TResult = IUniqueId['id'];

export function useActiveCommentId(): TResult {
  const activeCommentId: IUniqueId['id'] = useStoreState<IUniqueId['id']>(
    (state): typeof state.comments.activeCommentId => state.comments.activeCommentId
  );

  return activeCommentId;
}

type TSetActiveComment = (commentId: IUniqueId['id']) => void;

export function useSetActiveComment(): TSetActiveComment {
  const setActiveComment: TSetActiveComment = useStoreActions<TSetActiveComment>(
    (actions): TSetActiveComment => actions.comments.setActive
  );

  const handleCommentSetActive: TSetActiveComment = React.useCallback<TSetActiveComment>(
    (commentId): void => {
      setActiveComment(commentId);
    },
    [setActiveComment]
  );

  return handleCommentSetActive;
}
