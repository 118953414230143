import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgInfoRoundSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 13.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM8 15A7 7 0 108 1a7 7 0 000 14zm0-9a1 1 0 100-2 1 1 0 000 2zm.75 1.75a.75.75 0 00-1.5 0v3.5a.75.75 0 001.5 0v-3.5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgInfoRoundSmallLined;
