/* eslint-disable no-underscore-dangle */
import { Subject, Observable } from 'rxjs';
import { StreamType, EventType, BusEvent } from './EventBus.types';

export class EventBus<INC extends Record<string, any>> {
  _streams: Map<EventType<INC>, StreamType<BusEvent<INC, EventType<INC>>>> = new Map();

  _selectStream(type: EventType<INC>): StreamType<BusEvent<INC, EventType<INC>>> {
    if (!this._streams.has(type)) {
      this._addNewStream(type, new Subject());
    }

    return this._streams.get(type);
  }

  _addNewStream(type: EventType<INC>, stream: StreamType<BusEvent<INC, EventType<INC>>>) {
    this._streams.set(type, stream);
  }

  emit({ type, payload, withCopmplete = true }: BusEvent<INC, EventType<INC>>): void {
    const currentStream = this._selectStream(type);

    currentStream.next({ type, payload });

    if (withCopmplete) {
      currentStream.complete();
    }
  }

  select(type: EventType<INC>): Observable<BusEvent<INC, EventType<INC>>> {
    const currentStream = this._selectStream(type);

    return currentStream.asObservable();
  }
}
