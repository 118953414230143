import { Spacing, Color } from '@pushwoosh/kit-constants';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: ${Color.CLEAR};
  padding: ${Spacing.S4} ${Spacing.S7} ${Spacing.S4} ${Spacing.S5};
  border-left: 4px solid transparent;

  &:hover {
    border-left: 4px solid ${Color.BRIGHT};
    background-color: ${Color.ROW_HOVER};
  }
`;

export const IconWrap = styled.div`
  margin-right: ${Spacing.S4};
  width: 16px;
  height: 16px;
  margin-top: ${Spacing.S0};
`;

export const InfoWrap = styled.div`
  flex: 1;
  margin-right: ${Spacing.S4};
`;

export const TimeWrap = styled.div`
  width: 36px;
  height: 36px;
`;
