import React from 'react';

// UI Components
import { ExtraOneButton, FormField, Modal, Section } from '@src/ui/kit';
import { ItemDetails } from '@src/ui/kit/DetailsPoint';
import { PointTitle } from '@src/ui/kit/DetailsPoint/styled';
import { DetailHeader } from '@src/ui/features/Webhook/components/DetailHeader';
import { Loading } from '@src/ui/kit/Loading';

// Types
import { TWebhookPoint } from '@src/common/types/points';
import { ModalType } from '@src/common/constants/modals';
import { TModal } from '@src/common/types/modal';
import { useCloseModalByType } from '@src/store/hooks/modals/useCloseModalByType';
import { DropOffStat } from '../../DropOffStat';

// Lazy load components
const JSONEditor = React.lazy(
  () => import('@src/ui/features/Webhook/components/JSONEditor/JSONEditor')
);

interface IProps {
  url: TWebhookPoint['data']['url'];
  headers: TWebhookPoint['data']['headers'];
  requestTemplate: TWebhookPoint['data']['request_template'];
  pointTitle: string;
  pointId: string;
}

export function DetailWebhook(props: IProps): JSX.Element {
  const { url, headers, requestTemplate, pointTitle, pointId } = props;

  const modalSettings = {
    type: ModalType.EDITING_FORM_POINT,
    data: {
      pointId
    }
  } as TModal;

  const closeModalByType = useCloseModalByType();

  return (
    <Modal
      title={
        <>
          Webhook:&nbsp;
          <PointTitle>{pointTitle}</PointTitle>
        </>
      }
      footerRight={
        <ExtraOneButton
          actionButtonName="Close"
          onClickActionButton={() => closeModalByType(ModalType.DETAIL_POINT)}
        />
      }
      isOpen
    >
      <Section direction="column">
        <ItemDetails>
          <DropOffStat pointId={pointId} modal={modalSettings} />
        </ItemDetails>
      </Section>
      <Section direction="column">
        <ItemDetails>
          <FormField direction="col" label="URL:" value={url} />
        </ItemDetails>
        <ItemDetails>
          <DetailHeader headers={headers} />
        </ItemDetails>
      </Section>
      <Section direction="column">
        <React.Suspense fallback={<Loading size="medium" />}>
          <JSONEditor value={requestTemplate} name="requestTemplate" readOnly />
        </React.Suspense>
      </Section>
    </Modal>
  );
}
