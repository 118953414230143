import styled, { css } from 'styled-components';
import { Paragraph } from '@pushwoosh/kit-typography';
import {
  Spacing,
  Color,
  ShapeRadius,
  FontSize,
  FontWeight,
  LineHeight
} from '@pushwoosh/kit-constants';

type Props = {
  isSelected: boolean;
};

const selectedCSS = css`
  background-color: ${Color.DANGER_LIGHT};
  border-left: 4px solid ${Color.DANGER};
`;

export const Container = styled.div<Props>`
  display: flex;
  cursor: pointer;
  border-left: 4px solid transparent;
  padding: ${Spacing.S5} ${Spacing.S6};

  ${({ isSelected }) => isSelected && selectedCSS}
`;

export const Number = styled.div<Props>`
  width: 24px;
  min-width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isSelected }) => (isSelected ? Color.DANGER : Color.DANGER_LIGHT)};
  border-radius: ${ShapeRadius.CONTROL};
  color: ${({ isSelected }) => (isSelected ? Color.CLEAR : Color.DANGER)};
  font-size: ${FontSize.SMALL};
  font-weight: ${FontWeight.MEDIUM};
  line-height: ${LineHeight.REGULAR};
  margin-right: ${Spacing.S4};
`;

export const Message = styled(Paragraph)`
  padding: ${Spacing.S0} 0;
`;
