import { createContext, useContext } from 'react';
import { Session } from '@pushwoosh/session-store';

export const SessionContext = createContext<Session | null>(null);
export const SessionProvider = SessionContext.Provider;

export function useSession(): Session {
  const session = useContext(SessionContext);
  if (!session) {
    throw new Error(
      'SessionContext is not defined! Please check what are you using useSession in a child of SessionProvider component!'
    );
  }

  return session;
}
