import * as React from 'react';

// Types
import { IProps } from './Modal.types';

// Styled
import { Overlay, Container, Body, Content, Title, Footer, Left, Right } from './styled';

export function Modal(props: IProps): JSX.Element {
  const {
    title,
    isOpen,
    className,
    children,
    widthType = 'wide',
    footerLeft,
    footerRight,
    isFullScreen
  } = props;

  if (!isOpen) {
    return null;
  }

  return (
    <Overlay isActive>
      <Container className={className} widthType={widthType} isFullScreen={isFullScreen}>
        <Body isFullScreen={isFullScreen}>
          <Title>{title}</Title>
          <Content isFullScreen={isFullScreen}>{children}</Content>
        </Body>
        {(footerLeft || footerRight) && (
          <Footer>
            <Left>{footerLeft}</Left>
            <Right>{footerRight}</Right>
          </Footer>
        )}
      </Container>
    </Overlay>
  );
}
