import { useEffect } from 'react';

export function useRemoveArrowOnClosePointsMenu(
  hasPointsMenuOpened: boolean,
  resetDrawArrow: () => void
): void {
  useEffect((): void => {
    if (hasPointsMenuOpened) {
      return;
    }

    resetDrawArrow();
  }, [hasPointsMenuOpened, resetDrawArrow]);
}
