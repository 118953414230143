import * as React from 'react';

// Analytics
import { sendPostEvent } from '@src/common/analytics';

// Store
import { useStoreActions, useStoreState } from '@src/store/store';

// Hooks
import { useAddNotifications, useUsername } from '@src/store/hooks';

// Enums
import { NotificationType, NOTIFICATION_TITLE_SUCCESS } from '@src/common/constants/notification';

// Types
import { TJourney } from '@src/common/types/journey';
import { TRequest } from '@src/store/models/requests/requests.types';
import { IUniqueId } from '@src/common/types/entities';

type TActionDeactivateById = (id: IUniqueId['id']) => Promise<TJourney>;
type TResult = [TActionDeactivateById, boolean];

export function useDeactivateJourneyById(): TResult {
  const addNotifications = useAddNotifications();
  const username = useUsername();

  const deactivateJourneyById: TActionDeactivateById = useStoreActions(
    (actions): TActionDeactivateById => actions.journeys.deactivateById
  );

  const request: TRequest = useStoreState(
    (state): TRequest => state.requests.entities['journeys.deactivateById']
  );

  const deactivateById = React.useCallback<TActionDeactivateById>(
    async (id): Promise<TJourney> => {
      const response = await deactivateJourneyById(id);

      sendPostEvent('CustomerJourneyDeactivate', { userEmail: username });

      addNotifications([
        {
          title: NOTIFICATION_TITLE_SUCCESS.deactivateJourney,
          type: NotificationType.SUCCESS
        }
      ]);

      return response;
    },
    [deactivateJourneyById, addNotifications, username]
  );

  return [deactivateById, Boolean(request)];
}
