import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgExitPointFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={24} height={24} rx={4} fill="#00A2FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 5.75a.75.75 0 01.724.552c.029.107.116.198.226.198h7.05a.5.5 0 01.5.5v5.5a.5.5 0 01-.5.5H9.45a.2.2 0 00-.2.2v2.616c0 .106.084.194.19.203 1.17.104 2.06.616 2.06 1.231 0 .69-1.12 1.25-2.5 1.25s-2.5-.56-2.5-1.25c0-.432.44-.814 1.106-1.038a.208.208 0 00.144-.195V6.5a.75.75 0 01.75-.75z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgExitPointFilled;
