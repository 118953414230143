import * as React from 'react';

// Store hooks
import { useAllPoints } from '@src/store/hooks';

// Constants
import { PointType } from '@src/common/constants/point';

export function useAppCodePointsWithEvent(): string[] {
  const points = useAllPoints();

  const getAppCodes = React.useMemo(() => {
    const appCodes = new Set<string>([]);
    for (let index = 0; index < points.length; index++) {
      const currentPoint = points[index];

      switch (currentPoint?.type) {
        case PointType.EVENT: {
          if (currentPoint.data?.name) {
            appCodes.add(currentPoint.data?.applicationCode);
          }
          break;
        }
        case PointType.WAIT_EVENT: {
          currentPoint.data?.waitEvents?.forEach((item) => {
            appCodes.add(item?.applicationCode);
          });
          break;
        }
        default: {
          break;
        }
      }
    }
    return appCodes;
  }, [points]);

  return Array.from(getAppCodes);
}
