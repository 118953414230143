import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgArrowRightMedium(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.47 15.47a.75.75 0 101.06 1.06l4-4a.75.75 0 000-1.06l-4-4a.75.75 0 10-1.06 1.06l2.72 2.72H6a.75.75 0 000 1.5h9.19l-2.72 2.72z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgArrowRightMedium;
