import styled from 'styled-components';
import { Paragraph } from '@pushwoosh/kit-typography';
import { Spacing, Color } from '@pushwoosh/kit-constants';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${Spacing.S5};
`;

export const ItemNotChecked = styled.div`
  width: 18px;
  height: 18px;
  background: ${Color.DIVIDER};
  border-radius: 100%;
  margin-right: ${Spacing.S4};
`;

export const IconWrap = styled.div`
  margin-right: ${Spacing.S3};
  margin-left: -${Spacing.S1};
  color: ${Color.SUCCESS};
`;

type ValueProps = {
  isChecked: boolean;
};

export const Value = styled(Paragraph)<ValueProps>`
  color: ${({ isChecked }) => (isChecked ? Color.SUCCESS : Color.MAIN)};
`;
