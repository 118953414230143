import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgOpenMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.19 6.75l-5.72 5.72a.75.75 0 101.06 1.06l5.72-5.72v1.94a.75.75 0 001.5 0V6a.75.75 0 00-.75-.75h-3.75a.75.75 0 000 1.5h1.94zM12 7.744a.75.75 0 00-.75-.75H7.397a2.25 2.25 0 00-2.25 2.25v7.36a2.25 2.25 0 002.25 2.25h7.36a2.25 2.25 0 002.25-2.25V12.75a.75.75 0 00-1.5 0v3.853a.75.75 0 01-.75.75h-7.36a.75.75 0 01-.75-.75v-7.36a.75.75 0 01.75-.75h3.853a.75.75 0 00.75-.75z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgOpenMediumLined;
