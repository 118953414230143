import * as React from 'react';

// Store Hooks
import { useOpenNewModal } from '@src/store/hooks';

// Constants
import { ModalType } from '@src/common/constants/modals';

export interface IConfirmServiceOptions {
  readonly title?: string;
  readonly description?: string;
  readonly confirmLabel?: string;
  readonly level?: 'info' | 'danger';
  readonly cancelLabel?: string;
  readonly onBeforeConfirm?: () => void;
  readonly onAfterConfirm?: () => void;
  readonly onBeforeCancel?: () => void;
  readonly onAfterCancel?: () => void;
  readonly isLoadingConfirm?: boolean;
  readonly isLoadingCancel?: boolean;
  readonly warningMessage?: string;
}

type TConfirmServiceResult = (options: IConfirmServiceOptions) => void;

const DEFAULT_CONFIRM_TITLE = 'Are you sure?';
const DEFAULT_CONFIRM_DESCRIPTION = '';
const DEFAULT_CONFIRM_CANCEL_LABEL = 'Cancel';
const DEFAULT_CONFIRM_CONFIRM_LABEL = 'Continue';

export function useConfirmService(): TConfirmServiceResult {
  const openModal = useOpenNewModal();

  const openConfirmHandler = React.useCallback(
    (options: IConfirmServiceOptions): void => {
      openModal({
        type: ModalType.CONFIRM,
        data: {
          title: options.title || DEFAULT_CONFIRM_TITLE,
          description: options.description || DEFAULT_CONFIRM_DESCRIPTION,
          cancelLabel: options.cancelLabel || DEFAULT_CONFIRM_CANCEL_LABEL,
          confirmLabel: options.confirmLabel || DEFAULT_CONFIRM_CONFIRM_LABEL,
          level: options.level,
          onAfterCancel: options.onAfterCancel,
          onAfterConfirm: options.onAfterConfirm,
          onBeforeCancel: options.onBeforeCancel,
          onBeforeConfirm: options.onBeforeConfirm,
          isLoadingConfirm: options.isLoadingConfirm || false,
          isLoadingCancel: options.isLoadingCancel || false,
          warningMessage: options.warningMessage || undefined
        }
      });
    },
    [openModal]
  );

  return openConfirmHandler;
}
