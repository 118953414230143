import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSocialLogoTwitterMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.353 9.254c.005.118.008.238.008.358 0 3.65-2.777 7.857-7.859 7.857a7.813 7.813 0 01-4.233-1.24 5.544 5.544 0 004.09-1.144 2.765 2.765 0 01-2.581-1.918 2.784 2.784 0 001.247-.046 2.762 2.762 0 01-2.216-2.708v-.035c.373.206.799.331 1.251.345a2.76 2.76 0 01-.854-3.688 7.843 7.843 0 005.692 2.887 2.762 2.762 0 014.707-2.52 5.513 5.513 0 001.755-.67 2.774 2.774 0 01-1.217 1.528 5.491 5.491 0 001.587-.434 5.556 5.556 0 01-1.377 1.428z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgSocialLogoTwitterMediumFilled;
