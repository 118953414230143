// Store
import { useStoreState } from '@src/store/store';

// Types
import { TEmailByAppCodeAndEmailCode } from '@src/store/models/emails/emails.types';

export function useGetEmailByAppCodeAndEmailCode(): TEmailByAppCodeAndEmailCode {
  return useStoreState(
    (state): TEmailByAppCodeAndEmailCode => state.emails.emailByAppCodeAndEmailCode
  );
}
