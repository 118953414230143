import React from 'react';
import { cloneDeep } from 'lodash';
import { UpdateTag, Tag as TagType, DynamicTagValue } from '@src/common/types/tag';
import { TypeTag } from '@src/common/constants/tag';
import { TFormFnSetFieldValue } from '@src/common/types/form';
import { useDynamicEvents } from '@src/store/hooks';
import { Select, TSelectOption } from '@src/ui/kit/Select';
import { Button, IconEnum } from '@src/ui/kit/';
import { getAvailableOptionsTags } from '@src/ui/features/SetTags/helpers/getAvailableOptionsTags';
import { newUpdateTagsByName } from '@src/ui/features/SetTags/helpers/newUpdateTagsByName';
import { SelectName, Tag, TagSelect, EventSelect, ValueSelect, TagRemove } from './styled';
import { EditFormValues } from '../EditFormSetTags';

type Props = {
  item: UpdateTag;
  applicationCode: string;
  existTags: TagType[];
  values: EditFormValues;
  names: Record<keyof EditFormValues, string>;
  index: number;
  isLoadAppCodes: Map<any, any>;
  removeDisabled: boolean;
  setFieldValue: TFormFnSetFieldValue;
  handleLoadAppCode: (appCode: string) => void;
  removeTag: (indexTag: number) => void;
};

export function DynamicTag(props: Props): JSX.Element {
  const {
    index,
    item,
    applicationCode,
    existTags,
    values,
    names,
    isLoadAppCodes,
    removeDisabled,
    setFieldValue,
    handleLoadAppCode,
    removeTag
  } = props;
  const { updateTags } = values;
  const value = item.value as DynamicTagValue;

  const { eventNameOptions, getAttributesList, getCurrentEvent, getEventValue } =
    useDynamicEvents(applicationCode);

  const existedTag = existTags.filter((tag) => tag.name === item.name);

  const attributesList = getAttributesList(value.eventName, {
    withMathTag: true,
    tagType: existedTag[0]?.type
  });

  const getTagNameOptions = React.useMemo(
    (): TSelectOption[] => getAvailableOptionsTags(existTags, values.updateTags),
    [existTags, values.updateTags]
  );

  const handleChangeName = React.useCallback(
    (name, indexTag) => {
      setFieldValue(
        names.updateTags,
        newUpdateTagsByName({
          name,
          indexTag,
          type: TypeTag.DYNAMIC,
          existTags,
          updateTags
        })
      );
    },
    [updateTags, existTags, names, setFieldValue]
  );

  const handleChangeEvent = React.useCallback(
    (value: { pointId: string; eventName: string; applicationCode: string }, indexTag) => {
      const newUpdateTags = updateTags.map((item, index) => {
        const updatedItem: UpdateTag = cloneDeep(item);

        if (index === indexTag && typeof item.value === 'object' && !Array.isArray(item.value)) {
          updatedItem.value = {
            ...item.value,
            pointId: value.pointId,
            eventName: value.eventName,
            applicationCode: value.applicationCode,
            attribute: ''
          };
        }
        return updatedItem;
      });

      setFieldValue(names.updateTags, newUpdateTags);
    },
    [updateTags, names, setFieldValue]
  );

  const handleChangeAttribute = React.useCallback(
    (attributeName: string, indexTag: number) => {
      const newUpdateTags = updateTags.map((item, index) => {
        const updatedItem = cloneDeep(item);

        if (index === indexTag && typeof item.value === 'object' && !Array.isArray(item.value)) {
          updatedItem.value = {
            ...item.value,
            attribute: attributeName
          };
        }
        return updatedItem;
      });
      setFieldValue(names.updateTags, newUpdateTags);
    },
    [names, updateTags, setFieldValue]
  );

  return (
    <Tag>
      <TagSelect>
        <SelectName
          name="name"
          value={
            item.name
              ? {
                  label: item.name,
                  value: item.name
                }
              : null
          }
          options={getTagNameOptions}
          placeholder="Select"
          isSearchable
          onChange={(currentValue: TSelectOption): void =>
            handleChangeName(currentValue.label, index)
          }
        />
      </TagSelect>
      <EventSelect>
        {item.name && (
          <Select
            name="event"
            value={getCurrentEvent(value.eventName, value.pointId)}
            options={eventNameOptions}
            placeholder="Select event"
            isSearchable
            onChange={(item) => {
              const event = item as TSelectOption;
              const { eventName, pointID, applicationCode } = getEventValue(
                event.value as unknown as string
              );

              handleChangeEvent({ pointId: pointID, eventName, applicationCode }, index);
            }}
          />
        )}
      </EventSelect>
      <ValueSelect>
        {item.name && value.eventName && (
          <Select
            name="name"
            value={
              value.attribute
                ? {
                    label: value.attribute,
                    value: value.attribute
                  }
                : null
            }
            placeholder="Select attribute"
            isSearchable
            options={attributesList}
            onChange={(currentValue: TSelectOption): void =>
              handleChangeAttribute(currentValue.value as string, index)
            }
            onFocus={() => applicationCode && handleLoadAppCode(applicationCode)}
            isLoading={isLoadAppCodes.get(applicationCode)}
          />
        )}
      </ValueSelect>
      <TagRemove>
        <Button
          disabled={removeDisabled}
          view="ghost"
          color="secondary"
          size="compact"
          onClick={() => removeTag(index)}
          iconType={IconEnum.CLOSE_SMALL}
        />
      </TagRemove>
    </Tag>
  );
}
