import styled from 'styled-components';
import { Color, FontSize, FontWeight, Spacing } from '@pushwoosh/kit-constants';

export const StyledTable = styled.table`
  display: flex;
  flex-direction: column;
`;

export const StyledRow = styled.tr`
  display: flex;
  min-width: 100%;
  border-bottom: 1px solid ${Color.DIVIDER};
`;

export const StyledCell = styled.td`
  display: flex;
  align-content: flex-start;
  align-items: center;
  height: 80px;
  padding-left: ${Spacing.S3};
  min-width: 122px;
  font-size: ${FontSize.REGULAR};
  font-weight: ${FontWeight.MEDIUM};

  &:first-child {
    padding-left: 0;
    flex-grow: 2;
  }
`;

export const StyledTableHeader = styled.th`
  display: flex;
  align-content: flex-start;
  min-width: 122px;
  padding: ${Spacing.S3};
  color: ${Color.LOCKED};
  font-size: ${FontSize.SMALL};

  &:first-child {
    padding-left: 0;
    flex-grow: 2;
  }
`;
