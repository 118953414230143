// Store
import { useStoreState } from '@src/store/store';

type TResult = string | null;

export function useCenteredPoint(): TResult {
  const centeredPoint = useStoreState(
    ({ errorsPoint }): string | null =>
      errorsPoint.isVisibleErrorsPaginate && errorsPoint.items[errorsPoint.currentError].id
  );

  return centeredPoint;
}
