import * as React from 'react';
import Konva from 'konva';

interface IUseHoverResult {
  isMouseEnter: boolean;
  handleMouseEnter: (event: Konva.KonvaEventObject<MouseEvent>) => void;
  handleMouseLeave: (event?: Konva.KonvaEventObject<MouseEvent>) => void;
}

export function useHover(
  onMouseEnter?: (event: Konva.KonvaEventObject<MouseEvent>) => void,
  onMouseLeave?: (event: Konva.KonvaEventObject<MouseEvent>) => void
): IUseHoverResult {
  const [isMouseEnter, setMouseEnter] = React.useState(false);

  const handleMouseEnter = React.useCallback(
    (event: Konva.KonvaEventObject<MouseEvent>): void => {
      if (onMouseEnter !== undefined) {
        onMouseEnter(event);
      }

      setMouseEnter(true);
    },
    [setMouseEnter, onMouseEnter]
  );

  const handleMouseLeave = React.useCallback(
    (event: Konva.KonvaEventObject<MouseEvent>): void => {
      if (onMouseLeave !== undefined) {
        onMouseLeave(event);
      }

      setMouseEnter(false);
    },
    [setMouseEnter, onMouseLeave]
  );

  return {
    isMouseEnter,
    handleMouseEnter,
    handleMouseLeave
  };
}
