import React from 'react';
import { Footnote, Paragraph } from '@pushwoosh/kit-typography';
import { TFormFnSetFieldValue } from '@src/common/types/form';
import { Switch } from '@src/ui/kit';
import { TimeStepperExactTime } from '@src/ui/kit/TimeStepperExactTime';
import { Item } from '@src/ui/form/styled';
import { useSession } from '@src/common/contexts/session';
import { IEditPointSendPushValues } from '../EditPointSendPush/EditPointSendPush.types';
import { Label } from './styled';

type Props = {
  values: IEditPointSendPushValues;
  names: Record<keyof IEditPointSendPushValues, string>;
  setFieldValue: TFormFnSetFieldValue;
};

export function OptimalTime(props: Props): JSX.Element {
  const { values, names, setFieldValue } = props;
  const sessionStore = useSession();

  const handleSwitchOptimalTime = React.useCallback(
    (value: boolean) => {
      setFieldValue(names.waitOptimalTime, value);
    },
    [names.waitOptimalTime, setFieldValue]
  );

  return (
    sessionStore.restrictions.allowBestTimeToSend && (
      <div>
        <Switch defaultEnabled={values.waitOptimalTime} onChange={handleSwitchOptimalTime}>
          Optimal time to send
        </Switch>
        <Item>
          <Paragraph>
            Each user will receive the message at the optimal time for them calculated based on
            their behavior and previously sent messages’ performance. If you first use — see guide.
          </Paragraph>
        </Item>
        {values.waitOptimalTime && (
          <>
            <Label>Default time</Label>
            <TimeStepperExactTime
              names={{
                exactTimeHour: names.defaultOptimalTimeHour,
                exactTimeMinute: names.defaultOptimalTimeMinute
              }}
              value={{
                exactTimeHour: values.defaultOptimalTimeHour,
                exactTimeMinute: values.defaultOptimalTimeMinute
              }}
              onChange={setFieldValue}
            />
            <Item>
              <Footnote>Message sent at recipient&apos;s local time if insufficient data.</Footnote>
            </Item>
          </>
        )}
      </div>
    )
  );
}
