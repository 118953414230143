import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgEmail(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 11a3 3 0 00-3 3v12a3 3 0 003 3h18a3 3 0 003-3V14a3 3 0 00-3-3H11zm-.427 2.18c-.031-.021-.03-.07.004-.086A.996.996 0 0111 13h18c.151 0 .294.034.423.094.035.016.035.065.004.087l-9.14 6.398a.5.5 0 01-.574 0l-9.14-6.398zm.214 2.591a.5.5 0 00-.787.41V26a1 1 0 001 1h18a1 1 0 001-1v-9.819a.5.5 0 00-.787-.41l-8.64 6.048a1 1 0 01-1.146 0l-8.64-6.048z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgEmail;
