import styled from 'styled-components';
import { Spacing, Color } from '@pushwoosh/kit-constants';

export const ItemWrap = styled.div`
  display: grid;
  grid-template-columns: 95px 16px;
  grid-template-rows: auto auto;
  gap: ${Spacing.S3};
  padding: ${Spacing.S5};
  border-bottom: 1px solid ${Color.DIVIDER};
`;

export const FullDocumentationWrap = styled.div`
  display: grid;
  grid-template-columns: 16px 95px;
  grid-template-rows: auto;
  gap: ${Spacing.S3};
  padding: ${Spacing.S7} 0;
`;
