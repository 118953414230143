import React from 'react';

// UI Components
import { ExtraOneButton, Modal, Section, FormField } from '@src/ui/kit';
import { PointTitle, ItemDetails } from '@src/ui/kit/DetailsPoint/styled';
import { DropOffStat } from '@src/ui/features/DropOffStat';

// Types
import { ModalType } from '@src/common/constants/modals';
import { TModal } from '@src/common/types/modal';
import { useCloseModalByType } from '@src/store/hooks/modals/useCloseModalByType';

interface IProps {
  filterName: string;
  filterCode: string;
  pointTitle: string;
  pointId: string;
  applicationId: string;
}

export function DetailSegmentSplitter(props: IProps): JSX.Element {
  const { filterName, filterCode, pointTitle, pointId, applicationId } = props;

  const modalSettings = {
    type: ModalType.EDITING_FORM_POINT,
    data: {
      pointId
    }
  } as TModal;

  const closeModalByType = useCloseModalByType();

  return (
    <Modal
      title={
        <>
          Segment Split:&nbsp;
          <PointTitle>{pointTitle}</PointTitle>
        </>
      }
      footerRight={
        <ExtraOneButton
          actionButtonName="Close"
          onClickActionButton={() => closeModalByType(ModalType.DETAIL_POINT)}
        />
      }
      isOpen
    >
      <Section direction="column">
        <ItemDetails>
          <DropOffStat pointId={pointId} modal={modalSettings} />
        </ItemDetails>
      </Section>
      <Section direction="column">
        <ItemDetails>
          <FormField
            label="Audience source:"
            value={filterName}
            url={`https://app.pushwoosh.com/applications/${applicationId}/segments/${filterCode}/detail`}
          />
        </ItemDetails>
      </Section>
    </Modal>
  );
}
