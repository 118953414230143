import { action, computed, thunkOn } from 'easy-peasy';
import { isAfter, isEqual } from 'date-fns';

// Common helpers
import { pointStorage } from '@src/common/helpers';

// Helpers
import { makeUniqueId } from '@src/common/helpers/makeUniqueId';
import {
  isOverlapPointsWithComments,
  isOverlapComments,
  convertArrayToDictionary
} from '@src/store/helpers';

// Constants
import { CANVAS_GRID_CELL_HEIGHT } from '@src/common/constants/canvas';

// Types
import { IDictionary, IVector2 } from '@src/common/types/types';
import { TComment } from '@src/common/types/comments';
import { ICommentsModel } from './comments.types';

export const model: ICommentsModel = {
  entities: {},
  activeCommentId: '',
  centeredCommentId: '',
  comments: computed(
    [(state): IDictionary<TComment> => state.entities],
    (commentsEntities): TComment[] =>
      Object.values(commentsEntities)
        .map((comment, index) => ({ ...comment, title: `${index + 1}` }))
        .filter((comment) => !comment.deleted)
  ),
  deletedComments: computed(
    [(state): IDictionary<TComment> => state.entities],
    (commentsEntities): TComment[] =>
      Object.values(commentsEntities)
        .map((comment, index) => ({ ...comment, title: `${index + 1}` }))
        .filter((comment) => comment.deleted)
  ),
  centeredCommentPosition: computed(
    [(state) => state],
    (state): IVector2 => state.entities[state.centeredCommentId]?.position
  ),
  isVisibleSidepanel: false,
  isVisibleDeletedComments: false,
  create: action((state, comment): void => {
    const id = makeUniqueId();

    state.entities[id] = { ...comment, id };
    state.activeCommentId = id;
  }),
  updateCommentPosition: action((state, payload) => {
    const { commentId, position } = payload;

    const comment = state.entities[commentId];

    state.entities[commentId] = { ...comment, position };
  }),
  setActive: action((state, commentId): void => {
    state.activeCommentId = commentId;
  }),
  checkOverlapComments: action((state, points): void => {
    const { comments, entities, activeCommentId } = state;
    const activeComment = entities[activeCommentId];

    if (activeComment) {
      for (;;) {
        // is active point overlap other points
        const isActiveCommentOverlapComments = comments.some(
          (comment): boolean =>
            comment.id !== activeCommentId && isOverlapComments(comment, activeComment)
        );

        const isActiveCommentOverlapPoints = points.some((point): boolean =>
          isOverlapPointsWithComments(point, activeComment)
        );

        // if active point overlap others -> move active point lower
        // and check overlapping again
        if (isActiveCommentOverlapComments || isActiveCommentOverlapPoints) {
          activeComment.position.y -= CANVAS_GRID_CELL_HEIGHT / 6;
        } else {
          break;
        }
      }
    }
  }),
  onChangeCommentsData: thunkOn(
    (
      actions
    ): [
      typeof actions.updateCommentPosition,
      typeof actions.updateComment,
      typeof actions.remove,
      typeof actions.restore
    ] => [actions.updateCommentPosition, actions.updateComment, actions.remove, actions.restore],
    (_, __, helpers): void => {
      const storeActions = helpers.getStoreActions();
      const state = helpers.getStoreState();
      storeActions.journeys.setUnsavedChanges(true);
      pointStorage.setPoints(
        state.journeys.currentJourneyId,
        state.points.items,
        state.comments.comments
      );
    }
  ),
  onLoad: thunkOn(
    (_, storeActions): string[] => [
      storeActions.journeys.loadById.successType,
      storeActions.journeys.updateById.successType,
      storeActions.journeys.activateById.successType,
      storeActions.journeys.autoSaveById.successType
    ],
    (_, target, helpers): void => {
      const { getState, getStoreState, getStoreActions } = helpers;
      const state = getState();
      const stateStore = getStoreState();
      const storeActions = getStoreActions();

      const pointDataStorage = pointStorage.getPoints(stateStore.journeys.currentJourneyId);

      const dateFromServer = new Date(target.result.updatedAt);
      const dateFromLocal = new Date(
        pointDataStorage && pointDataStorage.updatedAt
          ? pointDataStorage.updatedAt
          : target.result.updatedAt
      );

      const isNewerDataOnServer =
        isAfter(dateFromServer, dateFromLocal) || isEqual(dateFromServer, dateFromLocal);

      if (isNewerDataOnServer) {
        state.entities = convertArrayToDictionary<TComment>(target.result.comments ?? []);
        pointStorage.removePoints(stateStore.journeys.currentJourneyId);
      } else {
        state.entities = convertArrayToDictionary<TComment>(pointDataStorage.comments);
        storeActions.journeys.setUnsavedChanges(true);
      }
    }
  ),
  updateComment: action((state, comment): void => {
    const { id } = comment;

    state.entities[id] = comment;
    state.activeCommentId = id;
  }),
  remove: action((state, commentId): void => {
    const comment = state.entities[commentId];

    state.entities[commentId] = { ...comment, deleted: true };
  }),
  restore: action((state, commentId): void => {
    const comment = state.entities[commentId];

    state.entities[commentId] = { ...comment, deleted: false };
  }),
  showSidepanel: action((state): void => {
    state.isVisibleSidepanel = true;
  }),
  hideSidepanel: action((state): void => {
    state.isVisibleSidepanel = false;
  }),
  setCenteredComment: action((state, commentId): void => {
    state.centeredCommentId = commentId;
  }),
  toggleDeletedComments: action((state): void => {
    state.isVisibleDeletedComments = !state.isVisibleDeletedComments;
  }),
  cancel: action((state, commentId): void => {
    delete state.entities[commentId];
  })
};
