import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSecureSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.03 7.03a.75.75 0 10-1.06-1.06L7.5 8.44l-.97-.97a.75.75 0 00-1.06 1.06l1.5 1.5a.75.75 0 001.06 0l3-3z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.216 1.282a.75.75 0 00-.432 0l-5 1.5a.75.75 0 00-.534.718V9c0 2.142 1.53 3.588 2.841 4.441a11.615 11.615 0 002.672 1.27l.014.005.005.002h.002c.14.042.29.043.432 0L8 14l.217.718h.001l.005-.002.014-.004a3.966 3.966 0 00.226-.075 11.612 11.612 0 002.446-1.195c1.312-.854 2.841-2.3 2.841-4.442V3.5a.75.75 0 00-.534-.718l-5-1.5zM8 14l-.216.718L8 14zm.493-.981c-.2.083-.37.147-.493.19a10.124 10.124 0 01-2.091-1.025C4.72 11.412 3.75 10.358 3.75 9V4.058L8 2.783l4.25 1.275V9c0 1.358-.97 2.412-2.159 3.184-.576.374-1.157.651-1.598.835z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgSecureSmallLined;
