// Types
import { IOption } from '@src/common/types/select';
import { TFilter } from '@src/common/types/filter';

export function makeFilterOptions(filters: TFilter[]): IOption<string>[] {
  return filters.map(
    (filter): IOption<string> => ({
      label: filter.name,
      value: filter.code
    })
  );
}
