import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSeparator(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x={7} y={4} width={2} height={8} rx={1} fill="#DBDBDB" />
    </svg>
  );
}

export default SvgSeparator;
