import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSendPushPoint(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14.2c0-1.12 0-1.68.218-2.108a2 2 0 01.874-.874C9.52 11 10.08 11 11.2 11h17.6c1.12 0 1.68 0 2.108.218a2 2 0 01.874.874C32 12.52 32 13.08 32 14.2v10.6c0 1.12 0 1.68-.218 2.108a2 2 0 01-.874.874C30.48 28 29.92 28 28.8 28H12.53a1 1 0 00-.696.282l-2.477 2.402c-.427.414-.64.621-.823.633a.5.5 0 01-.416-.175C8 31.002 8 30.704 8 30.11V14.2zM25 17a1 1 0 01-1 1H13a1 1 0 01-1-1v-1a1 1 0 011-1h11a1 1 0 011 1v1zm-13 5a1 1 0 001 1h5a1 1 0 001-1v-1a1 1 0 00-1-1h-5a1 1 0 00-1 1v1z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgSendPushPoint;
