import React from 'react';

// Components
import { DocsIcon, HistoryIcon, NotesListIcon, RewardIcon, WizardIcon } from '@pushwoosh/kit-icons';

// Constants
import { SidebarType } from '@src/common/constants/sidebars';

// Store Hooks
import { useCurrentJourneyId, useGetJourneyById, useGettingStarted } from '@src/store/hooks';
import { useStoreState } from '@src/store/store';
import { JourneyStatus } from '@src/common/constants';

import { useMagic } from '@src/store/hooks/useMagic';
import { Color } from '@pushwoosh/kit-constants';
import { ZoomControl } from '../ZoomControl';
import { Button } from '../Button';

// Types
import { IControlsProps } from './Controls.types';

// Styled
import { ControlsPanelContainer, Countdown, GettingStartedContainer, NotOpened } from './styled';

const COUNTDOWN = 30;

export function Controls(props: IControlsProps): JSX.Element {
  const {
    zoom = 1,
    isDisabledIncrement,
    isDisabledDecrement,
    onIncrementZoom,
    onDecrementZoom,
    onResetZoom,
    onShowDocumentation,
    onShowComments,
    onShowChangeLog
  } = props;

  const { isGettingStarted } = useGettingStarted();
  const sidebar = useStoreState((store) => store.sidebars?.sidebar);
  const journeyId = useCurrentJourneyId();
  const getJourneyById = useGetJourneyById();
  const journey = getJourneyById(journeyId);
  const { doMagic, undoMagic } = useMagic();

  const [oldCoords, setOldCoords] = React.useState([]);
  const [secondsLeft, setSecondsLeft] = React.useState(COUNTDOWN);

  React.useEffect(() => {
    if (secondsLeft <= 0 && oldCoords.length !== 0) return setOldCoords([]);

    const intervalId = setInterval(() => {
      if (oldCoords.length !== 0) {
        setSecondsLeft(secondsLeft - 1);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [secondsLeft, oldCoords]);

  const handleDoMagic = () => {
    const oldCoords = doMagic();
    setOldCoords(oldCoords);
    setSecondsLeft(30);
  };

  const handleUnDoMagic = () => {
    undoMagic(oldCoords);
    setOldCoords([]);
  };

  const renderZoomScale = `${(zoom * 100).toFixed(0)}%`;

  return (
    <ControlsPanelContainer>
      {journey?.status === JourneyStatus.PAUSED && (
        <GettingStartedContainer>
          <Button isActive={sidebar?.type === SidebarType.HISTORY} onClick={onShowChangeLog}>
            <HistoryIcon size="medium" view="lined" />
          </Button>
        </GettingStartedContainer>
      )}
      <GettingStartedContainer>
        <Button onClick={oldCoords.length !== 0 ? handleUnDoMagic : handleDoMagic} isActive={false}>
          {oldCoords.length !== 0 ? (
            <>
              <Countdown color={secondsLeft <= 10 ? Color.DANGER : Color.MAIN}>
                {secondsLeft}
              </Countdown>
              <RewardIcon
                size="medium"
                view="lined"
                color={secondsLeft <= 10 ? Color.DANGER : Color.MAIN}
              />
            </>
          ) : (
            <WizardIcon size="medium" view="filled" />
          )}
        </Button>
      </GettingStartedContainer>
      <GettingStartedContainer>
        {isGettingStarted && <NotOpened />}
        <Button isActive={sidebar?.type === SidebarType.COMMENTS} onClick={onShowComments}>
          <NotesListIcon size="medium" view="lined" />
        </Button>
      </GettingStartedContainer>
      <GettingStartedContainer>
        <Button
          isActive={sidebar?.type === SidebarType.DOCUMENTATION}
          onClick={onShowDocumentation}
        >
          <DocsIcon size="medium" view="lined" />
        </Button>
      </GettingStartedContainer>
      <ZoomControl
        value={renderZoomScale}
        isDisabledIncrement={isDisabledIncrement}
        isDisabledDecrement={isDisabledDecrement}
        onResetZoom={onResetZoom}
        onDecrementZoom={onDecrementZoom}
        onIncrementZoom={onIncrementZoom}
      />
    </ControlsPanelContainer>
  );
}
