import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgCloseRoundMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.636 18.364a8.995 8.995 0 010-12.728 8.995 8.995 0 0112.728 0 8.995 8.995 0 010 12.728 8.995 8.995 0 01-12.728 0zm9.9-2.829a1 1 0 01-1.415 0L12 13.415l-2.121 2.12a1 1 0 11-1.415-1.414L10.586 12 8.464 9.879A1 1 0 019.88 8.464L12 10.586l2.121-2.122a1 1 0 111.415 1.415l-2.122 2.12 2.122 2.122a1 1 0 010 1.414z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgCloseRoundMediumFilled;
