// Store
import { useStoreActions, useStoreState } from '@src/store/store';

// Types
import { TJourney, ParamsJourneyList } from '@src/common/types/journey';
import { IDictionary } from '@src/common/types/types';
import { TRequest } from '@src/store/models/requests/requests.types';

type TActionLoad = (payload: ParamsJourneyList) => Promise<IDictionary<TJourney>>;
type TResult = [TActionLoad, boolean];

export function useLoadJourneys(): TResult {
  const load: TActionLoad = useStoreActions((actions): TActionLoad => actions.journeys.load);

  const request: TRequest = useStoreState(
    (state): TRequest => state.requests.entities['journeys.load']
  );

  return [load, Boolean(request)];
}
