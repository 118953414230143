import { thunk, actionOn, computed } from 'easy-peasy';

// Types
import { TEvent, TEventsByMultipleAppCode } from '@src/common/types/event';
import { IEventModel } from './event.types';

export const model: IEventModel = {
  entities: {},

  eventsByAppCode: computed(
    (state): ((applicationCode: string) => TEvent[]) =>
      (applicationCode): TEvent[] =>
        state.entities[applicationCode] || []
  ),

  eventsByAppCodes: computed(
    (state): ((applicationCodes: string[]) => TEvent[]) =>
      (applicationCodes): TEvent[] => {
        const events: TEvent[] = [];
        applicationCodes.forEach((appCode) => {
          events.push(...state.entities[appCode]);
        });
        return events;
      }
  ),

  loadByAppCode: thunk(async (_, payload, helpers): Promise<{ code: string; events: TEvent[] }> => {
    const {
      injections: { eventService, api }
    } = helpers;

    const response = await eventService.fetchByAppCode(payload, api);

    return {
      code: payload,
      events: response.payload.items
    };
  }),

  loadByAppCodes: thunk(async (_, payload, helpers): Promise<TEventsByMultipleAppCode> => {
    const {
      injections: { eventService, api }
    } = helpers;

    const response = await eventService.fetchByAppCodes(payload, api);

    return response.payload;
  }),

  onLoad: actionOn(
    (state): string => state.loadByAppCode.successType,
    (state, target): void => {
      state.entities[target.result.code] = target.result.events;
    }
  ),
  onLoadMultipleAppCodes: actionOn(
    (state): string => state.loadByAppCodes.successType,
    (state, target): void => {
      const appCodes = Object.keys(target.result);
      appCodes.forEach((AppCode) => {
        state.entities[AppCode] = target.result[AppCode];
      });
    }
  )
};
