import * as React from 'react';

// Store
import { useStoreActions } from '@src/store/store';

// Types
import { IUniqueId } from '@src/common/types/entities';

export type TSetActivePoint = (pointId: IUniqueId['id'], isMultiple?: boolean) => void;
type TResult = TSetActivePoint;

export function useSetActivePoint(): TResult {
  const setActivePoint: TSetActivePoint = useStoreActions<TSetActivePoint>(
    (actions): TSetActivePoint => actions.points.setActive
  );

  const setMultipleActivePoint: TSetActivePoint = useStoreActions<TSetActivePoint>(
    (actions): TSetActivePoint => actions.points.setMultipleActive
  );

  const handlePointSetActive: TSetActivePoint = React.useCallback<TSetActivePoint>(
    (pointId, isMultiple): void => {
      if (isMultiple) {
        setMultipleActivePoint(pointId);
      } else {
        setActivePoint(pointId);
      }
    },
    [setActivePoint, setMultipleActivePoint]
  );

  return handlePointSetActive;
}
