import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgHomeSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.48 2.424a.75.75 0 00-.96 0l-6 5 .96 1.152.77-.641V13c0 .414.336.75.75.75h2.5a.75.75 0 00.75-.75V9.75h1.5V13c0 .414.336.75.75.75H12a.75.75 0 00.75-.75V7.935l.77.641.96-1.152-6-5zm2.77 4.26L8 3.977 4.75 6.685v5.565h1V9a.75.75 0 01.75-.75h3a.75.75 0 01.75.75v3.25h1V6.685z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgHomeSmallLined;
