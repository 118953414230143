import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgEmojiMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 1.5a8.5 8.5 0 100 17 8.5 8.5 0 000-17zm4.417 10.376a.75.75 0 01.207 1.04C15.589 16.466 14.019 17.25 12 17.25c-2.02 0-3.59-.785-4.624-2.333a.75.75 0 011.248-.834C9.37 15.202 10.467 15.75 12 15.75s2.63-.548 3.376-1.667a.75.75 0 011.04-.207zM8.5 8a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm7 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgEmojiMediumLined;
