import styled from 'styled-components';

import { Spacing } from '@pushwoosh/kit-constants';

export const TimeStepperContainer = styled.div`
  display: grid;

  grid-template-columns: repeat(3, 100px);
  column-gap: ${Spacing.S5};
`;
