import React from 'react';

// Types
import { ITooltipContentProps } from './TooltipContent.types';

// Styled
import { TooltipContentItem } from './styled';

export function TooltipContent(props: ITooltipContentProps): JSX.Element {
  const { tooltipData } = props;

  return <TooltipContentItem>{tooltipData}</TooltipContentItem>;
}
