import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgLockSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.25A2.75 2.75 0 005.25 5v1.25H5c-.69 0-1.25.56-1.25 1.25v4c0 .69.56 1.25 1.25 1.25h6c.69 0 1.25-.56 1.25-1.25v-4c0-.69-.56-1.25-1.25-1.25h-.25V5A2.75 2.75 0 008 2.25zm1.25 4V5a1.25 1.25 0 10-2.5 0v1.25h2.5zm-4 1.5v3.5h5.5v-3.5h-5.5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgLockSmallLined;
