import React from 'react';
import { MinusIcon, PlusIcon } from '@pushwoosh/kit-icons';
import { Container, Control, Value } from './styled';

type Props = {
  value: string;
  isDisabledIncrement: boolean;
  isDisabledDecrement: boolean;
  onResetZoom: React.MouseEventHandler<HTMLButtonElement>;
  onDecrementZoom: React.MouseEventHandler<HTMLButtonElement>;
  onIncrementZoom: React.MouseEventHandler<HTMLButtonElement>;
};

export function ZoomControl(props: Props): JSX.Element {
  const {
    value,
    isDisabledIncrement,
    isDisabledDecrement,
    onResetZoom,
    onDecrementZoom,
    onIncrementZoom
  } = props;

  return (
    <Container>
      <Control onClick={onDecrementZoom} isDisabled={isDisabledDecrement}>
        <MinusIcon size="small" />
      </Control>
      <Value onClick={onResetZoom}>{value}</Value>
      <Control onClick={onIncrementZoom} isDisabled={isDisabledIncrement}>
        <PlusIcon size="small" />
      </Control>
    </Container>
  );
}
