export const CANVAS_GRID_CELL_WIDTH = 25;
export const CANVAS_GRID_CELL_HEIGHT = 25;
export const CANVAS_GRIP_ROUNDING_ACCURACY = 5;
export const CANVAS_ARROW_COLOR = '#475266';
export const CANVAS_BACKGROUND_COLOR = '#f5f5f5';

export const CANVAS_ITEM_TYPE_POINT = 'point';
export const CANVAS_ITEM_TYPE_ARROW = 'arrow';

export const COLOR_GREEN = '#479900';
export const COLOR_ORANGE = '#F08000';

export const ZOOM_DEFAULT = 1; // 100%
export const ZOOM_MAX_RANGE = 4; // 400%
export const ZOOM_MIN_RANGE = 0.18; // 18%
export const ZOOM_STEP = 0.25; // 25%
