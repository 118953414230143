import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgFolderSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 3.75a.816.816 0 00-.552.207.583.583 0 00-.198.428v6.23c0 .148.063.304.198.428a.817.817 0 00.552.207h9c.217 0 .415-.08.552-.207a.584.584 0 00.198-.428V5.77a.584.584 0 00-.198-.428.816.816 0 00-.552-.206H8.75a.75.75 0 01-.509-.2L6.957 3.75H3.5zm-1.57-.896A2.316 2.316 0 013.5 2.25h3.75a.75.75 0 01.509.199l1.284 1.186H12.5c.578 0 1.144.211 1.57.604.427.395.68.944.68 1.53v4.846c0 .587-.253 1.136-.68 1.53a2.316 2.316 0 01-1.57.605h-9a2.316 2.316 0 01-1.57-.604 2.083 2.083 0 01-.68-1.53V4.384c0-.587.253-1.136.68-1.53z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgFolderSmallLined;
