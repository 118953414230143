import styled from 'styled-components';
import { Color, FontWeight, FontSize, LineHeight, Spacing } from '@pushwoosh/kit-constants';

type ButtonProps = {
  isCompact?: boolean;
};

export const ButtonField = styled.div`
  font-weight: ${FontWeight.MEDIUM};
  font-size: ${FontSize.REGULAR};
  line-height: ${LineHeight.REGULAR};
  color: ${Color.MAIN};
  margin-left: ${Spacing.S1};
`;

export const StyledButton = styled.button<ButtonProps>`
  height: 52px;
  width: ${({ isCompact }) => (isCompact ? '52px' : 'auto')};
  justify-content: center;
  border: none;
  outline: transparent;
  display: flex;
  align-items: center;
  padding: ${({ isCompact }) => (isCompact ? '14px' : '16px 20px 16px 12px')};
  color: ${Color.MAIN};
  cursor: pointer;
  background: transparent;
  position: relative;

  &:hover {
    background: ${Color.BRIGHT_LIGHT};
    color: ${Color.PRIMARY_HOVER};

    ${ButtonField} {
      color: ${Color.PRIMARY_HOVER};
    }
  }

  &:active {
    background: ${Color.BRIGHT_LIGHT};
    opacity: 0.5;

    color: ${Color.PRIMARY_HOVER};

    ${ButtonField} {
      color: ${Color.PRIMARY_HOVER};
    }
  }
`;
