import React from 'react';

// Helpers
import { findOption } from '@src/ui/helpers';

// Components
import { FormControl } from '@src/ui/form/FormControl';

// Types
import { ISelectOption } from '@src/common/types/form';
import { TOption } from '@pushwoosh/ui/lib/molecules/Select';
import { IFormSelectProps } from './FormSelect.types';

// Styled
import { Grid, Select } from './styled';

const MENU_PORTAL_TARGET = document.querySelector('body');

export function FormSelect<Value extends string | number | boolean | any>(
  props: IFormSelectProps<Value>
): JSX.Element {
  const {
    url,
    label,
    className,
    placeholder = 'Select...',
    name,
    value,
    valueLabel,
    options,
    error,
    components,
    isLoading = false,
    isInvalid = false,
    isDisabled = false,
    isSearchable = false,
    actionText,
    rightButton,
    onChange,
    onBlur,
    onActionClick
  } = props;

  const handleSelectChange = React.useCallback(
    (option: TOption): void => {
      onChange(name, option.value, option.label);
    },
    [onChange, name]
  );

  const handleSelectBlur = React.useCallback((): void => {
    if (typeof onBlur === 'function') {
      onBlur(name, true);
    }
  }, [onBlur, name]);

  const selectedOption = findOption(options, value, valueLabel);

  return (
    <FormControl
      actionText={actionText}
      onActionClick={onActionClick}
      className={className}
      label={label}
      url={url}
      error={error}
      disabled={isDisabled}
    >
      <Grid hasButton={!!rightButton}>
        <Select
          name={name}
          placeholder={placeholder}
          value={selectedOption as ISelectOption<string>}
          options={options as ISelectOption<string>[]}
          menuPortalTarget={MENU_PORTAL_TARGET}
          components={components}
          isOptionSelected={(
            option: ISelectOption<string>,
            options: ISelectOption<string>[]
          ): boolean =>
            options.some(
              (item): boolean => item.value === option.value && item.label === option.label
            )
          }
          isSearchable={isSearchable}
          isLoading={isLoading}
          isInvalid={isInvalid}
          isDisabled={isDisabled}
          onChange={handleSelectChange}
          onBlur={handleSelectBlur}
        />

        {rightButton}
      </Grid>
    </FormControl>
  );
}
