// Store
import { useStoreState } from '@src/store/store';

// Types
import { TComment } from '@src/common/types/comments';

type TResult = TComment[];

export function useComments(): TResult {
  const comments: TComment[] = useStoreState<TComment[]>(
    (state): TComment[] => state.comments.comments
  );

  return comments;
}
