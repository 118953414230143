import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPlatformLogoBaiduMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.422 12.185c1.895-.408 1.637-2.672 1.58-3.168-.092-.763-.99-2.097-2.21-1.991-1.533.137-1.757 2.352-1.757 2.352-.207 1.025.496 3.213 2.387 2.807zm2.012 3.938c-.055.16-.18.567-.071.922.21.795.902.83.902.83h.992V15.45H9.195c-.478.143-.709.514-.76.673zM9.94 8.386c1.047 0 1.893-1.204 1.893-2.694C11.832 4.204 10.986 3 9.939 3 8.894 3 8.046 4.204 8.046 5.692c0 1.49.848 2.694 1.893 2.694zm4.508.178c1.399.182 2.299-1.311 2.477-2.443.182-1.13-.72-2.443-1.71-2.668-.993-.227-2.232 1.362-2.345 2.399-.134 1.267.181 2.532 1.578 2.712zm3.428 6.652s-2.165-1.675-3.428-3.485c-1.712-2.668-4.146-1.582-4.96-.226-.81 1.357-2.073 2.216-2.252 2.443-.181.224-2.614 1.537-2.075 3.936.54 2.397 2.437 2.352 2.437 2.352s1.398.137 3.02-.226c1.622-.36 3.018.09 3.018.09s3.789 1.27 4.826-1.174c1.036-2.443-.586-3.71-.586-3.71zm-6.483 3.635H8.93c-1.064-.211-1.488-.938-1.542-1.062-.052-.126-.354-.71-.194-1.702.46-1.487 1.771-1.594 1.771-1.594h1.311V12.88l1.117.017v5.953zm4.588-.017h-2.835c-1.099-.283-1.15-1.063-1.15-1.063v-3.136l1.15-.018v2.818c.07.3.443.354.443.354h1.168v-3.154h1.223v4.2zm4.013-8.37c0-.541-.45-2.17-2.118-2.17-1.67 0-1.894 1.538-1.894 2.625 0 1.038.088 2.487 2.164 2.44 2.075-.045 1.848-2.35 1.848-2.895z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPlatformLogoBaiduMediumFilled;
