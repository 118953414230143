// Store
import { useStoreState } from '@src/store/store';

// Types
import { TPoints } from '@src/common/types/points';

type TResult = TPoints[];

export function usePoints(): TResult {
  const points: TPoints[] = useStoreState<TPoints[]>(
    (state): TPoints[] => state.points.filteredItems
  );

  return points;
}
