import React from 'react';
import { sendMixpanelEvent } from '@src/common/analytics';
import {
  useCreatePoint,
  useCheckOverlapPoints,
  useCreateComment,
  useDragAndDropStore
} from '@src/store/hooks';
import { IVector2 } from '@src/common/types/types';
import { TPoints } from '@src/common/types/points';

export function useAddPoint() {
  const handlePointCreate = useCreatePoint();
  const handlePointsCheckOverlap = useCheckOverlapPoints();
  const handleCommentCreate = useCreateComment();
  const dragAndDropStore = useDragAndDropStore();

  const onDragEndNewPoint = React.useCallback(
    (position: IVector2): void => {
      if (dragAndDropStore.isDragging) {
        if (dragAndDropStore.draggablePoint.type === 'comment') {
          sendMixpanelEvent({ eventName: 'CommentAdded' });
          handleCommentCreate({ ...dragAndDropStore.draggablePoint, position });
        } else {
          sendMixpanelEvent({
            eventName: 'PointAdded',
            eventData: {
              PointType: dragAndDropStore.draggablePoint.type
            }
          });
          handlePointCreate({
            type: dragAndDropStore.draggablePoint.type as TPoints['type'],
            position
          });
        }
      }
    },
    [handlePointCreate, dragAndDropStore, handleCommentCreate]
  );

  return {
    onDragEndNewPoint,
    handlePointsCheckOverlap
  };
}
