// Store
import { useStoreActions, useStoreState } from '@src/store/store';

// Types
import { TRequest } from '@src/store/models/requests/requests.types';
import { TEmail } from '@src/common/types/email';

type TActionLoad = (payload: string) => Promise<{ code: string; emails: TEmail[] }>;
type TResult = [TActionLoad, boolean];

export function useLoadEmails(): TResult {
  const load: TActionLoad = useStoreActions((actions): TActionLoad => actions.emails.loadByAppCode);

  const request: TRequest = useStoreState(
    (state): TRequest => state.requests.entities['emails.loadByAppCode']
  );

  return [load, Boolean(request)];
}
