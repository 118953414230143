import * as React from 'react';
import Konva from 'konva';
import { Group, Rect } from 'react-konva';
import { useBus } from '@src/common/contexts/bus';
import { Vector2d } from 'konva/lib/types';
import { Node, NodeConfig } from 'konva/lib/Node';

// Constants
import {
  GroupSizeContent,
  DEFAULT_POINT_BLOCK_OFFSET,
  INVALID_COLOR,
  POINTS_WITH_INPUTS
} from '@src/common/constants';
import { useStoreState } from '@src/store/store';
import {
  useStoreTooltips,
  useActionHideTooltip,
  useActionUpdatePositionAtPoint,
  useCheckOverlapPoints
} from '@src/store/hooks';
import { hotkeys$ } from '@src/store/subjects/hotkeys';

// Helpers
import { getSizePoint } from '@src/common/helpers';

// Components
import { PointMenu } from './PointMenu';

// Types
import { IProps } from './Point.types';
import { PointInfoBadgeMemo } from './PointInfoBadge';

export function Point(props: IProps): JSX.Element {
  const {
    id,
    type,
    countContent,
    children,
    x,
    y,
    onDelete,
    onClone,
    isDraggable,
    isInvalid,
    onClick,
    onDblClick,
    color = '#ffffff',
    onMouseDown,
    onMouseEnter,
    onMouseLeave,
    onMouseUp,
    onMouseMove,
    isActive,
    isConfigured
  } = props;
  const bus = useBus();
  const [hotkeys, setHotkeys] = React.useState(hotkeys$.initialState);

  React.useEffect(() => {
    hotkeys$.subscribe(setHotkeys);
    hotkeys$.init();
  }, []);

  const [isHover, setHover] = React.useState(false);

  const activePointIds = useStoreState((state) => state.points.activePointIds);
  const tooltips = useStoreTooltips();
  const hideTooltip = useActionHideTooltip();
  const actionUpdatePositionAtPoint = useActionUpdatePositionAtPoint();
  const handlePointsCheckOverlap = useCheckOverlapPoints();

  const { widthPoint: width, heightPoint: height } = getSizePoint(type);

  const strokeWidth = React.useMemo(
    () => (isActive || isInvalid ? 2 : null),
    [isActive, isInvalid]
  );
  const stroke = React.useMemo(() => (isActive ? color : null), [isActive, color]);
  const shadowOffsetY = React.useMemo(() => (isActive || isHover ? 8 : 2), [isActive, isHover]);
  const shadowBlur = React.useMemo(() => (isActive || isHover ? 12 : 4), [isActive, isHover]);
  const shadowOpacity = React.useMemo(
    () => (isActive || isHover ? 0.16 : 0.1),
    [isActive, isHover]
  );

  const amountContent = GroupSizeContent.HEIGHT * countContent;
  const amountOffset = (countContent + 1) * DEFAULT_POINT_BLOCK_OFFSET;

  const heightPoint = !countContent ? height : amountContent + amountOffset;

  const handleMouseEnter = React.useCallback(
    (event: Konva.KonvaEventObject<MouseEvent>) => {
      setHover(true);

      onMouseEnter(event);
    },
    [onMouseEnter]
  );

  const handleMouseLeave = React.useCallback(
    (event: Konva.KonvaEventObject<MouseEvent>) => {
      setHover(false);

      onMouseLeave(event);
    },
    [onMouseLeave]
  );

  const handlePointDragMove = React.useCallback(
    (konvaEvent: any) => {
      konvaEvent.evt.preventDefault();
      const group = konvaEvent.currentTarget;

      if (tooltips.length > 0) {
        hideTooltip({ id, force: true });
      }
      bus.emit({
        type: 'reCalcArrowPosition$',
        withCopmplete: false,
        payload: [group]
      });
    },
    [bus, hideTooltip, tooltips, id]
  );

  const handlePointDragEnd = React.useCallback(
    (konvaEvent: any) => {
      konvaEvent.evt.preventDefault();
      const group = konvaEvent.currentTarget;

      actionUpdatePositionAtPoint(id, {
        x: group.x(),
        y: group.y()
      });

      handlePointsCheckOverlap();

      bus.emit({
        type: 'reCalcArrowPosition$',
        withCopmplete: false,
        payload: [group]
      });
    },
    [actionUpdatePositionAtPoint, bus, handlePointsCheckOverlap, id]
  );

  // eslint-disable-next-line prefer-arrow-callback
  const dragBoundFunc = React.useCallback(
    // eslint-disable-next-line func-names
    function (this: Node<NodeConfig>, pos: Vector2d): Vector2d {
      return {
        x: pos.x,
        // eslint-disable-next-line react/no-this-in-sfc
        y: hotkeys.isAltPressed || hotkeys.isShiftPressed ? this.absolutePosition().y : pos.y
      };
    },
    [hotkeys.isAltPressed, hotkeys.isShiftPressed]
  );

  return (
    <Group
      pointId={id}
      pointType={type}
      name="point"
      x={x}
      y={y}
      width={width}
      height={heightPoint}
      fill="#ffffff"
      draggable={isDraggable && activePointIds.length <= 1}
      onMouseUp={POINTS_WITH_INPUTS.has(type) ? onMouseUp : undefined}
      onMouseMove={POINTS_WITH_INPUTS.has(type) ? onMouseMove : undefined}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onDragMove={handlePointDragMove}
      onDragEnd={handlePointDragEnd}
      perfectDrawEnabled={false}
      dragBoundFunc={dragBoundFunc}
    >
      {activePointIds.length === 1 && (
        <PointMenu type={type} isActive={isActive} onDelete={onDelete} onClone={onClone} />
      )}

      <Group
        onClick={onClick}
        onDblClick={onDblClick}
        onMouseDown={onMouseDown}
        perfectDrawEnabled={false}
      >
        <Rect
          width={width}
          height={heightPoint}
          fill="#ffffff"
          strokeWidth={strokeWidth}
          stroke={isInvalid ? INVALID_COLOR : stroke}
          cornerRadius={4}
          shadowOffsetX={0}
          shadowOffsetY={shadowOffsetY}
          shadowBlur={shadowBlur}
          shadowColor="#000000"
          shadowOpacity={shadowOpacity}
          perfectDrawEnabled={false}
        />

        {children}
      </Group>

      {!isConfigured ? <PointInfoBadgeMemo x={-8} y={-8} /> : null}
    </Group>
  );
}
