import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgTimeDelayPointFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={24} height={24} rx={4} fill="#00A2FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18.5a6.5 6.5 0 100-13 6.5 6.5 0 000 13zm.75-9.76v-.25a.5.5 0 00-.5-.5h-.5a.5.5 0 00-.5.5v3.542a.75.75 0 00.364.643l2.62 1.57.214.128a.5.5 0 00.686-.172l.257-.43a.5.5 0 00-.172-.685l-.214-.129-2.012-1.205a.5.5 0 01-.243-.429V8.74z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgTimeDelayPointFilled;
