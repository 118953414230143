import React from 'react';
import { TComment } from '@src/common/types/comments';
import { format } from 'date-fns';
import { IStatuses } from '@src/common/types/mode';
import { CommentItem } from '../CommentItem';
import { CommentForm } from '../CommentForm';

type Props = {
  order: string;
  date: string;
  message: string;
  commentId: string;
  position: TComment['position'];
  onCommentClick: (commentId: string) => void;
  updateComment: (comment: TComment) => void;
  onCommentRemove: (commentId: string) => void;
  mode: IStatuses;
};

export function Comment(props: Props): JSX.Element {
  const {
    order,
    date,
    message,
    commentId,
    onCommentClick,
    position,
    mode,
    updateComment,
    onCommentRemove
  } = props;
  const [isEditComment, setEditComment] = React.useState(false);
  const dateObj = new Date(date);
  const formatedDate = date && format(dateObj, 'HH:mm, MMMM dd, yyyy');

  const enableEdit = React.useCallback(() => {
    if (mode.isRead) {
      return;
    }
    setEditComment(true);
  }, [mode.isRead]);

  const disableEdit = React.useCallback(() => {
    setEditComment(false);
  }, []);

  return isEditComment ? (
    <CommentForm
      commentId={commentId}
      createdAt={date}
      disableEdit={disableEdit}
      order={order}
      date={formatedDate}
      message={message}
      position={position}
      updateComment={updateComment}
      onRemove={onCommentRemove}
    />
  ) : (
    <CommentItem
      onClick={onCommentClick}
      onRemove={onCommentRemove}
      onDbClick={enableEdit}
      commentId={commentId}
      order={order}
      date={formatedDate}
      message={message}
    />
  );
}
