// Models
import { model as applicationModel } from '../application/application.model';
import { model as filterModel } from '../filter/filter.model';
import { model as TagModel } from '../tag/tag.model';
import { model as pointsModel } from '../points/points.model';
import { model as canvasModel } from '../canvas/canvas.model';
import { model as journeysModel } from '../journeys/journeys.model';
import { model as pointEditMenuModel } from '../pointEditMenu/pointEditMenu.model';
import { model as popupMenuModel } from '../popupMenu/popupMenu.model';
import { model as sidebarModel } from '../sidebar/sidebar.model';
import { model as modeModel } from '../mode/mode.model';
import { model as userModel } from '../user/user.model';
import { model as notificationsModel } from '../notifications/notifications.model';
import { model as requestsModel } from '../requests/requests.model';
import { model as modalsModel } from '../modals/modals.model';
import { model as errorsPointModel } from '../errorsPoint/errorsPoint.model';
import { model as statisticsPointModel } from '../statistics/statistics.model';
import { model as presetModel } from '../preset/preset.model';
import { model as eventModel } from '../event/event.model';
import { model as emailsModel } from '../emails/emails.model';
import { model as tooltipModel } from '../tooltip/tooltip.model';
import { model as richMediasModel } from '../richMedias/richMedias.model';
import { model as documentationMenuModel } from '../documentationMenu/documentationMenu.model';
import { model as commentsModel } from '../comments/comments.model';
import { model as sidebarsModel } from '../sidebars/sidebars.model';
import { model as assistantModel } from '../assistant/assistant.model';
import { model as arrowModel } from '../arrow/arrow.model';
import { model as restrictionsModel } from '../restrictions/restrictions.model';
import { model as changeLogModel } from '../changeLog/changeLog.model';

export const rootModel = {
  application: applicationModel,
  filter: filterModel,
  tag: TagModel,
  preset: presetModel,
  event: eventModel,
  user: userModel,
  pointEditMenu: pointEditMenuModel,
  points: pointsModel,
  canvas: canvasModel,
  journeys: journeysModel,
  popupMenu: popupMenuModel,
  sidebar: sidebarModel,
  notifications: notificationsModel,
  mode: modeModel,
  requests: requestsModel,
  modals: modalsModel,
  errorsPoint: errorsPointModel,
  statistics: statisticsPointModel,
  emails: emailsModel,
  tooltip: tooltipModel,
  richMedias: richMediasModel,
  documentationMenu: documentationMenuModel,
  comments: commentsModel,
  sidebars: sidebarsModel,
  assistant: assistantModel,
  arrow: arrowModel,
  restrictions: restrictionsModel,
  changeLog: changeLogModel
};
