import React from 'react';

// Components
import { NumberStepper } from '@pushwoosh/kit-number-stepper';

// Types
import { IFormCommonProps } from '@src/common/types/form';
import { EditPointSegmentValues } from '@src/ui/features/StartSegment/components/EditingPointSegment/EditPointSegment.types';

// Styled
import { H4 } from '@pushwoosh/kit-typography';
import { TimeFieldWrapper, TimeWrapper, TimeSelect } from './styled';

// Components
import { TimezoneSelect } from '../TimezoneSelect';

type IFormProps = Pick<
  IFormCommonProps<EditPointSegmentValues>,
  'names' | 'values' | 'setFieldValue'
>;

type IProps = IFormProps & {
  isDisabled: boolean;
};

export function TimeField(props: IProps): JSX.Element {
  const { names, values, setFieldValue, isDisabled } = props;

  const handleTimeChange = React.useCallback(
    (name: string, value: number): void => {
      setFieldValue(name, value);
    },
    [setFieldValue]
  );

  return (
    <TimeFieldWrapper>
      <TimeWrapper>
        <H4>Launch at</H4>
        <TimeSelect>
          <NumberStepper
            step={1}
            min={0}
            max={23}
            name={names.hour}
            value={values.hour}
            onChange={({ value }) => value > 0 && handleTimeChange(names.hour, value)}
            isDisabled={isDisabled}
          />
          <NumberStepper
            step={1}
            max={59}
            name={names.minute}
            value={values.minute}
            onChange={({ value }) => value >= 0 && handleTimeChange(names.minute, value)}
            isDisabled={isDisabled}
          />
        </TimeSelect>
      </TimeWrapper>
      <TimezoneSelect
        name={names.timezone}
        handleChange={setFieldValue}
        value={values.timezone}
        isDisabled={isDisabled}
      />
    </TimeFieldWrapper>
  );
}
