import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgFinishMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 6.25A.75.75 0 017 5.5h9.978a.5.5 0 01.394.807L14.74 9.693a.5.5 0 000 .614l2.633 3.386a.5.5 0 01-.394.807H7.75v3.753c.64.015 1.237.09 1.706.212.26.067.524.16.74.293.175.107.554.388.554.892s-.38.785-.554.892a2.76 2.76 0 01-.74.293c-.53.138-1.221.215-1.956.215-.735 0-1.426-.077-1.956-.215a2.76 2.76 0 01-.74-.293c-.175-.107-.554-.388-.554-.892s.38-.785.554-.892a2.76 2.76 0 01.74-.293c.212-.056.45-.101.706-.136V6.25zm-.677 13.797l.013-.01a.07.07 0 01-.013.01zm3.841-.01a.07.07 0 01.013.01.07.07 0 01-.013-.01zm0-.775a.07.07 0 01.013-.009l-.013.01zm-3.828 0a.07.07 0 01-.013-.009s.006.003.013.01z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgFinishMediumFilled;
