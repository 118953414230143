import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgFunnelMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.986 4.184a.75.75 0 01.68-.434h16.667a.75.75 0 01.573 1.234l-6.49 7.674V19.5a.75.75 0 01-1.085.67l-3.333-1.666a.75.75 0 01-.415-.67v-5.176L3.094 4.984a.75.75 0 01-.108-.8zM5.283 5.25l5.623 6.649a.75.75 0 01.177.484v4.987l1.834.916v-5.903a.75.75 0 01.177-.484l5.623-6.649H5.283z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgFunnelMediumLined;
