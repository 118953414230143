import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';

export const StatWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: ${Spacing.S3} 0;
`;
