import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgArrowRoundRightMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12a9 9 0 1118 0 9 9 0 01-18 0zm8.293 4.707a1 1 0 010-1.414L13.586 13H8a1 1 0 110-2h5.586l-2.293-2.293a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgArrowRoundRightMediumFilled;
