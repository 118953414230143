import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPlatformLogoAppleMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.828 2.708C14.118 1.008 15.91 1 15.91 1s.267 1.598-1.014 3.137c-1.368 1.644-2.923 1.375-2.923 1.375s-.292-1.293.855-2.804zm-.69 3.923c.663 0 1.894-.912 3.496-.912 2.76 0 3.844 1.963 3.844 1.963s-2.122 1.086-2.122 3.719c0 2.97 2.644 3.994 2.644 3.994s-1.848 5.203-4.345 5.203c-1.147 0-2.038-.773-3.246-.773-1.232 0-2.453.801-3.25.801C6.88 20.626 4 15.691 4 11.724c0-3.902 2.438-5.95 4.724-5.95 1.487 0 2.64.857 3.413.857z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPlatformLogoAppleMediumFilled;
