import React, { useEffect } from 'react';
import { createPopper } from '@popperjs/core';

// Hooks
import { useClickOutside } from '../../hooks/useClickOutside';

// Components
import { Portal } from '../Portal';

// Styled
import { ChildrenContainer, ArrowBottom, ArrowRight, ArrowTop, ArrowRightStart } from './styled';

import { IDropdownProps } from './Dropdown.types';

export function Dropdown(props: IDropdownProps): JSX.Element {
  const { dropdown, withArrow, children, styles, view = 'light' } = props;
  const { showDropdown, setShowDropdown, target, position } = dropdown;
  const menuRef = useClickOutside((): void => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  });

  const isShowBottomArrow =
    withArrow &&
    (position === 'bottom' || position === 'bottom-end' || position === 'bottom-start');

  const isShowTopArrow =
    withArrow && (position === 'top' || position === 'top-end' || position === 'top-start');

  const isShowRightArrow = withArrow && (position === 'right' || position === 'right-end');

  const isShowRightStart = withArrow && position === 'right-start';

  useEffect(() => {
    if (menuRef.current && target.current) {
      createPopper(target.current, menuRef.current, {
        placement: position,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 4]
            }
          }
        ]
      });
    }
  }, [menuRef, target, position, showDropdown]);

  return showDropdown ? (
    <Portal container={document.body}>
      <ChildrenContainer
        ref={menuRef as React.Ref<HTMLDivElement>}
        view={view}
        withBorder
        styles={styles}
      >
        {isShowBottomArrow && <ArrowBottom view={view} />}
        {isShowTopArrow && <ArrowTop view={view} position={position} />}
        {isShowRightArrow && <ArrowRight view={view} />}
        {isShowRightStart && <ArrowRightStart view={view} />}
        {children}
      </ChildrenContainer>
    </Portal>
  ) : null;
}
