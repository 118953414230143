import styled from 'styled-components';
import { FontSize, Color, Spacing } from '@pushwoosh/kit-constants';

export const StyledComment = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  flex-direction: row;
  user-select: none;
  border-radius: 8px;
  padding: ${Spacing.S3};

  &:hover {
    cursor: pointer;
    background-color: rgba(136, 158, 184, 0.1);
  }

  &:active {
    cursor: grab;
    background-color: rgba(136, 158, 184, 0.1);
    opacity: 0.5;
  }
`;

export const Name = styled.div`
  width: 100%;
  font-size: ${FontSize.REGULAR};
  color: ${Color.MAIN};
  margin-left: ${Spacing.S3};
`;
