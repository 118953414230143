import React from 'react';

// UI Components
import { ExtraOneButton, Modal, Section } from '@src/ui/kit';
import { ItemDetails, PointTitle } from '@src/ui/kit/DetailsPoint/styled';
import { DetailManualTags } from '@src/ui/features/SetTags/components/DetailManualTags';
import { DetailDynamicTags } from '@src/ui/features/SetTags/components/DetailDynamicTags';

// Helpers
import { getSeparateTags } from '@src/ui/features/SetTags/helpers/getSeparateTags';

// Types
import { UpdateTag } from '@src/common/types/tag';
import { ModalType } from '@src/common/constants/modals';
import { TModal } from '@src/common/types/modal';
import { useCloseModalByType } from '@src/store/hooks/modals/useCloseModalByType';
import { DropOffStat } from '../../DropOffStat';

interface IProps {
  tags: UpdateTag[];
  pointTitle: string;
  pointId: string;
}

export function DetailSetTags(props: IProps): JSX.Element {
  const { tags, pointTitle, pointId } = props;
  const { manualTagsList, dynamicTagsList } = getSeparateTags(tags);
  const closeModalByType = useCloseModalByType();

  const modalSettings = {
    type: ModalType.EDITING_FORM_POINT,
    data: {
      pointId
    }
  } as TModal;

  return (
    <Modal
      title={
        <>
          Update User Profile:&nbsp;
          <PointTitle>{pointTitle}</PointTitle>
        </>
      }
      footerRight={
        <ExtraOneButton
          actionButtonName="Close"
          onClickActionButton={() => closeModalByType(ModalType.DETAIL_POINT)}
        />
      }
      isOpen
    >
      <Section direction="column">
        <ItemDetails>
          <DropOffStat pointId={pointId} modal={modalSettings} />
        </ItemDetails>
      </Section>
      {manualTagsList.length > 0 && (
        <Section direction="column">
          <ItemDetails>
            <DetailManualTags tags={manualTagsList} />
          </ItemDetails>
        </Section>
      )}

      {dynamicTagsList.length > 0 && (
        <Section direction="column">
          <DetailDynamicTags tags={dynamicTagsList} />
        </Section>
      )}
    </Modal>
  );
}
