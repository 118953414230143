// Services
import {
  applicationService,
  filterService,
  presetService,
  journeysService,
  eventService,
  statisticsService,
  emailService,
  richMediaService,
  tagService,
  restrictionsService
} from '@src/store/api/services';

// Types
import { TInjections } from '@src/store/types';

export const injections: TInjections = {
  applicationService,
  filterService,
  presetService,
  journeysService,
  eventService,
  statisticsService,
  emailService,
  richMediaService,
  tagService,
  restrictionsService
};
