import styled from 'styled-components';
import { Color, FontSize, ShapeRadius, Spacing } from '@pushwoosh/kit-constants';

export const FooterWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  min-width: 182px;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spacing.S7};
  border-bottom: 1px solid ${Color.DIVIDER};
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;

  :not(:last-child) {
    margin-bottom: ${Spacing.S5};
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SuccessWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: ${Spacing.S7};
  border-radius: ${ShapeRadius.CONTROL};

  & img {
    width: 120px;
    height: 120px;
  }
`;

export const CloseIcon = styled.div`
  cursor: pointer;
`;

export const StyledParagraph = styled.p`
  font-size: ${FontSize.REGULAR};
  display: flex;
  width: 188px;
  margin-right: ${Spacing.S7};
`;
