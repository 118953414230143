import * as React from 'react';

// Store Hooks
import { useCurrentJourneyId, useDeactivateJourneyById } from '@src/store/hooks';

// Features Hooks
import { useConfirmService } from '@src/ui/features/Modals/Confirm/hooks/useConfirmService';

// Неизвестно для чего тут title
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useConfirmDeactivateJourney(title?: string): () => void {
  const journeyId = useCurrentJourneyId();
  const [deactivateJourneyById, isRequestDeactivateJourneyById] = useDeactivateJourneyById();
  const confirm = useConfirmService();

  const isLoadingConfirm = isRequestDeactivateJourneyById;

  const confirmDeactivateJourneyHandler = React.useCallback((): void => {
    confirm({
      description:
        'Journey will be deactivated. All users that are currently in the journey will be removed from it instantly. You won’t be able to reactivate this journey.',
      title: 'Deactivate Journey',
      confirmLabel: 'Deactivate',
      level: 'danger',
      onAfterConfirm(): void {
        deactivateJourneyById(journeyId);
      },
      isLoadingConfirm
    });
  }, [confirm, deactivateJourneyById, journeyId, isLoadingConfirm]);

  return confirmDeactivateJourneyHandler;
}
