import styled from 'styled-components';
import { Paragraph, H4 } from '@pushwoosh/kit-typography';
import { Spacing } from '@pushwoosh/kit-constants';
import { FormInput } from '@src/ui/form';

export const Description = styled(Paragraph)`
  margin-bottom: ${Spacing.S5};
`;

export const StyledInput = styled(FormInput)`
  padding-bottom: 0;
`;

export const DetailBlock = styled.div`
  margin-bottom: ${Spacing.S5};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const DetailTitle = styled(H4)`
  margin-bottom: ${Spacing.S1};
`;

export const TagWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const Row = styled.div`
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: ${Spacing.S5};
`;
