import styled from 'styled-components';
import { Spacing, FontSize, Color } from '@pushwoosh/kit-constants';
import { PointType, CHANNEL_GROUP, FLOW_GROUP, ENTRY_GROUP } from '@src/common/constants/point';
import { Button } from '@src/ui/kit';

const getPointBackground = (type: PointType): string => {
  if (CHANNEL_GROUP.has(type)) {
    return 'rgba(222, 155, 255, 0.14);';
  }

  if (FLOW_GROUP.has(type)) {
    return 'rgba(153, 201, 255, 0.14);';
  }

  if (ENTRY_GROUP.has(type)) {
    return 'rgba(100, 219, 44, 0.14);';
  }

  return Color.CLEAR;
};

type StyledPointProps = {
  isDisabled: boolean;
  type: PointType;
};

export const StyledPoint = styled.div<StyledPointProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  height: 40px;
  font-size: ${FontSize.REGULAR};
  border-radius: 8px;
  padding-left: ${Spacing.S3};
  padding-right: ${Spacing.S4};

  &:hover {
    background-color: ${({ type }: StyledPointProps) => getPointBackground(type)};
    cursor: pointer;
  }

  &:active {
    background-color: ${({ type }: StyledPointProps) => getPointBackground(type)};
    cursor: grabbing;
    opacity: 0.5;
  }
`;

export const StyledBody = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const Name = styled.div`
  font-size: ${FontSize.REGULAR};
  color: ${Color.MAIN};
  margin-left: ${Spacing.S3};
`;

export const LockIcon = styled.div``;

export const StyledButton = styled(Button)`
  width: 100%;
  justify-content: center;
`;
