import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';

export const JourneyPage = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const Viewport = styled.div`
  position: relative;
  display: flex;
  z-index: 1;
  min-height: 100%;
  overflow: hidden;
`;

export const Edit = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
`;

export const CanvasWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ebedf0;
`;

const LeftPanel = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: ${Spacing.S5};
`;

type RightPanelProps = {
  isVisibleSidebar: boolean;
};

export const RightPanel = styled(LeftPanel)<RightPanelProps>`
  right: ${({ isVisibleSidebar }) => (isVisibleSidebar ? '316px' : '0')};
  width: fit-content;
  margin-left: auto;
`;
