import React from 'react';
import { Button, IconEnum } from '@src/ui/kit';
import { Tag } from '@pushwoosh/kit-constants';
import { useParams } from 'react-router-dom';
import {
  useAutoSaveById,
  useCurrentJourneyId,
  useGetApplicationByCode,
  useGetJourneyById,
  useLoadApplications,
  useUnsavedChanges
} from '@src/store/hooks';
import { DropdownMenu, DropdownMenuItem } from '@pushwoosh/kit-dropdown-menu';
import { KebabIcon } from '@pushwoosh/kit-icons';
import { useJourneyHandlers } from '@src/ui/features/JourneyList/components/JorneyControls/hooks/useJourneyHandlers';
import { JourneyStatus } from '@src/common/constants';
import { useStoreActions } from '@src/store/store';
import {
  useConfirmActivateJourney,
  useConfirmDeactivateJourney
} from '@src/ui/features/Modals/Confirm';
import { useHistory } from '@src/common/contexts/history';
import {
  BackwardLink,
  HeaderWrapper,
  DeleteJourneyButton,
  StyledDropdownMenuWindow,
  Title,
  Wrapper,
  StyledSavedButton,
  Subtitle,
  TitleWrapper
} from './styled';

export function JourneyHeader() {
  const { applicationCode } = useParams<{ applicationCode: string }>();
  const journeyId = useCurrentJourneyId();
  const getJourneyById = useGetJourneyById();
  const journey = getJourneyById(journeyId);
  const getApplicationByCode = useGetApplicationByCode();
  const [loadApplications] = useLoadApplications();
  const { confirmActivateJourneyHandler } = useConfirmActivateJourney();
  const confirmDeactivateJourney = useConfirmDeactivateJourney();
  const validateById = useStoreActions((actions) => actions.journeys.validateById);
  const isUnsavedChanges = useUnsavedChanges();
  const autoSaveById = useAutoSaveById();
  const history = useHistory();

  const [applicationName, setApplicationName] = React.useState('');

  const { status, title } = journey;

  const {
    handleButtonCloneClick,
    handleButtonDeleteClick,
    handleButtonRenameClick,
    handleButtonPauseClick
  } = useJourneyHandlers({
    status,
    id: journeyId,
    title,
    beforeLoadJourney: false,
    afterDeleteJourney: () => history.push('/journeys/list')
  });

  React.useEffect(() => {
    if (journey?.id) {
      const application = journey && getApplicationByCode(journey.params.applicationCode);
      if (!application) {
        loadApplications();
      } else {
        setApplicationName(application.title);
      }
    }
  }, [loadApplications, getApplicationByCode, journey]);

  return (
    <Wrapper>
      <HeaderWrapper>
        <BackwardLink to={applicationCode ? `/journeys/${applicationCode}/list` : '/journeys/list'}>
          <Button iconType={IconEnum.ARROW_ROUND_LEFT_MEDIUM_FILLED} color="primary" view="ghost" />
        </BackwardLink>
        <TitleWrapper>
          <Title onClick={handleButtonRenameClick}>{journey.title}</Title>
          {applicationName !== '' && <Subtitle>{applicationName}</Subtitle>}
        </TitleWrapper>
      </HeaderWrapper>
      <DropdownMenu
        windowZIndex={999}
        icon={<KebabIcon size="medium" />}
        view="ghost"
        color="secondary"
      >
        <StyledDropdownMenuWindow>
          {isUnsavedChanges ? (
            <DropdownMenuItem
              as={Tag.BUTTON}
              color="main"
              type="button"
              onClick={() => autoSaveById(journeyId)}
            >
              Save
            </DropdownMenuItem>
          ) : (
            <StyledSavedButton>Campaign is saved</StyledSavedButton>
          )}
          <DropdownMenuItem
            as={Tag.BUTTON}
            color="main"
            type="button"
            onClick={handleButtonRenameClick}
          >
            Rename...
          </DropdownMenuItem>
          {status === JourneyStatus.RUNNING && (
            <DropdownMenuItem
              as={Tag.BUTTON}
              color="main"
              type="button"
              onClick={handleButtonPauseClick}
            >
              Pause and edit campaign…
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            as={Tag.BUTTON}
            color="main"
            type="button"
            onClick={handleButtonCloneClick}
          >
            Clone...
          </DropdownMenuItem>

          {status === JourneyStatus.RUNNING && (
            <DropdownMenuItem
              as={Tag.BUTTON}
              color="main"
              type="button"
              onClick={confirmDeactivateJourney}
            >
              Stop...
            </DropdownMenuItem>
          )}

          {status === JourneyStatus.DRAFT && (
            <>
              <DropdownMenuItem
                as={Tag.BUTTON}
                color="main"
                type="button"
                onClick={() => validateById({ journeyId })}
              >
                Validate
              </DropdownMenuItem>
              <DropdownMenuItem
                as={Tag.BUTTON}
                color="main"
                type="button"
                onClick={() => confirmActivateJourneyHandler()}
              >
                Launch...
              </DropdownMenuItem>
            </>
          )}

          {status === JourneyStatus.PAUSED && (
            <>
              <DropdownMenuItem
                as={Tag.BUTTON}
                color="main"
                type="button"
                onClick={() => validateById({ journeyId })}
              >
                Validate
              </DropdownMenuItem>
              <DropdownMenuItem
                as={Tag.BUTTON}
                color="main"
                type="button"
                onClick={() => confirmActivateJourneyHandler()}
              >
                Re-launch campaign...
              </DropdownMenuItem>
            </>
          )}

          {status === JourneyStatus.DRAFT && (
            <DeleteJourneyButton onClick={handleButtonDeleteClick}>Delete...</DeleteJourneyButton>
          )}
        </StyledDropdownMenuWindow>
      </DropdownMenu>
    </Wrapper>
  );
}
