import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgProfileMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 15c0-1.368 1.856-2 3-2s3 .632 3 2a.5.5 0 01-.5.5h-5A.5.5 0 015 15z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 10.5a2 2 0 11-4 0 2 2 0 014 0zm-1.5 0a.5.5 0 11-1 0 .5.5 0 011 0z"
        fill={props.fill}
      />
      <path
        d="M12.25 10a.75.75 0 01.75-.75h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75zM13 11.25a.75.75 0 000 1.5h2a.75.75 0 000-1.5h-2z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 7.5A2.25 2.25 0 014.5 5.25h15a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25v-9zm2.25-.75a.75.75 0 00-.75.75v9c0 .414.336.75.75.75h15a.75.75 0 00.75-.75v-9a.75.75 0 00-.75-.75h-15z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgProfileMediumLined;
