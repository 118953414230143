import React from 'react';
import { useStoreState } from '@src/store/store';
import { useOpenNewModal, useCloseAllModals } from '@src/store/hooks';
import { DropOffsType } from '@src/common/constants/stats';
import { TModal } from '@src/common/types/modal';

import { getFormattedDropOffs } from '../helpers/getFormattedDropOffs';

export function useDropOffs(pointId: string, modal: TModal) {
  const getPointDropOffs = useStoreState((state) => state.statistics.getPointDropOffs);
  const closeAllModals = useCloseAllModals();
  const openNewModal = useOpenNewModal();
  const dropOffs = getPointDropOffs(pointId);
  const allDropOffs = dropOffs.reduce((prevValue, dropOff) => prevValue + dropOff.value, 0);
  let formattedDropOffs = getFormattedDropOffs(dropOffs);

  const openEditModal = React.useCallback(() => {
    closeAllModals();

    openNewModal(modal);
  }, [closeAllModals, openNewModal, modal]);

  formattedDropOffs = formattedDropOffs.map((dropOff) => {
    if (dropOff.type === DropOffsType.DeviceNotFound) {
      return {
        ...dropOff,
        description:
          'Devices that failed to register or unsubscribed while being in the journey. Make sure all the target devices are available.'
      };
    }

    if (dropOff.type === DropOffsType.PresetNotFound) {
      return {
        ...dropOff,
        actionText: 'Edit preset',
        onActionClick: openEditModal
      };
    }

    return dropOff;
  });

  return {
    allDropOffs,
    dropOffs,
    formattedDropOffs
  };
}
