import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSearchSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.581 10.642a4.194 4.194 0 111.06-1.06.49.49 0 01.017.016l2.519 2.518a.5.5 0 010 .707l-.354.354a.5.5 0 01-.707 0l-2.519-2.519a.622.622 0 01-.016-.016zm.306-3.448a2.694 2.694 0 11-5.387 0 2.694 2.694 0 015.387 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgSearchSmallLined;
