import React from 'react';
import { type SegmentData, type ConditionsGroup } from '@pushwoosh/grpc-bridge';
import { Modal, ModalBody } from '@pushwoosh/kit-modal';
import { Vertical } from '@pushwoosh/kit-helpers';
import { PageHeaderEditable } from '@pushwoosh/kit-page-header';
import { sendMixpanelEvent } from '@src/common/analytics';
import { SegmentParcel } from '../components/SegmentParcel';

interface IProps {
  applicationCode: string;
  open: boolean;
  onClose: () => void;
  onSaved: (state: SegmentData) => void;
}

export function SegmentBuilder(props: IProps): JSX.Element {
  const { applicationCode, open, onClose, onSaved } = props;

  return (
    <Modal size="full" isOpen={open} isSide onClose={onClose}>
      <ModalBody>
        <Vertical padding="16px 0 0 0">
          <SegmentParcel
            applicationCode={applicationCode}
            onSaved={(state) => {
              sendMixpanelEvent({
                eventName: 'FilterSave',
                eventData: {
                  operator: (state.condition as ConditionsGroup).operator
                }
              });

              onSaved(state);
            }}
            onEvent={(type) => {
              let name;
              if (type === 'addEvent') {
                name = 'SegmentBuilder:AddEventConditionClicked';
              }
              if (type === 'addGroup') {
                name = 'SegmentBuilder:AddGroupClicked';
              }
              if (name) {
                sendMixpanelEvent({ eventName: name });
              }
            }}
            render={(props) => {
              const { state, onChangeName, groups, error, onSubmit, submitting, renderHelp } =
                props;
              return (
                <Vertical gap={24}>
                  <PageHeaderEditable
                    isLoadingSubmit={submitting}
                    title={'Create\u00A0segment:'}
                    placeholder="Enter segment name"
                    submitText="Save segment"
                    error={error}
                    value={state.name}
                    onChange={onChangeName}
                    onSubmit={onSubmit}
                    onBack={onClose}
                  />
                  {groups}
                  {renderHelp()}
                </Vertical>
              );
            }}
          />
        </Vertical>
      </ModalBody>
    </Modal>
  );
}
