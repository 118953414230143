/* eslint-disable react/no-unescaped-entities */
import React from 'react';

// UI Components
import {
  ExtraOneButton,
  Modal,
  Section,
  SeparatorOr,
  SplitedList,
  FormField,
  Message,
  Button
} from '@src/ui/kit';
import { FormBlock } from '@src/ui/form/styled';
import { Footnote, Paragraph } from '@pushwoosh/kit-typography';
import { Color } from '@pushwoosh/kit-constants';
import { ItemDetails, PointTitle } from '@src/ui/kit/DetailsPoint/styled';
import { DropOffStat } from '@src/ui/features/DropOffStat';
import { decodeDelay, formatTime } from '@src/common/helpers';
import { useLoadEvents, useGetEventsByApplicationCode } from '@src/store/hooks';

// Types
import { TWaitEvent } from '@src/common/types/points';
import { ModalType } from '@src/common/constants/modals';
import { TModal } from '@src/common/types/modal';
import { useCloseModalByType } from '@src/store/hooks/modals/useCloseModalByType';
import { BranchType } from '@src/common/types/branch';
import {
  Container,
  Content,
  Header,
  HeaderBranchKey,
  HeaderTitle
} from '@src/ui/features/WaitEvent/components/DetailedBranch/styled';
import { CollapseIcon, ExpandIcon } from '@pushwoosh/kit-icons';
import { ButtonsWrap } from '@src/ui/features/WaitEvent/containers/styled';
import { DetailedBranch } from '../components/DetailedBranch';

type TExtendedWaitEvent = TWaitEvent & { applicationName: string; id: string };

interface IProps {
  waitEvents: TExtendedWaitEvent[];
  pointTitle: string;
  pointId: string;
  isChanged?: boolean;
  applicationCode: string;
  branches: BranchType[];
}

export function DetailWaitEvent(props: IProps): JSX.Element {
  const { waitEvents, pointTitle, pointId, isChanged, applicationCode, branches } = props;
  const [isChangedLocal, setChanged] = React.useState(isChanged);
  const [loadEventsByAppCode] = useLoadEvents();
  const getEventsByApplicationCode = useGetEventsByApplicationCode();
  const events = getEventsByApplicationCode(applicationCode);

  const [opens, setOpens] = React.useState<boolean[]>([]);

  React.useEffect(() => {
    if (events.length === 0 && applicationCode) {
      loadEventsByAppCode(applicationCode);
    }
  }, [applicationCode, events.length, loadEventsByAppCode]);

  const closeModalByType = useCloseModalByType();

  const modalSettings = {
    type: ModalType.EDITING_FORM_POINT,
    data: {
      pointId
    }
  } as TModal;

  const handleCollapseAll = React.useCallback(() => {
    const newOpens = branches.map(() => false);
    setOpens(newOpens);
  }, [branches, setOpens]);

  const handleExpandAll = React.useCallback(() => {
    const newOpens = branches.map(() => true);
    setOpens(newOpens);
  }, [branches, setOpens]);

  const handleToggleOpen = React.useCallback(
    (index: number) => {
      const newOpens = [...opens];
      newOpens[index] = !newOpens[index];
      setOpens(newOpens);
    },
    [opens, setOpens]
  );

  const allCollapsed = opens.every((b) => !b);

  return (
    <Modal
      title={
        <>
          Wait for Trigger:&nbsp;
          <PointTitle>{pointTitle}</PointTitle>
        </>
      }
      footerLeft={
        <ButtonsWrap>
          {allCollapsed ? (
            <Button color="primary" size="field" view="ghost" onClick={handleExpandAll}>
              <ExpandIcon size="small" view="lined" />
              &nbsp;&nbsp;Expand all
            </Button>
          ) : (
            <Button color="primary" size="field" view="ghost" onClick={handleCollapseAll}>
              <CollapseIcon size="small" view="lined" />
              &nbsp;&nbsp;Collapse all
            </Button>
          )}
        </ButtonsWrap>
      }
      footerRight={
        <ExtraOneButton
          actionButtonName="Close"
          onClickActionButton={() => closeModalByType(ModalType.DETAIL_POINT)}
        />
      }
      isOpen
    >
      <Section direction="column">
        <ItemDetails>
          <DropOffStat pointId={pointId} modal={modalSettings} />
        </ItemDetails>
      </Section>
      <Section direction="column">
        <FormField label="Waiting period:" value={formatTime(decodeDelay(waitEvents[0].delay))} />
        <ItemDetails>
          <Footnote style={{ color: Color.LOCKED }}>
            Once the specified time has passed, users who have not committed any of the specified
            events are routed down the "Not triggered" path.
          </Footnote>
        </ItemDetails>
      </Section>
      <Section>
        <SplitedList direction="vertical" renderSeparator={SeparatorOr}>
          {branches?.map((branch, index) => (
            <DetailedBranch
              index={index}
              open={!!opens[index]}
              toggleOpen={handleToggleOpen}
              title={branch.title || `Trigger group ${index + 1}`}
              waitEvents={branch.events as unknown as TExtendedWaitEvent[]}
            />
          ))}

          <Container>
            <Header kind="secondary">
              <HeaderTitle>
                <HeaderBranchKey>{'Branch "Not triggered"'}</HeaderBranchKey>
              </HeaderTitle>
            </Header>
            <Content>
              <Paragraph>
                Users who have not triggered specified events or met the conditions within the
                waiting period will be directed to the “Not triggered” branch.
              </Paragraph>
            </Content>
          </Container>
        </SplitedList>
        {isChangedLocal && (
          <FormBlock>
            <Message
              color={Color.WARNING_LIGHT}
              withClosing
              onClose={() => setChanged(false)}
              title="Other steps might be affected"
              message="Make sure you to change settings for Update User Profile and Webhooks, in case Events from this step are used there"
            />
          </FormBlock>
        )}
      </Section>
    </Modal>
  );
}
