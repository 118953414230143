import * as React from 'react';
import { Text } from 'react-konva';

// Helpers
import { getSizePoint, getSizeIconPoint } from '@src/common/helpers';

// Constants
import { DEFAULT_POINT_BLOCK_OFFSET } from '@src/common/constants/point';

// Types
import { IProps } from './PointText.types';

const ONE_ROW_Y_POSITION = 14;
const TWO_ROWS_Y_POSITION = 7;
const THREE_ROWS_Y_POSITION = 5;
const TWO_ROWS = 2;
const THREE_ROWS = 3;
const THREE_ROWS_HEIGHT = 28;

export function PointText(props: IProps): JSX.Element {
  const { type, order, children, fill = '#555555' } = props;
  const textRef = React.useRef(null);
  const textHeight = textRef.current?.height();
  const [yCord, setYCord] = React.useState(ONE_ROW_Y_POSITION);

  React.useEffect(() => {
    if (textRef.current) {
      const { textArr } = textRef.current;

      if (textArr.length === TWO_ROWS) {
        setYCord(TWO_ROWS_Y_POSITION);
      } else if (textArr.length >= THREE_ROWS) {
        setYCord(THREE_ROWS_Y_POSITION);
      } else {
        setYCord(ONE_ROW_Y_POSITION);
      }

      if (textArr.length > THREE_ROWS) {
        textArr[2].text = `${textArr[2].text.slice(0, textArr[2].text.length - 3)}...`;
        textRef.current.textArr = textArr.slice(0, THREE_ROWS);
      }
    }
  }, [children, textHeight, yCord]);

  const { widthPoint } = getSizePoint(type);
  const { widthIcon } = getSizeIconPoint(type);

  const widthText = widthPoint - DEFAULT_POINT_BLOCK_OFFSET * 3 - widthIcon;
  const xCord = DEFAULT_POINT_BLOCK_OFFSET + widthIcon;

  return (
    <Text
      width={widthText}
      ref={textRef}
      perfectDrawEnabled={false}
      shadowForStrokeEnabled={false}
      x={xCord}
      y={typeof order === 'number' ? 5 : yCord}
      fontFamily="Roboto"
      fontSize={14}
      fontStyle="500"
      text={children as string}
      fill={fill}
      wrap={typeof order === 'number' ? 'none' : 'word'}
      ellipsis
      scaleX={textHeight > THREE_ROWS_HEIGHT ? 0.8 : 1}
      scaleY={textHeight > THREE_ROWS_HEIGHT ? 0.8 : 1}
    />
  );
}
