import * as React from 'react';

// Analytics
import { sendMixpanelEvent } from '@src/common/analytics';

// Store Hooks
import {
  useActivateJourneyById,
  useCurrentJourneyId,
  useIsSetUpGoal,
  usePoints,
  useGetJourneyById
} from '@src/store/hooks';
import { isConfiguredPoint } from '@src/common/helpers/isConfiguredPoint';
import { PointType } from '@src/common/constants/point';

// Features Hooks
import { useConfirmService } from '@src/ui/features/Modals/Confirm/hooks/useConfirmService';

export function useConfirmActivateJourney() {
  const journeyId = useCurrentJourneyId();
  const confirm = useConfirmService();
  const isSetUpGoal = useIsSetUpGoal();
  const [activateJourneyById, isRequestActivateJourneyById] = useActivateJourneyById();
  const points = usePoints();
  const configuredPoints = points.filter((point) => isConfiguredPoint(point));
  const getJourneyById = useGetJourneyById();
  const journey = getJourneyById(journeyId);
  const isSilentHoursEnabled =
    journey?.params?.silentHours?.emailParams?.enabled ||
    journey?.params?.silentHours?.inAppParams?.enabled ||
    journey?.params?.silentHours?.pushParams?.enabled ||
    journey?.params?.silentHours?.smsParams?.enabled;

  const isFrequencyCappingEnabled = journey?.params?.frequencyCapping?.isEnabled;
  const isConversionGoalsEnabled = journey?.points?.find(
    (point) => point.type === PointType.GOAL_EVENT
  );
  const isLoadingConfirm = isRequestActivateJourneyById;

  const confirmActivateJourneyHandler = React.useCallback((): void => {
    sendMixpanelEvent({
      eventName: 'ActivateJourney',
      eventData: {
        ConfiguredPoints: configuredPoints.length,
        IsSilentHoursEnabled: isSilentHoursEnabled,
        IsFrequencyCappingEnabled: isFrequencyCappingEnabled,
        IsConversionGoalsEnabled: isConversionGoalsEnabled
      }
    });
    confirm({
      title: 'Launch campaign',
      confirmLabel: isSetUpGoal ? 'Launch' : 'Launch anyway',
      cancelLabel: 'Back to edit',
      onBeforeCancel(): void {
        sendMixpanelEvent({ eventName: 'Canvas:DialogLaunch:BackToEdit' });
      },
      warningMessage: isSetUpGoal
        ? undefined
        : 'To make the most of this campaign, we recommend specify your conversion goals',
      description:
        'After launching your campaign, content changes are allowed but adding new steps or deleting existing ones is not possible.',
      onAfterConfirm(): void {
        activateJourneyById(journeyId);
      },
      isLoadingConfirm
    });
  }, [
    confirm,
    activateJourneyById,
    journeyId,
    isLoadingConfirm,
    configuredPoints.length,
    isConversionGoalsEnabled,
    isFrequencyCappingEnabled,
    isSilentHoursEnabled,
    isSetUpGoal
  ]);

  return { confirmActivateJourneyHandler, isLoadingConfirm };
}
