import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgArrowRoundRightSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.859 8.535L8.53 10.864a.75.75 0 01-1.06-1.06L8.523 8.75H5.667a.75.75 0 110-1.5h2.856L7.47 6.197a.75.75 0 111.06-1.06l2.334 2.333a.748.748 0 01.22.523V8a.746.746 0 01-.225.535z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.417a6.583 6.583 0 110 13.166A6.583 6.583 0 018 1.417zM13.083 8A5.083 5.083 0 102.917 8a5.083 5.083 0 0010.166 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgArrowRoundRightSmallLined;
