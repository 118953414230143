import React from 'react';

// Store Hooks
import {
  useGetPointById,
  useUpdatePoint,
  useCloseAllModals,
  useOpenNewModal
} from '@src/store/hooks';

// Types
import { IUniqueId } from '@src/common/types/entities';
import { ModalType } from '@src/common/constants/modals';

// UI Hooks
import { useForm, useSaveJourney } from '@src/ui/hooks';

// UI Components
import { Modal } from '@src/ui/kit/Modal';
import { Button } from '@src/ui/kit/Button';
import { IconEnum } from '@src/ui/kit/Icon';
import { Loading } from '@src/ui/kit/Loading';
import { Section } from '@src/ui/kit';
import {
  DynamicValues,
  ContainerDynamicValues,
  TypeData
} from '@src/ui/features/Webhook/components/DynamicValues';

// Types
import { TWebhookPoint } from '@src/common/types/points';
import { IWebhookFormValues } from '../Webhook.types';

// Helpers
import { getHeadersList } from '../helpers/getHeadersList';
import { validate } from '../validation';

// Lazy load components
const JSONEditor = React.lazy(
  () => import('@src/ui/features/Webhook/components/JSONEditor/JSONEditor')
);

interface IProps {
  pointId: IUniqueId['id'];
  requestTemplate?: string;
}

const names = {
  url: 'url',
  headers: 'headers',
  dynamicValues: 'dynamicValues',
  requestTemplate: 'requestTemplate',
  title: 'title'
};

export function FullScreenEditor(props: IProps): JSX.Element {
  const { pointId, requestTemplate = '' } = props;
  const getPointById = useGetPointById();
  const updatePoint = useUpdatePoint();
  const closeAllModals = useCloseAllModals();
  const isNotSavedJourney = useSaveJourney();
  const openNewModal = useOpenNewModal();
  const point = getPointById(pointId) as TWebhookPoint;
  const headerList = getHeadersList(point?.data?.headers ?? {});
  const initialValues = React.useMemo(
    () => ({
      title: point.title,
      url: point?.data?.url ?? '',
      headers: headerList ?? [],
      dynamicValues: {
        type: TypeData.DEVICE
      },
      requestTemplate
    }),
    [point, headerList, requestTemplate]
  );

  const { values, errors, setFieldValue, handleBlur, handleSubmit, setFieldError } =
    useForm<IWebhookFormValues>({
      initialValues,
      validate,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSubmit: () => {},
      validateOnBlur: true,
      validateOnChange: false
    });

  const backToDialog = React.useCallback(() => {
    const changePoint = {
      id: pointId,
      data: {
        url: point.data.url,
        headers: point.data.headers,
        method: 'POST',
        request_template: ''
      }
    };

    if (values.requestTemplate) {
      changePoint.data.request_template = values.requestTemplate;
    }

    updatePoint(changePoint);
    closeAllModals();

    openNewModal({
      type: ModalType.EDITING_FORM_POINT,
      data: {
        pointId
      }
    });
  }, [
    closeAllModals,
    openNewModal,
    point.data.headers,
    point.data.url,
    pointId,
    updatePoint,
    values.requestTemplate
  ]);

  return (
    <Modal
      title="Webhook"
      isOpen
      isFullScreen
      footerLeft={
        <Button
          color="primary"
          view="ghost"
          iconPosition="left"
          iconType={IconEnum.ARROW_LEFT_MEDIUM}
          onClick={backToDialog}
        >
          Back to Dialog
        </Button>
      }
    >
      <Section direction="column">
        <React.Suspense fallback={<Loading size="medium" />}>
          <ContainerDynamicValues>
            <JSONEditor
              value={values.requestTemplate}
              name="requestTemplate"
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              pointId={pointId as IUniqueId['id']}
              onSubmit={handleSubmit}
            />
            <DynamicValues
              values={values}
              errors={errors}
              names={names}
              isNotSavedJourney={isNotSavedJourney}
              setFieldValue={setFieldValue}
              onBlurField={handleBlur}
            />
          </ContainerDynamicValues>
        </React.Suspense>
      </Section>
    </Modal>
  );
}
