import styled from 'styled-components';
import { Paragraph } from '@pushwoosh/kit-typography';
import { Color, Spacing, FontSize, ShapeRadius } from '@pushwoosh/kit-constants';

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${Spacing.S3};
  border-radius: ${ShapeRadius.CONTROL};
`;

export const Label = styled(Paragraph)`
  color: ${Color.MAIN};
  margin-right: ${Spacing.S3};
`;

export const Value = styled.div`
  padding: ${Spacing.S0} ${Spacing.S3};
  background-color: ${Color.SOFT_LIGHT};
  color: ${Color.MAIN};
  font-size: ${FontSize.REGULAR};
  border-radius: inherit;
  display: flex;
  align-items: center;

  & > p {
    margin-top: 0 !important;
  }
`;
