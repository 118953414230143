import styled from 'styled-components';
import { Color, Spacing } from '@pushwoosh/kit-constants';
import { Button } from '@src/ui/kit';

export const StyledStatBar = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0;
  padding-bottom: ${Spacing.S13};
  border-radius: 8px;
  overflow: auto;
`;

export const BottomItem = styled.div`
  width: 280px;
  height: 56px;
  background-color: ${Color.CLEAR};
  position: fixed;
  bottom: ${Spacing.S5};
  border-top: 1px solid #ebf0f5;
  padding: 0 ${Spacing.S5};
  border-radius: 0 0 8px 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  width: 280px;
`;
