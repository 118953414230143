import styled from 'styled-components';
import { Spacing, Color, ShapeRadius } from '@pushwoosh/kit-constants';
import { Footnote } from '@pushwoosh/kit-typography';

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  padding: ${Spacing.S3};
  background-color: ${Color.BRIGHT_LIGHT};
  margin-top: ${Spacing.S3};
  border-radius: ${ShapeRadius.CONTROL};
`;

export const Description = styled(Footnote)`
  margin-left: ${Spacing.S3};
`;
