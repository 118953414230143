import React from 'react';

// Hooks
import { useStoreActions, useStoreState } from '@src/store/store';
import { useRemoveComment, useUpdateComment, useModeMap } from '@src/store/hooks';

// UI Components
import { RightDrawerSidebar, Checkbox } from '@src/ui/kit';
import { Comment } from '../components/Comment';
import { DeletedComment } from '../components/DeletedComment';

// Styled
import { CommentsWrap, EmptyCommentsList, ShowDeletedCheckbox, ShowDeletedTitle } from '../styled';

export function CommentsPanel(): JSX.Element {
  const comments = useStoreState((state) => state.comments.comments);
  const deletedComments = useStoreState((state) => state.comments.deletedComments);
  const isVisibleDeletedComments = useStoreState(
    (state) => state.comments.isVisibleDeletedComments
  );
  const hideSidepanel = useStoreActions((actions) => actions.sidebars.closeAllSidebars);
  const setCenteredComment = useStoreActions((actions) => actions.comments.setCenteredComment);
  const toggleDeletedComments = useStoreActions(
    (actions) => actions.comments.toggleDeletedComments
  );
  const handleCommentRemove = useRemoveComment();
  const handleUpdateComment = useUpdateComment();
  const handleRestoreComment = useStoreActions((actions) => actions.comments.restore);
  const modeMap = useModeMap();

  const handleClose = React.useCallback((): void => {
    hideSidepanel();
  }, [hideSidepanel]);

  const handleSetCenteredComment = React.useCallback(
    (commentId: string) => {
      setCenteredComment(commentId);
    },
    [setCenteredComment]
  );

  return (
    <RightDrawerSidebar
      title="Notes"
      isBack={false}
      isOpen
      onClose={handleClose}
      renderFooter={() => (
        <ShowDeletedCheckbox>
          <Checkbox
            name="showDeleted"
            value={isVisibleDeletedComments}
            onChange={toggleDeletedComments as unknown as () => void}
          />
          <ShowDeletedTitle>Show Deleted</ShowDeletedTitle>
        </ShowDeletedCheckbox>
      )}
    >
      <CommentsWrap>
        {!isVisibleDeletedComments && comments.length === 0 && (
          <EmptyCommentsList>
            To add a note, drag-and-drop the “Add notes” icon from the left panel to the canvas.
          </EmptyCommentsList>
        )}
        {isVisibleDeletedComments && comments.length === 0 && deletedComments.length === 0 && (
          <EmptyCommentsList>
            To add a note, drag-and-drop the “Add notes” icon from the left panel to the canvas.
          </EmptyCommentsList>
        )}
        {comments.map((comment) => (
          <Comment
            key={comment.id}
            order={comment.title}
            date={comment.createdAt}
            message={comment.message}
            onCommentClick={handleSetCenteredComment}
            commentId={comment.id}
            position={comment.position}
            updateComment={handleUpdateComment}
            onCommentRemove={handleCommentRemove}
            mode={modeMap}
          />
        ))}
        {isVisibleDeletedComments &&
          deletedComments.map((comment) => (
            <DeletedComment
              key={comment.id}
              commentId={comment.id}
              order={comment.title}
              date={comment.createdAt}
              message={comment.message}
              onRestore={handleRestoreComment}
              onClick={handleSetCenteredComment}
            />
          ))}
      </CommentsWrap>
    </RightDrawerSidebar>
  );
}
