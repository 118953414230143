import React from 'react';
import { Icon, IconEnum } from '@src/ui/kit';

// Styled
import {
  StyledHeader,
  StyledPopoverWrapper,
  StyledPopoverTitle,
  StyledPopoverDescription,
  StyledCloseButton
} from './styled';

interface IPopoverProps {
  title: string;
  description: string;
  x: number;
  y: number;
  children: React.ReactNode;
  handleMouseOver?: () => void;
  handleMouseLeave?: () => void;
  onClose?: (event: React.MouseEvent | MouseEvent) => void;
}

export function Popover({
  title,
  description,
  x,
  y,
  children,
  handleMouseOver,
  handleMouseLeave,
  onClose
}: IPopoverProps): JSX.Element {
  const ref = React.useRef(null);

  React.useEffect(() => {
    const handleClickOutSide = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose(event);
      }
    };
    document.addEventListener('mousedown', handleClickOutSide);

    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  }, [ref, onClose]);

  return (
    <StyledPopoverWrapper
      ref={ref}
      onMouseDown={(event) => event.stopPropagation()}
      x={x}
      y={y}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <StyledHeader>
        <StyledPopoverTitle>{title}</StyledPopoverTitle>
        {onClose && (
          <StyledCloseButton onClick={onClose}>
            <Icon type={IconEnum.CLOSE_SMALL} />
          </StyledCloseButton>
        )}
      </StyledHeader>
      <StyledPopoverDescription>{description}</StyledPopoverDescription>
      {children}
    </StyledPopoverWrapper>
  );
}
