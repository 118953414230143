import React from 'react';
import { format } from 'date-fns';
import { CalendarIcon, ChevronIcon } from '@pushwoosh/kit-icons';
import { Tooltip } from '@pushwoosh/kit-tooltip';
import { useStoreState } from '@src/store/store';
import { useGetJourneyById } from '@src/store/hooks';
import { getTimePeriodTitle } from '@src/store/helpers/getTimePeriodTitle';
import { PeriodForm } from './components/PeriodForm';
import { Container, PeriodWrap, PeriodValue, Header, Title } from './styled';

export function PeriodButton(): JSX.Element {
  const [isOpen, setOpen] = React.useState(false);
  const [isVisibleTooltip, setVisibleTooltip] = React.useState(false);
  const periodSettings = useStoreState((state) => state.statistics.periodSettings);
  const getJourneyById = useGetJourneyById();
  const currentJourneyId = useStoreState((state) => state.journeys.currentJourneyId);
  const journey = getJourneyById(currentJourneyId);
  const journeyFromDate = format(periodSettings.dateFrom, 'dd-MM-yyyy');
  const journeyToDate = format(periodSettings.dateTo, 'dd-MM-yyyy');
  const tooltipContent = `from ${journeyFromDate} to ${journeyToDate}`;

  return (
    <Container isOpen={isOpen}>
      {isOpen ? (
        <Header onClick={() => setOpen(false)}>
          <CalendarIcon size="small" view="lined" />
          <Title>Select time period…</Title>
          <ChevronIcon size="small" direction="up" />
        </Header>
      ) : (
        <Tooltip isVisible={isVisibleTooltip} position="right-middle" content={tooltipContent}>
          <PeriodWrap
            onClick={() => setOpen(true)}
            onMouseEnter={() => setVisibleTooltip(true)}
            onMouseLeave={() => setVisibleTooltip(false)}
          >
            <CalendarIcon size="small" view="lined" />
            <PeriodValue>Time period: {getTimePeriodTitle(periodSettings.period)}</PeriodValue>
            <ChevronIcon size="small" direction="down" />
          </PeriodWrap>
        </Tooltip>
      )}
      <PeriodForm
        createdAt={journey?.createdAt}
        isOpen={isOpen}
        periodSettings={periodSettings}
        onClose={() => {
          setOpen(false);
          setVisibleTooltip(false);
        }}
      />
    </Container>
  );
}
