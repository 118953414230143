import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('This field cannot be empty'),
  lastName: Yup.string().required('This field cannot be empty'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('This field cannot be empty'),
  company: Yup.string().required('This field cannot be empty'),
  message: Yup.string().notRequired()
});
