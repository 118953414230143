import * as React from 'react';
import Konva from 'konva';

// Types
import { TPoints } from '@src/common/types/points';
import { IVector2 } from '@src/common/types/types';

// Constants
import { PointType } from '@src/common/constants/point';

// Helpers
import { findMaxLeftPoint, getCoordsAlignStage } from '@src/canvas/helpers';

interface IProps {
  isRequestLoadedById: boolean;
  centeredId?: string;
  points: TPoints[];
  refStage: { current: Konva.Stage };
}

type TResult = [IVector2, (coors: IVector2) => void];

export function useCenteredStage({
  isRequestLoadedById,
  centeredId,
  points,
  refStage
}: IProps): TResult {
  const [coords, setCenteredCoords] = React.useState<IVector2>({
    x: 0,
    y: 0
  });
  const [centeredIdPrev, setCenteredIdPrev] = React.useState(centeredId);
  const previousRequest = React.useRef(true);

  React.useEffect((): void => {
    const { scaleX, scaleY } = refStage.current.getStage().getAttrs();

    if (centeredId && centeredId !== centeredIdPrev) {
      const [coordsCenteredError] = points.filter(
        (item: TPoints): boolean => item.id === centeredId
      );

      if (coordsCenteredError && coordsCenteredError.position) {
        setCenteredCoords(
          getCoordsAlignStage({
            x: coordsCenteredError.position.x - 312,
            y: coordsCenteredError.position.y,
            scaleX,
            scaleY
          })
        );

        setCenteredIdPrev(centeredId);
      }
    }
    if (previousRequest.current && !isRequestLoadedById && points.length) {
      const entryPoints = points.filter((item: TPoints): boolean => item.type === PointType.EVENT);

      const coords = findMaxLeftPoint(entryPoints.length ? entryPoints : points, {
        scaleX,
        scaleY
      });

      setCenteredCoords({
        x: coords.x + 312,
        y: coords.y
      });
    }
    previousRequest.current = isRequestLoadedById;
  }, [isRequestLoadedById, points, refStage, centeredId, centeredIdPrev, coords]);
  return [coords, setCenteredCoords];
}
