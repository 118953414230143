import * as React from 'react';

// Hooks
import { useMousePosition } from '@src/ui/hooks/useMousePosition';

// Types
import { IVector2 } from '@src/common/types/types';
import { IDraggableProps } from './Draggable.types';

// Styled
import { DraggableContainer } from './styled';

const DRAG_MOUSE_PADDING = 2;

export function Draggable(props: IDraggableProps): JSX.Element {
  const { children } = props;
  const position: IVector2 = useMousePosition();

  if (position.x === 0 && position.y === 0) {
    return null;
  }

  const style: React.CSSProperties = {
    transform: `translate(${position.x + DRAG_MOUSE_PADDING}px, ${
      position.y + DRAG_MOUSE_PADDING
    }px)`
  };

  return <DraggableContainer style={style}>{children}</DraggableContainer>;
}
