import React from 'react';
import { useDynamicEvents } from '@src/store/hooks';
import { Checkbox } from '@pushwoosh/kit-checkbox';
import { Select, TSelectOption } from '@src/ui/kit/Select';
import { PlaceholderName, PlaceholderWrap } from './styled';

type Props = {
  placeholder: {
    name: string;
    isSelected: boolean;
    attribute: string;
    eventName: string;
    pointID: string;
  };
  index: number;
  applicationCode: string;
  onSelectPlaceholder: (value: boolean, index: number) => void;
  onSelectEvent: (value: string, pointId: string, index: number) => void;
  onSelectAttribute: (value: string, index: number) => void;
  handleLoadAppCode: (appCode: string) => void;
};

export function Placeholder(props: Props): JSX.Element {
  const {
    index,
    applicationCode,
    placeholder,
    onSelectAttribute,
    onSelectEvent,
    onSelectPlaceholder,
    handleLoadAppCode
  } = props;
  const { eventNameOptions, getAttributesList, getCurrentEvent, getEventValue } =
    useDynamicEvents(applicationCode);

  const currentEvent = getCurrentEvent(placeholder.eventName, placeholder.pointID);
  const attributesList = getAttributesList(placeholder.eventName);

  React.useEffect(() => {
    if (!placeholder.eventName) return;

    if (attributesList.length === 1 && !placeholder.attribute) {
      onSelectAttribute(attributesList[0].value as string, index);
    }

    if (attributesList.length > 0) {
      if (!attributesList.find((item) => item.value === placeholder.attribute)) {
        onSelectAttribute(attributesList[0].value as string, index);
      }
    }
  }, [placeholder, applicationCode, index, onSelectAttribute, attributesList]);

  return (
    <PlaceholderWrap>
      <PlaceholderName>
        <Checkbox
          name="placeholderName"
          value={false}
          isChecked={placeholder.isSelected}
          onChange={(bag) => {
            onSelectPlaceholder(bag.isChecked, index);
          }}
        >
          {placeholder.name}
        </Checkbox>
      </PlaceholderName>
      <Select
        isDisabled={!placeholder.isSelected}
        value={currentEvent}
        options={eventNameOptions}
        onChange={(item) => {
          const event = item as TSelectOption;
          const { eventName, pointID } = getEventValue(event.value as unknown as string);

          onSelectEvent(eventName, pointID, index);
          handleLoadAppCode(applicationCode);
        }}
      />
      <Select
        isDisabled={!currentEvent?.value && !placeholder.isSelected}
        value={
          placeholder.attribute
            ? {
                label: placeholder.attribute,
                value: placeholder.attribute
              }
            : null
        }
        options={attributesList}
        onChange={(currentValue: TSelectOption) => {
          onSelectAttribute(currentValue.value as string, index);
        }}
      />
    </PlaceholderWrap>
  );
}
