export enum AttributeTypeEnum {
  INTEGER = 1,
  STRING = 2,
  LIST = 3,
  DATE = 4,
  BOOLEAN = 5
}

export enum OperatorTypeEnum {
  EQ = 'EQ',
  NOTEQ = 'NOTEQ',
  IN = 'IN',
  NOTIN = 'NOTIN',
  LTE = 'LTE',
  GTE = 'GTE',
  BETWEEN = 'BETWEEN',
  UNKNOWN = 'OPERATOR_UNKNOWN' // This comes from backend
}

export enum OperatorTypesHumans {
  EQ = 'is',
  NOTEQ = 'not is',
  IN = 'in',
  NOTIN = 'not in',
  LTE = 'less or equals',
  GTE = 'greater or equals',
  BETWEEN = 'between',
  OPERATOR_UNKNOWN = ''
}

export const ONE_CHOICE_OPERATORS = new Set([
  OperatorTypeEnum.EQ,
  OperatorTypeEnum.NOTEQ,
  OperatorTypeEnum.LTE,
  OperatorTypeEnum.GTE
]);

export const TWO_CHOICE_OPERATORS = new Set([OperatorTypeEnum.BETWEEN]);

export const MULTIPLE_CHOICE_OPERATORS = new Set([OperatorTypeEnum.IN, OperatorTypeEnum.NOTIN]);
