import React from 'react';
import { useParams } from 'react-router-dom';
import { Color } from '@pushwoosh/kit-constants';
import { getPlaceholderFromPreset } from '@src/common/helpers/getPlaceholdersFromPreset';
import { IFormSelectProps } from '@src/ui/form/FormSelect/FormSelect.types';
import { Section, Message } from '@src/ui/kit';
import { H4, Paragraph } from '@pushwoosh/kit-typography';
import { RadioButton } from '@src/ui/kit/RadioButton';
import { ContentSettingsProps, ModeSms } from './ContentSettings.types';
import {
  StyledRadioButtonGroup,
  FormSelectWithOffset,
  Option,
  FormTextareaWithOffset,
  MessageWrap
} from './styled';

function SelectPresetOption(
  props: React.ComponentProps<IFormSelectProps<string>['components']['Option']>
): JSX.Element {
  const { innerProps, data } = props;

  return (
    <Option {...innerProps}>
      <H4>{data.label}</H4>
      <Paragraph>{data.message}</Paragraph>
    </Option>
  );
}

export function ContentSettings(props: ContentSettingsProps): JSX.Element {
  const {
    applicationCode,
    values,
    names,
    presetSelectOptions,
    isLoadingPresetOptions,
    presets,
    setValues,
    setFieldTouched,
    setFieldValue
  } = props;
  const params = useParams<{ applicationCode: string }>();

  const showContentIsPreset = values.isPresetAvailable
    ? values.isPresetAvailable && values.content !== ''
    : true;

  const changeMode = React.useCallback(
    (value: string) => {
      if (value === ModeSms.PRESET) {
        setValues({
          ...values,
          [names.isPresetAvailable]: true,
          [names.presetCode]: '',
          [names.content]: ''
        });
      }
      if (value === ModeSms.CUSTOM) {
        setValues({
          ...values,
          [names.isPresetAvailable]: false,
          [names.content]: ''
        });
      }
    },
    [setValues, names, values]
  );

  const handleSelectPreset = React.useCallback(
    (_, value: string) => {
      const currentPreset = presets.find((item) => item.code === value);
      const placeholders = getPlaceholderFromPreset(currentPreset);

      setValues({
        ...values,
        [names.presetCode]: currentPreset?.code,
        [names.content]: currentPreset?.content,
        [names.title]: currentPreset.title || currentPreset.name,
        [names.placeholders]: placeholders
      });
    },
    [setValues, presets, names, values]
  );

  const handleChangeContent = React.useCallback(
    (name: string, value: string) => {
      if (values.isPresetAvailable) {
        const currenPreset = presets.find((item) => item.code === values.presetCode);
        setValues({
          ...values,
          [names.isPresetAvailable]: false,
          [names.title]: currenPreset.title,
          [names.content]: value
        });
      } else {
        setFieldValue(name, value);
      }
    },
    [values, names, setFieldValue, presets, setValues]
  );

  const handleConvertToCustom = React.useCallback(() => {
    setValues({
      ...values,
      [names.isPresetAvailable]: false
    });
  }, [names.isPresetAvailable, values, setValues]);

  const presetLink = params.applicationCode
    ? `https://app.pushwoosh.com/applications/${applicationCode}/presets`
    : `https://go.pushwoosh.com/cp/applications/${applicationCode}/presets`;

  return (
    <Section direction="column">
      {applicationCode && (
        <StyledRadioButtonGroup
          value={values.isPresetAvailable ? ModeSms.PRESET : ModeSms.CUSTOM}
          onChange={changeMode}
        >
          <RadioButton value={ModeSms.PRESET} label="Preset" />
          <RadioButton value={ModeSms.CUSTOM} label="Custom content" />
        </StyledRadioButtonGroup>
      )}
      {applicationCode && values.isPresetAvailable && (
        <FormSelectWithOffset
          label="Preset"
          url={presetLink}
          name={names.presetCode}
          value={values.presetCode}
          options={presetSelectOptions}
          placeholder="Select preset"
          components={{
            Option: SelectPresetOption
          }}
          isLoading={isLoadingPresetOptions}
          isSearchable
          onChange={handleSelectPreset}
          onBlur={setFieldTouched}
        />
      )}
      {applicationCode && showContentIsPreset && (
        <FormTextareaWithOffset
          name={names.content}
          label="Content"
          value={values.content}
          onChange={handleChangeContent}
          disabled={values.isPresetAvailable}
          actionText={values.isPresetAvailable && 'Convert to custom'}
          onActionClick={handleConvertToCustom}
        />
      )}
      {applicationCode && !values.isPresetAvailable && (
        <MessageWrap>
          <Message
            title="Custom content does not provide any settings other than content itself"
            message="If choose Custom content, you cannot set properties other than the content itself: platform-specific settings, languages, media attachments, and root params. Users will only receive the text you enter here (including personalizing placeholders, if any). To set additional parameters, use presets."
            color={Color.BRIGHT_LIGHT}
          />
        </MessageWrap>
      )}
    </Section>
  );
}
