// Types
import { TKonvaEventHandler } from '@src/common/types/utils';
import { TArrow } from '@src/common/types/arrow';

export interface IDrawingArrowCallbacks {
  onDrawEndOnPoint?: (arrow: TArrow) => void;
  onDrawEndOnCanvas?: (arrow: TArrow) => void;
}

export enum DrawingArrowStatus {
  IDLE = 'idle',
  DRAWING = 'drawing',
  DRAWING_PAUSE_ON_POINT = 'drawing-pause-on-point',
  DRAWING_PAUSE_ON_CANVAS = 'drawing-pause-on-canvas'
}

interface IStatus<Statuses> {
  status: Statuses;
}

export type TDrawingArrowState = TArrow & IStatus<DrawingArrowStatus>;
export type TStartDrawArrow = TKonvaEventHandler<MouseEvent>;
export type TMoveDrawArrow = TKonvaEventHandler<MouseEvent>;
export type TEndDrawArrow = TKonvaEventHandler<MouseEvent>;
export type TResetDrawArrow = () => void;

export interface IUseDrawingArrow {
  startDrawArrow: TStartDrawArrow;
  moveDrawArrow: TMoveDrawArrow;
  endDrawArrow: TEndDrawArrow;
  resetDrawArrow?: TResetDrawArrow;
}
