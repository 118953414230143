import * as React from 'react';

// Styled
import { Button } from './styled';

// Types
import { ChoiceProps } from './Choice.types';

export function Choice(props: ChoiceProps): JSX.Element {
  const { color = 'blue', value, isSelected = false, isDisabled, children, onChange } = props;

  const handleClick = React.useCallback(() => {
    if (isDisabled) {
      return;
    }

    if (typeof onChange === 'function') {
      onChange(value, isSelected);
    }
  }, [isDisabled, isSelected, value, onChange]);

  return (
    <Button
      color={color}
      type="button"
      isSelected={isSelected}
      isDisabled={isDisabled}
      onClick={handleClick}
    >
      {children}
    </Button>
  );
}
