import React from 'react';
import { Icon, IconEnum } from '@src/ui/kit/Icon';
import { Wrap, LogoutText } from './styled';

interface IProps {
  onLogout: () => void;
}

export function LogoutButton(props: IProps): JSX.Element {
  return (
    <Wrap onClick={props.onLogout}>
      <Icon type={IconEnum.LOGOUT_SMALL_LINED} />
      <LogoutText>Logout</LogoutText>
    </Wrap>
  );
}
