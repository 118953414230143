import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgWebhooksLarge(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 8.4a5.067 5.067 0 00-3.066 9.1l.96.54a.5.5 0 01.19.681l-.245.436-2.83 5.03a.58.58 0 00-.003.525 2.136 2.136 0 11-1.943-1.247.097.097 0 00.086-.05l2.265-4.027a.526.526 0 00-.118-.648 7.067 7.067 0 1111.486-3.281.473.473 0 01-.626.3l-.937-.35a.541.541 0 01-.318-.65 5.067 5.067 0 00-4.9-6.359zm.116 7.265A.134.134 0 0020 15.6a2.136 2.136 0 111.952-1.267.58.58 0 00.009.527l2.189 3.785a.526.526 0 00.619.228 7.067 7.067 0 11-2.149 12.326.474.474 0 01-.052-.693l.665-.746a.54.54 0 01.721-.06 5.067 5.067 0 10.574-8.56l-.5.29a.5.5 0 01-.683-.182l-.25-.433-2.979-5.149zm6.82 12.07a2.136 2.136 0 10-1.728-3.39.58.58 0 01-.46.255h-6.114a.5.5 0 00-.5.5v.5a5.067 5.067 0 11-6.418-4.885.541.541 0 00.403-.601l-.169-.986a.474.474 0 00-.574-.39 7.067 7.067 0 108.613 8.291.527.527 0 01.508-.429h4.251a.58.58 0 01.46.255c.389.534 1.018.88 1.729.88z"
        fill="#7AA0CC"
      />
    </svg>
  );
}

export default SvgWebhooksLarge;
