// Enums
import {
  DEFAULT_JSON_VALUE,
  PointType,
  REACHABILITY_CHECK_TYPES
} from '@src/common/constants/point';
import { RepeatType } from '@src/common/constants/repeatSegment';

// Types
import { TPoints } from '@src/common/types/points';

export const AVAILABLE_POINTS: TPoints[] = [
  {
    id: '4',
    title: '',
    type: PointType.EXIT,
    position: {
      x: 100,
      y: 100
    },
    data: {},
    outputs: []
  },
  {
    id: '2',
    title: '',
    type: PointType.EVENT,
    position: {
      x: 0,
      y: 0
    },
    data: {
      name: '',
      applicationCode: '',
      eventConditions: []
    },
    outputs: []
  },
  {
    id: '1',
    title: '',
    type: PointType.SEGMENT,
    position: {
      x: 0,
      y: 0
    },
    data: {
      applicationCode: '',
      applicationTitle: '',
      filterCode: '',
      filterTitle: '',
      entryLimitsEnabled: true,
      entryLimitsPerSecond: 5000,
      repeat: {
        enabled: false,
        type: RepeatType.EVERY_DAY
      },
      IsDeactivated: false
    },
    outputs: []
  },
  {
    id: '3',
    title: '',
    type: PointType.WAIT,
    position: {
      x: 200,
      y: 200
    },
    data: {
      delay: 300
    },
    outputs: []
  },
  {
    id: '5',
    title: '',
    type: PointType.SEND_PUSH,
    position: {
      x: 200,
      y: 200
    },
    data: {
      title: '',
      content: '',
      presetCode: '',
      applicationCode: '',
      campaignCode: '',
      behaviorSplitter: {
        enabled: false,
        interval: 0
      }
    },
    outputs: []
  },
  {
    id: '6',
    title: '',
    type: PointType.SEND_EMAIL,
    position: {
      x: 200,
      y: 200
    },
    data: {
      applicationCode: '',
      presetCode: '',
      campaignCode: '',
      sendOnlyToSubscribed: true
    },
    outputs: []
  },
  {
    id: '7',
    title: '',
    type: PointType.SEND_IN_APP,
    position: {
      x: 200,
      y: 200
    },
    data: {
      applicationCode: '',
      richMediaCode: '',
      expireInDays: 7
    },
    outputs: []
  },
  {
    id: '12',
    title: '',
    type: PointType.WEBHOOK,
    position: {
      x: 200,
      y: 200
    },
    data: {
      url: '',
      headers: {
        'Content Type': 'application/json'
      },
      request_template: DEFAULT_JSON_VALUE,
      method: 'POST'
    },
    outputs: []
  },
  {
    id: '11',
    title: '',
    type: PointType.SET_TAGS,
    position: {
      x: 200,
      y: 200
    },
    data: {
      applicationCode: '',
      tags: []
    },
    outputs: []
  },
  {
    id: '8',
    title: '',
    type: PointType.BOOLEAN_SPLITTER,
    position: {
      x: 200,
      y: 200
    },
    data: {
      filterCode: '',
      name: ''
    },
    outputs: []
  },
  {
    id: '12',
    title: '',
    type: PointType.REACHABILITY_CHECK,
    position: {
      x: 200,
      y: 200
    },
    data: {
      filterType: REACHABILITY_CHECK_TYPES.PUSH
    },
    outputs: []
  },
  {
    id: '9',
    title: '',
    type: PointType.WAIT_EVENT,
    position: {
      x: 200,
      y: 200
    },
    data: {
      waitEvents: [],
      waitEventsConditionsScript: ''
    },
    outputs: []
  },
  {
    id: '10',
    title: '',
    type: PointType.AB_SPLITTER,
    position: {
      x: 200,
      y: 200
    },
    data: {
      split: [50, 50]
    },
    outputs: []
  },
  {
    id: '14',
    title: '',
    type: PointType.SEND_SMS,
    position: {
      x: 200,
      y: 200
    },
    data: {
      content: '',
      presetCode: '',
      applicationCode: '',
      campaignCode: '',
      smsBehaviorSplitter: {
        enabled: false,
        interval: 0
      }
    },
    outputs: []
  },
  {
    id: '15',
    title: '',
    type: PointType.API,
    position: {
      x: 200,
      y: 200
    },
    data: {
      applicationCode: '',
      attributes: [],
      entryLimitsEnabled: true,
      entryLimitsPerSecond: 5000,
      IsDeactivated: false
    },
    outputs: []
  }
];
