import { formatISO } from 'date-fns';

// Types
import { TagValue } from '@src/common/types/tag';
import { TypeTag } from '@src/common/constants/tag';

export const getDefaultValueTag = (type: TypeTag): TagValue => {
  switch (type) {
    case TypeTag.BOOLEAN: {
      return false;
    }
    case TypeTag.STRING: {
      return '';
    }
    case TypeTag.DATE: {
      return formatISO(new Date());
    }
    case TypeTag.PRICE: {
      return 0;
    }
    case TypeTag.INTEGER: {
      return 0;
    }
    case TypeTag.LIST: {
      return [];
    }
    case TypeTag.VERSION: {
      return '';
    }
    case TypeTag.DYNAMIC: {
      return {
        pointId: '',
        eventName: '',
        applicationCode: '',
        attribute: ''
      };
    }
    default: {
      return null;
    }
  }
};
