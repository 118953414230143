import * as React from 'react';
import Konva from 'konva';
import { Color, FontSize, FontWeight } from '@pushwoosh/kit-constants';
import { Group, Text, Label, Tag } from 'react-konva';

// Helpers
import { getSizePoint } from '@src/common/helpers';

// Types
import { PointShortInfoProps } from './PointShortInfo.types';

export const PointShortInfo = (props: PointShortInfoProps): JSX.Element => {
  const { type, align, label, show = true } = props;

  const { widthPoint, heightPoint } = getSizePoint(type);

  const [xCoord, setXCoords] = React.useState(0);
  const [yCoord, setYCoords] = React.useState(heightPoint + 8);
  const forRef = React.useRef<Konva.Stage>(null);

  React.useEffect((): void => {
    if (forRef.current && align === 'center') {
      let newXCoord = 0;
      const directionOffset = forRef.current.attrs.width > widthPoint ? -1 : 1;
      newXCoord = (Math.abs(widthPoint - forRef.current.attrs.width) / 2) * directionOffset;
      setXCoords(newXCoord);
    }

    if (forRef.current && align === 'bottom') {
      let newYCoord = 0;
      newYCoord = Math.abs(heightPoint + forRef.current.attrs.height);
      setYCoords(newYCoord);
    }
  }, [widthPoint, align, label, heightPoint]);

  if (!show) {
    return null;
  }

  return (
    <Group x={xCoord} y={yCoord} height={30} perfectDrawEnabled={false}>
      <Label pointerWidth={10}>
        <Tag
          perfectDrawEnabled={false}
          fill={Color.SOFT}
          opacity={0.1}
          cornerRadius={4}
          ref={forRef as any}
          shadowForStrokeEnabled={false}
        />
        <Text
          x={18}
          perfectDrawEnabled={false}
          shadowForStrokeEnabled={false}
          y={0}
          height={18}
          fontSize={parseInt(FontSize.HEADING5, 10)}
          fontFamily="Roboto"
          fontStyle={String(FontWeight.MEDIUM)}
          text={label}
          fill={Color.SOFT}
          wrap="none"
          padding={4}
        />
      </Label>
    </Group>
  );
};
