import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSendInAppPoint(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 11a1 1 0 01-1 1h-1a1 1 0 01-1-1v-1a1 1 0 011-1h1a1 1 0 011 1v1zM8 17.2c0-1.12 0-1.68.218-2.108a2 2 0 01.874-.874C9.52 14 10.08 14 11.2 14h17.6c1.12 0 1.68 0 2.108.218a2 2 0 01.874.874C32 15.52 32 16.08 32 17.2v9.6c0 1.12 0 1.68-.218 2.108a2 2 0 01-.874.874C30.48 30 29.92 30 28.8 30H11.2c-1.12 0-1.68 0-2.108-.218a2 2 0 01-.874-.874C8 28.48 8 27.92 8 26.8v-9.6zM26 20a1 1 0 01-1 1H13a1 1 0 01-1-1v-1a1 1 0 011-1h12a1 1 0 011 1v1zm-14 5a1 1 0 001 1h5a1 1 0 001-1v-1a1 1 0 00-1-1h-5a1 1 0 00-1 1v1zM8 11a1 1 0 001 1h17a1 1 0 001-1v-1a1 1 0 00-1-1H9a1 1 0 00-1 1v1z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgSendInAppPoint;
