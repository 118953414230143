import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgFinishMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 6A.75.75 0 017 5.25h11a.75.75 0 01.6 1.2L15.937 10l2.663 3.55a.75.75 0 01-.6 1.2H7.75v3.503c.64.015 1.237.09 1.706.212.26.067.524.16.74.293.175.107.554.388.554.892s-.38.785-.554.892a2.76 2.76 0 01-.74.293c-.53.138-1.221.215-1.956.215-.735 0-1.426-.077-1.956-.215a2.76 2.76 0 01-.74-.293c-.175-.107-.554-.388-.554-.892s.38-.785.554-.892a2.76 2.76 0 01.74-.293c.212-.056.45-.101.706-.136V6zm1.5 7.25v-6.5h8.75l-2.1 2.8a.75.75 0 000 .9l2.1 2.8H7.75zm-2.177 6.797l.013-.01c-.007.007-.013.01-.013.01zm3.841-.01l.013.01s-.006-.003-.013-.01zm0-.775a.11.11 0 01.013-.009l-.013.01zm-3.828 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgFinishMediumLined;
