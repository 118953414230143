import { JourneyStatus } from '@src/common/constants/journey';

// Types
import { StateTablePartial } from '@src/ui/features/JourneyList/components/Table';
import { ParamsJourneyList } from '@src/common/types/journey';
import { PER_PAGE_DEFAULT } from '@src/ui/features/JourneyList/components/Table/hooks/usePagination';

export const compileRequest = (state: StateTablePartial): ParamsJourneyList => {
  const paramsRequest: ParamsJourneyList = {};

  // Sorting
  if (state.sortType && state.sortColumn) {
    const sortColumn = state.sortColumn === 'dataModified' ? 'updatedAt' : state.sortColumn;
    paramsRequest.sort_field = sortColumn;
    paramsRequest.sort_type = state.sortType;
  }

  // Filtering
  if (typeof state.filterTitle === 'string' && state.filterTitle !== '') {
    paramsRequest.filter_title = state.filterTitle;
  }

  if (state.filterStatus && state.filterStatus.length !== 0) {
    paramsRequest.filter_state = state.filterStatus.join(',');
  }

  // Pagination
  paramsRequest.per_page = state.perPage || PER_PAGE_DEFAULT;

  if (state.page) {
    paramsRequest.page = state.page;
  }

  // Archive filter
  if (state.showArchive) {
    paramsRequest.filter_state = JourneyStatus.ARCHIVED;
  }

  return paramsRequest;
};
