import React, { useMemo } from 'react';
import { Button, IconEnum } from '@src/ui/kit';
import { FormBlock } from '@src/ui/form/styled';
import { JourneyStatus } from '@src/common/constants';
import { ICommonEvent } from '@src/common/types/event';
import { EditEvents, useEditEvents } from '@src/ui/features/EditEvents';
import { FormInput } from '@src/ui/form';
import { TFormErrors } from '@src/ui/hooks/useForm';
import { BranchType } from '@src/common/types/branch';
import {
  CompactEventBadge,
  CompactEventsView,
  CompactOperator,
  Container,
  Content,
  HalfContent,
  Header,
  HeaderActions,
  HeaderBranchKey,
  HeaderBranchName,
  HeaderTitle,
  StatusIcon
} from './styled';
import { WaitingPeriodTypeProps } from '../../../WaitPeriodTime';

type Props = {
  title?: string;
  order: number;
  setFieldValue: WaitingPeriodTypeProps['setFieldValue'];
  setSaveDisabled: (value: boolean) => void;
  deleteBranch: (index: number) => void;
  renameBranch: (index: number, title: string) => void;
  canDelete: boolean;
  open: boolean;
  events: ICommonEvent[];
  status: JourneyStatus;
  toggleOpen: (index: number) => void;
  errors?: WaitingPeriodTypeProps['errors']['branches'];
};

function useErrors(errors: Props['errors'], order: number) {
  return useMemo(() => {
    let branchError: undefined | string;
    let branchErrors: undefined | TFormErrors<BranchType>;
    let eventsErrors: string[] | TFormErrors<ICommonEvent>[] = [];

    if (errors && typeof errors === 'object') {
      const error = errors[order - 1];

      if (error) {
        if (typeof error === 'string') {
          branchError = error;
        }

        if (typeof error === 'object') {
          branchErrors = error;

          if (typeof error.events === 'object') {
            eventsErrors = error.events;
          }
        }
      }
    }
    return {
      branchError,
      branchErrors,
      eventsErrors
    };
  }, [errors, order]);
}

export function Branch(props: Props): JSX.Element {
  const {
    title,
    order,
    events,
    status,
    open,
    canDelete,
    setFieldValue,
    setSaveDisabled,
    deleteBranch,
    renameBranch,
    toggleOpen,
    errors
  } = props;

  const { listEvents, updateEvent, addEvent, removeEvent, updateOperator } = useEditEvents(events);

  const { branchError, branchErrors, eventsErrors } = useErrors(errors, order);

  React.useEffect(() => {
    setFieldValue(`branches[${order - 1}].events`, listEvents);
  }, [listEvents, order, setFieldValue]);

  const handleUpdateEvent = React.useCallback(
    (indexElement: number, data: ICommonEvent) => {
      updateEvent(indexElement, data);

      if (status === JourneyStatus.RUNNING) {
        if (listEvents.length === 1 && data.name === '') {
          setSaveDisabled(true);
        } else {
          setSaveDisabled(false);
        }

        listEvents.forEach((event) =>
          event.eventConditions?.forEach((condition) => {
            if (!condition.value) {
              setSaveDisabled(true);
            }
          })
        );
      }
    },
    [updateEvent, status, listEvents, setSaveDisabled]
  );

  const handleBranchDeleteClick = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation();
      if (!canDelete) return;
      deleteBranch(order - 1);
    },
    [deleteBranch, order, canDelete]
  );

  return (
    <Container hasError={!!branchError}>
      <Header kind="primary" onClick={() => toggleOpen(order - 1)}>
        <HeaderTitle>
          <HeaderBranchKey>{`Branch ${order}`}</HeaderBranchKey>
          <StatusIcon type={IconEnum.CHEVRON_DOWN_SMALL} open={open} />
          {open || <HeaderBranchName>{title}</HeaderBranchName>}
        </HeaderTitle>

        <HeaderActions>
          {canDelete && (
            <Button
              iconType={IconEnum.CLOSE_SMALL}
              iconPosition={'right'}
              onClick={handleBranchDeleteClick}
              size="compact"
              color="primary"
              view="ghost"
            />
          )}
        </HeaderActions>
      </Header>

      {open ? (
        <HalfContent>
          <FormBlock>
            <FormInput
              label="Branch Name"
              name={`branches[${order - 1}].title`}
              value={title}
              error={branchErrors?.title}
              placeholder={`Trigger group ${order}`}
              onChange={(_, value): void => renameBranch(order - 1, value)}
            />
          </FormBlock>

          <FormBlock>
            <EditEvents
              inBorder
              events={listEvents}
              errors={eventsErrors}
              updateEvent={handleUpdateEvent}
              removeEvent={removeEvent}
              updateOperator={updateOperator}
            />
            <FormBlock style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => listEvents.length < 4 && addEvent(order)}
                view="shape"
                size="compact"
                color="secondary"
                iconType={IconEnum.PLUS_SMALL}
              >
                Add Event
              </Button>
            </FormBlock>
          </FormBlock>
        </HalfContent>
      ) : (
        <Content>
          <CompactEventsView>
            {listEvents.map((event, index) => (
              <React.Fragment key={index}>
                <CompactEventBadge hasError={!!eventsErrors[index]}>
                  {event.name || '(not selected)'}
                </CompactEventBadge>

                {index === events.length - 1 || (
                  <CompactOperator>{event?.operation?.type?.toString()}</CompactOperator>
                )}
              </React.Fragment>
            ))}
          </CompactEventsView>
        </Content>
      )}
    </Container>
  );
}
