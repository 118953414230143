import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSegmentSplitPoint(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.794 30.965a12 12 0 004.106-2.951c.37-.41.283-1.04-.156-1.376l-3.18-2.427c-.439-.335-1.061-.243-1.465.135a6 6 0 11-5.076-10.302c.544-.09.996-.527.998-1.08l.012-4c.002-.552-.445-1.005-.996-.961a12 12 0 105.757 22.962zm3.014-20.113a12 12 0 00-3.316-2.015c-.512-.207-1.074.092-1.238.62l-1.183 3.82c-.163.528.137 1.081.63 1.33a6.004 6.004 0 013.293 5.081c.022.484-.014.968-.108 1.441-.107.542.144 1.12.66 1.315l3.74 1.417c.517.195 1.099-.064 1.25-.595a12 12 0 00-3.728-12.414z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgSegmentSplitPoint;
