import { FormikValues } from 'formik';
import { TPreset } from '@src/common/types/preset';
import {
  TPresetSelectOption,
  TFormFnSetFieldTouched,
  TFormFnSetFieldValue
} from '@src/common/types/form';
import { IEditPointSendSmsValues } from '../EditPointSendSms/EditPointSendSms.types';

export type ContentSettingsProps = {
  applicationCode: string;
  pointId: string;
  values: IEditPointSendSmsValues;
  names: Record<keyof IEditPointSendSmsValues, string>;
  presetSelectOptions: TPresetSelectOption[];
  isLoadingPresetOptions: boolean;
  presets: TPreset[];
  setValues: (values: FormikValues) => void;
  setFieldTouched: TFormFnSetFieldTouched;
  setFieldValue: TFormFnSetFieldValue;
};

export enum ModeSms {
  PRESET = 'preset',
  CUSTOM = 'custom'
}
