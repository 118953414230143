import * as React from 'react';

// Components
import { DocElements } from '@src/ui/features/Documentation/components/DocElements';

export function DocumentationPointSendEmail(): JSX.Element {
  return (
    <DocElements>
      <DocElements.Paragraph>
        <strong>Email</strong>
        {' element indicates a point to communicate to a customer with an email message.'}
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        {'To send an email at some moment of the customer journey, add the '}
        <strong>Email</strong>
        {
          ' element to the canvas following the point you consider to be the appropriate basis for communication. Choose the '
        }

        <strong>email preset</strong>
        {" you'd like to use."}
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-send-email-1.png"
        label="Adding Email element"
        alt="Adding Email element"
      />
    </DocElements>
  );
}
