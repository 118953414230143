import * as React from 'react';
import konva from 'konva';
import { Path } from 'react-konva';

// Constants
import { ENTRY_GROUP_COLOR, POINT_MENU_DEFAULT, PointType } from '@src/common/constants';

// Hooks
import { useShowOneBadge } from '@src/canvas/hooks';

// Helpers
import { getTitlePoint } from '@src/canvas/helpers';

// Components
import { Point, PointContent, PointText, PointIcon, PointOutput, PointShortInfo } from '../Point';

// Types
import { IProps } from './PointApi.types';

export function PointApi(props: IProps): JSX.Element {
  const { data, propsCommonPoint } = props;

  const [isShowBadge, setShowBadge] = useShowOneBadge(propsCommonPoint.isDrawingArrow);

  const titlePoint = getTitlePoint(PointType.API, propsCommonPoint.title, data);

  return (
    <Point type={PointType.API} color={ENTRY_GROUP_COLOR} {...propsCommonPoint}>
      <PointContent type={PointType.API}>
        <PointIcon type={PointType.API} fill={ENTRY_GROUP_COLOR}>
          <Path
            fill="white"
            perfectDrawEnabled={false}
            shadowForStrokeEnabled={false}
            data="M20.5001 12C19.3955 12 18.5001 12.8954 18.5001 14V21V26C18.5001 27.1046 19.3955 28 20.5001 28C21.6046 28 22.5001 27.1046 22.5001 26V23.5C22.5001 23.2239 22.7239 23 23.0001 23H24.5001C26.7092 23 28.5001 21.2091 28.5001 19V16C28.5001 13.7909 26.7092 12 24.5001 12H20.5001ZM30.5 14C30.5 12.8954 31.3954 12 32.5 12C33.6046 12 34.5 12.8954 34.5 14V26C34.5 27.1046 33.6046 28 32.5 28C31.3954 28 30.5 27.1046 30.5 26V14ZM11 12C7.96243 12 5.5 14.4624 5.5 17.5V23V26C5.5 27.1046 6.39543 28 7.5 28C8.60457 28 9.5 27.1046 9.5 26V23.5C9.5 23.2239 9.72386 23 10 23H12C12.2761 23 12.5 23.2239 12.5 23.5V26C12.5 27.1046 13.3954 28 14.5 28C15.6046 28 16.5 27.1046 16.5 26V23V17.5C16.5 14.4624 14.0376 12 11 12Z"
          />

          <Path
            fill="rgb(93,151, 43)"
            perfectDrawEnabled={false}
            shadowForStrokeEnabled={false}
            data="M24.5001 15H23.0001C22.7239 15 22.5001 15.2239 22.5001 15.5V19.5C22.5001 19.7761 22.7239 20 23.0001 20H24.5001C25.0523 20 25.5001 19.5523 25.5001 19V16C25.5001 15.4477 25.0523 15 24.5001 15ZM11 16C10.1716 16 9.5 16.6716 9.5 17.5V19.5C9.5 19.7761 9.72386 20 10 20H12C12.2761 20 12.5 19.7761 12.5 19.5V17.5C12.5 16.6716 11.8284 16 11 16Z"
          />
        </PointIcon>
        <PointText type={PointType.API}>{titlePoint}</PointText>
        <PointOutput
          pointId={propsCommonPoint.id}
          type={PointType.API}
          colorBlock={ENTRY_GROUP_COLOR}
          isInvalid={propsCommonPoint.isInvalid}
          isActive={propsCommonPoint.isActive}
          isLinkedOutput={propsCommonPoint.isLinkedOutput}
          onMouseDown={(event: konva.KonvaEventObject<MouseEvent>): void => {
            setShowBadge(!isShowBadge);
            propsCommonPoint.onMouseDownOutput(event);
          }}
          textBadge="Connect this"
          fillBadge={POINT_MENU_DEFAULT}
          isShowBadge={propsCommonPoint.isLinkedOutput ? false : isShowBadge}
          arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
        />
      </PointContent>
      <PointShortInfo type={PointType.API} label="Entry" align="left" />
    </Point>
  );
}
