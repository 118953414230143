import React from 'react';
import {
  DropdownMenu,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuWindow
} from '@pushwoosh/kit-dropdown-menu';
import { Tag } from '@pushwoosh/kit-constants';
import { KebabIcon } from '@pushwoosh/kit-icons';

// Constants
import { JourneyStatus } from '@src/common/constants/journey';

// Types
import { JourneyControlsProps } from './JourneyControls.types';

// Hooks
import { useJourneyHandlers } from './hooks/useJourneyHandlers';

// Styled
import { Container } from './styled';

export function JourneyControls(props: JourneyControlsProps): JSX.Element {
  const { journeyId: id, journeyStatus: status, journeyTitle: title, onReloadJourneys } = props;
  const {
    handleButtonCloneClick,
    handleButtonDeleteClick,
    handleButtonStartClick,
    handleButtonEditClick,
    handleButtonRenameClick,
    handleButtonFinishClick,
    handleButtonArchiveClick
  } = useJourneyHandlers({
    status,
    id,
    title,
    onReloadJourneys,
    beforeLoadJourney: true
  });

  return (
    <Container>
      <DropdownMenu
        color="secondary"
        view="ghost"
        windowPlacement="bottom-end"
        icon={<KebabIcon size="medium" />}
        windowZIndex={999}
      >
        <DropdownMenuWindow>
          <DropdownMenuGroup>
            {status === JourneyStatus.RUNNING && (
              <>
                <DropdownMenuItem
                  color="main"
                  as={Tag.BUTTON}
                  type="button"
                  onClick={handleButtonEditClick}
                >
                  View
                </DropdownMenuItem>
                <DropdownMenuItem
                  color="main"
                  as={Tag.BUTTON}
                  type="button"
                  onClick={handleButtonFinishClick}
                >
                  Deactivate
                </DropdownMenuItem>
              </>
            )}
            {status === JourneyStatus.DRAFT && (
              <>
                <DropdownMenuItem
                  color="main"
                  as={Tag.BUTTON}
                  type="button"
                  onClick={handleButtonEditClick}
                >
                  Edit…
                </DropdownMenuItem>
                <DropdownMenuItem
                  color="main"
                  as={Tag.BUTTON}
                  type="button"
                  onClick={handleButtonStartClick}
                >
                  Start
                </DropdownMenuItem>
                <DropdownMenuItem
                  color="main"
                  as={Tag.BUTTON}
                  type="button"
                  onClick={handleButtonRenameClick}
                >
                  Rename…
                </DropdownMenuItem>
              </>
            )}
            {(status === JourneyStatus.FINISHED || status === JourneyStatus.ARCHIVED) && (
              <DropdownMenuItem
                color="main"
                as={Tag.BUTTON}
                type="button"
                onClick={handleButtonEditClick}
              >
                View
              </DropdownMenuItem>
            )}
            <DropdownMenuItem
              color="main"
              as={Tag.BUTTON}
              type="button"
              onClick={handleButtonCloneClick}
            >
              Clone
            </DropdownMenuItem>
            {status === JourneyStatus.FINISHED && (
              <DropdownMenuItem
                color="main"
                as={Tag.BUTTON}
                type="button"
                onClick={handleButtonArchiveClick}
              >
                Archive…
              </DropdownMenuItem>
            )}
          </DropdownMenuGroup>
          {status === JourneyStatus.DRAFT && (
            <DropdownMenuGroup>
              <DropdownMenuItem
                color="danger"
                as={Tag.BUTTON}
                type="button"
                onClick={handleButtonDeleteClick}
              >
                Delete…
              </DropdownMenuItem>
            </DropdownMenuGroup>
          )}
        </DropdownMenuWindow>
      </DropdownMenu>
    </Container>
  );
}
