import * as React from 'react';

import AbSplitPoint from './AbSplitPoint';
import AbSplitPointFilled from './AbSplitPointFilled';
import AbTest from './AbTest';
import AddNotesPointFilled from './AddNotesPointFilled';
import ArrowDownMedium from './ArrowDownMedium';
import ArrowDownSmall from './ArrowDownSmall';
import ArrowLeftMedium from './ArrowLeftMedium';
import ArrowLeftSmall from './ArrowLeftSmall';
import ArrowRightMedium from './ArrowRightMedium';
import ArrowRightSmall from './ArrowRightSmall';
import ArrowRoundDownMediumFilled from './ArrowRoundDownMediumFilled';
import ArrowRoundDownMediumLined from './ArrowRoundDownMediumLined';
import ArrowRoundDownSmallFilled from './ArrowRoundDownSmallFilled';
import ArrowRoundDownSmallLined from './ArrowRoundDownSmallLined';
import ArrowRoundLeftMediumFilled from './ArrowRoundLeftMediumFilled';
import ArrowRoundLeftMediumLined from './ArrowRoundLeftMediumLined';
import ArrowRoundLeftSmallFilled from './ArrowRoundLeftSmallFilled';
import ArrowRoundLeftSmallLined from './ArrowRoundLeftSmallLined';
import ArrowRoundRightMediumFilled from './ArrowRoundRightMediumFilled';
import ArrowRoundRightMediumLined from './ArrowRoundRightMediumLined';
import ArrowRoundRightSmallFilled from './ArrowRoundRightSmallFilled';
import ArrowRoundRightSmallLined from './ArrowRoundRightSmallLined';
import ArrowRoundUpMediumFilled from './ArrowRoundUpMediumFilled';
import ArrowRoundUpMediumLined from './ArrowRoundUpMediumLined';
import ArrowRoundUpSmallFilled from './ArrowRoundUpSmallFilled';
import ArrowRoundUpSmallLined from './ArrowRoundUpSmallLined';
import ArrowUpMedium from './ArrowUpMedium';
import ArrowUpSmall from './ArrowUpSmall';
import AttachmentMediumLined from './AttachmentMediumLined';
import AudienceBasedEntryPoint from './AudienceBasedEntryPoint';
import AudienceBasedEntryPointFilled from './AudienceBasedEntryPointFilled';
import BackupMediumLined from './BackupMediumLined';
import BillingSmallLined from './BillingSmallLined';
import BookmarkMediumLined from './BookmarkMediumLined';
import BookmarkSmallLined from './BookmarkSmallLined';
import BoxSmallLined from './BoxSmallLined';
import BulletCircleFilled from './BulletCircleFilled';
import BulletCircleLined from './BulletCircleLined';
import BulletSquareFilled from './BulletSquareFilled';
import BulletSquareLined from './BulletSquareLined';
import BulletTriangleFilled from './BulletTriangleFilled';
import BulletTriangleLined from './BulletTriangleLined';
import CalendarMediumLined from './CalendarMediumLined';
import Canvas from './Canvas';
import CertificateMediumLined from './CertificateMediumLined';
import ChangeSmallLined from './ChangeSmallLined';
import CheckMedium from './CheckMedium';
import CheckRoundMediumFilled from './CheckRoundMediumFilled';
import CheckRoundMediumLined from './CheckRoundMediumLined';
import CheckRoundSmallFilled from './CheckRoundSmallFilled';
import CheckRoundSmallLined from './CheckRoundSmallLined';
import CheckSmall from './CheckSmall';
import ChevroRightMedium from './ChevroRightMedium';
import ChevronDouble from './ChevronDouble';
import ChevronDoubleReverse from './ChevronDoubleReverse';
import ChevronDownMedium from './ChevronDownMedium';
import ChevronDownSmall from './ChevronDownSmall';
import ChevronLeftMedium from './ChevronLeftMedium';
import ChevronLeftSmall from './ChevronLeftSmall';
import ChevronRightSmall from './ChevronRightSmall';
import ChevronUpMedium from './ChevronUpMedium';
import ChevronUpSmall from './ChevronUpSmall';
import CloseMedium from './CloseMedium';
import CloseRoundMediumFilled from './CloseRoundMediumFilled';
import CloseRoundMediumLined from './CloseRoundMediumLined';
import CloseRoundSmallFilled from './CloseRoundSmallFilled';
import CloseRoundSmallLined from './CloseRoundSmallLined';
import CloseSmall from './CloseSmall';
import ColorMediumLined from './ColorMediumLined';
import CommentCanvas from './CommentCanvas';
import CommentMediumFilled from './CommentMediumFilled';
import CommentMediumLined from './CommentMediumLined';
import CopyMediumFilled from './CopyMediumFilled';
import CopyMediumLined from './CopyMediumLined';
import CopySmallLined from './CopySmallLined';
import DangerTriangleMediumFilled from './DangerTriangleMediumFilled';
import DangerTriangleMediumLined from './DangerTriangleMediumLined';
import DangerTriangleSmallFilled from './DangerTriangleSmallFilled';
import DangerTriangleSmallLined from './DangerTriangleSmallLined';
import Delay from './Delay';
import DocsMediumLined from './DocsMediumLined';
import DocumentMediumLined from './DocumentMediumLined';
import DocumentSmallLined from './DocumentSmallLined';
import DownloadMediumFilled from './DownloadMediumFilled';
import DownloadMediumLined from './DownloadMediumLined';
import DragMedium from './DragMedium';
import DropdownDown from './DropdownDown';
import DropdownLeft from './DropdownLeft';
import DropdownRight from './DropdownRight';
import DropdownUp from './DropdownUp';
import EditMediumLined from './EditMediumLined';
import EditSmallLined from './EditSmallLined';
import Email from './Email';
import EmailMediumFilled from './EmailMediumFilled';
import EmailMediumLined from './EmailMediumLined';
import EmailSmallFilled from './EmailSmallFilled';
import EmailSmallLined from './EmailSmallLined';
import EmojiMediumLined from './EmojiMediumLined';
import ExitPoint from './ExitPoint';
import ExitPointFilled from './ExitPointFilled';
import FilterMediumLined from './FilterMediumLined';
import FilterSmallLined from './FilterSmallLined';
import FinishMediumFilled from './FinishMediumFilled';
import FinishMediumLined from './FinishMediumLined';
import FinishSmallLined from './FinishSmallLined';
import FlashMediumFilled from './FlashMediumFilled';
import FlashMediumLined from './FlashMediumLined';
import FlashSmallFilled from './FlashSmallFilled';
import FolderMediumFilled from './FolderMediumFilled';
import FolderMediumLined from './FolderMediumLined';
import FolderSmallFilled from './FolderSmallFilled';
import FolderSmallLined from './FolderSmallLined';
import Funnel from './Funnel';
import FunnelMediumLined from './FunnelMediumLined';
import Goal from './Goal';
import HelpRoundMediumFilled from './HelpRoundMediumFilled';
import HelpRoundMediumLined from './HelpRoundMediumLined';
import HelpRoundSmallFilled from './HelpRoundSmallFilled';
import HelpRoundSmallLined from './HelpRoundSmallLined';
import HomeSmallLined from './HomeSmallLined';
import InApp from './InApp';
import InAppLarge from './InAppLarge';
import InfinityMediumLined from './InfinityMediumLined';
import InfoRoundMediumFilled from './InfoRoundMediumFilled';
import InfoRoundMediumLined from './InfoRoundMediumLined';
import InfoRoundSmallFilled from './InfoRoundSmallFilled';
import InfoRoundSmallLined from './InfoRoundSmallLined';
import JourneyMediumLined from './JourneyMediumLined';
import JourneySmallLined from './JourneySmallLined';
import KebabMedium from './KebabMedium';
import LanguageMediumLined from './LanguageMediumLined';
import LanguageSmallLined from './LanguageSmallLined';
import LimitMediumLined from './LimitMediumLined';
import LinkMediumLined from './LinkMediumLined';
import LinkSmallLined from './LinkSmallLined';
import ListMediumLined from './ListMediumLined';
import ListSmallLined from './ListSmallLined';
import LockMediumLined from './LockMediumLined';
import LockSmallFilled from './LockSmallFilled';
import LockSmallLined from './LockSmallLined';
import Locked from './Locked';
import LoginMediumLined from './LoginMediumLined';
import LogoutMediumLined from './LogoutMediumLined';
import LogoutSmallLined from './LogoutSmallLined';
import MenuMedium from './MenuMedium';
import MinusMedium from './MinusMedium';
import MinusRoundMediumFilled from './MinusRoundMediumFilled';
import MinusRoundMediumLined from './MinusRoundMediumLined';
import MinusRoundSmallFilled from './MinusRoundSmallFilled';
import MinusRoundSmallLined from './MinusRoundSmallLined';
import MinusSmall from './MinusSmall';
import MoreMedium from './MoreMedium';
import NotificationDisabledMediumLined from './NotificationDisabledMediumLined';
import NotificationEnabledMediumLined from './NotificationEnabledMediumLined';
import OpenMediumFilled from './OpenMediumFilled';
import OpenMediumLined from './OpenMediumLined';
import OpenSmallLined from './OpenSmallLined';
import PauseRoundMediumLined from './PauseRoundMediumLined';
import PlaceMediumLined from './PlaceMediumLined';
import PlaceSmallFilled from './PlaceSmallFilled';
import PlaceSmallLined from './PlaceSmallLined';
import PlatformLogoAmazonMediumFilled from './PlatformLogoAmazonMediumFilled';
import PlatformLogoAndroidMediumFilled from './PlatformLogoAndroidMediumFilled';
import PlatformLogoAppleMediumFilled from './PlatformLogoAppleMediumFilled';
import PlatformLogoBaiduMediumFilled from './PlatformLogoBaiduMediumFilled';
import PlatformLogoChromeMediumFilled from './PlatformLogoChromeMediumFilled';
import PlatformLogoEmailMediumFilled from './PlatformLogoEmailMediumFilled';
import PlatformLogoFirefoxMediumFilled from './PlatformLogoFirefoxMediumFilled';
import PlatformLogoHuaweiMediumFilled from './PlatformLogoHuaweiMediumFilled';
import PlatformLogoMacOsMediumFilled from './PlatformLogoMacOsMediumFilled';
import PlatformLogoSafariMediumFilled from './PlatformLogoSafariMediumFilled';
import PlatformLogoWindowsMediumFilled from './PlatformLogoWindowsMediumFilled';
import PlatformLogoiExplorerMediumFilled from './PlatformLogoiExplorerMediumFilled';
import PlayRoundMediumFilled from './PlayRoundMediumFilled';
import PlayRoundMediumLined from './PlayRoundMediumLined';
import PlusMedium from './PlusMedium';
import PlusRoundMediumFilled from './PlusRoundMediumFilled';
import PlusRoundMediumLined from './PlusRoundMediumLined';
import PlusRoundSmallFilled from './PlusRoundSmallFilled';
import PlusSmall from './PlusSmall';
import PremiumDisabled from './PremiumDisabled';
import PremiumEnabled from './PremiumEnabled';
import PremiumMono from './PremiumMono';
import ProfileMediumLined from './ProfileMediumLined';
import PulseMediumLined from './PulseMediumLined';
import PulseSmallLined from './PulseSmallLined';
import Push from './Push';
import PushMediumLined from './PushMediumLined';
import PushSmallFilled from './PushSmallFilled';
import PushSmallLined from './PushSmallLined';
import ReachabilityCheckLarge from './ReachabilityCheckLarge';
import ReachabilityCheckPoint from './ReachabilityCheckPoint';
import ReachabilityCheckPointFilled from './ReachabilityCheckPointFilled';
import RefreshMediumFilled from './RefreshMediumFilled';
import RefreshMediumLined from './RefreshMediumLined';
import RefreshSmallFilled from './RefreshSmallFilled';
import ReloadMediumLined from './ReloadMediumLined';
import RewardMediumLined from './RewardMediumLined';
import SearchMediumLined from './SearchMediumLined';
import SearchSmallLined from './SearchSmallLined';
import SecureSmallLined from './SecureSmallLined';
import SegmentSplitPoint from './SegmentSplitPoint';
import SegmentSplitPointFilled from './SegmentSplitPointFilled';
import SendEmailPoint from './SendEmailPoint';
import SendEmailPointFilled from './SendEmailPointFilled';
import SendInAppPoint from './SendInAppPoint';
import SendInAppPointFilled from './SendInAppPointFilled';
import SendMediumLined from './SendMediumLined';
import SendPushPoint from './SendPushPoint';
import SendPushPointFilled from './SendPushPointFilled';
import SendSmsPointFilled from './SendSmsPointFilled';
import Separator from './Separator';
import SetTagPoint from './SetTagPoint';
import SetTagPointFilled from './SetTagPointFilled';
import SettingsMediumFilled from './SettingsMediumFilled';
import SettingsMediumLined from './SettingsMediumLined';
import SettingsSmallFilled from './SettingsSmallFilled';
import SettingsSmallLined from './SettingsSmallLined';
import SocialLogoFacebookMediumFilled from './SocialLogoFacebookMediumFilled';
import SocialLogoGoogleMediumFilled from './SocialLogoGoogleMediumFilled';
import SocialLogoLinkedinMediumFilled from './SocialLogoLinkedinMediumFilled';
import SocialLogoTwitterMediumFilled from './SocialLogoTwitterMediumFilled';
import SorDescMedium from './SorDescMedium';
import SortAscMedium from './SortAscMedium';
import SortAscSmall from './SortAscSmall';
import SortDescSmall from './SortDescSmall';
import SortNotSortedMedium from './SortNotSortedMedium';
import SortNotSortedSmall from './SortNotSortedSmall';
import Spinner from './Spinner';
import StopSmallFilled from './StopSmallFilled';
import SuperUserMediumFilled from './SuperUserMediumFilled';
import SuperUserMediumLined from './SuperUserMediumLined';
import SuperUserSmallFilled from './SuperUserSmallFilled';
import Tag from './Tag';
import TagMediumLined from './TagMediumLined';
import TagSmallLined from './TagSmallLined';
import TilesMediumLined from './TilesMediumLined';
import TimeDelayPoint from './TimeDelayPoint';
import TimeDelayPointFilled from './TimeDelayPointFilled';
import TimeMediumLined from './TimeMediumLined';
import TimeSmallFilled from './TimeSmallFilled';
import TimeSmallLined from './TimeSmallLined';
import TrashMediumFilled from './TrashMediumFilled';
import TrashMediumLined from './TrashMediumLined';
import TrashSmallFilled from './TrashSmallFilled';
import TrashSmallLined from './TrashSmallLined';
import TriggerBasedEntryPoint from './TriggerBasedEntryPoint';
import TriggerBasedEntryPointFilled from './TriggerBasedEntryPointFilled';
import TrophyMediumLined from './TrophyMediumLined';
import TrophySmallLined from './TrophySmallLined';
import TrueFalse from './TrueFalse';
import TutorialMediumLined from './TutorialMediumLined';
import Union from './Union';
import UploadMediumFilled from './UploadMediumFilled';
import UploadMediumLined from './UploadMediumLined';
import UserMediumFilled from './UserMediumFilled';
import UserMediumLined from './UserMediumLined';
import UserSmallFilled from './UserSmallFilled';
import UserSmallLined from './UserSmallLined';
import VisibilityInvisibleMediumLined from './VisibilityInvisibleMediumLined';
import VisibilityVisibleMediumLined from './VisibilityVisibleMediumLined';
import VisibilityVisibleSmallLined from './VisibilityVisibleSmallLined';
import WaitForTriggerPoint from './WaitForTriggerPoint';
import WaitForTriggerPointFilled from './WaitForTriggerPointFilled';
import WarningRoundMediumFilled from './WarningRoundMediumFilled';
import WarningRoundMediumLined from './WarningRoundMediumLined';
import WarningRoundSmallFilled from './WarningRoundSmallFilled';
import WarningRoundSmallLined from './WarningRoundSmallLined';
import WebhookPoint from './WebhookPoint';
import WebhookPointFilled from './WebhookPointFilled';
import WebhooksLarge from './WebhooksLarge';
import ZoomZoomInMediumLined from './ZoomZoomInMediumLined';
import ZoomZoomOutMediumLined from './ZoomZoomOutMediumLined';
import ApiEntryFilled from './ApiEntryFilled';

import { IProps } from '../Icon.types';

import { IconWrapper } from '../styled';

export enum IconEnum {
  AB_SPLIT_POINT = 'ab_split_point',
  AB_SPLITTER = 'ab-splitter',
  AB_TEST = 'ab_test',
  ADD_NOTES_POINT_FILLED = 'add_notes_point_filled',
  ARROW_DOWN_MEDIUM = 'arrow_down_medium',
  ARROW_DOWN_SMALL = 'arrow_down_small',
  ARROW_LEFT_MEDIUM = 'arrow_left_medium',
  ARROW_LEFT_SMALL = 'arrow_left_small',
  ARROW_RIGHT_MEDIUM = 'arrow_right_medium',
  ARROW_RIGHT_SMALL = 'arrow_right_small',
  ARROW_ROUND_DOWN_MEDIUM_FILLED = 'arrow_round_down_medium_filled',
  ARROW_ROUND_DOWN_MEDIUM_LINED = 'arrow_round_down_medium_lined',
  ARROW_ROUND_DOWN_SMALL_FILLED = 'arrow_round_down_small_filled',
  ARROW_ROUND_DOWN_SMALL_LINED = 'arrow_round_down_small_lined',
  ARROW_ROUND_LEFT_MEDIUM_FILLED = 'arrow_round_left_medium_filled',
  ARROW_ROUND_LEFT_MEDIUM_LINED = 'arrow_round_left_medium_lined',
  ARROW_ROUND_LEFT_SMALL_FILLED = 'arrow_round_left_small_filled',
  ARROW_ROUND_LEFT_SMALL_LINED = 'arrow_round_left_small_lined',
  ARROW_ROUND_RIGHT_MEDIUM_FILLED = 'arrow_round_right_medium_filled',
  ARROW_ROUND_RIGHT_MEDIUM_LINED = 'arrow_round_right_medium_lined',
  ARROW_ROUND_RIGHT_SMALL_FILLED = 'arrow_round_right_small_filled',
  ARROW_ROUND_RIGHT_SMALL_LINED = 'arrow_round_right_small_lined',
  ARROW_ROUND_UP_MEDIUM_FILLED = 'arrow_round_up_medium_filled',
  ARROW_ROUND_UP_MEDIUM_LINED = 'arrow_round_up_medium_lined',
  ARROW_ROUND_UP_SMALL_FILLED = 'arrow_round_up_small_filled',
  ARROW_ROUND_UP_SMALL_LINED = 'arrow_round_up_small_lined',
  ARROW_UP_MEDIUM = 'arrow_up_medium',
  ARROW_UP_SMALL = 'arrow_up_small',
  ATTACHMENT_MEDIUM_LINED = 'attachment_medium_lined',
  AUDIENCE_BASED_ENTRY_POINT = 'audience_based_entry_point',
  SEGMENT = 'start-by-segment',
  BACKUP_MEDIUM_LINED = 'backup_medium_lined',
  BILLING_SMALL_LINED = 'billing_small_lined',
  BOOKMARK_MEDIUM_LINED = 'bookmark_medium_lined',
  BOOKMARK_SMALL_LINED = 'bookmark_small_lined',
  BOX_SMALL_LINED = 'box_small_lined',
  BULLET_CIRCLE_FILLED = 'bullet_circle_filled',
  BULLET_CIRCLE_LINED = 'bullet_circle_lined',
  BULLET_SQUARE_FILLED = 'bullet_square_filled',
  BULLET_SQUARE_LINED = 'bullet_square_lined',
  BULLET_TRIANGLE_FILLED = 'bullet_triangle_filled',
  BULLET_TRIANGLE_LINED = 'bullet_triangle_lined',
  CALENDAR_MEDIUM_LINED = 'calendar_medium_lined',
  CANVAS = 'canvas',
  CERTIFICATE_MEDIUM_LINED = 'certificate_medium_lined',
  CHANGE_SMALL_LINED = 'change_small_lined',
  CHECK_MEDIUM = 'check_medium',
  CHECK_ROUND_MEDIUM_FILLED = 'check_round_medium_filled',
  CHECK_ROUND_MEDIUM_LINED = 'check_round_medium_lined',
  CHECK_ROUND_SMALL_FILLED = 'check_round_small_filled',
  CHECK_ROUND_SMALL_LINED = 'check_round_small_lined',
  CHECK_SMALL = 'check_small',
  CHEVRO_RIGHT_MEDIUM = 'chevro_right_medium',
  CHEVRON_DOUBLE = 'chevron_double',
  CHEVRON_DOUBLE_REVERSE = 'chevron_double_reverse',
  CHEVRON_DOWN_MEDIUM = 'chevron_down_medium',
  CHEVRON_DOWN_SMALL = 'chevron_down_small',
  CHEVRON_LEFT_MEDIUM = 'chevron_left_medium',
  CHEVRON_LEFT_SMALL = 'chevron_left_small',
  CHEVRON_RIGHT_SMALL = 'chevron_right_small',
  CHEVRON_UP_MEDIUM = 'chevron_up_medium',
  CHEVRON_UP_SMALL = 'chevron_up_small',
  CLOSE_MEDIUM = 'close_medium',
  CLOSE_ROUND_MEDIUM_FILLED = 'close_round_medium_filled',
  CLOSE_ROUND_MEDIUM_LINED = 'close_round_medium_lined',
  CLOSE_ROUND_SMALL_FILLED = 'close_round_small_filled',
  CLOSE_ROUND_SMALL_LINED = 'close_round_small_lined',
  CLOSE_SMALL = 'close_small',
  COLOR_MEDIUM_LINED = 'color_medium_lined',
  COMMENT_CANVAS = 'comment_canvas',
  COMMENT_MEDIUM_FILLED = 'comment_medium_filled',
  COMMENT_MEDIUM_LINED = 'comment_medium_lined',
  COPY_MEDIUM_FILLED = 'copy_medium_filled',
  COPY_MEDIUM_LINED = 'copy_medium_lined',
  COPY_SMALL_LINED = 'copy_small_lined',
  DANGER_TRIANGLE_MEDIUM_FILLED = 'danger_triangle_medium_filled',
  DANGER_TRIANGLE_MEDIUM_LINED = 'danger_triangle_medium_lined',
  DANGER_TRIANGLE_SMALL_FILLED = 'danger_triangle_small_filled',
  DANGER_TRIANGLE_SMALL_LINED = 'danger_triangle_small_lined',
  DELAY = 'delay',
  DOCS_MEDIUM_LINED = 'docs_medium_lined',
  DOCUMENT_MEDIUM_LINED = 'document_medium_lined',
  DOCUMENT_SMALL_LINED = 'document_small_lined',
  DOWNLOAD_MEDIUM_FILLED = 'download_medium_filled',
  DOWNLOAD_MEDIUM_LINED = 'download_medium_lined',
  DRAG_MEDIUM = 'drag_medium',
  DROPDOWN_DOWN = 'dropdown_down',
  DROPDOWN_LEFT = 'dropdown_left',
  DROPDOWN_RIGHT = 'dropdown_right',
  DROPDOWN_UP = 'dropdown_up',
  EDIT_MEDIUM_LINED = 'edit_medium_lined',
  EDIT_SMALL_LINED = 'edit_small_lined',
  EMAIL = 'email',
  EMAIL_MEDIUM_FILLED = 'email_medium_filled',
  EMAIL_MEDIUM_LINED = 'email_medium_lined',
  EMAIL_SMALL_FILLED = 'email_small_filled',
  EMAIL_SMALL_LINED = 'email_small_lined',
  EMOJI_MEDIUM_LINED = 'emoji_medium_lined',
  EXIT_POINT = 'exit_point',
  EXIT = 'exit',
  FILTER_MEDIUM_LINED = 'filter_medium_lined',
  FILTER_SMALL_LINED = 'filter_small_lined',
  FINISH_MEDIUM_FILLED = 'finish_medium_filled',
  FINISH_MEDIUM_LINED = 'finish_medium_lined',
  FINISH_SMALL_LINED = 'finish_small_lined',
  FLASH_MEDIUM_FILLED = 'flash_medium_filled',
  FLASH_MEDIUM_LINED = 'flash_medium_lined',
  FLASH_SMALL_FILLED = 'flash_small_filled',
  FOLDER_MEDIUM_FILLED = 'folder_medium_filled',
  FOLDER_MEDIUM_LINED = 'folder_medium_lined',
  FOLDER_SMALL_FILLED = 'folder_small_filled',
  FOLDER_SMALL_LINED = 'folder_small_lined',
  FUNNEL = 'funnel',
  FUNNEL_MEDIUM_LINED = 'funnel_medium_lined',
  GOAL_EVENT = 'goal-event',
  HELP_ROUND_MEDIUM_FILLED = 'help_round_medium_filled',
  HELP_ROUND_MEDIUM_LINED = 'help_round_medium_lined',
  HELP_ROUND_SMALL_FILLED = 'help_round_small_filled',
  HELP_ROUND_SMALL_LINED = 'help_round_small_lined',
  HOME_SMALL_LINED = 'home_small_lined',
  IN_APP = 'in_app',
  IN_APP_LARGE = 'in_app_large',
  INFINITY_MEDIUM_LINED = 'infinity_medium_lined',
  INFO_ROUND_MEDIUM_FILLED = 'info_round_medium_filled',
  INFO_ROUND_MEDIUM_LINED = 'info_round_medium_lined',
  INFO_ROUND_SMALL_FILLED = 'info_round_small_filled',
  INFO_ROUND_SMALL_LINED = 'info_round_small_lined',
  JOURNEY_MEDIUM_LINED = 'journey_medium_lined',
  JOURNEY_SMALL_LINED = 'journey_small_lined',
  KEBAB_MEDIUM = 'kebab_medium',
  LANGUAGE_MEDIUM_LINED = 'language_medium_lined',
  LANGUAGE_SMALL_LINED = 'language_small_lined',
  LIMIT_MEDIUM_LINED = 'limit_medium_lined',
  LINK_MEDIUM_LINED = 'link_medium_lined',
  LINK_SMALL_LINED = 'link_small_lined',
  LIST_MEDIUM_LINED = 'list_medium_lined',
  LIST_SMALL_LINED = 'list_small_lined',
  LOCK_MEDIUM_LINED = 'lock_medium_lined',
  LOCK_SMALL_FILLED = 'lock_small_filled',
  LOCK_SMALL_LINED = 'lock_small_lined',
  LOCKED = 'locked',
  LOGIN_MEDIUM_LINED = 'login_medium_lined',
  LOGOUT_MEDIUM_LINED = 'logout_medium_lined',
  LOGOUT_SMALL_LINED = 'logout_small_lined',
  MENU_MEDIUM = 'menu_medium',
  MINUS_MEDIUM = 'minus_medium',
  MINUS_ROUND_MEDIUM_FILLED = 'minus_round_medium_filled',
  MINUS_ROUND_MEDIUM_LINED = 'minus_round_medium_lined',
  MINUS_ROUND_SMALL_FILLED = 'minus_round_small_filled',
  MINUS_ROUND_SMALL_LINED = 'minus_round_small_lined',
  MINUS_SMALL = 'minus_small',
  MORE_MEDIUM = 'more_medium',
  NOTIFICATION_DISABLED_MEDIUM_LINED = 'notification_disabled_medium_lined',
  NOTIFICATION_ENABLED_MEDIUM_LINED = 'notification_enabled_medium_lined',
  OPEN_MEDIUM_FILLED = 'open_medium_filled',
  OPEN_MEDIUM_LINED = 'open_medium_lined',
  OPEN_SMALL_LINED = 'open_small_lined',
  PAUSE_ROUND_MEDIUM_LINED = 'pause_round_medium_lined',
  PLACE_MEDIUM_LINED = 'place_medium_lined',
  PLACE_SMALL_FILLED = 'place_small_filled',
  PLACE_SMALL_LINED = 'place_small_lined',
  PLATFORM_LOGO_AMAZON_MEDIUM_FILLED = 'platform_logo_amazon_medium_filled',
  PLATFORM_LOGO_ANDROID_MEDIUM_FILLED = 'platform_logo_android_medium_filled',
  PLATFORM_LOGO_APPLE_MEDIUM_FILLED = 'platform_logo_apple_medium_filled',
  PLATFORM_LOGO_BAIDU_MEDIUM_FILLED = 'platform_logo_baidu_medium_filled',
  PLATFORM_LOGO_CHROME_MEDIUM_FILLED = 'platform_logo_chrome_medium_filled',
  PLATFORM_LOGO_EMAIL_MEDIUM_FILLED = 'platform_logo_email_medium_filled',
  PLATFORM_LOGO_FIREFOX_MEDIUM_FILLED = 'platform_logo_firefox_medium_filled',
  PLATFORM_LOGO_HUAWEI_MEDIUM_FILLED = 'platform_logo_huawei_medium_filled',
  PLATFORM_LOGO_MAC_OS_MEDIUM_FILLED = 'platform_logo_mac_os_medium_filled',
  PLATFORM_LOGO_SAFARI_MEDIUM_FILLED = 'platform_logo_safari_medium_filled',
  PLATFORM_LOGO_WINDOWS_MEDIUM_FILLED = 'platform_logo_windows_medium_filled',
  PLATFORM_LOGOI_EXPLORER_MEDIUM_FILLED = 'platform_logoi_explorer_medium_filled',
  PLAY_ROUND_MEDIUM_FILLED = 'play_round_medium_filled',
  PLAY_ROUND_MEDIUM_LINED = 'play_round_medium_lined',
  PLUS_MEDIUM = 'plus_medium',
  PLUS_ROUND_MEDIUM_FILLED = 'plus_round_medium_filled',
  PLUS_ROUND_MEDIUM_LINED = 'plus_round_medium_lined',
  PLUS_ROUND_SMALL_FILLED = 'plus_round_small_filled',
  PLUS_SMALL = 'plus_small',
  PREMIUM_DISABLED = 'premium_disabled',
  PREMIUM_ENABLED = 'premium_enabled',
  PREMIUM_MONO = 'premium_mono',
  PROFILE_MEDIUM_LINED = 'profile_medium_lined',
  PULSE_MEDIUM_LINED = 'pulse_medium_lined',
  PULSE_SMALL_LINED = 'pulse_small_lined',
  PUSH = 'push',
  PUSH_MEDIUM_LINED = 'push_medium_lined',
  PUSH_SMALL_FILLED = 'push_small_filled',
  PUSH_SMALL_LINED = 'push_small_lined',
  REACHABILITY_CHECK_LARGE = 'reachability_check_large',
  REACHABILITY_CHECK_POINT = 'reachability_check_point',
  REACHABILITY_CHECK = 'filter',
  REFRESH_MEDIUM_FILLED = 'refresh_medium_filled',
  REFRESH_MEDIUM_LINED = 'refresh_medium_lined',
  REFRESH_SMALL_FILLED = 'refresh_small_filled',
  RELOAD_MEDIUM_LINED = 'reload_medium_lined',
  REWARD_MEDIUM_LINED = 'reward_medium_lined',
  SEARCH_MEDIUM_LINED = 'search_medium_lined',
  SEARCH_SMALL_LINED = 'search_small_lined',
  SECURE_SMALL_LINED = 'secure_small_lined',
  SEGMENT_SPLIT_POINT = 'segment_split_point',
  BOOLEAN_SPLITTER = 'boolean-splitter',
  SEND_EMAIL_POINT = 'send_email_point',
  SEND_EMAIL = 'send-email',
  SEND_IN_APP_POINT = 'send_in_app_point',
  SEND_IN_APP = 'inapp',
  SEND_MEDIUM_LINED = 'send_medium_lined',
  SEND_PUSH_POINT = 'send_push_point',
  SEND_PUSH = 'send-push',
  SEND_SMS = 'send-sms',
  SEPARATOR = 'separator',
  SET_TAG_POINT = 'set_tag_point',
  SET_TAGS = 'set-tags',
  SETTINGS_MEDIUM_FILLED = 'settings_medium_filled',
  SETTINGS_MEDIUM_LINED = 'settings_medium_lined',
  SETTINGS_SMALL_FILLED = 'settings_small_filled',
  SETTINGS_SMALL_LINED = 'settings_small_lined',
  SOCIAL_LOGO_FACEBOOK_MEDIUM_FILLED = 'social_logo_facebook_medium_filled',
  SOCIAL_LOGO_GOOGLE_MEDIUM_FILLED = 'social_logo_google_medium_filled',
  SOCIAL_LOGO_LINKEDIN_MEDIUM_FILLED = 'social_logo_linkedin_medium_filled',
  SOCIAL_LOGO_TWITTER_MEDIUM_FILLED = 'social_logo_twitter_medium_filled',
  SOR_DESC_MEDIUM = 'sor_desc_medium',
  SORT_ASC_MEDIUM = 'sort_asc_medium',
  SORT_ASC_SMALL = 'sort_asc_small',
  SORT_DESC_SMALL = 'sort_desc_small',
  SORT_NOT_SORTED_MEDIUM = 'sort_not_sorted_medium',
  SORT_NOT_SORTED_SMALL = 'sort_not_sorted_small',
  SPINNER = 'spinner',
  STOP_SMALL_FILLED = 'stop_small_filled',
  SUPER_USER_MEDIUM_FILLED = 'super_user_medium_filled',
  SUPER_USER_MEDIUM_LINED = 'super_user_medium_lined',
  SUPER_USER_SMALL_FILLED = 'super_user_small_filled',
  TAG = 'tag',
  TAG_MEDIUM_LINED = 'tag_medium_lined',
  TAG_SMALL_LINED = 'tag_small_lined',
  TILES_MEDIUM_LINED = 'tiles_medium_lined',
  TIME_DELAY_POINT = 'time_delay_point',
  WAIT = 'wait',
  TIME_MEDIUM_LINED = 'time_medium_lined',
  TIME_SMALL_FILLED = 'time_small_filled',
  TIME_SMALL_LINED = 'time_small_lined',
  TRASH_MEDIUM_FILLED = 'trash_medium_filled',
  TRASH_MEDIUM_LINED = 'trash_medium_lined',
  TRASH_SMALL_FILLED = 'trash_small_filled',
  TRASH_SMALL_LINED = 'trash_small_lined',
  TRIGGER_BASED_ENTRY_POINT = 'trigger_based_entry_point',
  EVENT = 'event',
  TROPHY_MEDIUM_LINED = 'trophy_medium_lined',
  TROPHY_SMALL_LINED = 'trophy_small_lined',
  TRUE_FALSE = 'true_false',
  TUTORIAL_MEDIUM_LINED = 'tutorial_medium_lined',
  UNION = 'union',
  UPLOAD_MEDIUM_FILLED = 'upload_medium_filled',
  UPLOAD_MEDIUM_LINED = 'upload_medium_lined',
  USER_MEDIUM_FILLED = 'user_medium_filled',
  USER_MEDIUM_LINED = 'user_medium_lined',
  USER_SMALL_FILLED = 'user_small_filled',
  USER_SMALL_LINED = 'user_small_lined',
  VISIBILITY_INVISIBLE_MEDIUM_LINED = 'visibility_invisible_medium_lined',
  VISIBILITY_VISIBLE_MEDIUM_LINED = 'visibility_visible_medium_lined',
  VISIBILITY_VISIBLE_SMALL_LINED = 'visibility_visible_small_lined',
  WAIT_FOR_TRIGGER_POINT = 'wait_for_trigger_point',
  WAIT_EVENT = 'wait-event',
  WARNING_ROUND_MEDIUM_FILLED = 'warning_round_medium_filled',
  WARNING_ROUND_MEDIUM_LINED = 'warning_round_medium_lined',
  WARNING_ROUND_SMALL_FILLED = 'warning_round_small_filled',
  WARNING_ROUND_SMALL_LINED = 'warning_round_small_lined',
  WEBHOOK_POINT = 'webhook_point',
  WEBHOOK = 'webhook',
  WEBHOOKS_LARGE = 'webhooks_large',
  ZOOM_ZOOM_IN_MEDIUM_LINED = 'zoom_zoom_in_medium_lined',
  ZOOM_ZOOM_OUT_MEDIUM_LINED = 'zoom_zoom_out_medium_lined',
  API = 'start-by-api'
}

export interface IIconProps extends IProps {
  type: IconEnum;
}

const ICON_COMPONENT_DICT: Record<IconEnum, React.ComponentType<IProps>> = {
  [IconEnum.AB_SPLIT_POINT]: AbSplitPoint,
  [IconEnum.AB_SPLITTER]: AbSplitPointFilled,
  [IconEnum.AB_TEST]: AbTest,
  [IconEnum.ADD_NOTES_POINT_FILLED]: AddNotesPointFilled,
  [IconEnum.ARROW_DOWN_MEDIUM]: ArrowDownMedium,
  [IconEnum.ARROW_DOWN_SMALL]: ArrowDownSmall,
  [IconEnum.ARROW_LEFT_MEDIUM]: ArrowLeftMedium,
  [IconEnum.ARROW_LEFT_SMALL]: ArrowLeftSmall,
  [IconEnum.ARROW_RIGHT_MEDIUM]: ArrowRightMedium,
  [IconEnum.ARROW_RIGHT_SMALL]: ArrowRightSmall,
  [IconEnum.ARROW_ROUND_DOWN_MEDIUM_FILLED]: ArrowRoundDownMediumFilled,
  [IconEnum.ARROW_ROUND_DOWN_MEDIUM_LINED]: ArrowRoundDownMediumLined,
  [IconEnum.ARROW_ROUND_DOWN_SMALL_FILLED]: ArrowRoundDownSmallFilled,
  [IconEnum.ARROW_ROUND_DOWN_SMALL_LINED]: ArrowRoundDownSmallLined,
  [IconEnum.ARROW_ROUND_LEFT_MEDIUM_FILLED]: ArrowRoundLeftMediumFilled,
  [IconEnum.ARROW_ROUND_LEFT_MEDIUM_LINED]: ArrowRoundLeftMediumLined,
  [IconEnum.ARROW_ROUND_LEFT_SMALL_FILLED]: ArrowRoundLeftSmallFilled,
  [IconEnum.ARROW_ROUND_LEFT_SMALL_LINED]: ArrowRoundLeftSmallLined,
  [IconEnum.ARROW_ROUND_RIGHT_MEDIUM_FILLED]: ArrowRoundRightMediumFilled,
  [IconEnum.ARROW_ROUND_RIGHT_MEDIUM_LINED]: ArrowRoundRightMediumLined,
  [IconEnum.ARROW_ROUND_RIGHT_SMALL_FILLED]: ArrowRoundRightSmallFilled,
  [IconEnum.ARROW_ROUND_RIGHT_SMALL_LINED]: ArrowRoundRightSmallLined,
  [IconEnum.ARROW_ROUND_UP_MEDIUM_FILLED]: ArrowRoundUpMediumFilled,
  [IconEnum.ARROW_ROUND_UP_MEDIUM_LINED]: ArrowRoundUpMediumLined,
  [IconEnum.ARROW_ROUND_UP_SMALL_FILLED]: ArrowRoundUpSmallFilled,
  [IconEnum.ARROW_ROUND_UP_SMALL_LINED]: ArrowRoundUpSmallLined,
  [IconEnum.ARROW_UP_MEDIUM]: ArrowUpMedium,
  [IconEnum.ARROW_UP_SMALL]: ArrowUpSmall,
  [IconEnum.ATTACHMENT_MEDIUM_LINED]: AttachmentMediumLined,
  [IconEnum.AUDIENCE_BASED_ENTRY_POINT]: AudienceBasedEntryPoint,
  [IconEnum.SEGMENT]: AudienceBasedEntryPointFilled,
  [IconEnum.BACKUP_MEDIUM_LINED]: BackupMediumLined,
  [IconEnum.BILLING_SMALL_LINED]: BillingSmallLined,
  [IconEnum.BOOKMARK_MEDIUM_LINED]: BookmarkMediumLined,
  [IconEnum.BOOKMARK_SMALL_LINED]: BookmarkSmallLined,
  [IconEnum.BOX_SMALL_LINED]: BoxSmallLined,
  [IconEnum.BULLET_CIRCLE_FILLED]: BulletCircleFilled,
  [IconEnum.BULLET_CIRCLE_LINED]: BulletCircleLined,
  [IconEnum.BULLET_SQUARE_FILLED]: BulletSquareFilled,
  [IconEnum.BULLET_SQUARE_LINED]: BulletSquareLined,
  [IconEnum.BULLET_TRIANGLE_FILLED]: BulletTriangleFilled,
  [IconEnum.BULLET_TRIANGLE_LINED]: BulletTriangleLined,
  [IconEnum.CALENDAR_MEDIUM_LINED]: CalendarMediumLined,
  [IconEnum.CANVAS]: Canvas,
  [IconEnum.CERTIFICATE_MEDIUM_LINED]: CertificateMediumLined,
  [IconEnum.CHANGE_SMALL_LINED]: ChangeSmallLined,
  [IconEnum.CHECK_MEDIUM]: CheckMedium,
  [IconEnum.CHECK_ROUND_MEDIUM_FILLED]: CheckRoundMediumFilled,
  [IconEnum.CHECK_ROUND_MEDIUM_LINED]: CheckRoundMediumLined,
  [IconEnum.CHECK_ROUND_SMALL_FILLED]: CheckRoundSmallFilled,
  [IconEnum.CHECK_ROUND_SMALL_LINED]: CheckRoundSmallLined,
  [IconEnum.CHECK_SMALL]: CheckSmall,
  [IconEnum.CHEVRO_RIGHT_MEDIUM]: ChevroRightMedium,
  [IconEnum.CHEVRON_DOUBLE]: ChevronDouble,
  [IconEnum.CHEVRON_DOUBLE_REVERSE]: ChevronDoubleReverse,
  [IconEnum.CHEVRON_DOWN_MEDIUM]: ChevronDownMedium,
  [IconEnum.CHEVRON_DOWN_SMALL]: ChevronDownSmall,
  [IconEnum.CHEVRON_LEFT_MEDIUM]: ChevronLeftMedium,
  [IconEnum.CHEVRON_LEFT_SMALL]: ChevronLeftSmall,
  [IconEnum.CHEVRON_RIGHT_SMALL]: ChevronRightSmall,
  [IconEnum.CHEVRON_UP_MEDIUM]: ChevronUpMedium,
  [IconEnum.CHEVRON_UP_SMALL]: ChevronUpSmall,
  [IconEnum.CLOSE_MEDIUM]: CloseMedium,
  [IconEnum.CLOSE_ROUND_MEDIUM_FILLED]: CloseRoundMediumFilled,
  [IconEnum.CLOSE_ROUND_MEDIUM_LINED]: CloseRoundMediumLined,
  [IconEnum.CLOSE_ROUND_SMALL_FILLED]: CloseRoundSmallFilled,
  [IconEnum.CLOSE_ROUND_SMALL_LINED]: CloseRoundSmallLined,
  [IconEnum.CLOSE_SMALL]: CloseSmall,
  [IconEnum.COLOR_MEDIUM_LINED]: ColorMediumLined,
  [IconEnum.COMMENT_CANVAS]: CommentCanvas,
  [IconEnum.COMMENT_MEDIUM_FILLED]: CommentMediumFilled,
  [IconEnum.COMMENT_MEDIUM_LINED]: CommentMediumLined,
  [IconEnum.COPY_MEDIUM_FILLED]: CopyMediumFilled,
  [IconEnum.COPY_MEDIUM_LINED]: CopyMediumLined,
  [IconEnum.COPY_SMALL_LINED]: CopySmallLined,
  [IconEnum.DANGER_TRIANGLE_MEDIUM_FILLED]: DangerTriangleMediumFilled,
  [IconEnum.DANGER_TRIANGLE_MEDIUM_LINED]: DangerTriangleMediumLined,
  [IconEnum.DANGER_TRIANGLE_SMALL_FILLED]: DangerTriangleSmallFilled,
  [IconEnum.DANGER_TRIANGLE_SMALL_LINED]: DangerTriangleSmallLined,
  [IconEnum.DELAY]: Delay,
  [IconEnum.DOCS_MEDIUM_LINED]: DocsMediumLined,
  [IconEnum.DOCUMENT_MEDIUM_LINED]: DocumentMediumLined,
  [IconEnum.DOCUMENT_SMALL_LINED]: DocumentSmallLined,
  [IconEnum.DOWNLOAD_MEDIUM_FILLED]: DownloadMediumFilled,
  [IconEnum.DOWNLOAD_MEDIUM_LINED]: DownloadMediumLined,
  [IconEnum.DRAG_MEDIUM]: DragMedium,
  [IconEnum.DROPDOWN_DOWN]: DropdownDown,
  [IconEnum.DROPDOWN_LEFT]: DropdownLeft,
  [IconEnum.DROPDOWN_RIGHT]: DropdownRight,
  [IconEnum.DROPDOWN_UP]: DropdownUp,
  [IconEnum.EDIT_MEDIUM_LINED]: EditMediumLined,
  [IconEnum.EDIT_SMALL_LINED]: EditSmallLined,
  [IconEnum.EMAIL]: Email,
  [IconEnum.EMAIL_MEDIUM_FILLED]: EmailMediumFilled,
  [IconEnum.EMAIL_MEDIUM_LINED]: EmailMediumLined,
  [IconEnum.EMAIL_SMALL_FILLED]: EmailSmallFilled,
  [IconEnum.EMAIL_SMALL_LINED]: EmailSmallLined,
  [IconEnum.EMOJI_MEDIUM_LINED]: EmojiMediumLined,
  [IconEnum.EXIT_POINT]: ExitPoint,
  [IconEnum.EXIT]: ExitPointFilled,
  [IconEnum.FILTER_MEDIUM_LINED]: FilterMediumLined,
  [IconEnum.FILTER_SMALL_LINED]: FilterSmallLined,
  [IconEnum.FINISH_MEDIUM_FILLED]: FinishMediumFilled,
  [IconEnum.FINISH_MEDIUM_LINED]: FinishMediumLined,
  [IconEnum.FINISH_SMALL_LINED]: FinishSmallLined,
  [IconEnum.FLASH_MEDIUM_FILLED]: FlashMediumFilled,
  [IconEnum.FLASH_MEDIUM_LINED]: FlashMediumLined,
  [IconEnum.FLASH_SMALL_FILLED]: FlashSmallFilled,
  [IconEnum.FOLDER_MEDIUM_FILLED]: FolderMediumFilled,
  [IconEnum.FOLDER_MEDIUM_LINED]: FolderMediumLined,
  [IconEnum.FOLDER_SMALL_FILLED]: FolderSmallFilled,
  [IconEnum.FOLDER_SMALL_LINED]: FolderSmallLined,
  [IconEnum.FUNNEL]: Funnel,
  [IconEnum.FUNNEL_MEDIUM_LINED]: FunnelMediumLined,
  [IconEnum.GOAL_EVENT]: Goal,
  [IconEnum.HELP_ROUND_MEDIUM_FILLED]: HelpRoundMediumFilled,
  [IconEnum.HELP_ROUND_MEDIUM_LINED]: HelpRoundMediumLined,
  [IconEnum.HELP_ROUND_SMALL_FILLED]: HelpRoundSmallFilled,
  [IconEnum.HELP_ROUND_SMALL_LINED]: HelpRoundSmallLined,
  [IconEnum.HOME_SMALL_LINED]: HomeSmallLined,
  [IconEnum.IN_APP]: InApp,
  [IconEnum.IN_APP_LARGE]: InAppLarge,
  [IconEnum.INFINITY_MEDIUM_LINED]: InfinityMediumLined,
  [IconEnum.INFO_ROUND_MEDIUM_FILLED]: InfoRoundMediumFilled,
  [IconEnum.INFO_ROUND_MEDIUM_LINED]: InfoRoundMediumLined,
  [IconEnum.INFO_ROUND_SMALL_FILLED]: InfoRoundSmallFilled,
  [IconEnum.INFO_ROUND_SMALL_LINED]: InfoRoundSmallLined,
  [IconEnum.JOURNEY_MEDIUM_LINED]: JourneyMediumLined,
  [IconEnum.JOURNEY_SMALL_LINED]: JourneySmallLined,
  [IconEnum.KEBAB_MEDIUM]: KebabMedium,
  [IconEnum.LANGUAGE_MEDIUM_LINED]: LanguageMediumLined,
  [IconEnum.LANGUAGE_SMALL_LINED]: LanguageSmallLined,
  [IconEnum.LIMIT_MEDIUM_LINED]: LimitMediumLined,
  [IconEnum.LINK_MEDIUM_LINED]: LinkMediumLined,
  [IconEnum.LINK_SMALL_LINED]: LinkSmallLined,
  [IconEnum.LIST_MEDIUM_LINED]: ListMediumLined,
  [IconEnum.LIST_SMALL_LINED]: ListSmallLined,
  [IconEnum.LOCK_MEDIUM_LINED]: LockMediumLined,
  [IconEnum.LOCK_SMALL_FILLED]: LockSmallFilled,
  [IconEnum.LOCK_SMALL_LINED]: LockSmallLined,
  [IconEnum.LOCKED]: Locked,
  [IconEnum.LOGIN_MEDIUM_LINED]: LoginMediumLined,
  [IconEnum.LOGOUT_MEDIUM_LINED]: LogoutMediumLined,
  [IconEnum.LOGOUT_SMALL_LINED]: LogoutSmallLined,
  [IconEnum.MENU_MEDIUM]: MenuMedium,
  [IconEnum.MINUS_MEDIUM]: MinusMedium,
  [IconEnum.MINUS_ROUND_MEDIUM_FILLED]: MinusRoundMediumFilled,
  [IconEnum.MINUS_ROUND_MEDIUM_LINED]: MinusRoundMediumLined,
  [IconEnum.MINUS_ROUND_SMALL_FILLED]: MinusRoundSmallFilled,
  [IconEnum.MINUS_ROUND_SMALL_LINED]: MinusRoundSmallLined,
  [IconEnum.MINUS_SMALL]: MinusSmall,
  [IconEnum.MORE_MEDIUM]: MoreMedium,
  [IconEnum.NOTIFICATION_DISABLED_MEDIUM_LINED]: NotificationDisabledMediumLined,
  [IconEnum.NOTIFICATION_ENABLED_MEDIUM_LINED]: NotificationEnabledMediumLined,
  [IconEnum.OPEN_MEDIUM_FILLED]: OpenMediumFilled,
  [IconEnum.OPEN_MEDIUM_LINED]: OpenMediumLined,
  [IconEnum.OPEN_SMALL_LINED]: OpenSmallLined,
  [IconEnum.PAUSE_ROUND_MEDIUM_LINED]: PauseRoundMediumLined,
  [IconEnum.PLACE_MEDIUM_LINED]: PlaceMediumLined,
  [IconEnum.PLACE_SMALL_FILLED]: PlaceSmallFilled,
  [IconEnum.PLACE_SMALL_LINED]: PlaceSmallLined,
  [IconEnum.PLATFORM_LOGO_AMAZON_MEDIUM_FILLED]: PlatformLogoAmazonMediumFilled,
  [IconEnum.PLATFORM_LOGO_ANDROID_MEDIUM_FILLED]: PlatformLogoAndroidMediumFilled,
  [IconEnum.PLATFORM_LOGO_APPLE_MEDIUM_FILLED]: PlatformLogoAppleMediumFilled,
  [IconEnum.PLATFORM_LOGO_BAIDU_MEDIUM_FILLED]: PlatformLogoBaiduMediumFilled,
  [IconEnum.PLATFORM_LOGO_CHROME_MEDIUM_FILLED]: PlatformLogoChromeMediumFilled,
  [IconEnum.PLATFORM_LOGO_EMAIL_MEDIUM_FILLED]: PlatformLogoEmailMediumFilled,
  [IconEnum.PLATFORM_LOGO_FIREFOX_MEDIUM_FILLED]: PlatformLogoFirefoxMediumFilled,
  [IconEnum.PLATFORM_LOGO_HUAWEI_MEDIUM_FILLED]: PlatformLogoHuaweiMediumFilled,
  [IconEnum.PLATFORM_LOGO_MAC_OS_MEDIUM_FILLED]: PlatformLogoMacOsMediumFilled,
  [IconEnum.PLATFORM_LOGO_SAFARI_MEDIUM_FILLED]: PlatformLogoSafariMediumFilled,
  [IconEnum.PLATFORM_LOGO_WINDOWS_MEDIUM_FILLED]: PlatformLogoWindowsMediumFilled,
  [IconEnum.PLATFORM_LOGOI_EXPLORER_MEDIUM_FILLED]: PlatformLogoiExplorerMediumFilled,
  [IconEnum.PLAY_ROUND_MEDIUM_FILLED]: PlayRoundMediumFilled,
  [IconEnum.PLAY_ROUND_MEDIUM_LINED]: PlayRoundMediumLined,
  [IconEnum.PLUS_MEDIUM]: PlusMedium,
  [IconEnum.PLUS_ROUND_MEDIUM_FILLED]: PlusRoundMediumFilled,
  [IconEnum.PLUS_ROUND_MEDIUM_LINED]: PlusRoundMediumLined,
  [IconEnum.PLUS_ROUND_SMALL_FILLED]: PlusRoundSmallFilled,
  [IconEnum.PLUS_SMALL]: PlusSmall,
  [IconEnum.PREMIUM_DISABLED]: PremiumDisabled,
  [IconEnum.PREMIUM_ENABLED]: PremiumEnabled,
  [IconEnum.PREMIUM_MONO]: PremiumMono,
  [IconEnum.PROFILE_MEDIUM_LINED]: ProfileMediumLined,
  [IconEnum.PULSE_MEDIUM_LINED]: PulseMediumLined,
  [IconEnum.PULSE_SMALL_LINED]: PulseSmallLined,
  [IconEnum.PUSH]: Push,
  [IconEnum.PUSH_MEDIUM_LINED]: PushMediumLined,
  [IconEnum.PUSH_SMALL_FILLED]: PushSmallFilled,
  [IconEnum.PUSH_SMALL_LINED]: PushSmallLined,
  [IconEnum.REACHABILITY_CHECK_LARGE]: ReachabilityCheckLarge,
  [IconEnum.REACHABILITY_CHECK_POINT]: ReachabilityCheckPoint,
  [IconEnum.REACHABILITY_CHECK]: ReachabilityCheckPointFilled,
  [IconEnum.REFRESH_MEDIUM_FILLED]: RefreshMediumFilled,
  [IconEnum.REFRESH_MEDIUM_LINED]: RefreshMediumLined,
  [IconEnum.REFRESH_SMALL_FILLED]: RefreshSmallFilled,
  [IconEnum.RELOAD_MEDIUM_LINED]: ReloadMediumLined,
  [IconEnum.REWARD_MEDIUM_LINED]: RewardMediumLined,
  [IconEnum.SEARCH_MEDIUM_LINED]: SearchMediumLined,
  [IconEnum.SEARCH_SMALL_LINED]: SearchSmallLined,
  [IconEnum.SECURE_SMALL_LINED]: SecureSmallLined,
  [IconEnum.SEGMENT_SPLIT_POINT]: SegmentSplitPoint,
  [IconEnum.BOOLEAN_SPLITTER]: SegmentSplitPointFilled,
  [IconEnum.SEND_EMAIL_POINT]: SendEmailPoint,
  [IconEnum.SEND_EMAIL]: SendEmailPointFilled,
  [IconEnum.SEND_IN_APP_POINT]: SendInAppPoint,
  [IconEnum.SEND_IN_APP]: SendInAppPointFilled,
  [IconEnum.SEND_MEDIUM_LINED]: SendMediumLined,
  [IconEnum.SEND_PUSH_POINT]: SendPushPoint,
  [IconEnum.SEND_PUSH]: SendPushPointFilled,
  [IconEnum.SEND_SMS]: SendSmsPointFilled,
  [IconEnum.SEPARATOR]: Separator,
  [IconEnum.SET_TAG_POINT]: SetTagPoint,
  [IconEnum.SET_TAGS]: SetTagPointFilled,
  [IconEnum.SETTINGS_MEDIUM_FILLED]: SettingsMediumFilled,
  [IconEnum.SETTINGS_MEDIUM_LINED]: SettingsMediumLined,
  [IconEnum.SETTINGS_SMALL_FILLED]: SettingsSmallFilled,
  [IconEnum.SETTINGS_SMALL_LINED]: SettingsSmallLined,
  [IconEnum.SOCIAL_LOGO_FACEBOOK_MEDIUM_FILLED]: SocialLogoFacebookMediumFilled,
  [IconEnum.SOCIAL_LOGO_GOOGLE_MEDIUM_FILLED]: SocialLogoGoogleMediumFilled,
  [IconEnum.SOCIAL_LOGO_LINKEDIN_MEDIUM_FILLED]: SocialLogoLinkedinMediumFilled,
  [IconEnum.SOCIAL_LOGO_TWITTER_MEDIUM_FILLED]: SocialLogoTwitterMediumFilled,
  [IconEnum.SOR_DESC_MEDIUM]: SorDescMedium,
  [IconEnum.SORT_ASC_MEDIUM]: SortAscMedium,
  [IconEnum.SORT_ASC_SMALL]: SortAscSmall,
  [IconEnum.SORT_DESC_SMALL]: SortDescSmall,
  [IconEnum.SORT_NOT_SORTED_MEDIUM]: SortNotSortedMedium,
  [IconEnum.SORT_NOT_SORTED_SMALL]: SortNotSortedSmall,
  [IconEnum.SPINNER]: Spinner,
  [IconEnum.STOP_SMALL_FILLED]: StopSmallFilled,
  [IconEnum.SUPER_USER_MEDIUM_FILLED]: SuperUserMediumFilled,
  [IconEnum.SUPER_USER_MEDIUM_LINED]: SuperUserMediumLined,
  [IconEnum.SUPER_USER_SMALL_FILLED]: SuperUserSmallFilled,
  [IconEnum.TAG]: Tag,
  [IconEnum.TAG_MEDIUM_LINED]: TagMediumLined,
  [IconEnum.TAG_SMALL_LINED]: TagSmallLined,
  [IconEnum.TILES_MEDIUM_LINED]: TilesMediumLined,
  [IconEnum.TIME_DELAY_POINT]: TimeDelayPoint,
  [IconEnum.WAIT]: TimeDelayPointFilled,
  [IconEnum.TIME_MEDIUM_LINED]: TimeMediumLined,
  [IconEnum.TIME_SMALL_FILLED]: TimeSmallFilled,
  [IconEnum.TIME_SMALL_LINED]: TimeSmallLined,
  [IconEnum.TRASH_MEDIUM_FILLED]: TrashMediumFilled,
  [IconEnum.TRASH_MEDIUM_LINED]: TrashMediumLined,
  [IconEnum.TRASH_SMALL_FILLED]: TrashSmallFilled,
  [IconEnum.TRASH_SMALL_LINED]: TrashSmallLined,
  [IconEnum.TRIGGER_BASED_ENTRY_POINT]: TriggerBasedEntryPoint,
  [IconEnum.EVENT]: TriggerBasedEntryPointFilled,
  [IconEnum.TROPHY_MEDIUM_LINED]: TrophyMediumLined,
  [IconEnum.TROPHY_SMALL_LINED]: TrophySmallLined,
  [IconEnum.TRUE_FALSE]: TrueFalse,
  [IconEnum.TUTORIAL_MEDIUM_LINED]: TutorialMediumLined,
  [IconEnum.UNION]: Union,
  [IconEnum.UPLOAD_MEDIUM_FILLED]: UploadMediumFilled,
  [IconEnum.UPLOAD_MEDIUM_LINED]: UploadMediumLined,
  [IconEnum.USER_MEDIUM_FILLED]: UserMediumFilled,
  [IconEnum.USER_MEDIUM_LINED]: UserMediumLined,
  [IconEnum.USER_SMALL_FILLED]: UserSmallFilled,
  [IconEnum.USER_SMALL_LINED]: UserSmallLined,
  [IconEnum.VISIBILITY_INVISIBLE_MEDIUM_LINED]: VisibilityInvisibleMediumLined,
  [IconEnum.VISIBILITY_VISIBLE_MEDIUM_LINED]: VisibilityVisibleMediumLined,
  [IconEnum.VISIBILITY_VISIBLE_SMALL_LINED]: VisibilityVisibleSmallLined,
  [IconEnum.WAIT_FOR_TRIGGER_POINT]: WaitForTriggerPoint,
  [IconEnum.WAIT_EVENT]: WaitForTriggerPointFilled,
  [IconEnum.WARNING_ROUND_MEDIUM_FILLED]: WarningRoundMediumFilled,
  [IconEnum.WARNING_ROUND_MEDIUM_LINED]: WarningRoundMediumLined,
  [IconEnum.WARNING_ROUND_SMALL_FILLED]: WarningRoundSmallFilled,
  [IconEnum.WARNING_ROUND_SMALL_LINED]: WarningRoundSmallLined,
  [IconEnum.WEBHOOK_POINT]: WebhookPoint,
  [IconEnum.WEBHOOK]: WebhookPointFilled,
  [IconEnum.WEBHOOKS_LARGE]: WebhooksLarge,
  [IconEnum.ZOOM_ZOOM_IN_MEDIUM_LINED]: ZoomZoomInMediumLined,
  [IconEnum.ZOOM_ZOOM_OUT_MEDIUM_LINED]: ZoomZoomOutMediumLined,
  [IconEnum.API]: ApiEntryFilled
};

export function Icon(props: IIconProps): JSX.Element {
  const { className, type, fill, rectFill, ...rest } = props;

  const IconComponent = ICON_COMPONENT_DICT[type];

  return (
    <IconWrapper rectFill={rectFill} fill={rectFill ? fill : null}>
      <IconComponent className={className} fill={fill} {...rest} />
    </IconWrapper>
  );
}
