import { lastDayOfMonth, differenceInDays, add, isSameDay, isSameMonth } from 'date-fns';
import { WeekType, DayType } from '../Datepicker.types';

export const completWeeks = (weeks: WeekType[], cursorDate: Date): WeekType[] => {
  const lastDayInMonth = lastDayOfMonth(cursorDate);
  const lastWeek = weeks[weeks.length - 1];
  const lastDay = lastWeek.value[lastWeek.value.length - 1].value;
  const diffDays = differenceInDays(lastDayInMonth, lastDay);

  if (diffDays < 1) return weeks;

  const additionalWeek: WeekType = {
    key: `${lastWeek.key}1`,
    value: []
  };

  for (let i = 1; i <= 7; i++) {
    const currentDate = add(lastDay, { days: i });
    const day: DayType = {
      date: currentDate.getDate(),
      isCurrentDate: isSameDay(new Date(), currentDate),
      isCurrentMonth: isSameMonth(cursorDate, currentDate),
      key: `days-${currentDate.getDay()}`,
      value: currentDate
    };

    additionalWeek.value.push(day);
  }

  const completedWeeks = [...weeks, additionalWeek];

  return completedWeeks;
};
