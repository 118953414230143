import * as React from 'react';

// Enums
import {
  CHANNEL_GROUP,
  FLOW_GROUP,
  PointType,
  PREMIUM_POINTS_TYPE
} from '@src/common/constants/point';
import { Color } from '@pushwoosh/kit-constants';

// Components
import { Icon, IconEnum } from '@src/ui/kit/Icon';

// Hooks
import { useClickOutside } from '@src/ui/hooks/useClickOutside';
import { useStoreActions } from '@src/store/store';

// Helpers
import { filterPointsByGroup, getPointIconColorByType, getPointNameByType } from '@src/ui/helpers';

// Styled
import {
  StyledEmptyList,
  StyledIcon,
  StyledInput,
  StyledItem,
  StyledName,
  StyledPointsWrapper,
  StyledTitle,
  StyledWrapper
} from '@src/canvas/components/PopupMenu/styled';

// Types
import { IVector2 } from '@src/common/types/types';
import { getPremiumFeatureAvailableByType } from '@src/ui/helpers/getPremiumFeatureAvailableByType';
import { useRestrictions } from '@src/store/hooks';
import { IProps } from './PopupMenu.types';

const HEADER_HEIGHT = 60;

export function PopupMenu(props: IProps): JSX.Element {
  const { isVisible, menuCoords, pointerCoords, pointList, onCreatePoint, onClickOutside } = props;

  const [filter, setFilter] = React.useState('');
  const [filteredPoints, setFilteredPoints] = React.useState(pointList);
  const [hoveredPointId, setHoveredPointId] = React.useState(null);

  const menuRef = useClickOutside(onClickOutside);
  const setMenuPosition = useStoreActions((actions) => actions.popupMenu.setMenuPosition);

  const position: IVector2 = React.useMemo(
    () => ({
      x: pointerCoords.x,
      y: pointerCoords.y
    }),
    [pointerCoords]
  );

  const flow = filterPointsByGroup(filteredPoints, FLOW_GROUP);
  const channel = filterPointsByGroup(filteredPoints, CHANNEL_GROUP);

  const restrictions = useRestrictions();

  const channelPoints: JSX.Element[] = React.useMemo(
    (): JSX.Element[] =>
      channel.map((point): JSX.Element => {
        const isPremium = PREMIUM_POINTS_TYPE.has(point.type);
        const isPremiumAvailable = getPremiumFeatureAvailableByType(point.type, restrictions);

        let isDisabled = false;

        if (point.type === PointType.SEND_SMS) {
          isDisabled = !restrictions.allowCjSMS;
        }

        const handleItemClick = (): void => {
          if ((!isPremium || isPremiumAvailable) && !isDisabled) {
            onCreatePoint({
              type: point.type,
              position
            });
          }
        };

        return (
          <StyledItem
            key={point.id}
            onClick={handleItemClick}
            onMouseEnter={() => setHoveredPointId(point.id)}
            onMouseLeave={() => setHoveredPointId(null)}
          >
            <Icon
              width={24}
              height={24}
              type={point.type.toString() as IconEnum}
              fill={getPointIconColorByType(point.type)}
              rectFill={Color.EXCEPTIONAL_LIGHT}
            />
            <StyledName>{getPointNameByType(point.type)}</StyledName>
            {isPremium && !isPremiumAvailable && (
              <Icon
                type={
                  point.id === hoveredPointId ? IconEnum.PREMIUM_ENABLED : IconEnum.PREMIUM_DISABLED
                }
              />
            )}
            {isDisabled && <Icon type={IconEnum.LOCK_SMALL_FILLED} fill={Color.LOCKED} />}
          </StyledItem>
        );
      }),
    [channel, onCreatePoint, position, restrictions]
  );

  const flowPoints: JSX.Element[] = React.useMemo(
    (): JSX.Element[] =>
      flow.map((point): JSX.Element => {
        const isPremium = PREMIUM_POINTS_TYPE.has(point.type);
        const isPremiumAvailable = getPremiumFeatureAvailableByType(point.type, restrictions);

        const handleItemClick = (): void => {
          if (!isPremium || isPremiumAvailable) {
            onCreatePoint({
              type: point.type,
              position
            });
          }
        };

        return (
          <StyledItem
            key={point.id}
            onClick={handleItemClick}
            onMouseEnter={() => setHoveredPointId(point.id)}
            onMouseLeave={() => setHoveredPointId(null)}
          >
            <Icon
              width={24}
              height={24}
              type={point.type.toString() as IconEnum}
              fill={getPointIconColorByType(point.type)}
              rectFill={Color.BRIGHT_LIGHT}
            />
            <StyledName>{getPointNameByType(point.type)}</StyledName>
            {isPremium && !isPremiumAvailable && (
              <Icon
                type={
                  point.id === hoveredPointId ? IconEnum.PREMIUM_ENABLED : IconEnum.PREMIUM_DISABLED
                }
              />
            )}
          </StyledItem>
        );
      }),
    [flow, onCreatePoint, position]
  );

  React.useEffect(() => {
    if (!menuRef.current) return;

    const menuBottomPosition = menuRef.current.offsetTop + menuRef.current.offsetHeight;
    const viewportHeight = window.innerHeight - HEADER_HEIGHT;

    if (menuBottomPosition > viewportHeight) {
      const menuOffset = menuBottomPosition - viewportHeight;

      setMenuPosition({ x: menuCoords.x, y: menuCoords.y - menuOffset });
    }
  }, [menuRef, menuCoords, setMenuPosition]);

  React.useEffect(() => {
    if (!isVisible) {
      setFilter('');
      setFilteredPoints(pointList);
    }
  }, [isVisible, pointList]);

  if (!isVisible) {
    return null;
  }

  const handleFilterChange = (name: string, value: string) => {
    setFilter(value);
    if (value !== '') {
      setFilteredPoints(
        pointList.filter((point) =>
          getPointNameByType(point.type).toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setFilteredPoints(pointList);
    }
  };

  return (
    <StyledWrapper
      left={menuCoords.x}
      top={menuCoords.y - 24}
      ref={menuRef as React.RefObject<HTMLDivElement>}
    >
      <div>
        <StyledIcon
          type={IconEnum.SEARCH_MEDIUM_LINED}
          fill={Color.BRIGHT}
          width={24}
          height={24}
        />
        <StyledInput
          autocomplete="off"
          placeholder="Search..."
          name="filter"
          autoFocus
          value={filter}
          onChange={handleFilterChange}
        />
      </div>
      <StyledPointsWrapper>
        <div>
          {channelPoints.length > 0 && <StyledTitle>Channel</StyledTitle>}

          {channelPoints}
        </div>
        <div>
          {flowPoints.length > 0 && <StyledTitle>Flow</StyledTitle>}

          {flowPoints}
        </div>
        {channelPoints.length === 0 && flowPoints.length === 0 && (
          <StyledEmptyList>No match found</StyledEmptyList>
        )}
      </StyledPointsWrapper>
    </StyledWrapper>
  );
}
