import styled from 'styled-components';
import { Spacing, Color, ShapeRadius, FontSize } from '@pushwoosh/kit-constants';

export const OrWrap = styled.div`
  position: relative;
  padding: ${Spacing.S2} ${Spacing.S3};
  background: ${Color.CLEAR};
  border-radius: ${ShapeRadius.CONTROL};
  font-size: ${FontSize.SMALL};
  color: ${Color.LOCKED};
  margin: ${Spacing.S1} 0;
  z-index: 1;
`;
