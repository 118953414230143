import React from 'react';
import { StyledButton, ButtonField, ButtonWrap } from './styled';

type Props = {
  deactivateJourney: () => void;
};

export function StopButton(props: Props): JSX.Element {
  const { deactivateJourney } = props;

  return (
    <ButtonWrap>
      <StyledButton onClick={deactivateJourney}>
        <ButtonField>Stop campaign</ButtonField>
      </StyledButton>
    </ButtonWrap>
  );
}
