// Constants
import { RepeatFrequency } from '@src/common/constants/repeatSegment';
import { TypeTag } from '@src/common/constants/tag';

// Types
import { validateFormEvent } from '@src/ui/features/EditEvents/helpers/validateFormEvent';
import { countErrors } from '@src/common/helpers/formikErrors';
import { TABSplitterPoint, TPoints } from '../types/points';
import { RepeatSegment } from '../types/repeatSegment';
import { PointType } from '../constants/point';
import { TApplication } from '../types/application';
import { TFilter } from '../types/filter';
import { TPreset } from '../types/preset';
import { TEvent } from '../types/event';
import { UpdateTag } from '../types/tag';

const BLOCKS_TO_BE_CONFIGURED = new Set([
  PointType.EVENT,
  PointType.API,
  PointType.SEGMENT,
  PointType.SEND_PUSH,
  PointType.SEND_EMAIL,
  PointType.SEND_SMS,
  PointType.BOOLEAN_SPLITTER,
  PointType.AB_SPLITTER,
  PointType.SET_TAGS,
  PointType.WAIT_EVENT,
  PointType.SEND_IN_APP,
  PointType.WEBHOOK
]);

function isAppSelected(data: { applicationCode?: TApplication['code'] }): boolean {
  return Boolean(data.applicationCode);
}

function isFilterSelected(data: { filterCode?: TFilter['code'] }): boolean {
  return Boolean(data.filterCode);
}

function isSumSplitEqualOneHundred(data: { split: TABSplitterPoint['data']['split'] }): boolean {
  const sum = data.split.reduce((acc, item) => acc + item, 0);
  return Boolean(sum === 100);
}

function isPresetSelected(data: { presetCode?: TPreset['code'] }): boolean {
  return Boolean(data.presetCode);
}

const isExistNameTags = (tags: UpdateTag[]): boolean =>
  Boolean(tags.find((item) => Boolean(item.name)));

function isEventSelected(data: { name?: TEvent['name'] }): boolean {
  return Boolean(data.name);
}

function isDatesCorrect(repeat: RepeatSegment): boolean {
  return repeat?.dates?.filter((item) => item === '').length === 0 && repeat?.dates?.length > 0;
}

export function isConfiguredPoint(point: TPoints): boolean {
  if (!BLOCKS_TO_BE_CONFIGURED.has(point.type)) {
    return true;
  }

  function checkRepeat(repeat: RepeatSegment): boolean {
    const needCheckDates = repeat?.frequency === RepeatFrequency.DATES;
    if (repeat?.enabled) {
      return needCheckDates ? isDatesCorrect(repeat) : true;
    }
    return true;
  }

  switch (point.type) {
    case PointType.EVENT: {
      return isAppSelected(point.data) && isEventSelected(point.data);
    }

    case PointType.SEGMENT: {
      return (
        checkRepeat(point.data?.repeat) && isAppSelected(point.data) && isFilterSelected(point.data)
      );
    }

    case PointType.API: {
      // TODO: add check for API
      return true;
    }

    case PointType.BOOLEAN_SPLITTER: {
      return isFilterSelected(point.data);
    }

    case PointType.AB_SPLITTER: {
      return isSumSplitEqualOneHundred(point.data);
    }

    case PointType.SET_TAGS: {
      const DynamicTagsNotFill = point.data.tags.filter((item) => {
        if (
          item.type === TypeTag.DYNAMIC &&
          typeof item.value === 'object' &&
          !Array.isArray(item.value)
        ) {
          const { value } = item;
          if (value.pointId === '') {
            return true;
          }
          if (value.eventName === '') {
            return true;
          }
          if (value.attribute === '') {
            return true;
          }
          return false;
        }
        return false;
      });

      return (
        DynamicTagsNotFill.length === 0 &&
        point.data.tags.length !== 0 &&
        isAppSelected(point.data) &&
        isExistNameTags(point.data.tags)
      );
    }

    case PointType.SEND_PUSH: {
      const isFilledContent = Boolean(point.data.content);
      const isFilledTitle = Boolean(point.data.name || point.data.title);
      const isCustom = isFilledContent && isFilledTitle;
      const isEnabledPersonalise = point.data.personalise?.enabled;
      const isPersonaliseCorrect = isEnabledPersonalise
        ? point.data.personalise?.placeholders?.length > 0
        : true;

      return (
        isPersonaliseCorrect &&
        isAppSelected(point.data) &&
        (isPresetSelected(point.data) || isCustom)
      );
    }

    case PointType.SEND_EMAIL: {
      const isEnabledPersonalise = point.data.personalise?.enabled;
      const isPersonaliseCorrect = isEnabledPersonalise
        ? point.data.personalise?.placeholders?.length > 0
        : true;
      return isPersonaliseCorrect && isAppSelected(point.data) && isPresetSelected(point.data);
    }

    case PointType.SEND_SMS: {
      const isCustom = Boolean(point.data.content);
      const isEnabledPersonalise = point.data.personalise?.enabled;
      const isPersonaliseCorrect = isEnabledPersonalise
        ? point.data.personalise?.placeholders?.length > 0
        : true;

      return (
        isPersonaliseCorrect &&
        isAppSelected(point.data) &&
        (isPresetSelected(point.data) || isCustom)
      );
    }

    case PointType.WAIT_EVENT: {
      if (!Array.isArray(point.data.waitEvents)) {
        return false;
      }

      const isEmptyWaitEvents = point.data.waitEvents.length < 1;
      const isFilledWaitEvents = point.data.waitEvents.every(
        (waitEvent): boolean => isAppSelected(waitEvent) && isEventSelected(waitEvent)
      );

      const hasEventErrors = !!point.data.waitEvents.find((we) => {
        const errors = validateFormEvent({
          isThrowOut: false,
          eventCode: we.name,
          eventConditions: we.eventConditions
        });

        return countErrors(errors) > 0;
      });

      return !isEmptyWaitEvents && isFilledWaitEvents && !hasEventErrors;
    }

    case PointType.SEND_IN_APP: {
      const isRichMedia = Boolean(point.data.richMediaCode);

      return isAppSelected(point.data) && isRichMedia;
    }

    case PointType.WEBHOOK: {
      return !!point.data.url;
    }

    default: {
      return false;
    }
  }
}
