// Types
import { IWebhookFormValues } from '@src/ui/features/Webhook/Webhook.types';

export const resetDynamicValues = (
  obj: IWebhookFormValues['dynamicValues']
): IWebhookFormValues['dynamicValues'] => {
  const keys = Object.keys(obj);
  keys.forEach((key) => {
    obj[key] = undefined;
  });

  return obj;
};
