import React from 'react';
import { TypeTag } from '@src/common/constants/tag';
import { useAllPoints, useGetEventsByApplicationCode } from '@src/store/hooks';
import { matchTagToAttribute } from '@src/ui/features/SetTags/helpers/matchTagToAttribute';
import { usePointsWithEvent } from '@src/ui/hooks/usePointsWithEvent';
import { PointType } from '@src/common/constants';
import { TApiPoint } from '@src/common/types/points';

export function useDynamicEvents(applicationCode: string) {
  const pointsWithEvent = usePointsWithEvent();
  const points = useAllPoints();

  const getEventsByApp = useGetEventsByApplicationCode();

  const getAttributeOptions = React.useCallback(
    (value: string, options?: { dateOnly?: boolean; withMathTag?: boolean; tagType?: TypeTag }) => {
      const attributes: any[] = [];
      const events = getEventsByApp(applicationCode);

      events.forEach((item) => {
        if (item.attributes && item.name === value) {
          if (options && options.dateOnly) {
            item.attributes
              .filter((attr) => attr.type === 4)
              .forEach((attr) => {
                attributes.push({
                  label: attr.name,
                  value: attr.name
                });
              });
          } else if (options && options.withMathTag) {
            item.attributes
              .filter((attr) => matchTagToAttribute(options.tagType, attr.type))
              .forEach((attr) => {
                attributes.push({
                  label: attr.name,
                  value: attr.name
                });
              });
          } else {
            item.attributes.forEach((attr) => {
              attributes.push({
                label: attr.name,
                value: attr.name
              });
            });
          }
        }
      });

      const apiEntryPoint = points.find((p) => p.type === PointType.API) as TApiPoint | undefined;

      if (apiEntryPoint) {
        apiEntryPoint.data?.attributes?.forEach((attr) => {
          attributes.push({
            label: attr,
            value: attr
          });
        });
      }

      return attributes;
    },
    [getEventsByApp, applicationCode, points]
  );

  const eventNameOptions = React.useMemo(
    () =>
      pointsWithEvent.map((item) => ({
        label: item.label,
        value: String(item.value)
      })),
    [pointsWithEvent]
  );

  const getAttributesList = React.useCallback(
    (
      eventName: string,
      options?: { dateOnly?: boolean; withMathTag?: boolean; tagType?: TypeTag }
    ) => getAttributeOptions(eventName, options),
    [getAttributeOptions]
  );

  const getCurrentEvent = React.useCallback(
    (name: string, pointID: string) =>
      pointsWithEvent.find((event: any) => event.value[1] === name && event.value[0] === pointID),
    [pointsWithEvent]
  );

  const getEventValue = React.useCallback((value: string) => {
    const splittedValue = value.split(',');
    const eventName = splittedValue[1];
    const pointID = splittedValue[0];
    const applicationCode = splittedValue[2];

    return {
      eventName,
      pointID,
      applicationCode
    };
  }, []);

  return {
    eventNameOptions,
    getAttributesList,
    getCurrentEvent,
    getEventValue
  };
}
