import { useCallback } from 'react';

// Store
import { useStoreActions } from '@src/store/store';

// Hooks
import { useSetMode } from '@src/store/hooks';

// Enums
import { Modes } from '@src/common/constants';
import { IPointOutput } from '@src/common/types/points';

type TAddOutputToPoint = (output: IPointOutput) => void;

export function useActionAddOutputToPoint(): TAddOutputToPoint {
  const setMode = useSetMode();

  const actionAddOutputToPoint = useStoreActions(
    (actions): typeof actions.points.addOutputToPoint => actions.points.addOutputToPoint
  );

  const addOutputToPoint = useCallback<TAddOutputToPoint>(
    (output): void => {
      actionAddOutputToPoint(output);

      setMode(Modes.CHANGED);
    },
    [actionAddOutputToPoint, setMode]
  );

  return addOutputToPoint;
}
