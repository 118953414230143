import styled from 'styled-components';
import { darken } from 'polished';
import {
  Color,
  ShapeRadius,
  Spacing,
  FontSize,
  FontWeight,
  LineHeight
} from '@pushwoosh/kit-constants';

interface ContainerProps {
  isError?: boolean;
  minHeight?: string;
}

export const Container = styled.textarea`
  width: 100%;
  min-height: 68px;

  padding: ${Spacing.S3} ${Spacing.S4};

  color: ${Color.MAIN};

  font-weight: ${FontWeight.REGULAR};
  font-size: ${FontSize.REGULAR};
  line-height: ${LineHeight.REGULAR};

  background: ${Color.CLEAR};
  border: 1px solid ${(props: ContainerProps) => (props.isError ? Color.WARNING : Color.FORM)};
  border-radius: ${ShapeRadius.CONTROL};
  outline: transparent;
  resize: vertical;

  ::placeholder {
    color: ${Color.PHANTOM};
  }

  &:hover {
    border: 1px solid ${darken(0.2, Color.FORM)};
  }

  &:focus {
    border: 1px solid ${Color.BRIGHT};
  }

  &:disabled {
    color: ${Color.LOCKED};

    background: ${Color.FROZEN};

    cursor: not-allowed;
  }

  &:read-only {
    background: ${Color.FROZEN};
  }
`;
