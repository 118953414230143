import * as React from 'react';

// Store
import { useStoreTooltips } from '@src/store/hooks';

// Containers
import { TooltipPointSendEmailStats } from '@src/containers/components/TooltipPointSendEmailStats';
import { TooltipPointSendPushStats } from '@src/containers/components/TooltipPointSendPushStats';
import { TooltipPointErrorStats } from '@src/containers/components/TooltipPointErrorStats';

// UI Components
import { Portal } from '@src/ui/kit';
import { TTooltipType } from '@src/common/types/tooltip';

const TOOLTIP_PORTAL_CONTAINER = document.getElementById('tooltip');

const TOOLTIP_COMPONENTS: Record<
  TTooltipType,
  React.ComponentType<React.ComponentProps<typeof TooltipPointSendEmailStats>>
> = {
  'point-send-email-stat': TooltipPointSendEmailStats,
  'point-send-push-stat': TooltipPointSendPushStats,
  'point-start-by-segment-stat': null,
  'point-event-stat': null,
  'point-exit-stat': null
};

function Tooltips(): JSX.Element {
  const tooltips = useStoreTooltips();

  const tooltipsRender = tooltips.map((tooltip): JSX.Element => {
    const TooltipComponent = TOOLTIP_COMPONENTS[tooltip.type];

    if (TooltipComponent === null) {
      return null;
    }

    if (!TooltipComponent) {
      return (
        <Portal key={tooltip.id} id={tooltip.id} container={TOOLTIP_PORTAL_CONTAINER}>
          <TooltipPointErrorStats tooltipId={tooltip.id} />
        </Portal>
      );
    }

    return (
      <Portal key={tooltip.id} id={tooltip.id} container={TOOLTIP_PORTAL_CONTAINER}>
        <TooltipComponent tooltipId={tooltip.id} />
      </Portal>
    );
  });

  return <>{tooltipsRender}</>;
}

export default React.memo(Tooltips);
