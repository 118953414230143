import * as React from 'react';

import { RichMediaEmptyPreviewSVG } from '../RichMediaEmptyPreviewSVG';
import { RichMediaPreviewContainer } from './styled';
import { TRichMediaPreviewProps } from './RichMediaPreview.types';
import { RichMediaPreviewIFrameMemo } from '../RichMediaPreviewIFrame';

function RichMediaPreview(props: TRichMediaPreviewProps): JSX.Element {
  const { richMediaCode, zipContent, params, language } = props;

  return (
    <RichMediaPreviewContainer>
      {zipContent ? (
        <RichMediaPreviewIFrameMemo
          richMediaCode={richMediaCode}
          zipContent={zipContent}
          params={params}
          language={language}
        />
      ) : (
        <RichMediaEmptyPreviewSVG />
      )}
    </RichMediaPreviewContainer>
  );
}

export const RichMediaPreviewMemo = React.memo(RichMediaPreview);
