import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSocialLogoLinkedinMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.724 17.47H6.152V9.73h2.572v7.74zM7.438 8.674h-.016C6.558 8.674 6 8.08 6 7.337 6 6.577 6.575 6 7.455 6c.88 0 1.422.578 1.439 1.337 0 .743-.559 1.337-1.456 1.337zM18 17.47h-2.572v-4.14c0-1.04-.373-1.75-1.303-1.75-.71 0-1.134.478-1.32.94-.068.166-.085.397-.085.628v4.322h-2.572s.034-7.013 0-7.74h2.572v1.097c.342-.528.954-1.278 2.319-1.278C16.73 9.55 18 10.655 18 13.032v4.438z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgSocialLogoLinkedinMediumFilled;
