import React from 'react';

import { Table, TableHeader, Row, Cell } from '@src/ui/kit/Table';
import { useGetPointById, useStoreStatisticsByJourney } from '@src/store/hooks';
import { TGoalEventPoint } from '@src/common/types/points';

import { Condition } from '@src/ui/kit/Condition';
import { StyledConditions, StyledCell } from './styled';

export function GoalsStat(): JSX.Element {
  const { goalsByPoints } = useStoreStatisticsByJourney();
  const getPointById = useGetPointById();
  const points = Object.keys(goalsByPoints).map((pointId) =>
    getPointById(pointId)
  ) as TGoalEventPoint[];

  return (
    <Table>
      <Row>
        <TableHeader>GOAL</TableHeader>
        <TableHeader>ACHIEVED</TableHeader>
      </Row>
      <>
        {goalsByPoints !== {} &&
          points.map((point) => {
            const { data, id } = point;
            return (
              <Row>
                <Cell>
                  <StyledCell>
                    {data.name}
                    {data.eventConditions && (
                      <StyledConditions>
                        Conditions:
                        {data.eventConditions.map((condition, index) => {
                          if (index + 1 !== data.eventConditions.length) {
                            return (
                              <>
                                <Condition
                                  key={index}
                                  condition={condition.attributeName}
                                  value={condition.value}
                                  operator={condition.operator}
                                />
                                &nbsp;and
                              </>
                            );
                          }
                          return (
                            <Condition
                              key={index}
                              condition={condition.attributeName}
                              value={condition.value}
                              operator={condition.operator}
                            />
                          );
                        })}
                      </StyledConditions>
                    )}
                  </StyledCell>
                </Cell>
                <Cell>{goalsByPoints[id].toString()}</Cell>
              </Row>
            );
          })}
      </>
    </Table>
  );
}
