// Types
import { TEvent } from '@src/common/types/event';
import { IOption } from '@src/common/types/select';

export function makeEventOptions(events: TEvent[]): IOption<string>[] {
  return events.map(
    (event): IOption<string> => ({
      label: event.name,
      value: event.name
    })
  );
}
