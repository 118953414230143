import styled from 'styled-components';
import {
  Color,
  ShapeRadius,
  Spacing,
  FontSize,
  FontWeight,
  UnitSize
} from '@pushwoosh/kit-constants';

type DropdownButtonProps = {
  showDropdown: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type SelectItem = {
  isSelected: boolean;
};

export const DropdownButtonWrap = styled.div`
  cursor: pointer;
  user-select: none;
  background-color: ${({ showDropdown }: DropdownButtonProps) =>
    showDropdown ? Color.MAIN : Color.FROZEN};
  color: ${({ showDropdown }) => (showDropdown ? Color.CLEAR : Color.MAIN)};
  border: 1px solid ${Color.FORM};
  border-radius: ${ShapeRadius.CONTROL};
  display: flex;
  align-items: center;
  padding: ${Spacing.S3} ${Spacing.S4};
  font-size: ${FontSize.REGULAR};
  font-weight: ${FontWeight.MEDIUM};
  height: ${UnitSize.FIELD_HEIGHT};

  & svg > path {
    fill: ${({ showDropdown }) => (showDropdown ? Color.CLEAR : Color.MAIN)};
  }
`;

export const Title = styled.div`
  margin-right: ${Spacing.S3};
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Content = styled.div`
  padding: ${Spacing.S2} 0;
  margin: -${Spacing.S5};
  min-width: 240px;
`;

export const Reset = styled.div`
  padding-left: ${Spacing.S4};
`;

export const DropdownItem = styled.div<SelectItem>`
  padding: ${Spacing.S3} ${Spacing.S4};
  color: ${Color.MAIN};
  display: flex;
  align-items: center;
  cursor: pointer;

  & > label {
    margin-right: ${Spacing.S3};
  }

  &:hover {
    background: ${Color.SOFT_LIGHT};
  }
`;

export const ArrowDown = styled.span`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid
    ${({ showDropdown }: DropdownButtonProps) => (showDropdown ? Color.CLEAR : Color.MAIN)};
`;
