import * as React from 'react';

// Components
import { FormControl } from '@src/ui/form/FormControl';
import { Textarea } from '@src/ui/kit/Textarea';

// Types
import { IProps } from './FormTextarea.types';

export function FormTextarea(props: IProps): JSX.Element {
  const {
    name,
    className,
    label,
    value,
    disabled,
    readonly,
    actionText,
    onChange,
    onActionClick,
    placeholder
  } = props;

  return (
    <FormControl
      className={className}
      label={label}
      disabled={disabled}
      actionText={actionText}
      onActionClick={onActionClick}
    >
      <Textarea
        name={name}
        value={value}
        disabled={disabled}
        readonly={readonly}
        onChange={readonly ? undefined : onChange}
        placeholder={placeholder}
      />
    </FormControl>
  );
}
