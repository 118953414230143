import * as React from 'react';

// Types
import { TSelectedProps } from '@src/ui/kit/Select';
import { Props } from '@src/ui/kit/AutoComplete/AutoComplete.types';

type Result = [() => void, () => void];

export const usePreviousValue = (
  value: TSelectedProps['value'],
  selectInputValue: TSelectedProps['inputValue'],
  onChange: Props['onChange'],
  isMulti: Props['isMulti']
): Result => {
  const [prevValue, setPrevValue] = React.useState<TSelectedProps['value']>();

  const handlePreviousValueFocus = React.useCallback(() => {
    if (!isMulti) {
      setPrevValue(value);
      onChange(null);
    }
  }, [isMulti, value, onChange]);

  const handlePreviousValueBlur = React.useCallback(() => {
    if (isMulti) {
      return;
    }
    if (selectInputValue !== '') {
      onChange({
        label: selectInputValue,
        value: selectInputValue
      });
    }
    if (!value && selectInputValue === '') {
      onChange(prevValue);
    }
  }, [isMulti, value, prevValue, selectInputValue, onChange]);

  return [handlePreviousValueFocus, handlePreviousValueBlur];
};
