import * as React from 'react';
import {
  useModeMap,
  useActionRemoveOutputFromPoint,
  useSetArrowHoveredToDelete
} from '@src/store/hooks';

// Components
import { ArrowMemo } from '@src/canvas/components/Arrow';

// Types
import { IDrawArrow } from './DrawArrow.types';

function DrawArrow(props: IDrawArrow): JSX.Element {
  const { arrow, isActive = false } = props;

  const modeMap = useModeMap();
  const canShowStats = !modeMap.isEdit;
  const canRemove = modeMap.isEdit;
  const actionRemoveOutputToPoint = useActionRemoveOutputFromPoint();
  const setArrowHoveredToDelete = useSetArrowHoveredToDelete();

  const handleArrowRemove = React.useCallback((): void => {
    actionRemoveOutputToPoint({
      fromPointId: arrow.fromPointId,
      toPointId: arrow.toPointId,
      key: arrow.outputKey,
      order: arrow.outputOrder
    });
  }, [actionRemoveOutputToPoint, arrow]);

  return (
    <ArrowMemo
      id={arrow.id}
      fromX={arrow.fromX}
      fromY={arrow.fromY}
      toX={arrow.toX}
      toY={arrow.toY}
      fromPointId={arrow.fromPointId}
      toPointId={arrow.toPointId}
      outputKey={arrow.outputKey}
      color={arrow.color}
      outputStats={arrow.outputStats}
      outputOrder={arrow.outputOrder}
      isActive={isActive}
      canRemove={canRemove}
      canShowStats={canShowStats}
      onRemove={handleArrowRemove}
      setArrowHoveredToDelete={setArrowHoveredToDelete}
      isDrawing={false}
    />
  );
}

export const DrawArrowMemo = React.memo(DrawArrow);
