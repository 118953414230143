import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSettingsMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.555 4.438A.5.5 0 0013.059 4H10.94a.5.5 0 00-.496.438l-.189 1.512a.527.527 0 01-.345.424c-.14.052-.277.109-.412.17a.527.527 0 01-.544-.055l-1.203-.936a.5.5 0 00-.66.042L5.595 7.092a.5.5 0 00-.042.66l.936 1.203c.12.155.138.365.056.544a5.959 5.959 0 00-.171.412.527.527 0 01-.424.345l-1.512.19A.5.5 0 004 10.94v2.118a.5.5 0 00.438.496l1.512.189a.527.527 0 01.424.345c.052.14.109.277.17.412a.527.527 0 01-.055.544l-.936 1.203a.5.5 0 00.042.66l1.497 1.497a.5.5 0 00.66.041l1.203-.935a.527.527 0 01.544-.056c.135.062.272.12.412.171.184.069.32.23.345.424l.19 1.512a.5.5 0 00.495.438h2.118a.5.5 0 00.496-.438l.189-1.512a.527.527 0 01.345-.424c.14-.052.277-.109.412-.17a.527.527 0 01.544.055l1.203.936a.5.5 0 00.66-.042l1.497-1.497a.5.5 0 00.041-.66l-.935-1.203a.527.527 0 01-.056-.544c.062-.135.12-.272.172-.412a.527.527 0 01.423-.345l1.512-.19A.5.5 0 0020 13.06V10.94a.5.5 0 00-.438-.496l-1.512-.189a.527.527 0 01-.424-.345 5.98 5.98 0 00-.17-.412.527.527 0 01.055-.544l.936-1.203a.5.5 0 00-.042-.66l-1.497-1.497a.5.5 0 00-.66-.042l-1.203.936a.527.527 0 01-.544.056 5.954 5.954 0 00-.412-.171.527.527 0 01-.345-.424l-.19-1.512zM12 15a3 3 0 100-6 3 3 0 000 6z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgSettingsMediumFilled;
