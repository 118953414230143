import React from 'react';

// Components
import { FormControl } from '@src/ui/form/FormControl';
import { Daypicker } from '@src/ui/kit';

// Types
import { IFormDaypickerProps } from './FormDaypicker.types';

export function FormDaypicker(props: IFormDaypickerProps): JSX.Element {
  const { label, values, name, setFieldValue, isDisabled } = props;

  const onChange = React.useCallback(
    (values: Set<string>) => {
      setFieldValue(name, Array.from(values));
    },
    [setFieldValue, name]
  );

  return (
    <FormControl label={label}>
      <Daypicker values={new Set(values)} onChange={onChange} isDisabled={isDisabled} />
    </FormControl>
  );
}
