import styled from 'styled-components';
import { Spacing, Color } from '@pushwoosh/kit-constants';
import { Paragraph } from '@pushwoosh/kit-typography';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const EmptyChangeLog = styled(Paragraph)`
  text-align: center;
  margin: ${Spacing.S5} 0 ${Spacing.S1} 0;
`;

export const ChangeLogInfo = styled.div`
  border-bottom: 1px solid ${Color.DIVIDER};
  border-top: 1px solid ${Color.DIVIDER};
  padding: ${Spacing.S3} ${Spacing.S5} ${Spacing.S3} ${Spacing.S5};
  display: flex;
  align-items: center;
  margin-top: -1px;
  margin-bottom: ${Spacing.S3};
`;

export const Description = styled.div`
  margin-left: ${Spacing.S3};
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  height: 100%;
  justify-content: center;
  text-align: center;
`;
