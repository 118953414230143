import React from 'react';

// Store Hooks
import { useArchiveJourneyById } from '@src/store/hooks';

// Features Hooks
import { useConfirmService } from '@src/ui/features/Modals/Confirm/hooks/useConfirmService';

const getDescriptionWithTitle = (title: string) => `
${title} journey will be moved to archive. All journey data will be saved so that you can clone it later to create a new journey. 
`;

type Args = {
  id: string;
  title: string;
  onReloadJourneys?: () => void;
};

export const useConfirmArchiveJourney = (args: Args): (() => void) => {
  const { id, title, onReloadJourneys } = args;
  const confirm = useConfirmService();
  const [archiveJourneyById, isRequestArchiveJourneyById] = useArchiveJourneyById();

  const confirmArchiveJourneyHandler = React.useCallback((): void => {
    confirm({
      onAfterConfirm: async (): Promise<void> => {
        await archiveJourneyById(id);
        if (onReloadJourneys) {
          onReloadJourneys();
        }
      },
      title: 'Archive Journey?',
      description: getDescriptionWithTitle(title),
      confirmLabel: 'Archive',
      isLoadingConfirm: isRequestArchiveJourneyById
    });
  }, [confirm, archiveJourneyById, id, title, isRequestArchiveJourneyById, onReloadJourneys]);

  return confirmArchiveJourneyHandler;
};
