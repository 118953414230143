import baselineSVG from '../assets/baseline.svg';
import noDataSVG from '../assets/noData.svg';
import notSignificantSVG from '../assets/notSignificant.svg';
import significantSVG from '../assets/significant.svg';
import baselineWinnerSVG from '../assets/baselineWinner.svg';

export function getStatusIcon(
  isBaseline: boolean,
  conversion: number,
  isSignificant: boolean,
  isWinner: boolean
) {
  if (isWinner && isBaseline) {
    return baselineWinnerSVG;
  }

  if (isWinner && !isBaseline) {
    return significantSVG;
  }

  if (isBaseline) {
    return baselineSVG;
  }

  if (conversion === 0 && !isSignificant) {
    return noDataSVG;
  }

  if (!isSignificant) {
    return notSignificantSVG;
  }

  return notSignificantSVG;
}
