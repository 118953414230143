import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgWarningRoundSmallFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15A7 7 0 108 1a7 7 0 000 14zM7.24 4.74a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v3.5a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-3.5zm.01 5.76a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-.5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgWarningRoundSmallFilled;
