import styled from 'styled-components';
import { Spacing, ShapeRadius, Color } from '@pushwoosh/kit-constants';
import { H4, Paragraph } from '@pushwoosh/kit-typography';

interface IContainerProps {
  color: string;
  fullWidth: boolean;
}

export const MessageContainer = styled.div`
  width: ${({ fullWidth }: IContainerProps) => (fullWidth ? '100%' : 'fit-content')};
  min-width: 200px;
  background-color: ${({ color }: IContainerProps) => color};
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const CloseIcon = styled.div`
  cursor: pointer;
`;

export const StyledH4 = styled(H4)`
  padding: ${Spacing.S3};
`;

export const StyledFootnote = styled(Paragraph)`
  padding: ${Spacing.S3};
  padding-top: 0;
`;

type IconWrapProps = {
  color: Color;
};

export const IconWrap = styled.div<IconWrapProps>`
  background: ${({ color }) => color};
  border-radius: ${ShapeRadius.CONTROL} 0 0 ${ShapeRadius.CONTROL};
`;

export const Wrap = styled.div`
  display: flex;
  margin-top: ${Spacing.S1};
  border-radius: ${Spacing.S1};
  overflow: hidden;
  width: 100%;
`;
