const POINT_WIDTH = 160;
const POINT_HEIGHT = 56;
const MIN_DISTANCE = 35;
const LABEL_OFFSET = 8;

interface IArgs {
  fromX: number;
  fromY: number;
  toX: number;
  toY: number;
  outputKey: string;
}

export function calculateCenterArrow({ fromX, fromY, toX, toY, outputKey }: IArgs) {
  const width = toX - fromX;
  let centerX;
  let centerY;

  if (width < MIN_DISTANCE && Math.abs(toY - fromY) < POINT_HEIGHT * 2) {
    if (toX - fromX < MIN_DISTANCE && Math.abs(toY - fromY) < POINT_HEIGHT) {
      centerX = fromX + 5;
    } else {
      centerX = toX + POINT_WIDTH + MIN_DISTANCE - LABEL_OFFSET;
      centerX = centerX > fromX + MIN_DISTANCE ? centerX : fromX + MIN_DISTANCE - LABEL_OFFSET;
    }
  } else if (outputKey === 'default') {
    centerX = fromX + (toX - fromX) / 2 - LABEL_OFFSET;
  } else {
    centerX = fromX + LABEL_OFFSET * 2;
  }

  if (outputKey === 'default') {
    centerY = fromY + (toY - fromY) / 2 - LABEL_OFFSET;
  } else {
    centerY = fromY - LABEL_OFFSET;
  }

  return {
    centerX,
    centerY
  };
}
