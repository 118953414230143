import styled from 'styled-components';
import { Spacing, FontWeight } from '@pushwoosh/kit-constants';
import { Text as TextComponent } from '@src/ui/atoms/Text';

export const Field = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${Spacing.S7};
`;

export const FieldTitle = styled.div`
  margin-right: ${Spacing.S3};
  font-weight: ${FontWeight.MEDIUM};
`;

export const Text = styled(TextComponent)`
  display: block;
  margin-top: ${Spacing.S7};
`;
