// Store
import { useStoreState } from '@src/store/store';

// Types
import { TEvent } from '@src/common/types/event';

type TResult = (applicationCode: string[]) => TEvent[];

export function useGetEventsByApplicationCodes(): TResult {
  const getEventsByApplicationCodes = useStoreState(
    (state): TResult => state.event.eventsByAppCodes
  );

  return getEventsByApplicationCodes;
}
