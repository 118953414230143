import React from 'react';
import { Collapse } from '@src/ui/kit';
import { sendYandexMetrika } from '@src/common/analytics/analytics';

// Enums
import { TemplateCategories } from '@src/common/constants/templates';

import { StyledLink } from './styled';

interface IProps {
  idCategory: TemplateCategories;
}

export function TemplateMenu(props: IProps): JSX.Element {
  const { idCategory } = props;

  return (
    <Collapse opened={Boolean(idCategory)} title="Templates">
      <StyledLink to={`/templates/${TemplateCategories.MOST_POPULAR}`}>Most popular</StyledLink>
      <StyledLink
        to={`/templates/${TemplateCategories.SUBSCRIPTION_BASED_MOBILE_APPS}`}
        onClick={(): void => sendYandexMetrika('JB_MENU_Subscription_based_mobile_apps')}
      >
        Subscription-based mobile apps
      </StyledLink>
      <StyledLink
        to={`/templates/${TemplateCategories.ECOMMERCE_RETAIL}`}
        onClick={(): void => sendYandexMetrika('JB_Ecommerce_Retail')}
      >
        Ecommerce & Retail
      </StyledLink>
      <StyledLink
        to={`/templates/${TemplateCategories.GAMING}`}
        onClick={(): void => sendYandexMetrika('JB_Gaming')}
      >
        Gaming
      </StyledLink>
      <StyledLink
        to={`/templates/${TemplateCategories.MEIDA}`}
        onClick={(): void => sendYandexMetrika('JB_Media')}
      >
        Media
      </StyledLink>
      <StyledLink
        to={`/templates/${TemplateCategories.SAAS}`}
        onClick={(): void => sendYandexMetrika('JB_SaaS')}
      >
        SaaS
      </StyledLink>
    </Collapse>
  );
}
