import React from 'react';

// Hooks
import { useSetMode } from '@src/store/hooks';

// Store
import { useStoreActions } from '@src/store/store';

// Types
import { TComment } from '@src/common/types/comments';

// Enums
import { Modes } from '@src/common/constants';

type TUpdateComment = (comment: TComment) => void;

export function useUpdateComment(): TUpdateComment {
  const setMode = useSetMode();

  const updateComment: TUpdateComment = useStoreActions<TUpdateComment>(
    (actions): TUpdateComment => actions.comments.updateComment
  );

  const handleCommentUpdate: TUpdateComment = React.useCallback<TUpdateComment>(
    (comment): void => {
      updateComment(comment);
      setMode(Modes.CHANGED);
    },
    [updateComment, setMode]
  );

  return handleCommentUpdate;
}
