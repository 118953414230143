import styled, { css } from 'styled-components';
import { Spacing, FontWeight, FontSize, Color, ShapeRadius } from '@pushwoosh/kit-constants';

export const MonthsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${Spacing.S5};
`;

type MonthProps = {
  isSameDay: boolean;
  isDisabled?: boolean;
};

const sameDay = css`
  background: ${Color.BRIGHT};
  color: ${Color.CLEAR};
`;

const notSameDay = css`
  &:hover {
    background: ${Color.BRIGHT_LIGHT};
    color: ${Color.PRIMARY_HOVER};
  }

  &:active {
    background: ${Color.BRIGHT_LIGHT};
    color: ${Color.PRIMARY_PRESSED};
  }
`;

const disabled = css`
  border: 1px solid ${Color.FORM};
  background: linear-gradient(
    to top left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 0.8px),
    ${Color.FORM} 50%,
    rgba(0, 0, 0, 0) calc(50% + 0.8px),
    rgba(0, 0, 0, 0) 100%
  );
  pointer-events: none;
`;

export const Month = styled.div`
  height: 59px;
  width: 59px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${FontWeight.MEDIUM};
  font-size: ${FontSize.SMALL};
  color: ${Color.MAIN};
  cursor: pointer;
  flex-direction: column;
  border-radius: ${ShapeRadius.CONTROL};
  text-transform: uppercase;

  ${({ isSameDay, isDisabled }: MonthProps) => (isSameDay && !isDisabled ? sameDay : notSameDay)}

  ${({ isDisabled }) => isDisabled && disabled}

  &:not(:nth-child(4n)) {
    margin-right: ${Spacing.S1};
  }
`;
