import { css } from 'styled-components';
import { UnitSize, FontWeight, FontSize } from '@pushwoosh/kit-constants';

import { IButtonProps } from '../Button.types';

// Size CSS
export const fieldCSS = css`
  height: ${UnitSize.FIELD_HEIGHT};
  font-weight: ${FontWeight.MEDIUM};
  font-size: ${FontSize.REGULAR};
`;

export const compactCSS = css<IButtonProps>`
  height: ${UnitSize.COMPACT_HEIGHT};
  font-weight: ${FontWeight.BOLD};
  font-size: ${FontSize.SMALL};
  text-transform: uppercase;
`;
