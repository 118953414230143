import styled from 'styled-components';
import { Spacing, Color, ShapeRadius } from '@pushwoosh/kit-constants';

type CurrentDateMarkerProps = {
  isSameDate: boolean;
};

export const CurrentDateMarker = styled.div`
  width: 8px;
  border: 2px solid
    ${({ isSameDate }: CurrentDateMarkerProps) => (isSameDate ? Color.CLEAR : Color.BRIGHT)};
  border-radius: ${ShapeRadius.CONTROL};
  margin-bottom: -${Spacing.S1};
`;

type CalendarProps = {
  withBorder: boolean;
};

export const CalendarWrap = styled.div`
  width: 280px;
  padding: ${Spacing.S5};
  background-color: ${Color.CLEAR};
  border: ${({ withBorder }: CalendarProps) =>
    withBorder ? `1px solid ${Color.FORM}` : 'transparent'};
  border-radius: ${ShapeRadius.CONTROL};
`;
