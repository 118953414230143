// Store
import { useStoreState } from '@src/store/store';

// Types
import { TEmail } from '@src/common/types/email';

type TResult = (applicationCode: string) => TEmail[];

export function useGetEmailsByApplicationCode(): TResult {
  const getEmailsByApplicationCode = useStoreState(
    (state): TResult => state.emails.emailsByAppCode
  );

  return getEmailsByApplicationCode;
}
