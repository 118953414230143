import * as React from 'react';

// Components
import { DocElements } from '@src/ui/features/Documentation/components/DocElements';

export function DocumentationPointSetTags(): JSX.Element {
  return (
    <DocElements>
      <DocElements.Paragraph>
        <strong>Manual update</strong>
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        To assign tag values to users within the journey manually:
      </DocElements.Paragraph>

      <DocElements.List>
        <DocElements.ListItem>
          {'Add the '}
          <strong>Update User Profile</strong>
          {" element wherever you'd like on the canvas."}
        </DocElements.ListItem>
        <DocElements.ListItem>
          {'Press '}
          <strong>+ Manual value</strong>
          {
            ' and select a tag from the drop-down list containing all the tags created in your Pushwoosh account.'
          }
        </DocElements.ListItem>
        <DocElements.ListItem>
          {'Specify values for the tag selected depending on its type.'}
        </DocElements.ListItem>
      </DocElements.List>

      <DocElements.Paragraph>{'You can set up to 10 tags at once.'} </DocElements.Paragraph>

      <DocElements.Paragraph>
        {
          "For user-specific tags, values are assigned to all user's devices with the same user ID. For tags that are not user-specific, values are assigned to a particular device a user's traveling the journey with."
        }
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        {
          'As for use cases, there are plenty of. For example, tagging users who reach a particular journey stage comes in handy when building further communications. '
        }
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-update-user-1.png"
        label="Setting Update User Profile"
        alt="Setting Update User Profile"
      />

      <DocElements.Paragraph>
        <strong>Dynamic Tag Value</strong>
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        {
          'Dynamic User Profile Update means the tag values are taken automatically from attributes of the events the user trigger prior to reaching the Update User Profile journey point. Setting tag values according to events users trigger or their attributes, you can communicate to that users more personally and send them tailored offers.'
        }
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        {'To set dynamic tag values, do the following:'}
      </DocElements.Paragraph>

      <DocElements.Paragraph>
        {
          '1. Put the Update User Profile element anywhere on the canvas providing that at least one triggered-based element (Trigger-based Entry or Wait for Trigger) precedes the Update User Profile point.'
        }
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-update-user-2.png"
        label="Setting Update User Profile"
        alt="Setting Update User Profile"
      />

      <DocElements.Paragraph>
        {'2. Double-click the element and press '}
        <strong>+Dynamic Value</strong>
        {' in the Dynamic Tag Value section.'}
      </DocElements.Paragraph>
      <DocElements.Paragraph>{'3. Select the tag to set.'}</DocElements.Paragraph>
      <DocElements.Paragraph>
        {
          '4. Choose an event from the dropdown list and the tag, which value will be set dynamically depending on the attributes of the event the user triggers.'
        }
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-update-user-3.png"
        label="Setting Update User Profile"
        alt="Setting Update User Profile"
      />
    </DocElements>
  );
}
