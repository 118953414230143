// Store
import { useStoreActions } from '@src/store/store';

// Types
import { IUniqueId } from '@src/common/types/entities';

type TAddPointIdsToBuffer = (payload: IUniqueId['id'][]) => void;

export function useAddPointIdsToBuffer(): TAddPointIdsToBuffer {
  return useStoreActions((actions): TAddPointIdsToBuffer => actions.points.addPointsToBuffer);
}
