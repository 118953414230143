import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSocialLogoFacebookMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.984 12.422h-1.952v6.959h-2.893v-6.959H8.764V9.963h1.375v-1.59c0-1.14.542-2.92 2.92-2.92l2.143.008v2.387h-1.556c-.253 0-.613.127-.613.67v1.446h2.204l-.253 2.458z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgSocialLogoFacebookMediumFilled;
