import React from 'react';
import { useStoreActions, useStoreState } from '@src/store/store';
import { useCurrentJourneyId } from '@src/store/hooks';
import { useConfirmActivateJourney } from '@src/ui/features/Modals/Confirm';
import { RightDrawerSidebar, Button } from '@src/ui/kit';
import { useAssistant } from '../hooks/useAssistant';
import { SidebarDescription } from '../components/SidebarDescription';
import { AssistantItem } from '../components/AssistantItem';
import { FooterWrap, SidebarContent } from '../styled';

export function AssistantSidebar(): JSX.Element {
  const hideSidepanel = useStoreActions((actions) => actions.sidebars.closeAllSidebars);
  const journeyId = useCurrentJourneyId();
  const validateById = useStoreActions((actions) => actions.journeys.validateById);
  const isLaunchReady = useStoreState((store) => store.assistant.isLaunchReady);
  const assistant = useAssistant();
  const { confirmActivateJourneyHandler } = useConfirmActivateJourney();
  const isCheckCompleted = !Object.values(assistant).includes(false);

  const handleClose = React.useCallback((): void => {
    hideSidepanel();
  }, [hideSidepanel]);

  const handleValidate = React.useCallback((): void => {
    hideSidepanel();
    validateById({ journeyId });
  }, [hideSidepanel, validateById, journeyId]);

  const handeStartJourney = React.useCallback(() => {
    hideSidepanel();
    confirmActivateJourneyHandler();
  }, [confirmActivateJourneyHandler, hideSidepanel]);

  return (
    <RightDrawerSidebar
      title="Assistant"
      isBack={false}
      isOpen
      onClose={handleClose}
      renderFooter={() => (
        <FooterWrap>
          {isLaunchReady ? (
            <Button color="primary" view="shape" size="field" onClick={handeStartJourney}>
              Start campaign
            </Button>
          ) : (
            <Button color="secondary" view="shape" size="field" onClick={handleValidate}>
              Run validation
            </Button>
          )}
        </FooterWrap>
      )}
    >
      <SidebarContent>
        <SidebarDescription isCheckCompleted={isCheckCompleted} />
        <AssistantItem isChecked={assistant.entriesAdded} value="Entry step added" />
        <AssistantItem isChecked={assistant.actionAdded} value="Add some additional steps" />
        <AssistantItem isChecked={assistant.exitAdded} value="Don’t forget to add Exit step" />
        <AssistantItem
          isChecked={assistant.stepsConnected}
          value="Combine all the steps all together"
        />
        <AssistantItem
          isChecked={assistant.stepsConfigured}
          value="Configure these steps correctly"
        />
      </SidebarContent>
    </RightDrawerSidebar>
  );
}
