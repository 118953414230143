import React from 'react';

// UI Components
import { Button, IconEnum } from '@src/ui/kit';
import {
  Container,
  Footer,
  Line
} from '@src/ui/features/ApiEntry/components/PlaceholdersEdit/styled';
import { FormInput } from '@src/ui/form';
import { placeholderValid } from '@src/ui/features/ApiEntry/helepers/placeholders';

interface IProps {
  placeholders: string[];
  addNewPlaceholder: () => void;
  removePlaceholder: (i: number) => void;
  updatePlaceholder: (i: number, value: string) => void;
}

export function PlaceholdersEdit(props: IProps): JSX.Element {
  const { placeholders, addNewPlaceholder, removePlaceholder, updatePlaceholder } = props;

  const errors = React.useMemo(
    () =>
      placeholders.map((placeholder, i) => {
        if (placeholder.trim().length === 0) {
          return undefined;
        }

        if (!placeholderValid(placeholder)) {
          return 'Invalid placeholder, please use only latin letters, digits and underscore';
        }

        if (placeholders.indexOf(placeholder) !== i) {
          return 'This placeholder already exists';
        }

        return undefined;
      }),
    [placeholders]
  );

  return (
    <Container>
      {placeholders.map((placeholder, i) => (
        <Line key={i}>
          <FormInput
            name={`attributes.${i}`}
            value={placeholder}
            error={errors[i]}
            onChange={(_, value) => updatePlaceholder(i, value)}
          />
          <Button
            iconType={IconEnum.CLOSE_SMALL}
            onClick={() => removePlaceholder(i)}
            size="compact"
            color="secondary"
            view="ghost"
          />
        </Line>
      ))}

      <Footer>
        <Button
          iconPosition="left"
          iconType={IconEnum.PLUS_MEDIUM}
          color="secondary"
          onClick={addNewPlaceholder}
        >
          Add Placeholder
        </Button>
      </Footer>
    </Container>
  );
}
