import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgArrowRoundLeftMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-8.293-4.707a1 1 0 010 1.414L10.414 11H16a1 1 0 110 2h-5.586l2.293 2.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgArrowRoundLeftMediumFilled;
