import React from 'react';

import { StyledCell } from '@src/ui/kit/Table/styled';

type IProps = {
  children: React.ReactNode;
};

export function Cell({ children }: IProps) {
  return <StyledCell>{children}</StyledCell>;
}
