import styled from 'styled-components';
import { Color, FontSize, FontWeight, Spacing } from '@pushwoosh/kit-constants';
import { Icon } from '@src/ui/kit';

export const Wrapper = styled.div`
  display: flex;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  display: flex;
  max-width: 1120px;
`;

export const StyledImg = styled.img``;

interface IAccordionItemWrapperProps {
  $isActive: boolean;
}

export const AccordionItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  border: 1px solid ${Color.DIVIDER};
  border-left: ${({ $isActive }: IAccordionItemWrapperProps) =>
    $isActive ? `4px solid ${Color.BRIGHT}` : `4px solid ${Color.DIVIDER}`};
  border-radius: 4px;
  width: 320px;
  padding: ${Spacing.S4} ${Spacing.S4} ${Spacing.S4} ${Spacing.S6};
  margin-bottom: ${Spacing.S3};
  margin-right: ${Spacing.S5};

  overflow: hidden;

  max-height: ${({ $isActive }: IAccordionItemWrapperProps) => ($isActive ? '500px' : '44px')};
  transition: max-height 0.2s cubic-bezier(0.5, 0, 1, 0.5);

  &:hover {
    cursor: pointer;
    background-color: ${Color.ROW_HOVER};

    & svg {
      fill: ${Color.BRIGHT};
    }
  }

  &:active {
    background-color: ${Color.SOFT_LIGHT};

    & svg {
      fill: ${Color.BRIGHT};
    }
  }
`;

export const Left = styled.div`
  display: flex;
  align-content: center;
`;

export const AccordionItemTitle = styled.span`
  display: flex;
  align-content: center;
  font-size: ${FontSize.REGULAR};
  font-weight: ${FontWeight.MEDIUM};
  color: ${Color.MAIN};
  line-height: 1.4;
  padding-bottom: ${Spacing.S4};
`;

export const AccordionItemDescription = styled.p`
  margin-top: ${Spacing.S1};
  font-size: ${FontSize.REGULAR};
`;

export const AccordionItemIcon = styled(Icon)`
  fill: ${({ $isActive }: IAccordionItemWrapperProps) =>
    $isActive ? Color.BRIGHT : Color.DIVIDER};
`;

export const AccordionItemIconWrapper = styled.div`
  display: flex;
  align-content: center;

  & > div {
    display: flex;
    align-content: center;
    margin: auto;
  }
`;
