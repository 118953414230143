// Enums
import { Modes, JourneyStatus } from '@src/common/constants';

export function getModeByJourneyStatus(journeyStatus: JourneyStatus): Modes {
  switch (journeyStatus) {
    case JourneyStatus.DRAFT: {
      return Modes.EDIT;
    }

    case JourneyStatus.FINISHED:
    case JourneyStatus.ARCHIVED:
    case JourneyStatus.RUNNING: {
      return Modes.READ;
    }

    default: {
      return Modes.EDIT;
    }
  }
}
