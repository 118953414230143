import React from 'react';
import { FormField } from '@src/ui/kit';
import { OperatorTypesHumans } from '@src/common/constants/attribute';
import { IEventPointCondition } from '@src/common/types/points';
import { ConditionValue } from '@src/common/components/ConditionValue/ConditionValue';
import { Container, Header, Tag, TagName, Tags } from './styled';

type Props = {
  eventName: string;
  eventUrl: string;
  eventConditions?: IEventPointCondition[];
};

export function DetailedEvent(props: Props): JSX.Element {
  const { eventName, eventUrl, eventConditions } = props;

  return (
    <Container>
      <Header>
        <FormField label="TRIGGER: " value={eventName} url={eventUrl} />
      </Header>

      {eventConditions?.length > 0 && (
        <Tags>
          <b>Conditions:</b>
          {eventConditions.map((eventCondition, index) => (
            <React.Fragment key={index}>
              {index !== 0 && <i>and</i>}
              <Tag>
                <TagName>{eventCondition.attributeName}</TagName>
                <i>{OperatorTypesHumans[eventCondition.operator]}</i>
                <ConditionValue operator={eventCondition.operator} value={eventCondition.value} />
              </Tag>
            </React.Fragment>
          ))}
        </Tags>
      )}
    </Container>
  );
}
