import React from 'react';
import {
  useCurrentJourneyId,
  useGetJourneyById,
  useGoalEventPoints,
  useOpenNewModal,
  useVisibleSidebar
} from '@src/store/hooks';
import { TrophyIcon } from '@pushwoosh/kit-icons';
import { JourneyStatus } from '@src/common/constants/journey';
import { ModalType } from '@src/common/constants/modals';
import { sendMixpanelEvent } from '@src/common/analytics';
import { SilentHoursButton } from '@src/ui/features/Settings/components/SilentHoursButton';
import { FrequencyCappingButton } from '@src/ui/features/Settings/components/FrequencyCappingButton';
import { SettingsButton } from '../SetttingsButton';
import { Container } from './styled';

export function SettingsPanel(): JSX.Element {
  const openNewModal = useOpenNewModal();
  const journeyId = useCurrentJourneyId();
  const getJourneyById = useGetJourneyById();
  const journey = getJourneyById(journeyId);
  const status = journey?.status;
  const isReadMode = status === JourneyStatus.DRAFT || status === JourneyStatus.PAUSED;
  const isVisibleSidebar = useVisibleSidebar();
  const { goalEventPoints } = useGoalEventPoints();

  const [popoverType, setPopoverType] = React.useState(null);

  const goalString = goalEventPoints.length === 1 ? 'goal' : 'goals';
  const goalsText =
    goalEventPoints.length > 0 ? `${goalEventPoints.length} ${goalString} set` : 'Conversion Goals';

  const isHiddenGoals = !isReadMode && goalEventPoints.length === 0;

  const setGoalJourneyHandle = React.useCallback((): void => {
    openNewModal({
      type: ModalType.JOURNEY_GOAL_SETTINGS,
      data: { journeyId }
    });
  }, [openNewModal, journeyId]);

  const setDetailGoalHandle = React.useCallback((): void => {
    openNewModal({
      type: ModalType.DETAIL_POINT_GOAL_EVENT_LIST
    });
  }, [openNewModal]);

  const handleSendMixpanelEvent = () => {
    sendMixpanelEvent({
      eventName: 'JourneyPointRestrictionButtonClicked',
      eventData: {
        point: popoverType === 'capping' ? 'Frequency Capping' : 'Silent Hours'
      }
    });
  };

  const handleLinkClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleSendMixpanelEvent();
  };

  return (
    <Container>
      {!isHiddenGoals && (
        <SettingsButton
          onClick={isReadMode ? setGoalJourneyHandle : setDetailGoalHandle}
          icon={<TrophyIcon size="medium" view="filled" />}
          isCompact={isVisibleSidebar}
        >
          {goalsText}
        </SettingsButton>
      )}
      <SilentHoursButton
        isPopoverVisible={popoverType === 'silentHours'}
        journey={journey}
        isReadMode={isReadMode}
        onSetPopoverType={(popoverType) => {
          sendMixpanelEvent({
            eventName: 'ShowBuyPopover',
            eventData: { CampaignOption: 'SilentHours' }
          });
          setPopoverType(popoverType);
        }}
        isVisibleSidebar={isVisibleSidebar}
        onLinkClick={handleLinkClick}
      />
      <FrequencyCappingButton
        isPopoverVisible={popoverType === 'capping'}
        journey={journey}
        isReadMode={isReadMode}
        onSetPopoverType={(popoverType) => {
          sendMixpanelEvent({
            eventName: 'ShowBuyPopover',
            eventData: { CampaignOption: 'Capping' }
          });
          setPopoverType(popoverType);
        }}
        isVisibleSidebar={isVisibleSidebar}
        onLinkClick={handleLinkClick}
      />
    </Container>
  );
}
