import React from 'react';
import { sendMixpanelEvent } from '@src/common/analytics/mixpanel';
import { Color } from '@pushwoosh/kit-constants';
import { SidebarType } from '@src/common/constants/sidebars';
import { useStoreActions, useStoreState } from '@src/store/store';
import { useVisibleSidebar, useValidateById } from '@src/store/hooks';
import { HelpRoundIcon, CheckRoundIcon } from '@pushwoosh/kit-icons';
import { Button } from '../Button';
import { useAssistant } from '../../hooks/useAssistant';

export function AssistantButton(): JSX.Element {
  const isVisibleSidebar = useVisibleSidebar();
  const setLaunchReady = useStoreActions((actions) => actions.assistant.setLaunchReady);
  const openSidebar = useStoreActions((state) => state.sidebars.openNewSidebar);
  const closeAllSidebars = useStoreActions((state) => state.sidebars.closeAllSidebars);
  const sidebars = useStoreState((state) => state.sidebars);
  const isLaunchReady = useStoreState((state) => state.assistant.isLaunchReady);
  const isSelected = sidebars.sidebar?.type === SidebarType.ASSISTANT;
  const assistant = useAssistant();
  const isCheckCompleted = !Object.values(assistant).includes(false);
  const { isLoading } = useValidateById();

  React.useEffect(() => {
    if (isCheckCompleted) {
      setLaunchReady(true);
    } else {
      setLaunchReady(false);
    }
  }, [isCheckCompleted, setLaunchReady]);

  const openAssistantSidebar = React.useCallback(() => {
    if (sidebars.isOpened && sidebars.sidebar.type === SidebarType.ASSISTANT) {
      closeAllSidebars();
    } else {
      closeAllSidebars();

      sendMixpanelEvent({ eventName: 'Canvas:ShowDrawer', eventData: { DrawerName: 'Assistant' } });

      openSidebar({
        type: SidebarType.ASSISTANT
      });
    }
  }, [openSidebar, sidebars, closeAllSidebars]);

  return isLaunchReady ? (
    <Button
      isCompact={isVisibleSidebar}
      icon={<CheckRoundIcon size="medium" type="fill" view="filled" />}
      iconColor={Color.SUCCESS}
      onClick={openAssistantSidebar}
      isSelected={isSelected}
      isDisabled={isLoading}
    >
      Ready to launch
    </Button>
  ) : (
    <Button
      isCompact={isVisibleSidebar}
      icon={<HelpRoundIcon size="medium" view="filled" />}
      onClick={openAssistantSidebar}
      isSelected={isSelected}
      iconColor={Color.BRIGHT}
      isDisabled={isLoading}
    >
      Assistant
    </Button>
  );
}
