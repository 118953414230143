import * as React from 'react';
import { isDate } from 'date-fns';

// Components
import { InputDatepicker } from '@src/ui/kit';
import { FormInput } from '@src/ui/form/FormInput';
import { FormInputAutoComplete } from '@src/ui/form/FormInputAutoComplete';
import { FormSelect } from '@src/ui/form/FormSelect';
import { FormControl } from '@src/ui/form/FormControl';

// Constants
import { AttributeTypeEnum, OperatorTypeEnum } from '@src/common/constants/attribute';

// Helpers
import { safeDate } from '@src/ui/features/EditEvents/helpers/safeDate';

// Types
import { ErrorMessage } from '@src/ui/form/FormControl/styled';
import { IFilterConditionOneChoiceProps } from './FilterConditionOneChoice.types';

const BOOLEAN_SELECT_OPTIONS = [
  {
    label: 'true',
    value: true
  },
  {
    label: 'false',
    value: false
  }
];

export function FilterConditionOneChoice(props: IFilterConditionOneChoiceProps): JSX.Element {
  const {
    applicationCode,
    eventCode,
    attributeType,
    attributeName,
    operatorType,
    name,
    value,
    touched = '',
    error = '',
    onChangeField,
    onBlurField,
    setFieldValue,
    setFieldTouched
  } = props;

  const handleInputIntegerChange = React.useCallback(
    (name, value): void => {
      const currentValue = value === '' ? '' : parseInt(value, 10);
      if (currentValue === '' || !Number.isNaN(currentValue)) {
        setFieldValue(name, currentValue);
      }
    },
    [setFieldValue]
  );

  const handleInputStringChange = React.useCallback(
    (event): void => {
      const { currentTarget } = event;
      const { value } = currentTarget;

      setFieldValue(currentTarget.name, value);
    },
    [setFieldValue]
  );

  if (
    !(
      typeof value === 'boolean' ||
      typeof value === 'number' ||
      typeof value === 'string' ||
      isDate(value)
    )
  ) {
    return null;
  }

  if (attributeType === AttributeTypeEnum.BOOLEAN) {
    const selectValue =
      value.toString() !== 'false'
        ? BOOLEAN_SELECT_OPTIONS[0].value
        : BOOLEAN_SELECT_OPTIONS[1].value;

    return (
      <FormSelect<boolean>
        placeholder="Select value"
        error={touched && error ? error : ''}
        name={name}
        value={selectValue}
        options={BOOLEAN_SELECT_OPTIONS}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
      />
    );
  }

  if (attributeType === AttributeTypeEnum.INTEGER) {
    return (
      <>
        <FormInput
          placeholder="Enter an integer"
          name={name}
          value={String(value)}
          onChange={handleInputIntegerChange}
          onBlur={onBlurField}
        />
        {touched && error && <ErrorMessage>{error}</ErrorMessage>}
      </>
    );
  }

  const operatorIsNotOrIs =
    operatorType === OperatorTypeEnum.EQ || operatorType === OperatorTypeEnum.NOTEQ;
  if (attributeType === AttributeTypeEnum.STRING && operatorIsNotOrIs) {
    return (
      <>
        <FormInputAutoComplete
          attributeName={attributeName}
          placeholder="Enter an text"
          name={name}
          applicationCode={applicationCode}
          eventCode={eventCode}
          error={touched && error ? error : ''}
          value={value as string}
          onChangeSelect={setFieldValue}
          onBlurSelect={setFieldTouched}
          onChange={onChangeField}
          onBlur={onBlurField}
        />
      </>
    );
  }

  if (attributeType === AttributeTypeEnum.STRING) {
    return (
      <>
        <FormInput
          placeholder="Enter an text"
          name={name}
          value={value as string}
          onChange={handleInputStringChange}
          onBlur={onBlurField}
        />
        {touched && error && <ErrorMessage>{error}</ErrorMessage>}
      </>
    );
  }

  if (attributeType === AttributeTypeEnum.DATE) {
    const selected = safeDate(value);

    const onChange = (date: Date): void => {
      setFieldValue(name, date);
    };

    return (
      <FormControl error={touched && error ? error : ''}>
        <InputDatepicker value={selected} onChange={onChange} />
      </FormControl>
    );
  }

  return null;
}
