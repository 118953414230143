import styled from 'styled-components';
import { Color, FontSize, FontWeight, Spacing } from '@pushwoosh/kit-constants';
import { Button, Icon, Link } from '@src/ui/kit';

export const Description = styled.div`
  display: flex;
  padding-left: ${Spacing.S7};
  padding-right: ${Spacing.S7};
  font-size: ${FontSize.REGULAR};
`;

export const PlansWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: ${Spacing.S7};
`;

export const Plan = styled.div`
  display: flex;
  flex-direction: column;
  width: 288px;
  height: 400px;
  border-radius: 4px;
  border: 1px solid ${Color.DIVIDER};
`;

export const PlanHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${Spacing.S5};
  font-weight: ${FontWeight.BOLD};
  font-size: ${FontSize.HEADING3};
  border-bottom: 1px solid ${Color.DIVIDER};

  & > div {
    margin-left: ${Spacing.S1};
  }
`;

export const PlanBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spacing.S5};
  height: 100%;
`;

export const Feature = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: ${FontSize.REGULAR};
  margin-bottom: ${Spacing.S4};
`;

export const CurrentPlan = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${Spacing.S4};
  border-radius: 4px;
  background-color: ${Color.SOFT_LIGHT};
  color: ${Color.LOCKED};
  font-weight: ${FontWeight.REGULAR};
  font-size: ${FontSize.REGULAR};
  height: 24px;
  padding-left: ${Spacing.S3};
  padding-right: ${Spacing.S3};
`;

export const GrayCheck = styled(Icon)`
  margin-top: ${Spacing.S0};
  background-color: ${Color.SOFT_LIGHT};
  border-radius: 4px;
  margin-right: ${Spacing.S3};
`;

export const GreenCheck = styled(GrayCheck)`
  background-color: ${Color.SUCCESS};
`;

export const StyledLink = styled(Link)`
  font-size: ${FontSize.HEADING3};
  margin-bottom: ${Spacing.S7};
  margin-left: ${Spacing.S7};
`;

export const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CloseButton = styled.div`
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  justify-content: center;
`;
