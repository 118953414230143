import React from 'react';
import { format } from 'date-fns';

// Components
import { Icon, IconEnum } from '@src/ui/kit';

// Constants
import { Color } from '@pushwoosh/kit-constants';

// Types
import { ICommentMessageProps } from './CommentMessage.types';

// Styled
import { CommentInfo, CommentUpdateDate, CommentText, RemoveIcon } from './styled';

export function CommentMessage(props: ICommentMessageProps): JSX.Element {
  const { message, createdAt, commentId, mode, setEdit, onRemoveComment } = props;

  return (
    <>
      <CommentText onClick={() => setEdit(true)}>{message}</CommentText>
      <CommentInfo>
        <CommentUpdateDate>{format(new Date(createdAt), 'HH:mm, MMMM dd, yyyy')}</CommentUpdateDate>
        {!mode.isRead && (
          <RemoveIcon onClick={() => onRemoveComment(commentId)}>
            <Icon fill={Color.SOFT} type={IconEnum.TRASH_MEDIUM_LINED} />
          </RemoveIcon>
        )}
      </CommentInfo>
    </>
  );
}
