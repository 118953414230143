import { useCallback } from 'react';

// Store
import { useStoreActions } from '@src/store/store';

// Hooks
import { useSetMode } from '@src/store/hooks';

// Enums
import { Modes } from '@src/common/constants';
import { IPointOutput } from '@src/common/types/points';

type TRemoveOutputFromPoint = (output: IPointOutput) => void;

export function useActionRemoveOutputFromPoint(): TRemoveOutputFromPoint {
  const setMode = useSetMode();

  const actionRemoveOutputFromPoint = useStoreActions(
    (actions): typeof actions.points.removeOutputFromPoint => actions.points.removeOutputFromPoint
  );

  const removeOutputFromPoint = useCallback<TRemoveOutputFromPoint>(
    (output): void => {
      actionRemoveOutputFromPoint(output);
      setMode(Modes.CHANGED);
    },
    [actionRemoveOutputFromPoint, setMode]
  );

  return removeOutputFromPoint;
}
