import * as React from 'react';
import { Path } from 'react-konva';

// Constants
import { CONTROL_GROUP_COLOR, PointType } from '@src/common/constants/point';

// Helpers
import { getTitlePoint } from '@src/canvas/helpers';

// Components
import { Point, PointContent, PointText, PointIcon, PointOutput } from '../Point';

// Types
import { IProps } from './PointSetTags.types';

export function PointSetTags(props: IProps): JSX.Element {
  const { data, propsCommonPoint } = props;

  const titlePoint = getTitlePoint(PointType.SET_TAGS, propsCommonPoint.title, data);

  return (
    <Point type={PointType.SET_TAGS} color={CONTROL_GROUP_COLOR} {...propsCommonPoint}>
      <PointContent type={PointType.SET_TAGS}>
        <PointIcon type={PointType.SET_TAGS} fill={CONTROL_GROUP_COLOR}>
          <Path
            fill="white"
            perfectDrawEnabled={false}
            shadowForStrokeEnabled={false}
            data="M15 10C12.2375 10 10 12.2375 10 15C10 17.7625 12.2375 20 15 20C17.7625 20 20 17.7625 20 15C20 12.2375 17.7625 10 15 10ZM6.00173 27.8278C6 27.8998 6 27.9108 6 27.9329C6 27.9951 6 28.0263 6.00069 28.0526C6.0287 29.1159 6.88405 29.9712 7.94733 29.9992C7.97366 29.9999 8.0048 29.9999 8.06706 29.9999H21.9246C21.9946 29.9999 22.0296 29.9999 22.0592 29.9991C23.1173 29.9677 23.9678 29.1173 23.9991 28.0591C24 28.0295 24 27.9945 24 27.9245C24 27.8935 24 27.8779 23.9978 27.7969C23.9257 25.1291 21.6149 22.4767 18.9821 22.0399C18.925 22.0304 19.1337 22.0588 19.1932 22.0669L19.206 22.0686C19.2077 22.0689 19.2077 22.0689 19.206 22.0686C19.2014 22.068 19.1907 22.0665 19.1721 22.0639L19.1666 22.0632C16.2855 21.6648 13.76 21.6702 10.8439 22.0748L10.8026 22.0806L10.8178 22.0785L10.818 22.0785C10.877 22.0704 11.0836 22.0421 11.0327 22.0504C8.37487 22.4845 6.06642 25.1355 6.00173 27.8278ZM34 13C34 13.5523 33.5523 14 33 14H24C23.4477 14 23 13.5523 23 13V12C23 11.4477 23.4477 11 24 11H33C33.5523 11 34 11.4477 34 12V13ZM23 19C23 19.5523 23.4477 20 24 20H29C29.5523 20 30 19.5523 30 19V18C30 17.4477 29.5523 17 29 17H24C23.4477 17 23 17.4477 23 18V19Z"
          />
        </PointIcon>
        <PointText type={PointType.SET_TAGS}>{titlePoint}</PointText>
        <PointOutput
          pointId={propsCommonPoint.id}
          type={PointType.SET_TAGS}
          colorBlock={CONTROL_GROUP_COLOR}
          isInvalid={propsCommonPoint.isInvalid}
          isActive={propsCommonPoint.isActive}
          isLinkedOutput={propsCommonPoint.isLinkedOutput}
          onMouseDown={propsCommonPoint.onMouseDownOutput}
          arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
        />
      </PointContent>
    </Point>
  );
}
