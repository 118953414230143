import React from 'react';

// Hooks
import { useSetMode } from '@src/store/hooks';

// Store
import { useStoreActions } from '@src/store/store';

// Types
import { TComment } from '@src/common/types/comments';

// Enums
import { Modes } from '@src/common/constants';

type TCreateComment = (comment: TComment) => void;

export function useCreateComment(): TCreateComment {
  const setMode = useSetMode();

  const createComment: TCreateComment = useStoreActions<TCreateComment>(
    (actions): TCreateComment => actions.comments.create
  );

  const handleCommentCreate: TCreateComment = React.useCallback<TCreateComment>(
    (comment): void => {
      createComment(comment);
      setMode(Modes.CHANGED);
    },
    [createComment, setMode]
  );

  return handleCommentCreate;
}
