import styled, { css } from 'styled-components';
import { NavLink } from '@src/ui/kit';
import { Spacing, FontSize, Color, FontWeight } from '@pushwoosh/kit-constants';

const CssActive = css`
  color: ${Color.PRIMARY};
  font-weight: ${FontWeight.BOLD};
`;

export const StyledLink = styled(NavLink)`
  display: flex;
  align-items: center;
  height: ${Spacing.S9};
  padding-left: ${Spacing.S1};
  font-size: ${FontSize.REGULAR};
  color: ${Color.MAIN};
  font-weight: ${FontWeight.REGULAR};
  text-decoration: none;

  &:hover {
    ${CssActive}
  }

  &.active {
    ${CssActive}
  }
`;
