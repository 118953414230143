import React from 'react';
import { FormChoiceGroup } from '@src/ui/form/FormChoiceGroup';
import { Field } from '@pushwoosh/pwkit-field';
import { NumberStepper } from '@pushwoosh/kit-number-stepper';
import { WeeklyValues } from '@src/common/constants/datetime';
import { TFormEditingPointWaitProps } from '../WaitForm/WaitForm.types';
import { Container, WaitSettings, DayWrap } from './styled';

type Props = {
  values: TFormEditingPointWaitProps['values'];
  names: TFormEditingPointWaitProps['names'];
  setFieldValue: TFormEditingPointWaitProps['setFieldValue'];
};

export function WeekSettings(props: Props): JSX.Element {
  const { values, names, setFieldValue } = props;

  return (
    <Container>
      <DayWrap>
        <FormChoiceGroup
          name={names.weekValue}
          label="Select specific day of a week:"
          activeValues={[values.weekValue] as string[]}
          defaultValues={WeeklyValues}
          isMultiple={false}
          setFieldValue={(name: string, value: string[]) => setFieldValue(name, value[0])}
        />
      </DayWrap>
      <Field title="Wait till specific time" helpText="On users timezone">
        <WaitSettings>
          <NumberStepper
            dimension="hour"
            name={names.weekHour}
            value={values.weekHour}
            max={23}
            onChange={({ value }) => setFieldValue(names.weekHour, value)}
          />
          <NumberStepper
            dimension="min"
            name={names.weekMinute}
            value={values.weekMinute}
            max={59}
            onChange={({ value }) => setFieldValue(names.weekMinute, value)}
          />
        </WaitSettings>
      </Field>
    </Container>
  );
}
