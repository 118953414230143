import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPlaceSmallFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14.944S13.5 11.278 13.5 7a5.5 5.5 0 00-11 0c0 4.278 5.5 7.944 5.5 7.944zM10.5 7a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPlaceSmallFilled;
