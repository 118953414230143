import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgFlashMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.788 4.681c.502-.943 1.938-.468 1.778.589l-.694 4.58h2.795a.95.95 0 01.839 1.396l-4.294 8.073c-.502.943-1.938.468-1.778-.589l.694-4.58H8.333a.95.95 0 01-.839-1.396l4.294-8.073z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgFlashMediumFilled;
