import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgChevroRightMedium(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.47 7.47a.75.75 0 011.06 0l4 4a.75.75 0 010 1.06l-4 4a.75.75 0 11-1.06-1.06L13.94 12l-3.47-3.47a.75.75 0 010-1.06z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgChevroRightMedium;
