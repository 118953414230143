import { actionOn, Actions } from 'easy-peasy';

// Types
import { IRequestsModel } from './requests.types';
import { TRootModel } from '../root/root.types';

function getThunkActionTypesByType(
  storeActions: Actions<TRootModel>,
  actionType: 'startType' | 'successType' | 'failType'
): string[] {
  const keys = Object.values(storeActions as any);

  const result: string[] = keys.reduce<string[]>((acc, model): string[] => {
    const modelActions = Object.values(model);

    const actionsTypes = modelActions.reduce<string[]>((actionTypes, action): string[] => {
      if (action[actionType]) {
        actionTypes.push(action[actionType]);
      }

      return actionTypes;
    }, []);

    return acc.concat(actionsTypes);
  }, []);

  return result;
}

export const model: IRequestsModel = {
  entities: {},

  onStartRequest: actionOn(
    (_, storeActions): string[] => getThunkActionTypesByType(storeActions, 'startType'),
    (state, target): void => {
      const name: string = target.type.slice(7, -7);

      state.entities[name] = {
        name
      };
    }
  ),

  onSuccessRequest: actionOn(
    (_, storeActions): string[] => getThunkActionTypesByType(storeActions, 'successType'),
    (state, target): void => {
      const name: string = target.type.slice(7, -9);

      delete state.entities[name];
    }
  ),

  onFailRequest: actionOn(
    (_, storeActions): string[] => getThunkActionTypesByType(storeActions, 'failType'),
    (state, target): void => {
      const name: string = target.type.slice(7, -6);

      delete state.entities[name];
    }
  )
};
