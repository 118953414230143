// Api
import { GetOptions, HttpClient } from '@pushwoosh/http-client';

// Helpers
import { getApiOrigin } from '@src/store/helpers';

// Types
import {
  TFetchByAppCodeResponse,
  TFetchByAppCodesResponse,
  TFetchAttributesValuesByEvent,
  TFetchByAppCodesParams
} from './types';

export const service = {
  async fetchByAppCode(applicationCode: string, api: HttpClient): Promise<TFetchByAppCodeResponse> {
    const options: GetOptions<TFetchByAppCodeResponse, { code: string }, never> = {
      params: {
        code: applicationCode
      },
      withAuthorization: true
    };

    // eslint-disable-next-line camelcase
    return api.get<TFetchByAppCodeResponse, { code: string }, never>(
      `${getApiOrigin()}/api/application/:code/event`,
      options
    );
  },

  async fetchAttributesValuesByEvent(
    applicationCode: string,
    idEvent: number,
    attribute: string,
    searchValue: string,
    api: HttpClient
  ): Promise<TFetchAttributesValuesByEvent> {
    const options: GetOptions<
      TFetchAttributesValuesByEvent,
      TFetchByAppCodesParams,
      {
        code: string;
        idEvent: string;
        attribute: string;
        limit: string;
        searchValue: string;
      }
    > = {
      params: {
        code: applicationCode,
        idEvent,
        attribute,
        limit: 100,
        searchValue
      },
      query: {
        code: applicationCode,
        idEvent: String(idEvent),
        attribute,
        limit: '100',
        searchValue
      },
      withAuthorization: true
    };

    return api.get<
      TFetchAttributesValuesByEvent,
      TFetchByAppCodesParams,
      never
      // eslint-disable-next-line camelcase
    >(`${getApiOrigin()}/api/application/:code/event/:idEvent`, options);
  },
  async fetchByAppCodes(
    applicationCode: string[],
    api: HttpClient
  ): Promise<TFetchByAppCodesResponse> {
    const options: GetOptions<TFetchByAppCodesResponse, any, never> = {
      params: {
        // eslint-disable-next-line quote-props
        application_code: String(applicationCode)
      },
      withAuthorization: true
    };

    // eslint-disable-next-line camelcase
    return api.get<TFetchByAppCodesResponse, any, never>(`${getApiOrigin()}/api/events`, options);
  }
};
