import { css } from 'styled-components';
import { Color } from '@pushwoosh/kit-constants';
import { IButtonProps } from '../Button.types';
import { fieldCSS, compactCSS } from './base';

const dangerShapeCSS = css<IButtonProps>`
  background: ${({ disabled }) => (disabled ? Color.LOCKED : Color.DANGER)};
  color: ${Color.CLEAR};
  border: none;

  &:hover {
    background: ${Color.DANGER_HOVER};
  }

  &:active {
    background: ${Color.DANGER_HOVER};
  }

  & > div > svg > path {
    fill: ${Color.CLEAR};
  }
`;

const dangerGhostCSS = css<IButtonProps>`
  background: transparent;
  border: none;
  color: ${({ disabled }) => (disabled ? Color.LOCKED : Color.DANGER)};

  & > div > svg > path {
    fill: ${({ disabled }) => (disabled ? Color.LOCKED : Color.DANGER)};
  }

  &:hover {
    background: ${Color.DANGER_LIGHT};
    color: ${Color.DANGER_HOVER};

    & > div > svg > path {
      fill: ${Color.DANGER_HOVER};
    }
  }

  &:active {
    color: ${Color.DANGER_PRESSED};
    background: ${Color.DANGER_LIGHT};

    & > div > svg > path {
      fill: ${Color.DANGER_PRESSED};
    }
  }
`;

export const dangerShapeFieldCSS = css<IButtonProps>`
  ${dangerShapeCSS}
  ${fieldCSS}
`;

export const dangerShapeCompactCSS = css<IButtonProps>`
  ${dangerShapeCSS}
  ${compactCSS}
`;

export const dangerGhostFieldCSS = css<IButtonProps>`
  ${fieldCSS}
  ${dangerGhostCSS}
`;

export const dangerGhostCompactCSS = css<IButtonProps>`
  ${compactCSS}
  ${dangerGhostCSS}

  &:hover {
    background: transparent;
  }

  &:active {
    background: transparent;
  }
`;
