import { css } from 'styled-components';
import { Color } from '@pushwoosh/kit-constants';
import { IButtonProps } from '../Button.types';
import { fieldCSS, compactCSS } from './base';

const secondaryShapeCSS = css<IButtonProps>`
  background: ${Color.FROZEN};
  color: ${({ disabled }) => (disabled ? Color.LOCKED : Color.MAIN)};
  border: 1px solid ${Color.FORM};

  &:hover {
    background: ${Color.CLEAR};
    color: ${Color.PRIMARY_HOVER};

    & > div > svg > path {
      fill: ${Color.PRIMARY_HOVER};
    }
  }

  &:active {
    background: ${Color.FORM};
    color: ${Color.MAIN};

    & > div > svg > path {
      fill: ${Color.MAIN};
    }
  }

  & > div > svg > path {
    fill: ${({ disabled }) => (disabled ? Color.LOCKED : Color.MAIN)};
  }
`;

const secondaryGhostCSS = css<IButtonProps>`
  background: transparent;
  color: ${({ disabled }) => (disabled ? Color.LOCKED : Color.MAIN)};
  border: none;

  &:hover {
    background: ${Color.BRIGHT_LIGHT};
    color: ${Color.PRIMARY_HOVER};

    & > div > svg > path {
      fill: ${Color.PRIMARY_HOVER};
    }
  }

  &:active {
    color: ${Color.PRIMARY_PRESSED};
    background: ${Color.BRIGHT_LIGHT};

    & > div > svg > path {
      fill: ${Color.PRIMARY_PRESSED};
    }
  }

  & > div > svg > path {
    fill: ${({ disabled }) => (disabled ? Color.LOCKED : Color.MAIN)};
  }
`;

export const secondaryShapeFieldCSS = css<IButtonProps>`
  ${secondaryShapeCSS}
  ${fieldCSS}
`;

export const secondaryShapeCompactCSS = css<IButtonProps>`
  ${secondaryShapeCSS}
  ${compactCSS}
`;

export const secondaryGhostFieldCSS = css<IButtonProps>`
  ${fieldCSS}
  ${secondaryGhostCSS}
`;

export const secondaryGhostCompactCSS = css<IButtonProps>`
  ${compactCSS}
  ${secondaryGhostCSS}

  color: ${Color.SOFT};

  & > div > svg > path {
    fill: ${Color.SOFT};
  }

  &:hover {
    background: transparent;
  }

  &:active {
    background: transparent;
  }
`;
