import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgAbSplitPointFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={24} height={24} rx={4} fill="#00A2FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 9a1 1 0 11-2 0 1 1 0 012 0zM10 9a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm4.527-1.824a1.25 1.25 0 01.527 1.688l-3.893 7.432a1.25 1.25 0 01-2.215-1.16l3.893-7.432a1.25 1.25 0 011.688-.528zM16.5 16a1 1 0 100-2 1 1 0 000 2zm0 1.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgAbSplitPointFilled;
