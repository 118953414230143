import React from 'react';
import Konva from 'konva';
import { Group, Path, Text } from 'react-konva';
import {
  useActiveCommentId,
  useSetActiveComment,
  useUpdateComment,
  useRemoveComment,
  useModeMap,
  useUpdateCommentPosition,
  useCheckOverlapComments
} from '@src/store/hooks';
import { useStoreActions } from '@src/store/store';

// Constamts
import { Color } from '@pushwoosh/kit-constants';

// Components
import { CommentTooltip } from './CommentTooltip';

// Types
import { ICommentProps } from './Comment.types';

const SMALL_TEXT_OFFSET = 4.5;
const LARGE_TEXT_OFFSET = 9;

export function Comment(props: ICommentProps): JSX.Element {
  const { text, position, commentId, message, createdAt } = props;

  const activeCommentId = useActiveCommentId();
  const setActiveComment = useSetActiveComment();
  const handleCommentUpdate = useUpdateComment();
  const handleCommentRemove = useRemoveComment();
  const handleCommentCancel = useStoreActions((state) => state.comments.cancel);
  const modeMap = useModeMap();
  const actionUpdateCommentPosition = useUpdateCommentPosition();
  const handleCommentsCheckOverlap = useCheckOverlapComments();

  const textPosition = text.length < 2 ? LARGE_TEXT_OFFSET : SMALL_TEXT_OFFSET;
  const isActive = activeCommentId === commentId;

  const [isOpenTooltip, setIsOpenTooltip] = React.useState(isActive);

  React.useEffect(() => {
    if (!isActive) {
      setIsOpenTooltip(false);
    }
  }, [isActive]);

  const onClick = React.useCallback(() => {
    setActiveComment(commentId);
    setIsOpenTooltip((state) => !state);
  }, [setActiveComment, commentId]);

  const handleCommentDragMove = React.useCallback(() => {
    setActiveComment(commentId);
    setIsOpenTooltip(false);
  }, [setIsOpenTooltip, setActiveComment, commentId]);

  const handleCommentDragEnd = React.useCallback(
    (event: Konva.KonvaEventObject<DragEvent>) => {
      if (isActive && !message) {
        setIsOpenTooltip(true);
      }

      const { commentId, x, y } = event.currentTarget.getAttrs();

      actionUpdateCommentPosition(commentId, { x, y });
      handleCommentsCheckOverlap();
    },
    [isActive, message, actionUpdateCommentPosition, handleCommentsCheckOverlap]
  );

  const handleCloseTooltip = React.useCallback(() => {
    if (!message) {
      handleCommentCancel(commentId);
    }

    setIsOpenTooltip(false);
  }, [commentId, message, handleCommentCancel]);

  return (
    <>
      {isOpenTooltip && (
        <CommentTooltip
          x={position.x}
          mode={modeMap}
          createdAt={createdAt}
          y={position.y}
          commentId={commentId}
          message={message}
          closeTooltip={handleCloseTooltip}
          onUpdateComment={handleCommentUpdate}
          onRemoveComment={handleCommentRemove}
        />
      )}
      <Group
        onDragEnd={handleCommentDragEnd}
        onDragMove={handleCommentDragMove}
        onClick={onClick}
        name="comment"
        commentId={commentId}
        x={position.x}
        y={position.y}
        draggable
      >
        <Path
          fill={isActive ? Color.PRIMARY : Color.MAIN}
          perfectDrawEnabled={false}
          shadowForStrokeEnabled={false}
          data="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 18.4991 25.9538 21.6195 23.2707 24.143C21.942 25.3927 20.4447 26.5087 18.9617 27.5758C18.6697 27.7859 18.3788 27.9938 18.0896 28.2004C16.8933 29.0552 15.7256 29.8896 14.6292 30.7772C14.2623 31.0742 13.7377 31.0742 13.3708 30.7772C12.2744 29.8897 11.1067 29.0553 9.91047 28.2005C9.62121 27.9938 9.33029 27.7859 9.03831 27.5758C7.5553 26.5087 6.05799 25.3927 4.72923 24.1429C2.04623 21.6194 0 18.4991 0 14Z"
        />
        <Text
          fill="white"
          perfectDrawEnabled={false}
          shadowForStrokeEnabled={false}
          x={textPosition}
          y={9}
          fontFamily="Roboto"
          fontSize={16}
          fontStyle="700"
          text={text}
        />
      </Group>
    </>
  );
}
