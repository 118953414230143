import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgUploadMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 6.25a4.752 4.752 0 00-4.549 3.377.75.75 0 01-.386.456A3.25 3.25 0 007 16.25h.25a.75.75 0 010 1.5H7a4.75 4.75 0 01-2.693-8.663c.194-.134.349-.318.441-.535a6.251 6.251 0 0110.035-2.104c.198.187.455.3.726.329a4.754 4.754 0 014.048 3.38c.09.302.287.562.547.739A3.75 3.75 0 0118 17.75h-1.25a.75.75 0 010-1.5H18a2.25 2.25 0 00.74-4.376.75.75 0 01-.501-.646 3.25 3.25 0 00-3.444-2.972.75.75 0 01-.625-.272A4.739 4.739 0 0010.5 6.25zm-2.03 8.28a.75.75 0 001.06 0l1.379-1.378a.2.2 0 01.341.142V19a.75.75 0 001.5 0v-5.707a.2.2 0 01.341-.14l1.379 1.377a.75.75 0 101.06-1.06l-3-3a.75.75 0 00-1.06 0l-3 3a.75.75 0 000 1.06z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgUploadMediumLined;
