import { thunk, actionOn } from 'easy-peasy';

import { Tag } from '@src/common/types/tag';
import { ITagModel } from './tag.types';

export const model: ITagModel = {
  items: [],

  load: thunk(async (_, __, helpers): Promise<Tag[]> => {
    const {
      injections: { tagService, api }
    } = helpers;

    const response = await tagService.fetch(api);

    return response.payload;
  }),

  onLoad: actionOn(
    (state): string => state.load.successType,
    (state, target): void => {
      state.items = target.result;
    }
  )
};
