import React from 'react';
import { TagProps } from './Tag.types';
import { TagContainer, Label, Value } from './styled';

export function Tag(props: TagProps): JSX.Element {
  const { label, value } = props;

  return (
    <TagContainer>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </TagContainer>
  );
}
