import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgWarningRoundMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12c0-4.974 4.025-9 9-9 4.974 0 9 4.025 9 9 0 4.974-4.025 9-9 9-4.974 0-9-4.025-9-9zm8-4.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5V13a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V7.5zm0 8a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgWarningRoundMediumFilled;
