import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';

export const TooltipContentItem = styled.div`
  display: flex;
  max-width: 216px;
  :not(:first-child) {
    margin-top: ${Spacing.S1};
  }
`;
