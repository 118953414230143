import * as React from 'react';
import Konva from 'konva';
import { Label, Text, Tag } from 'react-konva';

// Constants
import { CANVAS_BACKGROUND_COLOR } from '@src/common/constants/canvas';

// Helpers
import { formatNumber } from '@src/common/helpers';
import { calculateCenterArrow } from '../Arrow/helpers/calculateCenterArrow';

// Types
import { IPropsStatisticsBlock } from './StatisticsBlock.types';

export const StatisticsBlock = ({
  fromX,
  fromY,
  toX,
  toY,
  countTransitions,
  fill,
  outputKey
}: IPropsStatisticsBlock): JSX.Element => {
  const forRef = React.useRef<Konva.Stage>(null);
  const { centerX, centerY } = calculateCenterArrow({ fromX, fromY, toX, toY, outputKey });

  const text = formatNumber(countTransitions);

  /* eslint-disable @typescript-eslint/no-explicit-any */
  return (
    <Label pointerWidth={10} x={centerX} y={centerY}>
      <Tag
        fill={fill}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
        opacity={countTransitions ? 1 : 0.9}
        cornerRadius={4}
        stroke={CANVAS_BACKGROUND_COLOR}
        strokeWidth={2}
        ref={forRef as any}
      />
      <Text
        height={20}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
        fontSize={14}
        fontStyle="bold"
        text={text}
        fill="#fff"
        padding={4}
        wrap="none"
      />
    </Label>
  );
  /* eslint-enable @typescript-eslint/no-explicit-any */
};
