import * as React from 'react';
import { LeafBase } from '@src/common/structureObjects/leaf';

// Hooks Store
import { useCurrentJourneyId, useGetJourneyById } from '@src/store/hooks';

import { useAutoLoadFullPointData } from '@src/ui/features/SwitchDetailPoint/hooks/useAutoLoadFullPointData';

import { useStoreActions } from '@src/store/store';
import { useDetailedPointInfo } from '@src/ui/features/SwitchDetailPoint/hooks/useDetailedPointInfo';

// UI Components
import { DetailWaitUntil } from '@src/ui/features/WaitUntil';
import { DetailApiEntry } from '@src/ui/features/ApiEntry';
import { DetailStartEvent } from '@src/ui/features/StartEvent';
import { DetailStartSegment } from '@src/ui/features/StartSegment';
import { DetailSegmentSplitter } from '@src/ui/features/SegmentSplitter';
import { DetailSendPush } from '@src/ui/features/SendPush';
import { DetailSendSms } from '@src/ui/features/SendSms';
import { DetailSendEmail } from '@src/ui/features/SendEmail';
import { DetailSendInApp } from '@src/ui/features/SendInApp';
import { DetailABSplitter } from '@src/ui/features/ABSplitter';
import { DetailSetTags } from '@src/ui/features/SetTags';
import { DetailWebhook } from '@src/ui/features/Webhook';
import { DetailWaitEvent } from '@src/ui/features/WaitEvent';

// Enums
import { PointType } from '@src/common/constants/point';

// Types
import { IUniqueId } from '@src/common/types/entities';
import { TDocumentationMenuRoutePath } from '@src/store/models/documentationMenu/documentationMenu.types';
import { DetailReachabilityCheck } from '@src/ui/features/ReachabilityCheck';
import { TWaitEvent } from '@src/common/types/points';

interface IProps {
  pointId: IUniqueId['id'];
  isChanged?: boolean;
}

type TExtendedWaitEvent = TWaitEvent & { applicationName: string; id: string };

export function SwitchDetailPoint(props: IProps): JSX.Element {
  const { pointId, isChanged } = props;
  const detailedPointInfo = useDetailedPointInfo(pointId);
  const journeyId = useCurrentJourneyId();
  const getJourneyById = useGetJourneyById();
  const currentJourney = getJourneyById(journeyId);

  const setRouteDocumentationMenu = useStoreActions(
    (actions) => actions.documentationMenu.setRoutePath
  );

  useAutoLoadFullPointData(pointId);

  React.useEffect(() => {
    setRouteDocumentationMenu(`/point-${detailedPointInfo.type}` as TDocumentationMenuRoutePath);
  }, [detailedPointInfo.type, setRouteDocumentationMenu]);

  switch (detailedPointInfo.type) {
    case PointType.EVENT: {
      return (
        <DetailStartEvent
          eventName={detailedPointInfo.eventName}
          eventConditions={detailedPointInfo.eventConditions}
          applicationCode={detailedPointInfo.applicationCode}
          pointTitle={detailedPointInfo.pointTitle}
          isChanged={isChanged}
        />
      );
    }

    case PointType.WAIT: {
      return (
        <DetailWaitUntil
          delay={detailedPointInfo.delay}
          exactTime={detailedPointInfo.exactTime}
          pointId={pointId}
          dynamicTime={detailedPointInfo.dynamicTime}
          exactDateTime={detailedPointInfo.exactDateTime}
          exactWeekDaysTime={detailedPointInfo.exactWeekDaysTime}
        />
      );
    }

    case PointType.API: {
      return (
        <DetailApiEntry
          journeyId={journeyId}
          applicationCode={detailedPointInfo.applicationCode}
          pointTitle={detailedPointInfo.pointTitle}
          attributes={detailedPointInfo.attributes}
          entries={detailedPointInfo.entries}
          launches={detailedPointInfo.launches}
        />
      );
    }

    case PointType.SEND_PUSH: {
      return (
        <DetailSendPush
          applicationCode={detailedPointInfo.applicationCode}
          campaignCode={detailedPointInfo.campaignCode}
          content={detailedPointInfo.content}
          sent={detailedPointInfo.sent}
          opened={detailedPointInfo.opened}
          conversion={detailedPointInfo.conversion}
          behaviorSplitterEnabled={detailedPointInfo.behaviorSplitterEnabled}
          behaviorSplitterInterval={detailedPointInfo.behaviorSplitterInterval}
          pointTitle={detailedPointInfo.pointTitle}
          pointId={pointId}
          enabledPersonalise={detailedPointInfo.enabledPersonalise}
          waitOptimalTime={detailedPointInfo.waitOptimalTime}
          defaultOptimalTime={detailedPointInfo.defaultOptimalTime}
          placeholders={detailedPointInfo.placeholders}
          title={detailedPointInfo.title}
          presetCode={detailedPointInfo.presetCode}
        />
      );
    }

    case PointType.SEND_SMS: {
      return (
        <DetailSendSms
          applicationCode={detailedPointInfo.applicationCode}
          campaignCode={detailedPointInfo.campaignCode}
          content={detailedPointInfo.content}
          sent={detailedPointInfo.sent}
          delivered={detailedPointInfo.delivered}
          behaviorSplitterEnabled={detailedPointInfo.behaviorSplitterEnabled}
          behaviorSplitterInterval={detailedPointInfo.behaviorSplitterInterval}
          pointTitle={detailedPointInfo.pointTitle}
          enabledPersonalise={detailedPointInfo.enabledPersonalise}
          placeholders={detailedPointInfo.placeholders}
        />
      );
    }

    case PointType.SET_TAGS: {
      return (
        <DetailSetTags
          tags={detailedPointInfo.tags}
          pointTitle={detailedPointInfo.pointTitle}
          pointId={pointId}
        />
      );
    }

    case PointType.WEBHOOK: {
      return (
        <DetailWebhook
          url={detailedPointInfo.url}
          requestTemplate={detailedPointInfo.requestTemplate}
          headers={detailedPointInfo.headers}
          pointTitle={detailedPointInfo.pointTitle}
          pointId={pointId}
        />
      );
    }

    case PointType.SEGMENT: {
      return (
        <DetailStartSegment
          filterTitle={detailedPointInfo.filterTitle}
          filterCode={detailedPointInfo.filterCode}
          entryLimitsEnabled={detailedPointInfo.entryLimitsEnabled}
          entryLimitsPerSecond={detailedPointInfo.entryLimitsPerSecond}
          enabledRepeat={detailedPointInfo.repeat.enabled}
          frequencyRepeat={detailedPointInfo.repeat.frequency}
          everyRepeat={detailedPointInfo.repeat.every}
          datesRepeat={detailedPointInfo.repeat.dates}
          timeRepeat={detailedPointInfo.repeat.time}
          activatedAt={currentJourney.activatedAt}
          pointTitle={detailedPointInfo.pointTitle}
          applicationId={detailedPointInfo.applicationId}
        />
      );
    }

    case PointType.SEND_EMAIL: {
      return (
        <DetailSendEmail
          pointId={pointId}
          applicationCode={detailedPointInfo.applicationCode}
          campaignCode={detailedPointInfo.campaignCode}
          pointTitle={detailedPointInfo.pointTitle}
          enabledPersonalise={detailedPointInfo.enabledPersonalise}
          sendOnlyToSubscribed={detailedPointInfo.sendOnlyToSubscribed}
          placeholders={detailedPointInfo.placeholders}
          behaviorSplitterEnabled={detailedPointInfo.behaviorSplitterEnabled}
          behaviorSplitterInterval={detailedPointInfo.behaviorSplitterInterval}
        />
      );
    }

    case PointType.BOOLEAN_SPLITTER: {
      return (
        <DetailSegmentSplitter
          filterCode={detailedPointInfo.filterCode}
          filterName={detailedPointInfo.filterName}
          pointTitle={detailedPointInfo.pointTitle}
          applicationId={currentJourney.params.applicationCode}
          pointId={pointId}
        />
      );
    }

    case PointType.WAIT_EVENT: {
      return (
        <DetailWaitEvent
          waitEvents={detailedPointInfo.waitEvents as TExtendedWaitEvent[]}
          branches={LeafBase.getBranchesFromJsom(
            detailedPointInfo.waitScript,
            detailedPointInfo.waitEvents
          )}
          pointTitle={detailedPointInfo.pointTitle}
          pointId={pointId}
          isChanged={isChanged}
          applicationCode={currentJourney.params.applicationCode}
        />
      );
    }

    case PointType.AB_SPLITTER: {
      return (
        <DetailABSplitter
          split={detailedPointInfo.split}
          statistics={detailedPointInfo.statistics}
          pointId={pointId}
        />
      );
    }

    case PointType.SEND_IN_APP: {
      return (
        <DetailSendInApp
          richMedia={detailedPointInfo.richMedia}
          pointTitle={detailedPointInfo.pointTitle}
          expireInDays={detailedPointInfo.expireInDays}
          pointId={pointId}
          applicationCode={currentJourney.params.applicationCode}
          campaignCode={detailedPointInfo.campaignCode}
        />
      );
    }

    case PointType.REACHABILITY_CHECK: {
      return (
        <DetailReachabilityCheck pointId={pointId} filterType={detailedPointInfo.filterType} />
      );
    }

    default: {
      return null;
    }
  }
}
