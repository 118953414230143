import * as React from 'react';

// Types
import { IProps } from './Plate.types';

// Styled
import { Container } from './styled';

function Plate(props: IProps, ref: React.Ref<HTMLDivElement>): JSX.Element {
  const {
    className,
    children,
    view = 'light',
    isFlat = false,
    withBorder = false,
    withShadow = false,
    onClick
  } = props;

  return (
    <Container
      ref={ref}
      className={className}
      onClick={onClick}
      view={view}
      withBorder={withBorder}
      withShadow={withShadow}
      isFlat={isFlat}
    >
      {children}
    </Container>
  );
}

export const PlateRef = React.forwardRef(Plate);
