// Constants
import { TypeTag } from '@src/common/constants/tag';
import { AttributeTypeEnum } from '@src/common/constants/attribute';

export const matchTagToAttribute = (
  tagType: TypeTag,
  attributeType: AttributeTypeEnum
): boolean => {
  if (tagType === TypeTag.INTEGER && attributeType === AttributeTypeEnum.INTEGER) {
    return true;
  }
  const alsoATagTypes = tagType === TypeTag.PRICE || tagType === TypeTag.VERSION;

  if (tagType === TypeTag.STRING && attributeType === AttributeTypeEnum.STRING) {
    return true;
  }

  if (alsoATagTypes && attributeType === AttributeTypeEnum.STRING) {
    return true;
  }

  if (tagType === TypeTag.LIST && attributeType === AttributeTypeEnum.LIST) {
    return true;
  }
  if (tagType === TypeTag.DATE && attributeType === AttributeTypeEnum.DATE) {
    return true;
  }
  if (tagType === TypeTag.BOOLEAN && attributeType === AttributeTypeEnum.BOOLEAN) {
    return true;
  }

  return false;
};
