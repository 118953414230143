import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgEmailSmallFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.586 3.586C1 4.172 1 5.114 1 7v2.5c0 1.886 0 2.828.586 3.414.586.586 1.528.586 3.414.586h6c1.886 0 2.828 0 3.414-.586C15 12.328 15 11.386 15 9.5V7c0-1.886 0-2.828-.586-3.414C13.828 3 12.886 3 11 3H5c-1.886 0-2.828 0-3.414.586zm2.219 1.729a.75.75 0 10-.61 1.37l4.5 2a.75.75 0 00.61 0l4.5-2a.75.75 0 00-.61-1.37L8 7.179 3.805 5.315z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgEmailSmallFilled;
