import React from 'react';
import { ChevronIcon } from '@pushwoosh/kit-icons';
import { Color } from '@pushwoosh/kit-constants';
import { TModal } from '@src/common/types/modal';
import { StatisticGrid, StatisticCell } from '@src/ui/kit';
import { useDropOffs } from './hooks/useDropOffs';
import { LabelContainer, Title, Wrap } from './styled';

type Props = {
  pointId: string;
  modal?: TModal;
};

export function DropOffStat(props: Props): JSX.Element {
  const { pointId, modal } = props;
  const [isOpened, setOpened] = React.useState(false);
  const { formattedDropOffs, allDropOffs, dropOffs } = useDropOffs(pointId, modal);

  if (dropOffs.length === 0) {
    return null;
  }

  return (
    <Wrap>
      {isOpened ? (
        <StatisticGrid
          data={formattedDropOffs}
          renderFirstRow={(item) => (
            <StatisticCell
              onClick={() => setOpened(false)}
              label={
                <LabelContainer>
                  <Title>{item.label}</Title>
                  <ChevronIcon size="small" direction="up" />
                </LabelContainer>
              }
              value={item.value}
              valueColor={Color.DANGER}
              bgColor={Color.DANGER_LIGHT}
            />
          )}
        />
      ) : (
        <StatisticCell
          onClick={() => setOpened(true)}
          label={
            <LabelContainer>
              <Title>Drop-offs</Title>
              <ChevronIcon size="small" direction="down" />
            </LabelContainer>
          }
          value={allDropOffs}
          valueColor={Color.DANGER}
        />
      )}
    </Wrap>
  );
}
