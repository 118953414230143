import styled from 'styled-components';
import { Spacing, FontSize, FontWeight } from '@pushwoosh/kit-constants';

export const Container = styled.div`
  width: 100%;
`;

export const Target = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  cursor: pointer;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: ${FontSize.REGULAR};
  font-weight: ${FontWeight.MEDIUM};
`;

export const StyledIcon = styled.div<{ isRotate: boolean }>`
  ${(props): string => (props.isRotate ? 'transform: rotate(90deg)' : 'transform: rotate(0deg)')};
  margin-right: ${Spacing.S1};
`;

export const Children = styled.div`
  padding-bottom: ${Spacing.S5};
`;
