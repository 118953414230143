import * as React from 'react';
import { useVisibleSidebar, useCurrentJourneyId, useGetJourneyById } from '@src/store/hooks';
import { JourneyStatus } from '@src/common/constants/journey';

// Components
import { LoadingOverlay } from '@pushwoosh/ui/lib/molecules/LoadingOverlay';
import { Settings } from '@src/ui/features/Settings';
import { Assistant } from '@src/ui/features/Assistant';
import { LaunchButton } from '@src/ui/features/LaunchButton';

import { LayoutWrap, CampaignBar, SidebarWrap, Content, HeaderWrap } from './styled';

interface IProps {
  Sidebar: React.ComponentType;
  Header: React.ComponentType;
  children: React.ReactNode | React.ReactNode[];
  isLoading?: boolean;
}

export function LayoutEditJourney(props: IProps): JSX.Element {
  const { Sidebar, children, isLoading = false, Header } = props;
  const isVisibleSidebar = useVisibleSidebar();
  const currentJourneyId = useCurrentJourneyId();
  const getJourneyById = useGetJourneyById();
  const journey = getJourneyById(currentJourneyId);
  const status = journey?.status;

  if (isLoading || !journey) {
    return <LoadingOverlay size="l" isActive />;
  }

  return (
    <LayoutWrap>
      <HeaderWrap>
        <Header />
      </HeaderWrap>
      <CampaignBar isVisibleSidebar={isVisibleSidebar}>
        <Settings />
        {(status === JourneyStatus.DRAFT || status === JourneyStatus.PAUSED) && <Assistant />}
        <LaunchButton />
      </CampaignBar>
      <SidebarWrap>
        <Sidebar />
      </SidebarWrap>

      <Content>{children}</Content>
    </LayoutWrap>
  );
}
