import { IPreset } from '@src/common/types/preset';
import { Placeholder } from '@src/common/types/placeholder';

export const getPlaceholderFromPreset = (preset: IPreset): Placeholder[] => {
  const titlePlaceholders = preset.title.match(/(?:\{)(.*?)(?=\|)/g) || [];
  const contentPlaceholders = preset.content.match(/(?:\{)(.*?)(?=\|)/g) || [];
  const formatContentPlaceholders = contentPlaceholders.map((placeholder) =>
    placeholder.slice(1, placeholder.length)
  );
  const formatTitlePlaceholders = titlePlaceholders.map((placeholder) =>
    placeholder.slice(1, placeholder.length)
  );
  const placeholdersNames = new Set([...formatTitlePlaceholders, ...formatContentPlaceholders]);
  const placeholders = Array.from(placeholdersNames).map((placeholderName) => ({
    name: placeholderName,
    pointID: '',
    eventName: '',
    attribute: '',
    isSelected: false
  }));

  return placeholders;
};
