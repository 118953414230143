import styled from 'styled-components';
import { Color } from '@pushwoosh/kit-constants';

export const Wrap = styled.div`
  position: relative;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Color.CLEAR};
  border-bottom: 1px solid ${Color.DIVIDER};
  box-shadow: 0px -4px 4px rgb(0 0 0 / 10%);
`;
