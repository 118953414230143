import * as React from 'react';
import { Field } from '@pushwoosh/pwkit-field';

// Components
import { TimeStepper } from '@src/ui/kit/TimeStepper';
import { TimeStepperExactTime } from '@src/ui/kit/TimeStepperExactTime';
import { FormSelect } from '@src/ui/form/FormSelect';

// Constants
import { ModalType, SECONDS_IN_DAY, SECONDS_IN_MINUTE } from '@src/common/constants';
import { WaitMode } from '@src/common/constants/wait';
import { useCloseAllModals, useOpenNewModal, useRestrictions } from '@src/store/hooks';
import { PremiumFeatures } from '@src/ui/features/Modals/PremiumModal/types';
import { DynamicTime } from '../DynamicTime';
import { DateSettings } from '../DateSettings';
import { WeekSettings } from '../WeekSettings';
import { getDeayOption } from './helpers/getDelayOptions';

// Styled
import { Container, OptionsWrap, Text } from './styled';

// Types
import { TFormEditingPointWaitProps } from './WaitForm.types';

const MIN_DELAY = SECONDS_IN_MINUTE * 1;
const MAX_DELAY = SECONDS_IN_DAY * 90;

const delayOptions = getDeayOption();

export function WaitForm(
  props: TFormEditingPointWaitProps & {
    applicationCode: string;
    onSubmit?: () => void;
  }
): JSX.Element {
  const { onSubmit, names, values, applicationCode, setFieldValue } = props;

  const { allowCjDynamicDelay } = useRestrictions();
  const openNewModal = useOpenNewModal();
  const closeAllModals = useCloseAllModals();

  const handleChangeMode = React.useCallback(
    (name, value) => {
      if (value === WaitMode.Dynamic && !allowCjDynamicDelay) {
        onSubmit();
        closeAllModals();
        openNewModal({
          type: ModalType.PREMIUM,
          data: {
            premiumType: PremiumFeatures.DYNAMIC
          }
        });
      } else {
        setFieldValue(names.waitMode, value as WaitMode);
      }
    },
    [onSubmit, names, setFieldValue, allowCjDynamicDelay, openNewModal, closeAllModals]
  );

  return (
    <Container>
      <FormSelect
        label="Time Delay Option"
        onChange={handleChangeMode}
        options={delayOptions}
        name={names.waitMode}
        value={values.waitMode}
      />
      {values.waitMode === WaitMode.Delay && (
        <OptionsWrap>
          <Text>{'Set the amount of time user should wait before moving forward.'}</Text>
          <Field title="" helpText="The maximum time period to wait is 30 days.">
            <TimeStepper
              name={names.delay}
              value={values.delay}
              onChange={setFieldValue}
              maxInSeconds={MAX_DELAY}
              minInSeconds={MIN_DELAY}
              maxDayValue={30}
            />
          </Field>
        </OptionsWrap>
      )}
      {values.waitMode === WaitMode.Wait && (
        <OptionsWrap>
          <Text>
            {
              'Set the specific time to continue the journey. If the time has already passed, user will wait till the next day.'
            }
          </Text>
          <Field title="Wait till specific time" helpText="On user’s timezone">
            <TimeStepperExactTime names={names} value={values} onChange={setFieldValue} />
          </Field>
        </OptionsWrap>
      )}
      {values.waitMode === WaitMode.Dynamic && (
        <DynamicTime
          values={values}
          names={names}
          applicationCode={applicationCode}
          setFieldValue={setFieldValue}
        />
      )}
      {values.waitMode === WaitMode.Date && (
        <DateSettings values={values} names={names} setFieldValue={setFieldValue} />
      )}
      {values.waitMode === WaitMode.Week && (
        <WeekSettings values={values} names={names} setFieldValue={setFieldValue} />
      )}
    </Container>
  );
}
