import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPlatformLogoMacOsMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM8.5 6H7l4.25 6L7 18h1.5l3.5-4.941L15.5 18H17l-4.25-6L17 6h-1.5L12 10.941 8.5 6z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPlatformLogoMacOsMediumFilled;
