import { action, computed, thunk } from 'easy-peasy';
import { format } from 'date-fns';

// Types
import { DropOff, IPointOutputStatistics, TPointStatistics } from '@src/common/types/statistics';
import { IUniqueId } from '@src/common/types/entities';
import { DropOffsType, TimePeriod } from '@src/common/constants/stats';
import { IStatisticsModel } from './statistics.type';

export const model: IStatisticsModel = {
  points: [],
  isLoading: true,
  journeyListStats: {},
  journey: {
    total: 0,
    inside: 0,
    left: 0,
    achievedGoal: 0,
    leftByError: 0,
    avgCTR: 0,
    uniqueUsersCount: 0,
    goalsToPointsMap: {},
    pointsToGoalsMap: {}
  },
  periodSettings: {
    period: TimePeriod.ALL_TIME,
    dateFrom: new Date(),
    dateTo: new Date()
  },

  getPointStatisticsById: computed(
    (state): ((pointId: IUniqueId['id']) => TPointStatistics) =>
      (pointId): TPointStatistics =>
        state.points.find((point): boolean => point.pointId === pointId)
  ),

  getPointDropOffs: computed(
    (state): ((pointId: IUniqueId['id']) => DropOff[]) =>
      (pointId): DropOff[] =>
        state.pointOutputsStats
          ?.filter((output): boolean => output.fromPointId === pointId)
          .reduce((prevValue, output) => [...prevValue, ...output.dropOffs], [])
  ),

  pointOutputsStats: computed((state): IPointOutputStatistics[] =>
    state.points.reduce<IPointOutputStatistics[]>((acc, pointStat): IPointOutputStatistics[] => {
      const outputs = pointStat.pointOutputStat.map((pointOutputStat): IPointOutputStatistics => {
        const stat = {
          fromPointId: pointStat.pointId,
          toPointId: pointOutputStat.nextPointId,
          key: pointOutputStat.key,
          count: pointOutputStat.count,
          dropOffs: pointOutputStat.dropOffs,
          errors: {
            error: pointOutputStat.errors.error
          }
        };

        const dropOffs: DropOff[] = [];

        Object.entries(pointOutputStat.errors).forEach((error) => {
          const dropOffType = error[0] as unknown as DropOffsType;

          if (Object.values(DropOffsType).includes(dropOffType)) {
            dropOffs.push({
              type: dropOffType,
              value: error[1]
            });
          }
        });

        stat.dropOffs = dropOffs;

        return stat;
      });

      return acc.concat(outputs);
    }, [])
  ),

  loadJourneyListStats: thunk(async (state, payload, { injections }): Promise<void> => {
    const { fetchJourneyListStat } = injections.statisticsService;
    const { api } = injections;
    const { journeyIds } = payload;

    const response = await fetchJourneyListStat(api, journeyIds);

    const { items } = response.payload;

    state.setJourneyListStats(items);
  }),

  load: thunk(async (state, payload, { injections }): Promise<void> => {
    const { fetchStatisticsData } = injections.statisticsService;
    const { api } = injections;

    let response;

    if (payload.periodSettings.period === TimePeriod.ALL_TIME) {
      response = await fetchStatisticsData(payload.id, api);
    } else {
      const fromDate = format(payload.periodSettings.dateFrom, 'yyyy-MM-dd');
      const toDate = format(payload.periodSettings.dateTo, 'yyyy-MM-dd');

      response = await fetchStatisticsData(payload.id, api, fromDate, toDate);
    }

    const { points, journey } = response.payload;

    state.setPointStatistics(points);
    state.setJourneyStatistics(journey);

    state.setIsLoading(false);
  }),

  setPeriodSettings: action((state, payload): void => {
    state.periodSettings = payload;
  }),

  setPointStatistics: action((state, payload): void => {
    state.points = payload;
  }),

  setJourneyStatistics: action((state, payload): void => {
    state.journey = payload;
  }),

  setIsLoading: action((state, payload): void => {
    state.isLoading = payload;
  }),

  setJourneyListStats: action((state, payload): void => {
    state.journeyListStats = payload;
  })
};
