import styled from 'styled-components';

// Constants
import { Spacing } from '@pushwoosh/kit-constants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ContainerProps = {
  isDisabled: boolean;
};

export const Container = styled.div<ContainerProps>`
  margin-top: ${Spacing.S5};
  margin-bottom: ${Spacing.S5};
  max-width: 100%;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
`;
