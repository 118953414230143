import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';
import { Select } from '@src/ui/kit/Select';

export const ListTagValue = styled.div`
  display: flex;
  align-items: center;
`;

export const ListValues = styled.div`
  flex: 1;
`;

export const OperationSelect = styled(Select)`
  margin-left: ${Spacing.S3};

  .customSelect__control {
    min-width: 120px;
  }
`;
