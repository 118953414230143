export const isCollide = (
  // coords for the first box
  x1: number,
  y1: number,
  width1: number,
  height1: number,

  // coords for the second box
  x2: number,
  y2: number,
  width2: number,
  height2: number
): boolean => !(y1 + height1 < y2 || y1 >= y2 + height2 || x1 + width1 < x2 || x1 >= x2 + width2);
