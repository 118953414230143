import styled from 'styled-components';
import { Paragraph } from '@pushwoosh/kit-typography';
import { Spacing } from '@pushwoosh/kit-constants';

export const StyledDescription = styled(Paragraph)`
  margin-top: ${Spacing.S3};
`;

export const StyledSwitchWrapper = styled.div`
  margin-top: ${Spacing.S5};
`;
