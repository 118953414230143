import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSuperUserMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 4H7v3a2 2 0 002 2h6a2 2 0 002-2V4h-2v2h-2V4h-2v2H9V4zM5 18a2 2 0 012-2h10a2 2 0 012 2v1a1 1 0 01-1 1H6a1 1 0 01-1-1v-1zM16 10H8l-1 5h10l-1-5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgSuperUserMediumFilled;
