import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgArrowRightSmall(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.47 5.53a.75.75 0 011.06-1.06l3 3a.75.75 0 010 1.06l-3 3a.75.75 0 11-1.06-1.06l1.72-1.72H4a.75.75 0 010-1.5h7.19L9.47 5.53z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgArrowRightSmall;
