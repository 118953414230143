import React from 'react';
import { format } from 'date-fns';

// UI Hooks
import { useForm } from '@src/ui/hooks';

// Components
import { AutosizeTextarea, Button } from '@src/ui/kit';

// Types
import { ICommentFormProps, ICommentFormValues } from './CommentForm.types';

// Styled
import { SaveButton, FormButtons } from './styled';

export function CommentForm(props: ICommentFormProps): JSX.Element {
  const { message, commentId, position, createdAt, closeTooltip, onUpdateComment } = props;

  const { values, setFieldValue, handleSubmit } = useForm<ICommentFormValues>({
    initialValues: {
      message: message ?? ''
    },
    onSubmit(formValues): void {
      onUpdateComment({
        id: commentId,
        type: 'comment',
        message: formValues.message,
        createdAt: createdAt || format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
        deleted: false,
        position
      });
    }
  });

  const commentRef = React.useRef(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (commentRef.current && !commentRef.current.contains(event.target)) {
        handleSubmit();
        closeTooltip();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [commentRef]);

  const handleEditComment = React.useCallback(
    (value: string) => {
      setFieldValue('message', value);
    },
    [setFieldValue]
  );

  return (
    <div ref={commentRef}>
      <AutosizeTextarea
        value={values.message}
        onChange={handleEditComment}
        placeholder="Write..."
      />
      <FormButtons>
        <SaveButton
          color="primary"
          disabled={!values.message}
          view="shape"
          size="compact"
          onClick={() => {
            handleSubmit();
            closeTooltip();
          }}
        >
          Save
        </SaveButton>
        <Button color="secondary" view="shape" size="compact" onClick={closeTooltip}>
          Cancel
        </Button>
      </FormButtons>
    </div>
  );
}
