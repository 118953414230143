import React from 'react';
import { ArrowIcon } from '@pushwoosh/kit-icons';
import { Badge } from '@src/ui/kit';
import { VictoryGroup, VictoryArea } from 'victory';
import { Color } from '@pushwoosh/kit-constants';
import { getStatusMessage } from './helpers/getStatusMessage';
import { getStatusIcon } from './helpers/getStatusIcon';
import { getChartFill } from './helpers/getChartFill';
import { getChartStroke } from './helpers/getChartStroke';
import {
  Container,
  Cell,
  Item,
  Status,
  Values,
  Significant,
  Stack,
  ConversionWrap,
  BadgeValue,
  IconWrap,
  StyledH4
} from './styled';
import { Chart } from './types';

type Props = {
  branchName: string;
  branchValue: number;
  visits: number;
  goals: number;
  conversion: number;
  baseLineConversion: number;
  isBaseline: boolean;
  isSignificant: boolean;
  zScore: number;
  pValue: number;
  isWinner: boolean;
  uplift: number;
  charts: Chart[];
};

export function Row(props: Props): JSX.Element {
  const {
    isBaseline,
    isSignificant,
    branchName,
    branchValue,
    visits,
    goals,
    conversion,
    zScore,
    pValue,
    charts,
    isWinner,
    uplift,
    baseLineConversion
  } = props;

  const cellRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (cellRef.current) {
      const svgArray = cellRef.current.getElementsByTagName('svg');

      svgArray.item(0).setAttribute('preserveAspectRatio', 'none');
    }
  }, []);

  const conversionDiff = (conversion - baseLineConversion) / conversion;

  const isDisabled = branchValue === 0;

  return (
    <Container>
      <Cell>
        <Item>
          <Status isDisabled={isDisabled} />
          <StyledH4 isDisabled={isDisabled}>{branchName}:</StyledH4>
          <StyledH4 isDisabled={isDisabled}>{branchValue}%</StyledH4>
          {isWinner && (
            <Badge isBoldText isSmall color={isDisabled ? Color.LOCKED : Color.SUCCESS}>
              WINNER
            </Badge>
          )}
        </Item>
        <Item>
          <div />
          <Values>Visits: {visits}</Values>
          <Values>Goals: {goals}</Values>
        </Item>
      </Cell>
      <Cell>
        <Item>
          <ConversionWrap>
            <StyledH4 isDisabled={isDisabled}>{(conversion * 100).toFixed(2)}%</StyledH4>
            {!isBaseline && uplift !== 0 && conversion > 0 && (
              <Badge
                isBoldText
                isSmall
                // eslint-disable-next-line no-nested-ternary
                color={isDisabled ? Color.LOCKED : isWinner ? Color.SUCCESS : Color.SOFT}
              >
                <BadgeValue>
                  {(conversionDiff * 100).toFixed(2)}%
                  <IconWrap conversionDiff={conversionDiff}>
                    <ArrowIcon
                      direction="right"
                      size="small"
                      // eslint-disable-next-line no-nested-ternary
                      color={isDisabled ? Color.LOCKED : isWinner ? Color.SUCCESS : Color.SOFT}
                    />
                  </IconWrap>
                </BadgeValue>
              </Badge>
            )}
          </ConversionWrap>
        </Item>
        <Item>
          <img
            src={getStatusIcon(isBaseline, conversion, isSignificant, isWinner)}
            alt={getStatusMessage(isBaseline, conversion, isSignificant, isWinner)}
          />
          <Significant>
            {getStatusMessage(isBaseline, conversion, isSignificant, isWinner)}
          </Significant>
        </Item>
      </Cell>
      <Cell ref={cellRef}>
        <VictoryGroup>
          {charts
            // eslint-disable-next-line no-nested-ternary
            .sort((a, b) => Number(a.isCurrent) - Number(b.isCurrent))
            .map((chart) =>
              chart.chartData.length > 0 ? (
                <VictoryArea
                  width={172}
                  height={22}
                  data={chart.chartData}
                  standalone={!chart.isCurrent}
                  style={{
                    data: {
                      fill: getChartFill(chart.isCurrent, isWinner, isDisabled),
                      opacity: isWinner || chart.isCurrent ? 0.3 : 1,
                      stroke: getChartStroke(chart.isCurrent, isWinner, isDisabled),
                      strokeWidth: 3,
                      strokeOpacity: 1
                    }
                  }}
                />
              ) : null
            )}
        </VictoryGroup>
        <Stack>
          <Values>Z-Score: {zScore.toFixed(3)}</Values>
          <Values>P-Value: {pValue.toFixed(3)}</Values>
        </Stack>
      </Cell>
    </Container>
  );
}
