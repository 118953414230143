import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';

export const PlaceholdersWrap = styled.div`
  margin-top: ${Spacing.S7};
`;

export const Header = styled.div`
  margin-bottom: ${Spacing.S1};
  display: grid;
  grid-template-columns: auto 192px 192px;
  grid-column-gap: ${Spacing.S3};
`;
