import * as React from 'react';

// Constants
import { CONTROL_GROUP_COLOR, PointType } from '@src/common/constants/point';

// Helpers
import { generateSequence } from '@src/common/helpers/generateSequence';
import { getNameBranch } from '@src/common/helpers/getNameBranch';
import { Color } from '@pushwoosh/kit-constants';
import { getOutputs } from '../helpers/getOutputs';

// Components
import { Point, PointContent, PointOutput } from '../Point';
import { Label } from './Label';
import { ProgressBar } from './ProgressBar';

// Types
import { IProps } from './PointABSplitter.types';

export function PointABSplitter(props: IProps): JSX.Element {
  const { countContent, outputs, data, propsCommonPoint } = props;

  const getIsOutput = React.useCallback(
    (key: string): boolean => getOutputs(key, outputs),
    [outputs]
  );

  const blocksIndex = generateSequence(countContent);

  return (
    <Point
      color={CONTROL_GROUP_COLOR}
      type={PointType.AB_SPLITTER}
      countContent={countContent}
      isOutputs
      {...propsCommonPoint}
    >
      {blocksIndex.map((item) => (
        <PointContent key={item} type={PointType.AB_SPLITTER} order={item + 1}>
          <Label y={6} fill={data.split[item] === 0 ? Color.PHANTOM : '#55555'}>{`${getNameBranch(
            item
          )}:`}</Label>
          <ProgressBar x={20} y={6} progress={data.split[item]} width={85} />
          <Label y={6} x={115} fill={data.split[item] === 0 ? Color.PHANTOM : CONTROL_GROUP_COLOR}>
            {`${data.split[item]}%`}
          </Label>
          <PointOutput
            type={PointType.AB_SPLITTER}
            outputKey={`output${item}`}
            order={item}
            pointId={propsCommonPoint.id}
            colorBlock={CONTROL_GROUP_COLOR}
            colorOutput={CONTROL_GROUP_COLOR}
            colorArrow={data.split[item] === 0 ? '#BCC9D8' : '#475266'}
            isInvalid={propsCommonPoint.isInvalid}
            isActive={propsCommonPoint.isActive}
            isLinkedOutput={getIsOutput(`output${item}`)}
            onMouseDown={propsCommonPoint.onMouseDownOutput}
            arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
          />
        </PointContent>
      ))}
    </Point>
  );
}
