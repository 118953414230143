import * as React from 'react';
import { useBus } from '@src/common/contexts/bus';

// Store Hooks
import {
  useAvailablePoints,
  usePopupMenuStore,
  useCreatePoint,
  useActionAddOutputToPoint
} from '@src/store/hooks';

// UI Components
import { PopupMenu } from '@src/ui';

// Helpers
import { makeUniqueId } from '@src/common/helpers/makeUniqueId';

// Types
import { TInfoToCreatePoint } from '@src/common/types/points';

export function PopupMenuCreatePoint(): JSX.Element {
  const bus = useBus();
  const popupMenuStore = usePopupMenuStore();
  const availablePoints = useAvailablePoints();

  const handlePointCreate = useCreatePoint();
  const addOutputToPoint = useActionAddOutputToPoint();

  React.useEffect(() => {
    const closePopupMenuSub = bus.select('closePopupMenu$').subscribe(() => {
      if (popupMenuStore.isVisible) {
        popupMenuStore.close();
      }
    });

    const openPopupMenuSub = bus.select('openPopupMenu$').subscribe((event) => {
      popupMenuStore.open({
        pageMouseX: event.payload.pageX,
        pageMouseY: event.payload.pageY,
        canvasMouseX: event.payload.toX,
        canvasMouseY: event.payload.toY,
        fromPointId: event.payload.fromPointId,
        pointOutputKey: event.payload.outputKey,
        pointOutputOrder: event.payload.outputOrder
      });
    });

    return () => {
      closePopupMenuSub.unsubscribe();
      openPopupMenuSub.unsubscribe();
    };
  }, [popupMenuStore, bus]);

  const handleCreatePoint = (point: TInfoToCreatePoint): void => {
    const toId = makeUniqueId();

    handlePointCreate({
      ...point,
      position: {
        x: popupMenuStore.mousePosition.x,
        y: popupMenuStore.mousePosition.y - 56 / 2
      },
      id: toId
    });

    popupMenuStore.close();

    if (popupMenuStore.fromPointId && popupMenuStore.pointOutputKey) {
      addOutputToPoint({
        fromPointId: popupMenuStore.fromPointId,
        toPointId: toId,
        key: popupMenuStore.pointOutputKey,
        order: popupMenuStore.pointOutputOrder
      });
    }
  };

  return (
    <PopupMenu
      isVisible={popupMenuStore.isVisible}
      menuCoords={popupMenuStore.menuPosition}
      pointerCoords={popupMenuStore.mousePosition}
      pointList={availablePoints}
      onCreatePoint={handleCreatePoint}
      onClickOutside={popupMenuStore.close as () => void}
    />
  );
}
