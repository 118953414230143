import * as React from 'react';

// Components
import { SelectAddMultiple } from '@src/ui/atoms/SelectAddMultiple';

// Types
import { ISelectOption } from '@src/common/types/form';

// UI hook
import { useSelectableSelect } from '@src/ui/hooks';

// Types
import { IProps } from './LoaderAttributesSelect.types';

// Remove in future, when backend be able to handle autocomplete for event attributes

export function LoaderAttributesSelect(props: IProps): JSX.Element {
  const { name, values = [], applicationCode, attributeName, eventCode, onChange, onBlur } = props;

  const { selectableValues, setSearch } = useSelectableSelect(
    applicationCode,
    eventCode,
    attributeName
  );

  const currentValues: ISelectOption<string | number>[] = (values as string[]).map(
    (value: string): ISelectOption<string> => ({
      label: value,
      value
    })
  );

  const onInputChange = React.useCallback(
    (value: string) => {
      setSearch(value);
    },
    [setSearch]
  );

  /* eslint-disable @typescript-eslint/no-explicit-any */
  return (
    <SelectAddMultiple
      name={name}
      values={currentValues}
      options={selectableValues}
      onChange={onChange}
      onBlur={onBlur}
      onInputChange={onInputChange}
    />
  );
  /* eslint-enable @typescript-eslint/no-explicit-any */
}
