import React from 'react';
import { Icon, IconEnum, Modal } from '@src/ui/kit';
import { useCloseAllModals } from '@src/store/hooks';
import { Color } from '@pushwoosh/kit-constants';
import { CloseIcon, SuccessWrapper, TitleWrapper, StyledParagraph } from './styled';

export function ContactSalesSuccess() {
  const closeAllModals = useCloseAllModals();
  return (
    <Modal
      isOpen
      title={
        <TitleWrapper>
          Request successfully sent
          <CloseIcon onClick={() => closeAllModals()}>
            <Icon type={IconEnum.CLOSE_MEDIUM} fill={Color.MAIN} />
          </CloseIcon>
        </TitleWrapper>
      }
      widthType="short"
    >
      <SuccessWrapper>
        <StyledParagraph>
          Our manager will get in touch with you as soon as possible. Thank you for contacting us.
        </StyledParagraph>
        {/* eslint-disable-next-line camelcase */}
        <img src={`${__webpack_public_path__}paperPlane.png`} alt="paper plane" />
      </SuccessWrapper>
    </Modal>
  );
}
