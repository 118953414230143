import React from 'react';
import { SidebarType } from '@src/common/constants/sidebars';
import { TSidebar } from '@src/common/types/sidebar';
import { useStoreState } from '@src/store/store';

import { CommentsPanel } from '@src/ui/features/CommentsPanel';
import { DocumentationMenu } from '@src/ui/features/Documentation';
import { AssistantSidebar, ValidationSidebar } from '@src/ui/features/Assistant';
import { ChangeLog } from '../../ChangeLog';

function renderSidebarByType(sidebar: TSidebar): JSX.Element {
  switch (sidebar?.type) {
    case SidebarType.COMMENTS: {
      return <CommentsPanel />;
    }

    case SidebarType.HISTORY: {
      return <ChangeLog />;
    }

    case SidebarType.DOCUMENTATION: {
      return <DocumentationMenu />;
    }

    case SidebarType.ASSISTANT: {
      return <AssistantSidebar />;
    }

    case SidebarType.VALIDATION: {
      return <ValidationSidebar />;
    }

    default: {
      return null;
    }
  }
}

export function SidebarManager(): JSX.Element {
  const sidebar = useStoreState((state) => state.sidebars.sidebar);
  const sidebarComponent = React.useMemo(() => renderSidebarByType(sidebar), [sidebar]);

  return <>{sidebarComponent}</>;
}
