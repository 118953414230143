import * as React from 'react';

// Hooks
import { useSavedHandler } from './useSavedHandler';

const EVENTS: ['mousedown', 'touchstart', 'focusin'] = ['mousedown', 'touchstart', 'focusin'];

export function useClickOutside(
  onClickOutside: (event: MouseEvent) => void
): React.RefObject<HTMLElement | null> {
  const savedHandler = useSavedHandler(onClickOutside);
  const ref = React.useRef<HTMLElement | null>(null);

  React.useEffect((): (() => void) => {
    const handler = (event: MouseEvent): void => {
      const elem = ref.current;
      const target = event.target as Node;

      if (elem && !elem.contains(target)) {
        savedHandler.current(event);
      }
    };

    EVENTS.forEach((eventName): void => {
      document.addEventListener(eventName, handler);
    });

    return (): void => {
      EVENTS.forEach((eventName): void => {
        document.removeEventListener(eventName, handler);
      });
    };
  }, [ref, savedHandler]);

  return ref;
}
