import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';

export const LabelContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: ${Spacing.S1};
  align-items: center;
`;

export const Title = styled.span``;

export const Wrap = styled.div``;
