import * as React from 'react';

// Store
import { useStoreState } from '@src/store/store';

// Types
import { TTooltip } from '@src/common/types/tooltip';
import { IUniqueId } from '@src/common/types/entities';

type TGetTooltipById = (tooltipId: IUniqueId['id']) => TTooltip;

export function useComputedGetTooltipById(): TGetTooltipById {
  const getTooltipById: TGetTooltipById = useStoreState<TGetTooltipById>(
    (state): TGetTooltipById => state.tooltip.getTooltipById
  );

  const handleGetTooltipById: TGetTooltipById = React.useCallback<TGetTooltipById>(
    (tooltipId): TTooltip => getTooltipById(tooltipId),
    [getTooltipById]
  );

  return handleGetTooltipById;
}
