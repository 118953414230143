import styled, { keyframes } from 'styled-components';
import { FontSize, ShapeRadius, Color, Spacing, UnitSize } from '@pushwoosh/kit-constants';

// components
import { Icon } from '../Icon';

interface ContainerProps {
  isDisabled: boolean;
}
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: ${UnitSize.FIELD_HEIGHT};
  padding: 0 ${Spacing.S3};
  background-color: ${({ isDisabled }: ContainerProps) =>
    isDisabled ? Color.FROZEN : Color.CLEAR};

  border: 1px solid ${Color.FORM};
  border-radius: ${ShapeRadius.CONTROL};

  :focus-within {
    border: 1px solid ${Color.BRIGHT};
  }
`;

export const Input = styled.input`
  height: 20px;
  width: 82%;
  border: none;

  background-color: ${Color.CLEAR};
  color: ${Color.MAIN};
  font-size: ${FontSize.REGULAR};

  ::placeholder {
    color: ${Color.PHANTOM};
  }

  :disabled {
    background-color: ${Color.FROZEN};
    color: ${Color.LOCKED};
  }

  outline: none;
`;

export const IconSearch = styled(Icon)`
  margin-left: ${Spacing.S1};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const IconSpinner = styled(Icon)`
  animation: ${rotate} 2s linear infinite;
  margin-left: auto;
`;

export const IconResetContainer = styled.div`
  margin-left: auto;
  cursor: pointer;
`;
