import React, { useRef } from 'react';

// Components
import { Button } from '@src/ui/kit';
import { Dropdown, useDropdown } from '@src/ui/kit/Dropdown';
import { Checkbox } from '@src/ui/kit/Checkbox';
import { DropdownButtonProps } from './DropdownButton.types';

// Styled
import { DropdownButtonWrap, Title, Content, Reset, DropdownItem, ArrowDown } from './styled';

export function DropdownButton<OptionType>(props: DropdownButtonProps<OptionType>): JSX.Element {
  const { options, values = [], placeholder, onChange } = props;
  const buttonRef = useRef(null);
  const dropdown = useDropdown({
    position: 'bottom-start',
    target: buttonRef
  });
  const [showClearButton, setShowClearButton] = React.useState(false);

  React.useEffect(
    () => {
      if (dropdown.showDropdown && values.length > 0) {
        setShowClearButton(true);
      }
      if (dropdown.showDropdown && values.length === 0) {
        setShowClearButton(false);
      }
    },
    // eslint-disable-next-line
    [dropdown.showDropdown, setShowClearButton]
  );

  const title = React.useMemo(
    () =>
      options
        .filter((option) => values.includes(option.value))
        .map((option) => option.label)
        .join(', '),
    [options, values]
  );

  const selectHandle = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: OptionType) => {
      event.preventDefault();

      onChange([...values, value]);
    },
    [onChange, values]
  );

  const unselectHandle = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, unselectValue: OptionType) => {
      event.preventDefault();

      const updatedValue = values.filter((value) => value !== unselectValue);

      onChange(updatedValue);
    },
    [onChange, values]
  );

  const handleReset = React.useCallback(() => {
    onChange([]);
  }, [onChange]);

  return (
    <>
      <DropdownButtonWrap
        ref={buttonRef}
        showDropdown={dropdown.showDropdown}
        onMouseDown={(event) => dropdown.toggleDropdown(event)}
      >
        <Title>{title || placeholder}</Title>
        <ArrowDown showDropdown={dropdown.showDropdown} />
      </DropdownButtonWrap>
      <Dropdown dropdown={dropdown} withArrow={false}>
        <Content>
          {showClearButton && values.length > 0 && (
            <Reset>
              <Button view="ghost" color="primary" onClick={handleReset}>
                Clear selected statuses
              </Button>
            </Reset>
          )}
          {options.map((option, index) => {
            const isSelected = values.includes(option.value);
            const { value, label } = option;

            return (
              <DropdownItem
                onClick={(e) => (isSelected ? unselectHandle(e, value) : selectHandle(e, value))}
                isSelected={isSelected}
                key={index}
              >
                <Checkbox readOnly name={label} value={isSelected} />
                {label}
              </DropdownItem>
            );
          })}
        </Content>
      </Dropdown>
    </>
  );
}
