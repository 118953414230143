import React from 'react';
import { useValidateById, useCurrentJourneyId } from '@src/store/hooks';
import { useStoreState } from '@src/store/store';
import { useConfirmActivateJourney } from '@src/ui/features/Modals/Confirm';

export function useStartJourney() {
  const { validate, isLoading } = useValidateById();
  const currentJourneyId = useCurrentJourneyId();
  const { isLoadingConfirm, confirmActivateJourneyHandler } = useConfirmActivateJourney();
  const isValidated = useStoreState((state) => state.journeys.isValidated);

  const startJourney = React.useCallback(() => {
    validate({ journeyId: currentJourneyId, onSuccessValidate: confirmActivateJourneyHandler });
  }, [currentJourneyId, validate, confirmActivateJourneyHandler]);

  return {
    isValidated,
    validate,
    isLoading,
    isLoadingConfirm,
    startJourney
  };
}
