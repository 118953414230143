// Types
import { TEvent } from '@src/common/types/event';

export function findEventAttributesByEventName(
  events: TEvent[],
  searchableEventName: string
): TEvent['attributes'] {
  const event = events.find((event): boolean => event.name === searchableEventName);

  if (event === undefined) {
    return [];
  }

  return event.attributes;
}
