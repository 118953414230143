exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles__text___3-X2J{font-weight:normal}.styles__size-xs___6SEme{font-size:12px}.styles__size-sm___JSHhx{font-size:14px}.styles__size-md___3Oq8z{font-size:16px}.styles__size-lg___ijSPC{font-size:18px}.styles__size-xl___2qDdG{font-size:20px}.styles__size-xxl___2NZiC{font-size:22px}.styles__size-xxxl___GBQUw{font-size:24px}.styles__size-xxxxl___agYYE{font-size:34px}.styles__weight-regular___lrSFX{font-weight:400}.styles__weight-medium___2MQJP{font-weight:500}.styles__weight-bold___3HDey{font-weight:600}.styles__transform-lowercase___3Hekg{text-transform:lowercase}.styles__transform-uppercase___1M17t{text-transform:uppercase}.styles__transform-capitalize___1sV6w{text-transform:capitalize}.styles__view-inactive___3EffT{color:#999}.styles__view-base___1ZSZV{color:#555}.styles__view-headers___3u2b6{color:#333}.styles__view-placeholder___3Zimy{color:#ccc}.styles__view-white___1i8Fy{color:#fff}.styles__view-primary___2lzm_{color:#0076bd}.styles__view-secondary___1Byt4{color:#00a3ff}.styles__view-helper___wrJRZ{color:#89a4bf}", ""]);

// Exports
exports.locals = {
	"text": "styles__text___3-X2J",
	"size-xs": "styles__size-xs___6SEme",
	"size-sm": "styles__size-sm___JSHhx",
	"size-md": "styles__size-md___3Oq8z",
	"size-lg": "styles__size-lg___ijSPC",
	"size-xl": "styles__size-xl___2qDdG",
	"size-xxl": "styles__size-xxl___2NZiC",
	"size-xxxl": "styles__size-xxxl___GBQUw",
	"size-xxxxl": "styles__size-xxxxl___agYYE",
	"weight-regular": "styles__weight-regular___lrSFX",
	"weight-medium": "styles__weight-medium___2MQJP",
	"weight-bold": "styles__weight-bold___3HDey",
	"transform-lowercase": "styles__transform-lowercase___3Hekg",
	"transform-uppercase": "styles__transform-uppercase___1M17t",
	"transform-capitalize": "styles__transform-capitalize___1sV6w",
	"view-inactive": "styles__view-inactive___3EffT",
	"view-base": "styles__view-base___1ZSZV",
	"view-headers": "styles__view-headers___3u2b6",
	"view-placeholder": "styles__view-placeholder___3Zimy",
	"view-white": "styles__view-white___1i8Fy",
	"view-primary": "styles__view-primary___2lzm_",
	"view-secondary": "styles__view-secondary___1Byt4",
	"view-helper": "styles__view-helper___wrJRZ"
};