// Store
import { useStoreState } from '@src/store/store';

// Types
import { TRichMedia } from '@src/common/types/richMedia';

type TResult = (applicationCode: string, code: string) => TRichMedia | undefined;

export function useGetRichMediaContent(): TResult {
  const getRichMediaContent = useStoreState(
    (state): TResult => state.richMedias.richMediaWithContent
  );

  return getRichMediaContent;
}
