import * as React from 'react';

type Result = [boolean, (value: boolean) => void];

export function useShowOneBadge(isDrawingArrow: boolean): Result {
  const [isShowBadge, setShowBadge] = React.useState(true);

  React.useEffect(
    () => {
      if (!isDrawingArrow && !isShowBadge) {
        setShowBadge(true);
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [isDrawingArrow]
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  return [isShowBadge, setShowBadge];
}
