import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgDangerTriangleSmallFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.313 2.38a1.5 1.5 0 00-2.626 0l-5.46 9.895A1.5 1.5 0 002.541 14.5h10.918a1.5 1.5 0 001.314-2.225L9.313 2.38zM7.24 6.25a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v3.5a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-3.5zm.01 5.5a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-.5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgDangerTriangleSmallFilled;
