import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgDocumentMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 11.25a.75.75 0 000 1.5h6a.75.75 0 000-1.5H9zM8.25 15a.75.75 0 01.75-.75h6a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM9 8.25a.75.75 0 100 1.5h1.5a.75.75 0 000-1.5H9z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.909 3.909A2.25 2.25 0 017.5 3.25h6.013a.75.75 0 01.517.22l4.5 4.5a.752.752 0 01.22.53v10a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-13c0-.597.237-1.169.659-1.591zM7.5 4.75h5.25V8.5c0 .414.336.75.75.75h3.75v9.25a.75.75 0 01-.75.75h-9a.75.75 0 01-.75-.75v-13a.75.75 0 01.75-.75zm6.75 1.06l1.94 1.94h-1.94V5.81z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgDocumentMediumLined;
