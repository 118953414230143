import styled from 'styled-components';
import { Color, Spacing, ShapeRadius } from '@pushwoosh/kit-constants';

export const UrlSettingsContainer = styled.div`
  padding: ${Spacing.S5};
  border-radius: ${ShapeRadius.CONTROL};
  background-color: ${Color.SOFT_LIGHT};
`;

export const AddHeaderItem = styled.div`
  margin-top: ${Spacing.S5};
`;

export const UrlContainer = styled.div`
  display: grid;
  grid-template-columns: auto 100px;
  grid-column-gap: ${Spacing.S3};
`;
