import styled from 'styled-components';
import { Icon, Link } from '@src/ui/kit';
import { FontWeight, Spacing, Color } from '@pushwoosh/kit-constants';
import { Footnote, Paragraph as DefaultParagraph } from '@pushwoosh/kit-typography';

export const StyledIcon = styled(Icon)`
  margin-left: ${Spacing.S2};
`;

export const StyledLink = styled(Link)`
  font-weight: ${FontWeight.BOLD};
`;

export const ErrorMessage = styled(Footnote)`
  margin-top: ${Spacing.S3};
  color: ${Color.WARNING};
`;

export const DefaultLink = styled(Link)`
  display: inline-flex;
  font-size: 1em;
`;

export const Paragraph = styled(DefaultParagraph)`
  margin-top: ${Spacing.S3};
`;

export const DocumentationLink = styled(Link)`
  float: right;
`;

export const Block = styled.div`
  margin: ${Spacing.S4} 0;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const OverrideWarn = styled.span`
  display: inline-flex;
  align-items: center;
  gap: ${Spacing.S2};
  font-size: 0.8em;
  text-transform: uppercase;
  color: white;
  background-color: ${Color.DANGER};
  padding: ${Spacing.S0} ${Spacing.S3};
  border-radius: 4px;
  font-weight: ${FontWeight.BOLD};
`;
