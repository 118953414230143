// Store
import { useStoreState } from '@src/store/store';

// Types
import { IJourneyStatistics } from '@src/common/types/statistics';

export function useStoreStatisticsByJourney(): IJourneyStatistics {
  const statisticsByJourney = useStoreState(
    (state): IJourneyStatistics => state.statistics.journey
  );

  return statisticsByJourney;
}
