import React from 'react';
import { Group } from 'react-konva';
import { Html } from 'react-konva-utils';

// Components
import { CommentForm } from '../CommentForm';
import { CommentMessage } from '../ComentMessage';

// Types
import { ICommentTooltipProps } from './CommentTooltip.types';

// Styled
import { TooltipWrapp } from './styled';

const TOOLTIP_X_OFFSET = 146;
const TOOLTIP_Y_OFFSET = 6;

export function CommentTooltip(props: ICommentTooltipProps): JSX.Element {
  const {
    x,
    y,
    message,
    commentId,
    createdAt,
    mode,
    closeTooltip,
    onUpdateComment,
    onRemoveComment
  } = props;
  const tooltipX = x - TOOLTIP_X_OFFSET;
  const [isEdit, setEdit] = React.useState(!message);
  const tooltipY = y - TOOLTIP_Y_OFFSET;

  return (
    <Group y={tooltipY} x={tooltipX}>
      <Html
        divProps={{
          style: {
            width: '320px'
          }
        }}
      >
        <TooltipWrapp>
          {isEdit && !mode.isRead ? (
            <CommentForm
              message={message}
              createdAt={createdAt}
              closeTooltip={closeTooltip}
              commentId={commentId}
              onUpdateComment={onUpdateComment}
              position={{
                x,
                y
              }}
            />
          ) : (
            <CommentMessage
              message={message}
              createdAt={createdAt}
              mode={mode}
              setEdit={setEdit}
              onRemoveComment={onRemoveComment}
              commentId={commentId}
            />
          )}
        </TooltipWrapp>
      </Html>
    </Group>
  );
}
