import React from 'react';
import { IconEnum } from '@src/ui/kit/Icon';
import { StyledLink } from './styled';

interface IProps {
  to?: string;
  beforeIcon: IconEnum;
  children: React.ReactNode;
  onClick?: () => void;
}

export function AccountMenuLink(props: IProps): JSX.Element {
  return (
    <StyledLink
      to={props.to}
      onClick={props.onClick}
      beforeIcon={props.beforeIcon}
      isExternal
      target="_blank"
      rel="noreferrer"
    >
      {props.children}
    </StyledLink>
  );
}
