// Store
import { useStoreState } from '@src/store/store';

// Types
import { Tag } from '@src/common/types/tag';

type TResult = Tag[];

export function useTag(): TResult {
  const filters: Tag[] = useStoreState((state): Tag[] => state.tag.items);

  return filters;
}
