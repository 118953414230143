import React from 'react';
import { Transition } from 'react-transition-group';
import _isEmpty from 'lodash/isEmpty';

import { IUniqueId } from '@src/common/types/entities';
import {
  useCloseAllModals,
  useGetJourneyById,
  usePoints,
  useStoreStatisticsByJourney
} from '@src/store/hooks';
import { Icon, IconEnum } from '@src/ui/kit';
import { H2 } from '@pushwoosh/kit-typography';
import { Color } from '@pushwoosh/kit-constants';
import { TabItem, Tabs } from '@src/ui/kit/Tabs';
import { GoalsStat } from '@src/ui/features/JourneyStatistics/components/GoalsStat';
import { CommunicationsStat } from '@src/ui/features/JourneyStatistics/components/CommunicationsStat';
import { filterPointsByGroup } from '@src/ui/helpers';
import { PointType } from '@src/common/constants';
import { TabNames } from './types';
import { Container, Header, ContentWrapper, CloseButton } from './styled';

interface IProps {
  journeyId: IUniqueId['id'];
}

export function FullStatistic(props: IProps): JSX.Element {
  const { journeyId } = props;
  const getJourneyById = useGetJourneyById();
  const { goals } = useStoreStatisticsByJourney();

  const { title } = getJourneyById(journeyId);

  const [animate, setAnimate] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(
    !_isEmpty(goals) ? TabNames.GOALS : TabNames.COMMUNICATIONS
  );

  const points = usePoints();

  const communicationPoints = filterPointsByGroup(
    points,
    new Set([PointType.SEND_EMAIL, PointType.SEND_PUSH])
  );

  const closeAllModals = useCloseAllModals();

  React.useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <Transition in={animate} timeout={50}>
      {(state) => (
        <Container state={state}>
          <Header>
            <H2>{title}</H2>
            <CloseButton size="compact" view="ghost" onClick={() => closeAllModals()}>
              <Icon type={IconEnum.CLOSE_MEDIUM} fill={Color.MAIN} />
            </CloseButton>
          </Header>

          <ContentWrapper>
            <Tabs>
              {!_isEmpty(goals) && (
                <TabItem name={TabNames.GOALS} setActiveTab={setActiveTab} activeTab={activeTab} />
              )}
              {!_isEmpty(communicationPoints) && (
                <TabItem
                  name={TabNames.COMMUNICATIONS}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                />
              )}
              {/* <TabItem */}
              {/*  name={TabNames.USERS_REMOVED} */}
              {/*  setActiveTab={setActiveTab} */}
              {/*  activeTab={activeTab} */}
              {/* /> */}
            </Tabs>

            {activeTab === TabNames.GOALS && <GoalsStat />}

            {activeTab === TabNames.COMMUNICATIONS && <CommunicationsStat />}
          </ContentWrapper>
        </Container>
      )}
    </Transition>
  );
}
