import React from 'react';
import { useStoreState } from '@src/store/store';
import { ValidationButton } from '../components/ValidationButton';
import { AssistantButton } from '../components/AssistantButton';
import { Container } from '../styled';

export function Assistant(): JSX.Element {
  const isVisibleErrorsPaginate = useStoreState(
    (store) => store.errorsPoint.isVisibleErrorsPaginate
  );

  return (
    <Container>{isVisibleErrorsPaginate ? <ValidationButton /> : <AssistantButton />}</Container>
  );
}
