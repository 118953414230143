import * as React from 'react';
import { Rect, Group } from 'react-konva';

// Types
import { IProps } from './Button.types';

export function Button(props: IProps): JSX.Element {
  const {
    position = { x: 0, y: 0 },
    width = 40,
    height = 40,
    fill = '#fff',
    isVisible = true,
    onClick,
    onMouseEnter,
    onMouseLeave,
    children
  } = props;

  return (
    <Group
      x={position.x}
      y={position.y}
      width={width}
      height={height}
      onClick={onClick}
      visible={isVisible}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Rect
        x={position.x - 2}
        y={position.y - 2}
        width={width + 4}
        height={height + 4}
        cornerRadius={50}
        fill="#EBEDF0"
      />
      <Rect
        x={position.x}
        y={position.y}
        width={width}
        height={height}
        cornerRadius={50}
        fill={fill}
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
      />

      {children}
    </Group>
  );
}
