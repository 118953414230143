import React from 'react';

import { DocElements } from '@src/ui/features/Documentation/components/DocElements';
import { Link } from '@src/ui/kit';
import { FontWeight } from '@pushwoosh/kit-constants';

export function DocumentationReachabilityCheck(): JSX.Element {
  return (
    <DocElements>
      <DocElements.Paragraph>
        {
          'Before sending messages to your users, you might need to check whether they are reachable via a specific channel, be it push notifications or emails. Try reaching out to users who are not subscribed to pushes or emails via other channels, and make sure every single user is engaged! '
        }
      </DocElements.Paragraph>
      <DocElements.Paragraph>
        {
          "Drag and drop the Reachability check element from the left panel to the canvas after any element you want to follow up by the communication. Then, choose the channel you'd like to check – push notifications or email. "
        }
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-reachabilitycheck-image1.png"
        alt="documentation-reachabilitycheck-image1"
      />

      <DocElements.Paragraph>
        {
          'For push notifications, the Push Alerts Enabled tag values will be reviewed, and the journey travelers will be split into two branches accordingly, one for those who have this tag set to "true" and those with "false" tag value. As for emails, users who have the Unsubscribed Email tag set to "true" will go to the not-reachable branch. '
        }
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-reachabilitycheck-image2.png"
        alt="documentation-reachabilitycheck-image2"
      />

      <DocElements.Paragraph>
        {
          "Then, follow up the Reachability check point with the communications accordingly – reach out to users not subscribed to pushes with emails and vice-versa, or send an In-App to cover all of them. You might want to check both channels simultaneously, and it's a piece of cake – just follow up the not-reachable branch of pushes, for example, with the reachability check for emails. Thus, you can detect the users who are not subscribed to any of your updates at all and reach out to them with In-Apps or other mediums"
        }
        <Link
          target="_blank"
          weight={FontWeight.MEDIUM}
          isExternal
          to="https://docs.pushwoosh.com/platform-docs/send-messages/customer-journey-builder/journey-elements/untitled"
        >
          (such as, e.g., SMS – refer to the Webhooks samples)
        </Link>
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-reachabilitycheck-image3.png"
        alt="documentation-reachabilitycheck-image3"
      />
    </DocElements>
  );
}
