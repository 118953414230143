import * as React from 'react';

// Store
import { useStoreState } from '@src/store/store';

// Types
import { TPointStatistics } from '@src/common/types/statistics';
import { IUniqueId } from '@src/common/types/entities';

type TGetPointStatByPointId = (pointId: IUniqueId['id']) => TPointStatistics;

export function useComputedGetPointStatByPointId(): TGetPointStatByPointId {
  const getPointStatByPointId = useStoreState<TGetPointStatByPointId>(
    (state): TGetPointStatByPointId => state.statistics.getPointStatisticsById
  );

  const handleGetPointStatByPointId = React.useCallback<TGetPointStatByPointId>(
    (pointId): TPointStatistics => getPointStatByPointId(pointId),
    [getPointStatByPointId]
  );

  return handleGetPointStatByPointId;
}
