import Konva from 'konva';

export function calcCanvasScale(stage: Konva.Stage, direction: number) {
  const oldScale = stage.scaleX();
  const pointer = stage.getPointerPosition();

  const mousePointTo = {
    x: (pointer.x - stage.x()) / oldScale,
    y: (pointer.y - stage.y()) / oldScale
  };

  const newScale = direction > 0 ? oldScale * 1.1 : oldScale / 1.1;

  const newPosition = {
    x: pointer.x - mousePointTo.x * newScale,
    y: pointer.y - mousePointTo.y * newScale
  };

  return {
    newScale,
    newPosition
  };
}
