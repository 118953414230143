import React from 'react';

// Analytics
import { sendMixpanelEvent } from '@src/common/analytics';

// Store Hooks
import { useDeactivateJourneyById } from '@src/store/hooks';

// Features Hooks
import { useConfirmService } from '@src/ui/features/Modals/Confirm/hooks/useConfirmService';

// Неизвестно для чего тут title
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useConfirmDeactivateJourney = (id: string, title: string): (() => void) => {
  const confirm = useConfirmService();
  const [deactivateJourneyById, isRequestDeactivateJourneyById] = useDeactivateJourneyById();
  const confirmDeactivateJourneyHandler = React.useCallback((): void => {
    sendMixpanelEvent({ eventName: 'DeactivateJourney' });
    confirm({
      description:
        'Journey will be deactivated. All users that are currently in the journey will be removed from it instantly. You won’t be able to reactivate this journey.',
      onAfterConfirm(): void {
        deactivateJourneyById(id);
      },
      title: 'Deactivate Journey',
      confirmLabel: 'Deactivate',
      level: 'danger',
      isLoadingConfirm: isRequestDeactivateJourneyById
    });
  }, [confirm, deactivateJourneyById, id, isRequestDeactivateJourneyById]);

  return confirmDeactivateJourneyHandler;
};
