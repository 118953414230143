import styled from 'styled-components';
import { H4 } from '@pushwoosh/kit-typography';
import { Spacing, Color } from '@pushwoosh/kit-constants';

export const Title = styled(H4)`
  margin-bottom: ${Spacing.S1};
`;

export const Warn = styled.span`
  color: ${Color.WARNING};
`;

export const Success = styled.span`
  color: ${Color.SUCCESS};
`;

export const Container = styled.div`
  margin-bottom: ${Spacing.S5};
`;
