import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgRefreshMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.067 10.005a.852.852 0 00.755-.499 5.752 5.752 0 0110.3-.12l-1.792-.48a.75.75 0 10-.388 1.45l3.415.914a.75.75 0 00.918-.53l.915-3.415a.75.75 0 00-1.449-.388l-.41 1.53a7.253 7.253 0 00-12.943.565c-.213.474.16.973.68.973zm-.373 5.579l-.398 1.486a.75.75 0 11-1.45-.389l.916-3.415a.75.75 0 01.918-.53l3.415.915a.75.75 0 11-.388 1.449l-1.834-.492a5.752 5.752 0 0010.307-.105.852.852 0 01.754-.498c.52 0 .893.498.68.971a7.253 7.253 0 01-12.92.607z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgRefreshMediumLined;
