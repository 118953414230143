import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgCheckSmall(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.697 5.22a.75.75 0 010 1.06l-5.042 5.042a.75.75 0 01-1.06 0L4.303 9.03a.75.75 0 011.06-1.06L7.126 9.73l4.511-4.511a.75.75 0 011.061 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgCheckSmall;
