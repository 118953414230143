import { useCallback } from 'react';

import { useStoreActions } from '@src/store/store';

type TUseArrowHoveredToDelete = (fromPointId?: string, key?: string) => void;

export function useSetArrowHoveredToDelete(): TUseArrowHoveredToDelete {
  const setArrowHoveredToDelete = useStoreActions(
    (actions): typeof actions.canvas.setArrowHoveredToDelete =>
      actions.canvas.setArrowHoveredToDelete
  );

  return useCallback<TUseArrowHoveredToDelete>(
    (fromPointId, key): void => {
      if (fromPointId && key) {
        setArrowHoveredToDelete({ fromPointId, key });
      } else {
        setArrowHoveredToDelete(null);
      }
    },
    [setArrowHoveredToDelete]
  );
}
