import React from 'react';
import { sendMixpanelEvent } from '@src/common/analytics/mixpanel';
import { Container, Number, Message } from './styled';

type Props = {
  order: number;
  id: number;
  value: string;
  isSelected: boolean;
  setCurrentError: (value: number) => void;
};

export function ValidationItem(props: Props): JSX.Element {
  const { order, id, value, isSelected, setCurrentError } = props;

  const onClick = React.useCallback(() => {
    sendMixpanelEvent({
      eventName: 'Canvas:Drawer:Validation',
      eventData: { DrawerAction: 'ClickToIssue' }
    });
    setCurrentError(id);
  }, [setCurrentError, id]);

  return (
    <Container onClick={onClick} isSelected={isSelected}>
      <Number isSelected={isSelected}>{order}</Number>
      <Message>{value}</Message>
    </Container>
  );
}
