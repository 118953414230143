import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPlusMedium(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.75 7a.75.75 0 00-1.5 0v4.25H7a.75.75 0 000 1.5h4.25V17a.75.75 0 001.5 0v-4.25H17a.75.75 0 000-1.5h-4.25V7z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPlusMedium;
