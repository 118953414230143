import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgRefreshMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.067 10.005a.852.852 0 00.755-.499 5.75 5.75 0 019.424-1.382l-1.485.857a.75.75 0 00.18 1.374l3.416.915a.75.75 0 00.918-.53l.915-3.415a.75.75 0 00-1.1-.844l-1.52.878A7.251 7.251 0 005.388 9.032c-.213.474.16.973.68.973zm3.21 5.02l-1.51.872a5.75 5.75 0 009.413-1.394.852.852 0 01.754-.498c.52 0 .893.498.68.971a7.251 7.251 0 01-12.172 1.685l-1.496.864a.75.75 0 01-1.1-.844l.916-3.415a.75.75 0 01.919-.53l3.415.915a.75.75 0 01.18 1.374z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgRefreshMediumFilled;
