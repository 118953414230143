import React from 'react';
import upgradeSvg from './assets/upgrade.svg';
import {
  Wrap,
  StyledImg,
  Title,
  Description,
  DescriptionList,
  ListItem,
  UpgradeButton
} from './styled';

export function UpgradePlan(): JSX.Element {
  const upgradePlan = () => {
    window.open('https://go.pushwoosh.com/cp/products', '_blank');
  };

  return (
    <Wrap>
      <StyledImg src={upgradeSvg} alt="Upgrade plan promo" />
      <Title>Upgrade your plan</Title>
      <Description>Extend your marketing capabilities with the Developer subscription:</Description>
      <DescriptionList>
        <ListItem>Unlimited pushes</ListItem>
        <ListItem>Up to 50k subscribers</ListItem>
        <ListItem>API access</ListItem>
        <ListItem>Messaging analytics</ListItem>
      </DescriptionList>
      <UpgradeButton onClick={upgradePlan}>Upgrade now</UpgradeButton>
    </Wrap>
  );
}
