import * as React from 'react';

// Constants
import { IconEnum } from '@src/ui/kit/Icon';

// Components
import { DeviceValues } from '@src/ui/features/Webhook/components/DeviceValues';
import { TagValues } from '@src/ui/features/Webhook/components/TagValues';
import { EventValues } from '@src/ui/features/Webhook/components/EventValues';
import { Loading } from '@src/ui/kit/Loading';

// Helpers
import { resetDynamicValues } from '@src/ui/features/Webhook/helpers/resetDynamicValues';
import { getInsertablePlaceholder } from '@src/ui/features/Webhook/helpers/getInsertablePlaceholder';

// Styled
import {
  Title,
  Container,
  FormSelect,
  FormInput,
  InsertablePlaceholder,
  Button,
  Body
} from './styled';

// Types
import { DynamicValuesProps } from './DynamicValues.types';

// Constants
import { TypesData, TypeData } from './constants';

export function DynamicValues(props: DynamicValuesProps): JSX.Element {
  const { values, names, isNotSavedJourney, setFieldValue } = props;

  const { type } = values.dynamicValues;

  const insertablePlaceholder = getInsertablePlaceholder(values.dynamicValues);
  const disabledInsertablePlaceholder = !insertablePlaceholder;
  const typeIsDevice = type === TypeData.DEVICE;
  const typeIsTags = type === TypeData.TAG;
  const typeIsEvent = type === TypeData.EVENT;

  const handleChangeType = React.useCallback(
    (_, value: TypeData) => {
      setFieldValue(names.dynamicValues, {
        ...resetDynamicValues(values.dynamicValues),
        type: value
      });
    },
    [names, values, setFieldValue]
  );

  const handleClipBoardWrite = React.useCallback(async () => {
    await navigator.clipboard.writeText(insertablePlaceholder);
  }, [insertablePlaceholder]);

  if (isNotSavedJourney) {
    return (
      <Container>
        <Title>Data Builder</Title>
        <Loading size="medium" />
      </Container>
    );
  }

  return (
    <Container>
      <Title>Data Builder</Title>
      <Body>
        <FormSelect
          name="type"
          label="Dynamic Data"
          value={type}
          options={TypesData}
          onChange={handleChangeType}
        />
        {typeIsDevice && (
          <DeviceValues values={values} names={names} setFieldValue={setFieldValue} />
        )}
        {typeIsTags && <TagValues values={values} names={names} setFieldValue={setFieldValue} />}
        {typeIsEvent && <EventValues values={values} names={names} setFieldValue={setFieldValue} />}
        <InsertablePlaceholder>
          <FormInput
            name="placeholder"
            value={insertablePlaceholder}
            label="Insertable Placeholder"
            disabled={disabledInsertablePlaceholder}
          />
          <Button
            color="secondary"
            size="compact"
            view="shape"
            iconPosition="left"
            iconType={IconEnum.COPY_MEDIUM_LINED}
            onClick={handleClipBoardWrite}
            disabled={disabledInsertablePlaceholder}
          />
        </InsertablePlaceholder>
      </Body>
    </Container>
  );
}
