import { SECONDS_IN_DAY, SECONDS_IN_HOUR, SECONDS_IN_MINUTE } from '@src/common/constants';
import { TDelay } from '../types/types';

export function decodeDelay(delay: number): TDelay {
  const daysCount = Math.floor(delay / SECONDS_IN_DAY);
  const daysSeconds = daysCount * SECONDS_IN_DAY;

  const hoursCount = Math.floor((delay - daysSeconds) / SECONDS_IN_HOUR);
  const hoursSeconds = hoursCount * SECONDS_IN_HOUR;

  const minutesCount = Math.floor((delay - daysSeconds - hoursSeconds) / SECONDS_IN_MINUTE);

  return {
    dd: daysCount,
    hh: hoursCount,
    mm: minutesCount
  };
}
