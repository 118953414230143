/* eslint-disable camelcase */
// Api
import { GetOptions, HttpClient } from '@pushwoosh/http-client';

// Helpers
import { getApiOrigin } from '@src/store/helpers';

// Types
import { IUniqueId } from '@src/common/types/entities';
import { TFetchStatisticsData, TFetchListStatsData } from './types';

export const service = {
  async fetchJourneyListStat(api: HttpClient, journey_ids: string): Promise<TFetchListStatsData> {
    const options: GetOptions<TFetchListStatsData, null, { journey_ids: string }> = {
      query: {
        journey_ids
      },
      params: null,
      withAuthorization: true
    };

    const url = `${getApiOrigin()}/api/journey/statistics`;

    return api.get<TFetchListStatsData, null, { journey_ids: string }>(url, options);
  },

  async fetchStatisticsData(
    id: IUniqueId['id'],
    api: HttpClient,
    from_date?: string,
    to_date?: string
  ): Promise<TFetchStatisticsData> {
    const options: GetOptions<
      TFetchStatisticsData,
      { id: string },
      { from_date?: string; to_date?: string }
    > = {
      params: {
        id
      },
      query:
        from_date && to_date
          ? {
              from_date,
              to_date
            }
          : {},
      withAuthorization: true
    };

    // eslint-disable-next-line camelcase
    const url = `${getApiOrigin()}/api/journey/:id/statistics`;

    return api.get<TFetchStatisticsData, { id: string }, never>(url, options);
  }
};
