import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgOpenSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.69 4.25L6.97 7.97a.75.75 0 001.06 1.06l3.72-3.72v1.94a.75.75 0 001.5 0V3.5a.75.75 0 00-.75-.75H8.75a.75.75 0 000 1.5h1.94zM7 4.5a.75.75 0 00-.75-.75H5A2.25 2.25 0 002.75 6v5A2.25 2.25 0 005 13.25h5A2.25 2.25 0 0012.25 11V9.75a.75.75 0 00-1.5 0V11a.75.75 0 01-.75.75H5a.75.75 0 01-.75-.75V6A.75.75 0 015 5.25h1.25A.75.75 0 007 4.5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgOpenSmallLined;
