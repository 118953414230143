import { TWaitPoint } from '@src/common/types/points';
import { decodeDelay, formatNumberDouble, formatTime, addZero } from '@src/common/helpers';
import { getDayOfWeek } from '@src/ui/features/WaitUntil/helpers/getDayOfWeek';

export function getValuePoint(data: TWaitPoint['data']) {
  if (data.exactTime) {
    return `${formatNumberDouble(data.exactTime.hour)}:${formatNumberDouble(
      data.exactTime.minute
    )}`;
  }

  if (data.dynamicTime?.tagName) {
    return 'based on tag';
  }

  if (data.dynamicTime?.eventName) {
    return 'based on event';
  }

  if (data.exactDateTime) {
    const { day, year, month, hour, minute } = data.exactDateTime;
    return `${addZero(day)}-${addZero(month)}-${year} ${addZero(hour)}:${addZero(minute)}`;
  }

  if (data.exactWeekDaysTime) {
    const { exactWeekDaysTime } = data;
    const day = Object.keys(exactWeekDaysTime)[0];
    const dayOfWeek = getDayOfWeek(Number(day));
    const { hour, minute } = Object.values(exactWeekDaysTime)[0];

    return `${dayOfWeek} ${addZero(hour)}:${addZero(minute)}`;
  }

  const decodeMapDelay = decodeDelay(data.delay);
  const dataMaxValue = formatTime(decodeMapDelay);

  return dataMaxValue;
}
