import styled from 'styled-components';
import { DropdownMenuContainerButton } from '@pushwoosh/kit-dropdown-menu';

export const Container = styled.div`
  ${DropdownMenuContainerButton} {
    width: 28px;
    height: 28px;
    padding: 0 1px;
  }
`;
