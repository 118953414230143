import { thunk, action } from 'easy-peasy';
import decode from 'jwt-decode';

import { parse } from 'querystring';

// Analytics
import { identifyUser } from '@src/common/analytics';
// Logger
import { setUserData } from '@src/common/logger';

// Enums
import { SubscriptionPlan } from '@src/common/constants/subscriptionPlan';

// Types
import { IUniqueId } from '@src/common/types/entities';
import { IUserModel } from './user.types';

type Introspect = {
  readonly username: string;
  // eslint-disable-next-line camelcase
  readonly account_id: number;
  // eslint-disable-next-line camelcase
  readonly user_id: string;
  // eslint-disable-next-line camelcase
  readonly subscription_plan: string;
};

export const model: IUserModel = {
  isAuthorized: false,
  username: null,
  userId: null,
  accountId: null,
  subscriptionPlan: null,

  setSubscriptionPlan: action((state, subscriptionPlan: SubscriptionPlan): void => {
    state.subscriptionPlan = subscriptionPlan;
  }),

  setAuthorized: action((state, isAuthorized: boolean): void => {
    state.isAuthorized = isAuthorized;
  }),

  setUsername: action((state, username: string): void => {
    state.username = username;
  }),

  setUserId: action((state, userId): void => {
    state.userId = userId;
  }),

  setAccountId: action((state, accountId: number): void => {
    state.accountId = accountId;
  }),

  checkAuth: thunk(async (actions, _, { injections }): Promise<void> => {
    const { api } = injections;
    try {
      const accessToken = await api.getAccessToken();

      if (accessToken) {
        const introspect = decode(accessToken) as Introspect;
        actions.setUsername(introspect.username);
        setUserData({ id: introspect.user_id as IUniqueId['id'] });
        actions.setUserId(introspect.user_id as IUniqueId['id']);
        actions.setAccountId(introspect.account_id as number);
        actions.setSubscriptionPlan(introspect.subscription_plan as SubscriptionPlan);

        identifyUser({
          userId: introspect.user_id as IUniqueId['id'],
          accountId: introspect.account_id as number,
          plan: introspect.subscription_plan as SubscriptionPlan,
          email: introspect.username
        });
        actions.setAuthorized(true);
        return;
      }
    } catch {
      const query = parse(window.location.search.slice(1));
      const authorizationCode = typeof query.code === 'string' ? query.code : null;
      const authorizationState = typeof query.state === 'string' ? query.state : null;
      const authorization =
        authorizationCode && authorizationState
          ? { code: authorizationCode, state: authorizationState }
          : null;

      if (!authorization) {
        await api.login();
        return;
      }

      const introspect = await api.authorize<Introspect>(authorization);

      actions.setUsername(introspect.username);
      setUserData({ id: introspect.user_id as IUniqueId['id'] });
      actions.setUserId(introspect.user_id as IUniqueId['id']);
      actions.setAccountId(introspect.account_id as number);
      actions.setSubscriptionPlan(introspect.subscription_plan as SubscriptionPlan);

      identifyUser({
        userId: introspect.user_id as IUniqueId['id'],
        accountId: introspect.account_id as number,
        plan: introspect.subscription_plan as SubscriptionPlan,
        email: introspect.username
      });

      actions.setAuthorized(true);
      window.history.replaceState({}, '', window.location.pathname);
    }
  }),

  logout: thunk(async (actions, _, { injections }): Promise<void> => {
    const { api } = injections;
    await api.logout();

    window.location.href = '/';
  })
};
