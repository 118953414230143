import React from 'react';
import { Icon } from '@src/ui/kit/Icon';
import { BeforeWrap, AfterWrap } from './styled';
import { ILinkProps } from './Link.types';

export function BaseLink(props: ILinkProps): JSX.Element {
  return (
    <>
      {props.beforeIcon ? (
        <BeforeWrap>
          <Icon type={props.beforeIcon} fill={props.fillIcon} />
        </BeforeWrap>
      ) : null}

      {props.children}

      {props.afterIcon ? (
        <AfterWrap>
          <Icon type={props.afterIcon} fill={props.fillIcon} />
        </AfterWrap>
      ) : null}
    </>
  );
}
