import styled from 'styled-components';
import { H4 } from '@pushwoosh/kit-typography';
import { Spacing, Color, ShapeRadius } from '@pushwoosh/kit-constants';

export const StatItemWrap = styled.div`
  flex: 1;
  padding: ${Spacing.S5};
  margin-right: ${Spacing.S1};
  margin-top: ${Spacing.S1};
  background-color: ${Color.SOFT_LIGHT};
  border-radius: ${ShapeRadius.CONTROL};
  min-width: 194px;
  max-width: 294px;

  &:last-child {
    margin-right: 0;
  }
`;

export const ValueRow = styled.div`
  margin-top: ${Spacing.S1};
  display: flex;
`;

export const Percent = styled(H4)`
  margin-left: ${Spacing.S3};
  color: ${Color.LOCKED};
`;
