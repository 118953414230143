import * as React from 'react';

// Components
import { LoadingOverlay } from '@pushwoosh/ui/lib/molecules/LoadingOverlay';

import { LayoutWrap, HeaderWrap, SidebarWrap, Content } from './styled';

const SINGLE_SPA_BUILD = process.env.buildType === 'single-spa';

interface IProps {
  Header: React.ComponentType;
  Sidebar: React.ComponentType;
  children: React.ReactNode | React.ReactNode[];
  isLoading?: boolean;
}

export function LayoutDefault(props: IProps): JSX.Element {
  const { Header, Sidebar, children, isLoading = false } = props;

  if (isLoading) {
    return <LoadingOverlay size="l" isActive />;
  }

  return (
    <LayoutWrap>
      {!SINGLE_SPA_BUILD && (
        <SidebarWrap>
          <HeaderWrap>
            <Header />
          </HeaderWrap>
          <Sidebar />
        </SidebarWrap>
      )}
      <Content isHiddenSidebar={SINGLE_SPA_BUILD}>{children}</Content>
    </LayoutWrap>
  );
}
