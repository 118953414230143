import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgBulletSquareLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 6.5v3h3v-3h-3zM5.5 5a.5.5 0 00-.5.5v5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-5a.5.5 0 00-.5-.5h-5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgBulletSquareLined;
