import styled from 'styled-components';
import { Spacing, ShapeRadius, FontSize, FontWeight, Color } from '@pushwoosh/kit-constants';
import { Control } from '@pushwoosh/pwkit-control';

interface IButtonCardIconProps {
  backgroundColor?: string;
}

export const ButtonCardIcon = styled.div`
  margin-right: ${Spacing.S4};
  border-radius: ${ShapeRadius.CONTROL};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: ${({ backgroundColor }: IButtonCardIconProps) => backgroundColor || 'none'};
`;

export const ButtonCardControl = styled(Control)`
  display: flex;
  align-items: center;
  padding: 0 ${Spacing.S7};

  &:hover {
    cursor: pointer;
    background-color: rgba(137, 164, 191, 0.05);
  }
`;

export const ButtonCardTitle = styled.h4`
  font-size: ${FontSize.HEADING4};
  font-weight: ${FontWeight.REGULAR};
  color: ${Color.MAIN};
`;
