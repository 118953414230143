import styled from 'styled-components';

export const RichMediaPreviewContainer = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 180px;
  height: 320px;

  background: rgba(137, 164, 191, 0.1);
  border-radius: 4px;
`;
