import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgJourneySmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.416 2.376A.75.75 0 0010.25 3v1.25H5.622a2.25 2.25 0 100 1.5h4.628V7a.75.75 0 001.166.624l3-2a.75.75 0 000-1.248l-3-2zM3.5 4.25a.75.75 0 110 1.5.75.75 0 010-1.5zm9.148.75l-.898.599V4.4l.898.599z"
        fill={props.fill}
      />
      <path
        d="M6.815 8.34c-.148-.509-.28-.957-.43-1.34h1.592c.095.29.18.581.26.856l.053.184c.243.832.466 1.52.814 2.01.268.376.603.63 1.146.687V10a.75.75 0 011.166-.624l2.25 1.5a.75.75 0 010 1.248l-2.25 1.5A.75.75 0 0110.25 13v-.758c-1.09-.069-1.847-.59-2.368-1.323-.518-.73-.8-1.666-1.032-2.459l-.035-.12z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgJourneySmallLined;
