import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgStopSmallFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.586 1.816v5.107a.27.27 0 01-.538 0V2.885a.808.808 0 00-1.615 0v6.73l-1.09-1.492c-.324-.498-.954-.665-1.416-.38-.46.293-.575.929-.256 1.425 0 0 1.758 2.662 2.508 3.801C5.929 14.11 7.143 15 9.413 15c3.76 0 4.096-2.903 4.096-3.77V3.963a.808.808 0 00-1.615 0v3.5a.27.27 0 01-.538 0V2.346a.808.808 0 10-1.616.012v4.565a.27.27 0 01-.538 0V1.808a.808.808 0 10-1.616.008z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgStopSmallFilled;
