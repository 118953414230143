import { format } from 'date-fns';
import { Footnote, H4 } from '@pushwoosh/kit-typography';
import { ChangeItem } from '@src/common/types/changeLog';
import React from 'react';
import { getIcon } from './helpers/getIcon';
import { Container, IconWrap, InfoWrap, TimeWrap } from './styled';

type Props = {
  item: ChangeItem;
};

export function ChangeItemComponent(props: Props): JSX.Element {
  const { item } = props;
  const Icon = getIcon(item.type);

  return (
    <Container>
      <IconWrap>
        <Icon size="small" view="lined" />
      </IconWrap>
      <InfoWrap>
        <H4>{item.title}</H4>
        <Footnote>{item.actions[0]}</Footnote>
      </InfoWrap>
      <TimeWrap>
        <Footnote>{format(new Date(item.time), 'HH:mm')}</Footnote>
      </TimeWrap>
    </Container>
  );
}
