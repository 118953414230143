import { PointType } from '@src/common/constants';

export function getPointShortDescriptionByType(type: PointType): string {
  switch (type) {
    case PointType.SEND_IN_APP: {
      return 'Enable new channel to reach your audience. Reach out to our team to start communications inside your app.';
    }
    case PointType.WEBHOOK: {
      return 'Share journey data with just about any other service out there. Connect with our team to enable this option.';
    }
    case PointType.REACHABILITY_CHECK: {
      return 'Plan through which channels to reach your audience in advance. Upgrade to Marketing plan to enhance your communications.';
    }
    case PointType.SET_TAGS: {
      return 'Improve segmentation by updating user information right inside the journey. Upgrade to Marketing plan.';
    }
    case PointType.API: {
      return 'Improve your automation processes by initiating actions via a simple webhook call. Connect with our team to enable this option.';
    }
    default: {
      return '';
    }
  }
}
