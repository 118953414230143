import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgMinusRoundSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 8a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0zM15 8A7 7 0 111 8a7 7 0 0114 0zM5 7.25a.75.75 0 000 1.5h6a.75.75 0 000-1.5H5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgMinusRoundSmallLined;
