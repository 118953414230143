import React from 'react';
import { useBus } from '@src/common/contexts/bus';
import Konva from 'konva';
import {
  useDrawingArrow,
  useRefreshArrowsAfterChangingPointOutputs,
  useRemoveArrowOnClosePointsMenu
} from '@src/canvas/hooks';
import { useActionAddOutputToPoint, usePointOutputs } from '@src/store/hooks';
import { useStoreActions } from '@src/store/store';
import { IResult as PopupSore } from '@src/store/hooks/usePopupMenuStore';
import { calcArrowPosition } from '../../helpers';

type Options = {
  popupMenuStore: PopupSore;
  stageRef: React.MutableRefObject<Konva.Stage>;
};

export function useArrows(options: Options) {
  const { popupMenuStore, stageRef } = options;
  const updateArrow = useStoreActions((actions) => actions.arrow.updateArrow);
  const setArrows = useStoreActions((actions) => actions.arrow.setArrows);
  const actionAddOutputToPoint = useActionAddOutputToPoint();
  const pointOutputs = usePointOutputs();
  const bus = useBus();
  const { resetDrawArrow } = useDrawingArrow();

  useRefreshArrowsAfterChangingPointOutputs(stageRef, pointOutputs, setArrows);
  useRemoveArrowOnClosePointsMenu(popupMenuStore.isVisible, resetDrawArrow);

  const reCalcArrowPosition = React.useMemo(
    (): ReturnType<typeof calcArrowPosition> => calcArrowPosition(updateArrow),
    [updateArrow]
  );

  React.useEffect(() => {
    const onDrawEndOnPointSun = bus.select('onDrawEndOnPoint$').subscribe((event) => {
      actionAddOutputToPoint({
        fromPointId: event.payload.fromPointId,
        toPointId: event.payload.toPointId,
        key: event.payload.outputKey,
        order: event.payload.outputOrder
      });
    });

    const reCalcArrowPositionSub = bus.select('reCalcArrowPosition$').subscribe((event) => {
      const payload = event.payload as any[];

      payload.forEach((item) => reCalcArrowPosition(item));
    });

    return () => {
      onDrawEndOnPointSun.unsubscribe();
      reCalcArrowPositionSub.unsubscribe();
    };
  }, []);

  return {
    reCalcArrowPosition
  };
}
