import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPush(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14a3 3 0 013-3h18a3 3 0 013 3v11a3 3 0 01-3 3H13.613a.5.5 0 00-.294.096l-3.73 2.713A1 1 0 018 30V14zm3-1a1 1 0 00-1 1v13.054a.5.5 0 00.794.405l1.743-1.268a1 1 0 01.588-.191H29a1 1 0 001-1V14a1 1 0 00-1-1H11zm2 4a1 1 0 011-1h12a1 1 0 110 2H14a1 1 0 01-1-1zm1 3a1 1 0 100 2h7a1 1 0 100-2h-7z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPush;
