import * as React from 'react';
import { NumberStepper } from '@pushwoosh/kit-number-stepper';

import { TimeStepperContainer } from './styled';
import { TTimeStepperExactTimeProps } from './TimeStepperExactTime.types';

export function TimeStepperExactTime(props: TTimeStepperExactTimeProps): JSX.Element {
  const { names, value, onChange } = props;

  const onChangeTime = React.useCallback(
    (name, value): void => {
      onChange(name, value);
    },
    [onChange]
  );

  return (
    <TimeStepperContainer>
      <NumberStepper
        dimension="hour"
        name={names.exactTimeHour}
        value={value.exactTimeHour}
        max={23}
        onChange={({ value }) => onChangeTime(names.exactTimeHour, value)}
      />

      <NumberStepper
        dimension="min"
        name={names.exactTimeMinute}
        value={value.exactTimeMinute}
        max={59}
        onChange={({ value }) => onChangeTime(names.exactTimeMinute, value)}
      />
    </TimeStepperContainer>
  );
}
