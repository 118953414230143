import styled from 'styled-components';
import { Paragraph, Footnote } from '@pushwoosh/kit-typography';
import { Spacing, FontSize, Color } from '@pushwoosh/kit-constants';

export const Description = styled(Paragraph)`
  margin-top: ${Spacing.S4};
  margin-bottom: ${Spacing.S4};
`;

export const RepeatSettings = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  width: 100%;
`;

export const RepeatTypeWrap = styled.div`
  width: 288px;
  margin-right: ${Spacing.S5};
`;

export const RepeatEveryWrap = styled.div`
  width: 80px;
  margin-right: ${Spacing.S3};
`;

export const RepeatDescription = styled(Footnote)`
  font-size: ${FontSize.REGULAR};
  margin-top: ${Spacing.S5};
  max-width: 590px;
`;

export const RepeatFrequencyWrap = styled.div`
  width: 200px;
`;

export const RepeatDaypickerWrap = styled.div`
  margin-top: ${Spacing.S5};
`;

export const DatesSpecifiedWrap = styled.div`
  margin-top: ${Spacing.S5};
  display: flex;
  flex-wrap: wrap;
  max-width: 590px;
`;

export const DatepickerWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${Spacing.S5};
  margin-bottom: ${Spacing.S5};
`;

export const IconWrap = styled.div`
  margin-left: ${Spacing.S1};
  cursor: pointer;
`;

export const StyledPremiumIconWrapper = styled.div`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #e4efff;
  padding: ${Spacing.S3};
  height: 36px;
`;

export const StyledMessageIconWrapper = styled.div`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: ${Color.BRIGHT_LIGHT};
  padding: ${Spacing.S3};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
