import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';
import { Paragraph } from '@pushwoosh/kit-typography';

export const CommentsWrap = styled.div`
  padding: ${Spacing.S3} 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const EmptyCommentsList = styled(Paragraph)`
  text-align: center;
  margin: auto;
`;

export const ShowDeletedCheckbox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 ${Spacing.S5};
`;

export const ShowDeletedTitle = styled(Paragraph)`
  margin-left: ${Spacing.S2};
`;
