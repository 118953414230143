import { TFormErrors } from '@src/ui/hooks/useForm';
import { BranchType } from '@src/common/types/branch';
import { ICommonEvent } from '@src/common/types/event';
import { validateFormEvent } from '@src/ui/features/EditEvents/helpers/validateFormEvent';
import { IEditOnePointValues } from '@src/ui/features/EditEvents/components/FormEditOneEvent';
import { treeShakeErrors } from '@src/common/helpers';
import { WaitingPeriodType } from './components/WaitPeriodTime';

const convertICommonEventToIEditOnePointValues = (event: ICommonEvent): IEditOnePointValues => ({
  eventCode: event.name,
  isThrowOut: event.isThrowOut,
  eventConditions: event.eventConditions
});

const convertIEditOnePointValuesErrorsToICommonEventErrors = (
  errors: TFormErrors<IEditOnePointValues>
): TFormErrors<ICommonEvent> => ({
  name: errors.eventCode,
  isThrowOut: errors.isThrowOut,
  eventConditions: errors.eventConditions
});

const validateEvent = (event: ICommonEvent): TFormErrors<ICommonEvent> =>
  convertIEditOnePointValuesErrorsToICommonEventErrors(
    validateFormEvent(convertICommonEventToIEditOnePointValues(event))
  );

const validateBranch = (branch: BranchType): TFormErrors<BranchType> => ({
  events: branch.events.map(validateEvent)
});

export const validate = (values: WaitingPeriodType): TFormErrors<WaitingPeriodType> => {
  const errors: TFormErrors<WaitingPeriodType> = { branches: values.branches.map(validateBranch) };

  return treeShakeErrors(errors);
};
