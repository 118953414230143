import styled, { css } from 'styled-components';
import { Spacing, Color, FontSize, FontWeight } from '@pushwoosh/kit-constants';

// Types
import { SwitchProps } from './Switch.types';

interface AlignProps {
  labelPosition: SwitchProps['labelPosition'];
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${(props: AlignProps) =>
    props.labelPosition === 'left' ? 'row-reverse' : 'row'};
`;

interface ComponentProps {
  enabled: boolean;
  isDisabled?: boolean;
}

const defineColorBackgroundBox = css<ComponentProps>`
  ${(props): string => {
    if (props.isDisabled && !props.enabled) {
      return Color.CLEAR;
    }
    if (props.isDisabled && props.enabled) {
      return Color.LOCKED;
    }
    if (props.enabled) {
      return Color.PRIMARY_HOVER;
    }
    return Color.CLEAR;
  }}
`;

const defineColorBorderBox = css<ComponentProps>`
  ${(props): string => {
    if (props.isDisabled) {
      return Color.LOCKED;
    }
    if (props.enabled) {
      return Color.PRIMARY_HOVER;
    }
    return Color.PHANTOM;
  }}
`;

const defineColorCircle = css<ComponentProps>`
  ${(props): string => {
    if (props.isDisabled && props.enabled) {
      return Color.CLEAR;
    }
    if (props.isDisabled && !props.enabled) {
      return Color.LOCKED;
    }
    if (!props.isDisabled && props.enabled) {
      return Color.CLEAR;
    }
    return Color.MAIN;
  }}
`;

const cssActive = css`
  &:active {
    background: ${Color.PRIMARY_HOVER};
    border: 2px solid ${Color.PRIMARY_PRESSED};
  }
`;

export const Box = styled.div.attrs<ComponentProps>({
  tabIndex: '0'
})<ComponentProps>`
  width: 36px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: ${({ enabled }) => (enabled ? 'flex-end' : 'flex-start')};
  border: 2px solid ${defineColorBorderBox};
  border-radius: 10px;
  background-color: ${defineColorBackgroundBox};

  ${(props) => (props.isDisabled ? '' : cssActive)}

  cursor: pointer;
  outline: none;
`;

export const Circle = styled.div<ComponentProps>`
  margin: 0 ${Spacing.S0};
  width: 12px;
  height: 12px;
  background: ${defineColorCircle};
  border-radius: 50%;
`;

export const Label = styled.div<AlignProps>`
  display: flex;
  align-items: center;
  margin-left: ${Spacing.S3};
  color: ${Color.MAIN};
  font-size: ${FontSize.REGULAR};
  font-weight: ${FontWeight.MEDIUM};
  margin: ${(props) =>
    props.labelPosition === 'left' ? `0px ${Spacing.S3} 0px` : `0px  0px 0px ${Spacing.S3}`};
`;
