import styled from 'styled-components';
import { Paragraph } from '@pushwoosh/kit-typography';
import { Spacing, Color, ShapeRadius } from '@pushwoosh/kit-constants';

export const IconWrap = styled.div`
  cursor: pointer;
  color: ${Color.PHANTOM};
  display: none;
  margin-left: auto;

  &:hover {
    color: ${Color.DANGER};
  }
`;

type Props = {
  isDeleteHovered: boolean;
  isPressed?: boolean;
};

export const Container = styled.div<Props>`
  padding: ${Spacing.S5} ${Spacing.S7};
  cursor: pointer;

  opacity: ${({ isPressed }) => (isPressed ? '0.5' : '1')};

  &:hover {
    background-color: ${({ isDeleteHovered }) =>
      isDeleteHovered ? Color.DANGER_LIGHT : Color.ROW_HOVER};

    ${IconWrap} {
      display: block;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: ${Spacing.S3};
`;

export const Number = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isDeleteHovered }) =>
    isDeleteHovered ? Color.DANGER_LIGHT : Color.SOFT_LIGHT};
  color: ${({ isDeleteHovered }) => (isDeleteHovered ? Color.DANGER : Color.MAIN)};
  border-radius: ${ShapeRadius.CONTROL};
  width: 24px;
  height: 24px;
  font-size: 12px;
  font-weight: 500;
  margin-right: ${Spacing.S4};
`;

export const Date = styled(Paragraph)`
  color: ${Color.LOCKED};
  margin-right: ${Spacing.S4};
`;

export const Message = styled(Paragraph)`
  margin-left: ${Spacing.S10};
`;
