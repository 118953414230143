import * as React from 'react';

// Components
import { Logo } from '@src/ui/atoms/Logo';

// Styles
import { Wrap } from './styled';

export function HeaderJourneysPage(): JSX.Element {
  return (
    <Wrap>
      <Logo isLarge />
    </Wrap>
  );
}
