import React from 'react';
import { sendMixpanelEvent } from '@src/common/analytics/mixpanel';
import { useStoreActions } from '@src/store/store';
import { useCurrentJourneyId } from '@src/store/hooks';
import { Button, IconEnum } from '@src/ui/kit';
import { Footer, ValidationButtons, ValidationControls } from './styled';

type Props = {
  nextError: () => void;
  prevError: () => void;
  stopValidation: () => void;
};

export function ValidationFooter(props: Props): JSX.Element {
  const { nextError, prevError, stopValidation } = props;
  const journeyId = useCurrentJourneyId();
  const validateById = useStoreActions((actions) => actions.journeys.validateById);
  const hideSidepanel = useStoreActions((actions) => actions.sidebars.closeAllSidebars);

  const handleValidate = React.useCallback((): void => {
    sendMixpanelEvent({
      eventName: 'Canvas:Drawer:Validation',
      eventData: { DrawerAction: 'Revalidate' }
    });
    hideSidepanel();
    validateById({ journeyId });
  }, [validateById, journeyId, hideSidepanel]);

  return (
    <Footer>
      <ValidationButtons>
        <Button color="secondary" view="shape" size="field" onClick={handleValidate}>
          Revalidate
        </Button>
        <Button color="secondary" view="shape" size="field" onClick={stopValidation}>
          Stop
        </Button>
      </ValidationButtons>
      <ValidationControls>
        <Button
          onClick={prevError}
          color="secondary"
          view="shape"
          size="field"
          iconType={IconEnum.ARROW_LEFT_MEDIUM}
        />
        <Button
          onClick={nextError}
          color="secondary"
          view="shape"
          size="field"
          iconType={IconEnum.ARROW_RIGHT_MEDIUM}
        />
      </ValidationControls>
    </Footer>
  );
}
