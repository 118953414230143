import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPremiumDisabled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.788 5.535a.5.5 0 01-.724.135L2.567 3.798a.5.5 0 00-.789.504l1.133 5.3a.5.5 0 00.489.395h9.2a.5.5 0 00.488-.396l1.133-5.285a.5.5 0 00-.788-.505l-2.498 1.861a.5.5 0 01-.723-.136L8.423 2.675a.5.5 0 00-.848 0l-1.787 2.86zm7.211 5.961a.5.5 0 00-.5-.5h-9a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-1z"
        fill="#C4C8CC"
      />
      <path
        opacity={0.4}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.821 12.996l-.681-.152a.498.498 0 01-.14-.348v-1a.5.5 0 01.5-.5h6.162l-.447 2H3.82zm7.018-7.268l-.954 4.269H3.4a.5.5 0 01-.489-.396L1.778 4.302a.5.5 0 01.79-.504L5.063 5.67a.5.5 0 00.724-.135l1.787-2.86a.5.5 0 01.848 0l1.789 2.861a.5.5 0 00.627.192z"
        fill="url(#PremiumDisabled_svg__paint0_linear_1178_150052)"
      />
      <path
        opacity={0.6}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.126 10.996l-.447 2h-1.18a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1.627zm1.532-6.853L5.35 9.997H3.4a.5.5 0 01-.489-.396L1.778 4.302a.5.5 0 01.79-.504L5.063 5.67a.5.5 0 00.724-.135l.87-1.392z"
        fill="url(#PremiumDisabled_svg__paint1_linear_1178_150052)"
      />
      <defs>
        <linearGradient
          id="PremiumDisabled_svg__paint0_linear_1178_150052"
          x1={6.072}
          y1={2.44}
          x2={-0.559}
          y2={6.477}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="PremiumDisabled_svg__paint1_linear_1178_150052"
          x1={7.073}
          y1={2.44}
          x2={2.324}
          y2={7.144}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#fff" stopOpacity={0.3} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgPremiumDisabled;
