import React from 'react';

import { OperatorTypeEnum, OperatorTypesHumans } from '@src/common/constants/attribute';
import { AttributeValuesType } from '@src/common/types/attribute';
import { StyledOperator, StyledWrapper } from './styled';

interface IProps {
  condition: string;
  operator: OperatorTypeEnum;
  value: AttributeValuesType;
}

export function Condition(props: IProps) {
  const { condition, operator, value } = props;
  return (
    <StyledWrapper>
      {condition}
      <StyledOperator>{OperatorTypesHumans[operator]}</StyledOperator>
      {typeof value === 'string' ? value : value.toString()}
    </StyledWrapper>
  );
}
