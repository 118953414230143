import React from 'react';
import { Spinner } from '@src/ui/kit/Spinner';
import { ThunkCreator } from 'easy-peasy';
import { IJourney } from '@src/common/types/journey';
import {
  useComments,
  useCurrentJourneyId,
  useGetJourneyById,
  usePoints,
  useGoalEventPoints
} from '@src/store/hooks';
import { IJourneySilentHours } from '@src/common/types/silentHours';
import { FrequencyCapping } from '@src/common/types/frequencyCapping';
import { TComment } from '@src/common/types/comments';
import { TPoints } from '@src/common/types/points';
import { StyledButton, ButtonField, LoaderWrap } from '../StartButton/styled';

type Props = {
  isLoadingConfirm: boolean;
  resumeJourney: ThunkCreator<
    {
      id: string;
      title: string;
      params: {
        silentHours?: IJourneySilentHours;
        frequencyCapping?: FrequencyCapping;
        applicationCode?: string;
      };
      comments: TComment[];
      points: TPoints[];
    },
    Promise<IJourney>
  >;
};

export function ResumeButton(props: Props): JSX.Element {
  const { isLoadingConfirm, resumeJourney } = props;
  const currentJourneyId = useCurrentJourneyId();
  const getJourneyById = useGetJourneyById();
  const journey = getJourneyById(currentJourneyId);
  const points = usePoints();
  const comments = useComments();
  const { goalEventPoints } = useGoalEventPoints();

  const onClick = React.useCallback(() => {
    resumeJourney({
      title: journey.title,
      params: journey.params,
      points: [...points, ...goalEventPoints],
      comments,
      id: currentJourneyId
    });
  }, [
    currentJourneyId,
    resumeJourney,
    journey.title,
    journey.params,
    points,
    goalEventPoints,
    comments
  ]);

  return (
    <StyledButton isDisabled={isLoadingConfirm} onClick={onClick}>
      {isLoadingConfirm ? (
        <>
          <LoaderWrap>
            <Spinner />
          </LoaderWrap>
          <ButtonField isDisabled={isLoadingConfirm}>Validating…</ButtonField>
        </>
      ) : (
        <ButtonField isDisabled={isLoadingConfirm}>Re-launch campaign</ButtonField>
      )}
    </StyledButton>
  );
}
