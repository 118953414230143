import * as React from 'react';

// Store
import { useStoreActions, useStoreState } from '@src/store/store';

// Hooks
import { useAddNotifications } from '@src/store/hooks';

// Enums
import { NotificationType, NOTIFICATION_TITLE_SUCCESS } from '@src/common/constants/notification';

// Types
import { TRequest } from '@src/store/models/requests/requests.types';
import { IUniqueId } from '@src/common/types/entities';

type TArchiveJourneyById = (journeyId: IUniqueId['id']) => Promise<void>;
type TResult = [TArchiveJourneyById, boolean];

export function useArchiveJourneyById(): TResult {
  const addNotifications = useAddNotifications();

  const archiveJourneyById: TArchiveJourneyById = useStoreActions<TArchiveJourneyById>(
    (actions): TArchiveJourneyById => actions.journeys.archiveById
  );

  const request: TRequest = useStoreState<TRequest>(
    (state): TRequest => state.requests.entities['journeys.archiveById']
  );

  const handleJourneyArchiveById = React.useCallback<TArchiveJourneyById>(
    async (journeyId): Promise<void> => {
      await archiveJourneyById(journeyId);

      addNotifications([
        {
          title: NOTIFICATION_TITLE_SUCCESS.archiveJourney,
          type: NotificationType.SUCCESS
        }
      ]);
    },
    [archiveJourneyById, addNotifications]
  );

  return [handleJourneyArchiveById, Boolean(request)];
}
