import * as React from 'react';

// Analytics
import { sendEventToGA, sendMixpanelEvent } from '@src/common/analytics';

// UI Components
import { Modal } from '@src/ui/kit/Modal';
import { Section } from '@src/ui/kit';
import { DefaultButtons, ExtraOneButton } from '@src/ui/kit/ModalButtons';

// Containers
import { EditEvents, useEditEvents } from '@src/ui/features/EditEvents';

// Store Hooks
import {
  useCloseAllModals,
  useCurrentJourneyId,
  useGetJourneyById,
  useGoalEventPoints,
  useJourneyAppCode
} from '@src/store/hooks';

// Store Helpers
import { makePoint } from '@src/store/helpers';

// Enums
import { PointType } from '@src/common/constants/point';
import { JourneyStatus } from '@src/common/constants/journey';

// Types
import { TGoalEventPoint } from '@src/common/types/points';

export function ModalConversionGoals(): JSX.Element {
  const { goalEventPoints, updateGoalEvents } = useGoalEventPoints();
  const journeyId = useCurrentJourneyId();
  const getJourneyById = useGetJourneyById();
  const applicationCode = useJourneyAppCode(journeyId);
  const journey = getJourneyById(journeyId);

  const closeAllModals = useCloseAllModals();

  const eventGoals = goalEventPoints.map((item) => ({
    id: item.id,
    ...item.data
  }));

  const { listEvents, updateEvent, addEvent, removeEvent } = useEditEvents(eventGoals);

  const handleEventGoalsSave = React.useCallback(() => {
    const newGoalPoints = listEvents.map((item) =>
      makePoint({
        type: PointType.GOAL_EVENT,
        data: {
          applicationCode,
          name: item.name,
          eventConditions: item.eventConditions,
          isThrowOut: item.isThrowOut
        }
      })
    );
    updateGoalEvents(newGoalPoints as TGoalEventPoint[]);
    sendMixpanelEvent({
      eventName: 'SetGoals',
      eventData: {
        GoalsCount: newGoalPoints.length
      }
    });
    closeAllModals();
  }, [listEvents, updateGoalEvents, closeAllModals, applicationCode]);

  const handleCancel = React.useCallback(() => {
    if (journey.status === JourneyStatus.RUNNING) {
      sendMixpanelEvent({
        eventName: 'JourneyEditModeChangesCancelled',
        eventData: {
          PointType: 'ConversionGoals'
        }
      });
    }

    closeAllModals();
  }, [closeAllModals, journey.status]);

  return (
    <Modal
      title="Conversion Goals"
      footerLeft={
        <ExtraOneButton
          disabled={journey.status === JourneyStatus.RUNNING}
          actionButtonName="Add goal"
          onClickActionButton={(): void => {
            addEvent();
            sendEventToGA({
              eventAction: 'Add',
              eventLabel: 'Goal'
            });
          }}
        />
      }
      footerRight={
        <DefaultButtons
          onClickActionButton={handleEventGoalsSave}
          actionButtonName={journey.status === JourneyStatus.RUNNING ? 'Save' : 'Apply'}
          onClickCancelButton={handleCancel}
        />
      }
      isOpen
    >
      <Section>
        {
          'Set a target event indicating that the customer reaches the marketing objective of the journey.'
        }
      </Section>
      <EditEvents
        events={listEvents}
        updateEvent={updateEvent}
        removeEvent={removeEvent}
        isShowThrowOut
        removeDisabled={journey.status === JourneyStatus.RUNNING}
      />
    </Modal>
  );
}
