import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgTriggerBasedEntryPointFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={24} height={24} rx={4} fill="#479900" />
      <path
        d="M7.893 13.24a.5.5 0 01-.426-.762l4.638-7.557c.361-.589 1.27-.282 1.2.405l-.492 4.885a.5.5 0 00.497.55h2.79a.5.5 0 01.426.764l-4.712 7.59c-.363.585-1.268.277-1.199-.408l.496-4.918a.5.5 0 00-.498-.55h-2.72z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgTriggerBasedEntryPointFilled;
