import * as React from 'react';
import { sendMixpanelEvent } from '@src/common/analytics';
import { ICommonEvent } from '@src/common/types/event';
import { OperationsType } from '@src/common/constants/operations';

export const useEditEvents = (events: ICommonEvent[]) => {
  const [listEvents, updateEvents] = React.useState(
    events.map((item) => ({
      ...item,
      operation: { type: item?.operation?.type ?? null, toIndex: item?.operation?.toIndex ?? 0 }
    }))
  );

  const updateOperator = React.useCallback(
    (indexElement: number) => {
      const newListEvents = listEvents.map((item, index) => {
        if (index === indexElement) {
          return {
            ...item,
            operation: {
              ...item.operation,
              type:
                item.operation.type === OperationsType.OR ? OperationsType.AND : OperationsType.OR
            }
          };
        }
        return item;
      });

      updateEvents([...newListEvents]);

      sendMixpanelEvent({
        eventName: 'CustomerJourneyWaitFotTriggerAndOrConditionSelected'
      });
    },
    [listEvents]
  );

  const updateEvent = React.useCallback(
    (indexElement: number, data: ICommonEvent, pointId?: string) => {
      const newListEvents = listEvents.map((item, index) => {
        if (index === indexElement) {
          return {
            id: pointId,
            operation: item.operation,
            ...data
          };
        }
        return item;
      });

      updateEvents([...newListEvents]);
    },
    [listEvents, updateEvents]
  );

  const addEvent = React.useCallback(
    (index?: number) => {
      updateEvents([
        ...listEvents,
        {
          name: '',
          isThrowOut: false,
          eventConditions: [],
          operation: {
            type: OperationsType.OR,
            toIndex: index
          }
        }
      ]);
    },
    [listEvents, updateEvents]
  );

  const removeEvent = React.useCallback(
    (id: number) => {
      if (listEvents.length > 1) {
        const newStateEvents = listEvents.filter((_, index): boolean => id !== index);
        updateEvents([...newStateEvents]);
      }
      if (listEvents.length === 1) {
        updateEvents([
          {
            name: '',
            isThrowOut: false,
            eventConditions: [],
            operation: { type: null, toIndex: 0 }
          }
        ]);
      }
    },
    [listEvents]
  );

  React.useEffect(() => {
    if (listEvents.length === 0) {
      addEvent();
    }
  }, [listEvents, addEvent]);

  return {
    listEvents,
    updateEvent,
    removeEvent,
    addEvent,
    updateOperator
  };
};
