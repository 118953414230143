import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSuperUserSmallFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.8 2H4.15v3.3a1.1 1.1 0 001.1 1.1h5.5a1.1 1.1 0 001.1-1.1V2H10.2v2.2H8.825V2h-1.65v2.2H5.8V2zM4.7 10.8A2.2 2.2 0 002.5 13v.55c0 .304.246.55.55.55h9.9a.55.55 0 00.55-.55V13a2.2 2.2 0 00-2.2-2.2H4.7zM10.887 7.5H5.112L4.15 9.7h7.7l-.963-2.2z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgSuperUserSmallFilled;
