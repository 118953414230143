import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgDangerTriangleMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.752 4.186c-.76-1.381-2.745-1.381-3.505 0L2.63 18.036C1.897 19.37 2.861 21 4.382 21h15.235c1.522 0 2.486-1.63 1.753-2.964l-7.618-13.85zM11 8.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5V14a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V8.5zm0 8a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgDangerTriangleMediumFilled;
