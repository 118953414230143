import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Color, FontSize } from '@pushwoosh/kit-constants';

// Analytics
import { sendMixpanelEvent } from '@src/common/analytics';

// Components
import { Icon, IconEnum } from '@src/ui/kit/Icon';
import { LoaderSkeleton } from '@src/ui/features/JourneyList/components/LoaderSkeleton';
import { IconSort } from '@src/ui/features/JourneyList/components/IconSort';
import { JourneyControls } from '@src/ui/features/JourneyList/components/JorneyControls';
import { JourneyTitle } from '@src/ui/features/JourneyList/components/Table/components/JourneyTitle';

// Hooks
import { useSort } from '@src/ui/features/JourneyList/components/Table/hooks/useSort';
import {
  usePagination,
  OPTIONS_SHOWN_ON_PAGE
} from '@src/ui/features/JourneyList/components/Table/hooks/usePagination';

// Constants
import { JourneyStatus } from '@src/common/constants/journey';

// Helper
import { formatNumber } from '@src/common/helpers';
import { makeTextStatusJourney } from '@src/ui/helpers/makeTextStatusJourney';
import { formatDateTimeDiff } from '@src/ui/helpers';
import { Skeleton } from '../LoaderSkeleton/styled';
import { defineColorByStatus } from './helpers/defineColorByStatus';

// Styled
import {
  ListContainer,
  TableContainer,
  PaginationContainer,
  Header,
  Cell,
  TitleWrap,
  Application,
  Badge,
  GrayText,
  BlackText,
  Pages,
  Page,
  ShowOnPage,
  ShowOnPageLabel,
  Select,
  LinkTitle
} from './styled';

// Types
import { TableProps, Column, ColumnConstant } from './Table.types';

export const Table = (props: TableProps): JSX.Element => {
  const { cells, isLoadingJourneys, isLoadingStats, pagination, onChange, onReloadJourneys } =
    props;
  const params = useParams<{ applicationCode: string }>();
  const { applicationCode } = params;

  const { page: newCurrentPage, pageCount, totalCount } = pagination;
  const columns: Column[] = React.useMemo(
    () => [
      {
        name: 'name',
        key: ColumnConstant.TITLE
      },
      {
        name: 'status',
        key: ColumnConstant.STATUS
      },
      {
        name: 'date modified',
        key: ColumnConstant.DATA_MODIFIED
      },
      {
        name: 'date created',
        key: ColumnConstant.CATEGORY
      },
      {
        name: 'total entries',
        key: ColumnConstant.TOTAL_ENTRIES
      },
      { name: 'average ctr', key: ColumnConstant.AVR_CTR },
      {
        name: 'goal reached',
        key: ColumnConstant.GOAL_REACHED
      },
      {
        name: '',
        key: ColumnConstant.ACTION
      }
    ],
    []
  );

  const { columnSort, sortType, changeSort } = useSort(onChange);
  const { page, perPage, pages, changePage, changePerPage } = usePagination(
    newCurrentPage,
    pageCount,
    onChange
  );

  return (
    <ListContainer>
      <TableContainer>
        {columns.map((column, columnIndex) => {
          const sortable =
            column.key === ColumnConstant.TITLE || column.key === ColumnConstant.DATA_MODIFIED;
          return (
            <Header
              key={columnIndex}
              isSortable={sortable}
              isSortActive={columnSort === column.key}
              onClick={() => sortable && changeSort(column.key)}
            >
              {column.name}
              {sortable && (
                <IconSort currentColumn={column.key} columnSort={columnSort} sortType={sortType} />
              )}
            </Header>
          );
        })}
        {isLoadingJourneys && <LoaderSkeleton />}
        {!isLoadingJourneys &&
          cells.map((cell, cellIndex) => (
            <React.Fragment key={cellIndex}>
              <Cell>
                <TitleWrap>
                  <LinkTitle
                    to={
                      applicationCode
                        ? `/journeys/${applicationCode}/${cell.status}/${cell.id}`
                        : `/journeys/${cell.status}/${cell.id}`
                    }
                    color={Color.MAIN}
                    size={FontSize.REGULAR}
                    onClick={() => sendMixpanelEvent({ eventName: 'OpenJourney' })}
                    $isFullWidth
                  >
                    <JourneyTitle value={cell.title} />
                    <Icon type={IconEnum.CHEVRON_RIGHT_SMALL} />
                  </LinkTitle>
                  <Application>{cell.application?.title}</Application>
                </TitleWrap>
              </Cell>
              <Cell key={cellIndex}>
                <Badge color={defineColorByStatus(cell.status as JourneyStatus)} isSmall isBoldText>
                  {makeTextStatusJourney(cell.status)}
                </Badge>
              </Cell>
              <Cell>
                <GrayText>{formatDateTimeDiff(cell.dataModified)}</GrayText>
              </Cell>
              <Cell>
                <GrayText>{formatDateTimeDiff(cell.category)}</GrayText>
              </Cell>
              <Cell>
                {isLoadingStats ? (
                  <Skeleton size={ColumnConstant.GOAL_REACHED} even={cellIndex % 2 === 0} />
                ) : (
                  <BlackText>
                    {cell.totalEntires === 0 ? '- -' : formatNumber(cell.totalEntires)}
                  </BlackText>
                )}
              </Cell>
              <Cell>
                {isLoadingStats ? (
                  <Skeleton size={ColumnConstant.AVR_CTR} even={cellIndex % 2 === 0} />
                ) : (
                  <BlackText>{cell.avrCTR === 0 ? '- -' : formatNumber(cell.avrCTR)}</BlackText>
                )}
              </Cell>
              <Cell>
                {isLoadingStats ? (
                  <Skeleton size={ColumnConstant.GOAL_REACHED} even={cellIndex % 2 === 0} />
                ) : (
                  <BlackText>
                    {cell.goalReached === 0 ? '- -' : formatNumber(cell.goalReached)}
                  </BlackText>
                )}
              </Cell>
              <Cell>
                <JourneyControls
                  journeyId={cell.id}
                  journeyStatus={cell.status}
                  journeyTitle={cell.title}
                  onReloadJourneys={onReloadJourneys}
                />
              </Cell>
            </React.Fragment>
          ))}
      </TableContainer>
      <PaginationContainer>
        {totalCount > perPage && (
          <Pages>
            <Page onClick={() => page !== 1 && changePage(page - 1)}>
              <Icon
                type={IconEnum.ARROW_LEFT_MEDIUM}
                fill={page === 1 ? Color.LOCKED : Color.MAIN}
              />
            </Page>
            {pages.map((currentPage, indexPage) => (
              <Page
                key={indexPage}
                isActive={currentPage === page}
                onClick={() => changePage(currentPage)}
              >
                {currentPage}
              </Page>
            ))}
            <Page onClick={() => page < pageCount && changePage(page + 1)}>
              <Icon
                type={IconEnum.ARROW_RIGHT_MEDIUM}
                fill={page === pageCount ? Color.LOCKED : Color.MAIN}
              />
            </Page>
          </Pages>
        )}
        <ShowOnPage>
          <ShowOnPageLabel>Show on page:</ShowOnPageLabel>
          <Select
            value={{
              label: perPage,
              value: perPage
            }}
            options={OPTIONS_SHOWN_ON_PAGE}
            onChange={({ value }) => changePerPage(value as number)}
            isSearchable={false}
          />
        </ShowOnPage>
      </PaginationContainer>
    </ListContainer>
  );
};
