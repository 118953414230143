import React from 'react';

// Components
import { Section } from '@src/ui/kit';
import { FormInput } from '@src/ui/form';

// Types
import { IEditTitleProps } from './EditJourneyTitle.types';

export function EditJourneyTitle(props: IEditTitleProps): JSX.Element {
  const { names, values, errors, touched, onBlurField, setFieldValue, onSubmit } = props;

  return (
    <Section direction="column">
      <form onSubmit={onSubmit} style={{ width: '100%' }}>
        <FormInput
          label="Journey name"
          error={touched.title && errors.title}
          name={names.title}
          value={values.title}
          onChange={setFieldValue}
          onBlur={onBlurField}
        />
      </form>
    </Section>
  );
}
