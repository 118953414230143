import * as React from 'react';
import { Group, Path } from 'react-konva';

// Types
import { IProps } from './Icon.types';

export function Icon(props: IProps): JSX.Element {
  const { width, height, x = 0, y = 0, pathsData = [], scale = 1, fill = 'white' } = props;

  const paths = React.useMemo(
    (): JSX.Element[] =>
      pathsData.map(
        (pathData): JSX.Element => (
          <Path scale={{ x: scale, y: scale }} key={pathData} data={pathData} fill={fill} />
        )
      ),
    [pathsData, fill, scale]
  );

  return (
    <Group x={x} y={y} width={width * scale} height={height * scale}>
      {paths}
    </Group>
  );
}
