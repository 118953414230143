import styled from 'styled-components';
import { Color, FontSize, FontWeight, Spacing } from '@pushwoosh/kit-constants';
import { TextInput } from '@src/ui/kit/TextInput';
import { Icon } from '@src/ui/kit';

interface IStyledWrapperProps {
  left: number;
  top: number;
}

export const StyledWrapper = styled.div`
  position: absolute;
  left: ${({ left }: IStyledWrapperProps) => `${left}px`};
  top: ${({ top }: IStyledWrapperProps) => `${top}px`};
  width: 220px;
  background-color: ${Color.CLEAR};
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06), 0px 0px 4px rgba(0, 0, 0, 0.06);
  padding-bottom: ${Spacing.S3};
  z-index: 100;
  max-height: 300px;
`;

export const StyledItem = styled.div`
  display: flex;
  align-items: center;
  padding: ${Spacing.S3} ${Spacing.S5};

  white-space: nowrap;
  cursor: pointer;

  color: #4d4d4d;
  font-style: normal;
  font-weight: normal;
  font-size: ${FontSize.SMALL};
  text-align: left;

  &:hover {
    background-color: ${Color.ROW_HOVER};
  }

  &:active {
    opacity: 0.5;
  }
`;

export const StyledName = styled.div`
  margin-left: ${Spacing.S4};
  margin-right: ${Spacing.S4};
  font-size: ${FontSize.REGULAR};
`;

export const StyledTitle = styled.div`
  padding-left: ${Spacing.S5};
  padding-right: ${Spacing.S5};
  font-weight: ${FontWeight.MEDIUM};
  font-size: ${FontSize.SMALL};
  text-transform: uppercase;
  color: ${Color.LOCKED};
  margin-top: 12px;
`;

export const StyledInput = styled(TextInput)`
  height: 52px;
  padding-left: ${Spacing.S11};
  border: none;
  border-bottom: 1px solid ${Color.DIVIDER};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  &:focus,
  :hover {
    border: none;
    border-bottom: 1px solid ${Color.DIVIDER};
  }
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 14px;
  left: 8px;
`;

export const StyledPointsWrapper = styled.div`
  max-height: 248px;
  overflow: scroll;
`;

export const StyledEmptyList = styled.div`
  padding: ${Spacing.S5};
  padding-top: ${Spacing.S7};
  color: ${Color.LOCKED};
`;
