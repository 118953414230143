import React from 'react';

// Components
import { Message, Switch } from '@src/ui/kit';

// Types
import { EditApiEntriesValues } from '@src/ui/features/ApiEntry/components/EditApiEntry';

// Styled
import { Color } from '@pushwoosh/kit-constants';
import { NumberStepper } from '@pushwoosh/kit-number-stepper';
import { LimitsDescription, StyledNumberWrap } from './styled';

interface IProps {
  names: Record<keyof EditApiEntriesValues, string>;
  values: EditApiEntriesValues;
  setFieldValue: (name: string, value: unknown) => void;
  min?: number;
  max?: number;
}

export function MessageRateLimits(props: IProps): JSX.Element {
  const { names, values, setFieldValue, min, max } = props;
  const handleUpdateLimit = React.useCallback(
    (value: number) => {
      setFieldValue(names.entryLimitsPerSecond, value);
    },
    [setFieldValue, names.entryLimitsPerSecond]
  );

  const toggleLimit = React.useCallback(
    (value: boolean) => {
      setFieldValue(names.entryLimitsEnabled, value);
    },
    [names.entryLimitsEnabled, setFieldValue]
  );

  return (
    <>
      <Switch defaultEnabled={values.entryLimitsEnabled} onChange={toggleLimit}>
        Message Rate Limits
      </Switch>
      <LimitsDescription>
        Specify how many users from segment can enter the journey at once every second.
      </LimitsDescription>
      {values.entryLimitsEnabled && (
        <>
          <StyledNumberWrap>
            <NumberStepper
              name={names.entryLimitsPerSecond}
              min={min || 100}
              step={min || 100}
              value={values.entryLimitsPerSecond}
              max={max || 50000}
              dimension="users/sec"
              onChange={({ value }) => handleUpdateLimit(value)}
            />
          </StyledNumberWrap>
          <Message
            color={Color.WARNING_LIGHT}
            title="Long processing time is possible"
            message="Please use this setting carefully, as it might take extremely long time for your audience to enter the journey"
          />
        </>
      )}
    </>
  );
}
