import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgTrashMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.746 6.935A.5.5 0 0018 6.5v-1a.5.5 0 00-.5-.5h-3.293a.5.5 0 01-.353-.146l-.708-.708A.5.5 0 0012.793 4h-1.586a.5.5 0 00-.353.146l-.708.708A.5.5 0 019.793 5H6.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h11c.09 0 .173-.023.246-.065zM10.75 11.25a.75.75 0 01.75.75v4a.75.75 0 01-1.5 0v-4a.75.75 0 01.75-.75zM14 12a.75.75 0 00-1.5 0v4a.75.75 0 001.5 0v-4z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 8.5a.5.5 0 00-.5-.5h-9a.5.5 0 00-.5.5V18a2 2 0 002 2h6a2 2 0 002-2V8.5zm-8.5 1V18a.5.5 0 00.5.5h6a.5.5 0 00.5-.5V9.5h-7z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgTrashMediumLined;
