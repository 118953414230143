import { SplitOutput } from '@src/common/types/statistics';

const SIGNIFICANT_MESSAGE_BASELINE = 'Significantly lower than control group';
const SIGNIFICANT_MESSAGE = 'Significantly better than control group';

export function checkWinner(
  statOutputs: { [key: string]: SplitOutput[] },
  goalId: string,
  isBaseline: boolean,
  uplift: number
) {
  let result = false;

  if (isBaseline) {
    const isNoConversion = Object.keys(statOutputs || {}).every((output) =>
      output === 'output0'
        ? true
        : statOutputs[output].find((item) => item.goalUUID === goalId)?.uplift < 0
    );

    const isSignificant = Object.values(statOutputs || {}).some(
      (output) =>
        output.find((item) => item.goalUUID === goalId)?.message === SIGNIFICANT_MESSAGE_BASELINE
    );

    result = isNoConversion && isSignificant;
  } else {
    const maxUpliftBranch = Object.values(statOutputs || {}).reduce((prevValue, stat) => {
      const bStat = stat.find((item) => item.goalUUID === goalId);
      if (bStat?.uplift > prevValue) {
        return bStat?.uplift;
      }

      return prevValue;
    }, 0);
    const isSignificant = Object.values(statOutputs || {}).some((output) => {
      const stat = output.find((item) => item.goalUUID === goalId);

      return stat?.message === SIGNIFICANT_MESSAGE;
    });

    result = isSignificant && uplift === maxUpliftBranch;
  }

  return result;
}
