// Types
import { IDictionary } from '../types/types';

export enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error',
  ERROR_POINT = 'error_point'
}

export const NOTIFICATION_TITLE_SUCCESS: IDictionary<string> = {
  activateJourney: 'Journey has been activated',
  deleteJourney: 'Journey has been deleted',
  updateJourney: 'Journey has been saved',
  deactivateJourney: 'Journey has been deactivated',
  archiveJourney: 'Journey has been archived',
  createTemplate: 'Creating journey...'
} as const;

export const NOTIFICATION_TITLE_FAIL: IDictionary<string> = {
  activateJourney: 'Unable to activate the journey',
  deactivateJourney: 'Journey cannot be deactivated',
  deleteJourney: 'Journey cannot be deleted',
  fetchJourney: 'Unable to get journey information',
  fetchJourneys: 'Unable to get journeys list',
  createJourney: 'Unable to create a journey',
  serverError: 'Server is not responding',
  notFoundJourney: 'Journey is not found'
} as const;

export const NOTIFICATION_MESSAGE_FAIL: IDictionary<string> = {
  haveErrorsPoints: 'Please fix the journey before activation ',
  serverError: 'Please try again later'
} as const;
