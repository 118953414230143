import * as React from 'react';

// Components
import { DocElements } from '@src/ui/features/Documentation/components/DocElements';

export function DocumentationPointSendBooleanSplitter(): JSX.Element {
  return (
    <DocElements>
      <DocElements.Paragraph>
        <strong>Splitter </strong>
        {
          "divides the journey into two separate branches based on segmentation. Choose a Filter from your Pushwoosh account to constitute the first segment. Customers who do not fall under Filter's conditions will go through the second branch of the journey. Each branch can include any elements following the"
        }
        <strong> True/False </strong>
        Splitter and end with its own
        <strong> Exit</strong>.
      </DocElements.Paragraph>

      <DocElements.Image
        src="documentation-point-boolean-splitter_gif-1.gif"
        label="Using a Segment Split"
        alt="Using a Segment Split"
      />
    </DocElements>
  );
}
