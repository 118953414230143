import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgArrowDownSmall(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.75 4a.75.75 0 00-1.5 0v5.19L5.53 7.47a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 00-1.06-1.06L8.75 9.19V4z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgArrowDownSmall;
