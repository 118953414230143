import React from 'react';

import { DocElements } from '@src/ui/features/Documentation/components/DocElements';
import { Link } from '@src/ui/kit';
import { FontWeight } from '@pushwoosh/kit-constants';

export function DocumentationWebhook(): JSX.Element {
  return (
    <DocElements>
      <DocElements.Paragraph>
        {
          'Using webhooks, you can share journey data with just about any other service out there: analytics, CRM systems, marketing automation services, and much more. For example, automatically notify external services when a customer has taken a particular action within the journey, send customer data into your analytic tools, trigger third-party emails or SMS on specific in-journey events – there are numerous use cases, choose yours.'
        }
      </DocElements.Paragraph>
      <DocElements.NumberedList>
        <DocElements.ListItem>
          {'Drag-and-drop the '}
          <strong>Webhook</strong>
          {' element to the canvas. Place '}
          <strong>Webhook</strong>
          {
            " anywhere you'd like, keeping in mind what journey info you're going to send to a third-party service."
          }
        </DocElements.ListItem>

        <DocElements.ListItem>
          {
            'Give it a name. It might be handy to name webhooks according to the services they send data to or the use case.'
          }
        </DocElements.ListItem>

        <DocElements.Image
          src="documentation-webhook_img-1.png"
          alt="documentation-webhook_img-1"
        />

        <DocElements.ListItem>
          {'Specify the '}
          <strong>request URL</strong>
          {' that you want to send the data to.'}
        </DocElements.ListItem>

        <DocElements.ListItem>
          {'Set the content type. By default, the content type is '}
          <strong>application/json</strong>
          {
            ". If the service you're sending the webhook to requires another content type, enter the appropriate one in the Content-Type header value. Examples of content types are:"
          }
          <DocElements.List>
            <DocElements.ListItem>{'x - www - form - urlencoded'}</DocElements.ListItem>
            <DocElements.ListItem>{'text/plain'}</DocElements.ListItem>
            <DocElements.ListItem>{'text/xml'}</DocElements.ListItem>
          </DocElements.List>
        </DocElements.ListItem>

        <DocElements.ListItem>
          {'Add headers if needed.\n For example, some APIs may require '}
          <strong>HTTP Basic authentication</strong>
          {'. To authenticate such requests, do the following:'}
          <DocElements.List>
            <DocElements.ListItem>
              {
                'Open plain text editor and type without any spaces username and password separated by a colon, e.g.: myuser:mypass'
              }
            </DocElements.ListItem>
            <DocElements.ListItem>{'Encode it into Base64.'}</DocElements.ListItem>
            <DocElements.ListItem>
              {'Copy the resulting string (e.g. bXl1c2VyOm15cGFzcw== )\n'}
            </DocElements.ListItem>
            <DocElements.ListItem>
              {
                'Add the Authorization header in webhook settings, where the value would be "Basic <YOUR BASE64 STRING>". Mind the space after the word "Basic".'
              }
            </DocElements.ListItem>
          </DocElements.List>
          <DocElements.Image
            src="documentation-webhook_img-2.png"
            alt="documentation-webhook_img-2"
          />
        </DocElements.ListItem>

        <DocElements.ListItem>{'Compose the request.'}</DocElements.ListItem>

        <DocElements.ListItem>
          {'To add the '}
          <strong>Dynamic Data</strong>
          {
            " (customer's tag values, attributes of the events the customer triggered, device data, etc.) to your webhook, choose the Dynamic Data parameter, copy the placeholder, and paste it to the request body."
          }
        </DocElements.ListItem>

        <DocElements.Image
          src="documentation-webhook_img-3.png"
          alt="documentation-webhook_img-3"
        />
      </DocElements.NumberedList>
      <DocElements.Paragraph>
        <Link
          target="_blank"
          weight={FontWeight.MEDIUM}
          isExternal
          to="https://docs.pushwoosh.com/platform-docs/send-messages/customer-journey-builder/journey-elements/untitled"
        >
          Webhook integration samples
        </Link>
      </DocElements.Paragraph>
    </DocElements>
  );
}
