import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSettingsMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.704 5.178a1.75 1.75 0 011.72-1.428h1.152c.842 0 1.565.6 1.72 1.428l.095.503a.25.25 0 00.387.16l.422-.289a1.75 1.75 0 012.226.207l.815.815a1.75 1.75 0 01.207 2.226l-.29.422a.25.25 0 00.161.387l.504.095a1.75 1.75 0 011.427 1.72v1.152c0 .842-.6 1.565-1.427 1.72l-.504.095a.25.25 0 00-.16.387l.29.422a1.75 1.75 0 01-.208 2.226l-.815.815a1.75 1.75 0 01-2.225.207l-.423-.29a.25.25 0 00-.387.16l-.095.505a1.75 1.75 0 01-1.72 1.427h-1.152a1.75 1.75 0 01-1.72-1.427l-.095-.504a.25.25 0 00-.387-.16l-.422.289a1.75 1.75 0 01-2.226-.207l-.815-.815a1.75 1.75 0 01-.207-2.225l.29-.423a.25.25 0 00-.16-.387l-.505-.095a1.75 1.75 0 01-1.427-1.72v-1.152c0-.842.6-1.565 1.428-1.72l.503-.095a.25.25 0 00.16-.387L5.552 8.8a1.75 1.75 0 01.207-2.226l.815-.815A1.75 1.75 0 018.8 5.552l.422.29a.25.25 0 00.387-.161l.095-.503zm1.72.072a.25.25 0 00-.246.204l-.094.503A1.75 1.75 0 018.375 7.08l-.422-.289a.25.25 0 00-.318.03l-.815.815a.25.25 0 00-.03.317l.29.423a1.75 1.75 0 01-1.122 2.709l-.504.094a.25.25 0 00-.204.246v1.152c0 .12.086.224.204.246l.504.095a1.75 1.75 0 011.121 2.708l-.289.422a.25.25 0 00.03.318l.815.816a.25.25 0 00.318.029l.422-.29a1.75 1.75 0 012.709 1.123l.094.503a.25.25 0 00.246.204h1.152a.25.25 0 00.246-.204l.095-.504a1.75 1.75 0 012.708-1.121l.423.289a.25.25 0 00.318-.03l.815-.815a.25.25 0 00.03-.317l-.29-.423a1.75 1.75 0 011.122-2.709l.503-.094a.25.25 0 00.204-.246v-1.152a.25.25 0 00-.204-.246l-.503-.095a1.75 1.75 0 01-1.122-2.708l.29-.423a.25.25 0 00-.03-.317l-.816-.815a.25.25 0 00-.317-.03l-.423.29a1.75 1.75 0 01-2.709-1.123l-.094-.503a.25.25 0 00-.246-.204h-1.152zm.576 5a1.75 1.75 0 100 3.5 1.75 1.75 0 000-3.5zM8.75 12a3.25 3.25 0 116.5 0 3.25 3.25 0 01-6.5 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgSettingsMediumLined;
