import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgArrowUpSmall(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.53 7.53a.75.75 0 01-1.06-1.06l3-3a.75.75 0 011.06 0l3 3a.75.75 0 01-1.06 1.06L8.75 5.81V11a.75.75 0 01-1.5 0V5.81L5.53 7.53z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgArrowUpSmall;
