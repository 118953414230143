import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgAudienceBasedEntryPointFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={24} height={24} rx={4} fill="#479900" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75 9a3 3 0 116 0 3 3 0 11-6 0zm-2 7.807c0-.383 0-.574.028-.78.152-1.089 1.083-2.164 2.139-2.47.199-.057.327-.075.584-.113a15.067 15.067 0 014.505-.006c.254.036.382.055.586.114 1.043.306 1.975 1.38 2.128 2.457.03.21.03.404.03.793 0 .184 0 .276-.012.353a1 1 0 01-.833.833c-.077.012-.17.012-.353.012H6.943c-.18 0-.269 0-.344-.012a1 1 0 01-.838-.837c-.011-.075-.011-.165-.011-.344zM15.75 9c0 1.13-.625 2.114-1.548 2.626-.176.098-.153.374.048.374a3 3 0 100-6c-.201 0-.224.276-.048.374A2.998 2.998 0 0115.75 9zm1.943 8.833c-.027.077.025.167.107.167h.252c.184 0 .276 0 .353-.012a1 1 0 00.833-.833c.012-.077.012-.17.012-.353 0-.389 0-.583-.03-.793-.153-1.077-1.085-2.152-2.128-2.457-.204-.06-.332-.078-.586-.114-.144-.02-.286-.04-.427-.056-.048-.006-.068.077-.025.098.664.32 1.329 1.085 1.553 1.787.143.446.143.898.143 1.8 0 .403 0 .605-.057.766z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgAudienceBasedEntryPointFilled;
