import styled from 'styled-components';

import { FontSize, Color, FontWeight, Spacing } from '@pushwoosh/kit-constants';

export const TimeStepperContainer = styled.div`
  display: grid;

  grid-template-columns: repeat(2, 100px);
  grid-template-areas:
    'sectionLabel sectionLabel'
    'sectionHour sectionMinute'
    'sectionHelp sectionHelp';
  column-gap: ${Spacing.S5};

  div:nth-child(1n) {
    grid-area: sectionLabel;
  }
  div:nth-child(2n) {
    grid-area: sectionHour;
  }
  div:nth-child(3n) {
    grid-area: sectionMinute;
  }
  div:nth-child(4n) {
    grid-area: sectionHelp;
  }
`;

export const Label = styled.div`
  margin-bottom: ${Spacing.S1};
  text-transform: capitalize;
  font-size: ${FontSize.REGULAR};
  font-weight: ${FontWeight.MEDIUM};
`;

export const Help = styled.div`
  margin-top: ${Spacing.S1};
  font-size: ${FontSize.SMALL};
  color: ${Color.LOCKED};
`;
