import { HttpClient, Body } from '@pushwoosh/http-client';
import mapValues from 'lodash/mapValues';
import map from 'lodash/map';
import get from 'lodash/get';
import last from 'lodash/last';

function checkOnAppPushwooshCom(): boolean {
  return (
    window.location.hostname === 'app.pushwoosh.com' || window.location.hostname === 'localhost'
  );
}

type RpcResponseSuccess = {
  name: string;
  result: any;
  extra?: any;
};

type RpcResponseError = {
  name: string;
  error: number;
  message: string;
  details: any;
};

type RpcResponse = RpcResponseSuccess | RpcResponseError;

function isResponseError(response: RpcResponse): response is RpcResponseError {
  return 'error' in response;
}

type RpcFetchBody = {
  auth?: {
    logged: boolean;
  };
  responses: Array<RpcResponseSuccess | RpcResponseError>;
};

export class Rpc {
  // eslint-disable-next-line no-useless-constructor
  constructor(private httpClient: HttpClient) {}

  callRemoteProcedure(
    name: string,
    params: any,
    logInfo: string,
    filesNames?: string[]
  ): Promise<{ auth: RpcFetchBody['auth']; response: RpcResponseSuccess }> {
    let body: Body;
    let url;

    if (filesNames) {
      body = new FormData();
      const newParams = JSON.stringify(
        mapValues(params, (value, key) => !filesNames.includes(key) && value)
      );
      const files = map(filesNames, (fileName) => {
        const file = get(params, fileName);
        return {
          file,
          fileName: last(fileName.split('.')) as string
        };
      });

      body.append('name', name);
      body.append('params', newParams);

      // eslint-disable-next-line no-restricted-syntax
      for (const { file, fileName } of files) {
        body.append(fileName, file);
      }

      url = `_upload_${name}`;
    } else {
      body = { calls: [{ name, params }] };
      url = '_rpc';
    }

    // eslint-disable-next-line no-underscore-dangle
    return this._fetch(url, body, `${name}|||${logInfo}`).then(({ auth, responses }) => {
      const response = responses[0];
      if (!response) {
        throw new Error('no result');
      }
      if (isResponseError(response)) {
        throw new Error(response.message);
      }

      return { auth, response };
    });
  }

  // eslint-disable-next-line no-underscore-dangle
  _fetch(url: string, body: Body, logStr: string) {
    const isAppPushwooshCom = checkOnAppPushwooshCom();

    return this.httpClient
      .post<RpcFetchBody, never, never, any>(`/cp/${url}?l=${encodeURI(logStr)}`, {
        body,
        withAuthorization: isAppPushwooshCom
      })
      .then((body) => body);
  }
}

export function createRPC(httpClient: HttpClient): Rpc {
  return new Rpc(httpClient);
}
