// Store
import { useStoreState } from '@src/store/store';

// Types
import { IPointOutputStatistics } from '@src/common/types/statistics';

export function usePointOutputsStats(): IPointOutputStatistics[] {
  const pointOutputsStats = useStoreState(
    (state): IPointOutputStatistics[] => state.statistics.pointOutputsStats
  );

  return pointOutputsStats;
}
