import { useCallback } from 'react';

// Store
import { useStoreActions } from '@src/store/store';

type THandleDecrementZoom = () => void;

export function useDecrementZoom(): THandleDecrementZoom {
  const decrementZoom = useStoreActions(
    (actions): typeof actions.canvas.decrementZoom => actions.canvas.decrementZoom
  );

  return useCallback<THandleDecrementZoom>((): void => {
    decrementZoom();
  }, [decrementZoom]);
}
