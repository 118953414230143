// Store
import { useStoreState } from '@src/store/store';

// Types
import { TTooltip } from '@src/common/types/tooltip';

export function useStoreTooltips(): TTooltip[] {
  const tooltips = useStoreState((actions): typeof actions.tooltip.items => actions.tooltip.items);

  return tooltips;
}
