import { DropOffsType } from '@src/common/constants/stats';

export function getDropOffLabel(dropOffType: DropOffsType): string {
  switch (dropOffType) {
    case DropOffsType.AccountSuspended: {
      return 'Account suspended';
    }

    case DropOffsType.DeviceNotFound: {
      return 'Device not found';
    }

    case DropOffsType.DynamicTimePassed: {
      return "Can't get time";
    }

    case DropOffsType.EmailLimitReached: {
      return 'Emails reached limits';
    }

    case DropOffsType.FilterNotFound: {
      return 'Filter not found';
    }

    case DropOffsType.RequestError: {
      return 'Request error';
    }

    case DropOffsType.RichMediaNotFound: {
      return 'Rich media not found';
    }

    case DropOffsType.UserMismatch: {
      return 'User mismatch';
    }

    case DropOffsType.UserNotFound: {
      return 'User not found';
    }

    case DropOffsType.PresetNotFound: {
      return 'Invalid push preset';
    }

    case DropOffsType.TagNotFound: {
      return 'Tag not fount';
    }

    case DropOffsType.Error: {
      return 'Internal error';
    }

    default: {
      return '';
    }
  }
}
