import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgTagMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.25 8.5a.75.75 0 000 1.5h.007a.75.75 0 000-1.5H9.25z" fill={props.fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 4.75a.75.75 0 00-.75.75V13c0 .2.08.39.22.53l6.442 6.436a2.249 2.249 0 003.184-.001l5.377-5.377-.53-.53.531.528a2.25 2.25 0 000-3.172L13.53 4.97a.75.75 0 00-.53-.22H5.5zM19.129 13a.75.75 0 01-.218.528l-5.377 5.377a.752.752 0 01-1.062 0l-2.868-2.866-3.354-3.35V6.25h6.44l6.22 6.221.001.001c.14.14.218.33.218.528z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgTagMediumLined;
