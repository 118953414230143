import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';

export const Container = styled.div`
  margin-top: ${Spacing.S5};
`;

export const DateOption = styled.div`
  display: grid;
  grid-template-columns: 130px auto;
  grid-column-gap: ${Spacing.S5};
  margin-bottom: ${Spacing.S5};
`;

export const EventDescription = styled.div`
  margin-top: ${Spacing.S5};
  margin-bottom: ${Spacing.S5};
`;

export const DelayOption = styled.div`
  display: grid;
  grid-template-columns: 130px auto;
  grid-column-gap: ${Spacing.S5};
  align-items: end;
  margin-top: ${Spacing.S5};
`;

export const DateTimeSettings = styled.div`
  display: grid;
  grid-template-columns: 80px 100px;
  grid-column-gap: ${Spacing.S3};
`;

export const EventSettings = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: ${Spacing.S3};
`;
