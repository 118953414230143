import React from 'react';
import { TagProps } from './Tag.types';
import { TagContainer, Value } from './styled';

export function InitialTag(props: Pick<TagProps, 'value'>): JSX.Element {
  const { value } = props;

  return (
    <TagContainer>
      <Value>{value}</Value>
    </TagContainer>
  );
}
