import * as Sentry from '@sentry/browser';

const { isProduction } = process.env;

const production = isProduction && window.location.hostname === 'app.pushwoosh.com';

export const initLogger = (): void => {
  if (production) {
    Sentry.init({
      dsn: 'https://77514d75a0ae4af493ca2a35dbf81140@sentry-io.pushwoosh.com/78',
      environment: isProduction ? 'production' : 'development'
    });
  }
};
