import { createStore, createTypedHooks } from 'easy-peasy';
import { HttpClient } from '@pushwoosh/http-client';
import { createLogger } from 'redux-logger';

// Models
import { rootModel } from '@src/store/models/root/root.model';

// Injections
import { injections } from '@src/store/injections';

// Types
import { TRootModel } from '@src/store/models/root/root.types';
import { TInjections } from '@src/store/types';
import { Rpc } from './api/configured/rpc';

const { isProduction } = process.env;

const logger = createLogger({
  colors: {
    title: ({ type }) => {
      if (type.includes('success')) {
        return '#3b8534';
      }
      if (type.includes('fail')) {
        return 'Crimson ';
      }

      return 'inherit';
    },
    prevState: () => '#9E9E9E',
    action: () => '#03A9F4',
    nextState: () => '#3b8534',
    error: () => '#F20404'
  }
});

export const store = createStore<TRootModel, { injections: TInjections }>(rootModel, {
  injections,
  middleware: isProduction ? [] : [logger]
});

type Injections = {
  api: HttpClient;
  rpc: Rpc;
};

export const createStoreWithInjections = ({ api, rpc }: Injections) =>
  createStore<TRootModel, { injections: TInjections }>(rootModel, {
    injections: {
      ...injections,
      api,
      rpc
    }
  });

export const { useStoreActions, useStoreState } = createTypedHooks<TRootModel>();
