import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgFilterMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 8.25h7.354a2.751 2.751 0 015.293 0H19a.75.75 0 010 1.5h-1.354a2.751 2.751 0 01-5.293 0H5a.75.75 0 010-1.5zm10-.5a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zM6.354 14.25H5a.75.75 0 000 1.5h1.354a2.751 2.751 0 005.292 0H19a.75.75 0 000-1.5h-7.354a2.751 2.751 0 00-5.292 0zm3.896.75a1.25 1.25 0 10-2.5 0 1.25 1.25 0 002.5 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgFilterMediumLined;
