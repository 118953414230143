import styled from 'styled-components';
import { H5 } from '@pushwoosh/kit-typography';
import { Spacing, Color } from '@pushwoosh/kit-constants';

type Props = {
  type: 'default' | 'error';
};

export const Container = styled.div<Props>`
  width: 100%;
  background-color: ${({ type }) => (type === 'error' ? Color.DANGER_LIGHT : Color.SOFT_LIGHT)};
  padding: ${Spacing.S5};
`;

export const Label = styled(H5)<Props>`
  color: ${({ type }) => (type === 'error' ? Color.DANGER : Color.MAIN)};
  margin-bottom: ${Spacing.S1};
  text-transform: uppercase;
`;

export const StatContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto auto;
  grid-column-gap: ${Spacing.S1};
`;
