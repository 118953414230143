import { useStoreActions, useStoreState } from '@src/store/store';
import { TRequest } from '@src/store/models/requests/requests.types';

type TActionLoad = (payload: { journeyIds: string }) => Promise<any>;
type TResult = [TActionLoad, boolean];

export function useLoadJourneyListStats(): TResult {
  const loadJourneyListStats: TActionLoad = useStoreActions(
    (actions): TActionLoad => actions.statistics.loadJourneyListStats
  );

  const request: TRequest = useStoreState(
    (state): TRequest => state.requests.entities['statistics.loadJourneyListStats']
  );

  return [loadJourneyListStats, Boolean(request)];
}
