// Store
import { useStoreState } from '@src/store/store';

// Types
import { IPointOutput } from '@src/common/types/points';

export function usePointOutputs(): IPointOutput[] {
  const pointOutputs = useStoreState<IPointOutput[]>(
    (state): IPointOutput[] => state.points.pointOutputs
  );

  return pointOutputs;
}
