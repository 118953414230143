import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgGoal(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 8.754C11 7.785 11.785 7 12.754 7h14.492C28.215 7 29 7.785 29 8.754V9.5a.5.5 0 00.5.5H31a2 2 0 012 2v3a5.002 5.002 0 01-4.069 4.913.553.553 0 00-.422.36 9.012 9.012 0 01-7.065 5.945.52.52 0 00-.444.508v.774a.5.5 0 00.5.5H25a2 2 0 012 2v1a2 2 0 01-2 2H15a2 2 0 01-2-2v-1a2 2 0 012-2h3.5a.5.5 0 00.5-.5v-.774a.521.521 0 00-.444-.508c-3.3-.532-5.999-2.858-7.065-5.945a.553.553 0 00-.422-.36A5.002 5.002 0 017 15v-3a2 2 0 012-2h1.5a.5.5 0 00.5-.5v-.746zm16 8.58V9.5a.5.5 0 00-.5-.5h-13a.5.5 0 00-.5.5v7.833a7 7 0 1014 0zM29.5 12a.5.5 0 00-.5.5v4.833c0 .242.24.41.452.293A3 3 0 0031 15v-2.5a.5.5 0 00-.5-.5h-1zM9 12.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v4.833c0 .242-.24.41-.452.293A3 3 0 019 15v-2.5zM15.5 30a.5.5 0 000 1h9a.5.5 0 000-1h-9zm3.599-18.094c.284-.875 1.523-.875 1.807 0l.513 1.578a.5.5 0 00.475.346h1.66c.92 0 1.302 1.177.558 1.718l-1.342.975a.5.5 0 00-.182.56l.512 1.577c.285.875-.717 1.603-1.461 1.062l-1.343-.975a.5.5 0 00-.587 0l-1.343.975c-.744.541-1.746-.187-1.462-1.062l.513-1.578a.5.5 0 00-.182-.559l-1.342-.975c-.744-.54-.362-1.718.559-1.718h1.659a.5.5 0 00.475-.346l.513-1.578z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgGoal;
