import { Color, FontSize, FontWeight, ShapeRadius, Spacing } from '@pushwoosh/kit-constants';
import styled from 'styled-components';
import { Icon } from '@src/ui/kit';

export const Container = styled.div<{ hasError?: boolean }>`
  background-color: ${Color.SOFT_LIGHT};
  border-radius: ${ShapeRadius.CONTROL};
  //border: 1px solid ${({ hasError }) =>
    hasError ? Color.DANGER : Color.SOFT_LIGHT}; // just for development
`;

export const Content = styled.div`
  padding: ${Spacing.S5};
`;

export const HalfContent = styled.div`
  padding: 0 ${Spacing.S5} ${Spacing.S5} ${Spacing.S5};
`;

export const HeaderTitle = styled.div`
  display: inline-grid;
  align-items: center;
  font-weight: ${FontWeight.BOLD};
  text-transform: uppercase;
  font-size: ${FontSize.SMALL};
  white-space: nowrap;
  grid-template-columns: min-content min-content auto;
  grid-gap: ${Spacing.S2};
  min-height: 32px;
`;

export const HeaderBranchKey = styled.div`
  cursor: pointer;

  &:hover {
    color: ${Color.PRIMARY_HOVER};
  }
`;

export const HeaderBranchName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StatusIcon = styled(Icon)<{ open?: boolean }>`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.2s ease-in-out;
`;

export const HeaderActions = styled.div``;

export const Header = styled.div<{ kind?: 'primary' | 'secondary' }>`
  display: grid;
  grid-template-columns: auto min-content;

  align-items: center;
  justify-content: space-between;
  background-color: ${({ kind }) => (kind === 'primary' ? Color.SOFT : Color.SOFT_LIGHT)};
  border-radius: ${ShapeRadius.CONTROL} ${ShapeRadius.CONTROL} 0 0;
  padding: ${Spacing.S0} ${Spacing.S3} ${Spacing.S0} ${Spacing.S5};
  user-select: none;

  ${HeaderTitle} {
    color: ${({ kind }) => (kind === 'primary' ? Color.CLEAR : Color.MAIN)};

    svg > path {
      fill: ${({ kind }) => (kind === 'primary' ? Color.CLEAR : Color.MAIN)};
    }
  }

  ${HeaderActions} {
    svg > path {
      fill: ${({ kind }) => (kind === 'primary' ? Color.CLEAR : Color.MAIN)};
    }
  }
`;

export const CompactEventsView = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: ${Spacing.S1};
`;

export const CompactEventBadge = styled.div<{ hasError?: boolean }>`
  color: ${({ hasError }) => (hasError ? Color.DANGER : Color.MAIN)};
  background-color: ${Color.CLEAR};
  padding: ${Spacing.S1} ${Spacing.S2};
  border-radius: ${ShapeRadius.CONTROL};
`;

export const CompactOperator = styled.div`
  color: ${Color.LOCKED};
  padding: ${Spacing.S1} ${Spacing.S2};
  font-weight: ${FontWeight.MEDIUM};
  font-size: ${FontSize.SMALL};
  text-transform: uppercase;
`;
