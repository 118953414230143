import * as React from 'react';

// Store Hooks
import { useApplications, useLoadApplications } from '@src/store/hooks';

// Types
import { TApplication } from '@src/common/types/application';

export function useApplicationsWithAutoLoad(): [TApplication[], boolean] {
  const applications = useApplications();
  const [loadApplications, isLoadingApplications] = useLoadApplications();

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (!isLoadingApplications) {
      loadApplications();
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return [applications, isLoadingApplications];
}
