// Types
import { IWebhookFormValues } from '@src/ui/features/Webhook/Webhook.types';

// Constants
import { TypeData } from '@src/ui/features/Webhook/components/DynamicValues';

export const getInsertablePlaceholder = (values: IWebhookFormValues['dynamicValues']): string => {
  if (values.type === TypeData.DEVICE) {
    if (values.deviceValue && values.deviceValue !== '') {
      return `{{device:${values.deviceValue}}}`;
    }
  }
  if (values.type === TypeData.TAG) {
    if (values.tagValue && values.tagValue !== '') {
      return `{{tag:${values.tagValue}}}`;
    }
  }

  if (values.type === TypeData.EVENT) {
    if (
      values.eventValue &&
      values.eventValue !== '' &&
      values.attributeValue &&
      values.attributeValue !== ''
    ) {
      return `{{point:${values.pointId};event:${values.eventValue};attribute:${values.attributeValue}}}`;
    }
  }

  return null;
};
