import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgArrowRoundDownSmallFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 8A7 7 0 101 8a7 7 0 0014 0zm-10.53.47a.75.75 0 011.06 0l1.72 1.72V4a.75.75 0 011.5 0v6.19l1.72-1.72a.75.75 0 111.06 1.06l-3 3a.75.75 0 01-1.06 0l-3-3a.75.75 0 010-1.06z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgArrowRoundDownSmallFilled;
