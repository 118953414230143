import styled from 'styled-components';
import { Spacing, Color, ShapeRadius } from '@pushwoosh/kit-constants';
import { Paragraph } from '@pushwoosh/kit-typography';

type ContainerProps = {
  bgColor?: Color;
};

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing.S3} ${Spacing.S5};
  border: 1px solid ${Color.DIVIDER};
  border-radius: ${ShapeRadius.CONTROL};
  box-sizing: border-box;
  cursor: pointer;
  ${({ bgColor }: ContainerProps) => bgColor && `background-color: ${bgColor};`}

  &:not(:only-child) {
    border: none;
    border-bottom: 1px solid ${Color.DIVIDER};
    border-radius: 0;
  }
`;

type ValueProps = {
  color?: Color;
};

export const Value = styled(Paragraph)`
  margin: 0 !important;
  ${({ color }: ValueProps) => color && `color: ${color};`}
`;
