import Konva from 'konva';
import { ActionCreator } from 'easy-peasy';

// Types
import { TArrow } from '@src/common/types/arrow';
import { IUniqueId } from '@src/common/types/entities';
import { UpdateArrow } from '@src/store/models/arrow/arrow.types';

export function calcArrowPosition(
  updateArrow: ActionCreator<UpdateArrow>
): (pointNode: Konva.Group) => void {
  return (pointNode): void => {
    const pointOutputs = pointNode.find('.point-output');
    const arrowNodes = pointNode.getStage().find('.arrow');
    const pointAttrs = pointNode.getAttrs();
    const parentGroup = pointNode.getParent();

    const arrowsToBeUpdated: Array<Partial<TArrow> & IUniqueId> = [];

    arrowNodes.forEach((arrowNode) => {
      const arrowAttrs = arrowNode.getAttrs();

      if (arrowAttrs.fromPointId === pointAttrs.pointId) {
        pointOutputs.forEach((output) => {
          const outputAttrs = output.getAttrs();
          const isEqualFromPoint = arrowAttrs.fromPointId === outputAttrs.fromPointId;
          const isEqualOutputKey = arrowAttrs.outputKey === outputAttrs.outputKey;

          if (isEqualFromPoint && isEqualOutputKey) {
            const outputRect = output.getClientRect({ relativeTo: pointNode });

            const fromX = pointNode.x() + parentGroup.x() + outputRect.x + outputRect.width / 2;
            const fromY = pointNode.y() + parentGroup.y() + outputRect.y + outputRect.height / 2;

            arrowsToBeUpdated.push({
              id: arrowAttrs.id,
              fromX,
              fromY
            });
          }
        });
      }

      if (arrowAttrs.toPointId === pointAttrs.pointId) {
        const toX = pointNode.x() + parentGroup.x();
        const toY = pointNode.y() + parentGroup.y() + pointNode.height() / 2;

        arrowsToBeUpdated.push({
          id: arrowAttrs.id,
          toX,
          toY
        });
      }
    });

    updateArrow(arrowsToBeUpdated);
  };
}
