import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgUserMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8a4 4 0 11-8 0 4 4 0 018 0zm-1.5 0a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM12 13.25c-1.245 0-2.855.307-4.178.93-1.277.603-2.572 1.649-2.572 3.249V19c0 .414.336.75.75.75h12a.75.75 0 00.75-.75v-1.571c0-1.6-1.295-2.646-2.572-3.248-1.323-.623-2.933-.931-4.178-.931zm-5.25 4.179c0-.68.56-1.349 1.712-1.891 1.105-.521 2.494-.788 3.538-.788 1.044 0 2.433.267 3.539.788 1.15.542 1.711 1.21 1.711 1.89v.822H6.75v-.821z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgUserMediumLined;
