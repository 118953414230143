import * as React from 'react';

// Analytics
import { sendMixpanelEvent } from '@src/common/analytics';
import { SidebarType } from '@src/common/constants/sidebars';

// Store Hooks
import {
  useCurrentJourneyId,
  useFilter,
  useGetJourneyById,
  useGetPointById,
  useLoadFilter,
  useOpenNewModal,
  useUpdatePoint,
  useEditJourneyById,
  useJourneyAppCode
} from '@src/store/hooks';
import { useStoreActions, useStoreState } from '@src/store/store';

// UI Hooks
import { useForm } from '@src/ui/hooks';

// Helpers
import { makeFilterOptions } from '@src/ui/helpers';

// UI Components
import { Section, Button, IconEnum } from '@src/ui/kit';
import { Paragraph } from '@pushwoosh/kit-typography';
import { FormInput } from '@src/ui/form';
import { FormBlock } from '@src/ui/form/styled';
import {
  FormEditingPointBooleanSplitter as FormEditingPointBooleanSplitterComponent,
  IFormEditingPointBooleanSplitterValues
} from '@src/ui/organisms/Form/EditingPointBooleanSplitter';
import { Modal } from '@src/ui/kit/Modal';
import { DefaultButtons } from '@src/ui/kit/ModalButtons';

// Types
import { TBooleanSplitterPoint } from '@src/common/types/points';
import { IOption } from '@src/common/types/select';
import { IUniqueId } from '@src/common/types/entities';
import { JourneyStatus, ModalType } from '@src/common/constants';

interface IFormEditingPointWaitProps {
  pointId: IUniqueId['id'];
  closeModal: () => void;
}

function FormEditingBooleanSplitter(props: IFormEditingPointWaitProps): JSX.Element {
  const { pointId, closeModal } = props;

  const [onLoadFilter, isLoadingFilter] = useLoadFilter();
  const filters = useFilter();
  const getPointById = useGetPointById();
  const updatePoint = useUpdatePoint();
  const showDocumentationMenu = useStoreActions((actions) => actions.documentationMenu.show);
  const openNewSidebar = useStoreActions((actions) => actions.sidebars.openNewSidebar);
  const closeAllSidebars = useStoreActions((actions) => actions.sidebars.closeAllSidebars);
  const editPoint = useStoreActions((actions) => actions.points.update);
  const handleEditJourneyById = useEditJourneyById();
  const sidebars = useStoreState((state) => state.sidebars);

  const point = getPointById(pointId) as TBooleanSplitterPoint;

  const journeyId = useCurrentJourneyId();
  const getJourneyById = useGetJourneyById();
  const applicationCode = useJourneyAppCode(journeyId);

  const { status } = getJourneyById(journeyId);

  const openNewModal = useOpenNewModal();

  const filtersOptions = makeFilterOptions(filters);
  const onShowDocumentation = React.useCallback((): void => {
    if (sidebars.isOpened) {
      closeAllSidebars();
    } else {
      openNewSidebar({
        type: SidebarType.DOCUMENTATION
      });
      showDocumentationMenu();
      sendMixpanelEvent({ eventName: 'ShowDocumentation' });
    }
  }, [showDocumentationMenu, openNewSidebar, closeAllSidebars, sidebars]);

  const { values, setFieldValue, setFieldTouched, handleSubmit, handleChange, handleBlur } =
    useForm<IFormEditingPointBooleanSplitterValues>({
      initialValues: {
        title: point.title,
        code: point.data.filterCode
      },
      onSubmit(formValues): void {
        const currentOption = filtersOptions.filter(
          (item: IOption<string>) => item.value === formValues.code
        );
        const pointData: TBooleanSplitterPoint['data'] = {
          filterCode: formValues.code,
          name: currentOption.length ? currentOption[0].label : ''
        };

        if (status !== JourneyStatus.RUNNING) {
          updatePoint({
            id: point.id,
            title: formValues.title,
            data: pointData
          });

          closeModal();
        } else {
          editPoint({
            id: point.id,
            title: formValues.title,
            data: pointData
          });

          handleEditJourneyById(journeyId);

          sendMixpanelEvent({
            eventName: 'JourneyEditModeChangesApplied',
            eventData: {
              PointType: 'BooleanSplitter'
            }
          });

          closeModal();

          openNewModal({
            type: ModalType.DETAIL_POINT,
            data: {
              pointId
            }
          });
        }
      }
    });

  const handleCancel = React.useCallback(() => {
    const { title, code } = values;
    const eventData = {
      PointType: 'BooleanSplitter',
      PointChanged: point.title !== title || point.data.filterCode !== code
    };

    if (status === JourneyStatus.RUNNING) {
      sendMixpanelEvent({
        eventName: 'JourneyEditModeChangesCancelled',
        eventData: {
          PointType: 'BooleanSplitter'
        }
      });
    } else {
      sendMixpanelEvent({
        eventName: 'ConfigurePointCancelled',
        eventData
      });
    }

    closeModal();
  }, [closeModal, point, values, status]);

  return (
    <Modal
      title="Segment Split"
      footerRight={
        <DefaultButtons
          onClickActionButton={handleSubmit}
          onClickCancelButton={handleCancel}
          actionButtonName={status === JourneyStatus.RUNNING ? 'Save' : 'Apply'}
        />
      }
      footerLeft={
        <Button
          color="primary"
          size="field"
          view="ghost"
          iconPosition="left"
          iconType={IconEnum.DOCS_MEDIUM_LINED}
          onClick={onShowDocumentation}
        >
          How to Use
        </Button>
      }
      isOpen
    >
      <Section direction="column">
        <Paragraph>
          Customer inside the journey will move forward depending on whether they match the filter
          from Pushwoosh Control Panel.
        </Paragraph>
        <FormBlock>
          <FormInput
            label="Point Name"
            name="title"
            value={values.title}
            onChange={setFieldValue}
          />
        </FormBlock>
      </Section>
      <FormEditingPointBooleanSplitterComponent
        applicationCode={applicationCode}
        values={values}
        names={{
          code: 'code',
          title: 'title'
        }}
        filters={filtersOptions}
        isLoadingFilter={isLoadingFilter}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        onBlurField={handleBlur}
        onChangeField={handleChange}
        onLoadFilter={onLoadFilter}
      />
    </Modal>
  );
}

export default React.memo(FormEditingBooleanSplitter);
