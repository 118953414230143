import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgFolderMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 17a2 2 0 01-2 2H5a2 2 0 01-2-2V6a2 2 0 012-2h6l2 2h6a2 2 0 012 2v9z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgFolderMediumFilled;
