import {
  PlusIcon,
  TrashIcon,
  RefreshIcon,
  PlusIconProps,
  TrashIconProps,
  RefreshIconProps
} from '@pushwoosh/kit-icons';
import { ChangeTypes } from '@src/common/constants/changeLog';

type Result<T> = (props: T) => JSX.Element;

export function getIcon(
  changeType: ChangeTypes
): Result<PlusIconProps | TrashIconProps | RefreshIconProps> {
  switch (changeType) {
    case ChangeTypes.ADD: {
      return PlusIcon as Result<PlusIconProps>;
    }

    case ChangeTypes.REMOVE: {
      return TrashIcon as Result<TrashIconProps>;
    }

    case ChangeTypes.UPDATE: {
      return RefreshIcon as Result<RefreshIconProps>;
    }

    default: {
      return null;
    }
  }
}
