import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgChevronDownMedium(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.47 10.47a.75.75 0 011.06 0L12 13.94l3.47-3.47a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 010-1.06z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgChevronDownMedium;
