import * as React from 'react';

// Components
import { Button } from '@src/ui/kit/Button';

// Styled
import { Container } from './styled';

// Types
import { Props } from './DangerButtons.types';

export function DangerButtons(props: Props): JSX.Element {
  const {
    cancelButtonName = 'Cancel',
    actionButtonName = 'Apply',
    isDisabledActionButton = false,
    onClickCancelButton,
    onClickActionButton
  } = props;

  return (
    <Container>
      {typeof onClickCancelButton === 'function' && (
        <Button color="secondary" onClick={onClickCancelButton}>
          {cancelButtonName}
        </Button>
      )}
      {typeof onClickActionButton === 'function' && (
        <Button
          color="danger"
          onClick={onClickActionButton}
          disabled={isDisabledActionButton}
          type="submit"
        >
          {actionButtonName}
        </Button>
      )}
    </Container>
  );
}
