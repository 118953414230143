// Store
import { useStoreState } from '@src/store/store';

// Types
import { TEvent } from '@src/common/types/event';

type TResult = (applicationCode: string) => TEvent[];

export function useGetEventsByApplicationCode(): TResult {
  const getEventsByApplicationCode = useStoreState((state): TResult => state.event.eventsByAppCode);

  return getEventsByApplicationCode;
}
