// Store
import { useStoreState } from '@src/store/store';

// Enums
import { SubscriptionPlan } from '@src/common/constants/subscriptionPlan';

export function useSubscripttionPlan(): SubscriptionPlan {
  const subscriptionPlan = useStoreState((state) => state.user.subscriptionPlan);

  return subscriptionPlan;
}
