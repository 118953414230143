declare global {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  interface Window {
    Pushwoosh: any;
  }
}

type TNameEvent =
  | 'CustomerJourneyActivate'
  | 'CustomerJourneyCreate'
  | 'CustomerJourneyClone'
  | 'CustomerJourneyDeactivate';

type TAttrs = {
  userEmail: string;
  isFirst?: boolean;
};

export const sendPostEvent = (nameEvent: TNameEvent, attrs: TAttrs): void => {
  window.Pushwoosh = window.Pushwoosh || [];

  window.Pushwoosh.push((api: any): void => {
    api.apiClient.postEvent({
      application: '6D382-5ADFE',
      event: nameEvent,
      attributes: attrs
    });
  });
};

type TEmailSubscriptionNameEvent = 'OnboardingProductUsage';

type TEmailSubscriptionAttrs = {
  ActionName: string;
};

export const sendToEmailSubscription = (
  event: TEmailSubscriptionNameEvent,
  attributes: TEmailSubscriptionAttrs,
  username: string
): void => {
  window.Pushwoosh = window.Pushwoosh || [];

  window.Pushwoosh.push((api: any) => {
    api.apiClient.postEvent({
      application: '6D382-5ADFE',
      hwid: username,
      userId: username,
      device_type: 14,
      event,
      attributes
    });
  });
};
