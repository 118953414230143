import * as React from 'react';

import { Color } from '@pushwoosh/kit-constants';

// Components
import { Icon, IconEnum } from '@src/ui/kit/Icon';

// Core Helpers
import { getPointIconColorByType } from '@src/ui/helpers';

// Core Constants
import { PointType } from '@src/common/constants/point';

// Types
import { TDocumentationMenuRoutePath } from '@src/store/models/documentationMenu/documentationMenu.types';
import { TDocumentationButtonCardsLink } from '@src/ui/features/Documentation/components/DocumentationButtonCards/DocumentationButtonCards.types';

export const documentationMenuLinks: TDocumentationButtonCardsLink<TDocumentationMenuRoutePath>[] =
  [
    {
      label: 'Canvas & Flow',
      path: '/canvas',
      backgroundColor: Color.SOFT_LIGHT,
      icon: <Icon type={IconEnum.CANVAS} fill={Color.SOFT} height={16} width={16} />
    },
    {
      label: 'Goals',
      path: '/goals',
      backgroundColor: Color.SOFT_LIGHT,
      icon: <Icon height={24} width={24} type={IconEnum.GOAL_EVENT} fill={Color.SOFT} />
    },
    {
      label: 'Trigger-based Entry',
      path: '/point-event',
      icon: (
        <Icon
          height={24}
          width={24}
          type={IconEnum.EVENT}
          fill={getPointIconColorByType(PointType.EVENT)}
          rectFill={Color.SUCCESS_LIGHT}
        />
      )
    },
    {
      label: 'Audience-based Entry',
      path: '/point-start-by-segment',
      icon: (
        <Icon
          height={24}
          width={24}
          type={IconEnum.SEGMENT}
          fill={getPointIconColorByType(PointType.SEGMENT)}
          rectFill={Color.SUCCESS_LIGHT}
        />
      )
    },
    {
      label: 'Push',
      path: '/point-send-push',
      icon: (
        <Icon
          height={24}
          width={24}
          type={IconEnum.SEND_PUSH}
          fill={getPointIconColorByType(PointType.SEND_PUSH)}
          rectFill={Color.EXCEPTIONAL_LIGHT}
        />
      )
    },
    {
      label: 'Email',
      path: '/point-send-email',
      icon: (
        <Icon
          height={24}
          width={24}
          type={IconEnum.SEND_EMAIL}
          fill={getPointIconColorByType(PointType.SEND_EMAIL)}
          rectFill={Color.EXCEPTIONAL_LIGHT}
        />
      )
    },
    {
      label: 'In-App',
      path: '/point-inapp',
      icon: (
        <Icon
          height={24}
          width={24}
          type={IconEnum.SEND_IN_APP}
          fill={getPointIconColorByType(PointType.SEND_IN_APP)}
          rectFill={Color.EXCEPTIONAL_LIGHT}
        />
      )
    },
    {
      label: 'Webhook',
      path: '/point-webhook',
      icon: (
        <Icon
          height={24}
          width={24}
          type={IconEnum.WEBHOOK}
          fill={getPointIconColorByType(PointType.WEBHOOK)}
          rectFill={Color.EXCEPTIONAL_LIGHT}
        />
      )
    },
    {
      label: 'Update User Profile',
      path: '/point-set-tags',
      icon: (
        <Icon
          height={24}
          width={24}
          type={IconEnum.SET_TAGS}
          fill={getPointIconColorByType(PointType.SET_TAGS)}
          rectFill={Color.BRIGHT_LIGHT}
        />
      )
    },
    {
      label: 'Exit',
      path: '/point-exit',
      icon: (
        <Icon
          height={24}
          width={24}
          type={IconEnum.EXIT}
          fill={getPointIconColorByType(PointType.EXIT)}
          rectFill={Color.BRIGHT_LIGHT}
        />
      )
    },
    {
      label: 'Time Delay',
      path: '/point-wait',
      icon: (
        <Icon
          height={24}
          width={24}
          type={IconEnum.WAIT}
          fill={getPointIconColorByType(PointType.WAIT)}
          rectFill={Color.BRIGHT_LIGHT}
        />
      )
    },
    {
      label: 'Segment Split',
      path: '/point-boolean-splitter',
      icon: (
        <Icon
          height={24}
          width={24}
          type={IconEnum.BOOLEAN_SPLITTER}
          fill={getPointIconColorByType(PointType.BOOLEAN_SPLITTER)}
          rectFill={Color.BRIGHT_LIGHT}
        />
      )
    },
    {
      label: 'Reachability Check',
      path: '/point-filter',
      icon: (
        <Icon
          height={24}
          width={24}
          type={IconEnum.REACHABILITY_CHECK}
          fill={getPointIconColorByType(PointType.REACHABILITY_CHECK)}
          rectFill={Color.BRIGHT_LIGHT}
        />
      )
    },
    {
      label: 'Wait For Trigger',
      path: '/point-wait-event',
      icon: (
        <Icon
          height={24}
          width={24}
          type={IconEnum.WAIT_EVENT}
          fill={getPointIconColorByType(PointType.WAIT_EVENT)}
          rectFill={Color.BRIGHT_LIGHT}
        />
      )
    },
    {
      label: 'A/B/n Split',
      path: '/point-ab-splitter',
      icon: (
        <Icon
          height={24}
          width={24}
          type={IconEnum.AB_SPLITTER}
          fill={getPointIconColorByType(PointType.AB_SPLITTER)}
          rectFill={Color.BRIGHT_LIGHT}
        />
      )
    }
  ];
