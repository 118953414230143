// Store
import { useStoreState } from '@src/store/store';

// Types
import { TRequest } from '@src/store/models/requests/requests.types';

type TResult = [boolean];

export function useStatusRequestLoadedById(): TResult {
  const request: TRequest = useStoreState(
    (state): TRequest => state.requests.entities['journeys.loadById']
  );

  return [Boolean(request)];
}
