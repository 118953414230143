import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgMinusRoundMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8 11.25a.75.75 0 000 1.5h8a.75.75 0 000-1.5H8z" fill={props.fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm8.5-10a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgMinusRoundMediumLined;
