import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgJourneyMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.646 5.339a.75.75 0 01.77.037l4 2.667a.75.75 0 010 1.248l-4 2.666a.75.75 0 01-1.166-.624V9.417H8.646a2.751 2.751 0 110-1.5h6.604V6a.75.75 0 01.396-.661zM7.25 8.667a1.25 1.25 0 10-2.5 0 1.25 1.25 0 002.5 0zm11.398 0L16.75 7.4v2.531l1.898-1.265z"
        fill={props.fill}
      />
      <path
        d="M10.655 13.031l.052.18c.31 1.063.677 2.27 1.34 3.203.69.973 1.703 1.645 3.203 1.669v1.25a.75.75 0 001.166.624l3-2a.75.75 0 000-1.248l-3-2a.75.75 0 00-1.166.624v1.25c-.964-.022-1.543-.422-1.981-1.038-.493-.693-.8-1.652-1.122-2.755l-.07-.24c-.146-.504-.301-1.036-.487-1.55H9.978c.252.576.453 1.264.677 2.031z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgJourneyMediumLined;
