import React from 'react';
import { useParams } from 'react-router-dom';

// Store Hooks

// UI Components
import {
  Modal,
  ExtraOneButton,
  Link,
  Button,
  IconEnum,
  Section,
  StatisticGrid,
  InitialTag,
  Icon
} from '@src/ui/kit';
import { PointTitle } from '@src/ui/kit/DetailsPoint/styled';
import { ModalButtonsWrap } from '@src/ui/form/styled';
import { StatItem, StatWrap } from '@src/ui/features/PointStat';
import { DropOffStat } from '@src/ui/features/DropOffStat';

// Helpers
import { decodeDelay, formatNumber } from '@src/common/helpers';

// Types
import { TPointSendPushStatistics } from '@src/common/types/statistics';
import { TSendPush } from '@src/common/types/points';
import { ModalType } from '@src/common/constants/modals';
import { TModal } from '@src/common/types/modal';

// Styled
import { useCloseModalByType } from '@src/store/hooks/modals/useCloseModalByType';
import { useDropOffs } from '@src/ui/features/DropOffStat/hooks/useDropOffs';
import { H3, H5 } from '@pushwoosh/kit-typography';
import { Color, FontSize } from '@pushwoosh/kit-constants';
import {
  DetailBlock,
  ContentText,
  TagWrap,
  GrayText,
  OptionsRow,
  OptionsText,
  ContentRow
} from '../styled';

interface IProps {
  applicationCode: string;
  content: string;
  campaignCode: string;
  behaviorSplitterEnabled: TSendPush['data']['behaviorSplitter']['enabled'];
  behaviorSplitterInterval: TSendPush['data']['behaviorSplitter']['interval'];
  sent: TPointSendPushStatistics['pointStat']['sent'];
  opened: TPointSendPushStatistics['pointStat']['opened'];
  conversion: TPointSendPushStatistics['pointStat']['conversion'];
  pointTitle: string;
  pointId: string;
  enabledPersonalise: TSendPush['data']['personalise']['enabled'];
  placeholders: TSendPush['data']['personalise']['placeholders'];
  waitOptimalTime: TSendPush['data']['waitOptimalTime'];
  defaultOptimalTime: TSendPush['data']['defaultOptimalTime'];
  title: string;
  presetCode?: string;
}

export function DetailSendPush(props: IProps): JSX.Element {
  const {
    sent,
    opened,
    conversion,
    applicationCode,
    behaviorSplitterEnabled,
    behaviorSplitterInterval,
    campaignCode,
    content,
    pointTitle,
    pointId,
    enabledPersonalise,
    placeholders,
    waitOptimalTime,
    defaultOptimalTime,
    title,
    presetCode
  } = props;

  const modalSettings = {
    type: ModalType.EDITING_FORM_POINT,
    data: {
      pointId
    }
  } as TModal;

  const params = useParams<{ applicationCode: string }>();
  const { allDropOffs } = useDropOffs(pointId, modalSettings);

  const closeModalByType = useCloseModalByType();
  const isEnabledBehaviorSplitter =
    behaviorSplitterEnabled &&
    typeof behaviorSplitterInterval === 'number' &&
    behaviorSplitterInterval > 0;
  const time = decodeDelay(behaviorSplitterInterval);

  const statsLink = params.applicationCode
    ? `https://app.pushwoosh.com/applications/${applicationCode}/campaigns/${campaignCode}/statistics`
    : `https://go.pushwoosh.com/cp/applications/${applicationCode}/campaigns/${campaignCode}/statistics`;

  return (
    <Modal
      title={
        <>
          Push:&nbsp;
          <PointTitle>{pointTitle}</PointTitle>
        </>
      }
      footerRight={
        <ExtraOneButton
          actionButtonName="Close"
          onClickActionButton={() => closeModalByType(ModalType.DETAIL_POINT)}
        />
      }
      footerLeft={
        <ModalButtonsWrap>
          <Link isExternal to={statsLink} target="_blanc">
            <Button
              color="primary"
              size="field"
              view="ghost"
              iconPosition="left"
              iconType={IconEnum.OPEN_MEDIUM_LINED}
            >
              Full Statistics
            </Button>
          </Link>
        </ModalButtonsWrap>
      }
      isOpen
    >
      <Section direction="column">
        <StatWrap>
          <StatItem title="SENT" value={sent} />
          <StatItem title="OPENED" value={opened} />
          <StatItem title="CONVERSION" value={`${formatNumber(conversion, '0.[00]')}%`} />
        </StatWrap>
        <DetailBlock>
          <StatisticGrid
            data={[
              {
                label: 'Entries',
                value: sent + allDropOffs
              }
            ]}
          />
        </DetailBlock>
        <DetailBlock>
          <DropOffStat pointId={pointId} modal={modalSettings} />
        </DetailBlock>
      </Section>
      <Section direction="column">
        <ContentRow>
          <H3>Content:</H3>

          {presetCode && (
            <Link
              size={FontSize.REGULAR}
              isExternal
              to={`https://app.pushwoosh.com/applications/${applicationCode}/push-notification/one-time/${presetCode}`}
              target="_blank"
              afterIcon={IconEnum.OPEN_MEDIUM_LINED}
            >
              {title || 'Open preset'}
            </Link>
          )}
        </ContentRow>
        <DetailBlock>
          {title !== '' && (
            <DetailBlock>
              <H5>TITLE:</H5>
              <ContentText>{title}</ContentText>
            </DetailBlock>
          )}
          <DetailBlock>
            <H5>MESSAGE:</H5>
            <ContentText>{content}</ContentText>
          </DetailBlock>
        </DetailBlock>
        {enabledPersonalise && (
          <DetailBlock>
            <H5>OVERWRITE PERSONALIZATION:</H5>
            {placeholders.map((placeholder, index) => (
              <TagWrap key={index}>
                <InitialTag value={placeholder.name} />
                <GrayText>to</GrayText>
                <InitialTag
                  value={
                    <div>
                      {placeholder.attribute}
                      <GrayText>from</GrayText>
                      {placeholder.eventName}
                    </div>
                  }
                />
              </TagWrap>
            ))}
          </DetailBlock>
        )}
      </Section>
      <Section direction="column">
        <DetailBlock>
          <H3>Options:</H3>
        </DetailBlock>
        {waitOptimalTime && (
          <DetailBlock>
            <OptionsRow>
              <Icon type={IconEnum.CHECK_ROUND_SMALL_FILLED} fill={Color.BRIGHT} />
              <OptionsText>Sending at the optimal time, defined for each user</OptionsText>
              <GrayText>default time</GrayText>
              <InitialTag
                value={`${defaultOptimalTime?.hour} hours ${defaultOptimalTime?.minute} minutes`}
              />
            </OptionsRow>
          </DetailBlock>
        )}
        {isEnabledBehaviorSplitter && (
          <DetailBlock>
            <OptionsRow>
              <Icon type={IconEnum.CHECK_ROUND_SMALL_FILLED} fill={Color.BRIGHT} />
              <OptionsText>Split flow if message is not opened</OptionsText>
              <GrayText>wait period</GrayText>
              <InitialTag value={`${time.dd} days ${time.hh} hours ${time.mm} minutes`} />
            </OptionsRow>
          </DetailBlock>
        )}
      </Section>
    </Modal>
  );
}
