import { thunk, actionOn, computed } from 'easy-peasy';

// Types
import { TRichMedia } from '@src/common/types/richMedia';
import {
  IRichMediasModel,
  TRichMediasByAppCode,
  TRichMediaByAppCodeAndRichMediaCode,
  TRichMediasByCode
} from './richMedias.types';

const UNNECESSARY_RICH_MEDIA = new Set(['Subscription Segments', 'Channels']);

export const model: IRichMediasModel = {
  entities: {},
  contents: {},

  richMediasByAppCode: computed(
    (state): TRichMediasByAppCode =>
      (appCode): TRichMedia[] =>
        state.entities[appCode] || []
  ),

  richMediaWithContent: computed(
    (state): TRichMediasByCode =>
      (appCode, code): TRichMedia | undefined =>
        state.contents[code]
  ),

  richMediaByAppCodeAndRichMediaCode: computed(
    (state): TRichMediaByAppCodeAndRichMediaCode =>
      (appCode, richMediaCode): TRichMedia | null => {
        const richMedias = state.richMediasByAppCode(appCode);
        const richMedia = richMedias.find((richMedia): boolean => richMedia.code === richMediaCode);

        return richMedia !== undefined ? richMedia : null;
      }
  ),

  loadByAppCode: thunk(
    async (_, payload, helpers): Promise<{ code: string; richMedias: TRichMedia[] }> => {
      const {
        injections: { richMediaService, api }
      } = helpers;

      const response = await richMediaService.fetchByAppCode(payload, api);

      const richMedias =
        response?.payload?.items?.filter(
          ({ name }: { name: string }) => !UNNECESSARY_RICH_MEDIA.has(name)
        ) ?? [];

      return {
        code: payload,
        richMedias
      };
    }
  ),

  loadWithContent: thunk(
    async (_, payload, helpers): Promise<{ code: string; richMedia?: TRichMedia }> => {
      const {
        injections: { richMediaService, api }
      } = helpers;

      const response = await richMediaService.fetchByRichMediaCode(
        payload.appCode,
        payload.richMediaCode,
        api
      );

      const richMedia = response?.payload;

      return {
        code: payload.richMediaCode,
        richMedia
      };
    }
  ),

  onLoad: actionOn(
    (state): string => state.loadByAppCode.successType,
    (state, target): void => {
      state.entities[target.result.code] = target.result.richMedias;
    }
  ),

  onLoadContent: actionOn(
    (state): string => state.loadWithContent.successType,
    (state, target): void => {
      state.contents[target.result.code] = target.result?.richMedia;
    }
  )
};
