// Store
import { useStoreState, useStoreActions } from '@src/store/store';

// Types
import { TPoints } from '@src/common/types/points';
import { TComment } from '@src/common/types/comments';

interface IResult {
  draggablePoint: TPoints | TComment;
  isDragging: boolean;
  dragStart: (pointInfo: TPoints | TComment) => void;
  dragEnd: (payload?: void) => void;
}

export function useDragAndDropStore(): IResult {
  const draggablePoint = useStoreState((state) => state.sidebar.draggedPointInfo);

  const isDragging = useStoreState((state): boolean => state.sidebar.isDraggingNewPoint);

  const dragStart = useStoreActions(
    (actions): ((PointInfo: TPoints | TComment) => void) => actions.sidebar.dragPointStart
  );

  const handleDragStart = (PointInfo: TPoints): void => {
    document.onselectstart = (): boolean => false;
    dragStart(PointInfo);
  };

  const dragEnd = useStoreActions(
    (actions): ((payload?: void) => void) => actions.sidebar.dragPointEnd
  );

  const handleDragEnd = (payload?: void): void => {
    document.onselectstart = (): boolean => true;
    dragEnd(payload);
  };

  return {
    draggablePoint,
    isDragging,
    dragStart: handleDragStart,
    dragEnd: handleDragEnd
  };
}
