import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';

type ContainerProps = {
  direction?: 'row' | 'column';
};

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ direction = 'row' }: ContainerProps) => direction};

  div {
    margin-right: ${Spacing.S5};
  }
`;
