import React from 'react';
import { useStore, StoreProvider } from 'easy-peasy';
import { useBus, BusProvider } from '@src/common/contexts/bus';
import { Stage, Layer, Rect } from 'react-konva';
import { Color } from '@pushwoosh/kit-constants';
import { useStoreState } from '@src/store/store';
import { useZoom, useModeMap } from '@src/store/hooks';
import { DrawItemsMemo } from '@src/canvas/components/DrawItems';
import { ArrowMemo } from '@src/canvas/components/Arrow';
import { useCanvas } from '../hooks/useCanvas';
import { useDrawingArrow } from '../hooks';
import { useAddPoint } from '../hooks/useCanvas/useAddPoint';
import { Grid } from '../components/Grid';

export function Canvas(): JSX.Element {
  const store = useStore();
  const bus = useBus();
  const {
    stageRef,
    coordsCenteredPoint,
    isDraggable,
    refSelection,
    selectionOptions,
    handleCanvasWheel,
    handleSelection,
    handleCanvasMouseUp,
    handleCanvasDragMove,
    handleCanvasDragEnd,
    handleCanvasDblClick,
    handleCanvasClick,
    handleStartSelection
  } = useCanvas();

  const isMounted = React.useRef(null);

  const arrowState = useStoreState((store) => store.arrow.drawingData);
  const isArrowDrawing = useStoreState((store) => store.arrow.isDrawingArrow);
  const zoom = useZoom();
  const modeMap = useModeMap();
  const { moveDrawArrow, endDrawArrow } = useDrawingArrow();
  const { handlePointsCheckOverlap, onDragEndNewPoint } = useAddPoint();

  React.useEffect(() => {
    isMounted.current = true;

    const endDrawArrowSub = bus.select('endDrawArrow$').subscribe((event) => {
      if (isArrowDrawing && isMounted.current) {
        endDrawArrow(event.payload);
      }
    });

    const addNewPointSub = bus.select('addNewPoint$').subscribe((event) => {
      if (isMounted.current) {
        onDragEndNewPoint(event.payload);
        handlePointsCheckOverlap();
      }
    });

    return () => {
      isMounted.current = false;

      endDrawArrowSub.unsubscribe();
      addNewPointSub.unsubscribe();
    };
  }, [isArrowDrawing, bus, endDrawArrow, handlePointsCheckOverlap, onDragEndNewPoint]);

  return (
    <>
      <Stage
        ref={stageRef}
        x={coordsCenteredPoint.x}
        y={coordsCenteredPoint.y}
        height={window.innerHeight}
        width={window.innerWidth}
        name="canvas"
        scaleX={zoom}
        scaleY={zoom}
        onWheel={handleCanvasWheel}
        onMouseMove={isArrowDrawing ? moveDrawArrow : handleSelection}
        onMouseUp={handleCanvasMouseUp}
        onDragMove={handleCanvasDragMove}
        onDragEnd={handleCanvasDragEnd}
        onDblClick={modeMap.isEdit ? handleCanvasDblClick : undefined}
        onDblTap={modeMap.isEdit ? handleCanvasDblClick : undefined}
        draggable={isDraggable}
        onClick={handleCanvasClick}
        onMouseDown={handleStartSelection}
      >
        <Grid coordsCenteredPoint={coordsCenteredPoint} zoom={zoom} />
        <StoreProvider store={store}>
          <BusProvider value={bus}>
            <Layer>
              <DrawItemsMemo />

              {isArrowDrawing ? (
                <ArrowMemo
                  id="draft"
                  fromX={arrowState.fromX}
                  fromY={arrowState.fromY}
                  toX={arrowState.toX}
                  toY={arrowState.toY}
                  fromPointId={arrowState.fromPointId}
                  outputKey={arrowState.outputKey}
                  color={arrowState.color}
                  outputOrder={arrowState.outputOrder}
                  isActive
                  isDrawing={true}
                />
              ) : null}

              <Rect
                fill={Color.BRIGHT_LIGHT}
                ref={refSelection}
                visible={selectionOptions.visible}
                x={selectionOptions.x}
                y={selectionOptions.y}
                height={selectionOptions.height}
                width={selectionOptions.width}
                perfectDrawEnabled={false}
                shadowForStrokeEnabled={false}
              />
            </Layer>
          </BusProvider>
        </StoreProvider>
      </Stage>
    </>
  );
}
