// Types
import { IVector2 } from '@src/common/types/types';

// Constants
import { GroupSizeFirst } from '@src/common/constants/point';

interface IAlignArgs {
  x: number;
  y: number;
  scaleX: number;
  scaleY: number;
}

interface ICenterArgs extends IAlignArgs {
  height: number;
  width: number;
}

const HEIGHT_OFFSET = 150;
const WIDTH_OFFSET = 250;

export const getCoordsAlignStage = ({ x, y, scaleX, scaleY }: IAlignArgs): IVector2 => ({
  x: (x * -1 + GroupSizeFirst.WIDTH + WIDTH_OFFSET) * scaleX,
  y: (y * -1 + GroupSizeFirst.WIDTH + HEIGHT_OFFSET) * scaleY
});

export const getCoordsCenterStage = ({
  x,
  y,
  scaleX,
  scaleY,
  width,
  height
}: ICenterArgs): IVector2 => ({
  x: x * -1 * scaleX + (width - 300) / 2,
  y: y * -1 * scaleY + height / 2
});
