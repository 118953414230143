import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgCommentMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 11.069C3.25 6.204 7.161 2.25 12 2.25c4.838 0 8.75 3.954 8.75 8.819 0 2.837-1.282 4.805-2.95 6.388-.825.782-1.752 1.479-2.666 2.142l-.538.389c-.736.53-1.45 1.045-2.122 1.593a.75.75 0 01-.948 0c-.672-.548-1.386-1.063-2.122-1.593-.178-.129-.358-.258-.538-.39-.914-.662-1.841-1.36-2.666-2.141-1.668-1.583-2.95-3.551-2.95-6.388z"
        fill={props.fill}
      />
      <path
        d="M9 13h4M9 10h6"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgCommentMediumFilled;
