import * as React from 'react';
// import classnames from 'classnames';

// Types
import { ITooltipProps } from './Tooltip.types';

// Styled
import { StyledTooltip, StyledArrow } from './styled';

function Tooltip(props: ITooltipProps): JSX.Element {
  const { children, id, x, y, onClick, onMouseEnter, onMouseLeave } = props;

  const handleTooltipClick = React.useCallback((): void => {
    if (onClick) {
      onClick(id);
    }
  }, [onClick, id]);

  const handleTooltipMouseEnter = React.useCallback((): void => {
    if (onMouseEnter) {
      onMouseEnter(id);
    }
  }, [onMouseEnter, id]);

  const handleTooltipMouseLeave = React.useCallback((): void => {
    if (onMouseLeave) {
      onMouseLeave();
    }
  }, [onMouseLeave]);

  return (
    <StyledTooltip
      x={x}
      y={y}
      onClick={handleTooltipClick}
      onMouseEnter={handleTooltipMouseEnter}
      onMouseLeave={handleTooltipMouseLeave}
    >
      {children}
      <StyledArrow />
    </StyledTooltip>
  );
}

export default React.memo(Tooltip);
