import React from 'react';

// Components
import { FormField } from '@src/ui/kit';
import { H3 } from '@pushwoosh/kit-typography';

// Types
import { UpdateTag } from '@src/common/types/tag';

// Styled
import { Container, TagsList, Tag } from './styled';

interface IProps {
  tags: UpdateTag[];
}

export function DetailManualTags(props: IProps): JSX.Element {
  const { tags } = props;

  return (
    <Container>
      <H3>Manual Tag Value</H3>
      <TagsList>
        {tags.map((tag) => (
          <Tag>
            <FormField label={`${tag.name}:`} value={String(tag.value) as string} />
          </Tag>
        ))}
      </TagsList>
    </Container>
  );
}
