import * as React from 'react';
import classnames from 'classnames';

// Components
import { FormControl as FormControlBase } from '@pushwoosh/ui/lib/molecules/FormControl';

// Types
import { TFormControlProps } from './FormControl.types';

// Styles
import styles from './styles/styles.scss';

export function FormControl(props: TFormControlProps): JSX.Element {
  const { className, ...rest } = props;

  return (
    <FormControlBase
      className={classnames(styles['form-control'], className)}
      labelClassName={styles.label}
      errorClassName={styles.error}
      {...rest}
    />
  );
}
