import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';

interface ITooltipProps {
  x: number;
  y: number;
}

export const StyledTooltip = styled.div`
  position: absolute;
  z-index: 2;
  box-sizing: border-box;
  width: 178px;
  padding: ${Spacing.S4};

  background-color: #4b5057;
  border-radius: 4px;

  color: #ffffff;
  transform: ${({ x, y }: ITooltipProps) =>
    `translate3d(calc(${x}px - 50%), calc(${y}px - 100%), 0)`};
`;

export const StyledArrow = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;

  width: 12px;
  height: 12px;
  box-sizing: border-box;

  background-color: #4b5057;
  border-radius: 4px 0;

  transform: translate3d(-50%, 50%, 0) rotate(45deg);
`;
