import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgArrowRoundRightMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.53 7.47l4 4a.747.747 0 010 1.06l-4 4a.75.75 0 11-1.06-1.06l2.72-2.72H8a.75.75 0 010-1.5h6.19l-2.72-2.72a.75.75 0 011.06-1.06z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-10 8.5a8.5 8.5 0 100-17 8.5 8.5 0 000 17z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgArrowRoundRightMediumLined;
