import styled from 'styled-components';
import { Spacing, FontSize, Color, FontWeight, ShapeRadius } from '@pushwoosh/kit-constants';

export const DropdownContent = styled.div`
  display: flex;
`;

export const AccountContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  padding-right: ${Spacing.S0};
`;

export const AccountText = styled.div`
  display: grid;
  grid-template-columns: 159px;
  grid-template-rows: ${Spacing.S6} auto;
  margin-left: ${Spacing.S4};
`;

export const AccountLabel = styled.div`
  font-weight: ${FontWeight.MEDIUM};
  font-size: ${FontSize.REGULAR};
`;

export const AccountEmail = styled.div`
  max-width: 159px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${FontWeight.REGULAR};
  font-size: ${FontSize.SMALL};
  color: ${Color.LOCKED};
`;

export const AccountMenuWrap = styled.div`
  width: 260px;
  display: flex;
  flex-direction: column;
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${Spacing.S5};
`;

export const Username = styled.span`
  color: ${Color.MAIN};
  font-weight: ${FontWeight.MEDIUM};
  font-size: ${FontSize.HEADING4};
`;

export const UpgradeBadge = styled.span`
  font-size: ${FontSize.SMALL};
  color: ${Color.BRIGHT};
  font-weight: ${FontWeight.BOLD};
  padding: ${Spacing.S1} ${Spacing.S3};
  background: ${Color.BRIGHT_LIGHT};
  border-radius: ${ShapeRadius.CONTROL};
  margin-left: auto;
`;

export const IconWrap = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${ShapeRadius.CONTROL};
  background-color: ${Color.BRIGHT_LIGHT};
`;
