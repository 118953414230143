import { TWebhookPoint } from '@src/common/types/points';

type GetHeadersListResult = {
  name: string;
  value: string;
};

export const getHeadersList = (headers: TWebhookPoint['data']['headers']): GetHeadersListResult[] =>
  Object.keys(headers).map((headerName) => {
    const headerValue = headers[headerName];

    return { name: headerName, value: headerValue };
  });
