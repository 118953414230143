import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface IProps {
  container: HTMLElement;
  children: React.ReactNode;
  id?: string;
}

export function Portal(props: IProps): JSX.Element {
  const { container, children, id } = props;

  return ReactDOM.createPortal(children, container, id);
}
