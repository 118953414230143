import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgTrueFalse(props: IProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.555 9.168A1 1 0 0026 10v2.962a.038.038 0 01-.038.038H14.904a.04.04 0 01-.038-.03A4.002 4.002 0 007 14a4 4 0 007.866 1.03.04.04 0 01.038-.03H25.962c.02 0 .038.017.038.038V18a1 1 0 001.555.832l6-4a1 1 0 000-1.664l-6-4zm3.018 4.416a.5.5 0 010 .832l-1.796 1.197a.5.5 0 01-.777-.416v-2.394a.5.5 0 01.777-.416l1.796 1.197zM9 14a2 2 0 114 0 2 2 0 01-4 0zm9.575 7c-.384-1.197-.737-2.297-1.162-3.233-.159-.35.088-.767.471-.767h1.052a.49.49 0 01.453.297c.412.972.747 2.02 1.066 3.017l.122.38c.57 1.775 1.123 3.369 1.966 4.53.71.977 1.607 1.627 2.957 1.753.275.026.5-.2.5-.477V25a1 1 0 011.555-.832l4.5 3a1 1 0 010 1.664l-4.5 3A1 1 0 0126 31v-1.5a.527.527 0 00-.5-.516c-2.093-.14-3.542-1.161-4.575-2.584-1.062-1.463-1.698-3.37-2.252-5.094L18.575 21z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgTrueFalse;
