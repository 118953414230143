import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgArrowRoundRightSmallFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15A7 7 0 118 1a7 7 0 010 14zm.47-10.53a.75.75 0 000 1.06l1.72 1.72H4a.75.75 0 000 1.5h6.19l-1.72 1.72a.75.75 0 101.06 1.06l3-3a.75.75 0 000-1.06l-3-3a.75.75 0 00-1.06 0z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgArrowRoundRightSmallFilled;
