import * as React from 'react';
import { useParams } from 'react-router-dom';

// Analytics
import { sendToEmailSubscription, sendMixpanelEvent } from '@src/common/analytics';

// Store Hooks
import {
  useJourneys,
  useLoadJourneys,
  useOpenNewModal,
  usePaginationJourneys,
  useUsername,
  useGetApplicationByCode,
  useLoadJourneyListStats
} from '@src/store/hooks';
import { useStoreState } from '@src/store/store';

// Features hooks
import { useApplicationsWithAutoLoad } from '@src/ui/features/EditEvents/hooks/useApplicationsWithAutoLoad';

// Components
import { Table, CellType, StateTablePartial } from '@src/ui/features/JourneyList/components/Table';
import { InputAsync, DropdownButton } from '@src/ui/kit';
import {
  Tools,
  ToolItem,
  CreateJourneyButton
} from '@src/ui/features/JourneyList/components/Tools';
import { Button } from '@src/ui/kit/Button';
import { IconEnum } from '@src/ui/kit/Icon';

// Helpers
import { compileRequest } from '@src/ui/features/JourneyList/helpers/compileRequest';
import { makeTextStatusJourney } from '@src/ui/helpers/makeTextStatusJourney';

// Features
import { EmptyListJourney } from '@src/ui/features/EmptyListJourney';

// Constants
import { ModalType } from '@src/common/constants/modals';
import { JourneyStatus } from '@src/common/constants/journey';
import { PER_PAGE_DEFAULT } from '@src/ui/features/JourneyList/components/Table/hooks/usePagination';

// Styled
import { StyledH1, Wrapper, UpgradePlanLink } from '@src/ui/features/JourneyList/styled';
import { useSession } from '@src/common/contexts/session';

const allStatuses = `${JourneyStatus.DRAFT},${JourneyStatus.RUNNING},${JourneyStatus.FINISHED},${JourneyStatus.PAUSED}`;

const journeyStatusList = Object.values(JourneyStatus)
  .map((item) => ({
    value: item,
    label: makeTextStatusJourney(item)
  }))
  .filter((item) => item.value !== JourneyStatus.ARCHIVED);

export const JourneyList = (): JSX.Element => {
  const journeys = useJourneys();
  const params = useParams() as any;
  const { applicationCode } = params;
  const [loadJourneys, isLoadingJourneys] = useLoadJourneys();
  const [loadJourneyListStats, isLoadingListStats] = useLoadJourneyListStats();
  const [, isLoadingApplications] = useApplicationsWithAutoLoad();
  const openNewModal = useOpenNewModal();
  const pagination = usePaginationJourneys();
  const username = useUsername();
  const getApplicationByCode = useGetApplicationByCode();
  const { restrictions } = useSession();

  const isJourneyAllowed = restrictions.allowCustomerJourney;
  const journeyListStats = useStoreState((state) => state.statistics.journeyListStats);

  const handleClickNewJourney = React.useCallback((): void => {
    sendMixpanelEvent({ eventName: 'CreateJourney' });
    sendToEmailSubscription(
      'OnboardingProductUsage',
      { ActionName: 'ClickCreateJourney' },
      username
    );
    openNewModal({ type: ModalType.CREATE_JOURNEY });
  }, [openNewModal, username]);

  React.useEffect(() => {
    if (journeys.length > 0) {
      const journeyIds = journeys.reduce((result, journey, index) => {
        if (result === '') {
          return `${journey.id}`;
        }

        if (index === journeys.length + 1) {
          return `${journey.id}`;
        }

        return `${result},${journey.id}`;
      }, '');

      loadJourneyListStats({ journeyIds });
    }
  }, [journeys, loadJourneyListStats]);

  React.useLayoutEffect((): void => {
    if (applicationCode) {
      loadJourneys({
        per_page: PER_PAGE_DEFAULT,
        filter_state: allStatuses,
        application_code: applicationCode,
        no_stats: 'yes'
      });
    } else {
      loadJourneys({
        per_page: PER_PAGE_DEFAULT,
        filter_state: allStatuses,
        no_stats: 'yes'
      });
    }
  }, [loadJourneys, applicationCode]);

  const cells = React.useMemo(
    (): CellType[] =>
      journeys.map((journey) => {
        const application = getApplicationByCode(journey.params.applicationCode);

        return {
          id: journey.id,
          application,
          title: journey.title,
          status: journey.status,
          dataModified: journey.updatedAt,
          category: journey.createdAt,
          totalEntires: journeyListStats[journey.id]?.in || 0,
          avrCTR: journeyListStats[journey.id]?.avrCTR || 0,
          goalReached: journeyListStats[journey.id]?.goals || 0
        };
      }),
    [journeys, getApplicationByCode, journeyListStats]
  );

  const [tableState, setState] = React.useState<StateTablePartial>({ showArchive: false });

  const handleChangeTableState = React.useCallback(
    (state: StateTablePartial) => {
      // если сбросили сортировки, надо удалить поля из стейта
      if (state.sortColumn && state.sortColumn === null) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { sortColumn, sortType, ...otherState } = state;
        setState({
          ...tableState,
          ...otherState
        });
      } else {
        setState({
          ...tableState,
          ...state
        });
      }

      const requestParams = compileRequest({
        ...tableState,
        ...state
      });

      if (requestParams.filter_state === '' || requestParams.filter_state === undefined) {
        requestParams.filter_state = allStatuses;
      }

      if (applicationCode) {
        requestParams.application_code = applicationCode;
      }

      loadJourneys(requestParams);
    },
    [tableState, loadJourneys, applicationCode]
  );

  const handleChangeFilterTitle = React.useCallback(
    (value: string) => {
      sendMixpanelEvent({ eventName: 'JourneySearch' });
      handleChangeTableState({
        ...tableState,
        page: 0,
        filterTitle: value
      });
    },
    [tableState, handleChangeTableState]
  );

  const handleChangeFilterStatus = React.useCallback(
    (value: JourneyStatus[]) => {
      handleChangeTableState({
        ...tableState,
        showArchive: false,
        page: undefined,
        perPage: undefined,
        filterStatus: value
      });
    },
    [tableState, handleChangeTableState]
  );

  const handleShowArchive = React.useCallback(() => {
    handleChangeTableState({
      showArchive: !tableState.showArchive,
      sortColumn: undefined,
      sortType: undefined,
      page: undefined,
      perPage: undefined,
      filterTitle: undefined,
      filterStatus: undefined
    });
  }, [tableState, handleChangeTableState]);

  const handleReloadJourneys = React.useCallback(() => {
    handleChangeTableState({
      ...tableState
    });
  }, [tableState, handleChangeTableState]);

  const emptyList =
    (tableState?.filterTitle === undefined || tableState?.filterTitle === '') &&
    journeys.length === 0 &&
    isLoadingJourneys === false &&
    isLoadingApplications === false &&
    tableState?.showArchive !== true &&
    (tableState?.filterStatus === undefined || tableState?.filterStatus?.length === 0);

  if (emptyList) {
    return <EmptyListJourney isJourneyAllowed={isJourneyAllowed} />;
  }

  return (
    <>
      <StyledH1>All Campaigns</StyledH1>
      <Wrapper>
        <Tools>
          <ToolItem>
            <InputAsync
              wait={isLoadingJourneys || isLoadingApplications}
              afterIdle={500}
              isDisabled={isLoadingApplications && isLoadingJourneys && cells.length === 0}
              onChange={handleChangeFilterTitle}
            />
          </ToolItem>
          {!tableState.showArchive && (
            <ToolItem>
              <DropdownButton
                values={tableState.filterStatus}
                options={journeyStatusList}
                onChange={handleChangeFilterStatus}
                placeholder="Status: All"
              />
            </ToolItem>
          )}
          <ToolItem>
            <Button
              color="secondary"
              onClick={handleShowArchive}
              iconPosition={tableState.showArchive ? 'right' : null}
              iconType={tableState.showArchive ? IconEnum.CLOSE_SMALL : null}
            >
              {!tableState.showArchive ? 'Show Archive' : 'Hide Archive'}
            </Button>
          </ToolItem>
          {isJourneyAllowed ? (
            <CreateJourneyButton onClick={handleClickNewJourney}>
              Create Campaign
            </CreateJourneyButton>
          ) : (
            <UpgradePlanLink isExternal target="_blank" to="https://app.pushwoosh.com/products">
              <Button>Upgrade plan</Button>
            </UpgradePlanLink>
          )}
        </Tools>
        <Table
          cells={cells}
          pagination={pagination}
          isLoadingJourneys={isLoadingJourneys || isLoadingApplications}
          isLoadingStats={isLoadingListStats}
          onChange={handleChangeTableState}
          onReloadJourneys={handleReloadJourneys}
        />
      </Wrapper>
    </>
  );
};
