import * as React from 'react';

// Types
import { TPoints } from '@src/common/types/points';

// Store
import { useStoreActions, useStoreState } from '@src/store/store';

type TCheckOverlapComments = (payload?: TPoints[]) => void;
type TResult = TCheckOverlapComments;

export function useCheckOverlapComments(): TResult {
  const checkOverlapComments: TCheckOverlapComments = useStoreActions<TCheckOverlapComments>(
    (actions): TCheckOverlapComments => actions.comments.checkOverlapComments
  );

  const points = useStoreState((state) => state.points.filteredItems);

  const handlePointsCheckOverlap: TCheckOverlapComments =
    React.useCallback<TCheckOverlapComments>((): void => {
      checkOverlapComments(points);
    }, [checkOverlapComments, points]);

  return handlePointsCheckOverlap;
}
