import React from 'react';

// Components
import { Icon, IconEnum } from '@src/ui/kit';

// Constants
import { Color } from '@pushwoosh/kit-constants';

// Styled
import { Wrap, Description } from './styled';

export function EmptyHeader(): JSX.Element {
  return (
    <Wrap>
      <Icon type={IconEnum.INFO_ROUND_SMALL_LINED} fill={Color.BRIGHT} />
      <Description>An empty header will be sent. We recommend adding headers.</Description>
    </Wrap>
  );
}
