import * as React from 'react';

// Store Hooks
import { useGetRichMediasByApplicationCode, useLoadRichMedias } from '@src/store/hooks';

// Types
import { TRichMedia } from '@src/common/types/richMedia';
import { TApplication } from '@src/common/types/application';
import { useLoadRichMediaWithContent } from '@src/store/hooks/richMedias/useLoadRichMediaWithContent';
import { useGetRichMediaContent } from '@src/store/hooks/richMedias/useGetRichMediaContent';

export function useRichMediasByAppCodeWithAutoLoad(
  appCode: TApplication['code'],
  richMediaCode?: TRichMedia['code']
): [TRichMedia[], boolean, TRichMedia | undefined, boolean] {
  const getRichMediasByApplicationCode = useGetRichMediasByApplicationCode();
  const richMedias = getRichMediasByApplicationCode(appCode);
  const [loadRichMediasByAppCode, isLoadingRichMedias] = useLoadRichMedias();
  const [loadRichMediaContent, isLoadingRichMediaContent] = useLoadRichMediaWithContent();
  const getRichMediaContent = useGetRichMediaContent();
  const selectedRichMedia = getRichMediaContent(appCode, richMediaCode);

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (appCode && !isLoadingRichMedias) {
      loadRichMediasByAppCode(appCode);

      if (richMediaCode) {
        loadRichMediaContent({ appCode, richMediaCode });
      }
    }
  }, [appCode, richMediaCode]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return [richMedias, isLoadingRichMedias, selectedRichMedia, isLoadingRichMediaContent];
}
