import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgCertificateMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.75 6.5a.75.75 0 000 1.5h7.5a.75.75 0 000-1.5h-7.5zM9 10.25a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3.25A2.75 2.75 0 003.25 6v8c0 .414.336.75.75.75h2.25V19c0 .414.336.75.75.75h5v1.512a.5.5 0 00.686.464L14.5 21l1.814.726a.5.5 0 00.686-.464V19.75h3a.75.75 0 00.75-.75V6A2.75 2.75 0 0018 3.25H6zm-.5 1.604A1.25 1.25 0 004.75 6v7.25h1.5V6c0-.512-.308-.953-.75-1.146zM17 18.25h2.25V6c0-.69-.56-1.25-1.25-1.25H7.45c.192.375.3.8.3 1.25v12.25H12v-1.3a3.5 3.5 0 115 0v1.3zm-.5-3.75a2 2 0 11-4 0 2 2 0 014 0zm-1 3.355a3.502 3.502 0 01-2 0v1.93l1-.4 1 .4v-1.93z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgCertificateMediumLined;
