import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgVisibilityVisibleSmallLined(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.497 8.218A8.661 8.661 0 013.361 8l.136-.218c.225-.348.557-.81.988-1.269C5.359 5.58 6.55 4.75 8 4.75c1.45 0 2.64.83 3.515 1.763A9.092 9.092 0 0112.64 8c-.04.065-.085.139-.136.218-.225.348-.557.81-.988 1.27C10.641 10.42 9.45 11.25 8 11.25c-1.45 0-2.64-.83-3.515-1.763a9.089 9.089 0 01-.988-1.269zm10.674-.554L13.5 8l.67.336-.001.002-.002.004-.007.012a3.49 3.49 0 01-.1.186 10.589 10.589 0 01-1.451 1.973c-1 1.067-2.56 2.237-4.61 2.237-2.05 0-3.61-1.17-4.61-2.237a10.59 10.59 0 01-1.53-2.117 3.943 3.943 0 01-.021-.042l-.007-.012-.002-.004v-.002L2.5 8l-.67-.336v-.002l.002-.004.007-.012a3.829 3.829 0 01.1-.185A10.589 10.589 0 013.39 5.488C4.39 4.42 5.95 3.25 8 3.25c2.05 0 3.61 1.17 4.61 2.237a10.588 10.588 0 011.53 2.117l.021.042.007.012.002.004v.002zM13.5 8l.67-.335a.75.75 0 010 .67L13.5 8zM1.828 7.665L2.5 8l-.67.335a.75.75 0 010-.67zM7.25 8a.75.75 0 111.5 0 .75.75 0 01-1.5 0zM8 5.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgVisibilityVisibleSmallLined;
