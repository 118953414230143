// Enums
import { RepeatType } from '@src/common/constants/repeatSegment';

export function makeTextRepeatType(repeatType: RepeatType): string {
  switch (repeatType) {
    case RepeatType.CUSTOM: {
      return 'Custom Period';
    }

    case RepeatType.DATES_SPECIFIED: {
      return 'Dates specified';
    }

    case RepeatType.EVERY_DAY: {
      return 'Every day';
    }

    case RepeatType.EVERY_WEEK: {
      return 'Every week';
    }

    case RepeatType.EVERY_MONTH: {
      return 'Every month';
    }

    default: {
      return 'Custom Period';
    }
  }
}
