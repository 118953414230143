import React from 'react';
import { Tooltip } from '@pushwoosh/kit-tooltip';

// Components
import { Icon, IconEnum } from '@src/ui/kit';
import { Color } from '@pushwoosh/kit-constants';
import { TooltipContent } from '../TooltipContent';

// Types
import { IStatBarItemProps } from './StatBarItem.types';

// Styled
import { Container, Value, Label, IconWrapper } from './styled';

export function StatBarItem(props: IStatBarItemProps): JSX.Element {
  const { value, label, tooltipData, isBorderBottom = false, isDisabled = false } = props;
  const tooltipDisabled = !tooltipData || isDisabled;
  const [isVisibleTooltip, setVisibleTooltip] = React.useState(false);

  const handleMouseEnter = React.useCallback(() => {
    setVisibleTooltip(true);
  }, []);

  const handleMouseLeave = React.useCallback(() => {
    setVisibleTooltip(false);
  }, []);

  return (
    <Container borderBottom={isBorderBottom} disabled={isDisabled}>
      <Label>
        {label}

        <Tooltip
          isVisible={!tooltipDisabled && isVisibleTooltip}
          position="top-start"
          offset={[-20, 6]}
          arrowOffset="28px"
          content={<TooltipContent tooltipData={tooltipData} />}
        >
          <IconWrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {!tooltipDisabled && (
              <Icon type={IconEnum.INFO_ROUND_SMALL_LINED} fill={Color.LOCKED} />
            )}
          </IconWrapper>
        </Tooltip>
      </Label>
      <Value>{value}</Value>
    </Container>
  );
}
