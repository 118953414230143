import * as React from 'react';

// Styled
import { Container, RadioInput, Label } from './styled';

// Types
import { RadioButtonProps } from './RadioButton.types';

export function RadioButton(props: RadioButtonProps): JSX.Element {
  const { label, value, checked, onChange, disabled = false } = props;

  return (
    <Container>
      <RadioInput
        name="label"
        type="radio"
        checked={checked}
        value={value}
        disabled={disabled}
        onChange={() => undefined}
      />
      <Label checked={checked} disabled={disabled} onClick={() => !disabled && onChange(value)}>
        {label}
      </Label>
    </Container>
  );
}
