import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPlatformLogoSafariMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.204 9.81l5.25-2.25a.75.75 0 01.985.985l-2.25 5.25a.75.75 0 01-.394.394l-5.25 2.25a.746.746 0 01-.826-.158.75.75 0 01-.159-.826l2.25-5.25a.75.75 0 01.394-.394zm1.61.943l1.433 1.434 1.075-2.509-2.509 1.075z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12c0-4.963 4.037-9 9-9s9 4.037 9 9-4.037 9-9 9-9-4.037-9-9zm1.5 0c0 4.136 3.364 7.5 7.5 7.5s7.5-3.364 7.5-7.5-3.364-7.5-7.5-7.5S4.5 7.864 4.5 12z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPlatformLogoSafariMediumFilled;
