import * as React from 'react';
import konva from 'konva';
import { Path } from 'react-konva';
import { addSeconds, intervalToDuration } from 'date-fns';

// Constants
import {
  CHANNEL_GROUP_COLOR,
  PointType,
  COLOR_TRUE,
  COLOR_FALSE
} from '@src/common/constants/point';

// Helpers
import { getTitlePoint } from '@src/canvas/helpers';
import { getOutputs } from '../helpers/getOutputs';

// Components
import { Point, PointContent, PointText, PointIcon, PointOutput, PointShortInfo } from '../Point';

// Types
import { IProps } from './PointSendSms.types';

const KEY_TRUE = 'output1';
const KEY_FALSE = 'default';

export function PointSendSms(props: IProps): JSX.Element {
  const [isShowBadge, setShowBadge] = React.useState([true, true]);
  const {
    data: { smsBehaviorSplitter },
    outputs,
    propsCommonPoint
  } = props;

  const getIsOutput = React.useCallback(
    (key: string): boolean => getOutputs(key, outputs),
    [outputs]
  );

  const getPointInfo = React.useCallback((): string => {
    if (!smsBehaviorSplitter?.interval) return '0 days';

    const startDate = new Date(0);
    const endDate = addSeconds(startDate, smsBehaviorSplitter?.interval);
    const duration = intervalToDuration({ start: startDate, end: endDate });

    if (duration.hours || duration.minutes) {
      return `${duration.days} days...`;
    }

    return `${duration.days} days`;
  }, [smsBehaviorSplitter?.interval]);

  const isLinkedOutputFirst = getIsOutput(KEY_TRUE);
  const isLinkedOutputSecond = getIsOutput(KEY_FALSE);

  const titlePoint = getTitlePoint(PointType.SEND_SMS, propsCommonPoint.title, props.data);

  return (
    <Point type={PointType.SEND_SMS} color={CHANNEL_GROUP_COLOR} {...propsCommonPoint}>
      <PointContent type={PointType.SEND_SMS}>
        <PointIcon type={PointType.SEND_SMS} fill={CHANNEL_GROUP_COLOR}>
          <Path
            data="M20 32c6.627 0 12-5.373 12-12S26.627 8 20 8 8 13.373 8 20c0 3.134 1.201 5.987 3.169 8.125a.517.517 0 0 1-.008.714l-2.307 2.307a.5.5 0 0 0 .353.854H20Z"
            fill="white"
          />
          <Path
            perfectDrawEnabled={false}
            shadowForStrokeEnabled={false}
            fill={CHANNEL_GROUP_COLOR}
            data="M24 17.5a1.5 1.5 0 0 1 1.5-1.5h2a1.5 1.5 0 0 1 1.5 1.5.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-.2a.3.3 0 0 0-.3-.3h-.4a.3.3 0 0 0-.3.3v1.65a.3.3 0 0 0 .3.3h1.2a1.5 1.5 0 0 1 1.5 1.5v1.75a1.5 1.5 0 0 1-1.5 1.5h-2a1.5 1.5 0 0 1-1.5-1.5v-.25a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v.45a.3.3 0 0 0 .3.3h.4a.3.3 0 0 0 .3-.3v-2.15a.3.3 0 0 0-.3-.3h-1.2a1.5 1.5 0 0 1-1.5-1.5V17.5Z"
          />
          <Path
            fill={CHANNEL_GROUP_COLOR}
            data="M17 16.567a.5.5 0 0 1 .82-.384l2.084 1.737a.15.15 0 0 0 .192 0l2.084-1.737a.5.5 0 0 1 .82.384V23.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-3.45a.15.15 0 0 0-.24-.12l-.58.435a.3.3 0 0 1-.36 0l-.58-.435a.15.15 0 0 0-.24.12v3.45a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-6.933Z"
          />
          <Path
            fill={CHANNEL_GROUP_COLOR}
            data="M11 17.5a1.5 1.5 0 0 1 1.5-1.5h2a1.5 1.5 0 0 1 1.5 1.5.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-.2a.3.3 0 0 0-.3-.3h-.4a.3.3 0 0 0-.3.3v1.65a.3.3 0 0 0 .3.3h1.2a1.5 1.5 0 0 1 1.5 1.5v1.75a1.5 1.5 0 0 1-1.5 1.5h-2a1.5 1.5 0 0 1-1.5-1.5v-.25a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v.45a.3.3 0 0 0 .3.3h.4a.3.3 0 0 0 .3-.3v-2.15a.3.3 0 0 0-.3-.3h-1.2a1.5 1.5 0 0 1-1.5-1.5V17.5Z"
          />
        </PointIcon>
        <PointText type={PointType.SEND_SMS}>{titlePoint}</PointText>
        {smsBehaviorSplitter?.enabled ? (
          <>
            <PointOutput
              y={9}
              pointId={propsCommonPoint.id}
              outputKey={KEY_TRUE}
              type={PointType.SEND_SMS}
              colorBlock={COLOR_TRUE}
              colorArrow={COLOR_TRUE}
              isInvalid={propsCommonPoint.isInvalid}
              isActive={propsCommonPoint.isActive}
              isLinkedOutput={isLinkedOutputFirst}
              onMouseDown={(event: konva.KonvaEventObject<MouseEvent>): void => {
                setShowBadge([isShowBadge[0], false]);
                propsCommonPoint.onMouseDownOutput(event);
              }}
              textBadge="Delivered"
              fillBadge={COLOR_TRUE}
              isShowBadge={isLinkedOutputFirst ? false : isShowBadge[0]}
              arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
            />
            <PointOutput
              y={31}
              pointId={propsCommonPoint.id}
              outputKey={KEY_FALSE}
              type={PointType.SEND_SMS}
              colorBlock={COLOR_FALSE}
              colorArrow={COLOR_FALSE}
              isInvalid={propsCommonPoint.isInvalid}
              isActive={propsCommonPoint.isActive}
              isLinkedOutput={isLinkedOutputSecond}
              onMouseDown={(event: konva.KonvaEventObject<MouseEvent>): void => {
                setShowBadge([isShowBadge[0], false]);
                propsCommonPoint.onMouseDownOutput(event);
              }}
              textBadge="Not delivered"
              fillBadge={COLOR_FALSE}
              isShowBadge={isLinkedOutputSecond ? false : isShowBadge[0]}
              arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
            />
          </>
        ) : (
          <PointOutput
            pointId={propsCommonPoint.id}
            type={PointType.SEND_SMS}
            colorBlock={CHANNEL_GROUP_COLOR}
            isInvalid={propsCommonPoint.isInvalid}
            isActive={propsCommonPoint.isActive}
            isLinkedOutput={propsCommonPoint.isLinkedOutput}
            onMouseDown={propsCommonPoint.onMouseDownOutput}
            arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
          />
        )}
      </PointContent>
      {smsBehaviorSplitter?.enabled && (
        <PointShortInfo
          type={PointType.SEND_SMS}
          label={`Wait for ${getPointInfo()}`}
          align="left"
        />
      )}
    </Point>
  );
}
