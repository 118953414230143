import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSettingsSmallFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.434 1a.5.5 0 00-.495.43l-.25 1.744a4.97 4.97 0 00-1.175.487l-1.41-1.058a.5.5 0 00-.653.047l-.801.8a.5.5 0 00-.047.654l1.058 1.41a4.97 4.97 0 00-.487 1.175l-1.745.25A.5.5 0 001 7.434v1.132a.5.5 0 00.43.495l1.744.25c.112.415.277.81.487 1.175l-1.058 1.41a.5.5 0 00.047.653l.8.801a.5.5 0 00.654.047l1.41-1.058c.365.21.76.375 1.175.487l.25 1.745a.5.5 0 00.495.429h1.132a.5.5 0 00.495-.43l.25-1.744c.415-.112.81-.277 1.175-.487l1.41 1.058a.5.5 0 00.653-.047l.801-.8a.5.5 0 00.047-.654l-1.058-1.41c.21-.365.375-.76.487-1.176l1.745-.249A.5.5 0 0015 8.566V7.434a.5.5 0 00-.43-.495l-1.744-.25a4.969 4.969 0 00-.487-1.175l1.058-1.41a.5.5 0 00-.047-.653l-.8-.801a.5.5 0 00-.654-.047l-1.41 1.058a4.968 4.968 0 00-1.176-.487l-.249-1.745A.5.5 0 008.566 1H7.434zM8 11a3 3 0 100-6 3 3 0 000 6z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgSettingsSmallFilled;
