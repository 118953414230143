import React from 'react';

// Components
import { H4, Paragraph } from '@pushwoosh/kit-typography';

// Types
import { DetailHeaderProps } from './DetailHeader.types';

// Styled
import { StyledDetailHeader } from './styled';

export function DetailHeader(props: DetailHeaderProps): JSX.Element {
  const { headers } = props;

  return (
    <StyledDetailHeader>
      <H4>Headers:</H4>
      {Object.entries(headers).map((header, index) => (
        <Paragraph key={index}>
          {header[0]}
          :&nbsp;
          {header[1]}
        </Paragraph>
      ))}
    </StyledDetailHeader>
  );
}
