import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgHelpRoundSmallFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 8A7 7 0 111 8a7 7 0 0114 0zM8.133 5.58a1.1 1.1 0 00-1.224.719l-.133.377a.5.5 0 01-.637.306l-.755-.266a.5.5 0 01-.306-.637l.133-.378a2.9 2.9 0 015.636.966c0 1.144-.847 1.879-1.401 2.249-.211.14-.419.255-.6.344a.44.44 0 01-.44.44h-.86a.5.5 0 01-.5-.5v-.533a.9.9 0 01.613-.853l.009-.003.044-.017a3.8 3.8 0 00.735-.377c.446-.296.6-.561.6-.75v-.002a1.1 1.1 0 00-.914-1.085zM8 12.5a1 1 0 100-2 1 1 0 000 2z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgHelpRoundSmallFilled;
