import * as React from 'react';
import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { useStoreActions } from '@src/store/store';
import { useEmailsByAppCodeWithAutoLoad } from '@src/ui/features/SendEmail/hooks/useEmailsByAppCodeWithAutoLoad';
import { makeEmailOptions } from '@src/ui/helpers';

// UI Components
import { Section, Switch } from '@src/ui/kit';
import { FormSelect } from '@src/ui/form';
import { PersonaliseMessage } from '@src/ui/features/PersonaliseMessage';
import { Paragraph } from './styled';

// Types
import { ISendEmailFormProps, ISendEmailFormValues } from './SendEmailForm.types';

export function SendEmailForm(props: ISendEmailFormProps): JSX.Element {
  const {
    emailOptions = [],
    applicationCode,
    pointId,
    names,
    values,
    errors,
    touched,
    isLoadingEmailOptions = false,
    setFieldValue,
    setFieldTouched,
    nameChanged,
    onSubmit
  } = props;
  const params = useParams<{ applicationCode: string }>();
  const [emails] = useEmailsByAppCodeWithAutoLoad(applicationCode);
  const emailsList = makeEmailOptions(emails);
  const isDisabledEmail = !applicationCode;
  const loadPlaceholders = useStoreActions((actions) => actions.emails.loadPlaceholders);

  const selectPreset = React.useCallback(
    (name: string, value: ISendEmailFormValues['presetCode'], label: string) => {
      setFieldValue(name, value);

      if (!nameChanged) {
        setFieldValue(names.title, label);
      }

      const emailData = emailsList.find((email): boolean => email.value === value);

      loadPlaceholders(emailData?.template ?? '')
        .then((response) => {
          const { items } = response;
          const placeholders = items.map((item) => ({
            name: item,
            pointID: '',
            eventName: '',
            attribute: '',
            isSelected: false
          }));

          setFieldValue(names.placeholders, placeholders);
        })
        .catch(() => {
          setFieldValue(names.placeholders, []);
        });
    },
    [names.title, setFieldValue, names.placeholders, emailsList, loadPlaceholders, nameChanged]
  );

  const handleSwitchPersonalise = React.useCallback(
    (value: boolean) => {
      setFieldValue(names.enabledPersonalise, value);
    },
    [names.enabledPersonalise, setFieldValue]
  );

  const handleSwitchLiquid = React.useCallback(
    (value: boolean) => {
      setFieldValue(names.enabledLiquid, value);
    },
    [names.enabledLiquid, setFieldValue]
  );

  const handleLiquidEventChange = React.useCallback(
    (value: string) => {
      setFieldValue(names.liquidEventName, value);
    },
    [names.liquidEventName, setFieldValue]
  );

  const handleSwitchSubscribed = React.useCallback(
    (value: boolean) => {
      setFieldValue(names.sendOnlyToSubscribed, !value);
    },
    [names.sendOnlyToSubscribed, setFieldValue]
  );

  const togglePlaceholder = React.useCallback(
    (value: boolean, index: number) => {
      const newValues = cloneDeep(values);

      newValues.placeholders[index].isSelected = value;

      setFieldValue(names.placeholders, newValues.placeholders);
    },
    [setFieldValue, values, names.placeholders]
  );

  const selectEvent = React.useCallback(
    (value: string, pointId: string, index: number) => {
      const newValues = cloneDeep(values);

      newValues.placeholders[index].eventName = value;
      newValues.placeholders[index].pointID = pointId;

      setFieldValue(names.placeholders, newValues.placeholders);
    },
    [setFieldValue, values, names.placeholders]
  );

  const selectAttr = React.useCallback(
    (value: string, index: number) => {
      const newValues = cloneDeep(values);

      newValues.placeholders[index].attribute = value;

      setFieldValue(names.placeholders, newValues.placeholders);
    },
    [setFieldValue, values, names.placeholders]
  );

  const presetLink = params.applicationCode
    ? `https://app.pushwoosh.com/applications/${applicationCode}/presets`
    : `https://go.pushwoosh.com/cp/applications/${applicationCode}/presets`;

  return (
    <>
      <Section direction="column">
        <FormSelect
          label="Message Preset"
          url={presetLink}
          name={names.presetCode}
          value={values.presetCode}
          error={touched.presetCode && errors.presetCode}
          options={emailOptions}
          placeholder="Select email preset"
          isLoading={isLoadingEmailOptions}
          isSearchable
          isDisabled={isDisabledEmail}
          onChange={selectPreset}
          onBlur={setFieldTouched}
        />
      </Section>

      <Section direction="column">
        <Switch onChange={handleSwitchSubscribed} defaultEnabled={!values.sendOnlyToSubscribed}>
          Send to unsubscribed
        </Switch>
        <Paragraph>
          Even if a user unsubscribes from an email newsletter, he will still receive this message.
        </Paragraph>
      </Section>

      {values.presetCode && (
        <PersonaliseMessage
          pointId={pointId}
          enabledPersonalise={values.enabledPersonalise}
          enabledLiquid={values.enabledLiquid}
          liquidEvent={values.liquidEventName}
          error={errors.placeholders as string}
          placeholders={values.placeholders}
          onSwitch={handleSwitchPersonalise}
          onSelectPlaceholder={togglePlaceholder}
          onSelectEvent={selectEvent}
          onSelectAttribute={selectAttr}
          onLiquidEnabledChange={handleSwitchLiquid}
          onLiquidEventChange={handleLiquidEventChange}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
}
