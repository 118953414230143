import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import { ShapeRadius, Color } from '@pushwoosh/kit-constants';

export const PREFIX = 'customSelect';

const selectCss = css`
  .${PREFIX}__control {
    min-width: 150px;
    box-sizing: border-box;
    background-color: ${Color.CLEAR};
    border: 1px solid ${Color.FORM};
    border-radius: ${ShapeRadius.CONTROL};
  }

  .${PREFIX}__indicator-separator {
    display: none;
  }

  .${PREFIX}__control--is-focused {
    border: 1px solid ${Color.FORM};
    box-shadow: none;
  }

  .${PREFIX}__control--is-disabled {
    background-color: ${Color.FROZEN};
  }

  .${PREFIX}__control:hover {
    border: 1px solid ${Color.FORM};
  }

  .${PREFIX}__placeholder {
    display: flex;
    width: 100%;
    text-overflow: clip;
    white-space: nowrap;
  }
`;

export const SelectComponent = styled(ReactSelect)`
  ${selectCss}
`;

export const SelectAsyncComponent = styled(AsyncSelect)`
  ${selectCss}
`;
