import { action, computed } from 'easy-peasy';

// Constants
import { Modes } from '@src/common/constants';

// Types
import { IStatuses } from '@src/common/types/mode';
import { IMode } from './mode.types';

export const model: IMode = {
  status: Modes.EDIT,

  statuses: computed([(state): Modes => state.status], (status): IStatuses => {
    const isChanged = status === Modes.CHANGED;
    const isRead = status === Modes.READ;
    const isEdit = isChanged || status === Modes.EDIT;

    return {
      isChanged,
      isRead,
      isEdit
    };
  }),

  setMode: action((state, status): void => {
    state.status = status;
  })
};
