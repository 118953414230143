import React from 'react';
import useCalendar, { CalendarViewType } from '@veccu/react-calendar';

// Components
import { MonthView } from './components/MonthView';
import { YearView } from './components/YearView';
import { DecadeView } from './components/DecadeView';

// Helpers
import { completWeeks } from './helpers/completeWeeks';

// Styled
import { CalendarWrap } from './styled';

// Types
import { DatepickerProps, ViewEnum } from './Datepicker.types';

export function Datepicker(props: DatepickerProps): JSX.Element {
  const [view, setView] = React.useState(ViewEnum.MONTH);
  const { value, onChange, minDate, maxDate, withBorder = true } = props;
  const { cursorDate, headers, body, navigation } = useCalendar({
    defaultDate: value,
    defaultViewType: CalendarViewType.Month
  });
  const weeks = body.value;
  const completedWeeks = completWeeks(weeks, cursorDate);

  return (
    <CalendarWrap withBorder={withBorder}>
      {view === ViewEnum.MONTH && (
        <MonthView
          cursorDate={cursorDate}
          navigation={navigation}
          headers={headers}
          weeks={completedWeeks}
          onChange={onChange}
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          setView={setView}
        />
      )}
      {view === ViewEnum.YEAR && (
        <YearView
          navigation={navigation}
          cursorDate={cursorDate}
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          setView={setView}
        />
      )}
      {view === ViewEnum.DECADE && (
        <DecadeView
          cursorDate={cursorDate}
          navigation={navigation}
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          setView={setView}
        />
      )}
    </CalendarWrap>
  );
}
