import React from 'react';
import { Switch } from '@src/ui/kit';
import { JourneyStatus } from '@src/common/constants';
import { TFormFnSetFieldValue } from '@src/common/types/form';
import { Paragraph } from '@pushwoosh/kit-typography';
import { Item } from '@src/ui/form/styled';
import { IEditPointSendPushValues } from '../EditPointSendPush/EditPointSendPush.types';

type Props = {
  journeyStatus: JourneyStatus;
  values: IEditPointSendPushValues;
  names: Record<keyof IEditPointSendPushValues, string>;
  setFieldValue: TFormFnSetFieldValue;
};

export function SendByUserId(props: Props): JSX.Element {
  const { journeyStatus, values, names, setFieldValue } = props;
  const isDisabled = journeyStatus === JourneyStatus.RUNNING;

  const handleSwitchSendByUserId = React.useCallback(
    (value: boolean) => {
      setFieldValue(names.sendByUserID, value);
    },
    [setFieldValue, names]
  );

  return (
    <div>
      <Switch
        isDisabled={isDisabled}
        defaultEnabled={values.sendByUserID}
        onChange={handleSwitchSendByUserId}
      >
        Send to all user devices (from UserID)
      </Switch>
      <Item>
        <Paragraph>
          If several devices are associated with a user (a specific User ID), the message will be
          sent to all those devices simultaneously.
        </Paragraph>
      </Item>
    </div>
  );
}
