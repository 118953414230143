import { PointType } from '@src/common/constants';

export function getMixpanelEventByType(pointType: PointType) {
  switch (pointType) {
    case PointType.SET_TAGS: {
      return {
        eventName: 'Canvas:ShowBuyPopover',
        eventData: {
          PointName: 'SetTag'
        }
      };
      // return 'Canvas:SetTag:ShowBuyPopover';
    }

    case PointType.SEND_IN_APP: {
      return {
        eventName: 'Canvas:ShowBuyPopover',
        eventData: {
          PointName: 'InApp'
        }
      };
    }

    case PointType.WEBHOOK: {
      return {
        eventName: 'Canvas:ShowBuyPopover',
        eventData: {
          PointName: 'Webhook'
        }
      };
    }

    default: {
      return {
        eventName: 'Error'
      };
    }
  }
}
