import * as React from 'react';

// Components
import { Tooltip } from '@src/canvas/components/Tooltip';
import { TooltipInfoField } from '@src/canvas/components/TooltipInfoField';

// Types
import { ITooltipPointSendEmailStatsProps } from './TooltipPointSendEmailStats.types';

function TooltipPointSendEmailStats(props: ITooltipPointSendEmailStatsProps): JSX.Element {
  const {
    className,
    sent,
    opened,
    clicked,
    unsubscribed,
    error,
    id,
    x,
    y,
    onClick,
    onMouseEnter,
    onMouseLeave
  } = props;

  return (
    <Tooltip
      className={className}
      id={id}
      x={x}
      y={y}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <TooltipInfoField label="Sent" value={sent} />
      <TooltipInfoField label="Opened" value={opened} />
      <TooltipInfoField label="Unsubscribed" value={unsubscribed} />
      <TooltipInfoField label="Clicked" value={clicked} />
      {/* <TooltipInfoField label="Goals Reached" value={goalsReached} /> */}
      <TooltipInfoField label="Error" value={error} />
    </Tooltip>
  );
}

export default React.memo(TooltipPointSendEmailStats);
