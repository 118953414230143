import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgBulletSquareFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 5.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v5a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-5z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgBulletSquareFilled;
