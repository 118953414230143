import { useCallback, useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from '@src/store/store';
import { useBus } from '@src/common/contexts/bus';
import { hotkeys$ } from '@src/store/subjects/hotkeys';

// Helpers
import { getCorrectPointPosition, getCorrectMousePositionByStage } from '@src/canvas/helpers';

// Types
import { TArrow } from '@src/common/types/arrow';
import { PointType } from '@src/common/constants/point';
import { IUniqueId } from '@src/common/types/entities';
import {
  DrawingArrowStatus,
  IUseDrawingArrow,
  TEndDrawArrow,
  TMoveDrawArrow,
  TResetDrawArrow,
  TStartDrawArrow
} from '@src/canvas/hooks/useDrawingArrow/useDrawingArrow.types';

interface IPointOutputAttrs {
  x: number;
  y: number;
  fromPointId: IUniqueId['id'];
  outputKey: IUniqueId['id'];
  outputOrder: number;
  colorArrow?: string;
}

interface IPointAttrs {
  x: number;
  y: number;
  pointId: IUniqueId['id'];
  pointName: string;
  pointType: PointType;
  pointGroup: string;
}

export function useDrawingArrow(): IUseDrawingArrow {
  const bus = useBus();
  const arrowState = useStoreState((store) => store.arrow.drawingData);
  const setArrowState = useStoreActions((actions) => actions.arrow.setArrowState);
  const resetArrowState = useStoreActions((actions) => actions.arrow.resetArrowState);
  const [hotkeys, setHotkeys] = useState(hotkeys$.initialState);

  useEffect(() => {
    hotkeys$.subscribe(setHotkeys);
    hotkeys$.init();
  }, []);

  const startDrawArrow = useCallback<TStartDrawArrow>(
    (konvaEvent): void => {
      const konvaNode = konvaEvent.currentTarget;

      konvaEvent.cancelBubble = true;

      if (konvaNode.hasName('point-output')) {
        const nodeAttrs = konvaNode.getAttrs() as IPointOutputAttrs;

        if (!nodeAttrs.fromPointId) {
          return;
        }

        const absolutePosition = konvaNode.getAbsolutePosition();
        const position = getCorrectPointPosition(konvaNode.getStage(), absolutePosition);

        setArrowState({
          status: DrawingArrowStatus.DRAWING,
          fromX: position.x,
          fromY: position.y,
          toX: position.x,
          toY: position.y,
          fromPointId: nodeAttrs.fromPointId,
          outputKey: nodeAttrs.outputKey,
          outputOrder: nodeAttrs.outputOrder,
          color: nodeAttrs.colorArrow
        });
      }
    },
    [setArrowState]
  );

  const moveDrawArrow = useCallback<TMoveDrawArrow>(
    (konvaEvent): void => {
      const konvaNode = konvaEvent.currentTarget;

      konvaEvent.cancelBubble = true;

      if (
        arrowState.status === DrawingArrowStatus.IDLE ||
        arrowState.status === DrawingArrowStatus.DRAWING_PAUSE_ON_CANVAS
      ) {
        return;
      }

      const isDrawingOnPoint =
        konvaNode.hasName('point') &&
        arrowState.status !== DrawingArrowStatus.DRAWING_PAUSE_ON_POINT;
      if (isDrawingOnPoint) {
        const nodeAttrs = konvaNode.getAttrs() as IPointAttrs;
        if (nodeAttrs.pointId === arrowState.fromPointId) {
          return;
        }

        setArrowState({
          status: DrawingArrowStatus.DRAWING_PAUSE_ON_POINT,
          toX: nodeAttrs.x,
          toY: nodeAttrs.y + konvaNode.height() / 2
        });
      }

      const isDrawingOnCanvas = konvaNode.hasName('canvas');
      const mousePositionOnCanvas = getCorrectMousePositionByStage(konvaNode.getStage());

      if (isDrawingOnCanvas) {
        setArrowState({
          status: DrawingArrowStatus.DRAWING,
          toX: mousePositionOnCanvas.x,
          toY:
            hotkeys.isAltPressed || hotkeys.isShiftPressed
              ? arrowState.fromY
              : mousePositionOnCanvas.y
        });
      }
    },
    [
      setArrowState,
      arrowState.status,
      arrowState.fromPointId,
      arrowState.fromY,
      hotkeys.isAltPressed,
      hotkeys.isShiftPressed
    ]
  );

  const endDrawArrow = useCallback<TEndDrawArrow>(
    (konvaEvent): void => {
      const konvaNode = konvaEvent.currentTarget;

      if (
        arrowState.status === DrawingArrowStatus.IDLE ||
        arrowState.status === DrawingArrowStatus.DRAWING_PAUSE_ON_CANVAS
      ) {
        return;
      }

      konvaEvent.cancelBubble = true;

      const isDrawingOnPoint = konvaNode.hasName('point');
      if (isDrawingOnPoint) {
        const nodeAttrs = konvaNode.getAttrs() as IPointAttrs;

        if (nodeAttrs.pointId === arrowState.fromPointId) {
          return;
        }

        const nodeRect = konvaNode.getClientRect();
        const arrow = {
          ...arrowState,
          toX: nodeAttrs.x,
          toY: nodeAttrs.y + nodeRect.height / 2,
          toPointId: nodeAttrs.pointId
        };

        setArrowState({
          ...arrow,
          status: DrawingArrowStatus.IDLE
        });

        bus.emit({ type: 'onDrawEndOnPoint$', payload: arrow, withCopmplete: false });

        return;
      }

      const isDrawingOnCanvas = konvaNode.hasName('canvas') && konvaNode === konvaEvent.target;

      if (isDrawingOnCanvas) {
        const mousePositionOnCanvas = getCorrectMousePositionByStage(konvaNode.getStage());

        const canvasRect = (
          konvaEvent.evt.currentTarget as HTMLCanvasElement
        ).getBoundingClientRect();

        const arrow: TArrow = {
          ...arrowState,
          toX: mousePositionOnCanvas.x,
          toY:
            hotkeys.isAltPressed || hotkeys.isShiftPressed
              ? arrowState.fromY
              : mousePositionOnCanvas.y,
          pageX: konvaEvent.evt.clientX - canvasRect.left,
          pageY: konvaEvent.evt.clientY - canvasRect.top
        };

        setArrowState({
          ...arrow,
          status: DrawingArrowStatus.DRAWING_PAUSE_ON_CANVAS
        });

        bus.emit({ type: 'openPopupMenu$', payload: arrow, withCopmplete: false });

        return;
      }

      setArrowState({
        status: DrawingArrowStatus.IDLE
      });
    },
    [setArrowState, arrowState, bus, hotkeys.isAltPressed, hotkeys.isShiftPressed]
  );

  const resetDrawArrow = useCallback<TResetDrawArrow>((): void => {
    resetArrowState();
  }, [resetArrowState]);

  return {
    startDrawArrow,
    moveDrawArrow,
    endDrawArrow,
    resetDrawArrow
  };
}
