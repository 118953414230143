import { SplitOutput } from '@src/common/types/statistics';
import { ChartData } from '../components/Row/types';
import { checkWinner } from './chekWinner';

function normalDensityZx(x: number, mean: number, stdDev: number) {
  const a = x - mean;
  return Math.exp(-(a * a) / (2 * stdDev * stdDev)) / (Math.sqrt(2 * Math.PI) * stdDev);
}

function calculateData(conversion: number, users: number): ChartData[] {
  const crA = conversion / users;
  const seA = Math.sqrt((crA * (1 - crA)) / users);
  const chartData = [];

  for (let i = crA - seA * 4; i < crA + seA * 4; i += seA / 20) {
    chartData.push({ x: i, y: normalDensityZx(i, crA, seA) / 10 });
  }

  return chartData;
}

type Options = {
  statOutputs: {
    [key: string]: SplitOutput[];
  };
  split: number[];
  goalId: string;
};

export function calculateChartsData(options: Options) {
  const { statOutputs, split, goalId } = options;
  const chartsDataMap = {} as { [key: string]: ChartData[] };

  split.forEach((value, index) => {
    const branchKey = `output${index}`;
    const branchStat = statOutputs?.[branchKey]?.find((item) => item.goalUUID === goalId);

    const chartData = calculateData(branchStat?.goals ?? 0, branchStat?.visits ?? 0);

    chartsDataMap[branchKey] = chartData;
  });

  const result = split.map((value, index) => {
    const branchKey = `output${index}`;
    const branchStat = statOutputs?.[branchKey]?.find((item) => item.goalUUID === goalId);
    let charts;
    const isWinner = checkWinner(statOutputs, goalId, index === 0, branchStat?.uplift);

    if (index === 0) {
      charts = split.map((sValue, sIndex) => {
        const branchKey = `output${sIndex}`;
        const isCurrent = sIndex === index;

        return {
          isCurrent,
          branchKey,
          chartData: chartsDataMap[branchKey]
        };
      });
    } else {
      charts = [
        { isCurrent: false, chartData: chartsDataMap.output0, branchKey: 'output0' },
        { isCurrent: true, chartData: chartsDataMap[`output${index}`], branchKey }
      ];
    }

    return {
      branchStat,
      isWinner,
      charts,
      branchValue: value
    };
  });

  return result;
}
