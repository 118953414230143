import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgWarningRoundMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20.5a8.5 8.5 0 100-17 8.5 8.5 0 000 17zm0 1.5c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM11 7.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5V13a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V7.5zm.5 7.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgWarningRoundMediumLined;
