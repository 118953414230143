import styled from 'styled-components';
import { Color, Shadow, Spacing, FontSize, FontWeight } from '@pushwoosh/kit-constants';

export const Container = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  background-color: ${Color.CLEAR};
  box-shadow: ${Shadow.REGULAR};
  border-radius: 8px;
`;

type Props = {
  isDisabled: boolean;
};

export const Control = styled.button<Props>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'auto' : 'pointer')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  border: none;
  outline: transform;
  background-color: ${Color.CLEAR};
  width: 36px;
  height: 36px;
  padding: ${Spacing.S2};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
  color: ${({ isDisabled }) => (isDisabled ? Color.PHANTOM : Color.MAIN)};

  &:hover {
    color: ${Color.PRIMARY_HOVER};
  }

  &:active {
    opacity: 0.5;
  }
`;

export const Value = styled.button`
  height: 36px;
  display: flex;
  border: none;
  background-color: ${Color.CLEAR};
  outline: transparent;
  align-items: center;
  justify-content: center;
  padding: ${Spacing.S2};
  font-size: ${FontSize.REGULAR};
  font-weight: ${FontWeight.MEDIUM};
  cursor: pointer;
  color: ${Color.MAIN};

  &:hover {
    color: ${Color.PRIMARY_HOVER};
  }

  &:active {
    opacity: 0.5;
  }
`;
