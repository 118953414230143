import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPlusRoundMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 7.25a.75.75 0 01.75.75v3.25H16a.75.75 0 010 1.5h-3.25V16a.75.75 0 01-1.5 0v-3.25H8a.75.75 0 010-1.5h3.25V8a.75.75 0 01.75-.75z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.473 2 2 6.473 2 12s4.473 10 10 10 10-4.473 10-10S17.527 2 12 2zm0 18.438A8.433 8.433 0 013.562 12 8.433 8.433 0 0112 3.562 8.433 8.433 0 0120.438 12 8.433 8.433 0 0112 20.438z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPlusRoundMediumLined;
