import React from 'react';

// UI Components
import { ExtraOneButton, Modal, Section } from '@src/ui/kit';
import { Paragraph } from '@pushwoosh/kit-typography';

// Types
import { TABSplitterPoint } from '@src/common/types/points';
import { TPointABStatistics } from '@src/common/types/statistics';
import { ModalType } from '@src/common/constants/modals';
import { TModal } from '@src/common/types/modal';
import { ISelectOption } from '@src/common/types/form';
import { FormBlock } from '@src/ui/form/styled';

// Styled
import { useCloseModalByType } from '@src/store/hooks/modals/useCloseModalByType';
import { FormSelect } from '@src/ui/form';
import { useGoalEventPoints } from '@src/store/hooks';
import { sendMixpanelEvent } from '@src/common/analytics';
import { DropOffStat } from '../../DropOffStat';
import { BranchTable } from '../components/BranchTable';
import { BranchWithoutGoals } from '../components/BranchWithoutGoals';

interface IProps {
  split: TABSplitterPoint['data']['split'];
  statistics: TPointABStatistics['pointStat'][];
  pointId: string;
}

export function DetailABSplitter(props: IProps): JSX.Element {
  const { split, statistics, pointId } = props;
  const { goalEventPoints } = useGoalEventPoints();

  const modalSettings = {
    type: ModalType.EDITING_FORM_POINT,
    data: {
      pointId
    }
  } as TModal;

  const goalSelectOptions = goalEventPoints.map((goal) => ({
    value: goal.id,
    label: goal.data.name
  }));

  const [selectedGoal, setSelectedGoal] = React.useState(goalSelectOptions[0]?.value);

  const closeModalByType = useCloseModalByType();

  const handleGoalSelect = (name: string, value: string) => {
    setSelectedGoal(value);

    sendMixpanelEvent({
      eventName: 'CustomerJourneyABTestGoalChanged'
    });
  };

  return (
    <Modal
      title={'A/B/n split'}
      footerRight={
        <ExtraOneButton
          actionButtonName="Close"
          onClickActionButton={() => closeModalByType(ModalType.DETAIL_POINT)}
        />
      }
      isOpen
    >
      <Section direction="column">
        <Paragraph>
          Monitor real-time goal conversion rates and deactivate less effective branches to increase
          the overall efficiency of your campaign.
        </Paragraph>
      </Section>
      <Section direction="column">
        {goalEventPoints.length > 0 && (
          <FormSelect
            name="goalEventSelect"
            options={goalSelectOptions as ISelectOption<any>[]}
            value={selectedGoal}
            onChange={handleGoalSelect}
            label="VIEW PERFOMANCE BY GOAL"
          />
        )}
        <FormBlock>
          {goalEventPoints.length > 0 ? (
            <BranchTable goalId={selectedGoal} split={split} pointId={pointId} />
          ) : (
            <BranchWithoutGoals split={split} pointId={pointId} statistics={statistics} />
          )}
        </FormBlock>
        <DropOffStat pointId={pointId} modal={modalSettings} />
      </Section>
    </Modal>
  );
}
