import { useEffect, MutableRefObject } from 'react';
import Konva from 'konva';

// Types
import { IPointOutput } from '@src/common/types/points';
import { TArrow } from '@src/common/types/arrow';
import { IUniqueId } from '@src/common/types/entities';

export function useRefreshArrowsAfterChangingPointOutputs(
  refStage: MutableRefObject<Konva.Stage>,
  pointOutputs: IPointOutput[],
  setArrows: (arrow: TArrow[]) => void
): void {
  useEffect((): void => {
    if (pointOutputs === undefined) {
      return;
    }

    const pointNodeEntities: Record<string, Konva.Group> = {};
    refStage.current.find('.point').forEach((pointNode): void => {
      const pointId: IUniqueId['id'] = pointNode.getAttr('pointId');

      pointNodeEntities[pointId] = pointNode as Konva.Group;
    });

    const arrows = pointOutputs.map<TArrow>((pointOutput): TArrow => {
      const fromPoint = pointNodeEntities[pointOutput.fromPointId];
      const toPoint = pointNodeEntities[pointOutput.toPointId];

      const fromPointOutputs = fromPoint.find('.point-output');

      const toX = toPoint.x();
      const toY = toPoint.y() + toPoint.height() / 2;

      let fromX = 0;
      let fromY = 0;
      let currentColor = '';

      fromPointOutputs.forEach((output) => {
        const outputAttrs = output.getAttrs();
        const isEqualFromPoint = pointOutput.fromPointId === outputAttrs.fromPointId;
        const isEqualOutputKey = pointOutput.key === outputAttrs.outputKey;

        if (isEqualFromPoint && isEqualOutputKey) {
          const outputRect = output.getClientRect({ relativeTo: fromPoint });

          fromX = fromPoint.x() + outputRect.x + outputRect.width / 2;
          fromY = fromPoint.y() + outputRect.y + outputRect.height / 2;
          currentColor = outputAttrs.colorArrow;
        }
      });

      return {
        fromX,
        fromY,
        toX,
        toY,
        fromPointId: pointOutput.fromPointId,
        toPointId: pointOutput.toPointId,
        outputKey: pointOutput.key,
        color: currentColor,
        outputOrder: pointOutput.order,
        outputStats: 0,
        pageX: 0,
        pageY: 0
      };
    });

    setArrows(arrows);
  }, [pointOutputs, refStage, setArrows]);
}
