import * as React from 'react';

// Types
import { IVector2 } from '@src/common/types/types';

// Hooks
import { useSavedHandler } from './useSavedHandler';

type TFunc = (event: MouseEvent) => void;

export function useMousePosition(): IVector2 {
  const [position, setPosition] = React.useState<IVector2>({ x: 0, y: 0 });
  const savedHandleMouseMove = useSavedHandler<TFunc>((event: MouseEvent): void => {
    setPosition({ x: event.clientX, y: event.clientY });
  });

  React.useEffect((): (() => void) => {
    const handler = savedHandleMouseMove.current;

    document.addEventListener('mousemove', handler);

    return (): void => {
      document.removeEventListener('mousemove', handler);
    };
  }, [savedHandleMouseMove]);

  return position;
}
