exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".alert-error__alert-error___3FOwD.default-theme .alert-error__custom-alert___jAEBW{position:relative;padding:14px 40px 14px 14px;background:#fff}.alert-error__alert-error___3FOwD.default-theme .alert-error__text-container___2Wabu{width:300px}.alert-error__alert-error___3FOwD.default-theme .alert-error__alert-title___1a1jU{font-size:14px;font-weight:bold;color:#333}.alert-error__alert-error___3FOwD.default-theme .alert-error__alert-message___zaiKV{margin-top:4px;font-size:12px;color:#555}.alert-error__alert-error___3FOwD.default-theme .alert-error__custom-icon___2Ml4X{position:absolute;top:15px;right:15px}.alert-error__alert-error___3FOwD.default-theme .alert-error__custom-button___-56cR{align-self:flex-start;margin-top:12px;padding:6px 16px;border-radius:4px;background-color:#fff;border:1px solid #e64a19;color:#e64a19;font-weight:500}.alert-error__alert-error___3FOwD.default-theme .alert-error__custom-button___-56cR:hover{background-color:#e64a19;border:1px solid #e64a19;color:#fff}", ""]);

// Exports
exports.locals = {
	"alert-error": "alert-error__alert-error___3FOwD",
	"custom-alert": "alert-error__custom-alert___jAEBW",
	"text-container": "alert-error__text-container___2Wabu",
	"alert-title": "alert-error__alert-title___1a1jU",
	"alert-message": "alert-error__alert-message___zaiKV",
	"custom-icon": "alert-error__custom-icon___2Ml4X",
	"custom-button": "alert-error__custom-button___-56cR"
};