import * as React from 'react';
import { useParams } from 'react-router-dom';

// Hooks
import { useDidMount } from '@src/ui/hooks';

// UI Components
import { FormItem, FormSelect } from '@src/ui/form';
import { Section } from '@src/ui/kit/Section';
import { Paragraph } from '@pushwoosh/kit-typography';
import { MessageRateLimits } from '@src/ui/features/StartSegment/components/MessageRateLimits';
import { ScheduledLaunch } from '@src/ui/features/StartSegment/components/ScheduledLaunch';
import { StyledInput } from '@src/ui/features/StartSegment/styled';
import { SelectButton } from '@src/ui/form/styled';
import { SegmentBuilder } from '@src/ui/features/SegmentBuilder';

// Types
import { JourneyStatus, PointType } from '@src/common/constants';
import { getPointNameByType } from '@src/ui/helpers';
import { useUser } from '@src/store/hooks';
import { EditPointSegmentProps, EditPointSegmentValues } from './EditPointSegment.types';

export function EditPointSegment(props: EditPointSegmentProps): JSX.Element {
  const {
    names,
    values,
    applicationCode,
    filters,
    isLoadingFilter,
    setFieldValue,
    setValues,
    setFieldTouched,
    onLoadFilter,
    journeyStatus,
    onSubmit
  } = props;
  const params = useParams<{ applicationCode: string }>();

  const [segmentModalOpen, setSegmentModalOpen] = React.useState(false);

  const { accountId } = useUser();

  const defaultTitle = getPointNameByType(PointType.SEGMENT);

  const [nameChanged, setNameChanged] = React.useState(!values.title.includes(defaultTitle));

  useDidMount(() => {
    onLoadFilter();
  });

  const handleChangeName = React.useCallback(
    (name: string, value: string) => {
      setNameChanged(true);
      setFieldValue(name, value);
    },
    [setFieldValue]
  );

  const handleCreateNewSegmentClick = React.useCallback(() => {
    setSegmentModalOpen(true);
  }, [setSegmentModalOpen]);

  const selectAudience = React.useCallback(
    (name: string, value: EditPointSegmentValues['filterCode'], label: string) => {
      if (!nameChanged) {
        setFieldValue(names.title, label);
      }
      setFieldValue(name, value);
    },
    [names.title, setFieldValue, nameChanged]
  );
  const isDisabled = !applicationCode || journeyStatus === JourneyStatus.RUNNING;

  const filtersLink = params.applicationCode
    ? `https://app.pushwoosh.com/applications/${applicationCode}/filters`
    : `https://go.pushwoosh.com/cp/applications/${applicationCode}/filters`;

  return (
    <div>
      <Section direction="column">
        <Paragraph>
          Choose a segment of users who will enter into this campaign. To create and edit segments,
          go to the Segments section of your project. All users that are in this segment will enter
          the journey right away after activating the journey.
        </Paragraph>
        <StyledInput
          label="Point Name"
          name={names.title}
          value={values.title}
          onChange={handleChangeName}
        />
      </Section>
      <Section>
        <FormItem>
          <FormSelect
            label="Audience Source"
            isSearchable
            name={names.filterCode}
            url={applicationCode && filtersLink}
            options={filters}
            placeholder="Select audience source"
            value={values.filterCode}
            isLoading={isLoadingFilter}
            isDisabled={isDisabled}
            onBlur={setFieldTouched}
            onChange={selectAudience}
            rightButton={
              <SelectButton color="secondary" onClick={handleCreateNewSegmentClick}>
                Create Segment
              </SelectButton>
            }
          />
        </FormItem>
      </Section>
      <Section direction="column">
        <ScheduledLaunch
          names={names}
          values={values}
          setFieldValue={setFieldValue}
          setValues={setValues}
          setFieldTouched={setFieldTouched}
          isDisabled={isDisabled}
          journeyStatus={journeyStatus}
          onSubmit={onSubmit}
        />
      </Section>
      <Section direction="column">
        <MessageRateLimits
          names={names}
          values={values}
          setFieldValue={setFieldValue}
          min={accountId === 105486 ? 1 : 10}
        />
      </Section>

      <SegmentBuilder
        applicationCode={applicationCode}
        open={segmentModalOpen}
        onClose={() => setSegmentModalOpen(false)}
        onSaved={(segment) => {
          onLoadFilter();

          setFieldValue(names.filterCode, segment.code);
          setFieldValue(names.title, segment.name);
          setSegmentModalOpen(false);
        }}
      />
    </div>
  );
}
