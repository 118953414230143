import * as React from 'react';
import konva from 'konva';
import { Path } from 'react-konva';
import { LeafBase } from '@src/common/structureObjects/leaf';

// Helpers
import { decodeDelay, formatTimeMaxValue } from '@src/common/helpers';

// Constants
import {
  COLOR_TRUE,
  COLOR_FALSE,
  CONTROL_GROUP_COLOR,
  PointType,
  COLOR_FALSE_LIGHT
} from '@src/common/constants/point';
import { COLOR_ORANGE } from '@src/common/constants/canvas';

// Helpers
import { getTitlePoint } from '@src/canvas/helpers';
import { getOutputs } from '../helpers/getOutputs';

// Components
import { Point, PointContent, PointText, PointIcon, PointOutput, PointShortInfo } from '../Point';

// Types
import { IProps } from './PointWaitEvent.types';

const KEY_TRUE = 'output1';
const KEY_FALSE = 'default';

export function PointWaitEvent(props: IProps): JSX.Element {
  const { data, outputs, propsCommonPoint } = props;

  const { waitEventsConditionsScript, waitEvents } = data;

  const branches = LeafBase.getBranchesFromJsom(waitEventsConditionsScript, waitEvents);

  const handleGetDelay = React.useCallback((): string => {
    if (!data.waitEvents || data.waitEvents.length === 0) {
      return '';
    }
    const mapDelay = decodeDelay(data.waitEvents[0].delay);
    const delay = formatTimeMaxValue(mapDelay);

    return `Wait ${delay.value} ${delay.isMultiple ? '...' : ''}`;
  }, [data.waitEvents]);

  const { isDrawingArrow } = props.propsCommonPoint;

  const getIsOutput = React.useCallback(
    (key: string): boolean => getOutputs(key, outputs),
    [outputs]
  );

  const [isShowBadge, setShowBadge] = React.useState([true, true]);

  const isLinkedOutputFirst = getIsOutput(KEY_TRUE);
  const isLinkedOutputSecond = getIsOutput(KEY_FALSE);

  // Show badge after drawing if haven't arrow
  React.useEffect(
    () => {
      if (!isDrawingArrow && !isShowBadge[0]) {
        setShowBadge([true, isShowBadge[1]]);
      }
      if (!isDrawingArrow && !isShowBadge[1]) {
        setShowBadge([isShowBadge[0], true]);
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [isDrawingArrow]
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  const titlePoint = getTitlePoint(PointType.WAIT_EVENT, propsCommonPoint.title, data);

  return (
    <Point
      type={PointType.BOOLEAN_SPLITTER}
      color={CONTROL_GROUP_COLOR}
      countContent={branches.length + 1}
      {...propsCommonPoint}
    >
      {branches.map((branch, index) => (
        <PointContent type={PointType.BOOLEAN_SPLITTER} fill="#99C9FF15" order={index + 1}>
          <PointIcon type={PointType.BOOLEAN_SPLITTER} fill={CONTROL_GROUP_COLOR}>
            <Path
              y={5}
              x={2}
              fill="white"
              data="M4.71004 0.343892C4.91764 -0.0713035 5.52974 -0.00411285 5.64232 0.44623L7.11782 6.3482C7.18537 6.6184 7.55263 6.65872 7.67719 6.4096L8.24377 5.27643C8.32847 5.10704 8.5016 5.00004 8.69098 5.00004H11.5C11.7761 5.00004 12 5.2239 12 5.50004V6.50004C12 6.77618 11.7761 7.00004 11.5 7.00004H9.92705C9.73767 7.00004 9.56453 7.10704 9.47984 7.27643L7.28996 11.6562C7.08236 12.0714 6.47026 12.0042 6.35768 11.5538L4.88218 5.65188C4.81463 5.38168 4.44737 5.34136 4.32281 5.59048L3.75623 6.72365C3.67153 6.89304 3.4984 7.00004 3.30902 7.00004H0.5C0.223858 7.00004 0 6.77618 0 6.50004V5.50004C0 5.2239 0.223858 5.00004 0.5 5.00004H2.07295C2.26234 5.00004 2.43547 4.89304 2.52016 4.72365L4.71004 0.343892Z"
            />
          </PointIcon>
          <PointText type={PointType.BOOLEAN_SPLITTER} order={0}>
            {branch.title || `Trigger group ${index + 1}`}
          </PointText>
          <PointOutput
            type={PointType.BOOLEAN_SPLITTER}
            outputKey={`output${index + 1}`}
            order={index}
            pointId={propsCommonPoint.id}
            colorArrow={CONTROL_GROUP_COLOR}
            colorBlock={CONTROL_GROUP_COLOR}
            colorOutput={CONTROL_GROUP_COLOR}
            isInvalid={propsCommonPoint.isInvalid}
            isActive={propsCommonPoint.isActive}
            isLinkedOutput={getIsOutput(`output${index + 1}`)}
            onMouseDown={propsCommonPoint.onMouseDownOutput}
            arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
          />
        </PointContent>
      ))}
      <PointContent
        type={PointType.BOOLEAN_SPLITTER}
        fill={COLOR_FALSE_LIGHT}
        order={branches.length + 1}
      >
        <PointIcon type={PointType.BOOLEAN_SPLITTER} fill={COLOR_FALSE}>
          <Path
            fill="white"
            data="M5.7019 7.64124C5.40901 7.34835 4.93413 7.34835 4.64124 7.64124C4.34835 7.93413 4.34835 8.40901 4.64124 8.7019L6.93935 11L4.64125 13.2981C4.34836 13.591 4.34836 14.0659 4.64125 14.3588C4.93414 14.6517 5.40902 14.6517 5.70191 14.3588L8.00001 12.0607L10.2981 14.3588C10.591 14.6516 11.0659 14.6516 11.3588 14.3588C11.6516 14.0659 11.6516 13.591 11.3588 13.2981L9.06067 11L11.3588 8.70192C11.6517 8.40902 11.6517 7.93415 11.3588 7.64125C11.0659 7.34836 10.591 7.34836 10.2981 7.64125L8.00001 9.93935L5.7019 7.64124Z"
          />
        </PointIcon>
        <PointText type={PointType.BOOLEAN_SPLITTER} order={1} fill={COLOR_FALSE}>
          Not triggered
        </PointText>
        <PointOutput
          type={PointType.BOOLEAN_SPLITTER}
          outputKey={KEY_FALSE}
          order={branches.length + 1}
          pointId={propsCommonPoint.id}
          colorBlock={CONTROL_GROUP_COLOR}
          colorArrow={COLOR_ORANGE}
          colorOutput={COLOR_FALSE}
          isInvalid={propsCommonPoint.isInvalid}
          isActive={propsCommonPoint.isActive}
          isLinkedOutput={getIsOutput(KEY_FALSE)}
          onMouseDown={propsCommonPoint.onMouseDownOutput}
          arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
        />
      </PointContent>
    </Point>
  );
}
