import React from 'react';

// Components
import { Spinner } from '../Spinner';
import { Icon } from '../Icon';

// Types
import { IButtonProps } from './Button.types';

// Helpers
import { getLoaderColors } from './helpers/getLoaderColor';

// Styled
import { StyledButton, Label } from './styled';

export function Button(props: IButtonProps): JSX.Element {
  const {
    children,
    className,
    isLoading,
    disabled,
    view = 'shape',
    size = 'field',
    color = 'primary',
    iconPosition = 'left',
    iconType,
    onClick,
    type = 'button',
    ref
  } = props;

  const isIconOnly = !children;

  return (
    <StyledButton
      className={className}
      isLoading={isLoading}
      disabled={disabled}
      view={view}
      color={color}
      size={size}
      onClick={onClick}
      iconPosition={iconPosition}
      isIconOnly={isIconOnly}
      type={type}
      ref={ref}
    >
      {!isLoading && iconType && iconPosition === 'left' && <Icon type={iconType} />}

      {isLoading && iconPosition === 'left' && <Spinner color={getLoaderColors(color, view)} />}

      <Label isLoading={isLoading}>{children}</Label>

      {!isLoading && iconType && iconPosition === 'right' && <Icon type={iconType} />}

      {isLoading && iconPosition === 'right' && <Spinner color={getLoaderColors(color, view)} />}
    </StyledButton>
  );
}
