import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgVisibilityInvisibleMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.197 4.136a.75.75 0 10-1.06 1.061l2.787 2.787a13.05 13.05 0 00-2.918 3.662.75.75 0 00-.01.69l.67-.336-.67.335v.002l.001.001.003.005.008.015.027.053a12.404 12.404 0 00.491.83c.34.526.847 1.231 1.51 1.939 1.313 1.4 3.331 2.903 5.964 2.903h.012a7.464 7.464 0 003.86-1.15l2.931 2.93a.75.75 0 101.06-1.06l-3.365-3.366-.01-.01-.006-.006-7.904-7.904a.764.764 0 00-.016-.016L5.197 4.136zm.328 7.866a11.55 11.55 0 012.462-2.954l1.598 1.598a2.75 2.75 0 003.769 3.769l1.424 1.424c-.85.47-1.805.727-2.784.744-2.03-.002-3.678-1.164-4.863-2.43a12.368 12.368 0 01-1.606-2.15zm5.203-.213a1.248 1.248 0 00.754 1.42 1.25 1.25 0 00.73.063l-1.484-1.483z"
        fill={props.fill}
      />
      <path
        d="M11.998 7.417a5.33 5.33 0 00-1.227.14.75.75 0 01-.342-1.46 6.831 6.831 0 011.572-.18c2.633 0 4.65 1.503 5.963 2.903a13.871 13.871 0 011.9 2.583 8.413 8.413 0 01.129.239l.003.007a.748.748 0 01-.005.712c-.428.8-.939 1.554-1.524 2.249a.75.75 0 11-1.147-.966 11.59 11.59 0 001.156-1.645 12.366 12.366 0 00-1.606-2.152c-1.187-1.267-2.836-2.43-4.87-2.43h-.002z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgVisibilityInvisibleMediumLined;
