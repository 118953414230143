import styled from 'styled-components';

// Constants
import { Spacing } from '@pushwoosh/kit-constants';

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: ${Spacing.S3};
`;
