import styled from 'styled-components';
import { Spacing } from '@pushwoosh/kit-constants';

export const StyledItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${Spacing.S3};
`;

export const HeaderName = styled.div`
  width: 160px;
  margin-right: ${Spacing.S3};
  padding-bottom: 0;
  margin-bottom: -${Spacing.S5};
`;

export const HeaderValue = styled.div`
  width: 100%;
  margin-right: ${Spacing.S3};
  padding-bottom: 0;
  margin-bottom: -${Spacing.S5};
`;
