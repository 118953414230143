import { usePoints } from '@src/store/hooks';
import { ENTRY_GROUP, CHANNEL_GROUP, FLOW_GROUP, PointType } from '@src/common/constants/point';
import { isConfiguredPoint } from '@src/common/helpers/isConfiguredPoint';

export function useAssistant() {
  const items = {
    entriesAdded: false,
    actionAdded: false,
    exitAdded: false,
    stepsConnected: false,
    stepsConfigured: false
  };

  const points = usePoints();

  points.forEach((point) => {
    if (ENTRY_GROUP.has(point.type)) {
      items.entriesAdded = true;
    }

    FLOW_GROUP.delete(PointType.EXIT);

    if (FLOW_GROUP.has(point.type) || CHANNEL_GROUP.has(point.type)) {
      items.actionAdded = true;
    }

    FLOW_GROUP.add(PointType.EXIT);

    if (point.type === PointType.EXIT) {
      items.exitAdded = true;
    }
  });

  const isStepsConnected =
    points.length >= 1 &&
    points.every((point) => {
      if (point.type === PointType.EXIT || point.type === PointType.GOAL_EVENT) {
        return point.outputs.length === 0;
      }

      return point.outputs.length >= 1;
    });

  items.stepsConfigured = points.length >= 1 && points.every((point) => isConfiguredPoint(point));

  items.stepsConnected = isStepsConnected;

  return items;
}
