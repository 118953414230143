import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgSendMediumLined(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.614 4.362a.75.75 0 01.73-.033l13.992 7a.75.75 0 010 1.342l-13.993 7A.75.75 0 014.258 19l-.008-5.667a.75.75 0 01.617-.739L8.162 12l-3.295-.595a.75.75 0 01-.617-.74L4.258 5c0-.26.135-.5.356-.637zm1.142 1.851l-.005 3.827 6.763 1.222a.75.75 0 010 1.476L5.751 13.96l.005 3.827L17.324 12 5.756 6.213z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgSendMediumLined;
