// Constants
import {
  AttributeTypeEnum,
  OperatorTypeEnum,
  OperatorTypesHumans
} from '@src/common/constants/attribute';

// Types
import { IOption } from '@src/common/types/select';

const INTEGER_OPERATOR_TYPES = new Set([
  OperatorTypeEnum.EQ,
  OperatorTypeEnum.NOTEQ,
  OperatorTypeEnum.LTE,
  OperatorTypeEnum.GTE,
  OperatorTypeEnum.BETWEEN,
  OperatorTypeEnum.IN,
  OperatorTypeEnum.NOTIN
]);

const STRING_OPERATOR_TYPES = new Set([
  OperatorTypeEnum.EQ,
  OperatorTypeEnum.NOTEQ,
  OperatorTypeEnum.IN,
  OperatorTypeEnum.NOTIN
]);

const LIST_OPERATOR_TYPES = new Set([OperatorTypeEnum.EQ, OperatorTypeEnum.IN]);

const DATE_OPERATOR_TYPES = new Set([
  OperatorTypeEnum.EQ,
  OperatorTypeEnum.LTE,
  OperatorTypeEnum.GTE,
  OperatorTypeEnum.BETWEEN
]);

const BOOLEAN_OPERATOR_TYPES = new Set([OperatorTypeEnum.EQ]);

function makeOperatorOptions(operators: string[]): IOption<string>[] {
  return Object.values(operators).map(
    (operator): IOption<string> => ({
      label: OperatorTypesHumans[operator as OperatorTypeEnum],
      value: operator
    })
  );
}

const integerOperatorOptions = makeOperatorOptions(Array.from(INTEGER_OPERATOR_TYPES));
const stringOperatorOptions = makeOperatorOptions(Array.from(STRING_OPERATOR_TYPES));
const listOperatorOptions = makeOperatorOptions(Array.from(LIST_OPERATOR_TYPES));
const dateOperatorOptions = makeOperatorOptions(Array.from(DATE_OPERATOR_TYPES));
const booleanOperatorOptions = makeOperatorOptions(Array.from(BOOLEAN_OPERATOR_TYPES));

export const makeOperatorOptionsByAttributeType = (type: number): IOption<string>[] => {
  if (type === AttributeTypeEnum.INTEGER) return integerOperatorOptions;
  if (type === AttributeTypeEnum.STRING) return stringOperatorOptions;
  if (type === AttributeTypeEnum.LIST) return listOperatorOptions;
  if (type === AttributeTypeEnum.DATE) return dateOperatorOptions;
  if (type === AttributeTypeEnum.BOOLEAN) return booleanOperatorOptions;

  return [];
};
