import * as React from 'react';
import konva from 'konva';
import { Path } from 'react-konva';

// Constants
import { ENTRY_GROUP_COLOR, POINT_MENU_DEFAULT, PointType } from '@src/common/constants';

// Hooks
import { useShowOneBadge } from '@src/canvas/hooks';

// Helpers
import { getTitlePoint } from '@src/canvas/helpers';

// Components
import { Point, PointContent, PointText, PointIcon, PointOutput, PointShortInfo } from '../Point';

// Types
import { IProps } from './PointEvent.types';

export function PointEvent(props: IProps): JSX.Element {
  const { data, propsCommonPoint } = props;

  const [isShowBadge, setShowBadge] = useShowOneBadge(propsCommonPoint.isDrawingArrow);

  const titlePoint = getTitlePoint(PointType.EVENT, propsCommonPoint.title, data);

  return (
    <Point type={PointType.EVENT} color={ENTRY_GROUP_COLOR} {...propsCommonPoint}>
      <PointContent type={PointType.EVENT}>
        <PointIcon type={PointType.EVENT} fill={ENTRY_GROUP_COLOR}>
          <Path
            fill="white"
            perfectDrawEnabled={false}
            shadowForStrokeEnabled={false}
            data="M13.5391 21.9362C12.9136 21.9362 12.5301 21.2508 12.8572 20.7178L20.6874 7.95934C21.1321 7.23477 22.2506 7.61224 22.1652 8.45809L21.2891 17.1429C21.2416 17.6141 21.6115 18.0232 22.0851 18.0232H26.4607C27.0879 18.0232 27.4712 18.7122 27.1403 19.2451L19.195 32.044C18.7478 32.7644 17.6343 32.3854 17.7194 31.5418L18.5996 22.8165C18.6471 22.3454 18.2772 21.9362 17.8036 21.9362H13.5391Z"
          />
        </PointIcon>
        <PointText type={PointType.EVENT}>{titlePoint}</PointText>
        <PointOutput
          pointId={propsCommonPoint.id}
          type={PointType.EVENT}
          colorBlock={ENTRY_GROUP_COLOR}
          isInvalid={propsCommonPoint.isInvalid}
          isActive={propsCommonPoint.isActive}
          isLinkedOutput={propsCommonPoint.isLinkedOutput}
          onMouseDown={(event: konva.KonvaEventObject<MouseEvent>): void => {
            setShowBadge(!isShowBadge);
            propsCommonPoint.onMouseDownOutput(event);
          }}
          textBadge="Connect this"
          fillBadge={POINT_MENU_DEFAULT}
          isShowBadge={propsCommonPoint.isLinkedOutput ? false : isShowBadge}
          arrowHoveredToDelete={propsCommonPoint.arrowHoveredToDelete}
        />
      </PointContent>
      <PointShortInfo type={PointType.EVENT} label="Entry" align="left" />
    </Point>
  );
}
