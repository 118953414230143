import * as React from 'react';
import { IProps } from '../Icon.types';

function SvgPlayRoundMediumFilled(props: IProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12c0-4.974 4.025-9 9-9 4.974 0 9 4.025 9 9 0 4.974-4.025 9-9 9-4.974 0-9-4.025-9-9zm7.502-3.061a.5.5 0 00-.752.432v5.258a.5.5 0 00.752.432l4.508-2.63a.5.5 0 000-.863l-4.508-2.63z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPlayRoundMediumFilled;
