import * as React from 'react';
import { Group, Tag, Text, Label, RegularPolygon } from 'react-konva';

// Types
import { IProps } from './PointBadge.types';

export function PointBadge(props: IProps): JSX.Element {
  const { x, y, text, fill } = props;

  return (
    <Group x={x} y={y} perfectDrawEnabled={false}>
      <RegularPolygon
        perfectDrawEnabled={false}
        shadowForStrokeEnabled={false}
        y={9}
        fill={fill}
        sides={3}
        radius={3}
        rotation={30}
        stroke={fill}
        strokeWidth={5}
        lineJoin="round"
      />
      <Label pointerWidth={10}>
        <Tag
          perfectDrawEnabled={false}
          shadowForStrokeEnabled={false}
          fill={fill}
          cornerRadius={3}
        />
        <Text
          perfectDrawEnabled={false}
          shadowForStrokeEnabled={false}
          height={18}
          fontSize={12}
          fontFamily="Roboto"
          fontStyle="800"
          text={text}
          fill="#fff"
          wrap="none"
          lineHeight={1}
          padding={4}
        />
      </Label>
    </Group>
  );
}
